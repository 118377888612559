import React, { useEffect, useRef, useState } from "react";
// UX
import CardTravelCertificateUX from "./CardTravelCertificateUX";
// Common

// Interface
import { State } from "./sequence/DoctorCertificate";
// Utils
import moment from "moment";
import { Dimmer, Loader, Button } from "semantic-ui-react";

// Main Props
type CardTravelCertificateProps = {
  // Function
  setProp: any;

  // Options
  masterOptions?: any;

  // Sequence
  runSequence?: any;
  DoctorCertificateSequence: State["DoctorCertificateSequence"];
};

// Current Date
const currentDate = new Date().toISOString().split("T")[0];
const christYear = moment(currentDate).format("YYYY");
const buddhistYear = (parseInt(christYear) + 543).toString();
const formattedDate = moment(currentDate).format(
  "DD/MM/YYYY".replace("YYYY", buddhistYear)
);
const defaultDate = moment().format("HH:mm");

// Visit Type Options
const visitTypeOptions = [
  { key: 1, value: "OPD", text: "ผู้ป่วยนอก" },
  { key: 2, value: "IPD", text: "ผู้ป่วยใน" },
];

// Special Requirement Options
const specialRequireIOptions = [
  { key: "Wheel chair", value: "Wheel chair", text: "Wheel chair" },
  { key: "Oxygen supply", value: "Oxygen supply", text: "Oxygen supply" },
  { key: "Business class", value: "Business class", text: "Business class" },
  { key: "Economy class", value: "Economy class", text: "Economy class" },
  { key: "to Step", value: "to Step", text: "to Step" },
  { key: "to Ramp to Seat (Cabin)", value: "to Ramp to Seat (Cabin)",  text: "to Ramp to Seat (Cabin)" },
];

// Main
const CardTravelCertificate = (props: CardTravelCertificateProps) => {
  // log Props
  // console.log("CardTravelCertificate Props: ", props.DoctorCertificateSequence);

  // Call Sequence ( Covid Certificate )
  useEffect(() => {
    props.runSequence({
      sequence: "DoctorCertificate",
      action: "FETCH_CERTIFICATE",
      fetchType: "CardTravelCertificate",
    });
  }, []);

  // set State
  const [isLoading, setIsLoading] = useState(false);
  const [newSpecialOptions, setNewSpecialOptions] = useState(
    specialRequireIOptions
  );
  const [currentSpecial, setCurrentSpecial] = useState("");

  // Data Check
  useEffect(() => {
    const hasDiagnosis =
      props.DoctorCertificateSequence?.travelReport?.diagnosis !== null;
    const hasPatientInfo =
      Object.keys(props.DoctorCertificateSequence?.travelReport?.patientInfo)
        .length > 0;
    const hasDoctorCertNumber =
      props.DoctorCertificateSequence?.travelReport?.doctorCertNumber !== null;

    if (hasDiagnosis && hasPatientInfo && hasDoctorCertNumber) {
      setIsLoading(false);
    } else {
      setIsLoading(true);
    }
  }, [
    props.DoctorCertificateSequence?.travelReport?.diagnosis,
    props.DoctorCertificateSequence?.travelReport?.patientInfo,
    props.DoctorCertificateSequence?.travelReport?.doctorCertNumber,
  ]);

  // Change Visit Type
  const handleChangeVisitType = (value: string) => {
    props.setProp(
      `DoctorCertificateSequence.travelReport.patientInfo.encounter_type`,
      value
    );
  };

  // Change Date
  const handleChangeDate = (name: any, date: string) => {
    // console.log("Key: ", name + " Date: ", date);
    props.setProp(`DoctorCertificateSequence.travelReport.${name}`, date);
  };

  // Change Text
  const handleChangeText = (
    name: any,
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    switch (name) {
      case "diagnosis":
      case "comment":
        props.setProp(
          `DoctorCertificateSequence.travelReport.${name}`,
          e.target.value
        );
        break;
      default:
        console.log("Doesn't Exist!!");
        break;
    }
  };

  // Add new Special Require
  const handleAddSpecialRequire = (e: any, { value }: { value: any }) => {
    setNewSpecialOptions((prevOptions: any) => [
      { text: value, value },
      ...prevOptions,
    ]);
  };

  // Change Special Require
  const handleChangeSpecialRequire = (value: any) => {
    props.setProp(
      `DoctorCertificateSequence.travelReport.specialRequire`,
      value
    );
  };

  // get Value from Special Require Dropdown
  const handleSelectedSpecialRequire = (e: any, { value }: { value: any }) => {
    //console.log(value)
    handleChangeSpecialRequire(value);
    setCurrentSpecial(value);
  };

  // Change Checkbox
  const handleChangeRecommend = (e: any, value: string) => {
    props.setProp(
      `DoctorCertificateSequence.travelReport.travelRecommend`,
      value
    );
  };

  // Condition Close Button
  // const clearSelectedData = () => {
  //   // Set selectedReport = null
  //   props.setProp(`DoctorCertificateSequence.selectedReport`, null);

  //   // Re-Fetch
  //   props.runSequence({
  //     sequence: "DoctorCertificate",
  //     action: "CLEAR_REPORT",
  //     callReport: "CardTravelCertificate",
  //   });
  // };

  // const closeSelectedCheck = (reportSelected: any) => {
  //   if (reportSelected != null) {
  //     return (
  //       <Button
  //         color="red"
  //         style={{ width: "8em" }}
  //         onClick={clearSelectedData}
  //       >
  //         ปิด
  //       </Button>
  //     );
  //   } else {
  //     return null;
  //   }
  // };

  // Disable when select FiveForm
  const handleDisableForm = (selected: any, reportType?: any) => {
    if (selected == null) {
      return false;
    } else if (
      selected !== null &&
      reportType === "ใบรับรองแพทย์สำหรับการเดินทาง"
    ) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <>
      <CardTravelCertificateUX
        // Visit Type
        onVisitTypeOption={visitTypeOptions}
        onVisitTypeValue={
          props.DoctorCertificateSequence?.travelReport?.patientInfo
            ?.encounter_type || ""
        }
        onHandleChangeVisitType={handleChangeVisitType}
        // Exam Date
        onExamDate={
          props.DoctorCertificateSequence?.travelReport?.examDate ||
          formattedDate
        }
        onHandleChangeDate={handleChangeDate}
        // Min Date
        onMinDate={moment().subtract(1, "days")}
        // Flight Date
        onFlightDate={
          props.DoctorCertificateSequence?.travelReport?.flightDate || ""
        }
        // Diagnosis
        onDiagnosis={
          props.DoctorCertificateSequence?.travelReport?.diagnosis || ""
        }
        onHandleChangeText={handleChangeText}
        // Checkbox
        onRecommendOption={(value: string) =>
          props.DoctorCertificateSequence?.travelReport?.travelRecommend ===
          `${value}`
        }
        onHandleChangeRecommend={handleChangeRecommend}
        // Special Require
        onSpecialRequireOption={newSpecialOptions}
        onSpecialRequireValue={
          props.DoctorCertificateSequence?.travelReport?.specialRequire ||
          currentSpecial
        }
        onHandleChangeSpecialRequire={handleSelectedSpecialRequire}
        onHandleAddSpecialRequire={handleAddSpecialRequire}
        // Comment
        onComment={props.DoctorCertificateSequence?.travelReport?.comment || ""}
        // Close Button When Selected Report
        // onCloseSelected={closeSelectedCheck(
        //   null
        // )}
        // Disable Input
        // onHandleDisableForm={handleDisableForm(
        //   null,
        //   props.DoctorCertificateSequence?.selectedReport?.data?.reportType
        // )}
      />
      <Dimmer active={isLoading} inverted>
        <Loader inverted>Loading</Loader>
      </Dimmer>
    </>
  );
};

export default CardTravelCertificate;
