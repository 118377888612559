import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  Label,
  Icon,
  Checkbox,
  Radio,
  Input,
  Button
} from 'semantic-ui-react'
import {
  Table
} from 'react-lib/frameworks/Table'

const CardSummaryPlanningUX = (props: any) => {
    return(
      <div
        style={{padding: "10px"}}>
        <div
          style={{display: "flex", justifyContent: "space-between"}}>
          
          <div
            style={{display: "flex", alignItems: "center"}}>
            
            <div
              className="ui label pink ribbon">
              NURSING DISCHARGE PLANNING SUMMARY
            </div>
          </div>
          <div
            style={{display: "flex"}}>
            
            <Label
              color="pink"
              style={{display: props.showStatus ? "" : "none"}}>
              Draft
            </Label>
            <div
              style={{cursor: "pointer", margin: "-5px -5px 0 15px", display: props.closeIcon ? "" : "none"}}>
              
              <Icon
                className="red large"
                name={"close"}
                onClick={props.onClose}>
              </Icon>
            </div>
          </div>
        </div>
        <div
          style={{paddingTop:"10px"}}>
          
          <div
            style={{"width":"100%","height":"100%","display":"grid","position":"relative","gridTemplateRows":"repeat(6, 1fr)","gridTemplateColumns":"repeat(50, 1fr)"}}>
            <div
              style={{ gridRow: "1/2", gridColumn: "1/15",fontWeight: "bold"}}>
              {`1. ${props.ClinicalTermNursing?.disc_nursing1?.name}`}
            </div>
            <div
              style={{ gridRow: "2/3", gridColumn: "1/15",fontWeight: "bold"}}>
              
              <Label>
                {props.dischargePlanning?.predischarge_condition || '-'}
              </Label>
            </div>
            <div
              style={{ gridRow: "3/4", gridColumn: "1/15",fontWeight: "bold"}}>
              {`2. ${props.ClinicalTermNursing?.disc_nursing2?.name}`}
            </div>
            <div
              style={{ gridRow: "3/4", gridColumn: "17/23",display:"flex"}}>
              
              <Radio
                checked={props.ClinicalTermNursing?.disc_nursing1?.has_problem === "N"}
                label="ไม่มี"
                name="has_problem"
                onChange={props.handleChangeValue("disc_nursing1.has_problem")}
                style={{ marginRight: "20px" }}
                value="N">
              </Radio>
              <Radio
                checked={props.ClinicalTermNursing?.disc_nursing1?.has_problem === "Y"}
                label="มี"
                name="has_problem"
                onChange={props.handleChangeValue("disc_nursing1.has_problem")}
                style={{ marginRight: "20px" }}
                value="Y">
              </Radio>
            </div>
            <div
              style={{ gridRow: "4/5", gridColumn: "1/10",display:"flex"}}>
              
              <Checkbox
                checked={props.ClinicalTermNursing?.disc_nursing2?.items[0]?.checked}
                onChange={props.handleCheckValue("disc_nursing2.items.0.checked")}
                style={{marginTop:"5px"}}>
              </Checkbox>
              <div
                style={{marginTop:"3px",marginLeft:"10px"}}>
                {props.ClinicalTermNursing?.disc_nursing2?.items[0]?.name}
              </div>
            </div>
            <div
              style={{ gridRow: "4/5", gridColumn: "11/16",display:"flex"}}>
              
              <Checkbox
                checked={props.ClinicalTermNursing?.disc_nursing2?.items[1]?.checked}
                onChange={props.handleCheckValue("disc_nursing2.items.1.checked")}
                style={{marginTop:"5px"}}>
              </Checkbox>
              <div
                style={{marginTop:"3px",marginLeft:"10px"}}>
                {props.ClinicalTermNursing?.disc_nursing2?.items[1]?.name}
              </div>
            </div>
            <div
              style={{ gridRow: "4/5", gridColumn: "17/25",display:"flex"}}>
              
              <Checkbox
                checked={props.ClinicalTermNursing?.disc_nursing2?.items[2]?.checked}
                onChange={props.handleCheckValue("disc_nursing2.items.2.checked")}
                style={{marginTop:"5px"}}>
              </Checkbox>
              <div
                style={{marginTop:"3px",marginLeft:"10px"}}>
                {props.ClinicalTermNursing?.disc_nursing2?.items[2]?.name}
              </div>
            </div>
            <div
              style={{ gridRow: "4/5", gridColumn: "26/35",display:"flex"}}>
              
              <Checkbox
                checked={props.ClinicalTermNursing?.disc_nursing2?.items[3]?.checked}
                onChange={props.handleCheckValue("disc_nursing2.items.3.checked")}
                style={{marginTop:"5px"}}>
              </Checkbox>
              <div
                style={{marginTop:"3px",marginLeft:"10px"}}>
                {props.ClinicalTermNursing?.disc_nursing2?.items[3]?.name}
              </div>
            </div>
            <div
              style={{ gridRow: "6/7", gridColumn: "1/4",display:"flex"}}>
              
              <Checkbox
                checked={props.ClinicalTermNursing?.disc_nursing2?.items[4]?.checked}
                onChange={props.handleCheckValue("disc_nursing2.items.4.checked")}
                style={{marginTop:"5px"}}>
              </Checkbox>
              <div
                style={{marginTop:"3px",marginLeft:"10px"}}>
                อื่น ๆ
              </div>
            </div>
            <div
              style={{ gridRow: "6/7", gridColumn: "5/6",}}>
              ระบุ
            </div>
            <div
              style={{ gridRow: "6/7", gridColumn: "7/16",}}>
              
              <Input
                onChange={props.handleChangeValue("disc_nursing2.items.4.answer")}
                type="text"
                value={props.ClinicalTermNursing?.disc_nursing2?.items[4]?.answer}>
              </Input>
            </div>
          </div>
          <div>
            
            <div
              style={{ height:"40px", fontWeight: "bold"}}>
              {`3. ${props.ClinicalTermNursing?.disc_nursing3?.name}`}
            </div>
            <div
              style={{ gridRow: "8/9", gridColumn: "2/10"}}>
              
              <div
                className="ui form">
                {props.discNursing3}
              </div>
              <Button
                color="blue"
                onClick={props.onAddDiscNursing3}>
                เพิ่ม
              </Button>
            </div>
          </div>
          <div
            style={{"width":"100%","height":"100%","display":"grid","position":"relative","gridTemplateRows":"repeat(13, 1fr)","gridTemplateColumns":"repeat(50, 1fr)"}}>
            <div
              style={{ gridRow: "1/2", gridColumn: "1/18",fontWeight: "bold"}}>
              {`4. ${props.ClinicalTermNursing?.disc_nursing4?.name}`}
            </div>
            <div
              style={{ gridRow: "2/3", gridColumn: "1/8", display: "flex" }}>
              
              <div
                style={{ fontWeight: "bold", padding: "5px" }}>
                วันที่
              </div>
              <Label>
                {props.dischargePlanning?.ended_date || '-'}
              </Label>
            </div>
            <div
              style={{ gridRow: "2/3", gridColumn: "9/15", display: "flex"}}>
              
              <div
                style={{ fontWeight: "bold", padding: "5px" }}>
                เวลา
              </div>
              <Label>
                {props.dischargePlanning?.ended_time || '-'}
              </Label>
            </div>
            <div
              style={{ gridRow: "2/3", gridColumn: "16/30", display: "flex"}}>
              
              <div
                style={{ fontWeight: "bold", padding: "5px" }}>
                จากหอผู่ป่วย
              </div>
              <Label>
                {props.dischargePlanning?.discharged_from || '-'}
              </Label>
            </div>
            <div
              style={{ gridRow: "3/4", gridColumn: "1/18",fontWeight: "bold"}}>
              {`5. ${props.ClinicalTermNursing?.disc_nursing5?.name}`}
            </div>
            <div
              style={{ gridRow: "4/8", gridColumn: "2/25"}}>
              
              <Table
                headers="Date,เวลานัด,เเพทย์,เเพทย์"
                minRows="3"
                showPagination={false}
                style={{height: "150px"}}>
              </Table>
            </div>
            <div
              style={{ gridRow: "9/10", gridColumn: "1/18",fontWeight: "bold"}}>
              {`6. ${props.ClinicalTermNursing?.disc_nursing6?.name}`}
            </div>
            <div
              style={{ gridRow: "10/11", gridColumn: "1/5",display:"flex"}}>
              
              <Checkbox
                checked={props.ClinicalTermNursing?.disc_nursing6?.items[0]?.checked}
                onChange={props.handleCheckValue("disc_nursing6.items.0.checked")}
                style={{marginTop:"5px"}}>
              </Checkbox>
              <div
                style={{marginTop:"3px",marginLeft:"10px"}}>
                {props.ClinicalTermNursing?.disc_nursing6?.items[0]?.name}
              </div>
            </div>
            <div
              style={{ gridRow: "10/11", gridColumn: "6/11",display:"flex"}}>
              
              <Checkbox
                checked={props.ClinicalTermNursing?.disc_nursing6?.items[1]?.checked}
                onChange={props.handleCheckValue("disc_nursing6.items.1.checked")}
                style={{marginTop:"5px"}}>
              </Checkbox>
              <div
                style={{marginTop:"3px",marginLeft:"10px"}}>
                {props.ClinicalTermNursing?.disc_nursing6?.items[1]?.name}
              </div>
            </div>
            <div
              style={{ gridRow: "10/11", gridColumn: "12/18",display:"flex"}}>
              
              <Checkbox
                checked={props.ClinicalTermNursing?.disc_nursing6?.items[2]?.checked}
                onChange={props.handleCheckValue("disc_nursing6.items.2.checked")}
                style={{marginTop:"5px"}}>
              </Checkbox>
              <div
                style={{marginTop:"3px",marginLeft:"10px"}}>
                {props.ClinicalTermNursing?.disc_nursing6?.items[2]?.name}
              </div>
            </div>
            <div
              style={{ gridRow: "10/11", gridColumn: "19/29",display:"flex"}}>
              
              <Checkbox
                checked={props.ClinicalTermNursing?.disc_nursing6?.items[3]?.checked}
                onChange={props.handleCheckValue("disc_nursing6.items.3.checked")}
                style={{marginTop:"5px"}}>
              </Checkbox>
              <div
                style={{marginTop:"3px",marginLeft:"10px"}}>
                {props.ClinicalTermNursing?.disc_nursing6?.items[3]?.name}
              </div>
            </div>
            <div
              style={{ gridRow: "11/12", gridColumn: "1/4",display:"flex"}}>
              
              <Checkbox
                checked={props.ClinicalTermNursing?.disc_nursing6?.items[4]?.checked}
                onChange={props.handleCheckValue("disc_nursing6.items.4.checked")}
                style={{marginTop:"5px"}}>
              </Checkbox>
              <div
                style={{marginTop:"3px",marginLeft:"10px"}}>
                {props.ClinicalTermNursing?.disc_nursing6?.items[4]?.name}
              </div>
            </div>
            <div
              style={{ gridRow: "11/12", gridColumn: "5/6",display:"flex"}}>
              ระบุ
            </div>
            <div
              style={{ gridRow: "11/12", gridColumn: "7/18",display:"flex"}}>
              
              <Input
                onChange={props.handleChangeValue("disc_nursing6.items.4.answer")}
                value={props.ClinicalTermNursing?.disc_nursing6?.items[4]?.answer}>
              </Input>
            </div>
            <div
              style={{ gridRow: "12/13", gridColumn: "1/18",fontWeight: "bold"}}>
              {`7. ${props.ClinicalTermNursing?.disc_nursing7?.name}`}
            </div>
            <div
              style={{ gridRow: "13/14", gridColumn: "2/19"}}>
              
              <Label>
              </Label>
            </div>
          </div>
        </div>
      </div>
    )
}


export default CardSummaryPlanningUX

export const screenPropsDefault = {"drugSearch":"[Drug Search Box]"}

/* Date Time : Thu Feb 01 2024 14:03:49 GMT+0700 (Indochina Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "props": {
        "style": {
          "type": "code",
          "value": "{padding: \"10px\"}"
        }
      },
      "seq": 0
    },
    {
      "from": null,
      "id": 88,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", justifyContent: \"space-between\"}"
        }
      },
      "seq": 2,
      "void": false
    },
    {
      "from": null,
      "id": 97,
      "name": "div",
      "parent": 88,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", alignItems: \"center\"}"
        }
      },
      "seq": 97,
      "void": false
    },
    {
      "from": null,
      "id": 98,
      "name": "div",
      "parent": 88,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\"}"
        }
      },
      "seq": 98,
      "void": false
    },
    {
      "from": null,
      "id": 99,
      "name": "div",
      "parent": 97,
      "props": {
        "children": {
          "type": "value",
          "value": "NURSING DISCHARGE PLANNING SUMMARY"
        },
        "className": {
          "type": "value",
          "value": "ui label pink ribbon"
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 99,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 101,
      "name": "Label",
      "parent": 98,
      "props": {
        "children": {
          "type": "value",
          "value": "Draft"
        },
        "className": {
          "type": "value",
          "value": ""
        },
        "color": {
          "type": "value",
          "value": "pink"
        },
        "name": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: props.showStatus ? \"\" : \"none\"}"
        }
      },
      "seq": 101,
      "void": false
    },
    {
      "from": null,
      "id": 102,
      "name": "div",
      "parent": 98,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{cursor: \"pointer\", margin: \"-5px -5px 0 15px\", display: props.closeIcon ? \"\" : \"none\"}"
        }
      },
      "seq": 102,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 103,
      "name": "Icon",
      "parent": 102,
      "props": {
        "className": {
          "type": "value",
          "value": "red large"
        },
        "name": {
          "type": "code",
          "value": "\"close\""
        },
        "onClick": {
          "type": "code",
          "value": "props.onClose"
        }
      },
      "seq": 103,
      "void": false
    },
    {
      "from": null,
      "id": 104,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{paddingTop:\"10px\"}"
        }
      },
      "seq": 104,
      "void": false
    },
    {
      "from": null,
      "id": 105,
      "label": "divgrid",
      "name": "div",
      "parent": 104,
      "props": {
        "style": {
          "type": "code",
          "value": "{\"width\":\"100%\",\"height\":\"100%\",\"display\":\"grid\",\"position\":\"relative\",\"gridTemplateRows\":\"repeat(6, 1fr)\",\"gridTemplateColumns\":\"repeat(50, 1fr)\"}"
        }
      },
      "seq": 105,
      "void": false
    },
    {
      "from": null,
      "id": 108,
      "name": "div",
      "parent": 105,
      "props": {
        "children": {
          "type": "code",
          "value": "`1. ${props.ClinicalTermNursing?.disc_nursing1?.name}`"
        },
        "className": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: \"1/2\", gridColumn: \"1/15\",fontWeight: \"bold\"}"
        }
      },
      "seq": 108,
      "void": false
    },
    {
      "from": null,
      "id": 109,
      "name": "div",
      "parent": 105,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: \"2/3\", gridColumn: \"1/15\",fontWeight: \"bold\"}"
        }
      },
      "seq": 109,
      "void": false
    },
    {
      "from": null,
      "id": 110,
      "name": "div",
      "parent": 105,
      "props": {
        "children": {
          "type": "code",
          "value": "`2. ${props.ClinicalTermNursing?.disc_nursing2?.name}`"
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: \"3/4\", gridColumn: \"1/15\",fontWeight: \"bold\"}"
        }
      },
      "seq": 110,
      "void": false
    },
    {
      "from": null,
      "id": 111,
      "name": "div",
      "parent": 105,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: \"4/5\", gridColumn: \"1/10\",display:\"flex\"}"
        }
      },
      "seq": 115,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 112,
      "name": "Label",
      "parent": 109,
      "props": {
        "children": {
          "type": "code",
          "value": "props.dischargePlanning?.predischarge_condition || '-'"
        }
      },
      "seq": 112,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 113,
      "name": "Checkbox",
      "parent": 111,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.ClinicalTermNursing?.disc_nursing2?.items[0]?.checked"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleCheckValue(\"disc_nursing2.items.0.checked\")"
        },
        "style": {
          "type": "code",
          "value": "{marginTop:\"5px\"}"
        }
      },
      "seq": 113,
      "void": false
    },
    {
      "from": null,
      "id": 114,
      "name": "div",
      "parent": 111,
      "props": {
        "children": {
          "type": "code",
          "value": "props.ClinicalTermNursing?.disc_nursing2?.items[0]?.name"
        },
        "style": {
          "type": "code",
          "value": "{marginTop:\"3px\",marginLeft:\"10px\"}"
        }
      },
      "seq": 114,
      "void": false
    },
    {
      "from": null,
      "id": 115,
      "name": "div",
      "parent": 105,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: \"3/4\", gridColumn: \"17/23\",display:\"flex\"}"
        }
      },
      "seq": 111,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 116,
      "name": "Radio",
      "parent": 115,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.ClinicalTermNursing?.disc_nursing1?.has_problem === \"N\""
        },
        "label": {
          "type": "value",
          "value": "ไม่มี"
        },
        "name": {
          "type": "value",
          "value": "has_problem"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeValue(\"disc_nursing1.has_problem\")"
        },
        "style": {
          "type": "code",
          "value": "{ marginRight: \"20px\" }"
        },
        "value": {
          "type": "value",
          "value": "N"
        }
      },
      "seq": 116,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 117,
      "name": "Radio",
      "parent": 115,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.ClinicalTermNursing?.disc_nursing1?.has_problem === \"Y\""
        },
        "label": {
          "type": "value",
          "value": "มี"
        },
        "name": {
          "type": "value",
          "value": "has_problem"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeValue(\"disc_nursing1.has_problem\")"
        },
        "style": {
          "type": "code",
          "value": "{ marginRight: \"20px\" }"
        },
        "value": {
          "type": "value",
          "value": "Y"
        }
      },
      "seq": 117,
      "void": false
    },
    {
      "from": null,
      "id": 118,
      "name": "div",
      "parent": 105,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: \"6/7\", gridColumn: \"1/4\",display:\"flex\"}"
        }
      },
      "seq": 121,
      "void": false
    },
    {
      "from": null,
      "id": 119,
      "name": "div",
      "parent": 105,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: \"4/5\", gridColumn: \"11/16\",display:\"flex\"}"
        }
      },
      "seq": 118,
      "void": false
    },
    {
      "from": null,
      "id": 120,
      "name": "div",
      "parent": 105,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: \"4/5\", gridColumn: \"17/25\",display:\"flex\"}"
        }
      },
      "seq": 119,
      "void": false
    },
    {
      "from": null,
      "id": 121,
      "name": "div",
      "parent": 105,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: \"4/5\", gridColumn: \"26/35\",display:\"flex\"}"
        }
      },
      "seq": 120,
      "void": false
    },
    {
      "from": null,
      "id": 122,
      "name": "div",
      "parent": 105,
      "props": {
        "children": {
          "type": "value",
          "value": "ระบุ"
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: \"6/7\", gridColumn: \"5/6\",}"
        }
      },
      "seq": 122,
      "void": false
    },
    {
      "from": null,
      "id": 123,
      "name": "div",
      "parent": 105,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: \"6/7\", gridColumn: \"7/16\",}"
        }
      },
      "seq": 123,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 124,
      "name": "Checkbox",
      "parent": 118,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.ClinicalTermNursing?.disc_nursing2?.items[4]?.checked"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleCheckValue(\"disc_nursing2.items.4.checked\")"
        },
        "style": {
          "type": "code",
          "value": "{marginTop:\"5px\"}"
        }
      },
      "seq": 124,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 125,
      "name": "Checkbox",
      "parent": 119,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.ClinicalTermNursing?.disc_nursing2?.items[1]?.checked"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleCheckValue(\"disc_nursing2.items.1.checked\")"
        },
        "style": {
          "type": "code",
          "value": "{marginTop:\"5px\"}"
        }
      },
      "seq": 125,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 126,
      "name": "Checkbox",
      "parent": 120,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.ClinicalTermNursing?.disc_nursing2?.items[2]?.checked"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleCheckValue(\"disc_nursing2.items.2.checked\")"
        },
        "style": {
          "type": "code",
          "value": "{marginTop:\"5px\"}"
        }
      },
      "seq": 126,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 127,
      "name": "Checkbox",
      "parent": 121,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.ClinicalTermNursing?.disc_nursing2?.items[3]?.checked"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleCheckValue(\"disc_nursing2.items.3.checked\")"
        },
        "style": {
          "type": "code",
          "value": "{marginTop:\"5px\"}"
        }
      },
      "seq": 127,
      "void": false
    },
    {
      "from": null,
      "id": 128,
      "name": "div",
      "parent": 118,
      "props": {
        "children": {
          "type": "value",
          "value": "อื่น ๆ"
        },
        "style": {
          "type": "code",
          "value": "{marginTop:\"3px\",marginLeft:\"10px\"}"
        }
      },
      "seq": 128,
      "void": false
    },
    {
      "from": null,
      "id": 129,
      "name": "div",
      "parent": 119,
      "props": {
        "children": {
          "type": "code",
          "value": "props.ClinicalTermNursing?.disc_nursing2?.items[1]?.name"
        },
        "style": {
          "type": "code",
          "value": "{marginTop:\"3px\",marginLeft:\"10px\"}"
        }
      },
      "seq": 129,
      "void": false
    },
    {
      "from": null,
      "id": 130,
      "name": "div",
      "parent": 120,
      "props": {
        "children": {
          "type": "code",
          "value": "props.ClinicalTermNursing?.disc_nursing2?.items[2]?.name"
        },
        "style": {
          "type": "code",
          "value": "{marginTop:\"3px\",marginLeft:\"10px\"}"
        }
      },
      "seq": 130,
      "void": false
    },
    {
      "from": null,
      "id": 131,
      "name": "div",
      "parent": 121,
      "props": {
        "children": {
          "type": "code",
          "value": "props.ClinicalTermNursing?.disc_nursing2?.items[3]?.name"
        },
        "style": {
          "type": "code",
          "value": "{marginTop:\"3px\",marginLeft:\"10px\"}"
        }
      },
      "seq": 131,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 132,
      "name": "Input",
      "parent": 123,
      "props": {
        "onChange": {
          "type": "code",
          "value": "props.handleChangeValue(\"disc_nursing2.items.4.answer\")"
        },
        "type": {
          "type": "value",
          "value": "text"
        },
        "value": {
          "type": "code",
          "value": "props.ClinicalTermNursing?.disc_nursing2?.items[4]?.answer"
        }
      },
      "seq": 132,
      "void": false
    },
    {
      "from": null,
      "id": 133,
      "name": "div",
      "parent": 173,
      "props": {
        "children": {
          "type": "code",
          "value": "`3. ${props.ClinicalTermNursing?.disc_nursing3?.name}`"
        },
        "style": {
          "type": "code",
          "value": "{ height:\"40px\", fontWeight: \"bold\"}"
        }
      },
      "seq": 133,
      "void": false
    },
    {
      "from": null,
      "id": 134,
      "name": "div",
      "parent": 173,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: \"8/9\", gridColumn: \"2/10\"}"
        }
      },
      "seq": 134,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 135,
      "name": "Button",
      "parent": 134,
      "props": {
        "children": {
          "type": "value",
          "value": "เพิ่ม"
        },
        "color": {
          "type": "value",
          "value": "blue"
        },
        "onClick": {
          "type": "code",
          "value": "props.onAddDiscNursing3"
        }
      },
      "seq": 171,
      "void": false
    },
    {
      "from": null,
      "id": 136,
      "name": "div",
      "parent": 172,
      "props": {
        "children": {
          "type": "code",
          "value": "`4. ${props.ClinicalTermNursing?.disc_nursing4?.name}`"
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: \"1/2\", gridColumn: \"1/18\",fontWeight: \"bold\"}"
        }
      },
      "seq": 136,
      "void": false
    },
    {
      "from": null,
      "id": 137,
      "name": "div",
      "parent": 138,
      "props": {
        "children": {
          "type": "value",
          "value": "วันที่"
        },
        "style": {
          "type": "code",
          "value": "{ fontWeight: \"bold\", padding: \"5px\" }"
        }
      },
      "seq": 137,
      "void": false
    },
    {
      "from": null,
      "id": 138,
      "name": "div",
      "parent": 172,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: \"2/3\", gridColumn: \"1/8\", display: \"flex\" }"
        }
      },
      "seq": 138,
      "void": false
    },
    {
      "from": null,
      "id": 139,
      "name": "div",
      "parent": 140,
      "props": {
        "children": {
          "type": "value",
          "value": "เวลา"
        },
        "style": {
          "type": "code",
          "value": "{ fontWeight: \"bold\", padding: \"5px\" }"
        }
      },
      "seq": 139,
      "void": false
    },
    {
      "from": null,
      "id": 140,
      "name": "div",
      "parent": 172,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: \"2/3\", gridColumn: \"9/15\", display: \"flex\"}"
        }
      },
      "seq": 140,
      "void": false
    },
    {
      "from": null,
      "id": 141,
      "name": "div",
      "parent": 144,
      "props": {
        "children": {
          "type": "value",
          "value": "จากหอผู่ป่วย"
        },
        "style": {
          "type": "code",
          "value": "{ fontWeight: \"bold\", padding: \"5px\" }"
        }
      },
      "seq": 141,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 142,
      "name": "Label",
      "parent": 138,
      "props": {
        "children": {
          "type": "code",
          "value": "props.dischargePlanning?.ended_date || '-'"
        }
      },
      "seq": 142,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 143,
      "name": "Label",
      "parent": 140,
      "props": {
        "children": {
          "type": "code",
          "value": "props.dischargePlanning?.ended_time || '-'"
        }
      },
      "seq": 143,
      "void": false
    },
    {
      "from": null,
      "id": 144,
      "name": "div",
      "parent": 172,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: \"2/3\", gridColumn: \"16/30\", display: \"flex\"}"
        }
      },
      "seq": 144,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 145,
      "name": "Label",
      "parent": 144,
      "props": {
        "children": {
          "type": "code",
          "value": "props.dischargePlanning?.discharged_from || '-'"
        }
      },
      "seq": 145,
      "void": false
    },
    {
      "from": null,
      "id": 146,
      "name": "div",
      "parent": 172,
      "props": {
        "children": {
          "type": "code",
          "value": "`5. ${props.ClinicalTermNursing?.disc_nursing5?.name}`"
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: \"3/4\", gridColumn: \"1/18\",fontWeight: \"bold\"}"
        }
      },
      "seq": 146,
      "void": false
    },
    {
      "from": null,
      "id": 147,
      "name": "div",
      "parent": 172,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: \"4/8\", gridColumn: \"2/25\"}"
        }
      },
      "seq": 147,
      "void": false
    },
    {
      "from": "react-lib/frameworks/Table",
      "id": 148,
      "name": "Table",
      "parent": 147,
      "props": {
        "headers": {
          "type": "value",
          "value": "Date,เวลานัด,เเพทย์,เเพทย์"
        },
        "minRows": {
          "type": "value",
          "value": "3"
        },
        "showPagination": {
          "type": "code",
          "value": "false"
        },
        "style": {
          "type": "code",
          "value": "{height: \"150px\"}"
        }
      },
      "seq": 148,
      "void": false
    },
    {
      "from": null,
      "id": 149,
      "name": "div",
      "parent": 172,
      "props": {
        "children": {
          "type": "code",
          "value": "`6. ${props.ClinicalTermNursing?.disc_nursing6?.name}`"
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: \"9/10\", gridColumn: \"1/18\",fontWeight: \"bold\"}"
        }
      },
      "seq": 149,
      "void": false
    },
    {
      "from": null,
      "id": 150,
      "name": "div",
      "parent": 172,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: \"10/11\", gridColumn: \"1/5\",display:\"flex\"}"
        }
      },
      "seq": 150,
      "void": false
    },
    {
      "from": null,
      "id": 151,
      "name": "div",
      "parent": 172,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: \"10/11\", gridColumn: \"6/11\",display:\"flex\"}"
        }
      },
      "seq": 151,
      "void": false
    },
    {
      "from": null,
      "id": 152,
      "name": "div",
      "parent": 172,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: \"10/11\", gridColumn: \"12/18\",display:\"flex\"}"
        }
      },
      "seq": 152,
      "void": false
    },
    {
      "from": null,
      "id": 153,
      "name": "div",
      "parent": 172,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: \"10/11\", gridColumn: \"19/29\",display:\"flex\"}"
        }
      },
      "seq": 153,
      "void": false
    },
    {
      "from": null,
      "id": 154,
      "name": "div",
      "parent": 172,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: \"11/12\", gridColumn: \"1/4\",display:\"flex\"}"
        }
      },
      "seq": 154,
      "void": false
    },
    {
      "from": null,
      "id": 155,
      "name": "div",
      "parent": 172,
      "props": {
        "children": {
          "type": "value",
          "value": "ระบุ"
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: \"11/12\", gridColumn: \"5/6\",display:\"flex\"}"
        }
      },
      "seq": 155,
      "void": false
    },
    {
      "from": null,
      "id": 156,
      "name": "div",
      "parent": 172,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: \"11/12\", gridColumn: \"7/18\",display:\"flex\"}"
        }
      },
      "seq": 156,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 157,
      "name": "Checkbox",
      "parent": 150,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.ClinicalTermNursing?.disc_nursing6?.items[0]?.checked"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleCheckValue(\"disc_nursing6.items.0.checked\")"
        },
        "style": {
          "type": "code",
          "value": "{marginTop:\"5px\"}"
        }
      },
      "seq": 157,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 158,
      "name": "Checkbox",
      "parent": 151,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.ClinicalTermNursing?.disc_nursing6?.items[1]?.checked"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleCheckValue(\"disc_nursing6.items.1.checked\")"
        },
        "style": {
          "type": "code",
          "value": "{marginTop:\"5px\"}"
        }
      },
      "seq": 158,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 159,
      "name": "Checkbox",
      "parent": 152,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.ClinicalTermNursing?.disc_nursing6?.items[2]?.checked"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleCheckValue(\"disc_nursing6.items.2.checked\")"
        },
        "style": {
          "type": "code",
          "value": "{marginTop:\"5px\"}"
        }
      },
      "seq": 159,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 160,
      "name": "Checkbox",
      "parent": 153,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.ClinicalTermNursing?.disc_nursing6?.items[3]?.checked"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleCheckValue(\"disc_nursing6.items.3.checked\")"
        },
        "style": {
          "type": "code",
          "value": "{marginTop:\"5px\"}"
        }
      },
      "seq": 160,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 161,
      "name": "Checkbox",
      "parent": 154,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.ClinicalTermNursing?.disc_nursing6?.items[4]?.checked"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleCheckValue(\"disc_nursing6.items.4.checked\")"
        },
        "style": {
          "type": "code",
          "value": "{marginTop:\"5px\"}"
        }
      },
      "seq": 161,
      "void": false
    },
    {
      "from": null,
      "id": 162,
      "name": "div",
      "parent": 150,
      "props": {
        "children": {
          "type": "code",
          "value": "props.ClinicalTermNursing?.disc_nursing6?.items[0]?.name"
        },
        "style": {
          "type": "code",
          "value": "{marginTop:\"3px\",marginLeft:\"10px\"}"
        }
      },
      "seq": 162,
      "void": false
    },
    {
      "from": null,
      "id": 163,
      "name": "div",
      "parent": 151,
      "props": {
        "children": {
          "type": "code",
          "value": "props.ClinicalTermNursing?.disc_nursing6?.items[1]?.name"
        },
        "style": {
          "type": "code",
          "value": "{marginTop:\"3px\",marginLeft:\"10px\"}"
        }
      },
      "seq": 163,
      "void": false
    },
    {
      "from": null,
      "id": 164,
      "name": "div",
      "parent": 152,
      "props": {
        "children": {
          "type": "code",
          "value": "props.ClinicalTermNursing?.disc_nursing6?.items[2]?.name"
        },
        "style": {
          "type": "code",
          "value": "{marginTop:\"3px\",marginLeft:\"10px\"}"
        }
      },
      "seq": 164,
      "void": false
    },
    {
      "from": null,
      "id": 165,
      "name": "div",
      "parent": 153,
      "props": {
        "children": {
          "type": "code",
          "value": "props.ClinicalTermNursing?.disc_nursing6?.items[3]?.name"
        },
        "style": {
          "type": "code",
          "value": "{marginTop:\"3px\",marginLeft:\"10px\"}"
        }
      },
      "seq": 165,
      "void": false
    },
    {
      "from": null,
      "id": 166,
      "name": "div",
      "parent": 154,
      "props": {
        "children": {
          "type": "code",
          "value": "props.ClinicalTermNursing?.disc_nursing6?.items[4]?.name"
        },
        "style": {
          "type": "code",
          "value": "{marginTop:\"3px\",marginLeft:\"10px\"}"
        }
      },
      "seq": 166,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 167,
      "name": "Input",
      "parent": 156,
      "props": {
        "onChange": {
          "type": "code",
          "value": "props.handleChangeValue(\"disc_nursing6.items.4.answer\")"
        },
        "value": {
          "type": "code",
          "value": "props.ClinicalTermNursing?.disc_nursing6?.items[4]?.answer"
        }
      },
      "seq": 167,
      "void": false
    },
    {
      "from": null,
      "id": 168,
      "name": "div",
      "parent": 172,
      "props": {
        "children": {
          "type": "code",
          "value": "`7. ${props.ClinicalTermNursing?.disc_nursing7?.name}`"
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: \"12/13\", gridColumn: \"1/18\",fontWeight: \"bold\"}"
        }
      },
      "seq": 168,
      "void": false
    },
    {
      "from": null,
      "id": 169,
      "name": "div",
      "parent": 172,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: \"13/14\", gridColumn: \"2/19\"}"
        }
      },
      "seq": 169,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 170,
      "name": "Label",
      "parent": 169,
      "props": {
      },
      "seq": 170,
      "void": false
    },
    {
      "from": null,
      "id": 171,
      "name": "div",
      "parent": 134,
      "props": {
        "children": {
          "type": "code",
          "value": "props.discNursing3"
        },
        "className": {
          "type": "value",
          "value": "ui form"
        }
      },
      "seq": 135,
      "void": false
    },
    {
      "from": null,
      "id": 172,
      "label": "divgrid",
      "name": "div",
      "parent": 104,
      "props": {
        "style": {
          "type": "code",
          "value": "{\"width\":\"100%\",\"height\":\"100%\",\"display\":\"grid\",\"position\":\"relative\",\"gridTemplateRows\":\"repeat(13, 1fr)\",\"gridTemplateColumns\":\"repeat(50, 1fr)\"}"
        }
      },
      "seq": 173,
      "void": false
    },
    {
      "from": null,
      "id": 173,
      "name": "div",
      "parent": 104,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 172,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 80,
  "isMounted": false,
  "memo": false,
  "name": "CardSummaryPlanningUX",
  "project": "IsHealth",
  "screenPropsDefault": {
    "drugSearch": "[Drug Search Box]"
  },
  "width": 80
}

*********************************************************************************** */
