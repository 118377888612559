import React, {
  ChangeEvent,
  useRef,
  useState,
  useEffect,
  forwardRef,
} from "react";
import PropTypes from "prop-types";
import * as Common from "react-lib/apps/common";
import { Button, Grid, Segment, Form } from "semantic-ui-react";
import PureReactTable, {
  alignCenter,
} from "react-lib/apps/common/PureReactTable";
import * as CAgent from "react-lib/apps/common/CAgent";
// import Zoom from "../../../_qmljs/Common/Zoom";
import { printURL } from "react-lib/utils/printURL";
import {
  ModConfirm as ModConfirmClose,
  ModConfirm as ModConfirmRemove,
  ModConfirm as ModConfirmSave,
} from "react-lib/apps/common";

// Config
import CONFIG from "config/config";
import ButtonLoadCheck from "react-lib/appcon/common/ButtonLoadCheck";

//! This is ModScanner in QML except this card does not appear in modal
const CardScanner = forwardRef((props: any, ref) => {
  const [buttonState, setButtonState] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [openModError, setOpenModError] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [scanData, setScanData] = useState<any[]>([]);
  const [image, setImage] = useState("");
  const [openModConfirmClose, setOpenModConfirmClose] = useState(false);
  const [openModConfirmRemove, setOpenModConfirmRemove] = useState(false);
  const [openModConfirmSave, setOpenModConfirmSave] = useState(false);
  const [modConfirmRemoveText, setModConfirmRemoveText] = useState(null);
  const [divisionOptions, setDivisionOptions] = useState([]);
  const [openModSuccess, setOpenModSuccess] = useState(false);
  const [modErrorTitle, setModErrorTitle] = useState("แจ้งเตือน");

  const dataId = useRef(0);
  const isMounted = useRef(false);
  const subScannerFormRef = useRef();
  const inputRef = useRef<any>();

  // const zoomRef = useRef();

  useEffect(() => {
    isMounted.current = true;
    if (!props.patientId && !props.encounterId) {
      props.onNoPatient();
      props.onClose();
    }

    const getDivision = async () => {
      const [data, error] = await props.controller.getDivision({
        limit: "99999",
      });
      if (isMounted.current) {
        if (data) {
          setDivisionOptions(data);
        } else {
          setDivisionOptions([]);
        }
      }
    };

    getDivision();
    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    if (["SUCCESS", "ERROR"].includes(buttonState)) {
      setTimeout(() => {
        setButtonState("");
      }, 2000);
    }
  }, [buttonState]);

  useEffect(() => {
    if (selectedRow) {
      setImage("data:image/jpeg;base64," + (selectedRow as any).document_image);
    } else {
      setImage("");
    }
  }, [selectedRow]);

  const handleScanClicked = () => {
    if (CONFIG.SCAN_VIA_FILE_BROWSE) {
      return inputRef.current.click();
    }

    setIsLoading(true);
    console.log(props.django);
    // CAgent.setMockupScannedImage(props.django); //! Only for dev, comment before commit
    CAgent.checkCAgentScanListAvailable(
      () => scanList(),
      () => scan()
    );
  };

  const getDocumentType = async ({ category, name }: any = {}) => {
    setError(null);
    const [data, error] = await props.controller.getDocumentType({
      limit: "99999",
      category,
      name,
      division:
        props.django && props.django.division
          ? props.django.division.id
          : "current",
    });
    return [data, error];
  };

  const scanList = () => {
    // * Single image
    // * CAgent.scan(function(base64imageList) {

    // * List image
    CAgent.scanList(handleScanList, function (error: any) {
      setIsLoading(false);
      setError(error);
      setModErrorTitle("แจ้งเตือน");
      setOpenModError(true);
    });
  };

  const handleScanList = (base64imageList: any[]) => {
    let lists: any[] = [...scanData];

    for (let i = 0; i < base64imageList.length; i++) {
      let base64image = base64imageList[i];
      let prefix = "data:image/jpeg;base64,";
      if (base64image.startsWith(prefix)) {
        base64image = base64image.slice(prefix.length);
      }
      addList(base64image, i + 1, lists);
      setScanData(lists);
      showPreview(base64image);
      props.scanCompleted(base64image);
    }
    setIsLoading(false);
    (subScannerFormRef as any).current.clearForm();
  };

  const addList = (base64image: any, i: any, lists?: any[]) => {
    let row = (subScannerFormRef as any).current.fillRow();
    row.document_image = base64image;
    row.code = "";
    row.id = "fakeId" + dataId.current;
    if (i !== -1) {
      row.page_number = i;
    }

    if (lists) {
      lists.push(row);
    } else {
      let newArr: any[] = [...scanData];

      newArr.push(row);

      setScanData(newArr as any);
    }

    dataId.current = dataId.current + 1;
  };

  const showPreview = (base64image: any) => {
    if (!base64image) {
      setImage("");
    }
    setImage("data:image/jpeg;base64," + base64image);
  };

  const scan = () => {
    CAgent.scan(
      function (base64image: any) {
        var prefix = "data:text/xml;base64,";
        if (base64image.startsWith(prefix)) {
          base64image = base64image.slice(prefix.length);
        }
        addList(base64image, -1);
        showPreview(base64image);
        props.scanCompleted(base64image);
        setIsLoading(false);
        (subScannerFormRef as any).current.clearForm();
      },
      function (error: any) {
        setIsLoading(false);
        setError(error);
        setModErrorTitle("แจ้งเตือน");
        setOpenModError(true);
      },
      props.django
    );
  };

  const handleClose = () => {
    if (scanData.length === 0) {
      return props.onClose();
    }
    setOpenModConfirmClose(true);
  };

  const handleRemoveClick = ({ row }: any) => {
    setOpenModConfirmRemove(true);
    setModConfirmRemoveText(
      `ต้องการลบ ${row.original.document_type_name} หมายเลข ${
        row.index + 1
      } ใช่หรือไม่` as any
    );
  };

  const handleRemoveItem = () => {
    let newArr = scanData.filter(
      (item: any) => item.id !== (selectedRow as any).id
    );
    setScanData(newArr);
    setOpenModConfirmRemove(false);
    setSelectedRow(null);
    (subScannerFormRef as any).current.clearForm();
  };

  const handleEditItem = () => {
    let newRow = (subScannerFormRef as any).current.fillRow();
    let cpSelectedRow = { ...(selectedRow as any), ...newRow };
    (subScannerFormRef as any).current.clearForm();
    let index = scanData.findIndex((item: any) => item.id === cpSelectedRow.id);
    let newArr: any[] = [...scanData];
    newArr[index] = cpSelectedRow;
    setSelectedRow(null);
    setScanData(newArr as any);
  };

  const handleSaveItem = () => {
    // * Save Each Item
    setOpenModConfirmSave(false);
    let data = [...scanData];
    let promiseArr = data.map((item: any) => {
      let newObj = { ...item };
      if (newObj.id && newObj.id.includes("fakeId")) {
        delete newObj["id"];
      }
      newObj.patient = props.patientId;
      newObj.encounter = props.encounterId;
      if (!newObj.version) {
        newObj.version = 0;
      }
      if (!newObj.owner_division) {
        newObj.owner_division = 0;
      }
      let returnFuc = save({ saveData: newObj });
      return returnFuc;
    });

    setButtonState("LOADING");
    setIsLoading(true);

    Promise.allSettled(promiseArr).then((result) => {
      let success = result.filter((item) => item.status === "fulfilled");
      let fail = result.filter((item) => item.status === "rejected");
      if (success.length === promiseArr.length) {
        setIsLoading(false);
        // setOpenModSuccess(true);
        setButtonState("SUCCESS");
      } else {
        setIsLoading(false);
        let reason = fail.filter((item: any) =>
          item.reason.hasOwnProperty("owner_division")
        );
        setButtonState("ERROR");
        if (reason.length > 0) {
          setError("กรุณาระบุ 'แผนกเจ้าของเอกสาร'" as any);
          setModErrorTitle("ไม่สามารถบันทึกเอกสารได้");
        } else {
          setError("กรุณาตรวจสอบข้อมูลเอกสาร" as any);
        }
        setOpenModError(true);
      }
    });
  };

  const save = async ({ saveData }: any = {}) => {
    const response = await props.controller.postScannedDocument({
      data: saveData,
    });
    return response;
  };

  const toBase64 = (file: File): Promise<string> =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () =>
        resolve((reader.result as string)?.replace(/^.*,/, ""));
      reader.onerror = () => reject(new Error("Error reading file"));
    });

  const handleFileInputChange = async (e: ChangeEvent<HTMLInputElement>) => {
    const files = e.target?.files;
    if (files?.length) {
      const promises = [...files].map((file) => toBase64(file));

      try {
        const base64Strings = await Promise.all(promises);

        handleScanList(base64Strings);

        inputRef.current.value = "";
      } catch (error: any) {
        console.error(`Error converting files to base64: ${error.message}`);
      }
    }
  };

  const handleModConfirmClose = () => {
    setImage("");
    setScanData([]);
    setSelectedRow(null);
    setOpenModConfirmClose(false);
    (subScannerFormRef as any).current.clearForm();

    props.onClose();
  };

  return (
    <>
      <ModConfirmSave
        className="fixNotMiddleModal"
        testid="modConfirmSave"
        titleColor="red"
        openModal={openModConfirmSave}
        content={<div>ไม่ต้องการสแกนเอกสารเพิ่มแล้วใช่หรือไม่</div>}
        onDeny={() => setOpenModConfirmSave(false)}
        onApprove={handleSaveItem}
        onCloseWithDimmerClick={() => setOpenModConfirmSave(false)}
      />
      <ModConfirmRemove
        className="fixNotMiddleModal"
        testid="modConfirmRemove"
        titleColor="red"
        openModal={openModConfirmRemove}
        content={<div>{modConfirmRemoveText}</div>}
        onDeny={() => setOpenModConfirmRemove(false)}
        onApprove={handleRemoveItem}
        onCloseWithDimmerClick={() => setOpenModConfirmRemove(false)}
      />
      <ModConfirmClose
        className="fixNotMiddleModal"
        testid="modConfirmClose"
        openModal={openModConfirmClose}
        titleColor="red"
        content={<div>มีเอกสารที่สแกนอยู่ ปิดหน้าจอหรือไม่</div>}
        onDeny={() => setOpenModConfirmClose(false)}
        onApprove={handleModConfirmClose}
        onCloseWithDimmerClick={() => setOpenModConfirmClose(false)}
      />
      <Common.ModInfo
        className="fixNotMiddleModal"
        // @ts-ignore
        testid="modError"
        open={openModError}
        titleColor="red"
        titleName={modErrorTitle}
        alertText={error}
        onApprove={() => setOpenModError(false)}
        onCloseWithDimmerClick={() => setOpenModError(false)}
      />
      <Common.ModInfo
        className="fixNotMiddleModal"
        // @ts-ignore
        testid="modSuccess"
        open={openModSuccess}
        titleColor="green"
        titleName="บันทึกสำเร็จ"
        onApprove={() => {
          setOpenModSuccess(false);
          props.onClose();
        }}
        onCloseWithDimmerClick={() => setOpenModSuccess(false)}
      />
      <Common.CardLayout
        titleText="สแกนเอกสาร"
        loading={isLoading}
        loadingText="กำลังสแกน"
        onClose={handleClose}
        headerColor="yellow"
        toggleable={props.toggleable}
        closeable={props.closeable}
      >
        {/* <Zoom ref={zoomRef as any} /> */}
        <Common.PatientIdentification patientInfo={props.patientInfo} />
        <Grid columns={2}>
          <Grid.Column>
            <Segment color="grey" inverted style={styles.segment}>
              <img
                // @ts-ignore
                testid="imgScanned"
                style={styles.image}
                src={image}
                onClick={(e) => {
                  // (zoomRef as any).current.qml.show(image, e);
                }}
              />
            </Segment>
          </Grid.Column>
          <Grid.Column style={{ paddingLeft: "0px" }}>
            <Common.SubScannerForm
              ref={subScannerFormRef}
              // @ts-ignore
              onScanClicked={handleScanClicked}
              onGetDocumentTypeOptions={({ searchText }: any = {}) =>
                getDocumentType({ name: searchText })
              }
              data={selectedRow}
              cancelEditClicked={() => {
                setSelectedRow(null);
                (subScannerFormRef as any).current.clearForm();
              }}
              onEditClicked={handleEditItem}
              divisionOptions={divisionOptions}
              defaultDocName={props.defaultDocName}
            />
            <PureReactTable
              testid="scannedDocTable"
              className=""
              selectedClassName="blueSelectedRow"
              style={styles.scannedDocTable}
              showPagination={false}
              showPageSizeOptions={false}
              minRows={5}
              data={scanData}
              onSelect={(originalRow: any) => {
                setSelectedRow(originalRow);
                setImage(
                  "data:image/jpeg;base64," + originalRow.document_image
                );
              }}
              selectedRow={selectedRow}
              columns={[
                {
                  Header: "NO.",
                  accessor: "number",
                  width: 50,
                  Cell: (row: any) => {
                    return alignCenter(row.index + 1);
                  },
                },
                {
                  Header: "ชื่อเอกสาร",
                  accessor: "document_type_name",
                },
                {
                  Header: "วันที่",
                  accessor: "scan_date",
                },
                {
                  Header: "เลขที่เอกสาร",
                  accessor: "document_no",
                },
                {
                  Header: "พิมพ์",
                  accessor: "_print",
                  Cell: (row: any) => {
                    return alignCenter(
                      <Button
                        color="teal"
                        icon="print"
                        onClick={() => {
                          let prefix = "data:image/jpeg;base64,";
                          printURL(prefix + row.original.document_image);
                        }}
                      />
                    );
                  },
                },
                {
                  Header: "ลบ",
                  accessor: "remove",
                  minWidth: 50,
                  Cell: (row: any) => {
                    return alignCenter(
                      <Button
                        color="red"
                        icon="remove"
                        onClick={() => handleRemoveClick({ row })}
                      />
                    );
                  },
                },
              ]}
            />
            <br />
            <Form>
              <Form.Group inline className="rightAlign">
                <Form.Field>
                  <Button color="red" content="ยกเลิก" onClick={handleClose} />
                </Form.Field>
                <Form.Field>
                  <ButtonLoadCheck
                    // function
                    id="btn-confirmData"
                    setProp={props.setProp}
                    onClick={() => setOpenModConfirmSave(true)}
                    // data
                    buttonLoadCheck={buttonState}
                    color="green"
                    title="บันทึก"
                  />
                </Form.Field>
              </Form.Group>
            </Form>
          </Grid.Column>
        </Grid>

        <input
          ref={inputRef}
          type="file"
          id="fileInput"
          accept=".jpg"
          multiple
          hidden
          onChange={handleFileInputChange}
        />
      </Common.CardLayout>
    </>
  );
});

const styles = {
  scannedDocTable: {
    height: "350px",
  },
  segment: {
    height: "100%",
    overflowY: "auto",
    display: "flex",
    justifyContent: "center",
  },
  image: { cursor: "zoom-in", height: "400px" },
};

CardScanner.propTypes = {
  patientData: PropTypes.object,
  patientInfo: PropTypes.object,
  controller: PropTypes.object,
  onClose: PropTypes.func,
  patientId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  onNoPatient: PropTypes.func,
  django: PropTypes.object,
  scanCompleted: PropTypes.func,
  encounterId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  closeable: PropTypes.bool,
  toggleable: PropTypes.bool,
};

CardScanner.defaultProps = {
  patientData: {},
  patientInfo: {},
  controller: {},
  django: {},
  onClose: () => {},
  patientId: null,
  encounterId: null,
  onNoPatient: () => {},
  scanCompleted: () => {},
  closeable: true,
  toggleable: false,
};

export default React.memo(CardScanner);
