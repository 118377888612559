import React, { useState, useEffect, useMemo } from "react";

// UX
import CardClaimTaxDocumentUX from "./CardClaimTaxDocumentUX";

// Interface
import { State } from "./sequence/ClaimTaxDocument";
import { Button, Dropdown, Form, FormField, FormGroup, Modal } from "semantic-ui-react";
import ButtonLoadCheck from "react-lib/appcon/common/ButtonLoadCheck";
import moment from "moment";
import { ErrorMessage } from "react-lib/apps/common";
// Util

// Common

// Props
type CardClaimTaxDocumentProps = {
  errorMessage: any;
  buttonLoadCheck: any;
  onEvent: (e: any) => any;
  setProp: any;
  runSequence: any;
  // Sequence
  ClaimTaxDocumentSequence?: State["ClaimTaxDocumentSequence"];
  masterOptions?: Record<string, any>;
};

const CARD_CLAIM_TAX = "CardClaimTaxDocument";

const toDate = moment();
const YEAR_OPTION = Array.from({ length: 111 }, (_, i) => {
  let yearValue = Number(toDate.format("YYYY")) + 543 - i;
  return { value: yearValue, key: yearValue, text: yearValue };
});

export const MONTH_OPTION = [
  { value: 1, key: 1, text: "มกราคม" },
  { value: 2, key: 2, text: "กุมภาพันธ์" },
  { value: 3, key: 3, text: "มีนาคม" },
  { value: 4, key: 4, text: "เมษายน" },
  { value: 5, key: 5, text: "พฤษภาคม" },
  { value: 6, key: 6, text: "มิถุนายน" },
  { value: 7, key: 7, text: "กรกฎาคม" },
  { value: 8, key: 8, text: "สิงหาคม" },
  { value: 9, key: 9, text: "กันยายน" },
  { value: 10, key: 10, text: "ตุลาคม" },
  { value: 11, key: 11, text: "พฤศจิกายน" },
  { value: 12, key: 12, text: "ธันวาคม" },
];

const BUTTON_ACTIONS = {
  PREVIEW: "PREVIEW",
  PRINT: "PRINT",
  XLSX: "XLSX",
} as const;

const CardClaimTaxDocument = (props: CardClaimTaxDocumentProps) => {
  const [type, setType] = useState<string>("");
  const [year, setYear] = useState<number>(Number(toDate.format("YYYY")) + 543);
  const [months, setMonths] = useState<string>("");
  const [endMonths, setEndMonths] = useState<string>("");
  const [doctor, setDoctor] = useState<any>("");

  // Use Effect
  useEffect(() => {
    props.runSequence({
      sequence: "ClaimTaxDocument",
      restart: true,
    });
  }, []);

  const reportShow = (type: any) => {
    setType(type);
  };

  const handleCloseModal = () => {
    setType("");
    setYear(Number(toDate.format("YYYY")) + 543);
    setMonths("");
    setEndMonths("");
    setDoctor("");
  };

  const handlePrintReport = (pdf: boolean) => (e: any, data: any) => {
    const isOneMonthType = ["ภ.ง.ด. 3", "ภ.ง.ด. 1", "ใบแนบ ภ.ง.ด. 3", "ใบแนบ ภ.ง.ด. 1"].includes(type);
    const isTwoMonthType = ["หนังสือรับรองการหักภาษี ณ ที่จ่าย", "ใบแนบหนังสือรับรองการหักภาษี ณ ที่จ่าย"].includes(type);
    const isMonthEmpty = months === "";
    const isEndMonthEmpty = endMonths === "";

    if (isTwoMonthType && (isMonthEmpty || isEndMonthEmpty)) {
      alert("กรุณาระบุเดือนเริ่มต้น และเดือนสิ้นสุดให้ครบถ้วน");
    } else if (isOneMonthType && isMonthEmpty) {
      alert("กรุณาระบุเดือนให้ครบถ้วน");
    } else {
      props.runSequence({
        sequence: "ClaimTaxDocument",
        action: "PRINT_REPORT",
        card: CARD_CLAIM_TAX,
        year: year,
        months: months,
        type: type,
        name: data.name,
        pdf,
        ...(["หนังสือรับรองการหักภาษี ณ ที่จ่าย", "ใบแนบหนังสือรับรองการหักภาษี ณ ที่จ่าย"].includes(
          type
        ) && {
          startMonths: months || 0,
          endMonths: endMonths || 0,
          doctor: doctor || "",
        }),
      });
    }
  };

  const doctorOptions = useMemo(() => {
    return [
      { key: "ALL", value: "ALL", text: "แพทย์ทั้งหมด" },
      ...(props.masterOptions?.doctor || []),
    ];
  }, [props.masterOptions?.doctor]);

  return (
    <>
      <CardClaimTaxDocumentUX reportShow={reportShow} />

      {/* Modal */}

      <Modal open={!!type} onClose={() => handleCloseModal()}>
        <div style={{ margin: "15px" }}>
          <ErrorMessage error={props.errorMessage?.[CARD_CLAIM_TAX]} />
          <div style={{ marginBottom: "15px", fontWeight: "bold" }}>{type}</div>

          {/* line 0 */}
          <div>
            <Form>
              <FormGroup>
                <FormField width={4}>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <label style={{ fontWeight: "bold", marginRight: "15px" }}>
                      <div style={{ display: "flex" }}>
                        <div>ปี</div>
                        <div style={{ color: "red" }}>*</div>
                      </div>
                    </label>
                    <Dropdown
                      fluid={true}
                      selection={true}
                      options={YEAR_OPTION}
                      style={{ width: "100%", margin: "0px 10px" }}
                      value={year}
                      onChange={(e: any, data: any) => setYear(data.value)}
                    ></Dropdown>
                  </div>
                </FormField>
                <FormField width={4}>
                  {[
                    "ภ.ง.ด. 3",
                    "หนังสือรับรองการหักภาษี ณ ที่จ่าย",
                    "ภ.ง.ด. 1",
                    "ใบแนบ ภ.ง.ด. 3",
                    "ใบแนบหนังสือรับรองการหักภาษี ณ ที่จ่าย",
                    "ใบแนบ ภ.ง.ด. 1",
                  ].includes(type) && (
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <label style={{ fontWeight: "bold", marginRight: "15px" }}>
                        {[
                          "หนังสือรับรองการหักภาษี ณ ที่จ่าย",
                          "ใบแนบหนังสือรับรองการหักภาษี ณ ที่จ่าย",
                        ].includes(type)
                          ? "ตั้งแต่เดือน"
                          : "เดือน"}
                      </label>
                      <Dropdown
                        fluid={true}
                        selection={true}
                        options={MONTH_OPTION}
                        style={{ width: "50%", margin: "0px 10px" }}
                        value={months}
                        onChange={(e: any, data: any) => setMonths(data.value)}
                        clearable={true}
                      ></Dropdown>
                    </div>
                  )}
                </FormField>
                <FormField width={4}>
                  {[
                    "หนังสือรับรองการหักภาษี ณ ที่จ่าย",
                    "ใบแนบหนังสือรับรองการหักภาษี ณ ที่จ่าย",
                  ].includes(type) && (
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <label style={{ fontWeight: "bold", marginRight: "15px" }}>ถึง</label>
                      <Dropdown
                        fluid={true}
                        selection={true}
                        options={MONTH_OPTION}
                        style={{ width: "50%", margin: "0px 10px" }}
                        value={endMonths}
                        onChange={(e: any, data: any) => setEndMonths(data.value)}
                        clearable={true}
                      ></Dropdown>
                    </div>
                  )}
                </FormField>
                <FormField width={4}>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <ButtonLoadCheck
                      // function
                      setProp={props.setProp}
                      onClick={handlePrintReport(true)}
                      // data
                      paramKey={`${CARD_CLAIM_TAX}_${BUTTON_ACTIONS.PREVIEW}`}
                      buttonLoadCheck={
                        props.buttonLoadCheck?.[`${CARD_CLAIM_TAX}_${BUTTON_ACTIONS.PREVIEW}`]
                      }
                      // config
                      name={BUTTON_ACTIONS.PREVIEW}
                      title="Preview"
                      color="yellow"
                      style={{ width: "100%" }}
                    />
                  </div>
                </FormField>
              </FormGroup>
              <FormGroup>
                <FormField width={12}>
                  {["หนังสือรับรองการหักภาษี ณ ที่จ่าย"].includes(type) && (
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <label style={{ fontWeight: "bold", marginRight: "15px" }}>ชื่อแพทย์</label>
                      <Dropdown
                        fluid={true}
                        selection={true}
                        options={doctorOptions}
                        style={{ width: "50%", margin: "0px 10px" }}
                        value={doctor}
                        onChange={(e: any, data: any) => setDoctor(data.value)}
                        clearable={true}
                        search={true}
                      ></Dropdown>
                    </div>
                  )}
                </FormField>
                <FormField width={4}>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <ButtonLoadCheck
                      // function
                      setProp={props.setProp}
                      onClick={handlePrintReport(false)}
                      // data
                      paramKey={`${CARD_CLAIM_TAX}_${BUTTON_ACTIONS.PRINT}`}
                      buttonLoadCheck={
                        props.buttonLoadCheck?.[`${CARD_CLAIM_TAX}_${BUTTON_ACTIONS.PRINT}`]
                      }
                      // config
                      name={BUTTON_ACTIONS.PRINT}
                      title="พิมพ์"
                      color="blue"
                      style={{ width: "100%" }}
                    />
                  </div>
                </FormField>
              </FormGroup>
              {type === "ใบแนบ ภ.ง.ด. 1" && (
                <FormGroup>
                  <FormField width={12}></FormField>
                  <FormField width={4}>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <ButtonLoadCheck
                        // function
                        setProp={props.setProp}
                        onClick={handlePrintReport(false)}
                        // data
                        paramKey={`${CARD_CLAIM_TAX}_${BUTTON_ACTIONS.XLSX}`}
                        buttonLoadCheck={
                          props.buttonLoadCheck?.[`${CARD_CLAIM_TAX}_${BUTTON_ACTIONS.XLSX}`]
                        }
                        // config
                        name={BUTTON_ACTIONS.XLSX}
                        title="ดาวน์โหลด xlsx."
                        color="green"
                        style={{ width: "100%" }}
                      />
                    </div>
                  </FormField>
                </FormGroup>
              )}
            </Form>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default CardClaimTaxDocument;
