
/* Generated apis ---------------------- */
import { to } from 'react-lib/utils'
import BaseService from  'react-lib/apis/services/BaseService'
import config from '../../config/config'
import IPDContinueByEMRSerializer from '../types/IPDContinueByEMRSerializer_apps_DPI'


const HOST = `${config.API_HOST}`
/* ['generics.RetrieveAPIView'] */
/* params: 'nurse_status', 'flag_treatment_order', 'flag_note_order', 'flag_pharmacy', 'flag_lab', 'flag_food', 'date', 'start_date', 'end_date', 'offset', 'order_by', 'order_perform_by', 'disable_pagination' */
/* data: 'plan_item', 'plan_item' */
const IPDContinueDayByEMR : 
{
  retrieve: ({pk, params, apiToken, extra}:
             { pk: any, 
                params?: any,
                apiToken?: any,
                extra?: any,
            }) => any,
} = 
{
  retrieve: async ({pk, params, apiToken, extra}) => {
    const base = new BaseService()
    if (apiToken)
      base.client.defaults.headers["Authorization"] = "Token " + apiToken;
    if (extra?.division)
      base.client.defaults.headers["division"] = extra.division;
    if (extra?.device)
      base.client.defaults.headers["device"] = extra.device;
    const result = await to(base.client.get(`${HOST}/apis/DPI/ipd-continue/emr/${pk}/`,
      { params: params ? { ...params } : {},
         ...extra
      }
    )
    .then(base.handleResponse)
    .catch(base.throwErrorMessage))
    return [result[1], result[0], result[2]]
  },
}

export default IPDContinueDayByEMR

