import React, {
  useEffect,
  useMemo,
  useState,
  useRef,
  MutableRefObject,
} from "react";

// UX
import CardDrugOrder from "react-lib/apps/HISV3/TPD/CardDrugOrder";
import CardAppointmentDetail from "./CardAppointmentDetail";
import { PreventClickEvent } from "../HISV3/ORM/CardORRequest";

// Common
import SnackMessage from "../common/SnackMessage";

// Interface
import {
  APPOINTMENT_CANCEL_OPTIONS,
  APPOINTMENT_POSTPONE_OPTIONS,
} from "./SchedulingInterface";

const DOCTOR_ORDER_FILTER_UNSHOWN = [
  "operatingorder",
  "drugoperatingorder",
  "admitorder",
  "anesthesiaorder",
];

const CARD_OTHER_APPOINTMENT = "CardOtherOrderAppointment";

const CardOtherOrderAppointment = (props: any) => {
  const [isEditable, setIsEditable] = useState<any>(null);
  const [openModOrder, setOpenModOrder] = useState<{
    id?: number;
    type?: string;
  } | null>(null);

  const otherRef = useRef() as MutableRefObject<HTMLDivElement>;

  useEffect(() => {
    setIsEditable(
      !props.config || props.config.isEditable === undefined
        ? true
        : props.config?.isEditable
    );
  }, [props.config]);

  const editable = useMemo(() => {
    const isEdit: boolean =
      props.ORRequestSequence?.allowed_action?.includes("EDIT_REQUEST");

    return isEdit;
  }, [otherRef, props.ORRequestSequence?.allowed_action]);

  useEffect(() => {
    if (otherRef.current && props.ORRequestSequence?.allowed_action) {
      PreventClickEvent(editable, otherRef.current);
    }
  }, [editable]);

  useEffect(() => {
    const disabled = props.isEditable === undefined ? false : !props.isEditable;

    if (!disabled && editable && props.defaultModOrder) {
      handleOpenModOrder(null, { name: props.defaultModOrder });
      props.setModOrder?.("");
    }
  }, [editable, isEditable, props.defaultModOrder]);

  const handleSaveAppointment = (e: any) => {
    console.log(
      "handleSaveAppointment props.selectedAppointment: ",
      props.selectedAppointment
    );

    if (
      props.selectedAppointment?.orders?.find(
        (item: any) => item?.type === "operatingorder"
      )
    ) {
      props.onEvent({
        message: "SetScheduling",
        params: {
          action: "SaveAppointment",
          isAppointment: props.selectedOperatingOption
            ? props.selectedOperatingOption === "EL"
            : true,
          card: CARD_OTHER_APPOINTMENT,
        },
      });
    } else {
      // toggle
      console.log("handleSaveAppointment setTab or_request");
      props.setTab("or_request");
    }
  };

  const handleOpenModOrder = (e: any, v: any) => {
    setOpenModOrder({ type: v.name });
  };

  console.log("CardOtherOrderAppointment props", props);
  // console.log("CardOtherOrderAppointment, props.drugOrder", props.drugOrder);
  // console.log('CardOtherOrderAppointment props.preOrderList?.find((item: any) => item.type === "drugoperatingorder")?.id: ', props.preOrderList?.find((item: any) => item.type === "drugoperatingorder")?.id);
  return (
    <div id="CardOtherOrderAppointment" ref={otherRef}>
      <CardDrugOrder
        disabledConfirmedOrderBtn={true}
        hiddenDrugList={true}
        disabledVerbalMedOrder={true}
        // drugOperatingOrderId={props.preOrderList?.filter((item: any) => item.type === "drugoperatingorder")?.map((item: any) => item.id)?.join(",")}

        preOrderList={props.preOrderList}
        orderTypes={["OPERATING"]}
        title={"ยาสำหรับผ่าตัด"}
        selectedAppointment={props.selectedAppointment}
        onEvent={props.onEvent}
        setProp={props.setProp}
        runSequence={props.runSequence}
        controller={props.controller}
        errorMessage={props.errorMessage}
        successMessage={props.successMessage}
        selectedEncounter={props.selectedEncounter}
        selectedEmr={props.selectedEmr}
        selectedProgressCycle={props.selectedProgressCycle}
        // selectedDrugItem to edit
        selectedDrugItemDetail={props.selectedDrugItemDetail}
        selectedDrugItemDetailOptions={props.selectedDrugItemDetailOptions}
        drugSearchText={props.drugSearchText}
        drugSearchResult={props.drugSearchResult}
        DrugSelectSequence={props.DrugSelectSequence}
        selectedDrug={props.selectedDrug}
        drugOrderList={props.drugOrderList}
        drugOrderListLoading={props.drugOrderListLoading}
        drugOrder={props.drugOrder}
        renderDrugOrderItems={props.renderDrugOrderItems}
        drugDose={props.drugDose}
        drugQty={props.drugQty}
        drugDuration={props.drugDuration}
        drugDescription={props.drugDescription}
        drugPrn={props.drugPrn}
        // modal doctor certificate check
        modDoctorCertificate={props.modDoctorCertificate}
        onCertificateApprove={handleSaveAppointment}
        modDrugLabInteraction={props.modDrugLabInteraction}
        modDrugDisease={props.modDrugDisease}
        modDrugInteraction={props.modDrugInteraction}
        currentDoctor={props.currentDoctor}
        // modal drug order template
        DrugOrderTemplateSequence={props.DrugOrderTemplateSequence}
        modReMedication={props.modReMedication}
        modConfirmDeleteDrug={props.modConfirmDeleteDrug}
        drugOrderItemEstimate={props.drugOrderItemEstimate}
        drugPermission={props.drugPermission}
        searchedItemList={props.searchedItemList}
        drugOrderItemMasterData={{
          masterUnits: props.masterOptions.unit,
          masterRoutes: props.masterOptions.route,
          masterSites: props.masterOptions.site,
          masterFrequencies: props.masterOptions.frequency,
          masterMethods: props.masterOptions.method,
        }}
        duplicateReasonsOptions={props.masterOptions.duplicateReasons}
        divisionForOrderDivOptions={props.masterOptions.divisionForOrderDiv}
        // SolventSelectionConfig
        modSolventSelectionConfig={props.modSolventSelectionConfig}
        selectedVerbalDrugOrderWorking={props.selectedVerbalDrugOrderWorking}
        // isEditable={isEditable}
        isEditable={true} // case hidden drug list
        defaultOrderBy={props.defaultOrderBy}
        drugSearchLoading={props.drugSearchLoading}
        searchedItemListWithKey={props.searchedItemListWithKey}
        masterOptions={props.masterOptions}
      />

      <div style={{ marginBottom: "-2rem" }} />

      <CardAppointmentDetail
        setProp={props.setProp}
        onEvent={props.onEvent}
        // controller
        proxyController={props.proxyController}
        // seq
        runSequence={props.runSequence}
        // data
        card={CARD_OTHER_APPOINTMENT}
        availableSlots={props.availableSlots}
        blockList={props.blockList}
        preOrderList={props.preOrderList}
        openModOrder={openModOrder}
        doctorOrderFilter={DOCTOR_ORDER_FILTER_UNSHOWN}
        // treatment order
        OrderTreatmentSequence={props.OrderTreatmentSequence}
        procedureItems={props.procedureItems}
        secondaryItems={props.secondaryItems}
        treatmentTemplateList={props.treatmentTemplateList}
        treatmentOrderList={props.treatmentOrderList}
        // imaging order
        ImagingOrderSequence={props.ImagingOrderSequence}
        modXrayDetail={props.modXrayDetail}
        clinicalFindingId={props.clinicalFindingId}
        clinicalFindingIndex={props.clinicalFindingIndex}
        clinicalFindingList={props.clinicalFindingList}
        organ={props.organ}
        clinicaltags={props.clinicaltags}
        filterClinicalFindingIds={props.filterClinicalFindingIds}
        // lab order
        selectedEncounter={props.selectedEncounter}
        selectedEmr={props.selectedEmr}
        selectedProgressCycle={props.selectedProgressCycle}
        OrderCentralLabSequence={props.OrderCentralLabSequence}
        userLabPermission={props.userLabPermission}
        doctorLabOrderList={props.doctorLabOrderList}
        // CommonInterface
        userTokenize={props.userTokenize}
        selectedAppointment={props.selectedAppointment}
        // selectedPatient={props.selectedPatient}
        loadingStatus={props.loadingStatus}
        buttonLoadCheck={props.buttonLoadCheck}
        errorMessage={props.errorMessage}
        successMessage={props.successMessage}
        searchedItemList={props.searchedItemList}
        searchedItemListWithKey={props.searchedItemListWithKey}
        // options
        masterOptions={props.masterOptions}
        cancelOptions={APPOINTMENT_CANCEL_OPTIONS}
        postponeOptions={APPOINTMENT_POSTPONE_OPTIONS}
        // config
        showOrderAppointment={props.showOrderAppointment}
        hideDrugAllergy={props.hideDrugAllergy}
        hideGeneralDetail={true}
        useNoteTemplate={true}
        isEditable={isEditable}
        hideButtonSave={!editable}
        hideConfirmButton={true}
        isAppointment={
          props.selectedOperatingOption ? props.selectedOperatingOption === "EL" : true
        }
        hidePrintAppointmentDoc={props.hidePrintAppointmentDoc}
        config={{
          ...props.config,
          hideDoctorCancel: true,
          hidePatientCancel: true,
          disabledPostpone: true,
        }}
        toggleOrRequest={
          !props.selectedAppointment?.orders?.find((item: any) => item?.type === "operatingorder")
        }
        // callback
        setTab={props.setTab}
        setOpenModOrder={setOpenModOrder}
      />

      <SnackMessage
        onEvent={props.onEvent}
        onClose={() => {
          props.setProp(`errorMessage.${CARD_OTHER_APPOINTMENT}`, null);
        }}
        error={
          props.errorMessage?.[CARD_OTHER_APPOINTMENT]?.message
            ? props.errorMessage?.[CARD_OTHER_APPOINTMENT]?.message
            : props.errorMessage?.[CARD_OTHER_APPOINTMENT]
        }
        success={null}
      />
    </div>
  );
};

export default React.memo(CardOtherOrderAppointment);
