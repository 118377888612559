import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  Button
} from 'semantic-ui-react'
import {
  Table
} from 'react-lib/frameworks/Table'
import {
  DateTextBox
} from 'react-lib/apps/common'

const CardNurseNoteUX = (props: any) => {

    const isMounted = React.useRef(true);
    const intl = useIntl();

    React.useEffect(() => {
        return () => {
            isMounted.current = false;
        };
    }, []);


    return(
      <div
        style={{ width: "100%", height: "100%", padding: "5px" }}>
        <div
          style={{ display: "flex", justifyContent: "flex-end", marginBottom: "10px" }}>
          <DateTextBox
            onChange={props.onSetStartDate}
            placeholder="วันที่เริ่ม"
            style={{paddingRight: "10px"}}
            value={props.selectedStartDate}>
          </DateTextBox>
          <DateTextBox
            onChange={props.onSetEndDate}
            placeholder="วันที่จบ"
            style={{paddingRight: "10px"}}
            value={props.selectedEndDate}>
          </DateTextBox>
          <Button
            color="blue"
            loading={props.nurseNoteLoading}
            onClick={props.nurseNoteSearch}>
            ค้นหา
          </Button>
          <Button
            color="blue"
            onClick={props.openModalNote}>
            เพิ่ม
          </Button>
          <Button
            className="icon"
            color="blue"
            disabled={!props.selectedNurseNote?.id}
            icon="print"
            loading={props.loadingPrint || false}
            onClick={props.handleNurseNoteReport}>
          </Button>
        </div>
        <div>
          <Table
            data={props.nurseNoteList}
            getTrProps={props.onSelectedRow}
            headers="วันที่และเวลาที่บันทึก,ผู้บันทึก,Focus,Progress Note A:Assessment I:Intervention E:Evaluation,"
            keys="formatted_date,nurse,focus,progress_note,_action"
            minRows="15"
            showPagination={false}
            widths="200,200,,,100">
          </Table>
        </div>
      </div>
    )
}


export default CardNurseNoteUX

export const screenPropsDefault = {}

/* Date Time : Mon Feb 12 2024 14:02:05 GMT+0700 (Indochina Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "props": {
        "style": {
          "type": "code",
          "value": "{ width: \"100%\", height: \"100%\", padding: \"5px\" }"
        }
      },
      "seq": 0
    },
    {
      "from": null,
      "id": 1,
      "name": "div",
      "parent": 0,
      "props": {
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", justifyContent: \"flex-end\", marginBottom: \"10px\" }"
        }
      },
      "seq": 1,
      "void": false
    },
    {
      "from": null,
      "id": 2,
      "name": "div",
      "parent": 0,
      "props": {
      },
      "seq": 2,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 3,
      "name": "Button",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": "เพิ่ม"
        },
        "color": {
          "type": "value",
          "value": "blue"
        },
        "onClick": {
          "type": "code",
          "value": "props.openModalNote"
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 11,
      "void": false
    },
    {
      "from": "react-lib/frameworks/Table",
      "id": 6,
      "name": "Table",
      "parent": 2,
      "props": {
        "columns": {
          "type": "code",
          "value": ""
        },
        "data": {
          "type": "code",
          "value": "props.nurseNoteList"
        },
        "defaultPageSize": {
          "type": "value",
          "value": ""
        },
        "getTrProps": {
          "type": "code",
          "value": "props.onSelectedRow"
        },
        "headers": {
          "type": "value",
          "value": "วันที่และเวลาที่บันทึก,ผู้บันทึก,Focus,Progress Note A:Assessment I:Intervention E:Evaluation,"
        },
        "keys": {
          "type": "value",
          "value": "formatted_date,nurse,focus,progress_note,_action"
        },
        "minRows": {
          "type": "value",
          "value": "15"
        },
        "showPagination": {
          "type": "code",
          "value": "false"
        },
        "widths": {
          "type": "value",
          "value": "200,200,,,100"
        }
      },
      "seq": 6,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 7,
      "name": "Button",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": "ค้นหา"
        },
        "color": {
          "type": "value",
          "value": "blue"
        },
        "loading": {
          "type": "code",
          "value": "props.nurseNoteLoading"
        },
        "onClick": {
          "type": "code",
          "value": "props.nurseNoteSearch"
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 10,
      "void": false
    },
    {
      "from": "react-lib/apps/common",
      "id": 11,
      "name": "DateTextBox",
      "parent": 1,
      "props": {
        "onChange": {
          "type": "code",
          "value": "props.onSetStartDate"
        },
        "placeholder": {
          "type": "value",
          "value": "วันที่เริ่ม"
        },
        "style": {
          "type": "code",
          "value": "{paddingRight: \"10px\"}"
        },
        "value": {
          "type": "code",
          "value": "props.selectedStartDate"
        }
      },
      "seq": 3,
      "void": false
    },
    {
      "from": "react-lib/apps/common",
      "id": 12,
      "name": "DateTextBox",
      "parent": 1,
      "props": {
        "onChange": {
          "type": "code",
          "value": "props.onSetEndDate"
        },
        "placeholder": {
          "type": "value",
          "value": "วันที่จบ"
        },
        "style": {
          "type": "code",
          "value": "{paddingRight: \"10px\"}"
        },
        "value": {
          "type": "code",
          "value": "props.selectedEndDate"
        }
      },
      "seq": 7,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 13,
      "name": "Button",
      "parent": 1,
      "props": {
        "className": {
          "type": "value",
          "value": "icon"
        },
        "color": {
          "type": "value",
          "value": "blue"
        },
        "disabled": {
          "type": "code",
          "value": "!props.selectedNurseNote?.id"
        },
        "icon": {
          "type": "value",
          "value": "print"
        },
        "loading": {
          "type": "code",
          "value": "props.loadingPrint || false"
        },
        "onClick": {
          "type": "code",
          "value": "props.handleNurseNoteReport"
        }
      },
      "seq": 13,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 80,
  "isMounted": true,
  "memo": false,
  "name": "CardNurseNoteUX",
  "project": "IsHealth",
  "screenPropsDefault": {
  },
  "width": 75
}

*********************************************************************************** */
