import React, { useEffect, useMemo, useState } from "react";
import { Button, Grid, Icon, Modal } from "semantic-ui-react";
import SnackMessage from "react-lib/apps/common/SnackMessage";
import CardNurseNoteUX from "./CardNurseNoteUX";
import ModProgressNoteForm from "./ModProgressNoteFormUX";
import { ModAuthen } from "react-lib/apps/common";
import * as utils from "react-lib/utils";
import moment from "moment";

import ModMedReconcileAlert from "react-lib/apps/common/ModMedReconcileAlert";

const CARD_NURSE_NOTE = "cardNurseNote";
const CardNurseNote = (props: any) => {
  const [openModAuthen, setOpenModAuthen] = useState<boolean>(false);
  const [action, setAction] = useState<string>("");
  const [username, setUsername] = useState<string>("");
  const [password, setPassword] = useState<string>("");

  useEffect(() => {
    return () => {
      props.onEvent({
        message: "HandleNurseNote",
        params: {
          action: "clear",
        },
      });
    }
  }, [])

  useEffect(() => {
    props.onEvent({
      message: "HandleNurseNote",
      params: {
        action: "search",
        encounterId: props.selectedEncounter?.id,
        card: CARD_NURSE_NOTE,
      },
    });
  }, [props.selectedEncounter]);

  useEffect(() => {
    setOpenModAuthen(false);
  }, [props.openModNurseNoteForm]);

  const handleConfirmSaveNurseNote = ({ username, password }) => {
    props.onEvent({
      message: "HandleNurseNote",
      params: {
        action: "save",
        date: utils.formatDatetime(moment()).replace(" ", "-"),
        encounterId: props.selectedEncounter?.id,
        username,
        password,
        card: CARD_NURSE_NOTE,
      },
    });
  };

  const handleConfirmRemoveNurseNote = ({ username, password }) => {
    props.onEvent({
      message: "HandleNurseNote",
      params: {
        action: "remove",
        encounterId: props.selectedEncounter?.id,
        username,
        password,
        card: CARD_NURSE_NOTE,
      },
    });
  };

  const nurseNoteList = useMemo(() => {
    return (props.nurseNoteFilter?.nurseNoteList || []).map((item) => ({
      ...item,
      progress_note: (
        <div style={{ wordWrap: "break-word", padding: "2px" }}>
          <Grid divided="vertically">
            <Grid.Row columns={2}>
              <Grid.Column
                width={2}
                style={{ fontWeight: "bold", textAlign: "center" }}
              >
                A:
              </Grid.Column>
              <Grid.Column width={14}>{item.diagnosis}</Grid.Column>
            </Grid.Row>
            <Grid.Row columns={2}>
              <Grid.Column
                width={2}
                style={{ fontWeight: "bold", textAlign: "center" }}
              >
                I:
              </Grid.Column>
              <Grid.Column width={14}>{item.plan}</Grid.Column>
            </Grid.Row>
            <Grid.Row columns={2}>
              <Grid.Column
                width={2}
                style={{ fontWeight: "bold", textAlign: "center" }}
              >
                E:
              </Grid.Column>
              <Grid.Column width={14}>{item.goal}</Grid.Column>
            </Grid.Row>
          </Grid>
        </div>
      ),
      _action: (
        <>
          <Button
            color="yellow"
            icon="pencil"
            onClick={() => {
              setAction("edit");
              props.onEvent({
                message: "HandleNurseNote",
                params: {
                  action: "edit",
                  card: CARD_NURSE_NOTE,
                  selectedNurseNote: item,
                },
              });
            }}
          />
          <Button
            color="red"
            icon="trash"
            onClick={() => {
              props.setProp("selectedNurseNote", item);
              // setAction("remove");
              setOpenModAuthen(true);
            }}
          />
        </>
      ),
    }));
  }, [props.nurseNoteFilter?.nurseNoteList]);

  console.log("Card Nurse Note: ", props);
  return (
    <>
      <SnackMessage
        onEvent={props.onEvent}
        onClose={async () => {
          props.setProp(`errorMessage.${CARD_NURSE_NOTE}`, null);
          props.setProp(`successMessage.${CARD_NURSE_NOTE}`, null);
        }}
        error={props.errorMessage?.[CARD_NURSE_NOTE]}
        success={props.successMessage?.[CARD_NURSE_NOTE]}
      />
      <CardNurseNoteUX
        // data
        selectedStartDate={props.nurseNoteFilter?.startDate}
        selectedEndDate={props.nurseNoteFilter.endDate}
        nurseNoteList={nurseNoteList}
        loadingPrint={!!props.buttonLoadCheck?.[`${CARD_NURSE_NOTE}_PRINT`]}
        selectedNurseNote={props.selectedNurseNote}
        // function
        openModalNote={() => {
          setAction("add");
          props.onEvent({
            message: "HandleNurseNote",
            params: { action: "new", card: CARD_NURSE_NOTE },
          });
        }}
        nurseNoteLoading={props.nurseNoteLoading}
        nurseNoteSearch={() => {
          props.onEvent({
            message: "HandleNurseNote",
            params: {
              action: "search",
              card: CARD_NURSE_NOTE,
              encounterId: props.selectedEncounter?.id,
            },
          });
        }}
        onSetStartDate={(date: string) => {
          props.setProp("nurseNoteFilter.startDate", date);
        }}
        onSetEndDate={(date: string) => {
          props.setProp("nurseNoteFilter.endDate", date);
        }}
        handleNurseNoteReport={() => {
          props.onEvent({
            message: "handleNurseNoteReport",
            params: {
              card: CARD_NURSE_NOTE,
              encounter: props.selectedEncounter?.id,
            },
          });
        }}
        onSelectedRow={(state, row, column, instance) => {
          return {
            onClick: () => {
              if (row.original) {
                props.setProp("selectedNurseNote", row.original);
              }
            },
            className:
              row?.original && row?.original?.id === props.selectedNurseNote?.id
                ? "blueSelectedRow"
                : "",
            style: {
              cursor: "pointer",
            },
          };
        }}
      />
      <ModAuthen
        titleName={"ระบุ Username และ Password เพื่อลบ"}
        titleColor={"red"}
        open={openModAuthen}
        onDeny={() => setOpenModAuthen(false)}
        // error={props.authenError}
        onApprove={({ username, password }) => {
          handleConfirmRemoveNurseNote({ username, password });
          setOpenModAuthen(false);
        }}
      />

      <ModMedReconcileAlert
        onEvent={props.onEvent}
        setProp={props.setProp}
        divisionType={props.divisionType}
        django={props.django}
        medReconcileCheck={props.medReconcileCheck}
        medReconcileIndex={props.medReconcileIndex}
        open={props.medReconcileCheck?.open_card_med_reconciliation}
        selectedEncounter={props.selectedEncounter}
      />

      <Modal open={props.openModNurseNoteForm}>
        <ModProgressNoteForm
          closeIcon={
            <Icon
              name="cancel"
              color="red"
              size="large"
              style={{ margin: "-30px -30px 0px 0px", cursor: "pointer" }}
              onClick={() => {
                props.setProp("openModNurseNoteForm", false);
              }}
            />
          }
          dateCreate={utils.formatDatetime(moment())}
          username={username}
          password={password}
          onChangeUsername={(e: any) => {
            setUsername(e.target.value);
          }}
          onChangePassword={(e: any) => {
            setPassword(e.target.value);
          }}
          selectedNurseNote={props.selectedNurseNote}
          setProp={props.setProp}
          django={props.django}
          handleSaveNurseNote={() => {
            // setOpenModAuthen(true);
            if (action === "add" || action === "edit") {
              handleConfirmSaveNurseNote({ username, password });
            } else if (action === "remove") {
              handleConfirmRemoveNurseNote({ username, password });
            }
            setUsername("");
            setPassword("");
          }}
          nurseNoteFocusOptions={props.nurseNoteFocusOptions}
          onChangeDropdown={(e: any, data: any) => {
            props.onEvent({
              message: "HandleNurseNote",
              params: {
                action: "change_option",
                data: data.value,
                card: CARD_NURSE_NOTE,
              },
            });
          }}
          handleAddNurseNoteFocusOptions={(e: any, data: any) => {
            props.onEvent({
              message: "HandleNurseNote",
              params: {
                action: "add_option",
                data: data.value,
                card: CARD_NURSE_NOTE,
              },
            });
          }}
        />
      </Modal>
    </>
  );
};

export default CardNurseNote;
