import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  Form,
  FormGroup,
  Dropdown,
  TextArea,
  Checkbox
} from 'semantic-ui-react'
import {
  DateTextBox
} from 'react-lib/apps/common'

const CardTravelCertificateUX = (props: any) => {
    return(
      <div>
        <Form>
          <FormGroup
            className="ui form"
            style={{display: "flex", padding: "1em 0em 0em 1em", gap: "1em"}}>
            <label
              style={{display: "flex",  alignItems: "center", width: "5.4em"}}>
              Visit Type
            </label>
            <Dropdown
              disabled={props.onHandleDisableForm}
              id="CardTravelCertificate-Dropdown-VisitType"
              onChange={(e, {value}) => props.onHandleChangeVisitType(value)}
              options={props.onVisitTypeOption}
              selection={true}
              style={{minWidth: "11.8em"}}
              value={props.onVisitTypeValue}>
            </Dropdown>
            <label
              style={{display: "flex",  alignItems: "center"}}>
              วันที่
            </label>
            <DateTextBox
              disabled={props.onHandleDisableForm}
              id="CardTravelCertificate-DateTextBox-ExamDate"
              minDate={props.onMinDate}
              onChange={(date: string) => props.onHandleChangeDate("examDate", date)}
              style={{width: "12em"}}
              value={props.onExamDate}>
            </DateTextBox>
          </FormGroup>
          <FormGroup
            className="ui form"
            style={{display: "flex", padding: "0em 0em 0em 1em", gap: "1.5em"}}>
            <label
              style={{display: "flex",  alignItems: "center"}}>
              ผู้ป่วยสามารถเดินทางโดยอากาศยานได้ ตั้งแต่วันที่
            </label>
            <DateTextBox
              disabled={props.onHandleDisableForm}
              id="CardTravelCertificate-DateTextBox-FlightDate"
              onChange={(date: string) => props.onHandleChangeDate("flightDate", date)}
              style={{width: "12em"}}
              value={props.onFlightDate}>
            </DateTextBox>
          </FormGroup>
          <FormGroup
            className="ui form"
            style={{display: "flex", padding: "0em 0em 0em 1em", gap: "1.5em"}}>
            <label
              style={{fontWeight: "bold"}}>
              การวินิจฉัย
            </label>
            <TextArea
              disabled={props.onHandleDisableForm}
              id="CardTravelCertificate-TextArea-Diagnosis"
              onChange={(e) => props.onHandleChangeText("diagnosis", e)}
              style={{width: "27.5em"}}
              value={props.onDiagnosis}>
            </TextArea>
          </FormGroup>
          <FormGroup
            className="ui form"
            style={{display: "flex", padding: "0em 0em 0em 2em"}}>
          </FormGroup>
          <FormGroup
            className="ui form"
            style={{display: "flex", padding: "0em 0em 0em 1em"}}>
            <label
              style={{display: "flex",  alignItems: "center", fontWeight: "bold"}}>
              ข้อเสนอแนะ และการประเมินการเดินทาง
            </label>
          </FormGroup>
          <FormGroup
            className="ui form"
            style={{display: "flex", padding: "0em 0em 0em 3em"}}>
            <Checkbox
              checked={props.onRecommendOption("first")}
              disabled={props.onHandleDisableForm}
              label="สามารถเดินทางโดยอากาศยานได้เหมือนผู้โดยสารทั่วไป"
              onChange={(e) => props.onHandleChangeRecommend(e, "first")}>
            </Checkbox>
          </FormGroup>
          <FormGroup
            className="ui form"
            style={{display: "flex", padding: "0em 0em 0em 3em"}}>
            <Checkbox
              checked={props.onRecommendOption("second")}
              disabled={props.onHandleDisableForm}
              label="สามารถเดินทางโดยอากาศยานร่วมกับเพื่อน/ครอบครัว ที่ไม่ใช่บุคลากรทางแพทย์ได้"
              onChange={(e) => props.onHandleChangeRecommend(e, "second")}
              style={{width: "24.5em"}}>
            </Checkbox>
          </FormGroup>
          <FormGroup
            className="ui form"
            style={{display: "flex", padding: "0em 0em 0em 3em"}}>
            <Checkbox
              checked={props.onRecommendOption("third")}
              disabled={props.onHandleDisableForm}
              label="สามารถเดินทางโดยอากาศยานโดยต้องมีบุคลากรทางแพทย์ร่วมเดินทางด้วยเท่านั้น"
              onChange={(e) => props.onHandleChangeRecommend(e, "third")}
              style={{width: "26.5em"}}>
            </Checkbox>
          </FormGroup>
          <FormGroup
            className="ui form"
            style={{display: "flex", padding: "0em 0em 0em 3em"}}>
            <Checkbox
              checked={props.onRecommendOption("fourth")}
              disabled={props.onHandleDisableForm}
              label="ไม่เหมาะ สำหรับการเดินทางโดยอากาศยาน เนื่องจากปัจจัยความเสี่ยงของผู้ป่วย"
              onChange={(e) => props.onHandleChangeRecommend(e, "fourth")}
              style={{width: "21em"}}>
            </Checkbox>
          </FormGroup>
          <FormGroup
            className="ui form"
            style={{display: "flex", padding: "1em 0em 0em 1em", gap: "2em"}}>
            <label
              style={{display: "flex",  alignItems: "center", fontWeight: "bold", height: "36px", width: "7.3em"}}>
              ข้อกำหนดพิเศษ
            </label>
            <Dropdown
              allowAdditions={true}
              clearable={true}
              disabled={props.onHandleDisableForm}
              id="CardTravelCertificate-Dropdown-SpecialRequire"
              multiple={true}
              onAddItem={props.onHandleAddSpecialRequire}
              onChange={props.onHandleChangeSpecialRequire}
              options={props.onSpecialRequireOption}
              search={true}
              selection={true}
              style={{minWidth: "25em"}}
              value={props.onSpecialRequireValue}>
            </Dropdown>
          </FormGroup>
          <FormGroup
            className="ui form"
            style={{display: "flex", padding: "0em 0em 0em 1em"}}>
            <label
              style={{fontWeight: "bold"}}>
              ความคิดเห็น
            </label>
          </FormGroup>
          <FormGroup
            className="ui form"
            style={{display: "flex", padding: "0em 0em 0em 2em"}}>
            <TextArea
              disabled={props.onHandleDisableForm}
              id="CardTravelCertificate-TextArea-Comment"
              onChange={(e) => props.onHandleChangeText("comment", e)}
              style={{width: "30em"}}
              value={props.onComment}>
            </TextArea>
          </FormGroup>
          <FormGroup
            className="ui form"
            style={{display: "flex", justifyContent: "flex-end"}}>
            <label>
              {props.onCloseSelected}
            </label>
          </FormGroup>
        </Form>
      </div>
    )
}


export default CardTravelCertificateUX

export const screenPropsDefault = {"language":"TH"}

/* Date Time : Thu Aug 01 2024 16:43:17 GMT+0700 (Indochina Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "props": {
        "className": {
          "type": "value",
          "value": ""
        },
        "id": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 0
    },
    {
      "from": null,
      "id": 255,
      "name": "label",
      "parent": 255,
      "props": {
        "children": {
          "type": "value",
          "value": "หลังจากการติดเชื้อ COVID-19 : เมื่อหายป่วยตามอาการทางคลินิก แนะนำให้สวมหน้ากากอนามัย ล้างมือ เว้นระยะห่างทางสังคม และหลีกเลี่ยงสถานที่แออัด"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\",  alignItems: \"center\"}"
        }
      },
      "seq": 255,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 304,
      "name": "Form",
      "parent": 0,
      "props": {
      },
      "seq": 304,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 305,
      "name": "FormGroup",
      "parent": 304,
      "props": {
        "className": {
          "type": "value",
          "value": "ui form"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", padding: \"1em 0em 0em 1em\", gap: \"1em\"}"
        }
      },
      "seq": 305,
      "void": false
    },
    {
      "from": null,
      "id": 306,
      "name": "label",
      "parent": 305,
      "props": {
        "children": {
          "type": "value",
          "value": "Visit Type"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\",  alignItems: \"center\", width: \"5.4em\"}"
        }
      },
      "seq": 306,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 307,
      "name": "Dropdown",
      "parent": 305,
      "props": {
        "disabled": {
          "type": "code",
          "value": "props.onHandleDisableForm"
        },
        "id": {
          "type": "value",
          "value": "CardTravelCertificate-Dropdown-VisitType"
        },
        "onChange": {
          "type": "code",
          "value": "(e, {value}) => props.onHandleChangeVisitType(value)"
        },
        "options": {
          "type": "code",
          "value": "props.onVisitTypeOption"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"11.8em\"}"
        },
        "value": {
          "type": "code",
          "value": "props.onVisitTypeValue"
        }
      },
      "seq": 307,
      "void": false
    },
    {
      "from": null,
      "id": 308,
      "name": "label",
      "parent": 305,
      "props": {
        "children": {
          "type": "value",
          "value": "วันที่"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\",  alignItems: \"center\"}"
        }
      },
      "seq": 308,
      "void": false
    },
    {
      "from": "react-lib/apps/common",
      "id": 309,
      "name": "DateTextBox",
      "parent": 305,
      "props": {
        "disabled": {
          "type": "code",
          "value": "props.onHandleDisableForm"
        },
        "id": {
          "type": "value",
          "value": "CardTravelCertificate-DateTextBox-ExamDate"
        },
        "minDate": {
          "type": "code",
          "value": "props.onMinDate"
        },
        "onChange": {
          "type": "code",
          "value": "(date: string) => props.onHandleChangeDate(\"examDate\", date)"
        },
        "style": {
          "type": "code",
          "value": "{width: \"12em\"}"
        },
        "value": {
          "type": "code",
          "value": "props.onExamDate"
        }
      },
      "seq": 309,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 310,
      "name": "FormGroup",
      "parent": 304,
      "props": {
        "className": {
          "type": "value",
          "value": "ui form"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", padding: \"0em 0em 0em 1em\", gap: \"1.5em\"}"
        }
      },
      "seq": 310,
      "void": false
    },
    {
      "from": null,
      "id": 311,
      "name": "label",
      "parent": 310,
      "props": {
        "children": {
          "type": "value",
          "value": "ผู้ป่วยสามารถเดินทางโดยอากาศยานได้ ตั้งแต่วันที่"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\",  alignItems: \"center\"}"
        }
      },
      "seq": 311,
      "void": false
    },
    {
      "from": "react-lib/apps/common",
      "id": 312,
      "name": "DateTextBox",
      "parent": 310,
      "props": {
        "disabled": {
          "type": "code",
          "value": "props.onHandleDisableForm"
        },
        "id": {
          "type": "value",
          "value": "CardTravelCertificate-DateTextBox-FlightDate"
        },
        "onChange": {
          "type": "code",
          "value": "(date: string) => props.onHandleChangeDate(\"flightDate\", date)"
        },
        "style": {
          "type": "code",
          "value": "{width: \"12em\"}"
        },
        "value": {
          "type": "code",
          "value": "props.onFlightDate"
        }
      },
      "seq": 312,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 313,
      "name": "FormGroup",
      "parent": 304,
      "props": {
        "className": {
          "type": "value",
          "value": "ui form"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", padding: \"0em 0em 0em 1em\", gap: \"1.5em\"}"
        }
      },
      "seq": 313,
      "void": false
    },
    {
      "from": null,
      "id": 314,
      "name": "label",
      "parent": 313,
      "props": {
        "children": {
          "type": "value",
          "value": "การวินิจฉัย"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"bold\"}"
        }
      },
      "seq": 314,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 315,
      "name": "TextArea",
      "parent": 313,
      "props": {
        "disabled": {
          "type": "code",
          "value": "props.onHandleDisableForm"
        },
        "id": {
          "type": "value",
          "value": "CardTravelCertificate-TextArea-Diagnosis"
        },
        "onChange": {
          "type": "code",
          "value": "(e) => props.onHandleChangeText(\"diagnosis\", e)"
        },
        "style": {
          "type": "code",
          "value": "{width: \"27.5em\"}"
        },
        "value": {
          "type": "code",
          "value": "props.onDiagnosis"
        }
      },
      "seq": 315,
      "void": true
    },
    {
      "from": "semantic-ui-react",
      "id": 316,
      "name": "FormGroup",
      "parent": 304,
      "props": {
        "className": {
          "type": "value",
          "value": "ui form"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", padding: \"0em 0em 0em 1em\"}"
        },
        "widths": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 318,
      "void": false
    },
    {
      "from": null,
      "id": 317,
      "name": "label",
      "parent": 316,
      "props": {
        "children": {
          "type": "value",
          "value": "ข้อเสนอแนะ และการประเมินการเดินทาง"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\",  alignItems: \"center\", fontWeight: \"bold\"}"
        }
      },
      "seq": 317,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 318,
      "name": "FormGroup",
      "parent": 304,
      "props": {
        "className": {
          "type": "value",
          "value": "ui form"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", padding: \"0em 0em 0em 3em\"}"
        }
      },
      "seq": 320,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 319,
      "name": "Checkbox",
      "parent": 318,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.onRecommendOption(\"first\")"
        },
        "disabled": {
          "type": "code",
          "value": "props.onHandleDisableForm"
        },
        "label": {
          "type": "value",
          "value": "สามารถเดินทางโดยอากาศยานได้เหมือนผู้โดยสารทั่วไป"
        },
        "onChange": {
          "type": "code",
          "value": "(e) => props.onHandleChangeRecommend(e, \"first\")"
        }
      },
      "seq": 319,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 320,
      "name": "FormGroup",
      "parent": 304,
      "props": {
        "className": {
          "type": "value",
          "value": "ui form"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", padding: \"0em 0em 0em 3em\"}"
        }
      },
      "seq": 321,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 321,
      "name": "FormGroup",
      "parent": 304,
      "props": {
        "className": {
          "type": "value",
          "value": "ui form"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", padding: \"0em 0em 0em 3em\"}"
        },
        "widths": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 322,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 322,
      "name": "FormGroup",
      "parent": 304,
      "props": {
        "className": {
          "type": "value",
          "value": "ui form"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", padding: \"0em 0em 0em 3em\"}"
        }
      },
      "seq": 326,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 323,
      "name": "Checkbox",
      "parent": 320,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.onRecommendOption(\"second\")"
        },
        "disabled": {
          "type": "code",
          "value": "props.onHandleDisableForm"
        },
        "label": {
          "type": "value",
          "value": "สามารถเดินทางโดยอากาศยานร่วมกับเพื่อน/ครอบครัว ที่ไม่ใช่บุคลากรทางแพทย์ได้"
        },
        "onChange": {
          "type": "code",
          "value": "(e) => props.onHandleChangeRecommend(e, \"second\")"
        },
        "style": {
          "type": "code",
          "value": "{width: \"24.5em\"}"
        }
      },
      "seq": 323,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 324,
      "name": "Checkbox",
      "parent": 321,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.onRecommendOption(\"third\")"
        },
        "disabled": {
          "type": "code",
          "value": "props.onHandleDisableForm"
        },
        "label": {
          "type": "value",
          "value": "สามารถเดินทางโดยอากาศยานโดยต้องมีบุคลากรทางแพทย์ร่วมเดินทางด้วยเท่านั้น"
        },
        "onChange": {
          "type": "code",
          "value": "(e) => props.onHandleChangeRecommend(e, \"third\")"
        },
        "style": {
          "type": "code",
          "value": "{width: \"26.5em\"}"
        }
      },
      "seq": 324,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 325,
      "name": "Checkbox",
      "parent": 322,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.onRecommendOption(\"fourth\")"
        },
        "disabled": {
          "type": "code",
          "value": "props.onHandleDisableForm"
        },
        "label": {
          "type": "value",
          "value": "ไม่เหมาะ สำหรับการเดินทางโดยอากาศยาน เนื่องจากปัจจัยความเสี่ยงของผู้ป่วย"
        },
        "onChange": {
          "type": "code",
          "value": "(e) => props.onHandleChangeRecommend(e, \"fourth\")"
        },
        "style": {
          "type": "code",
          "value": "{width: \"21em\"}"
        }
      },
      "seq": 325,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 326,
      "name": "FormGroup",
      "parent": 304,
      "props": {
        "className": {
          "type": "value",
          "value": "ui form"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", padding: \"1em 0em 0em 1em\", gap: \"2em\"}"
        }
      },
      "seq": 329,
      "void": false
    },
    {
      "from": null,
      "id": 327,
      "name": "label",
      "parent": 326,
      "props": {
        "children": {
          "type": "value",
          "value": "ข้อกำหนดพิเศษ"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\",  alignItems: \"center\", fontWeight: \"bold\", height: \"36px\", width: \"7.3em\"}"
        }
      },
      "seq": 327,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 328,
      "name": "Dropdown",
      "parent": 326,
      "props": {
        "allowAdditions": {
          "type": "code",
          "value": "true"
        },
        "clearable": {
          "type": "code",
          "value": "true"
        },
        "disabled": {
          "type": "code",
          "value": "props.onHandleDisableForm"
        },
        "id": {
          "type": "value",
          "value": "CardTravelCertificate-Dropdown-SpecialRequire"
        },
        "multiple": {
          "type": "code",
          "value": "true"
        },
        "onAddItem": {
          "type": "code",
          "value": "props.onHandleAddSpecialRequire"
        },
        "onChange": {
          "type": "code",
          "value": "props.onHandleChangeSpecialRequire"
        },
        "options": {
          "type": "code",
          "value": "props.onSpecialRequireOption"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"25em\"}"
        },
        "value": {
          "type": "code",
          "value": "props.onSpecialRequireValue"
        }
      },
      "seq": 328,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 329,
      "name": "FormGroup",
      "parent": 304,
      "props": {
        "className": {
          "type": "value",
          "value": "ui form"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", padding: \"0em 0em 0em 1em\"}"
        }
      },
      "seq": 332,
      "void": false
    },
    {
      "from": null,
      "id": 330,
      "name": "label",
      "parent": 329,
      "props": {
        "children": {
          "type": "value",
          "value": "ความคิดเห็น"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"bold\"}"
        }
      },
      "seq": 330,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 331,
      "name": "TextArea",
      "parent": 333,
      "props": {
        "disabled": {
          "type": "code",
          "value": "props.onHandleDisableForm"
        },
        "id": {
          "type": "value",
          "value": "CardTravelCertificate-TextArea-Comment"
        },
        "onChange": {
          "type": "code",
          "value": "(e) => props.onHandleChangeText(\"comment\", e)"
        },
        "style": {
          "type": "code",
          "value": "{width: \"30em\"}"
        },
        "value": {
          "type": "code",
          "value": "props.onComment"
        }
      },
      "seq": 331,
      "void": true
    },
    {
      "from": "semantic-ui-react",
      "id": 332,
      "name": "FormGroup",
      "parent": 304,
      "props": {
        "className": {
          "type": "value",
          "value": "ui form"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", padding: \"0em 0em 0em 2em\"}"
        }
      },
      "seq": 316,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 333,
      "name": "FormGroup",
      "parent": 304,
      "props": {
        "className": {
          "type": "value",
          "value": "ui form"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", padding: \"0em 0em 0em 2em\"}"
        }
      },
      "seq": 333,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 334,
      "name": "FormGroup",
      "parent": 304,
      "props": {
        "className": {
          "type": "value",
          "value": "ui form"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", justifyContent: \"flex-end\"}"
        }
      },
      "seq": 334,
      "void": false
    },
    {
      "from": null,
      "id": 335,
      "name": "label",
      "parent": 334,
      "props": {
        "children": {
          "type": "value",
          "value": "{props.onCloseSelected}"
        }
      },
      "seq": 335,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 80,
  "name": "CardTravelCertificateUX",
  "project": "IsHealth",
  "screenPropsDefault": {
    "language": "TH"
  },
  "width": 55
}

*********************************************************************************** */
