import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  Button,
  Icon,
  FormGroup,
  FormField,
  Dropdown
} from 'semantic-ui-react'

const CardConsultDetailUX = (props: any) => {
    return(
      <div
        style={{padding: "30px"}}>
        <div
          style={{display: "flex", justifyContent: "flex-end"}}>
          
          <div
            style={{ marginRight: "10px" }}>
            {props.appStatusLabel}
          </div>
          <div
            style={{ marginRight: "10px", display: props.config?.hideConsultStatus? "none": "" }}>
            {props.consultStatusLabel}
          </div>
          <div
            style={{ display: props.config?.hideCloseIcon? "none": ""}}>
            
            <Icon
              name="close"
              onClick={props.onClose}>
            </Icon>
          </div>
        </div>
        <div
          className="ui form"
          style={{...(props.config.isSource ? {}: {display: "none"})}}>
          
          <FormGroup
            inline={true}>
            <FormField
              width="4">
              <div
                style={{width: "100%"}}>
                
                <label>
                  จากแผนก
                </label>
                <Dropdown
                  clearable={true}
                  disabled={props.config.sourceReadOnly}
                  fluid={true}
                  name="sourceDivision"
                  options={props.divisionList}
                  search={true}
                  selection={true}
                  value={props.consultDetail?.sourceDivision}>
                </Dropdown>
              </div>
            </FormField>
            <FormField
              width="4">
              <div
                style={{width: "100%"}}>
                {}
                <label>
                  แพทย์
                </label>
                <Dropdown
                  clearable={true}
                  disabled={props.config.sourceReadOnly}
                  fluid={true}
                  name="sourceProviderDoctor"
                  options={props.doctorOptions}
                  search={true}
                  selection={true}
                  value={props.consultDetail?.sourceProviderDoctor}>
                </Dropdown>
              </div>
            </FormField>
            <FormField>
              <label>
                ผู้ป่วย
              </label>
            </FormField>
            <FormField>
              <div
                style={{width: "100%"}}>
                
                <div>
                  {props.patientDetail}
                </div>
              </div>
            </FormField>
            <FormField>
              <div
                style={{display:  props?.config?.showExtraButton? "": "none"}}>
                {props.extraButton}
              </div>
            </FormField>
          </FormGroup>
        </div>
        <div
          className="ui form"
          style={{...(props.config.isDestination ? {} : {display: "none"})}}>
          
          <FormGroup
            inline={true}>
            <FormField
              width="4">
              <div
                style={{width: "100%"}}>
                
                <div
                  style={{display: "flex"}}>
                  
                  <label>
                    ถึงแผนก
                  </label>
                  <div
                    style={{color: "red"}}>
                    *
                  </div>
                </div>
                <Dropdown
                  clearable={true}
                  disabled={props.config?.destinationReadOnly}
                  fluid={true}
                  name="destinationDivision"
                  onChange={props.onDivisionChange}
                  options={props.divisionTypeClinic}
                  search={true}
                  selection={true}
                  value={props.consultDetail?.destinationDivision}>
                </Dropdown>
              </div>
            </FormField>
            <FormField
              width="3">
              <div
                style={{width: "100%"}}>
                {}
                <label>
                  แพทย์
                </label>
                <Dropdown
                  clearable={true}
                  disabled={props.config?.destinationReadOnly}
                  fluid={true}
                  name="destinationProviderDoctor"
                  onChange={props.onSelectedProviderDoctor}
                  options={props.doctorProviderOptions}
                  search={true}
                  selection={true}
                  value={props.consultDetail?.destinationProviderDoctor}>
                </Dropdown>
              </div>
            </FormField>
            <FormField
              width="8">
              <div
                style={{width: "100%"}}>
                {props.bookingComponent}
              </div>
            </FormField>
            <FormField
              width="1">
              <div
                style={{display:  props?.config?.showViewListIcon? "": "none"}}>
                {props.viewListIcon}
              </div>
            </FormField>
          </FormGroup>
        </div>
        <div>
          
          <label
            style={{fontWeight: "bold"}}>
            รายละเอียดการส่งปรึกษา
          </label>
        </div>
        <div>
          {props.consultTextArea}
        </div>
        <div
          style={{display: props.config?.hideDestinationNote ? "none" : "" }}>
          ตอบกลับ Consult (สำหรับแพทย์รับปรึกษา)
          <label
            style={{fontWeight: "bold"}}>
            รายละเอียดการส่งปรึกษา
          </label>
        </div>
        <div
          style={{display: props.config?.hideDestinationNote ? "none" : "" }}>
          {props.reactConsultTextArea}
        </div>
        <div
          style={{display: props.config?.hideActionBar ? "none" : "flex" , justifyContent: "flex-end"}}>
          
          <div
            style={{ display: props.config?.hideConfirmButton ? "none" : "" }}>
            
            <Button
              color="green"
              onClick={props.onConfirmAppointment}>
              ยืนยันนัดหมาย
            </Button>
          </div>
          <Button
            color="green"
            disabled={props.disabledSelectDateTimeButton}
            onClick={props.onSelectDateTime}
            style={{...(props.mode !== "CREATE"? {display: "none"}: {})}}>
            <Icon
              name="calendar alternate">
            </Icon>
            <label>
              เลือกวันเวลานัดหมาย
            </label>
          </Button>
          <div
            style={{ display: props.config?.showSelectDoctorButton && props.consultDetail?.destinationProviderDoctor === "unknown"? "" : "none" }}>
            
            <Button
              color="green"
              onClick={props.onSpecifyAppointment}>
              ระบุแพทย์และวันเวลานัดหมาย
            </Button>
          </div>
          <div
            style={{ display: props.config?.hideEditDateTimeButton ? "none" : "" }}>
            
            <Button
              color="yellow"
              onClick={props.onSelectDateTime}
              style={{...(props.mode !== "EDIT"? {display: "none"}: {})}}>
              แก้ไขวันเวลานัดหมาย
            </Button>
          </div>
          <div
            style={{ display: props.config?.hideSaveButton ? "none" : "" }}>
            
            <Button
              color="green"
              onClick={props.onSaveConsultAppointment}
              style={{...(props.config?.hideResponseButton? {display: "none"}: {})}}>
              บันทึกข้อความตอบกลับ
            </Button>
          </div>
          <div
            style={{ display: props.config?.hideEditButton ? "none" : "" }}>
            
            <Button
              color="yellow"
              onClick={props.onEditConsultAppointment}
              style={{...(props.config?.hideResponseButton? {display: "none"}: {})}}>
              แก้ไขข้อความตอบกลับ
            </Button>
          </div>
          <div
            style={{ display: props.config?.hideCancelButton ? "none" : "" }}>
            
            <Button
              color="red"
              onClick={props.onCancelAppointment}
              style={{...(props.mode !== "EDIT"? {display: "none"}: {})}}>
              ยกเลิกการส่งปรึกษา
            </Button>
          </div>
          <div
            style={{ display: props.config?.hideFinishButton ? "none" : "" }}>
            
            <Button
              color="blue"
              onClick={props.onFinishConsult}
              type="button">
              เสร็จสิ้นการ consult
            </Button>
          </div>
          <div
            style={{ display: props.config?.hideReverseFinishButton ? "none" : "" }}>
            
            <Button
              color="orange"
              onClick={props.onReverseFinishConsult}
              type="button">
              ยกเลิกเสร็จสิ้นการ consult
            </Button>
          </div>
        </div>
      </div>
    )
}


export default CardConsultDetailUX

export const screenPropsDefault = {}

/* Date Time : Thu Feb 22 2024 16:57:31 GMT+0700 (Indochina Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "props": {
        "style": {
          "type": "code",
          "value": "{padding: \"30px\"}"
        }
      },
      "seq": 0
    },
    {
      "from": null,
      "id": 2,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 39,
      "void": false
    },
    {
      "from": null,
      "id": 3,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "code",
          "value": "props.consultTextArea"
        }
      },
      "seq": 42,
      "void": false
    },
    {
      "from": null,
      "id": 4,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: props.config?.hideActionBar ? \"none\" : \"flex\" , justifyContent: \"flex-end\"}"
        }
      },
      "seq": 93,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 21,
      "name": "Button",
      "parent": 4,
      "props": {
        "color": {
          "type": "value",
          "value": "green"
        },
        "disabled": {
          "type": "code",
          "value": "props.disabledSelectDateTimeButton"
        },
        "onClick": {
          "type": "code",
          "value": "props.onSelectDateTime"
        },
        "style": {
          "type": "code",
          "value": "{...(props.mode !== \"CREATE\"? {display: \"none\"}: {})}"
        }
      },
      "seq": 41,
      "void": false
    },
    {
      "from": null,
      "id": 22,
      "name": "label",
      "parent": 2,
      "props": {
        "children": {
          "type": "value",
          "value": "รายละเอียดการส่งปรึกษา"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"bold\"}"
        }
      },
      "seq": 22,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 23,
      "name": "Icon",
      "parent": 21,
      "props": {
        "name": {
          "type": "value",
          "value": "calendar alternate"
        }
      },
      "seq": 23,
      "void": false
    },
    {
      "from": null,
      "id": 24,
      "name": "label",
      "parent": 21,
      "props": {
        "children": {
          "type": "value",
          "value": "เลือกวันเวลานัดหมาย"
        }
      },
      "seq": 24,
      "void": false
    },
    {
      "from": null,
      "id": 39,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", justifyContent: \"flex-end\"}"
        }
      },
      "seq": 1,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 40,
      "name": "Button",
      "parent": 96,
      "props": {
        "children": {
          "type": "value",
          "value": "แก้ไขวันเวลานัดหมาย"
        },
        "color": {
          "type": "value",
          "value": "yellow"
        },
        "disabled": {
          "type": "value",
          "value": ""
        },
        "onClick": {
          "type": "code",
          "value": "props.onSelectDateTime"
        },
        "style": {
          "type": "code",
          "value": "{...(props.mode !== \"EDIT\"? {display: \"none\"}: {})}"
        }
      },
      "seq": 40,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 41,
      "name": "Button",
      "parent": 97,
      "props": {
        "children": {
          "type": "value",
          "value": "ยกเลิกการส่งปรึกษา"
        },
        "color": {
          "type": "value",
          "value": "red"
        },
        "onClick": {
          "type": "code",
          "value": "props.onCancelAppointment"
        },
        "size": {
          "type": "code",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{...(props.mode !== \"EDIT\"? {display: \"none\"}: {})}"
        }
      },
      "seq": 96,
      "void": false
    },
    {
      "from": null,
      "id": 42,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "ui form"
        },
        "style": {
          "type": "code",
          "value": "{...(props.config.isSource ? {}: {display: \"none\"})}"
        }
      },
      "seq": 2,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 43,
      "name": "FormGroup",
      "parent": 42,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 43,
      "void": false
    },
    {
      "from": null,
      "id": 45,
      "name": "label",
      "parent": 48,
      "props": {
        "children": {
          "type": "value",
          "value": "จากแผนก"
        }
      },
      "seq": 45,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 47,
      "name": "FormField",
      "parent": 43,
      "props": {
        "width": {
          "type": "value",
          "value": "4"
        }
      },
      "seq": 47,
      "void": false
    },
    {
      "from": null,
      "id": 48,
      "name": "div",
      "parent": 47,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        }
      },
      "seq": 48,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 49,
      "name": "Dropdown",
      "parent": 48,
      "props": {
        "clearable": {
          "type": "code",
          "value": "true"
        },
        "disabled": {
          "type": "code",
          "value": "props.config.sourceReadOnly"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "sourceDivision"
        },
        "onChange": {
          "type": "code",
          "value": ""
        },
        "onSearchChange": {
          "type": "code",
          "value": ""
        },
        "options": {
          "type": "code",
          "value": "props.divisionList"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.consultDetail?.sourceDivision"
        }
      },
      "seq": 49,
      "void": false
    },
    {
      "from": null,
      "id": 51,
      "name": "label",
      "parent": 53,
      "props": {
        "children": {
          "type": "value",
          "value": "แพทย์"
        }
      },
      "seq": 51,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 52,
      "name": "FormField",
      "parent": 43,
      "props": {
        "width": {
          "type": "value",
          "value": "4"
        }
      },
      "seq": 52,
      "void": false
    },
    {
      "from": null,
      "id": 53,
      "name": "div",
      "parent": 52,
      "props": {
        "children": {
          "type": "code",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        }
      },
      "seq": 53,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 54,
      "name": "Dropdown",
      "parent": 53,
      "props": {
        "clearable": {
          "type": "code",
          "value": "true"
        },
        "disabled": {
          "type": "code",
          "value": "props.config.sourceReadOnly"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "sourceProviderDoctor"
        },
        "onChange": {
          "type": "code",
          "value": ""
        },
        "onSearchChange": {
          "type": "code",
          "value": ""
        },
        "options": {
          "type": "code",
          "value": "props.doctorOptions"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.consultDetail?.sourceProviderDoctor"
        }
      },
      "seq": 54,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 57,
      "name": "FormGroup",
      "parent": 93,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 57,
      "void": false
    },
    {
      "from": null,
      "id": 59,
      "name": "label",
      "parent": 110,
      "props": {
        "children": {
          "type": "value",
          "value": "ถึงแผนก"
        }
      },
      "seq": 59,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 61,
      "name": "FormField",
      "parent": 57,
      "props": {
        "width": {
          "type": "value",
          "value": "4"
        }
      },
      "seq": 61,
      "void": false
    },
    {
      "from": null,
      "id": 62,
      "name": "div",
      "parent": 61,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        }
      },
      "seq": 62,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 63,
      "name": "Dropdown",
      "parent": 62,
      "props": {
        "clearable": {
          "type": "code",
          "value": "true"
        },
        "disabled": {
          "type": "code",
          "value": "props.config?.destinationReadOnly"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "destinationDivision"
        },
        "onChange": {
          "type": "code",
          "value": "props.onDivisionChange"
        },
        "onSearchChange": {
          "type": "code",
          "value": ""
        },
        "options": {
          "type": "code",
          "value": "props.divisionTypeClinic"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.consultDetail?.destinationDivision"
        }
      },
      "seq": 110,
      "void": false
    },
    {
      "from": null,
      "id": 65,
      "name": "label",
      "parent": 67,
      "props": {
        "children": {
          "type": "value",
          "value": "แพทย์"
        }
      },
      "seq": 65,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 66,
      "name": "FormField",
      "parent": 57,
      "props": {
        "width": {
          "type": "value",
          "value": "3"
        }
      },
      "seq": 66,
      "void": false
    },
    {
      "from": null,
      "id": 67,
      "name": "div",
      "parent": 66,
      "props": {
        "children": {
          "type": "code",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        }
      },
      "seq": 67,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 68,
      "name": "Dropdown",
      "parent": 67,
      "props": {
        "clearable": {
          "type": "code",
          "value": "true"
        },
        "disabled": {
          "type": "code",
          "value": "props.config?.destinationReadOnly"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "destinationProviderDoctor"
        },
        "onChange": {
          "type": "code",
          "value": "props.onSelectedProviderDoctor"
        },
        "onSearchChange": {
          "type": "code",
          "value": ""
        },
        "options": {
          "type": "code",
          "value": "props.doctorProviderOptions"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.consultDetail?.destinationProviderDoctor"
        }
      },
      "seq": 68,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 71,
      "name": "FormField",
      "parent": 43,
      "props": {
        "width": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 71,
      "void": false
    },
    {
      "from": null,
      "id": 72,
      "name": "label",
      "parent": 71,
      "props": {
        "children": {
          "type": "value",
          "value": "ผู้ป่วย"
        }
      },
      "seq": 72,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 73,
      "name": "FormField",
      "parent": 43,
      "props": {
        "width": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 73,
      "void": false
    },
    {
      "from": null,
      "id": 74,
      "name": "div",
      "parent": 111,
      "props": {
        "children": {
          "type": "code",
          "value": "props.patientDetail"
        }
      },
      "seq": 74,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 75,
      "name": "FormField",
      "parent": 57,
      "props": {
        "width": {
          "type": "value",
          "value": "8"
        }
      },
      "seq": 75,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 79,
      "name": "FormField",
      "parent": 57,
      "props": {
        "width": {
          "type": "value",
          "value": "1"
        }
      },
      "seq": 79,
      "void": false
    },
    {
      "from": null,
      "id": 85,
      "name": "div",
      "parent": 79,
      "props": {
        "children": {
          "type": "code",
          "value": "props.viewListIcon"
        },
        "style": {
          "type": "code",
          "value": "{display:  props?.config?.showViewListIcon? \"\": \"none\"}"
        }
      },
      "seq": 85,
      "void": false
    },
    {
      "from": null,
      "id": 86,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": "ตอบกลับ Consult (สำหรับแพทย์รับปรึกษา)"
        },
        "style": {
          "type": "code",
          "value": "{display: props.config?.hideDestinationNote ? \"none\" : \"\" }"
        }
      },
      "seq": 86,
      "void": false
    },
    {
      "from": null,
      "id": 87,
      "name": "label",
      "parent": 86,
      "props": {
        "children": {
          "type": "value",
          "value": "รายละเอียดการส่งปรึกษา"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"bold\"}"
        }
      },
      "seq": 87,
      "void": false
    },
    {
      "from": null,
      "id": 88,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "code",
          "value": "props.reactConsultTextArea"
        },
        "style": {
          "type": "code",
          "value": "{display: props.config?.hideDestinationNote ? \"none\" : \"\" }"
        }
      },
      "seq": 88,
      "void": false
    },
    {
      "from": null,
      "id": 89,
      "name": "div",
      "parent": 39,
      "props": {
        "children": {
          "type": "code",
          "value": "props.appStatusLabel"
        },
        "style": {
          "type": "code",
          "value": "{ marginRight: \"10px\" }"
        }
      },
      "seq": 89,
      "void": false
    },
    {
      "from": null,
      "id": 90,
      "name": "div",
      "parent": 39,
      "props": {
        "children": {
          "type": "code",
          "value": "props.consultStatusLabel"
        },
        "style": {
          "type": "code",
          "value": "{ marginRight: \"10px\", display: props.config?.hideConsultStatus? \"none\": \"\" }"
        }
      },
      "seq": 90,
      "void": false
    },
    {
      "from": null,
      "id": 93,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "ui form"
        },
        "style": {
          "type": "code",
          "value": "{...(props.config.isDestination ? {} : {display: \"none\"})}"
        }
      },
      "seq": 3,
      "void": false
    },
    {
      "from": null,
      "id": 94,
      "name": "div",
      "parent": 110,
      "props": {
        "children": {
          "type": "value",
          "value": "*"
        },
        "style": {
          "type": "code",
          "value": "{color: \"red\"}"
        }
      },
      "seq": 94,
      "void": false
    },
    {
      "from": null,
      "id": 95,
      "name": "div",
      "parent": 75,
      "props": {
        "children": {
          "type": "code",
          "value": "props.bookingComponent"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        }
      },
      "seq": 95,
      "void": false
    },
    {
      "from": null,
      "id": 96,
      "name": "div",
      "parent": 4,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: props.config?.hideEditDateTimeButton ? \"none\" : \"\" }"
        }
      },
      "seq": 98,
      "void": false
    },
    {
      "from": null,
      "id": 97,
      "name": "div",
      "parent": 4,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: props.config?.hideCancelButton ? \"none\" : \"\" }"
        }
      },
      "seq": 106,
      "void": false
    },
    {
      "from": null,
      "id": 98,
      "name": "div",
      "parent": 4,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: props.config?.hideConfirmButton ? \"none\" : \"\" }"
        }
      },
      "seq": 21,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 99,
      "name": "Button",
      "parent": 98,
      "props": {
        "children": {
          "type": "value",
          "value": "ยืนยันนัดหมาย"
        },
        "color": {
          "type": "value",
          "value": "green"
        },
        "onClick": {
          "type": "code",
          "value": "props.onConfirmAppointment"
        },
        "size": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 99,
      "void": false
    },
    {
      "from": null,
      "id": 100,
      "name": "div",
      "parent": 39,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: props.config?.hideCloseIcon? \"none\": \"\"}"
        }
      },
      "seq": 100,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 101,
      "name": "Icon",
      "parent": 100,
      "props": {
        "name": {
          "type": "value",
          "value": "close"
        },
        "onClick": {
          "type": "code",
          "value": "props.onClose"
        }
      },
      "seq": 101,
      "void": false
    },
    {
      "from": null,
      "id": 102,
      "name": "div",
      "parent": 4,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: props.config?.showSelectDoctorButton && props.consultDetail?.destinationProviderDoctor === \"unknown\"? \"\" : \"none\" }"
        }
      },
      "seq": 97,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 103,
      "name": "Button",
      "parent": 102,
      "props": {
        "children": {
          "type": "value",
          "value": "ระบุแพทย์และวันเวลานัดหมาย"
        },
        "color": {
          "type": "value",
          "value": "green"
        },
        "onClick": {
          "type": "code",
          "value": "props.onSpecifyAppointment"
        }
      },
      "seq": 103,
      "void": false
    },
    {
      "from": null,
      "id": 104,
      "name": "div",
      "parent": 4,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: props.config?.hideSaveButton ? \"none\" : \"\" }"
        }
      },
      "seq": 102,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 105,
      "name": "Button",
      "parent": 104,
      "props": {
        "children": {
          "type": "value",
          "value": "บันทึกข้อความตอบกลับ"
        },
        "color": {
          "type": "value",
          "value": "green"
        },
        "onClick": {
          "type": "code",
          "value": "props.onSaveConsultAppointment"
        },
        "size": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{...(props.config?.hideResponseButton? {display: \"none\"}: {})}"
        }
      },
      "seq": 105,
      "void": false
    },
    {
      "from": null,
      "id": 106,
      "name": "div",
      "parent": 4,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: props.config?.hideEditButton ? \"none\" : \"\" }"
        }
      },
      "seq": 104,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 107,
      "name": "Button",
      "parent": 106,
      "props": {
        "children": {
          "type": "value",
          "value": "แก้ไขข้อความตอบกลับ"
        },
        "color": {
          "type": "value",
          "value": "yellow"
        },
        "onClick": {
          "type": "code",
          "value": "props.onEditConsultAppointment"
        },
        "size": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{...(props.config?.hideResponseButton? {display: \"none\"}: {})}"
        }
      },
      "seq": 107,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 108,
      "name": "FormField",
      "parent": 43,
      "props": {
        "width": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 108,
      "void": false
    },
    {
      "from": null,
      "id": 109,
      "name": "div",
      "parent": 108,
      "props": {
        "children": {
          "type": "code",
          "value": "props.extraButton"
        },
        "style": {
          "type": "code",
          "value": "{display:  props?.config?.showExtraButton? \"\": \"none\"}"
        }
      },
      "seq": 109,
      "void": false
    },
    {
      "from": null,
      "id": 110,
      "name": "div",
      "parent": 62,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\"}"
        }
      },
      "seq": 63,
      "void": false
    },
    {
      "from": null,
      "id": 111,
      "name": "div",
      "parent": 73,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        }
      },
      "seq": 111,
      "void": false
    },
    {
      "from": null,
      "id": 112,
      "name": "div",
      "parent": 4,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: props.config?.hideFinishButton ? \"none\" : \"\" }"
        }
      },
      "seq": 112,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 113,
      "name": "Button",
      "parent": 112,
      "props": {
        "children": {
          "type": "value",
          "value": "เสร็จสิ้นการ consult"
        },
        "color": {
          "type": "value",
          "value": "blue"
        },
        "onClick": {
          "type": "code",
          "value": "props.onFinishConsult"
        },
        "size": {
          "type": "code",
          "value": ""
        },
        "style": {
          "type": "value",
          "value": ""
        },
        "type": {
          "type": "value",
          "value": "button"
        }
      },
      "seq": 113,
      "void": false
    },
    {
      "from": null,
      "id": 114,
      "name": "div",
      "parent": 4,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: props.config?.hideReverseFinishButton ? \"none\" : \"\" }"
        }
      },
      "seq": 114,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 115,
      "name": "Button",
      "parent": 114,
      "props": {
        "children": {
          "type": "value",
          "value": "ยกเลิกเสร็จสิ้นการ consult"
        },
        "color": {
          "type": "value",
          "value": "orange"
        },
        "onClick": {
          "type": "code",
          "value": "props.onReverseFinishConsult"
        },
        "size": {
          "type": "code",
          "value": ""
        },
        "style": {
          "type": "value",
          "value": ""
        },
        "type": {
          "type": "value",
          "value": "button"
        }
      },
      "seq": 115,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 80,
  "name": "CardConsultDetailUX",
  "project": "IsHealth",
  "screenPropsDefault": {
  },
  "width": 80
}

*********************************************************************************** */
