import FormSymbolImages from "./AddSymbolPrintList";

export default function toPDFMakeData(props: any) {
  const injectImages = (haveHeader: boolean) => {
    let symbolUsage = {};
    if (!haveHeader) {
      symbolUsage = {
        checked: FormSymbolImages.checked,
        unchecked: FormSymbolImages.unchecked,
        squarebox: FormSymbolImages.squarebox,
        checkmark: FormSymbolImages.checkmark,
      };
    }
    return symbolUsage;
  };

  return {
    content: [
      {
        preserveLeadingSpaces: true,
        margin: [0, 0, 0, 0],
        alignment: `center`,
        fontSize: `18`,
        pageBreak: ``,
        bold: `true`,
        decorationColor: ``,
        decorationStyle: ``,
        text: `Do Not Resuscitate Request`,
        decoration: ``,
        width: `auto`,
        color: ``,
      },
      {
        decorationStyle: ``,
        margin: [0, 0, 0, 0],
        fontSize: 15,
        pageBreak: ``,
        alignment: `left`,
        bold: false,
        preserveLeadingSpaces: true,
        text: ` `,
        decoration: ``,
        width: `auto`,
        color: ``,
        decorationColor: ``,
      },
      {
        columns: [
          {
            text: ``,
            decorationStyle: ``,
            pageBreak: ``,
            color: ``,
            margin: [0, 0, 0, 0],
            decoration: ``,
            alignment: `left`,
            fontSize: 15,
            bold: false,
            width: `*`,
            preserveLeadingSpaces: true,
            decorationColor: ``,
          },
          {
            text: `วันที่`,
            decorationColor: ``,
            decorationStyle: ``,
            color: ``,
            preserveLeadingSpaces: true,
            pageBreak: ``,
            margin: [0, 0, 0, 0],
            alignment: `right`,
            bold: false,
            width: `auto`,
            fontSize: 15,
            decoration: ``,
          },
          {
            bold: false,
            fontSize: 15,
            margin: [0, 0, 0, 0],
            width: 10,
            pageBreak: ``,
            preserveLeadingSpaces: true,
            color: ``,
            alignment: `left`,
            decorationColor: ``,
            text: ``,
            decoration: ``,
            decorationStyle: ``,
          },
          {
            preserveLeadingSpaces: true,
            text: `${props.items?.printDay} เดือน ${props.items?.printMonth} พ.ศ. ${props.items?.printYear}`,
            width: `auto`,
            decorationStyle: ``,
            bold: false,
            decorationColor: ``,
            color: ``,
            pageBreak: ``,
            decoration: ``,
            fontSize: 15,
            margin: [0, 0, 0, 0],
            alignment: `right`,
          },
          {
            decoration: ``,
            width: 10,
            color: ``,
            text: ``,
            alignment: `left`,
            margin: [0, 0, 0, 0],
            bold: false,
            decorationColor: ``,
            decorationStyle: ``,
            preserveLeadingSpaces: true,
            pageBreak: ``,
            fontSize: 15,
          },
          {
            decorationColor: ``,
            decorationStyle: ``,
            margin: [0, 0, 0, 0],
            pageBreak: ``,
            decoration: ``,
            color: ``,
            fontSize: 15,
            text: `เวลา`,
            alignment: `right`,
            bold: false,
            preserveLeadingSpaces: true,
            width: `auto`,
          },
          {
            text: ``,
            pageBreak: ``,
            width: 10,
            bold: false,
            margin: [0, 0, 0, 0],
            decoration: ``,
            color: ``,
            alignment: `left`,
            fontSize: 15,
            decorationColor: ``,
            preserveLeadingSpaces: true,
            decorationStyle: ``,
          },
          {
            bold: false,
            alignment: `right`,
            preserveLeadingSpaces: true,
            fontSize: 15,
            decorationStyle: ``,
            margin: [0, 0, 0, 0],
            text: props.items?.signedTime,
            decoration: ``,
            decorationColor: ``,
            color: ``,
            pageBreak: ``,
            width: `auto`,
          },
        ],
      },
      {
        decorationStyle: ``,
        decorationColor: ``,
        decoration: ``,
        bold: false,
        fontSize: 15,
        alignment: `left`,
        margin: [0, 0, 0, 0],
        color: ``,
        preserveLeadingSpaces: true,
        width: `auto`,
        text: ` `,
        pageBreak: ``,
      },
      {
        columns: [
          {
            bold: false,
            alignment: `left`,
            pageBreak: ``,
            text: `ผู้ป่วยชื่อ`,
            decoration: ``,
            width: `auto`,
            fontSize: 15,
            preserveLeadingSpaces: true,
            margin: [0, 0, 0, 0],
            decorationColor: ``,
            color: ``,
            decorationStyle: ``,
          },
          {
            text: ``,
            decoration: ``,
            decorationStyle: ``,
            decorationColor: ``,
            width: 5,
            color: ``,
            bold: false,
            fontSize: 15,
            pageBreak: ``,
            alignment: `left`,
            preserveLeadingSpaces: true,
            margin: [0, 0, 0, 0],
          },
          {
            stack: [
              {
                text: props.items?.full_name_th || props.items?.full_name,
                decoration: ``,
                decorationStyle: ``,
                decorationColor: ``,
                width: `auto`,
                color: ``,
                bold: false,
                fontSize: 15,
                pageBreak: ``,
                alignment: `center`,
                preserveLeadingSpaces: true,
                margin: [0, 0, 0, -15],
              },
              {
                text: `..................................................................................................`,
                decoration: ``,
                decorationStyle: ``,
                decorationColor: ``,
                width: `auto`,
                color: ``,
                bold: false,
                fontSize: 15,
                pageBreak: ``,
                alignment: `left`,
                preserveLeadingSpaces: true,
                margin: [0, 0, 0, 0],
              },
            ],
            width: `auto`,
          },
          {
            text: ``,
            preserveLeadingSpaces: true,
            margin: [0, 0, 0, 0],
            width: 10,
            bold: false,
            decorationColor: ``,
            pageBreak: ``,
            color: ``,
            decoration: ``,
            fontSize: 15,
            alignment: `left`,
            decorationStyle: ``,
          },
          {
            alignment: `left`,
            decorationStyle: ``,
            width: `auto`,
            color: ``,
            decoration: ``,
            fontSize: 15,
            preserveLeadingSpaces: true,
            text: `HN :`,
            bold: false,
            decorationColor: ``,
            pageBreak: ``,
            margin: [0, 0, 0, 0],
          },
          {
            text: ``,
            decoration: ``,
            decorationStyle: ``,
            decorationColor: ``,
            width: 5,
            color: ``,
            bold: false,
            fontSize: 15,
            pageBreak: ``,
            alignment: `left`,
            preserveLeadingSpaces: true,
            margin: [0, 0, 0, 0],
          },
          {
            stack: [
              {
                text: props.items?.hn,
                decoration: ``,
                decorationStyle: ``,
                decorationColor: ``,
                width: `auto`,
                color: ``,
                bold: false,
                fontSize: 15,
                pageBreak: ``,
                alignment: `center`,
                preserveLeadingSpaces: true,
                margin: [0, 0, 0, -15],
              },
              {
                text: `................................................................................`,
                decoration: ``,
                decorationStyle: ``,
                decorationColor: ``,
                width: `auto`,
                color: ``,
                bold: false,
                fontSize: 15,
                pageBreak: ``,
                alignment: `left`,
                preserveLeadingSpaces: true,
                margin: [0, 0, 0, 0],
              },
            ],
            width: `auto`,
          },
        ],
      },
      {
        columns: [
          {
            width: `auto`,
            color: ``,
            pageBreak: ``,
            decoration: ``,
            decorationStyle: ``,
            alignment: `left`,
            decorationColor: ``,
            preserveLeadingSpaces: true,
            text: `ข้าพเจ้า .....................................................................................................`,
            margin: [0, 0, 0, 0],
            bold: false,
            fontSize: 15,
          },
          {
            margin: [0, 0, 0, 0],
            decoration: ``,
            fontSize: 15,
            preserveLeadingSpaces: true,
            decorationStyle: ``,
            decorationColor: ``,
            text: ``,
            pageBreak: ``,
            color: ``,
            width: 10,
            alignment: `left`,
            bold: false,
          },
          {
            decorationStyle: ``,
            pageBreak: ``,
            bold: false,
            decoration: ``,
            fontSize: 15,
            alignment: `left`,
            text: `เกี่ยวข้องเป็น ..............................................`,
            color: ``,
            decorationColor: ``,
            width: `auto`,
            margin: [0, 0, 0, 0],
            preserveLeadingSpaces: true,
          },
          {
            preserveLeadingSpaces: true,
            fontSize: 15,
            width: `auto`,
            decorationStyle: ``,
            margin: [0, 0, 0, 0],
            color: ``,
            decorationColor: ``,
            decoration: ``,
            alignment: `left`,
            pageBreak: ``,
            bold: false,
            text: ` และในฐานะ`,
          },
        ],
      },
      {
        columns: [
          {
            width: `auto`,
            decoration: ``,
            decorationStyle: ``,
            alignment: `left`,
            text: `ผู้มีอำนาจตัดสินใจด้านการรักษาแทนผู้ป่วยข้างต้น ได้รับการอธิบายจากแพทย์`,
            margin: [0, 0, 0, 0],
            fontSize: 15,
            bold: false,
            color: ``,
            preserveLeadingSpaces: true,
            pageBreak: ``,
            decorationColor: ``,
          },
          {
            decorationStyle: ``,
            bold: false,
            color: ``,
            decoration: ``,
            margin: [0, 0, 0, 0],
            fontSize: 15,
            alignment: `left`,
            width: 5,
            preserveLeadingSpaces: true,
            pageBreak: ``,
            text: ``,
            decorationColor: ``,
          },
          {
            stack: [
              {
                text: props.items?.doctor_name || " ",
                decoration: ``,
                decorationStyle: ``,
                decorationColor: ``,
                width: `auto`,
                color: ``,
                bold: false,
                fontSize: 15,
                pageBreak: ``,
                alignment: `left`,
                preserveLeadingSpaces: true,
                margin: [0, 0, 0, -15],
              },
              {
                text: `...................................................................................`,
                decoration: ``,
                decorationStyle: ``,
                decorationColor: ``,
                width: `auto`,
                color: ``,
                bold: false,
                fontSize: 15,
                pageBreak: ``,
                alignment: `left`,
                preserveLeadingSpaces: true,
                margin: [0, 0, 0, 0],
              },
            ],
            width: `auto`,
          },
        ],
      },
      {
        fontSize: 15,
        margin: [0, 0, 0, 0],
        width: `auto`,
        color: ``,
        decorationColor: ``,
        bold: false,
        pageBreak: ``,
        decoration: ``,
        preserveLeadingSpaces: true,
        alignment: `left`,
        text: `ว่าผู้ป่วยมีภาวะ ดังนี้ ...............................................................................................................................................................................`,
        decorationStyle: ``,
      },
      {
        width: `auto`,
        preserveLeadingSpaces: true,
        decorationStyle: ``,
        alignment: `left`,
        decorationColor: ``,
        fontSize: 15,
        text: `.................................................................................................................................................................................................................`,
        margin: [0, 0, 0, 0],
        pageBreak: ``,
        bold: false,
        decoration: ``,
        color: ``,
      },
      {
        text: `.................................................................................................................................................................................................................`,
        decorationColor: ``,
        bold: false,
        width: `auto`,
        fontSize: 15,
        decoration: ``,
        pageBreak: ``,
        alignment: `left`,
        preserveLeadingSpaces: true,
        decorationStyle: ``,
        color: ``,
        margin: [0, 0, 0, 0],
      },
      {
        decoration: ``,
        pageBreak: ``,
        bold: false,
        color: ``,
        text: `หลังจากได้รับการอธิบายจากแพทย์ ข้าพเจ้าได้ปรึกษากับสมาชิกครอบครัวที่ใกล้ชิด (ดังรายชื่อตามเอกสารแนบ) และได้ตกลงร่วมกัน`,
        decorationColor: ``,
        alignment: `left`,
        fontSize: 15,
        margin: [0, 0, 0, 0],
        decorationStyle: ``,
        preserveLeadingSpaces: true,
        width: `auto`,
      },
      {
        preserveLeadingSpaces: true,
        pageBreak: ``,
        alignment: `left`,
        decorationColor: ``,
        color: ``,
        text: `ว่ามีความประสงค์ไม่ให้ช่วยฟื้นคืนชีวิตผู้ป่วย ในขณะที่ผู้ป่วยกำลังอยู่ในวาระสุดท้ายของชีวิต และไม่มีสติสัมปชัญญะแล้ว และขอ`,
        decorationStyle: ``,
        decoration: ``,
        margin: [0, 0, 0, 0],
        width: `auto`,
        fontSize: 15,
        bold: false,
      },
      {
        bold: false,
        decoration: ``,
        text: `แสดงความจำนงไม่ให้มีการปฏิบัติดังต่อไปนี้ คือ`,
        width: `auto`,
        fontSize: 15,
        decorationColor: ``,
        margin: [0, 0, 0, 0],
        pageBreak: ``,
        alignment: `left`,
        color: ``,
        decorationStyle: ``,
        preserveLeadingSpaces: true,
      },
      {
        columns: [
          {
            text: ``,
            decoration: ``,
            pageBreak: ``,
            decorationStyle: ``,
            alignment: `left`,
            color: ``,
            margin: [0, 0, 0, 0],
            bold: false,
            fontSize: 15,
            preserveLeadingSpaces: true,
            width: 20,
            decorationColor: ``,
          },
          {
            image: "unchecked",
            width: 10,
            margin: [0, 4, 0, 0],
          },
          {
            fontSize: 15,
            pageBreak: ``,
            margin: [0, 0, 0, 0],
            color: ``,
            width: 5,
            decorationStyle: ``,
            bold: false,
            decorationColor: ``,
            alignment: `left`,
            preserveLeadingSpaces: true,
            text: ``,
            decoration: ``,
          },
          {
            margin: [0, 0, 0, 0],
            alignment: `left`,
            decorationColor: ``,
            preserveLeadingSpaces: true,
            decoration: ``,
            width: `auto`,
            text: `กระบวนการฟื้นคืนชีพเมื่อหัวใจหยุดเต้น`,
            fontSize: 15,
            color: ``,
            pageBreak: ``,
            bold: false,
            decorationStyle: ``,
          },
        ],
      },
      {
        columns: [
          {
            margin: [0, 0, 0, 0],
            width: 20,
            bold: false,
            decoration: ``,
            text: ``,
            decorationStyle: ``,
            alignment: `left`,
            preserveLeadingSpaces: true,
            pageBreak: ``,
            fontSize: 15,
            decorationColor: ``,
            color: ``,
          },
          {
            image: "unchecked",
            width: 10,
            margin: [0, 4, 0, 0],
          },
          {
            margin: [0, 0, 0, 0],
            preserveLeadingSpaces: true,
            decoration: ``,
            pageBreak: ``,
            decorationStyle: ``,
            fontSize: 15,
            color: ``,
            alignment: `left`,
            text: ``,
            bold: false,
            width: 5,
            decorationColor: ``,
          },
          {
            fontSize: 15,
            text: `การใส่ท่อช่วยหายใจ`,
            decorationColor: ``,
            alignment: `left`,
            bold: false,
            preserveLeadingSpaces: true,
            decorationStyle: ``,
            width: `auto`,
            pageBreak: ``,
            margin: [0, 0, 0, 0],
            decoration: ``,
            color: ``,
          },
        ],
      },
      {
        columns: [
          {
            width: 20,
            fontSize: 15,
            decorationColor: ``,
            text: ``,
            alignment: `left`,
            bold: false,
            margin: [0, 0, 0, 0],
            pageBreak: ``,
            color: ``,
            preserveLeadingSpaces: true,
            decoration: ``,
            decorationStyle: ``,
          },
          {
            image: "unchecked",
            width: 10,
            margin: [0, 4, 0, 0],
          },
          {
            decorationColor: ``,
            pageBreak: ``,
            text: ``,
            bold: false,
            preserveLeadingSpaces: true,
            decoration: ``,
            width: 5,
            color: ``,
            decorationStyle: ``,
            alignment: `left`,
            margin: [0, 0, 0, 0],
            fontSize: 15,
          },
          {
            decorationStyle: ``,
            pageBreak: ``,
            text: `การเจาะคอ`,
            color: ``,
            bold: false,
            preserveLeadingSpaces: true,
            width: `auto`,
            decoration: ``,
            margin: [0, 0, 0, 0],
            alignment: `left`,
            fontSize: 15,
            decorationColor: ``,
          },
        ],
      },
      {
        columns: [
          {
            bold: false,
            pageBreak: ``,
            text: ``,
            width: 20,
            decoration: ``,
            preserveLeadingSpaces: true,
            decorationStyle: ``,
            color: ``,
            alignment: `left`,
            margin: [0, 0, 0, 0],
            decorationColor: ``,
            fontSize: 15,
          },
          {
            image: "unchecked",
            width: 10,
            margin: [0, 4, 0, 0],
          },
          {
            decoration: ``,
            width: 5,
            decorationStyle: ``,
            margin: [0, 0, 0, 0],
            text: ``,
            alignment: `left`,
            fontSize: 15,
            color: ``,
            decorationColor: ``,
            pageBreak: ``,
            preserveLeadingSpaces: true,
            bold: false,
          },
          {
            width: `auto`,
            bold: false,
            alignment: `left`,
            decorationColor: ``,
            decoration: ``,
            text: `การใช้เครื่องช่วยหายใจ`,
            pageBreak: ``,
            color: ``,
            fontSize: 15,
            margin: [0, 0, 0, 0],
            decorationStyle: ``,
            preserveLeadingSpaces: true,
          },
        ],
      },
      {
        columns: [
          {
            preserveLeadingSpaces: true,
            color: ``,
            decorationStyle: ``,
            pageBreak: ``,
            bold: false,
            text: ``,
            decorationColor: ``,
            margin: [0, 0, 0, 0],
            width: 20,
            fontSize: 15,
            decoration: ``,
            alignment: `left`,
          },
          {
            image: "unchecked",
            width: 10,
            margin: [0, 4, 0, 0],
          },
          {
            pageBreak: ``,
            color: ``,
            decoration: ``,
            decorationStyle: ``,
            width: 5,
            preserveLeadingSpaces: true,
            alignment: `left`,
            bold: false,
            decorationColor: ``,
            fontSize: 15,
            text: ``,
            margin: [0, 0, 0, 0],
          },
          {
            decorationStyle: ``,
            margin: [0, 0, 0, 0],
            preserveLeadingSpaces: true,
            bold: false,
            pageBreak: ``,
            color: ``,
            width: `auto`,
            alignment: `left`,
            fontSize: 15,
            decorationColor: ``,
            text: `การให้สารอาหารและน้ำทางสายยาง`,
            decoration: ``,
          },
        ],
      },
      {
        columns: [
          {
            decorationColor: ``,
            bold: false,
            decoration: ``,
            preserveLeadingSpaces: true,
            margin: [0, 0, 0, 0],
            pageBreak: ``,
            fontSize: 15,
            width: 20,
            color: ``,
            text: ``,
            alignment: `left`,
            decorationStyle: ``,
          },
          {
            image: "unchecked",
            width: 10,
            margin: [0, 4, 0, 0],
          },
          {
            color: ``,
            pageBreak: ``,
            alignment: `left`,
            decoration: ``,
            decorationColor: ``,
            preserveLeadingSpaces: true,
            width: 5,
            fontSize: 15,
            bold: false,
            text: ``,
            margin: [0, 0, 0, 0],
            decorationStyle: ``,
          },
          {
            decorationStyle: ``,
            color: ``,
            fontSize: 15,
            preserveLeadingSpaces: true,
            alignment: `left`,
            bold: false,
            text: `การกระตุ้นระบบไหลเวียน`,
            decoration: ``,
            width: `auto`,
            pageBreak: ``,
            margin: [0, 0, 0, 0],
            decorationColor: ``,
          },
        ],
      },
      {
        columns: [
          {
            margin: [0, 0, 0, 0],
            text: ``,
            decorationStyle: ``,
            fontSize: 15,
            decorationColor: ``,
            pageBreak: ``,
            alignment: `left`,
            preserveLeadingSpaces: true,
            bold: false,
            color: ``,
            width: 20,
            decoration: ``,
          },
          {
            image: "unchecked",
            width: 10,
            margin: [0, 4, 0, 0],
          },
          {
            decoration: ``,
            margin: [0, 0, 0, 0],
            text: ``,
            fontSize: 15,
            preserveLeadingSpaces: true,
            width: 5,
            bold: false,
            pageBreak: ``,
            alignment: `left`,
            decorationStyle: ``,
            color: ``,
            decorationColor: ``,
          },
          {
            decorationStyle: ``,
            decoration: ``,
            color: ``,
            text: `การเข้ารักษาในห้อง ไอ.ซี.ยู`,
            decorationColor: ``,
            width: `auto`,
            pageBreak: ``,
            margin: [0, 0, 0, 0],
            alignment: `left`,
            fontSize: 15,
            bold: false,
            preserveLeadingSpaces: true,
          },
        ],
      },
      {
        columns: [
          {
            preserveLeadingSpaces: true,
            bold: false,
            width: 20,
            decoration: ``,
            color: ``,
            pageBreak: ``,
            alignment: `left`,
            decorationColor: ``,
            margin: [0, 0, 0, 0],
            text: ``,
            decorationStyle: ``,
            fontSize: 15,
          },
          {
            image: "unchecked",
            width: 10,
            margin: [0, 4, 0, 0],
          },
          {
            fontSize: 15,
            width: 5,
            preserveLeadingSpaces: true,
            decoration: ``,
            decorationStyle: ``,
            color: ``,
            text: ``,
            margin: [0, 0, 0, 0],
            pageBreak: ``,
            bold: false,
            decorationColor: ``,
            alignment: `left`,
          },
          {
            decorationStyle: ``,
            alignment: `left`,
            decorationColor: ``,
            pageBreak: ``,
            bold: false,
            fontSize: 15,
            text: `การรักษาโรคแทรกซ้อนด้วยยาหรือวิธีการรักษาใดๆ`,
            decoration: ``,
            preserveLeadingSpaces: true,
            margin: [0, 0, 0, 0],
            color: ``,
            width: `auto`,
          },
        ],
      },
      {
        columns: [
          {
            margin: [0, 0, 0, 0],
            text: ``,
            color: ``,
            decorationStyle: ``,
            bold: false,
            alignment: `left`,
            pageBreak: ``,
            fontSize: 15,
            preserveLeadingSpaces: true,
            width: 20,
            decoration: ``,
            decorationColor: ``,
          },
          {
            image: "unchecked",
            width: 10,
            margin: [0, 4, 0, 0],
          },
          {
            fontSize: 15,
            bold: false,
            color: ``,
            alignment: `left`,
            width: 5,
            decorationColor: ``,
            preserveLeadingSpaces: true,
            margin: [0, 0, 0, 0],
            text: ``,
            pageBreak: ``,
            decoration: ``,
            decorationStyle: ``,
          },
          {
            text: `อื่นๆ .........................................................................................................................................................................................`,
            bold: false,
            preserveLeadingSpaces: true,
            decorationStyle: ``,
            decoration: ``,
            alignment: `left`,
            margin: [0, 0, 0, 0],
            fontSize: 15,
            color: ``,
            pageBreak: ``,
            width: `auto`,
            decorationColor: ``,
          },
        ],
      },
      {
        decorationStyle: ``,
        alignment: `left`,
        decoration: ``,
        margin: [0, 0, 0, 0],
        preserveLeadingSpaces: true,
        text: `ข้าพเจ้ารับทราบและเข้าใจดีแล้วว่า ข้าพเจ้าสามารถเพิกถอนการแสดงความจำนงนี้ได้ตลอดเวลา โดยการยกเลิกเอกสารนี้เป็น`,
        width: `auto`,
        decorationColor: ``,
        fontSize: 15,
        color: ``,
        pageBreak: ``,
        bold: false,
      },
      {
        text: `เป็นลายลักษณ์อักษร และแสดงความจำนงต่อแพทย์ด้วยวาจา หรือหากผู้ป่วยกลับมาอยู่สภาวะปกติและมีสติสัมปชัญญะ เอกสาร`,
        preserveLeadingSpaces: true,
        bold: false,
        fontSize: 15,
        alignment: `left`,
        decorationStyle: ``,
        color: ``,
        decorationColor: ``,
        decoration: ``,
        margin: [0, 0, 0, 0],
        width: `auto`,
        pageBreak: ``,
      },
      {
        bold: false,
        decorationStyle: ``,
        width: `auto`,
        color: ``,
        alignment: `left`,
        decorationColor: ``,
        decoration: ``,
        preserveLeadingSpaces: true,
        margin: [0, 0, 0, 0],
        text: `ฉบับนี้จะถูกยกเลิกทันที ข้าพเจ้าขอยืนยันว่า ความประสงค์ ดังกล่าว เป็นความประสงค์ของสมาชิกครอบครัว และญาติที่ใกล้ชิด`,
        fontSize: 15,
        pageBreak: ``,
      },
      {
        bold: false,
        preserveLeadingSpaces: true,
        decorationStyle: ``,
        alignment: `left`,
        pageBreak: ``,
        text: `ของผู้ป่วยจริง`,
        decorationColor: ``,
        fontSize: 15,
        decoration: ``,
        width: `auto`,
        color: ``,
        margin: [0, 0, 0, 0],
      },
      {
        width: `auto`,
        decoration: ``,
        preserveLeadingSpaces: true,
        bold: false,
        alignment: `left`,
        color: ``,
        pageBreak: ``,
        fontSize: 15,
        decorationStyle: ``,
        margin: [0, 0, 0, 0],
        decorationColor: ``,
        text: ` `,
      },
      {
        columns: [
          {
            width: `auto`,
            bold: false,
            text: `ลงชื่อ ...........................................................`,
            decorationStyle: ``,
            decorationColor: ``,
            fontSize: 15,
            color: ``,
            alignment: `left`,
            margin: [0, 0, 0, 0],
            decoration: ``,
            pageBreak: ``,
            preserveLeadingSpaces: true,
          },
          {
            decorationColor: ``,
            fontSize: 15,
            pageBreak: ``,
            text: ``,
            width: 5,
            preserveLeadingSpaces: true,
            decoration: ``,
            bold: false,
            color: ``,
            alignment: `left`,
            decorationStyle: ``,
            margin: [0, 0, 0, 0],
          },
          {
            text: `ผู้มีอำนาจตัดสินใจด้านการรักษาแทนผู้ป่วย`,
            bold: false,
            color: ``,
            margin: [0, 0, 0, 0],
            width: `auto`,
            preserveLeadingSpaces: true,
            decorationColor: ``,
            decoration: ``,
            decorationStyle: ``,
            alignment: `left`,
            fontSize: 15,
            pageBreak: ``,
          },
          {
            decoration: ``,
            width: 20,
            decorationStyle: ``,
            decorationColor: ``,
            pageBreak: ``,
            preserveLeadingSpaces: true,
            text: ``,
            fontSize: 15,
            bold: false,
            color: ``,
            margin: [0, 0, 0, 0],
            alignment: `left`,
          },
          {
            stack: [
              {
                text: props.items?.signedDate,
                decoration: ``,
                decorationStyle: ``,
                decorationColor: ``,
                width: `auto`,
                color: ``,
                bold: false,
                fontSize: 15,
                pageBreak: ``,
                alignment: `center`,
                preserveLeadingSpaces: true,
                margin: [0, 0, 0, -15],
              },
              {
                text: `..................................`,
                decoration: ``,
                decorationStyle: ``,
                decorationColor: ``,
                width: `auto`,
                color: ``,
                bold: false,
                fontSize: 15,
                pageBreak: ``,
                alignment: `center`,
                preserveLeadingSpaces: true,
                margin: [0, 0, 0, 0],
              },
            ],
            width: `auto`,
          },
        ],
      },
      {
        columns: [
          {
            decoration: ``,
            fontSize: 15,
            width: 25,
            margin: [0, 0, 0, 0],
            color: ``,
            alignment: `left`,
            decorationStyle: ``,
            preserveLeadingSpaces: true,
            decorationColor: ``,
            bold: false,
            text: ``,
            pageBreak: ``,
          },
          {
            preserveLeadingSpaces: true,
            decoration: ``,
            text: `(..........................................................)`,
            alignment: `left`,
            width: `auto`,
            fontSize: 15,
            margin: [0, 0, 0, 0],
            pageBreak: ``,
            color: ``,
            bold: false,
            decorationStyle: ``,
            decorationColor: ``,
          },
          {
            decorationColor: ``,
            bold: false,
            width: 220,
            margin: [0, 0, 0, 0],
            decoration: ``,
            alignment: `left`,
            preserveLeadingSpaces: true,
            fontSize: 15,
            color: ``,
            text: ``,
            decorationStyle: ``,
            pageBreak: ``,
          },
          {
            color: ``,
            text: `วันที่`,
            decorationColor: ``,
            fontSize: 15,
            preserveLeadingSpaces: true,
            alignment: `left`,
            decorationStyle: ``,
            pageBreak: ``,
            bold: false,
            margin: [0, 0, 0, 0],
            decoration: ``,
            width: `auto`,
          },
        ],
      },
      {
        preserveLeadingSpaces: true,
        pageBreak: ``,
        alignment: `left`,
        text: ` `,
        color: ``,
        margin: [0, 0, 0, 0],
        decorationColor: ``,
        decorationStyle: ``,
        width: `auto`,
        decoration: ``,
        fontSize: 15,
        bold: false,
      },
      {
        columns: [
          {
            color: ``,
            bold: false,
            decoration: ``,
            alignment: `left`,
            width: `auto`,
            margin: [0, 0, 0, 0],
            decorationColor: ``,
            decorationStyle: ``,
            fontSize: 15,
            pageBreak: ``,
            preserveLeadingSpaces: true,
            text: `ลงชื่อ ...........................................................`,
          },
          {
            bold: false,
            color: ``,
            decorationColor: ``,
            width: 5,
            text: ``,
            decorationStyle: ``,
            margin: [0, 0, 0, 0],
            preserveLeadingSpaces: true,
            decoration: ``,
            alignment: `left`,
            pageBreak: ``,
            fontSize: 15,
          },
          {
            alignment: `left`,
            text: `พยาน`,
            color: ``,
            margin: [0, 0, 0, 0],
            preserveLeadingSpaces: true,
            width: `auto`,
            fontSize: 15,
            bold: false,
            decorationStyle: ``,
            pageBreak: ``,
            decorationColor: ``,
            decoration: ``,
          },
          {
            margin: [0, 0, 0, 0],
            decoration: ``,
            color: ``,
            alignment: `left`,
            fontSize: 15,
            preserveLeadingSpaces: true,
            text: ``,
            pageBreak: ``,
            width: 75,
            decorationColor: ``,
            bold: false,
            decorationStyle: ``,
          },
          {
            width: `auto`,
            decorationStyle: ``,
            decorationColor: ``,
            pageBreak: ``,
            margin: [0, 0, 0, 0],
            text: `ลงชื่อ ...........................................................`,
            color: ``,
            fontSize: 15,
            alignment: `left`,
            bold: false,
            decoration: ``,
            preserveLeadingSpaces: true,
          },
          {
            decorationColor: ``,
            decoration: ``,
            text: ``,
            fontSize: 15,
            pageBreak: ``,
            bold: false,
            preserveLeadingSpaces: true,
            margin: [0, 0, 0, 0],
            decorationStyle: ``,
            alignment: `left`,
            color: ``,
            width: 5,
          },
          {
            fontSize: 15,
            decoration: ``,
            preserveLeadingSpaces: true,
            decorationStyle: ``,
            margin: [0, 0, 0, 0],
            bold: false,
            decorationColor: ``,
            color: ``,
            width: `auto`,
            text: `พยาน`,
            pageBreak: ``,
            alignment: `left`,
          },
        ],
      },
      {
        columns: [
          {
            color: ``,
            preserveLeadingSpaces: true,
            decorationColor: ``,
            text: ``,
            width: 25,
            pageBreak: ``,
            decoration: ``,
            bold: false,
            fontSize: 15,
            alignment: `left`,
            margin: [0, 0, 0, 0],
            decorationStyle: ``,
          },
          {
            pageBreak: ``,
            color: ``,
            width: `auto`,
            bold: false,
            decorationColor: ``,
            decorationStyle: ``,
            margin: [0, 0, 0, 0],
            text: `(..........................................................)`,
            alignment: `left`,
            fontSize: 15,
            decoration: ``,
            preserveLeadingSpaces: true,
          },
          {
            color: ``,
            decorationColor: ``,
            text: ``,
            width: 125,
            pageBreak: ``,
            fontSize: 15,
            decoration: ``,
            margin: [0, 0, 0, 0],
            preserveLeadingSpaces: true,
            decorationStyle: ``,
            bold: false,
            alignment: `left`,
          },
          {
            pageBreak: ``,
            preserveLeadingSpaces: true,
            bold: false,
            fontSize: 15,
            decorationStyle: ``,
            alignment: `left`,
            width: `auto`,
            text: `(..........................................................)`,
            decoration: ``,
            color: ``,
            decorationColor: ``,
            margin: [0, 0, 0, 0],
          },
        ],
      },
      {
        margin: [0, 0, 0, 0],
        pageBreak: ``,
        preserveLeadingSpaces: true,
        decorationColor: ``,
        width: `auto`,
        decorationStyle: ``,
        decoration: ``,
        bold: false,
        color: ``,
        text: ` `,
        fontSize: 15,
        alignment: `left`,
      },
      {
        decorationColor: ``,
        color: ``,
        fontSize: `12`,
        pageBreak: `after`,
        decorationStyle: ``,
        margin: [0, 0, 0, 0],
        preserveLeadingSpaces: true,
        bold: false,
        width: `auto`,
        text: `หมายเหตุ : ระบุชื่อผู้ที่ร้องขอไม่ให้ช่วยฟื้นคืนชีวิตผู้ป่วย และความเกี่ยวข้องกับผู้ป่วย (เช่น ญาติสายตรง หรือผู้แทนโดยชอบตามกฎหมาย)`,
        decoration: ``,
        alignment: `left`,
      },
      {
        margin: [0, 0, 0, 0],
        decorationStyle: ``,
        bold: `true`,
        pageBreak: ``,
        preserveLeadingSpaces: true,
        text: `Do Not Resuscitate Request`,
        color: ``,
        decorationColor: ``,
        alignment: `center`,
        decoration: ``,
        fontSize: `18`,
        width: `auto`,
      },
      {
        text: ` `,
        decorationColor: ``,
        decoration: ``,
        alignment: `left`,
        pageBreak: ``,
        margin: [0, 0, 0, 0],
        fontSize: 15,
        decorationStyle: ``,
        color: ``,
        width: `auto`,
        preserveLeadingSpaces: true,
        bold: false,
      },
      {
        preserveLeadingSpaces: true,
        margin: [0, 0, 0, 0],
        decorationColor: ``,
        decorationStyle: ``,
        text: `รายชื่อญาติสนิทที่เข้าร่วมประชุม และตกลงร่วมกัน เรื่องไม่ให้ช่วยฟื้นคืนชีวิต ผู้ป่วย (พร้อมรับรองสำเนาบัตรประชาชน/พาสปอร์ต)`,
        alignment: `left`,
        fontSize: 15,
        decoration: ``,
        color: ``,
        pageBreak: ``,
        width: `auto`,
        bold: false,
      },
      {
        preserveLeadingSpaces: true,
        decorationStyle: ``,
        text: ` `,
        pageBreak: ``,
        decorationColor: ``,
        alignment: `left`,
        margin: [0, 0, 0, 0],
        fontSize: 15,
        decoration: ``,
        bold: false,
        width: `auto`,
        color: ``,
      },
      {
        columns: [
          {
            bold: false,
            margin: [0, 0, 0, 0],
            color: ``,
            pageBreak: ``,
            preserveLeadingSpaces: true,
            text: ``,
            decorationColor: ``,
            decorationStyle: ``,
            alignment: `left`,
            decoration: ``,
            width: 15,
            fontSize: 15,
          },
          {
            width: `auto`,
            text: `1) ................................................................................................................. เกี่ยวข้องเป็น ............................................................`,
            bold: false,
            margin: [0, 0, 0, 0],
            color: ``,
            pageBreak: ``,
            decorationStyle: ``,
            preserveLeadingSpaces: true,
            decorationColor: ``,
            alignment: `left`,
            decoration: ``,
            fontSize: 15,
          },
        ],
      },
      {
        columns: [
          {
            bold: false,
            pageBreak: ``,
            decoration: ``,
            text: ``,
            width: 15,
            margin: [0, 0, 0, 0],
            alignment: `left`,
            decorationStyle: ``,
            color: ``,
            preserveLeadingSpaces: true,
            decorationColor: ``,
            fontSize: 15,
          },
          {
            preserveLeadingSpaces: true,
            decorationStyle: ``,
            color: ``,
            pageBreak: ``,
            decorationColor: ``,
            bold: false,
            margin: [0, 0, 0, 0],
            alignment: `left`,
            decoration: ``,
            fontSize: 15,
            width: `auto`,
            text: `2) ................................................................................................................. เกี่ยวข้องเป็น ............................................................`,
          },
        ],
      },
      {
        columns: [
          {
            text: ``,
            decorationStyle: ``,
            margin: [0, 0, 0, 0],
            alignment: `left`,
            decoration: ``,
            preserveLeadingSpaces: true,
            width: 15,
            pageBreak: ``,
            color: ``,
            decorationColor: ``,
            bold: false,
            fontSize: 15,
          },
          {
            decorationStyle: ``,
            color: ``,
            width: `auto`,
            preserveLeadingSpaces: true,
            pageBreak: ``,
            margin: [0, 0, 0, 0],
            alignment: `left`,
            text: `3) ................................................................................................................. เกี่ยวข้องเป็น ............................................................`,
            decoration: ``,
            bold: false,
            fontSize: 15,
            decorationColor: ``,
          },
        ],
      },
      {
        columns: [
          {
            decoration: ``,
            margin: [0, 0, 0, 0],
            decorationStyle: ``,
            decorationColor: ``,
            pageBreak: ``,
            preserveLeadingSpaces: true,
            bold: false,
            text: ``,
            fontSize: 15,
            width: 15,
            alignment: `left`,
            color: ``,
          },
          {
            preserveLeadingSpaces: true,
            alignment: `left`,
            fontSize: 15,
            decorationColor: ``,
            margin: [0, 0, 0, 0],
            width: `auto`,
            pageBreak: ``,
            decorationStyle: ``,
            color: ``,
            bold: false,
            decoration: ``,
            text: `4) ................................................................................................................. เกี่ยวข้องเป็น ............................................................`,
          },
        ],
      },
      {
        columns: [
          {
            bold: false,
            width: 15,
            fontSize: 15,
            decorationColor: ``,
            decoration: ``,
            pageBreak: ``,
            color: ``,
            decorationStyle: ``,
            preserveLeadingSpaces: true,
            text: ``,
            alignment: `left`,
            margin: [0, 0, 0, 0],
          },
          {
            decoration: ``,
            decorationColor: ``,
            pageBreak: ``,
            decorationStyle: ``,
            preserveLeadingSpaces: true,
            margin: [0, 0, 0, 0],
            alignment: `left`,
            bold: false,
            fontSize: 15,
            width: `auto`,
            text: `5) ................................................................................................................. เกี่ยวข้องเป็น ............................................................`,
            color: ``,
          },
        ],
      },
      {
        columns: [
          {
            decorationStyle: ``,
            alignment: `left`,
            width: 15,
            decoration: ``,
            preserveLeadingSpaces: true,
            fontSize: 15,
            decorationColor: ``,
            text: ``,
            color: ``,
            margin: [0, 0, 0, 0],
            bold: false,
            pageBreak: ``,
          },
          {
            alignment: `left`,
            pageBreak: ``,
            decorationColor: ``,
            decorationStyle: ``,
            text: `6) ................................................................................................................. เกี่ยวข้องเป็น ............................................................`,
            decoration: ``,
            bold: false,
            fontSize: 15,
            width: `auto`,
            color: ``,
            margin: [0, 0, 0, 0],
            preserveLeadingSpaces: true,
          },
        ],
      },
      {
        columns: [
          {
            pageBreak: ``,
            decorationColor: ``,
            decoration: ``,
            decorationStyle: ``,
            width: 15,
            alignment: `left`,
            bold: false,
            color: ``,
            margin: [0, 0, 0, 0],
            fontSize: 15,
            preserveLeadingSpaces: true,
            text: ``,
          },
          {
            pageBreak: ``,
            decorationStyle: ``,
            decorationColor: ``,
            alignment: `left`,
            fontSize: 15,
            bold: false,
            decoration: ``,
            text: `7) ................................................................................................................. เกี่ยวข้องเป็น ............................................................`,
            margin: [0, 0, 0, 0],
            preserveLeadingSpaces: true,
            width: `auto`,
            color: ``,
          },
        ],
      },
    ],
    pageSize: `A4`,
    defaultStyle: {
      font: `THSarabunNew`,
    },
    pageOrientation: `portrait`,
    images: injectImages(props.items?.haveHeader),
  };
}
