import React, { useState, useEffect, useMemo, SyntheticEvent } from "react";

import moment from "moment";
import CardPatientInfoREGUX from "./CardPatientInfoREGUX";
import ModOpenEmergencyUX from "./ModOpenEmergencyUX";
import {
  Message,
  Modal,
  Button,
  Input,
  Checkbox,
  Radio,
  Segment,
  Dropdown,
  Image,
} from "semantic-ui-react";

// Common
import SnackMessage from "react-lib/apps/common/SnackMessage";
import ButtonLoadCheck from "react-lib/appcon/common/ButtonLoadCheck";
import ErrorMessage from "react-lib/apps/common/ErrorMessage";
import DateTextBox from "react-lib/apps/common/DateTextBox";
import { formatDate } from "../../../utils/dateUtils";

import ModAliasName from "react-lib/apps/common/ModAliasName";

// CSS
import "./CardPatientInfoREGUX.scss";

// Utils
import { beToAd } from "react-lib/utils";

const getAge = (birthdate: any) => {
  if (birthdate.isValid()) {
    var today = moment().startOf("day");
    var years = today.diff(birthdate, "year");
    birthdate.add(years, "years");
    var months = today.diff(birthdate, "months");
    birthdate.add(months, "months");
    var days = today.diff(birthdate, "days");
    return years + " ปี " + months + " เดือน " + days + " วัน";
  }
  return "";
};

const requiredField = [
  "info_channel",
  "gender",
  "blood_type",
  "birthdate",
  "nationality",
  "father",
  "mother",
  "owner_name",
  "tel_mobile",
];

const alertMessages: Record<string, string> = {
  info_channel: "ช่องทางให้ข้อมูล",
  gender: "เพศ",
  blood_type: "หมู่เลือด",
  birthdate: "วันเดือนปีเกิด",
  nationality: "สัญชาติ",
  father: "ชื่อบิดา",
  mother: "ชื่อมารดา",
  owner_name: "ชื่อผู้ติดต่อฉุกเฉิน",
  tel_mobile: "เบอร์โทรผู้ติดต่อ",

  citizen_type: "เอกสารระบุตัวตน",
  citizen_no: "หมายเลขบัตรประจำตัวประชาชน",
  passport_no: "หมายเลขที่หนังสือเดินทาง",
  alien_no: "หมายเลขใบต่างด้าว",

  pre_name_th: "คำนำหน้า",
  first_name_th: "ชื่อ",
  last_name_th: "นามสกุล",

  pre_name_en: "Prefix",
  first_name_en: "First name",
  last_name_en: "Last name",
  // middle_name_en: "Middle name",

  nickname: "ชื่อนามแฝง",
  nickname_permit_no: "เลขที่อนุมัตินามแฝง",
  nickname_reason: "เหตุผล",
  nickname_warrantor: "ผู้อนุมัตินามแฝง",
};

const IMAGES = {
  //icon
  mobilePhoneLockInv: "/static/images/regSearch/mobilePhoneLockInv.png",
  mobilePhonCheck: "/static/images/regSearch/mobilePhonCheck.png",
};

const oneOfRequiredTH = ["pre_name_th", "first_name_th", "last_name_th"];

const oneOfRequiredEN = ["pre_name_en", "first_name_en", "last_name_en"];

const CARD_PATIENT_INFO = "CardPatientInfo";
const MOD_OPEN_EMERGENCY = "ModOpenEmergencyUX";

const CardPatientInfo = (props: any) => {
  const [open, setOpen] = useState<boolean>(false);
  const [openSnackMessage, setOpenSnackMessage] = useState<boolean>(false);
  const [openModCancelNickname, setOpenCancelNickname] = useState<boolean>(false);
  const [openModEmergency, setOpenModEmergency] = useState<boolean>(false);
  const [openEditName, setOpenEditName] = useState<boolean>(false);
  const [aliasModal, setAliasModal] = useState<boolean>(false);
  const [aliasData, setAliasData] = useState<any>();
  const [disableNameRefNo, setDisableNameRefNo] = useState<boolean>(false);
  const [textAlert, setTextAlert] = useState<any>();

  const birthdate_ad = beToAd(props.patient?.birthdate)?.startOf("day");
  const age = typeof birthdate_ad === "undefined" ? "" : getAge(birthdate_ad);

  useEffect(() => {
    if (props.errorMessage?.SetPatient !== null && props.errorMessage?.SetPatient !== undefined) {
      if (props.errorMessage?.SetPatient.alias_patients) {
        // patientDetail[1].items
        console.log(props.errorMessage?.SetPatient);
        setAliasModal(true);
        setAliasData(props.errorMessage?.SetPatient.items);
      } else {
        setOpenSnackMessage(true);
      }

      setTimeout(() => {
        setOpenSnackMessage(false);
        props.runSequence({
          sequence: "SetPatient",
          action: "clear",
        });
      }, 2000);
    }

    if (
      props.successMessage?.SetPatient !== null &&
      props.successMessage?.SetPatient !== undefined
    ) {
      props.runSequence({
        sequence: "SetPatient",
        action: "clear",
      });
    }
  }, [props.errorMessage?.SetPatient, props.successMessage?.SetPatient]);

  useEffect(() => {
    if (props?.successMessage?.[MOD_OPEN_EMERGENCY]) {
      setOpenModEmergency(false);
    }
  }, [props?.successMessage?.[MOD_OPEN_EMERGENCY]]);

  const reasonOptions = useMemo(() => {
    return [
      { id: "0", value: "0", text: "-" },
      ...(props?.masterOptions?.nicknamereason || []),
    ].map((item: any) => ({
      key: item.id,
      value: item.value,
      text: item.text,
    }));
  }, [props?.masterOptions?.nicknamereason]);

  const handleAddOptions = (e: SyntheticEvent, v: any) => {
    props.setProp("masterOptions.nicknamereason", [
      ...props?.masterOptions?.nicknamereason,
      { key: v.value, text: v.value, value: v.value },
    ]);
  };

  const changeProp = (key: string) => (e: any, v: any) => {
    if (v.type === "checkbox") {
      props.setProp(`SetPatientSequence.patient.${key}`, v.checked);
    } else if (v.type === "radio") {
      props.setProp(`SetPatientSequence.${key}`, v.value);
    } else {
      if (key === "nickname") {
        props.setProp(`SetPatientSequence.patient`, {
          ...props?.SetPatientSequence.patient,
          nickname: v.value,
          nickname_first_name: v.value,
          nickname_last_name: v.value,
        });
      } else if (key === "citizen_type") {
        props.setProp(`SetPatientSequence.patient`, {
          ...props?.SetPatientSequence.patient,
          citizen_type: v.value,
          citizen_no: "",
          passport_no: "",
          alien_no: "",
        });
      } else {
        props.setProp(`SetPatientSequence.patient.${key}`, v.value);
      }
    }
  };

  const changeValue = (key: string) => (e: any, v: any) => {
    props.setProp(`SetPatientSequence.patientEmergency.${key}`, v.value);
  };

  const changeBirthdateSpecial = (_event: any, data: any) => {
    props.setProp("SetPatientSequence.patient.birthdate", "");
    props.setProp(`SetPatientSequence.patient.${data.name}`, data.checked);
  };

  const idKey = (citizen_type: string) => {
    const key: string =
      {
        T: "citizen_no",
        F: "passport_no",
        A: "alien_no",
        "": "id_number",
      }?.[citizen_type as string] || "";
    return key;
  };

  const changeIdNumber = (e: any, v: any) => {
    const key = idKey(props.patient?.citizen_type || "");
    props.setProp(`SetPatientSequence.patient.${key}`, v.value);
  };

  const changeDate = (key: string) => (date: any) =>
    props.setProp(`SetPatientSequence.patient.${key}`, date);

  const handleCheckNameActive = (e: any, v: any) => {
    if (
      props?.SetPatientSequence?.isNicknameActive &&
      props?.SetPatientSequence?.patient?.nickname_active
    ) {
      setOpenCancelNickname(true);
    } else {
      const value = typeof v.checked === "boolean" ? v.checked : v.value;
      if (value) {
        props.setProp(`SetPatientSequence.patient.nickname_active`, value);
      } else {
        props.setProp(`SetPatientSequence.patient`, {
          ...props.SetPatientSequence.patient,
          nickname_active: value,
          nickname: "",
          nickname_permit_no: "",
          nickname_reason: "",
          nickname_warrantor: "",
        });
      }
    }
  };

  const handleCheckEditName = () => {
    setDisableNameRefNo(false);

    let newName = props.SetPatientSequence?.patient;
    let oldName = props.SetPatientSequence?.patientOldName;

    const nameFields = [
      // "pre_name_th",
      "first_name_th",
      "middle_name_th",
      "last_name_th",
      // "pre_name_en",
      "first_name_en",
      // "middle_name_en",
      "last_name_en",
    ];

    const isFieldChanged = (field: string): boolean => {
      return newName?.[field] != oldName?.[`${field}_old`];
    };

    // #const isCitizenTypeChanged = (): boolean =>
    //   newName?.citizen_type != oldName?.citizenType;

    // #const isCitizenInfoChanged = (field: any, type: string): boolean => {
    //   const citizenType = newName?.citizen_type;
    //   return (
    //     citizenType === oldName?.citizenType &&
    //     citizenType === type &&
    //     newName?.[`${field}_no`] != oldName?.[`${field}No`]
    //   );
    // };

    for (const field of nameFields) {
      if (isFieldChanged(field)) {
        return true;
      }
    }

    return false;
    // #if (
    //   isCitizenTypeChanged() ||
    //   // * เช็คการเปลี่ยนเอกสารระบุตัวตย
    //   isCitizenInfoChanged("citizen", "T") ||
    //   isCitizenInfoChanged("passport", "F") ||
    //   isCitizenInfoChanged("alien", "A")
    // ) {
    //   setDisableNameRefNo(true);
    //   return true;
    // } else {
    //   return false;
    // }
  };

  const handleSavePatient = (confirmDupplicateSave: boolean) => {
    setOpen(false);

    let isCompleted = true;
    let textAlert: string[] = [];
    let ageYear = age.split("ปี");

    // เช็คข้อมูลที่จำเป็น
    requiredField.forEach((item: string) => {
      const patientValue = props.patient?.[item];
      const relativeAddressValue = props.patient?.relative_address?.[item];

      if (item === "owner_name" || item === "tel_mobile") {
        if (!relativeAddressValue) {
          isCompleted = false;
          textAlert.push(alertMessages[item]);
        }
      } else if (item === "father" || item === "mother") {
        if (Number(ageYear?.[0]) < 20 && patientValue === "") {
          isCompleted = false;
          textAlert.push(alertMessages[item]);
        }
      } else if (patientValue === "") {
        isCompleted = false;
        textAlert.push(alertMessages[item]);
      }
    });

    // เช็คเลขบัตรจากชนิดที่เลือก
    if (props.patient?.citizen_type === "") {
      isCompleted = false;
      textAlert.push(alertMessages.citizen_type);
    } else if (props.patient?.[idKey(props?.patient?.citizen_type || "")] === "") {
      isCompleted = false;
      textAlert.push(alertMessages[idKey(props.patient?.citizen_type)]);
    }

    // เช็คนามเเฝง
    if (props.patient?.nickname_active) {
      if (!props.patient?.nickname) {
        isCompleted = false;
        textAlert.push(alertMessages.nickname);
      }
      if (!props.patient?.nickname_permit_no) {
        isCompleted = false;
        textAlert.push(alertMessages.nickname_permit_no);
      }
      if (!props.patient?.nickname_reason) {
        isCompleted = false;
        textAlert.push(alertMessages.nickname_reason);
      }
      if (!props.patient?.nickname_warrantor) {
        isCompleted = false;
        textAlert.push(alertMessages.nickname_warrantor);
      }
    }

    // เช็คชื่อว่าใส่ครบทั้งแผง
    if (props?.patient?.citizen_type === "" || props?.patient?.citizen_type === "T") {
      oneOfRequiredTH.forEach((item: any) => {
        if (props.patient?.[item] === "") {
          isCompleted = false;
          textAlert.push(alertMessages[item]);
        }
      });
    } else if (props?.patient?.citizen_type !== "" && props?.patient?.citizen_type !== "T") {
      oneOfRequiredEN.forEach((item: any) => {
        if (props.patient?.[item] === "") {
          isCompleted = false;
          textAlert.push(alertMessages[item]);
        }
      });
    }

    // กรณีกรอกข้อมูลครบทุกช่อง
    if (isCompleted) {
      setOpen(false);
      setTextAlert([]);

      // เช็คการเปลี่ยนชื่อ
      if (props.SetPatientSequence?.patientOldName && handleCheckEditName()) {
        setOpenEditName(true);
      } else {
        props.runSequence({
          sequence: "SetPatient",
          confirmSave: confirmDupplicateSave,
          action: "save",
          buttonLoadKey: `${CARD_PATIENT_INFO}_SAVE`,
        });
      }
    } else {
      setOpen(true);
      setTextAlert(textAlert.length ? { กรอกข้อมูลที่จำเป็น: Object.values(textAlert) } : null);
      alert("กรอกข้อมูลที่จำเป็น");
    }
  };

  const nonResidentChk = (
    <div style={{ paddingTop: "10px" }}>
      <Checkbox
        checked={props.patient?.non_resident || false}
        label="non-resident"
        onChange={changeProp("non_resident")}
      />
    </div>
  );

  const handleCloseModEditName = async () => {
    props.setProp("SetPatientSequence.patient.change_name_ref_no", "");

    await props.setProp("SetPatientSequence.patient.change_name_reason", "");

    setOpenEditName(false);
  };

  // console.log("PatientInfo", props);

  return (
    <div>
      {open && (
        <div style={{ padding: "10px" }}>
          <ErrorMessage error={textAlert} />
        </div>
      )}

      {openSnackMessage && (
        <SnackMessage
          onEvent={props.onEvent}
          onClose={() => {
            setOpenSnackMessage(false);
            props.runSequence({
              sequence: "SetPatient",
              action: "clear",
            });
          }}
          error={props.errorMessage?.SetPatient}
          success={props.successMessage?.SetPatient}
        />
      )}

      <ModAliasName
        openModal={aliasModal}
        data={aliasData}
        content="พบชื่อคล้ายกันในระบบ ต้องการบันทึกผู้ป่วยใหม่เพิ่มหรือไม่"
        onApprove={() => {
          setAliasData([]);
          setAliasModal(false);
          handleSavePatient(true);
        }}
        onDeny={() => {
          setAliasData([]);
          setAliasModal(false);
        }}
      />

      <CardPatientInfoREGUX
        // class
        className="card-patient-info-regux"
        // Fields
        masterOptions={props.masterOptions}
        patient={props.patient}
        secretOptions={[
          { key: "N", value: "N", text: "ไม่ปกปิด" },
          { key: "S", value: "S", text: "ปกปิดประวัติ" },
        ]}
        citizenTypeOptions={[
          { key: "T", value: "T", text: "เลขประจำตัวประชาชน" },
          { key: "F", value: "F", text: "เลขที่หนังสือเดินทาง" },
          { key: "A", value: "A", text: "ใบต่างด้าว" },
          { key: "N", value: "N", text: "ไม่มีเอกสารยืนยันตัว" },
        ]}
        citizenLable={
          props.patient?.citizen_type === "T"
            ? "หมายเลขบัตรประจำตัวประชาชน / Identification number*"
            : props.patient?.citizen_type === "F"
            ? "หมายเลขที่หนังสือเดินทาง / Passport number*"
            : props.patient?.citizen_type === "A"
            ? "หมายเลขใบต่างด้าว / Alien number*"
            : props.patient?.citizen_type === "N"
            ? ""
            : "หมายเลขบัตรประจำตัวประชาชน / Identification number*"
        }
        citizenInput={
          props.patient?.citizen_type !== "N" ? (
            <Input
              onChange={changeProp(`${[idKey(props?.patient?.citizen_type || "T")]}`)}
              value={props.patient?.[idKey(props?.patient?.citizen_type || "T")]}
              style={{ width: "100%" }}
            />
          ) : (
            nonResidentChk
          )
        }
        reimburseLabel={
          (props.patient?.citizen_type === "F" || props.patient?.citizen_type === "A") &&
          "เลขประจำตัวกรมบัญชีกลาง"
        }
        reimburseInput={
          props.patient?.citizen_type === "F" || props.patient?.citizen_type === "A" ? (
            <Input
              onChange={changeProp("reimburse_foreign_code")}
              value={props.patient?.reimburse_foreign_code}
              style={{ width: "100%" }}
            />
          ) : (
            props.patient?.citizen_type === "T" && nonResidentChk
          )
        }
        nonResidentChk={
          (props.patient?.citizen_type === "F" || props.patient?.citizen_type === "A") &&
          nonResidentChk
        }
        id_number={props.patient?.[idKey(props?.patient?.citizen_type || "")]}
        age={age}
        isRequired={Number(age.split("ปี")?.[0]) < 20}
        marriageOptions={[
          { key: "S", value: "S", text: "โสด" },
          { key: "M", value: "M", text: "สมรส" },
          { key: "E", value: "E", text: "หมั้น" },
          { key: "D", value: "D", text: "หย่า" },
          { key: "W", value: "W", text: "หม้าย" },
          { key: "A", value: "A", text: "แยกกันอยู่" },
        ]}
        name={props?.patient?.name || ""}
        isNicknameActive={props?.SetPatientSequence?.isNicknameActive || ""}
        reasonOptions={reasonOptions}
        onAddOptions={handleAddOptions}
        dateTextBox={
          <DateTextBox
            disabled={props.patient?.use_birthdate_special}
            id="dt-dob"
            onChange={changeDate("birthdate")}
            maxDate={formatDate(moment())}
            style={{ color: "blue" }}
            value={props.patient?.use_birthdate_special ? "" : props.patient?.birthdate}
          ></DateTextBox>
        }
        buttonSecret={
          <ButtonLoadCheck
            // function
            setProp={props.setProp}
            onClick={() => {
              if (props?.patient?.id) {
                props.runSequence({
                  sequence: "SetPatient",
                  action: "secret",
                  card: CARD_PATIENT_INFO,
                  buttonLoadKey: `${CARD_PATIENT_INFO}_SECRET`,
                  active: true,
                });
              } else {
                alert("กรุณาระบุ HN");
              }
            }}
            // data
            paramKey={`${CARD_PATIENT_INFO}_SECRET`}
            buttonLoadCheck={props.buttonLoadCheck?.[`${CARD_PATIENT_INFO}_SECRET`]}
            // config
            name="SECRET"
            style={{ width: "100%" }}
            title="ปกปิดประวัติผู้ป่วย"
            color="green"
          />
        }
        buttonSave={
          <ButtonLoadCheck
            // function
            setProp={props.setProp}
            onClick={() => {
              handleSavePatient(false);
            }}
            // data
            paramKey={`${CARD_PATIENT_INFO}_SAVE`}
            buttonLoadCheck={props.buttonLoadCheck?.[`${CARD_PATIENT_INFO}_SAVE`]}
            // config
            name="SAVE"
            style={{ width: "100%" }}
            title="SAVE"
            color="green"
          />
        }
        buttonVerify={
          props.patient?.profile_status === "NOT_VERIFIED" ? (
            <ButtonLoadCheck
              // function
              setProp={props.setProp}
              onClick={() => {
                props.runSequence({
                  sequence: "SetPatient",
                  action: "verified",
                  buttonLoadKey: `${CARD_PATIENT_INFO}_VERIFY`,
                });
              }}
              // data
              paramKey={`${CARD_PATIENT_INFO}_VERIFY`}
              buttonLoadCheck={props.buttonLoadCheck?.[`${CARD_PATIENT_INFO}_VERIFY`]}
              // config
              style={{ width: "100%" }}
              title={
                <div style={{ display: "flex", alignItems: "center" }}>
                  <Image
                    height={20}
                    src={IMAGES.mobilePhoneLockInv}
                    style={{ marginRight: "10px" }}
                  />
                  <div style={{ color: "white", fontSize: "12px" }}>กดเพื่อยืนยันประวัติ</div>
                </div>
              }
              color="red"
            />
          ) : (
            props.patient?.profile_status === "VERIFIED" && (
              <div style={{ display: "flex", alignItems: "center" }}>
                <Image height={20} src={IMAGES.mobilePhonCheck} style={{ marginRight: "10px" }} />
                <div style={{ color: "green", fontSize: "12px" }}>ยืนยันประวัติแล้ว</div>
              </div>
            )
          )
        }
        OnEmergency={() => setOpenModEmergency(true)}
        // Handler
        changeProp={changeProp}
        changeBirthdateSpecial={changeBirthdateSpecial}
        changeIdNumber={changeIdNumber}
        changeDate={changeDate}
        handleCheckNameActive={handleCheckNameActive}
      />

      <Modal
        open={openModCancelNickname}
        size="tiny"
        onClose={() => {
          setOpenCancelNickname(false);
        }}
      >
        <Modal.Header>ยกเลิกนามแฝง</Modal.Header>
        <Modal.Content>
          <div>
            <div style={{ marginBottom: "5px" }}> เหตุผล </div>

            {(props?.masterOptions?.nicknameActive || []).map((item: any) => {
              return (
                <div style={{ display: "flex", padding: "5px" }}>
                  <Radio
                    checked={props.SetPatientSequence?.checkCancelNickname === item.text}
                    onChange={changeProp("checkCancelNickname")}
                    value={item.text}
                  ></Radio>

                  <div style={{ marginLeft: "5px" }}> {item.text}</div>
                </div>
              );
            })}
          </div>

          <div style={{ padding: "15px 0px 0px 0px", display: "flex" }}>
            <Button
              color="green"
              content="ตกลง"
              style={{ marginRight: "10px" }}
              onClick={() => {
                props.setProp("SetPatientSequence.patient.nickname_active", false);
                setOpenCancelNickname(false);
              }}
            />

            <Button
              color="red"
              content="ยกเลิก"
              onClick={() => {
                setOpenCancelNickname(false);
              }}
            />
          </div>
        </Modal.Content>
      </Modal>

      <Modal
        open={openModEmergency}
        size="small"
        onClose={() => {
          setOpenModEmergency(false);
          props.setProp(`errorMessage.${MOD_OPEN_EMERGENCY}`, null);
        }}
      >
        <ModOpenEmergencyUX
          hn={props?.SetPatientSequence?.patientEmergency?.hn}
          first_name={props?.SetPatientSequence?.patientEmergency?.first_name}
          last_name={props?.SetPatientSequence?.patientEmergency?.last_name}
          gender={props?.SetPatientSequence?.patientEmergency?.gender}
          ErrorMessage={<ErrorMessage error={props?.errorMessage?.[MOD_OPEN_EMERGENCY]} />}
          changeValue={changeValue}
          OnSave={() => {
            props.runSequence({
              sequence: "SetPatient",
              action: "patient_emergency",
              card: MOD_OPEN_EMERGENCY,
            });
          }}
          OnClear={() => {
            props.runSequence({
              sequence: "SetPatient",
              action: "clear_emergency",
              card: MOD_OPEN_EMERGENCY,
            });
          }}
          onClose={() => {
            setOpenModEmergency(false);
            props.setProp(`errorMessage.${MOD_OPEN_EMERGENCY}`, null);
          }}
        />
      </Modal>

      <Modal size="tiny" open={openEditName} onClose={() => setOpenEditName(false)}>
        <Segment inverted className="blue" style={{ margin: "0px" }}>
          ผู้ป่วยเปลี่ยนชื่อ-นามสกุล
        </Segment>
        <div style={{ margin: "10px 20px" }}>
          <div style={{ fontWeight: "bold" }}>เหตุผล</div>
          <div style={{ margin: "10px 0px" }}>
            <Dropdown
              clearable={true}
              fluid={true}
              id="cb-identify"
              onChange={changeProp("change_name_reason")}
              options={[
                {
                  key: 1,
                  value: "ผู้ป่วยเปลี่ยนชื่อ",
                  text: "ผู้ป่วยเปลี่ยนชื่อ",
                },
                {
                  key: 2,
                  value: "เปลี่ยนหมายเลขหนังสือเดินทาง / ใบต่างด้าว",
                  text: "เปลี่ยนหมายเลขหนังสือเดินทาง / ใบต่างด้าว",
                },
                {
                  key: 3,
                  value: "เจ้าหน้าที่บันทึกข้อมูลผิด",
                  text: "เจ้าหน้าที่บันทึกข้อมูลผิด",
                },
                {
                  key: 4,
                  value: "ผู้ป่วยกรอกข้อมูลผิด",
                  text: "ผู้ป่วยกรอกข้อมูลผิด",
                },
                {
                  key: 5,
                  value: "ญาติกรอกข้อมูลผิด",
                  text: "ญาติกรอกข้อมูลผิด",
                },
                {
                  key: 6,
                  value: "ร้องขอเปลี่ยนคำนำหน้าชื่อ โดยผู้ป่วย",
                  text: "ร้องขอเปลี่ยนคำนำหน้าชื่อ โดยผู้ป่วย",
                },
                {
                  key: 7,
                  value: "ร้องขอเปลี่ยนชื่อตัว โดยผู้ป่วย",
                  text: "ร้องขอเปลี่ยนชื่อตัว โดยผู้ป่วย",
                },
                {
                  key: 8,
                  value: "ร้องขอเปลี่ยนชื่อสกุล โดยผู้ป่วย",
                  text: "ร้องขอเปลี่ยนชื่อสกุล โดยผู้ป่วย",
                },
                {
                  key: 9,
                  value: "ร้องขอเปลี่ยนชื่อรอง โดยผู้ป่วย",
                  text: "ร้องขอเปลี่ยนชื่อรอง โดยผู้ป่วย",
                },
                {
                  key: 10,
                  value: "ร้องขอเปลี่ยนชื่อต้วและชื่อสกุล โดยผู้ป่วย",
                  text: "ร้องขอเปลี่ยนชื่อต้วและชื่อสกุล โดยผู้ป่วย",
                },
                {
                  key: 11,
                  value: "ร้องขอเปลี่ยนแปลงประวัติสถานภาพการสมรส โดยผู้ป่วย",
                  text: "ร้องขอเปลี่ยนแปลงประวัติสถานภาพการสมรส โดยผู้ป่วย",
                },
                {
                  key: 12,
                  value: "ร้องขอเปลี่ยนชื่อ, นามสกุล กรณีไม่ตรงกับความเป็นจริง โดยผู้ป่วย",
                  text: "ร้องขอเปลี่ยนชื่อ, นามสกุล กรณีไม่ตรงกับความเป็นจริง โดยผู้ป่วย",
                },
                {
                  key: 13,
                  value: "ร้องขอเปลี่ยนคำนำหน้าชื่อ โดยญาติ",
                  text: "ร้องขอเปลี่ยนคำนำหน้าชื่อ โดยญาติ",
                },
                {
                  key: 14,
                  value: "ร้องขอเปลี่ยนชื่อตัว โดยญาติ",
                  text: "ร้องขอเปลี่ยนชื่อตัว โดยญาติ",
                },
                {
                  key: 15,
                  value: "ร้องขอเปลี่ยนชื่อสกุล โดยญาติ",
                  text: "ร้องขอเปลี่ยนชื่อสกุล โดยญาติ",
                },
                {
                  key: 16,
                  value: "ร้องขอเปลี่ยนชื่อรอง โดยญาติ",
                  text: "ร้องขอเปลี่ยนชื่อรอง โดยญาติ",
                },
                {
                  key: 17,
                  value: "ร้องขอเปลี่ยนชื่อต้วและชื่อสกุล โดยญาติ",
                  text: "ร้องขอเปลี่ยนชื่อต้วและชื่อสกุล โดยญาติ",
                },
                {
                  key: 18,
                  value: "ร้องขอเปลี่ยนแปลงประวัติสถานภาพการสมรส โดยญาติ",
                  text: "ร้องขอเปลี่ยนแปลงประวัติสถานภาพการสมรส โดยญาติ",
                },
                {
                  key: 19,
                  value: "ร้องขอเปลี่ยนชื่อ, นามสกุล กรณีไม่ตรงกับความเป็นจริง โดยผู้ป่วย",
                  text: "ร้องขอเปลี่ยนชื่อ, นามสกุล กรณีไม่ตรงกับความเป็นจริง โดยผู้ป่วย",
                },
                {
                  key: 20,
                  value: "เปลี่ยนหมายเลขหนังสือเดินทาง / ใบต่างด้าว",
                  text: "เปลี่ยนหมายเลขหนังสือเดินทาง / ใบต่างด้าว",
                },
                {
                  key: 21,
                  value: "ร้องขอโดยไม่มีหลักฐานการเปลี่ยนแปลง โดยผู้ป่วย",
                  text: "ร้องขอโดยไม่มีหลักฐานการเปลี่ยนแปลง โดยผู้ป่วย",
                },
                {
                  key: 22,
                  value: "ร้องขอโดยไม่มีหลักฐานการเปลี่ยนแปลง โดยญาติ",
                  text: "ร้องขอโดยไม่มีหลักฐานการเปลี่ยนแปลง โดยญาติ",
                },
                {
                  key: 23,
                  value: "ร้องขอโดยไม่มีหลักฐานการเปลี่ยนแปลง กรณีผู้ป่วยเสียชีวิต",
                  text: "ร้องขอโดยไม่มีหลักฐานการเปลี่ยนแปลง กรณีผู้ป่วยเสียชีวิต",
                },
              ]}
              selection={true}
              value={props.patient?.change_name_reason}
            ></Dropdown>
          </div>
          <div style={{ fontWeight: "bold" }}>หมายเลขหนังสือเปลี่ยนชื่อ</div>

          <div style={{ margin: "10px 0px" }}>
            <Input
              fluid={true}
              disabled={disableNameRefNo}
              value={props.patient?.change_name_ref_no}
              onChange={changeProp("change_name_ref_no")}
            ></Input>
          </div>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Button
              color="green"
              onClick={() => {
                if (props.patient?.change_name_reason == null) {
                  alert("ระบุเหตุผลเปลี่ยนชื่อ");
                } else if (!disableNameRefNo && props.patient?.change_name_ref_no == null) {
                  alert("ระบุหมายเลขหนังสือเปลี่ยนชื่อ");
                } else {
                  props.runSequence({
                    sequence: "SetPatient",
                    action: "save",
                    buttonLoadKey: `${CARD_PATIENT_INFO}_SAVE`,
                  });
                  setOpenEditName(false);
                }
              }}
              style={{ margin: "0px 20px" }}
            >
              บันทึก
            </Button>
            <Button color="red" onClick={handleCloseModEditName} style={{ margin: "0px 20px" }}>
              ยกเลิก
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default CardPatientInfo;
