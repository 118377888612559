import React, { useMemo, useEffect, useCallback, useRef } from "react";
import { Input } from "semantic-ui-react";

import { Column, RowInfo } from "react-table-6";

// Framework
import { Table } from "react-lib/frameworks/Table";

// Types
export type DrugSearchBoxProps = {
  setProp: (key: string, value: any, callback?: () => any) => any;
  onEvent: (e: any) => any;
  // data
  drugSearchText?: string;
  selectedDrug?: Record<string, any>;
  drugSearchResult?: any[];
  drugSearchLoading?: boolean;
  // CommonInterface
  errorMessage?: any;
  // config
  disabled?: boolean;
  isContinueMed?: boolean;
  // callback
  getTrProps?: (state: any, rowInfo: RowInfo, column: Column, instance: any) => any;
  DrugSelectSequence?: any;
};

const DrugSearchBox = (props: DrugSearchBoxProps) => {
  const cardRef = useRef<any>();
  const timeoutRef = useRef<any>(0);
  const abortController = useRef<AbortController>(null);
  const selectedDrugRef = useRef<any>(null);

  // Callback effect
  const handleClose = useCallback(async () => {
    props.setProp(`drugSearchText`, "");
    props.setProp(`drugSearchResult`, []);
    props.setProp(`drugSearchLoading`, false);
    document.removeEventListener("click", handleDocumentClick);
    handleClear();
  }, []);

  const handleDocumentClick = useCallback((e: MouseEvent) => {
    console.log(" handleDocumentClick ");
    if (!cardRef.current?.contains(e.target as Node) && !selectedDrugRef.current) {
      handleClose();
    }
  }, []);

  // Effect
  useEffect(() => {
    selectedDrugRef.current = props.selectedDrug;
  }, [props.selectedDrug]);

  // Memo
  const showTable = useMemo(() => {
    return (
      props.selectedDrug === null &&
      (props.drugSearchText?.length || 0) >= 3 &&
      (props.drugSearchResult?.length || 0) > 0
    );
  }, [props.selectedDrug, props.drugSearchText, props.drugSearchResult]);

  useEffect(() => {
    if (showTable) {
      document.addEventListener("click", handleDocumentClick);
    } else {
      document.removeEventListener("click", handleDocumentClick);
    }
    return () => {
      document.removeEventListener("click", handleDocumentClick);
    };
  }, [showTable]);

  const drugSearchResult = useMemo(() => {
    return (props.drugSearchResult || []).map((item: any) => ({
      ...item,
      generic_name: <div dangerouslySetInnerHTML={{ __html: item.generic_name || "" }} />,
    }));
  }, [props.drugSearchResult]);

  // Handler
  const handleClear = () => {
    // * หาก มี การ request อยู่แต่มีการแก้ไขคำค้นหาให้ Cancel request
    if (abortController.current) {
      abortController.current.abort();
    }

    clearTimeout(timeoutRef.current);
  };

  const handleChange = async (e: any, data: any) => {
    if (data.value?.length <= 2 && props.drugSearchLoading) {
      props.setProp("drugSearchLoading", false);
    }
    props.setProp("drugSearchText", data.value);
    handleClear();

    timeoutRef.current = setTimeout(() => {
      clearTimeout(timeoutRef.current);
      handleSearchDrug(data.value);
    }, 250);
  };

  const handleSearchDrug = (value: string) => {
    props.onEvent({
      message: "DrugSearch",
      params: {
        keyword: value,
        abortController,
      },
    });
  };

  return (
    <div ref={cardRef} style={{ width: "100%" }}>
      <Input
        className={props.errorMessage && !props.drugSearchText ? "error" : ""}
        value={props.drugSearchText}
        fluid={true}
        disabled={props.disabled || false}
        loading={props.drugSearchLoading || false}
        style={{ width: "100%" }}
        // callback
        onChange={handleChange}
        // onFocus={() => {
        //   document.removeEventListener("click", handleDocumentClick);
        //   document.addEventListener("click", handleDocumentClick);
        // }}
      />
      <div
        style={{
          display: showTable ? "block" : "none",
          position: "absolute",
          zIndex: 100,
          width: "80%",
          height: "400px",
        }}
      >
        <Table
          id="tb-medSearch"
          headers={`Generic Name, Drug Name,Form,Strength,Contain,หน่วยขาย${
            props.isContinueMed ? ",บัญชี,status" : ""
          }`}
          keys={`generic_name,drug_name,dosage_form_name,strength,container,stock_unit_name${
            props.isContinueMed ? ",drug_list,material_status" : ""
          }`}
          data={drugSearchResult}
          showPagination={false}
          minRows={8}
          style={{ height: "250px" }}
          getTrProps={props.getTrProps}
        />
      </div>
    </div>
  );
};

DrugSearchBox.displayName = "DrugSearchBox";

export default React.memo(DrugSearchBox);
