import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  Button,
  Dropdown,
  Input,
  Checkbox,
  TextArea
} from 'semantic-ui-react'
import {
  Table
} from 'react-lib/frameworks/Table'

const CardPostOperationUX = (props: any) => {
    return(
      <div
        id="CardPostOperation"
        style={{ margin: "15px", padding: "20px", backgroundColor: "rgba(198, 235, 243, 0.2)" }}>
        <div
          style={{ paddingBottom: "0px", display: "flex", justifyContent: "space-between", alignItems: "center"}}>

          <label
            style={{ fontWeight: "bold", fontSize: "22px" }}>
            Operative Note
          </label>
          <Button>

          </Button>
        </div>
        <hr>
        </hr>
        <div
          style={{ display: "flex", alignItems: "center" ,paddingBottom: "5px", fontWeight: "bold" }}>

          <div
            style={{ width: "20%", margin: "5px 10px 0px 0px" }}>

            <label>
              Date of Operation
            </label>
            <label
              style={{marginLeft: "20px"}}>
              {props.date}
            </label>
          </div>
          <div
            style={{ width: "20%", margin: "5px 10px 0px 0px" }}>

            <label>
              Operative time
            </label>
            <label
              style={{marginLeft: "20px"}}>
              {props.time}
            </label>
          </div>
          <div
            style={{display: "flex", width: "25%", margin: "5px 10px 0px 0px" }}>

            <label
              style={{display: "flex", alignItems: "center" }}>
              Swab Instrument
            </label>
            <div
              style={{width: "60%", paddingLeft: "20px"}}>

              <Dropdown
                fluid={true}
                onChange={props.onChangeSwab}
                options={props.swabOptions}
                selection={true}
                value={props.swab}>
              </Dropdown>
            </div>
          </div>
        </div>
        <div
          style={{ display: "flex", alignItems: "center" ,paddingBottom: "5px", fontWeight: "bold" }}>

          <div
            style={{margin: "5px 10px 0px 0px" }}>

            <label>
              Team
            </label>
            <label
              style={{color: "red"}}>
              *
            </label>
          </div>
          <div
            style={{width: "60%", margin: "5px 10px 0px 0px" }}>

            <Dropdown
              fluid={true}
              onChange={props.onChangeChiefSurgeon}
              options={props.chiefSurgeonOptions}
              selection={true}
              value={props.chiefSurgeon}>
            </Dropdown>
          </div>
        </div>
        <hr>
        </hr>
        <div
          style={{ display: "flex", alignItems: "center" ,paddingBottom: "5px"}}>

          <div
            style={{display: "flex", justifyContent: "flex-end", minWidth: "120px", maxWidth: "120px",margin: "5px 10px 0px 0px", fontWeight: "bold"}}>

            <label>
              Assistant Surgeon
            </label>
          </div>
          <div
            style={{width: "90%", margin: "5px 10px 0px 0px" }}>

            <div>
              {props.assistant_surgeons_summary}
            </div>
          </div>
        </div>
        <div
          style={{ display: "flex", alignItems: "center" ,paddingBottom: "5px"}}>

          <div
            style={{display: "flex", justifyContent: "flex-end", minWidth: "120px", maxWidth: "120px", margin: "5px 10px 0px 0px", fontWeight: "bold"}}>

            <label>
              Scrub Nurse
            </label>
          </div>
          <div
            style={{width: "90%", margin: "5px 10px 0px 0px" }}>

            <div>
              {props.scrub_nurse_summary}
            </div>
          </div>
        </div>
        <div
          style={{ display: "flex", alignItems: "center" ,paddingBottom: "5px"}}>

          <div
            style={{display: "flex", justifyContent: "flex-end", minWidth: "120px", maxWidth: "120px",margin: "5px 10px 0px 0px", fontWeight: "bold"}}>

            <label>
              Circulation Nurse
            </label>
          </div>
          <div
            style={{width: "90%", margin: "5px 10px 0px 0px" }}>

            <div>
              {props.circulating_nurse_summary}
            </div>
          </div>
        </div>
        <div
          style={{ display: "flex", alignItems: "center" ,paddingBottom: "5px"}}>

          <div
            style={{display: "flex", justifyContent: "flex-end", minWidth: "120px", maxWidth: "120px", margin: "5px 10px 0px 0px", fontWeight: "bold"}}>

            <label>
              Anesthesisologist
            </label>
          </div>
          <div
            style={{width: "90%", margin: "5px 10px 0px 0px"}}>

            <div>
              {props.anesthesiologist_summary}
            </div>
          </div>
        </div>
        <div
          style={{ display: "flex", alignItems: "center" ,paddingBottom: "5px"}}>

          <div
            style={{display: "flex", justifyContent: "flex-end", minWidth: "120px", maxWidth: "120px", margin: "5px 10px 0px 0px", fontWeight: "bold"}}>

            <label>
              Anesthetist
            </label>
          </div>
          <div
            style={{width: "90%", margin: "5px 10px 0px 0px"}}>

            <div>
              {props.anesthetist_summary}
            </div>
          </div>
        </div>
        <div
          style={{ display: "flex", alignItems: "center" ,paddingBottom: "5px"}}>

          <div
            style={{display: "flex", justifyContent: "flex-end", minWidth: "120px", maxWidth: "120px",margin: "5px 10px 0px 0px", fontWeight: "bold"}}>

            <label>
              Preoperative diagnosis
            </label>
          </div>
          <div
            style={{width: "90%", margin: "5px 10px 0px 0px", padding: "10px", backgroundColor: "rgba(255,255,255)"}}>

            <div
              style={{paddingBottom: "5px"}}>

              <label
                style={{fontWeight: "bold"}}>
                Principle Diagnosis
              </label>
            </div>
            <div
              style={{ display: "flex", alignItems: "center" ,paddingBottom: "5px"}}>

              <div
                style={{width: "26%", margin: "5px 10px 0px 0px", display: "flex", alignItems: "center" }}>

                <label
                  style={{fontWeight: "bold"}}>
                  Medical Term
                </label>
                <Input
                  fluid={true}
                  onChange={props.onChangePreMedical}
                  style={{margin: "0px 10px", width: "100%"}}
                  value={props.preMedical}>
                </Input>
              </div>
              <div
                style={{width: "26%", margin: "5px 10px 0px 0px", display: "flex", alignItems: "center" }}>

                <label
                  style={{fontWeight: "bold"}}>
                  ICD10 Term
                </label>
                <Input
                  fluid={true}
                  onChange={props.onChangePreTerm}
                  style={{margin: "0px 10px", width: "100%"}}
                  value={props.preTerm}>
                </Input>
              </div>
              <div
                style={{width: "26%", margin: "5px 10px 0px 0px", display: "flex", alignItems: "center" }}>

                <label
                  style={{fontWeight: "bold"}}>
                  ICD10 Code
                </label>
                <Input
                  fluid={true}
                  onChange={props.onChangePreCode}
                  style={{margin: "0px 10px", width: "100%"}}
                  value={props.preCode}>
                </Input>
              </div>
              <div
                style={{ margin: "5px 10px 0px 0px", display: "flex", alignItems: "center" }}>

                <Checkbox
                  checked={props.preActive}
                  onChange={props.onChangePreActive}
                  style={{margin: "0px 10px 0 0"}}>
                </Checkbox>
                <label
                  style={{fontWeight: "bold"}}>
                  Active Disease
                </label>
                <Button
                  color="teal"
                  icon="erase"
                  size="mini"
                  style={{margin: "0px 10px"}}>
                </Button>
                <Button
                  color="red"
                  icon="minus"
                  size="mini"
                  style={{margin: "0px 0 0 10px"}}>
                </Button>
              </div>
            </div>
            <div
              style={{ display: "flex", alignItems: "center" ,paddingBottom: "5px"}}>

              <div
                style={{width: "44%", margin: "5px 10px 0px 0px", display: "flex", alignItems: "center" }}>

                <label
                  style={{fontWeight: "bold"}}>
                  สาเหตุเพิ่มเติม
                </label>
                <div
                  style={{width: "80%"}}>

                  <Input
                    fluid={true}
                    onChange={props.onChangePreMedicalDescription}
                    style={{margin: "0px 10px", width: "100%"}}
                    value={props.preMedicalDescription}>
                  </Input>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          style={{ display: "flex", alignItems: "center" ,paddingBottom: "5px"}}>

          <div
            style={{display: "flex", justifyContent: "flex-end", minWidth: "120px", maxWidth: "120px",margin: "5px 10px 0px 0px", fontWeight: "bold"}}>

            <label>
              Postoperative diagnosis
            </label>
          </div>
          <div
            style={{width: "90%", margin: "5px 10px 0px 0px", padding: "15px", backgroundColor: "rgba(245,245,245)"}}>

            <div
              style={{paddingBottom: "5px"}}>

              <label
                style={{fontWeight: "bold"}}>
                Principle Diagnosis
              </label>
              <label
                style={{ color: "red" }}>
                *
              </label>
            </div>
            <div
              style={{ display: "flex", alignItems: "center" ,paddingBottom: "5px"}}>

              <div
                style={{width: "26%", margin: "5px 10px 0px 0px", display: "flex", alignItems: "center" }}>

                <label
                  style={{fontWeight: "bold"}}>
                  Medical Term
                </label>
                <Input
                  fluid={true}
                  onChange={props.onChangePostMedical}
                  style={{margin: "0px 10px", width: "100%"}}
                  value={props.postMedical}>
                </Input>
              </div>
              <div
                style={{width: "26%", margin: "5px 10px 0px 0px", display: "flex", alignItems: "center" }}>

                <label
                  style={{fontWeight: "bold"}}>
                  ICD10 Term
                </label>
                <Input
                  fluid={true}
                  onChange={props.onChangePostTerm}
                  style={{margin: "0px 10px", width: "100%"}}
                  value={props.postTerm}>
                </Input>
              </div>
              <div
                style={{width: "26%", margin: "5px 10px 0px 0px", display: "flex", alignItems: "center" }}>

                <label
                  style={{fontWeight: "bold"}}>
                  ICD10 Code
                </label>
                <Input
                  fluid={true}
                  onChange={props.onChangePostCode}
                  style={{margin: "0px 10px", width: "100%"}}
                  value={props.postCode}>
                </Input>
              </div>
              <div
                style={{margin: "5px 10px 0px 0px", display: "flex", alignItems: "center" }}>

                <Checkbox
                  checked={props.postActive}
                  onChange={props.onChangePostActive}
                  style={{margin: "0px 10px 0 0"}}>
                </Checkbox>
                <label
                  style={{fontWeight: "bold"}}>
                  Active Disease
                </label>
                <Button
                  color="teal"
                  icon="erase"
                  onClick={props.clearPrinciple}
                  size="mini"
                  style={{margin: "0px 10px"}}>
                </Button>
                <Button
                  color="red"
                  icon="minus"
                  onClick={props.clearPrinciple}
                  size="mini"
                  style={{margin: "0px 0 0 10px"}}>
                </Button>
              </div>
            </div>
            <div
              style={{ display: props.openSearchSecondary ? "block" : "none", zIndex: 100,  width: "calc(100% - 20px)", } }>

              <Table
                data={props.searchPostProcedures}
                getTrProps={props.principleDiagnosis}
                keys="medterm,icdterm,icdcode"
                minRows="4"
                showPagination={false}
                style={{ height: "150px" }}>
              </Table>
            </div>
            <div
              style={{...(props.secondary_style || {})}}>

              <label
                style={{fontWeight: "bold"}}>
                Secondary Diagnosis
              </label>
            </div>
            <div>
              {props.secondaryDiagnosis}
            </div>
            <div
              style={{ display: "flex", alignItems: "center", justifyContent: "center", width: "100%", height: "100%" , backgroundColor: "rgba(217,217,217)"}}>
              {}
              <Button
                onClick={props.addSecondary}
                style={{width: "100%", height: "100%", padding: "20px"}}>
                เพิ่ม Secondary Diagnosis
              </Button>
            </div>
          </div>
        </div>
        <div
          style={{ display: "flex", alignItems: "center" ,paddingBottom: "5px"}}>

          <div
            style={{display: "flex", justifyContent: "flex-end", minWidth: "120px", maxWidth: "120px",margin: "5px 10px 0px 0px", fontWeight: "bold"} }>

            <label>
              Operation
            </label>
          </div>
          <div
            style={{width: "90%", margin: "5px 10px 0px 0px", padding: "10px", backgroundColor: "rgba(245,245,245)"}}>

            <div
              style={{margin: "5px 10px 0px 0px", padding: "10px", backgroundColor: "rgba(255,255,255)"}}>

              <div
                style={{display: props.hideOperatingTreatment?"none":""}}>
                {props.operationProcedure}
              </div>
              <div
                style={{ display: props.hideOperatingTreatment?"none":"flex", alignItems: "center" ,marginBottom: "15px", backgroundColor: "rgba(217,217,217)"}}>

                <Button
                  onClick={props.addProcedure}
                  style={{width: "100%", height: "100%", padding: "20px"}}>
                  เพิ่ม รายการหัตถการ
                </Button>
              </div>
              <div
                className="ui form"
                style={{display: "none", alignItems: "center", marginBottom: "1rem"}}>

                <label
                  style={{fontWeight: "bold"}}>
                  Other detail
                </label>
                <TextArea
                  onChange={props.onChangeOtherTreatment}
                  rows="5"
                  value={props.otherTreatment || ""}>
                </TextArea>
              </div>
              <div>
                {props.operation}
              </div>
              <div
                style={{ display: "flex", alignItems: "center" ,marginBottom: "15px", backgroundColor: "rgba(217,217,217)"}}>
                {}
                <Button
                  onClick={props.addOperation}
                  style={{width: "100%", height: "100%", padding: "20px"}}>
                  เพิ่ม Operation
                </Button>
              </div>
            </div>
          </div>
        </div>
        <div
          className="ui form"
          style={{ display: "flex", alignItems: "start" ,paddingBottom: "5px"}}>

          <div
            style={{display: "flex", justifyContent: "flex-end", minWidth: "120px", maxWidth: "120px",margin: "13px 10px 0px 0px", fontWeight: "bold", alignItems: "start"}}>

            <label>
              Implant
            </label>
          </div>
          <div
            style={{width: "90%", margin: "5px 10px 0px 0px", padding: "10px", display: "flex", alignItems: "start"}}>

            <Checkbox
              checked={props.implant}
              onChange={props.onChangeImplant}
              toggle={true}>
            </Checkbox>
            <label
              style={{margin: "0px 10px", fontWeight: "bold"}}>
              {props.implant ? "Yes" : "N/A"}
            </label>
            <TextArea
              disabled={props.implant ? false : true}
              onChange={props.onChangeImplantDetail}
              style={{width: "70%"}}
              value={props.implantDetail}>
            </TextArea>
          </div>
        </div>
        <div
          style={{ display: "flex", alignItems: "center" ,paddingBottom: "5px"}}>

          <div
            style={{display: "flex", justifyContent: "flex-end", minWidth: "120px", maxWidth: "120px",margin: "5px 10px 0px 0px", fontWeight: "bold"}}>

            <label>
              Specific Concern
            </label>
          </div>
          <div
            style={{width: "90%", margin: "5px 10px 0px 0px", padding: "10px", display: "flex", alignItems: "center"}}>

            <Checkbox
              checked={props.concern}
              onChange={props.onChangeConcern}
              toggle={true}>
            </Checkbox>
            <label
              style={{margin: "0px 10px", fontWeight: "bold"}}>
              {props.concern ? "Yes" : "N/A"}
            </label>
            <Input
              disabled={props.concern ? false : true}
              onChange={props.onChangeConcernDetail}
              style={{width: "70%"}}
              value={props.concernDetail}>
            </Input>
          </div>
        </div>
        <div
          style={{ display: "flex", alignItems: "center" ,paddingBottom: "5px"}}>

          <div
            style={{display: "flex", justifyContent: "flex-end", minWidth: "120px", maxWidth: "120px",margin: "5px 10px 0px 0px", fontWeight: "bold"}}>

            <label>
              Type of wound
            </label>
          </div>
          <div
            style={{width: "90%", margin: "5px 10px 0px 0px", padding: "10px", display: "flex", alignItems: "center"}}>

            <Dropdown
              onChange={props.onChangeWound}
              options={props.woundOptions}
              selection={true}
              style={{width: "78%"}}
              value={props.wound}>
            </Dropdown>
          </div>
        </div>
        <div
          style={{ display: "flex", alignItems: "center" ,paddingBottom: "5px"}}>

          <div
            style={{display: "flex", justifyContent: "flex-end", minWidth: "120px", maxWidth: "120px",margin: "5px 10px 0px 0px", fontWeight: "bold"}}>

            <label>
              Specimen
            </label>
          </div>
          <div
            style={{width: "90%", margin: "5px 10px 0px 0px", padding: "10px", display: "flex", alignItems: "center"}}>

            <div
              style={{display: "flex", alignItems: "center"}}>

              <Checkbox
                checked={props.sepecimen}
                label={props.sepecimen ? "Yes" : "N/A"}
                onChange={props.onChangeSepecimen}
                style={{fontWeight: "bold", minWidth: "max-content"}}
                toggle={true}>
              </Checkbox>
            </div>
            <div
              style={{width: "35%", display: "flex", alignItems: "center"}}>

              <Checkbox
                checked={props.chkCyto}
                disabled={props.sepecimen ? false : true}
                label="CYTO"
                onChange={props.onChangeChkCyto}
                style={{margin: "0px 10px",fontWeight: "bold",minWidth: "max-content"}}>
              </Checkbox>
              <Input
                disabled={props.chkCyto ? false : true}
                fluid={true}
                onChange={props.onChangeCyto}
                style={{margin: "0px 10px", width: "100%"}}
                value={props.cyto}>
              </Input>
              <label
                style={{fontWeight: "bold"}}>
                ขวด
              </label>
            </div>
            <div
              style={{width: "35%", display: "flex", alignItems: "center"}}>

              <Checkbox
                checked={props.chkPatho}
                disabled={props.sepecimen ? false : true}
                label="PATHO"
                onChange={props.onChangeChkPatho}
                style={{margin: "0px 10px",fontWeight: "bold", minWidth: "max-content"}}>
              </Checkbox>
              <Input
                disabled={props.chkPatho ? false : true}
                fluid={true}
                onChange={props.onChangePatho}
                style={{margin: "0px 10px", width: "100%"}}
                value={props.patho}>
              </Input>
              <label
                style={{fontWeight: "bold"}}>
                ชิ้น
              </label>
            </div>
          </div>
        </div>
        <div
          style={{ display: "flex", alignItems: "center" ,paddingBottom: "5px"}}>

          <div
            style={{display: "flex", justifyContent: "flex-end", minWidth: "120px", maxWidth: "120px",margin: "5px 10px 0px 0px", fontWeight: "bold"}}>

            <label>
              Blood Loss (ml.)
            </label>
          </div>
          <div
            style={{width: "90%", margin: "5px 10px 0px 0px", padding: "10px", display: "flex", alignItems: "center"}}>

            <Input
              onChange={props.onChangeBlood}
              style={{width: "78%"}}
              value={props.blood}>
            </Input>
          </div>
        </div>
        <div
          style={{ display: "flex", alignItems: "center" ,paddingBottom: "5px"}}>

          <div
            style={{display: "flex", justifyContent: "flex-end", minWidth: "120px", maxWidth: "120px", margin: "5px 10px 0px 0px", fontWeight: "bold"}}>

            <label>
              Tourniquet
            </label>
          </div>
          <div
            style={{width: "90%", margin: "5px 10px 0px 0px", padding: "10px", display: "flex", alignItems: "center"}}>

            <Checkbox
              checked={props.tourniquet}
              onChange={props.onChangeTourniquet}
              toggle={true}>
            </Checkbox>
            <label
              style={{margin: "0px 10px", fontWeight: "bold"}}>
              {props.tourniquet ? "Yes" : "N/A"}
            </label>
            <Input
              disabled={props.tourniquet ? false : true}
              onChange={props.onChangeTourniquetDetail}
              style={{width: "70%"}}
              value={props.tourniquetDetail}>
            </Input>
          </div>
        </div>
        <div
          style={{ display: "flex", alignItems: "center", justifyContent: "flex-end" ,padding: "5px 10px 0px 0px",margin: "5px 10px 0px 0px"}}>

          <Button
            color="teal"
            onClick={props.openTemplate}
            style={{backgroundColor: "rgba(19,128,88)"}}>
            Template
          </Button>
        </div>
        <div
          style={{ display: "flex", alignItems: "baseline" ,paddingBottom: "5px"}}>

          <div
            style={{display: "flex", justifyContent: "flex-end", minWidth: "120px", maxWidth: "120px",margin: "5px 10px 0px 0px", fontWeight: "bold"}}>

            <label>
              Procedure
            </label>
          </div>
          <div
            className="ui form"
            style={{width: "90%", margin: "5px 10px 0px 0px", padding: "10px", display: "flex", alignItems: "center"}}>

            <TextArea
              onChange={props.onChangeProcedure}
              rows="10"
              style={{width: "100%", padding: "10px", resize: "none"}}
              value={props.procedure}>
            </TextArea>
          </div>
        </div>
        <div
          style={{ display: "flex", alignItems: "center" ,paddingBottom: "5px"}}>

          <div
            style={{display: "flex", justifyContent: "flex-end", width: "10%", margin: "5px 10px 0px 0px", fontWeight: "bold"}}>

          </div>
          <div
            style={{width: "90%", margin: "5px 10px 0px 0px", padding: "10px", display: "flex", alignItems: "center", justifyContent: "space-between"}}>

            <div>

            </div>
            <div>
              {props.onPrint}
            </div>
          </div>
        </div>
        <div
          style={{padding: "20px", backgroundColor: "rgba(245,245,245)", margin: "5px 10px 0px 0px"}}>

          <div>

            <label
              style={{fontWeight: "bold"}}>
              ภาพประกอบการผ่าตัด
            </label>
          </div>
          <div
            style={{padding: "10px 0px"}}>
            {props.imgDetail}
          </div>
          <div>

            <Button
              color="blue"
              disabled={props.disabledUpload}
              onClick={props.takePhoto}
              style={{marginRight: "10px" }}>
              Take Photo
            </Button>
            <Button
              color="blue"
              disabled={props.disabledUpload}
              onClick={props.uploadImg}>
              Upload
            </Button>
          </div>
        </div>
        <div
          style={{ display: "flex", alignItems: "center", justifyContent: "flex-end" ,padding: "5px 10px 0px 0px",margin: "15px 10px 0px 0px"}}>

          <div>
            {props.buttonSave}
          </div>
          <div
            style={{margin: "0px 5px" ,display: "none"}}>

            <Button
              color="green">
              Confirm
            </Button>
          </div>
          <div
            style={{display: "none"}}>

            <Button
              color="red">
              Unconfirm
            </Button>
          </div>
        </div>
      </div>
    )
}


export default CardPostOperationUX

export const screenPropsDefault = {"debug":true,"showSupplyList":false}

/* Date Time : Fri Sep 06 2024 18:44:21 GMT+0700 (Indochina Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "props": {
        "id": {
          "type": "value",
          "value": "CardPostOperation"
        },
        "style": {
          "type": "code",
          "value": "{ margin: \"15px\", padding: \"20px\", backgroundColor: \"rgba(198, 235, 243, 0.2)\" }"
        }
      },
      "seq": 0
    },
    {
      "from": null,
      "id": 1,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ paddingBottom: \"0px\", display: \"flex\", justifyContent: \"space-between\", alignItems: \"center\"}"
        }
      },
      "seq": 1,
      "void": false
    },
    {
      "from": null,
      "id": 2,
      "name": "label",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": "Operative Note"
        },
        "style": {
          "type": "code",
          "value": "{ fontWeight: \"bold\", fontSize: \"22px\" }"
        }
      },
      "seq": 2,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 3,
      "name": "Button",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "color": {
          "type": "value",
          "value": ""
        },
        "size": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 3,
      "void": false
    },
    {
      "from": null,
      "id": 5,
      "name": "hr",
      "parent": 0,
      "props": {
      },
      "seq": 5,
      "void": true
    },
    {
      "from": null,
      "id": 6,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", alignItems: \"center\" ,paddingBottom: \"5px\", fontWeight: \"bold\" }"
        }
      },
      "seq": 6,
      "void": false
    },
    {
      "from": null,
      "id": 12,
      "name": "div",
      "parent": 6,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", width: \"25%\", margin: \"5px 10px 0px 0px\" }"
        }
      },
      "seq": 17,
      "void": false
    },
    {
      "from": null,
      "id": 14,
      "name": "div",
      "parent": 6,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"20%\", margin: \"5px 10px 0px 0px\" }"
        }
      },
      "seq": 7,
      "void": false
    },
    {
      "from": null,
      "id": 15,
      "name": "label",
      "parent": 14,
      "props": {
        "children": {
          "type": "value",
          "value": "Date of Operation"
        }
      },
      "seq": 15,
      "void": false
    },
    {
      "from": null,
      "id": 16,
      "name": "label",
      "parent": 14,
      "props": {
        "children": {
          "type": "code",
          "value": "props.date"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft: \"20px\"}"
        }
      },
      "seq": 16,
      "void": false
    },
    {
      "from": null,
      "id": 17,
      "name": "div",
      "parent": 6,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"20%\", margin: \"5px 10px 0px 0px\" }"
        }
      },
      "seq": 14,
      "void": false
    },
    {
      "from": null,
      "id": 18,
      "name": "label",
      "parent": 17,
      "props": {
        "children": {
          "type": "value",
          "value": "Operative time"
        },
        "style": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 18,
      "void": false
    },
    {
      "from": null,
      "id": 19,
      "name": "label",
      "parent": 17,
      "props": {
        "children": {
          "type": "code",
          "value": "props.time"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft: \"20px\"}"
        }
      },
      "seq": 19,
      "void": false
    },
    {
      "from": null,
      "id": 21,
      "name": "div",
      "parent": 12,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{width: \"60%\", paddingLeft: \"20px\"}"
        }
      },
      "seq": 24,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 22,
      "name": "Dropdown",
      "parent": 21,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeSwab"
        },
        "options": {
          "type": "code",
          "value": "props.swabOptions"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.swab"
        }
      },
      "seq": 22,
      "void": false
    },
    {
      "from": null,
      "id": 24,
      "name": "label",
      "parent": 12,
      "props": {
        "children": {
          "type": "value",
          "value": "Swab Instrument"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", alignItems: \"center\" }"
        }
      },
      "seq": 23,
      "void": false
    },
    {
      "from": null,
      "id": 26,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", alignItems: \"center\" ,paddingBottom: \"5px\", fontWeight: \"bold\" }"
        }
      },
      "seq": 26,
      "void": false
    },
    {
      "from": null,
      "id": 27,
      "name": "div",
      "parent": 26,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{margin: \"5px 10px 0px 0px\" }"
        }
      },
      "seq": 27,
      "void": false
    },
    {
      "from": null,
      "id": 28,
      "name": "div",
      "parent": 26,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{width: \"60%\", margin: \"5px 10px 0px 0px\" }"
        }
      },
      "seq": 28,
      "void": false
    },
    {
      "from": null,
      "id": 29,
      "name": "label",
      "parent": 27,
      "props": {
        "children": {
          "type": "value",
          "value": "Team"
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 29,
      "void": false
    },
    {
      "from": null,
      "id": 30,
      "name": "label",
      "parent": 27,
      "props": {
        "children": {
          "type": "value",
          "value": "*"
        },
        "style": {
          "type": "code",
          "value": "{color: \"red\"}"
        }
      },
      "seq": 30,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 31,
      "name": "Dropdown",
      "parent": 28,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeChiefSurgeon"
        },
        "options": {
          "type": "code",
          "value": "props.chiefSurgeonOptions"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.chiefSurgeon"
        }
      },
      "seq": 31,
      "void": false
    },
    {
      "from": null,
      "id": 32,
      "name": "hr",
      "parent": 0,
      "props": {
      },
      "seq": 32,
      "void": true
    },
    {
      "from": null,
      "id": 33,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", alignItems: \"center\" ,paddingBottom: \"5px\"}"
        }
      },
      "seq": 33,
      "void": false
    },
    {
      "from": null,
      "id": 34,
      "name": "div",
      "parent": 33,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", justifyContent: \"flex-end\", minWidth: \"120px\", maxWidth: \"120px\",margin: \"5px 10px 0px 0px\", fontWeight: \"bold\"}"
        }
      },
      "seq": 34,
      "void": false
    },
    {
      "from": null,
      "id": 35,
      "name": "label",
      "parent": 34,
      "props": {
        "children": {
          "type": "value",
          "value": "Assistant Surgeon"
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 35,
      "void": false
    },
    {
      "from": null,
      "id": 38,
      "name": "div",
      "parent": 33,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{width: \"90%\", margin: \"5px 10px 0px 0px\" }"
        }
      },
      "seq": 38,
      "void": false
    },
    {
      "from": null,
      "id": 40,
      "name": "div",
      "parent": 38,
      "props": {
        "children": {
          "type": "code",
          "value": "props.assistant_surgeons_summary"
        }
      },
      "seq": 40,
      "void": false
    },
    {
      "from": null,
      "id": 41,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", alignItems: \"center\" ,paddingBottom: \"5px\"}"
        }
      },
      "seq": 41,
      "void": false
    },
    {
      "from": null,
      "id": 42,
      "name": "div",
      "parent": 41,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", justifyContent: \"flex-end\", minWidth: \"120px\", maxWidth: \"120px\", margin: \"5px 10px 0px 0px\", fontWeight: \"bold\"}"
        }
      },
      "seq": 42,
      "void": false
    },
    {
      "from": null,
      "id": 43,
      "name": "div",
      "parent": 41,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{width: \"90%\", margin: \"5px 10px 0px 0px\" }"
        }
      },
      "seq": 43,
      "void": false
    },
    {
      "from": null,
      "id": 44,
      "name": "div",
      "parent": 43,
      "props": {
        "children": {
          "type": "code",
          "value": "props.scrub_nurse_summary"
        }
      },
      "seq": 44,
      "void": false
    },
    {
      "from": null,
      "id": 45,
      "name": "label",
      "parent": 42,
      "props": {
        "children": {
          "type": "value",
          "value": "Scrub Nurse"
        }
      },
      "seq": 45,
      "void": false
    },
    {
      "from": null,
      "id": 46,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", alignItems: \"center\" ,paddingBottom: \"5px\"}"
        }
      },
      "seq": 46,
      "void": false
    },
    {
      "from": null,
      "id": 47,
      "name": "div",
      "parent": 46,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", justifyContent: \"flex-end\", minWidth: \"120px\", maxWidth: \"120px\",margin: \"5px 10px 0px 0px\", fontWeight: \"bold\"}"
        }
      },
      "seq": 47,
      "void": false
    },
    {
      "from": null,
      "id": 48,
      "name": "div",
      "parent": 46,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{width: \"90%\", margin: \"5px 10px 0px 0px\" }"
        }
      },
      "seq": 48,
      "void": false
    },
    {
      "from": null,
      "id": 49,
      "name": "div",
      "parent": 48,
      "props": {
        "children": {
          "type": "code",
          "value": "props.circulating_nurse_summary"
        }
      },
      "seq": 49,
      "void": false
    },
    {
      "from": null,
      "id": 50,
      "name": "label",
      "parent": 47,
      "props": {
        "children": {
          "type": "value",
          "value": "Circulation Nurse"
        }
      },
      "seq": 50,
      "void": false
    },
    {
      "from": null,
      "id": 51,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", alignItems: \"center\" ,paddingBottom: \"5px\"}"
        }
      },
      "seq": 51,
      "void": false
    },
    {
      "from": null,
      "id": 52,
      "name": "div",
      "parent": 51,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", justifyContent: \"flex-end\", minWidth: \"120px\", maxWidth: \"120px\", margin: \"5px 10px 0px 0px\", fontWeight: \"bold\"}"
        }
      },
      "seq": 52,
      "void": false
    },
    {
      "from": null,
      "id": 53,
      "name": "div",
      "parent": 51,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{width: \"90%\", margin: \"5px 10px 0px 0px\"}"
        }
      },
      "seq": 53,
      "void": false
    },
    {
      "from": null,
      "id": 54,
      "name": "div",
      "parent": 53,
      "props": {
        "children": {
          "type": "code",
          "value": "props.anesthesiologist_summary"
        }
      },
      "seq": 54,
      "void": false
    },
    {
      "from": null,
      "id": 55,
      "name": "label",
      "parent": 52,
      "props": {
        "children": {
          "type": "value",
          "value": "Anesthesisologist"
        }
      },
      "seq": 55,
      "void": false
    },
    {
      "from": null,
      "id": 56,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", alignItems: \"center\" ,paddingBottom: \"5px\"}"
        }
      },
      "seq": 56,
      "void": false
    },
    {
      "from": null,
      "id": 57,
      "name": "div",
      "parent": 56,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", justifyContent: \"flex-end\", minWidth: \"120px\", maxWidth: \"120px\", margin: \"5px 10px 0px 0px\", fontWeight: \"bold\"}"
        }
      },
      "seq": 57,
      "void": false
    },
    {
      "from": null,
      "id": 58,
      "name": "div",
      "parent": 56,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{width: \"90%\", margin: \"5px 10px 0px 0px\"}"
        }
      },
      "seq": 58,
      "void": false
    },
    {
      "from": null,
      "id": 59,
      "name": "div",
      "parent": 58,
      "props": {
        "children": {
          "type": "code",
          "value": "props.anesthetist_summary"
        }
      },
      "seq": 59,
      "void": false
    },
    {
      "from": null,
      "id": 60,
      "name": "label",
      "parent": 57,
      "props": {
        "children": {
          "type": "value",
          "value": "Anesthetist"
        }
      },
      "seq": 60,
      "void": false
    },
    {
      "from": null,
      "id": 61,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", alignItems: \"center\" ,paddingBottom: \"5px\"}"
        }
      },
      "seq": 61,
      "void": false
    },
    {
      "from": null,
      "id": 62,
      "name": "div",
      "parent": 61,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", justifyContent: \"flex-end\", minWidth: \"120px\", maxWidth: \"120px\",margin: \"5px 10px 0px 0px\", fontWeight: \"bold\"}"
        }
      },
      "seq": 62,
      "void": false
    },
    {
      "from": null,
      "id": 63,
      "name": "div",
      "parent": 61,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{width: \"90%\", margin: \"5px 10px 0px 0px\", padding: \"10px\", backgroundColor: \"rgba(255,255,255)\"}"
        }
      },
      "seq": 63,
      "void": false
    },
    {
      "from": null,
      "id": 64,
      "name": "label",
      "parent": 62,
      "props": {
        "children": {
          "type": "value",
          "value": "Preoperative diagnosis"
        }
      },
      "seq": 64,
      "void": false
    },
    {
      "from": null,
      "id": 65,
      "name": "div",
      "parent": 63,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{paddingBottom: \"5px\"}"
        }
      },
      "seq": 65,
      "void": false
    },
    {
      "from": null,
      "id": 66,
      "name": "div",
      "parent": 63,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", alignItems: \"center\" ,paddingBottom: \"5px\"}"
        }
      },
      "seq": 66,
      "void": false
    },
    {
      "from": null,
      "id": 67,
      "name": "div",
      "parent": 63,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", alignItems: \"center\" ,paddingBottom: \"5px\"}"
        }
      },
      "seq": 67,
      "void": false
    },
    {
      "from": null,
      "id": 68,
      "name": "label",
      "parent": 65,
      "props": {
        "children": {
          "type": "value",
          "value": "Principle Diagnosis"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"bold\"}"
        }
      },
      "seq": 68,
      "void": false
    },
    {
      "from": null,
      "id": 69,
      "name": "div",
      "parent": 66,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{width: \"26%\", margin: \"5px 10px 0px 0px\", display: \"flex\", alignItems: \"center\" }"
        }
      },
      "seq": 69,
      "void": false
    },
    {
      "from": null,
      "id": 70,
      "name": "div",
      "parent": 66,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{width: \"26%\", margin: \"5px 10px 0px 0px\", display: \"flex\", alignItems: \"center\" }"
        }
      },
      "seq": 70,
      "void": false
    },
    {
      "from": null,
      "id": 71,
      "name": "div",
      "parent": 66,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{width: \"26%\", margin: \"5px 10px 0px 0px\", display: \"flex\", alignItems: \"center\" }"
        }
      },
      "seq": 71,
      "void": false
    },
    {
      "from": null,
      "id": 72,
      "name": "div",
      "parent": 66,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ margin: \"5px 10px 0px 0px\", display: \"flex\", alignItems: \"center\" }"
        }
      },
      "seq": 72,
      "void": false
    },
    {
      "from": null,
      "id": 73,
      "name": "label",
      "parent": 69,
      "props": {
        "children": {
          "type": "value",
          "value": "Medical Term"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"bold\"}"
        }
      },
      "seq": 73,
      "void": false
    },
    {
      "from": null,
      "id": 74,
      "name": "label",
      "parent": 70,
      "props": {
        "children": {
          "type": "value",
          "value": "ICD10 Term"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"bold\"}"
        }
      },
      "seq": 74,
      "void": false
    },
    {
      "from": null,
      "id": 75,
      "name": "label",
      "parent": 71,
      "props": {
        "children": {
          "type": "value",
          "value": "ICD10 Code"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"bold\"}"
        }
      },
      "seq": 75,
      "void": false
    },
    {
      "from": null,
      "id": 76,
      "name": "label",
      "parent": 72,
      "props": {
        "children": {
          "type": "value",
          "value": "Active Disease"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"bold\"}"
        }
      },
      "seq": 80,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 77,
      "name": "Input",
      "parent": 69,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangePreMedical"
        },
        "style": {
          "type": "code",
          "value": "{margin: \"0px 10px\", width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.preMedical"
        }
      },
      "seq": 77,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 78,
      "name": "Input",
      "parent": 70,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangePreTerm"
        },
        "style": {
          "type": "code",
          "value": "{margin: \"0px 10px\", width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.preTerm"
        }
      },
      "seq": 78,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 79,
      "name": "Input",
      "parent": 71,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangePreCode"
        },
        "style": {
          "type": "code",
          "value": "{margin: \"0px 10px\", width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.preCode"
        }
      },
      "seq": 79,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 80,
      "name": "Checkbox",
      "parent": 72,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.preActive"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangePreActive"
        },
        "style": {
          "type": "code",
          "value": "{margin: \"0px 10px 0 0\"}"
        }
      },
      "seq": 76,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 81,
      "name": "Button",
      "parent": 72,
      "props": {
        "color": {
          "type": "value",
          "value": "teal"
        },
        "icon": {
          "type": "value",
          "value": "erase"
        },
        "size": {
          "type": "value",
          "value": "mini"
        },
        "style": {
          "type": "code",
          "value": "{margin: \"0px 10px\"}"
        }
      },
      "seq": 81,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 82,
      "name": "Button",
      "parent": 72,
      "props": {
        "color": {
          "type": "value",
          "value": "red"
        },
        "icon": {
          "type": "value",
          "value": "minus"
        },
        "size": {
          "type": "value",
          "value": "mini"
        },
        "style": {
          "type": "code",
          "value": "{margin: \"0px 0 0 10px\"}"
        }
      },
      "seq": 82,
      "void": false
    },
    {
      "from": null,
      "id": 84,
      "name": "div",
      "parent": 67,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "ui form"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\", margin: \"5px 10px 0px 0px\", display: \"flex\", alignItems: \"start\" }"
        }
      },
      "seq": 84,
      "void": false
    },
    {
      "from": null,
      "id": 90,
      "name": "div",
      "parent": 84,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{width: \"65%\"}"
        }
      },
      "seq": 246,
      "void": false
    },
    {
      "from": null,
      "id": 92,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", alignItems: \"center\" ,paddingBottom: \"5px\"}"
        }
      },
      "seq": 92,
      "void": false
    },
    {
      "from": null,
      "id": 93,
      "name": "div",
      "parent": 92,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", justifyContent: \"flex-end\", minWidth: \"120px\", maxWidth: \"120px\",margin: \"5px 10px 0px 0px\", fontWeight: \"bold\"}"
        }
      },
      "seq": 93,
      "void": false
    },
    {
      "from": null,
      "id": 94,
      "name": "div",
      "parent": 92,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{width: \"90%\", margin: \"5px 10px 0px 0px\", padding: \"15px\", backgroundColor: \"rgba(245,245,245)\"}"
        }
      },
      "seq": 94,
      "void": false
    },
    {
      "from": null,
      "id": 95,
      "name": "div",
      "parent": 94,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{paddingBottom: \"5px\"}"
        }
      },
      "seq": 95,
      "void": false
    },
    {
      "from": null,
      "id": 96,
      "name": "div",
      "parent": 94,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", alignItems: \"center\" ,paddingBottom: \"5px\"}"
        }
      },
      "seq": 96,
      "void": false
    },
    {
      "from": null,
      "id": 97,
      "name": "div",
      "parent": 94,
      "props": {
        "children": {
          "type": "code",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", alignItems: \"center\", justifyContent: \"center\", width: \"100%\", height: \"100%\" , backgroundColor: \"rgba(217,217,217)\"}"
        }
      },
      "seq": 233,
      "void": false
    },
    {
      "from": null,
      "id": 98,
      "name": "label",
      "parent": 93,
      "props": {
        "children": {
          "type": "value",
          "value": "Postoperative diagnosis"
        }
      },
      "seq": 98,
      "void": false
    },
    {
      "from": null,
      "id": 99,
      "name": "label",
      "parent": 95,
      "props": {
        "children": {
          "type": "value",
          "value": "Principle Diagnosis"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"bold\"}"
        }
      },
      "seq": 99,
      "void": false
    },
    {
      "from": null,
      "id": 100,
      "name": "div",
      "parent": 96,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{width: \"26%\", margin: \"5px 10px 0px 0px\", display: \"flex\", alignItems: \"center\" }"
        }
      },
      "seq": 100,
      "void": false
    },
    {
      "from": null,
      "id": 101,
      "name": "div",
      "parent": 96,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{width: \"26%\", margin: \"5px 10px 0px 0px\", display: \"flex\", alignItems: \"center\" }"
        }
      },
      "seq": 101,
      "void": false
    },
    {
      "from": null,
      "id": 102,
      "name": "div",
      "parent": 96,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{width: \"26%\", margin: \"5px 10px 0px 0px\", display: \"flex\", alignItems: \"center\" }"
        }
      },
      "seq": 102,
      "void": false
    },
    {
      "from": null,
      "id": 103,
      "name": "div",
      "parent": 96,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{margin: \"5px 10px 0px 0px\", display: \"flex\", alignItems: \"center\" }"
        }
      },
      "seq": 103,
      "void": false
    },
    {
      "from": null,
      "id": 104,
      "name": "label",
      "parent": 100,
      "props": {
        "children": {
          "type": "value",
          "value": "Medical Term"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"bold\"}"
        }
      },
      "seq": 104,
      "void": false
    },
    {
      "from": null,
      "id": 105,
      "name": "label",
      "parent": 101,
      "props": {
        "children": {
          "type": "value",
          "value": "ICD10 Term"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"bold\"}"
        }
      },
      "seq": 105,
      "void": false
    },
    {
      "from": null,
      "id": 106,
      "name": "label",
      "parent": 102,
      "props": {
        "children": {
          "type": "value",
          "value": "ICD10 Code"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"bold\"}"
        }
      },
      "seq": 106,
      "void": false
    },
    {
      "from": null,
      "id": 107,
      "name": "label",
      "parent": 103,
      "props": {
        "children": {
          "type": "value",
          "value": "Active Disease"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"bold\"}"
        }
      },
      "seq": 111,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 108,
      "name": "Input",
      "parent": 100,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangePostMedical"
        },
        "style": {
          "type": "code",
          "value": "{margin: \"0px 10px\", width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.postMedical"
        }
      },
      "seq": 108,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 109,
      "name": "Input",
      "parent": 101,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangePostTerm"
        },
        "style": {
          "type": "code",
          "value": "{margin: \"0px 10px\", width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.postTerm"
        }
      },
      "seq": 109,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 110,
      "name": "Input",
      "parent": 102,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangePostCode"
        },
        "style": {
          "type": "code",
          "value": "{margin: \"0px 10px\", width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.postCode"
        }
      },
      "seq": 110,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 111,
      "name": "Checkbox",
      "parent": 103,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.postActive"
        },
        "label": {
          "type": "code",
          "value": ""
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangePostActive"
        },
        "style": {
          "type": "code",
          "value": "{margin: \"0px 10px 0 0\"}"
        }
      },
      "seq": 107,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 112,
      "name": "Button",
      "parent": 103,
      "props": {
        "color": {
          "type": "value",
          "value": "teal"
        },
        "icon": {
          "type": "value",
          "value": "erase"
        },
        "onClick": {
          "type": "code",
          "value": "props.clearPrinciple"
        },
        "size": {
          "type": "value",
          "value": "mini"
        },
        "style": {
          "type": "code",
          "value": "{margin: \"0px 10px\"}"
        }
      },
      "seq": 112,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 113,
      "name": "Button",
      "parent": 103,
      "props": {
        "color": {
          "type": "value",
          "value": "red"
        },
        "icon": {
          "type": "value",
          "value": "minus"
        },
        "onClick": {
          "type": "code",
          "value": "props.clearPrinciple"
        },
        "size": {
          "type": "value",
          "value": "mini"
        },
        "style": {
          "type": "code",
          "value": "{margin: \"0px 0 0 10px\"}"
        }
      },
      "seq": 113,
      "void": false
    },
    {
      "from": null,
      "id": 114,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", alignItems: \"center\" ,paddingBottom: \"5px\"}"
        }
      },
      "seq": 114,
      "void": false
    },
    {
      "from": null,
      "id": 115,
      "name": "div",
      "parent": 114,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", justifyContent: \"flex-end\", minWidth: \"120px\", maxWidth: \"120px\",margin: \"5px 10px 0px 0px\", fontWeight: \"bold\"} "
        }
      },
      "seq": 115,
      "void": false
    },
    {
      "from": null,
      "id": 122,
      "name": "label",
      "parent": 115,
      "props": {
        "children": {
          "type": "value",
          "value": "Operation"
        }
      },
      "seq": 122,
      "void": false
    },
    {
      "from": null,
      "id": 123,
      "name": "div",
      "parent": 114,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{width: \"90%\", margin: \"5px 10px 0px 0px\", padding: \"10px\", backgroundColor: \"rgba(245,245,245)\"}"
        }
      },
      "seq": 123,
      "void": false
    },
    {
      "from": null,
      "id": 124,
      "name": "div",
      "parent": 123,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{margin: \"5px 10px 0px 0px\", padding: \"10px\", backgroundColor: \"rgba(255,255,255)\"}"
        }
      },
      "seq": 124,
      "void": false
    },
    {
      "from": null,
      "id": 126,
      "name": "div",
      "parent": 124,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: props.hideOperatingTreatment?\"none\":\"flex\", alignItems: \"center\" ,marginBottom: \"15px\", backgroundColor: \"rgba(217,217,217)\"}"
        }
      },
      "seq": 127,
      "void": false
    },
    {
      "from": null,
      "id": 129,
      "name": "div",
      "parent": 124,
      "props": {
        "children": {
          "type": "code",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", alignItems: \"center\" ,marginBottom: \"15px\", backgroundColor: \"rgba(217,217,217)\"}"
        }
      },
      "seq": 255,
      "void": false
    },
    {
      "from": null,
      "id": 152,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "ui form"
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", alignItems: \"start\" ,paddingBottom: \"5px\"}"
        }
      },
      "seq": 152,
      "void": false
    },
    {
      "from": null,
      "id": 153,
      "name": "div",
      "parent": 152,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", justifyContent: \"flex-end\", minWidth: \"120px\", maxWidth: \"120px\",margin: \"13px 10px 0px 0px\", fontWeight: \"bold\", alignItems: \"start\"}"
        }
      },
      "seq": 153,
      "void": false
    },
    {
      "from": null,
      "id": 154,
      "name": "div",
      "parent": 152,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{width: \"90%\", margin: \"5px 10px 0px 0px\", padding: \"10px\", display: \"flex\", alignItems: \"start\"}"
        }
      },
      "seq": 154,
      "void": false
    },
    {
      "from": null,
      "id": 155,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", alignItems: \"center\" ,paddingBottom: \"5px\"}"
        }
      },
      "seq": 155,
      "void": false
    },
    {
      "from": null,
      "id": 156,
      "name": "label",
      "parent": 153,
      "props": {
        "children": {
          "type": "value",
          "value": "Implant"
        }
      },
      "seq": 156,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 157,
      "name": "Checkbox",
      "parent": 154,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.implant"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeImplant"
        },
        "toggle": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 157,
      "void": false
    },
    {
      "from": null,
      "id": 158,
      "name": "label",
      "parent": 154,
      "props": {
        "children": {
          "type": "code",
          "value": "props.implant ? \"Yes\" : \"N/A\""
        },
        "style": {
          "type": "code",
          "value": "{margin: \"0px 10px\", fontWeight: \"bold\"}"
        }
      },
      "seq": 158,
      "void": false
    },
    {
      "from": null,
      "id": 159,
      "name": "div",
      "parent": 155,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", justifyContent: \"flex-end\", minWidth: \"120px\", maxWidth: \"120px\",margin: \"5px 10px 0px 0px\", fontWeight: \"bold\"}"
        }
      },
      "seq": 159,
      "void": false
    },
    {
      "from": null,
      "id": 160,
      "name": "div",
      "parent": 155,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{width: \"90%\", margin: \"5px 10px 0px 0px\", padding: \"10px\", display: \"flex\", alignItems: \"center\"}"
        }
      },
      "seq": 160,
      "void": false
    },
    {
      "from": null,
      "id": 161,
      "name": "label",
      "parent": 159,
      "props": {
        "children": {
          "type": "value",
          "value": "Specific Concern"
        }
      },
      "seq": 161,
      "void": false
    },
    {
      "from": null,
      "id": 162,
      "name": "label",
      "parent": 160,
      "props": {
        "children": {
          "type": "code",
          "value": "props.concern ? \"Yes\" : \"N/A\""
        },
        "style": {
          "type": "code",
          "value": "{margin: \"0px 10px\", fontWeight: \"bold\"}"
        }
      },
      "seq": 164,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 164,
      "name": "Checkbox",
      "parent": 160,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.concern"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeConcern"
        },
        "toggle": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 162,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 165,
      "name": "Input",
      "parent": 160,
      "props": {
        "disabled": {
          "type": "code",
          "value": "props.concern ? false : true"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeConcernDetail"
        },
        "style": {
          "type": "code",
          "value": "{width: \"70%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.concernDetail"
        }
      },
      "seq": 165,
      "void": false
    },
    {
      "from": null,
      "id": 166,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", alignItems: \"center\" ,paddingBottom: \"5px\"}"
        }
      },
      "seq": 166,
      "void": false
    },
    {
      "from": null,
      "id": 167,
      "name": "div",
      "parent": 166,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", justifyContent: \"flex-end\", minWidth: \"120px\", maxWidth: \"120px\",margin: \"5px 10px 0px 0px\", fontWeight: \"bold\"}"
        }
      },
      "seq": 167,
      "void": false
    },
    {
      "from": null,
      "id": 168,
      "name": "div",
      "parent": 166,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{width: \"90%\", margin: \"5px 10px 0px 0px\", padding: \"10px\", display: \"flex\", alignItems: \"center\"}"
        }
      },
      "seq": 168,
      "void": false
    },
    {
      "from": null,
      "id": 169,
      "name": "label",
      "parent": 167,
      "props": {
        "children": {
          "type": "value",
          "value": "Type of wound"
        }
      },
      "seq": 169,
      "void": false
    },
    {
      "from": null,
      "id": 171,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", alignItems: \"center\" ,paddingBottom: \"5px\"}"
        }
      },
      "seq": 171,
      "void": false
    },
    {
      "from": null,
      "id": 172,
      "name": "div",
      "parent": 171,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", justifyContent: \"flex-end\", minWidth: \"120px\", maxWidth: \"120px\",margin: \"5px 10px 0px 0px\", fontWeight: \"bold\"}"
        }
      },
      "seq": 172,
      "void": false
    },
    {
      "from": null,
      "id": 173,
      "name": "div",
      "parent": 171,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{width: \"90%\", margin: \"5px 10px 0px 0px\", padding: \"10px\", display: \"flex\", alignItems: \"center\"}"
        }
      },
      "seq": 173,
      "void": false
    },
    {
      "from": null,
      "id": 174,
      "name": "label",
      "parent": 172,
      "props": {
        "children": {
          "type": "value",
          "value": "Specimen"
        }
      },
      "seq": 174,
      "void": false
    },
    {
      "from": null,
      "id": 175,
      "name": "div",
      "parent": 173,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", alignItems: \"center\"}"
        }
      },
      "seq": 175,
      "void": false
    },
    {
      "from": null,
      "id": 176,
      "name": "div",
      "parent": 173,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{width: \"35%\", display: \"flex\", alignItems: \"center\"}"
        }
      },
      "seq": 176,
      "void": false
    },
    {
      "from": null,
      "id": 177,
      "name": "div",
      "parent": 173,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{width: \"35%\", display: \"flex\", alignItems: \"center\"}"
        }
      },
      "seq": 177,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 178,
      "name": "Checkbox",
      "parent": 175,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.sepecimen"
        },
        "label": {
          "type": "code",
          "value": "props.sepecimen ? \"Yes\" : \"N/A\""
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeSepecimen"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"bold\", minWidth: \"max-content\"}"
        },
        "toggle": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 178,
      "void": false
    },
    {
      "from": null,
      "id": 181,
      "name": "label",
      "parent": 176,
      "props": {
        "children": {
          "type": "value",
          "value": "ขวด"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"bold\"}"
        }
      },
      "seq": 227,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 182,
      "name": "Input",
      "parent": 176,
      "props": {
        "disabled": {
          "type": "code",
          "value": "props.chkCyto ? false : true"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeCyto"
        },
        "style": {
          "type": "code",
          "value": "{margin: \"0px 10px\", width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.cyto"
        }
      },
      "seq": 182,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 183,
      "name": "Checkbox",
      "parent": 177,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.chkPatho"
        },
        "disabled": {
          "type": "code",
          "value": "props.sepecimen ? false : true"
        },
        "label": {
          "type": "value",
          "value": "PATHO"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeChkPatho"
        },
        "style": {
          "type": "code",
          "value": "{margin: \"0px 10px\",fontWeight: \"bold\", minWidth: \"max-content\"}"
        }
      },
      "seq": 183,
      "void": false
    },
    {
      "from": null,
      "id": 185,
      "name": "label",
      "parent": 177,
      "props": {
        "children": {
          "type": "value",
          "value": "ชิ้น"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"bold\"}"
        }
      },
      "seq": 186,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 186,
      "name": "Input",
      "parent": 177,
      "props": {
        "disabled": {
          "type": "code",
          "value": "props.chkPatho ? false : true"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangePatho"
        },
        "style": {
          "type": "code",
          "value": "{margin: \"0px 10px\", width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.patho"
        }
      },
      "seq": 185,
      "void": false
    },
    {
      "from": null,
      "id": 187,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", alignItems: \"center\" ,paddingBottom: \"5px\"}"
        }
      },
      "seq": 187,
      "void": false
    },
    {
      "from": null,
      "id": 188,
      "name": "div",
      "parent": 187,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", justifyContent: \"flex-end\", minWidth: \"120px\", maxWidth: \"120px\",margin: \"5px 10px 0px 0px\", fontWeight: \"bold\"}"
        }
      },
      "seq": 188,
      "void": false
    },
    {
      "from": null,
      "id": 189,
      "name": "div",
      "parent": 187,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{width: \"90%\", margin: \"5px 10px 0px 0px\", padding: \"10px\", display: \"flex\", alignItems: \"center\"}"
        }
      },
      "seq": 189,
      "void": false
    },
    {
      "from": null,
      "id": 190,
      "name": "label",
      "parent": 188,
      "props": {
        "children": {
          "type": "value",
          "value": "Blood Loss (ml.)"
        }
      },
      "seq": 190,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 191,
      "name": "Input",
      "parent": 189,
      "props": {
        "onChange": {
          "type": "code",
          "value": "props.onChangeBlood"
        },
        "style": {
          "type": "code",
          "value": "{width: \"78%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.blood"
        }
      },
      "seq": 191,
      "void": false
    },
    {
      "from": null,
      "id": 192,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", alignItems: \"center\" ,paddingBottom: \"5px\"}"
        }
      },
      "seq": 192,
      "void": false
    },
    {
      "from": null,
      "id": 193,
      "name": "div",
      "parent": 192,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", justifyContent: \"flex-end\", minWidth: \"120px\", maxWidth: \"120px\", margin: \"5px 10px 0px 0px\", fontWeight: \"bold\"}"
        }
      },
      "seq": 193,
      "void": false
    },
    {
      "from": null,
      "id": 194,
      "name": "div",
      "parent": 192,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{width: \"90%\", margin: \"5px 10px 0px 0px\", padding: \"10px\", display: \"flex\", alignItems: \"center\"}"
        }
      },
      "seq": 194,
      "void": false
    },
    {
      "from": null,
      "id": 195,
      "name": "label",
      "parent": 193,
      "props": {
        "children": {
          "type": "value",
          "value": "Tourniquet"
        }
      },
      "seq": 195,
      "void": false
    },
    {
      "from": null,
      "id": 196,
      "name": "label",
      "parent": 194,
      "props": {
        "children": {
          "type": "code",
          "value": "props.tourniquet ? \"Yes\" : \"N/A\""
        },
        "style": {
          "type": "code",
          "value": "{margin: \"0px 10px\", fontWeight: \"bold\"}"
        }
      },
      "seq": 197,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 197,
      "name": "Checkbox",
      "parent": 194,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.tourniquet"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeTourniquet"
        },
        "toggle": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 196,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 198,
      "name": "Input",
      "parent": 194,
      "props": {
        "disabled": {
          "type": "code",
          "value": "props.tourniquet ? false : true"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeTourniquetDetail"
        },
        "style": {
          "type": "code",
          "value": "{width: \"70%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.tourniquetDetail"
        }
      },
      "seq": 198,
      "void": false
    },
    {
      "from": null,
      "id": 199,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", alignItems: \"center\", justifyContent: \"flex-end\" ,padding: \"5px 10px 0px 0px\",margin: \"5px 10px 0px 0px\"}"
        }
      },
      "seq": 199,
      "void": false
    },
    {
      "from": null,
      "id": 200,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", alignItems: \"baseline\" ,paddingBottom: \"5px\"}"
        }
      },
      "seq": 200,
      "void": false
    },
    {
      "from": null,
      "id": 201,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", alignItems: \"center\" ,paddingBottom: \"5px\"}"
        }
      },
      "seq": 201,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 202,
      "name": "Button",
      "parent": 199,
      "props": {
        "children": {
          "type": "value",
          "value": "Template"
        },
        "color": {
          "type": "value",
          "value": "teal"
        },
        "onClick": {
          "type": "code",
          "value": "props.openTemplate"
        },
        "style": {
          "type": "code",
          "value": "{backgroundColor: \"rgba(19,128,88)\"}"
        }
      },
      "seq": 202,
      "void": false
    },
    {
      "from": null,
      "id": 203,
      "name": "div",
      "parent": 200,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", justifyContent: \"flex-end\", minWidth: \"120px\", maxWidth: \"120px\",margin: \"5px 10px 0px 0px\", fontWeight: \"bold\"}"
        }
      },
      "seq": 203,
      "void": false
    },
    {
      "from": null,
      "id": 204,
      "name": "div",
      "parent": 200,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "ui form"
        },
        "style": {
          "type": "code",
          "value": "{width: \"90%\", margin: \"5px 10px 0px 0px\", padding: \"10px\", display: \"flex\", alignItems: \"center\"}"
        }
      },
      "seq": 204,
      "void": false
    },
    {
      "from": null,
      "id": 205,
      "name": "label",
      "parent": 203,
      "props": {
        "children": {
          "type": "value",
          "value": "Procedure"
        }
      },
      "seq": 205,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 207,
      "name": "TextArea",
      "parent": 204,
      "props": {
        "disabled": {
          "type": "value",
          "value": ""
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeProcedure"
        },
        "rows": {
          "type": "value",
          "value": "10"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\", padding: \"10px\", resize: \"none\"}"
        },
        "value": {
          "type": "code",
          "value": "props.procedure"
        }
      },
      "seq": 207,
      "void": true
    },
    {
      "from": null,
      "id": 208,
      "name": "div",
      "parent": 201,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", justifyContent: \"flex-end\", width: \"10%\", margin: \"5px 10px 0px 0px\", fontWeight: \"bold\"}"
        }
      },
      "seq": 208,
      "void": false
    },
    {
      "from": null,
      "id": 209,
      "name": "div",
      "parent": 201,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{width: \"90%\", margin: \"5px 10px 0px 0px\", padding: \"10px\", display: \"flex\", alignItems: \"center\", justifyContent: \"space-between\"}"
        }
      },
      "seq": 209,
      "void": false
    },
    {
      "from": null,
      "id": 212,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{padding: \"20px\", backgroundColor: \"rgba(245,245,245)\", margin: \"5px 10px 0px 0px\"}"
        }
      },
      "seq": 212,
      "void": false
    },
    {
      "from": null,
      "id": 213,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", alignItems: \"center\", justifyContent: \"flex-end\" ,padding: \"5px 10px 0px 0px\",margin: \"15px 10px 0px 0px\"}"
        }
      },
      "seq": 213,
      "void": false
    },
    {
      "from": null,
      "id": 214,
      "name": "div",
      "parent": 212,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 214,
      "void": false
    },
    {
      "from": null,
      "id": 215,
      "name": "div",
      "parent": 212,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 241,
      "void": false
    },
    {
      "from": null,
      "id": 216,
      "name": "label",
      "parent": 214,
      "props": {
        "children": {
          "type": "value",
          "value": "ภาพประกอบการผ่าตัด"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"bold\"}"
        }
      },
      "seq": 216,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 217,
      "name": "Button",
      "parent": 215,
      "props": {
        "children": {
          "type": "value",
          "value": "Take Photo"
        },
        "color": {
          "type": "value",
          "value": "blue"
        },
        "disabled": {
          "type": "code",
          "value": "props.disabledUpload"
        },
        "onClick": {
          "type": "code",
          "value": "props.takePhoto"
        },
        "style": {
          "type": "code",
          "value": "{marginRight: \"10px\" }"
        }
      },
      "seq": 217,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 218,
      "name": "Button",
      "parent": 215,
      "props": {
        "children": {
          "type": "value",
          "value": "Upload"
        },
        "color": {
          "type": "value",
          "value": "blue"
        },
        "disabled": {
          "type": "code",
          "value": "props.disabledUpload"
        },
        "onClick": {
          "type": "code",
          "value": "props.uploadImg"
        }
      },
      "seq": 218,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 222,
      "name": "Dropdown",
      "parent": 168,
      "props": {
        "onChange": {
          "type": "code",
          "value": "props.onChangeWound"
        },
        "options": {
          "type": "code",
          "value": "props.woundOptions"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{width: \"78%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.wound"
        }
      },
      "seq": 222,
      "void": false
    },
    {
      "from": null,
      "id": 223,
      "name": "div",
      "parent": 94,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{...(props.secondary_style || {})}"
        }
      },
      "seq": 223,
      "void": false
    },
    {
      "from": null,
      "id": 224,
      "name": "label",
      "parent": 223,
      "props": {
        "children": {
          "type": "value",
          "value": "Secondary Diagnosis"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"bold\"}"
        }
      },
      "seq": 224,
      "void": false
    },
    {
      "from": null,
      "id": 225,
      "name": "div",
      "parent": 94,
      "props": {
        "children": {
          "type": "code",
          "value": "props.secondaryDiagnosis"
        },
        "style": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 225,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 226,
      "name": "Button",
      "parent": 97,
      "props": {
        "children": {
          "type": "value",
          "value": "เพิ่ม Secondary Diagnosis"
        },
        "onClick": {
          "type": "code",
          "value": "props.addSecondary"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\", height: \"100%\", padding: \"20px\"}"
        }
      },
      "seq": 226,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 227,
      "name": "Checkbox",
      "parent": 176,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.chkCyto"
        },
        "disabled": {
          "type": "code",
          "value": "props.sepecimen ? false : true"
        },
        "label": {
          "type": "value",
          "value": "CYTO"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeChkCyto"
        },
        "style": {
          "type": "code",
          "value": "{margin: \"0px 10px\",fontWeight: \"bold\",minWidth: \"max-content\"}"
        }
      },
      "seq": 180,
      "void": false
    },
    {
      "from": null,
      "id": 228,
      "name": "div",
      "parent": 124,
      "props": {
        "children": {
          "type": "code",
          "value": "props.operationProcedure"
        },
        "className": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: props.hideOperatingTreatment?\"none\":\"\"}"
        }
      },
      "seq": 126,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 229,
      "name": "Button",
      "parent": 126,
      "props": {
        "children": {
          "type": "value",
          "value": "เพิ่ม รายการหัตถการ"
        },
        "onClick": {
          "type": "code",
          "value": "props.addProcedure"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\", height: \"100%\", padding: \"20px\"}"
        }
      },
      "seq": 229,
      "void": false
    },
    {
      "from": null,
      "id": 230,
      "name": "div",
      "parent": 124,
      "props": {
        "children": {
          "type": "code",
          "value": "props.operation"
        }
      },
      "seq": 230,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 231,
      "name": "Button",
      "parent": 129,
      "props": {
        "children": {
          "type": "value",
          "value": "เพิ่ม Operation"
        },
        "onClick": {
          "type": "code",
          "value": "props.addOperation"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\", height: \"100%\", padding: \"20px\"}"
        }
      },
      "seq": 231,
      "void": false
    },
    {
      "from": null,
      "id": 233,
      "name": "div",
      "parent": 94,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: props.openSearchSecondary ? \"block\" : \"none\", zIndex: 100,  width: \"calc(100% - 20px)\", } "
        }
      },
      "seq": 97,
      "void": false
    },
    {
      "from": "react-lib/frameworks/Table",
      "id": 234,
      "name": "Table",
      "parent": 233,
      "props": {
        "data": {
          "type": "code",
          "value": "props.searchPostProcedures"
        },
        "defaultPageSize": {
          "type": "code",
          "value": ""
        },
        "getTrProps": {
          "type": "code",
          "value": "props.principleDiagnosis"
        },
        "keys": {
          "type": "value",
          "value": "medterm,icdterm,icdcode"
        },
        "minRows": {
          "type": "value",
          "value": "4"
        },
        "showPagination": {
          "type": "code",
          "value": "false"
        },
        "style": {
          "type": "code",
          "value": "{ height: \"150px\" }"
        }
      },
      "seq": 234,
      "void": false
    },
    {
      "from": null,
      "id": 235,
      "name": "div",
      "parent": 213,
      "props": {
        "children": {
          "type": "code",
          "value": "props.buttonSave"
        }
      },
      "seq": 235,
      "void": false
    },
    {
      "from": null,
      "id": 236,
      "name": "div",
      "parent": 213,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{margin: \"0px 5px\" ,display: \"none\"}"
        }
      },
      "seq": 236,
      "void": false
    },
    {
      "from": null,
      "id": 237,
      "name": "div",
      "parent": 213,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"none\"}"
        }
      },
      "seq": 237,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 239,
      "name": "Button",
      "parent": 236,
      "props": {
        "children": {
          "type": "value",
          "value": "Confirm"
        },
        "color": {
          "type": "value",
          "value": "green"
        }
      },
      "seq": 239,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 240,
      "name": "Button",
      "parent": 237,
      "props": {
        "children": {
          "type": "value",
          "value": "Unconfirm"
        },
        "color": {
          "type": "value",
          "value": "red"
        }
      },
      "seq": 240,
      "void": false
    },
    {
      "from": null,
      "id": 241,
      "name": "div",
      "parent": 212,
      "props": {
        "children": {
          "type": "code",
          "value": "props.imgDetail"
        },
        "style": {
          "type": "code",
          "value": "{padding: \"10px 0px\"}"
        }
      },
      "seq": 215,
      "void": false
    },
    {
      "from": null,
      "id": 242,
      "name": "div",
      "parent": 209,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 211,
      "void": false
    },
    {
      "from": null,
      "id": 243,
      "name": "div",
      "parent": 209,
      "props": {
        "children": {
          "type": "code",
          "value": "props.onPrint"
        }
      },
      "seq": 243,
      "void": false
    },
    {
      "from": null,
      "id": 244,
      "name": "label",
      "parent": 95,
      "props": {
        "children": {
          "type": "value",
          "value": "*"
        },
        "style": {
          "type": "code",
          "value": "{ color: \"red\" }"
        }
      },
      "seq": 244,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 245,
      "name": "TextArea",
      "parent": 90,
      "props": {
        "onChange": {
          "type": "code",
          "value": "props.onChangePreMedicalDescription"
        },
        "style": {
          "type": "code",
          "value": "{margin: \"0px 10px\", width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.preMedicalDescription"
        }
      },
      "seq": 245,
      "void": true
    },
    {
      "from": null,
      "id": 246,
      "name": "div",
      "parent": 84,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"bold\", width: \"14%\"}"
        }
      },
      "seq": 90,
      "void": false
    },
    {
      "from": null,
      "id": 247,
      "name": "label",
      "parent": 246,
      "props": {
        "children": {
          "type": "value",
          "value": "Pre-Diagnosis Detail"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"bold\", width: \"14%\"}"
        }
      },
      "seq": 247,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 248,
      "name": "TextArea",
      "parent": 154,
      "props": {
        "disabled": {
          "type": "code",
          "value": "props.implant ? false : true"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeImplantDetail"
        },
        "readOnly": {
          "type": "code",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{width: \"70%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.implantDetail"
        }
      },
      "seq": 248,
      "void": true
    },
    {
      "from": null,
      "id": 249,
      "name": "div",
      "parent": 94,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\", margin: \"5px 10px 0px 0px\", display: \"flex\", alignItems: \"start\" }"
        }
      },
      "seq": 249,
      "void": false
    },
    {
      "from": null,
      "id": 250,
      "name": "div",
      "parent": 249,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "ui form"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\", margin: \"5px 10px 0px 0px\", display: \"flex\", alignItems: \"start\" }"
        }
      },
      "seq": 250,
      "void": false
    },
    {
      "from": null,
      "id": 251,
      "name": "div",
      "parent": 250,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"bold\", width: \"14%\"}"
        }
      },
      "seq": 251,
      "void": false
    },
    {
      "from": null,
      "id": 252,
      "name": "div",
      "parent": 250,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{width: \"65%\"}"
        }
      },
      "seq": 252,
      "void": false
    },
    {
      "from": null,
      "id": 253,
      "name": "label",
      "parent": 251,
      "props": {
        "children": {
          "type": "value",
          "value": "Post-Diagnosis Detail"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"bold\", width: \"14%\"}"
        }
      },
      "seq": 253,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 254,
      "name": "TextArea",
      "parent": 252,
      "props": {
        "onChange": {
          "type": "code",
          "value": "props.onChangePostMedicalDescription"
        },
        "style": {
          "type": "code",
          "value": "{margin: \"0px 10px\", width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.postMedicalDescription"
        }
      },
      "seq": 254,
      "void": true
    },
    {
      "from": null,
      "id": 255,
      "name": "div",
      "parent": 124,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "ui form"
        },
        "style": {
          "type": "code",
          "value": "{display: \"none\", alignItems: \"center\", marginBottom: \"1rem\"}"
        }
      },
      "seq": 128,
      "void": false
    },
    {
      "from": null,
      "id": 256,
      "name": "label",
      "parent": 255,
      "props": {
        "children": {
          "type": "value",
          "value": "Other detail"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"bold\"}"
        }
      },
      "seq": 256,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 257,
      "name": "TextArea",
      "parent": 255,
      "props": {
        "onChange": {
          "type": "code",
          "value": "props.onChangeOtherTreatment"
        },
        "rows": {
          "type": "value",
          "value": "5"
        },
        "value": {
          "type": "code",
          "value": "props.otherTreatment || \"\""
        }
      },
      "seq": 257,
      "void": true
    }
  ],
  "forwardRef": false,
  "height": 100,
  "isMounted": false,
  "memo": false,
  "name": "CardPostOperationUX",
  "project": "IsHealth",
  "screenPropsDefault": {
    "debug": true,
    "showSupplyList": false
  },
  "width": 75
}

*********************************************************************************** */
