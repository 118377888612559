import React from 'react';
import CardLayout from '../common/CardLayout';
import { Grid, Divider} from 'semantic-ui-react'

const styles = {
  gridHeader: {
    fontWeight: 'bold',
    backgroundColor: '#F2F2F2'
  },
  gridColumn: {
    overflowWrap: 'break-word'
  }
}

const CardTreatmentResultView = (props) => {

  const generatePrincipal = (procedure) => {
    return procedure.map((items, index) => {
      return (
        <div key={index}>
          {items.icd9cm_code} + {items.icd9cm_term}
        </div>
      )
    })
  }

  const generateData = () => {
    if(props.treatmentOrderResult.length === 0){
      return 'No Data.'
    }
    return props.treatmentOrderResult.map((items) => {
      let treatmentOrder = items.treatmentOrder
      let procedure = items.procedure
      return (
        <div>
          <Grid celled >
          <Grid.Row>
              <Grid.Column width={3} style={styles.gridHeader}>
                วันเวลาเริ่มทำหัตถการ
            </Grid.Column>
              <Grid.Column width={13} style={styles.gridColumn}>
                {treatmentOrder.start} - {treatmentOrder.finish}
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={3} style={styles.gridHeader}>
                ผู้สั่งทำหัตถการ 
            </Grid.Column>
              <Grid.Column width={13} style={styles.gridColumn}>
                {treatmentOrder.order_by_name}
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={3} style={styles.gridHeader}>
                รายละเอียดคำสั่งหัตถการ
            </Grid.Column>
              <Grid.Column width={13} style={styles.gridColumn}>
                {treatmentOrder.order_detail}
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={3} style={styles.gridHeader}>
                รายละเอียดอุปกรณ์
            </Grid.Column>
              <Grid.Column width={13} style={styles.gridColumn}>
                {treatmentOrder.equipment_detail}
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={3} style={styles.gridHeader}>
                Principal
            </Grid.Column>
              <Grid.Column width={13} style={styles.gridColumn}>
                {generatePrincipal(procedure)}
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={3} style={styles.gridHeader}>
                รายละเอียด
            </Grid.Column>
              <Grid.Column width={13} style={styles.gridColumn}>
                {treatmentOrder.result}
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={3} style={styles.gridHeader}>
                ภาวะแทรกซ้อน
            </Grid.Column>
              <Grid.Column width={13} style={styles.gridColumn}>
                {treatmentOrder.complications}
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={3} style={styles.gridHeader}>
                รหัสผู้ทำหัตถการ
            </Grid.Column>
              <Grid.Column width={13} style={styles.gridColumn}>
                {treatmentOrder.order_perform_by_name}
              </Grid.Column>
            </Grid.Row>
          </Grid>
          <br />
          <Divider />
        </div>
      )
    })
  }

  return (
    <CardLayout
      titleText='Treatment Result'
      headerColor='purple'
      closeable={props.closeable}
      toggleable={props.toggleable}
      defaultBackground={props.defaultBackground}
      hideHeaderIcon={props.hideHeaderIcon}
      loading={props.treatmentNoteLoading}
      enableMargin={props.enableMargin}
    >
      <div style={{ maxHeight: 480, overflowY: 'auto' }}>
        {generateData()}
      </div>
    </CardLayout>
  )
}

CardTreatmentResultView.defaultProps = {
  closeable: true,
  toggleable: true,
  defaultBackground: true,
  hideHeaderIcon: false,
  treatmentOrderResult: [],
  treatmentNoteLoading: false,
  onGetTreatmentResult: () => { },
  enableMargin: true,
};

export default CardTreatmentResultView;
