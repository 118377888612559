import FormCheckboxImages from "./FormCheckbox";
export default function toPDFMakeData(props: any) {
  const diagnosisCheck = (diagnosis: any) => {
    if (diagnosis && diagnosis.trim().length > 0) {
      return [
        {
          pageBreak: ``,
          decoration: ``,
          alignment: `left`,
          color: ``,
          margin: [0, 0, 0, 0],
          width: `auto`,
          decorationStyle: ``,
          bold: false,
          text: ` `,
          preserveLeadingSpaces: true,
          decorationColor: ``,
          fontSize: 15,
        },
      ];
    } else {
      return [
        {
          pageBreak: ``,
          decoration: ``,
          alignment: `left`,
          color: ``,
          margin: [0, 0, 0, 0],
          width: `auto`,
          decorationStyle: ``,
          bold: false,
          text: `............................................................................................................................................................................................................`,
          preserveLeadingSpaces: true,
          decorationColor: ``,
          fontSize: 15,
        },
        {
          alignment: `left`,
          preserveLeadingSpaces: true,
          width: `auto`,
          fontSize: 15,
          decoration: ``,
          decorationStyle: ``,
          decorationColor: ``,
          color: ``,
          margin: [0, 0, 0, 0],
          text: `............................................................................................................................................................................................................`,
          bold: false,
          pageBreak: ``,
        },
      ];
    }
  };

  return {
    defaultStyle: {
      font: `THSarabunNew`,
    },
    pageOrientation: `portrait`,
    pageSize: `A4`,
    content: [
      {
        text: `ใบรับรองแพทย์`,
        decorationStyle: ``,
        color: ``,
        fontSize: `18`,
        width: `auto`,
        preserveLeadingSpaces: true,
        alignment: `center`,
        decoration: ``,
        bold: `true`,
        decorationColor: ``,
        pageBreak: ``,
      },
      {
        text: `สำหรับการเดินทาง`,
        decorationColor: ``,
        decorationStyle: ``,
        bold: `true`,
        decoration: ``,
        fontSize: `18`,
        alignment: `center`,
        width: `auto`,
        color: ``,
        pageBreak: ``,
        preserveLeadingSpaces: true,
      },
      {
        decoration: ``,
        alignment: `left`,
        decorationColor: ``,
        bold: false,
        text: ` `,
        decorationStyle: ``,
        width: `auto`,
        preserveLeadingSpaces: true,
        fontSize: 15,
        pageBreak: ``,
        color: ``,
      },
      {
        columns: [
          {
            fontSize: 15,
            decorationStyle: ``,
            alignment: `left`,
            decoration: ``,
            bold: false,
            preserveLeadingSpaces: true,
            color: ``,
            width: 420,
            decorationColor: ``,
            pageBreak: ``,
            text: ``,
          },
          {
            preserveLeadingSpaces: true,
            bold: true,
            width: `auto`,
            decorationStyle: ``,
            alignment: `left`,
            decorationColor: ``,
            color: ``,
            decoration: ``,
            pageBreak: ``,
            text: `วันที่ ${props.items?.formatCreatedDate}`,
            fontSize: 15,
          },
        ],
      },
      {
        width: `auto`,
        color: ``,
        text: `ถึงผู้ที่เกี่ยวข้อง`,
        decorationColor: ``,
        decorationStyle: ``,
        decoration: ``,
        fontSize: 15,
        alignment: `left`,
        bold: false,
        pageBreak: ``,
        preserveLeadingSpaces: true,
      },
      {
        columns: [
          {
            alignment: `left`,
            fontSize: 15,
            pageBreak: ``,
            bold: false,
            decoration: ``,
            decorationStyle: ``,
            decorationColor: ``,
            color: ``,
            width: 40,
            text: ``,
            preserveLeadingSpaces: true,
          },
          {
            color: ``,
            fontSize: 15,
            decorationStyle: ``,
            alignment: `left`,
            pageBreak: ``,
            text: `ขอรับรองว่า `,
            width: `auto`,
            decorationColor: ``,
            bold: false,
            preserveLeadingSpaces: true,
            decoration: ``,
          },
          {
            decoration: ``,
            fontSize: 15,
            text: ``,
            pageBreak: ``,
            decorationColor: ``,
            width: 10,
            preserveLeadingSpaces: true,
            color: ``,
            decorationStyle: ``,
            alignment: `left`,
            bold: false,
          },
          {
            stack: [
              {
                decoration: ``,
                decorationStyle: ``,
                alignment: `left`,
                preserveLeadingSpaces: true,
                decorationColor: ``,
                text: props.items?.patientInfo?.full_name,
                margin: [0, 0, 0, -15],
                bold: false,
                fontSize: 15,
                color: ``,
                pageBreak: ``,
                width: `auto`,
              },
              {
                decorationStyle: ``,
                alignment: `left`,
                margin: [0, 0, 0, 0],
                fontSize: 15,
                text: `....................................................................................................................................................................`,
                decoration: ``,
                decorationColor: ``,
                pageBreak: ``,
                color: ``,
                bold: false,
                preserveLeadingSpaces: true,
                width: `auto`,
              },
            ],
          },
        ],
      },
      {
        columns: [
          {
            decorationColor: ``,
            color: ``,
            fontSize: 15,
            text: `หมายเลขประจำตัวประชาชน / เลขที่หนังสือเดินทาง / อื่นๆ`,
            preserveLeadingSpaces: true,
            pageBreak: ``,
            alignment: `left`,
            width: `auto`,
            decoration: ``,
            decorationStyle: ``,
            bold: false,
          },
          {
            alignment: `left`,
            width: 10,
            text: ``,
            preserveLeadingSpaces: true,
            color: ``,
            decoration: ``,
            decorationStyle: ``,
            decorationColor: ``,
            bold: false,
            pageBreak: ``,
            fontSize: 15,
          },
          {
            stack: [
              {
                margin: [0, 0, 0, -15],
                preserveLeadingSpaces: true,
                decoration: ``,
                decorationColor: ``,
                bold: false,
                width: `auto`,
                text:
                  props.items?.patientInfo?.citizen_no === "" ||
                  props.items?.patientInfo?.citizen_no === "Non citizen identity"
                    ? props.items?.patientInfo?.citizen_passport === "" ||
                      props.items?.patientInfo?.citizen_passport === "Non citizen identity"
                      ? " "
                      : props.items?.patientInfo?.citizen_passport
                    : props.items?.patientInfo?.citizen_no,
                fontSize: 15,
                color: ``,
                alignment: `left`,
                pageBreak: ``,
                decorationStyle: ``,
              },
              {
                alignment: `left`,
                pageBreak: ``,
                fontSize: 15,
                preserveLeadingSpaces: true,
                color: ``,
                margin: [0, 0, 0, 0],
                width: `auto`,
                text: `........................................................................................................`,
                decorationColor: ``,
                decorationStyle: ``,
                decoration: ``,
                bold: false,
              },
            ],
          },
        ],
      },
      {
        columns: [
          {
            width: `auto`,
            decorationStyle: ``,
            bold: false,
            decoration: ``,
            color: ``,
            fontSize: 15,
            text: `อายุ `,
            pageBreak: ``,
            alignment: `left`,
            decorationColor: ``,
            preserveLeadingSpaces: true,
          },
          {
            decorationColor: ``,
            width: 10,
            pageBreak: ``,
            fontSize: 15,
            decorationStyle: ``,
            alignment: `left`,
            bold: false,
            preserveLeadingSpaces: true,
            text: ``,
            color: ``,
            decoration: ``,
          },
          {
            stack: [
              {
                preserveLeadingSpaces: true,
                fontSize: 15,
                width: `auto`,
                pageBreak: ``,
                margin: [0, 0, 0, -15],
                alignment: `left`,
                decorationStyle: ``,
                decorationColor: ``,
                decoration: ``,
                text: `${props.items?.ageYear} ปี ${props.items?.ageMonth} เดือน ${props.items?.ageDay} วัน`,
                color: ``,
                bold: false,
              },
              {
                preserveLeadingSpaces: true,
                pageBreak: ``,
                decorationColor: ``,
                bold: false,
                decoration: ``,
                decorationStyle: ``,
                margin: [0, 0, 0, 0],
                alignment: `left`,
                text: `............................................................................`,
                color: ``,
                fontSize: 15,
                width: `auto`,
              },
            ],
            width: `auto`,
          },
          {
            decorationColor: ``,
            decorationStyle: ``,
            width: 10,
            color: ``,
            pageBreak: ``,
            preserveLeadingSpaces: true,
            fontSize: 15,
            text: ` `,
            alignment: `left`,
            decoration: ``,
            bold: false,
          },
          {
            text: `หมายเลขผู้ป่วย    `,
            width: `auto`,
            preserveLeadingSpaces: true,
            pageBreak: ``,
            decorationColor: ``,
            bold: false,
            color: ``,
            fontSize: 15,
            decorationStyle: ``,
            alignment: `left`,
            decoration: ``,
          },
          {
            decorationColor: ``,
            decorationStyle: ``,
            width: 10,
            color: ``,
            pageBreak: ``,
            preserveLeadingSpaces: true,
            fontSize: 15,
            text: ` `,
            alignment: `left`,
            decoration: ``,
            bold: false,
          },
          {
            stack: [
              {
                color: ``,
                decoration: ``,
                bold: false,
                fontSize: 15,
                alignment: `left`,
                preserveLeadingSpaces: true,
                width: `auto`,
                decorationColor: ``,
                decorationStyle: ``,
                margin: [0, 0, 0, -15],
                pageBreak: ``,
                text: props.items?.patientInfo?.hn,
              },
              {
                margin: [0, 0, 0, 0],
                color: ``,
                decorationStyle: ``,
                fontSize: 15,
                preserveLeadingSpaces: true,
                pageBreak: ``,
                decoration: ``,
                text: `....................................................................................`,
                width: `auto`,
                bold: false,
                alignment: `left`,
                decorationColor: ``,
              },
            ],
            width: `auto`,
          },
        ],
      },
      {
        columns: [
          {
            decoration: ``,
            width: `auto`,
            pageBreak: ``,
            alignment: `left`,
            decorationStyle: ``,
            color: ``,
            decorationColor: ``,
            text: `ได้รับการตรวจ และการรักษาในโรงพยาบาลในฐานะ `,
            fontSize: 15,
            preserveLeadingSpaces: true,
            bold: false,
          },
          {
            bold: false,
            decorationStyle: ``,
            decoration: ``,
            width: 5,
            text: ``,
            fontSize: 15,
            pageBreak: ``,
            preserveLeadingSpaces: true,
            alignment: `left`,
            decorationColor: ``,
            color: ``,
          },
          {
            stack: [
              {
                alignment: `left`,
                margin: [0, 0, 0, -15],
                preserveLeadingSpaces: true,
                width: `auto`,
                fontSize: 15,
                decorationStyle: ``,
                bold: false,
                color: ``,
                pageBreak: ``,
                decorationColor: ``,
                text:
                  props.items?.patientInfo?.encounter_type === "OPD" ? "ผู้ป่วยนอก" : "ผู้ป่วยใน",
                decoration: ``,
              },
              {
                text: `........................................`,
                decorationColor: ``,
                margin: [0, 0, 0, 0],
                preserveLeadingSpaces: true,
                decorationStyle: ``,
                alignment: `left`,
                color: ``,
                bold: false,
                width: `auto`,
                pageBreak: ``,
                decoration: ``,
                fontSize: 15,
              },
            ],
            width: `auto`,
          },
          {
            color: ``,
            decorationColor: ``,
            decoration: ``,
            preserveLeadingSpaces: true,
            pageBreak: ``,
            width: 10,
            text: ``,
            decorationStyle: ``,
            bold: false,
            alignment: `left`,
            fontSize: 15,
          },
          {
            color: ``,
            fontSize: 15,
            decoration: ``,
            decorationStyle: ``,
            text: `เมื่อ`,
            bold: false,
            alignment: `left`,
            preserveLeadingSpaces: true,
            width: `auto`,
            decorationColor: ``,
            pageBreak: ``,
            margin: [0, 0, 0, 0],
          },
          {
            color: ``,
            decorationColor: ``,
            decoration: ``,
            preserveLeadingSpaces: true,
            pageBreak: ``,
            width: 10,
            text: ``,
            decorationStyle: ``,
            bold: false,
            alignment: `left`,
            fontSize: 15,
          },
          {
            stack: [
              {
                margin: [0, 0, 0, -15],
                bold: false,
                fontSize: 15,
                width: `auto`,
                color: ``,
                decorationStyle: ``,
                decorationColor: ``,
                alignment: `left`,
                text: props.items?.examDate ? props.items?.examDate : ` `,
                preserveLeadingSpaces: true,
                pageBreak: ``,
                decoration: ``,
              },
              {
                decoration: ``,
                margin: [0, 0, 0, 0],
                color: ``,
                decorationColor: ``,
                fontSize: 15,
                decorationStyle: ``,
                text: `.................................................................`,
                pageBreak: ``,
                preserveLeadingSpaces: true,
                bold: false,
                width: `auto`,
                alignment: `left`,
              },
            ],
            width: `auto`,
          },
        ],
      },
      {
        columns: [
          {
            decorationStyle: ``,
            fontSize: 15,
            width: `auto`,
            alignment: `left`,
            text: `การวินิจฉัย :`,
            preserveLeadingSpaces: true,
            decoration: ``,
            color: ``,
            bold: false,
            pageBreak: ``,
            decorationColor: ``,
          },
          {
            text: ``,
            decorationColor: ``,
            preserveLeadingSpaces: true,
            pageBreak: ``,
            bold: false,
            width: 10,
            decorationStyle: ``,
            fontSize: 15,
            alignment: `left`,
            color: ``,
            decoration: ``,
          },
          {
            stack: [
              {
                decoration: ``,
                color: ``,
                width: `auto`,
                preserveLeadingSpaces: true,
                pageBreak: ``,
                decorationStyle: ``,
                decorationColor: ``,
                bold: false,
                margin: [0, 0, 0, -15],
                text: props.items?.diagnosis === "" ? " " : props.items?.diagnosis,
                fontSize: 15,
                alignment: `left`,
              },
              {
                decorationStyle: ``,
                preserveLeadingSpaces: true,
                decorationColor: ``,
                bold: false,
                color: ``,
                decoration: ``,
                margin: [0, 0, 0, 0],
                pageBreak: ``,
                width: `auto`,
                text: `....................................................................................................................................................................................`,
                fontSize: 15,
                alignment: `left`,
              },
            ],
          },
        ],
      },
      ...diagnosisCheck(props.items?.diagnosis),
      {
        decorationColor: ``,
        text: ` `,
        preserveLeadingSpaces: true,
        bold: false,
        decorationStyle: ``,
        pageBreak: ``,
        decoration: ``,
        width: `auto`,
        fontSize: 15,
        alignment: `left`,
        color: ``,
      },
      {
        alignment: `left`,
        text: `ข้อเสนอแนะ และการประเมินการเดินทาง`,
        decorationColor: ``,
        decoration: ``,
        bold: false,
        pageBreak: ``,
        fontSize: 15,
        width: `auto`,
        preserveLeadingSpaces: true,
        color: ``,
        decorationStyle: ``,
      },
      {
        columns: [
          {
            decoration: ``,
            alignment: `left`,
            fontSize: 15,
            color: ``,
            width: 30,
            bold: false,
            preserveLeadingSpaces: true,
            decorationColor: ``,
            decorationStyle: ``,
            pageBreak: ``,
            text: ``,
          },
          {
            image: props.items?.travelRecommend === "first" ? "checked" : "unchecked",
            margin: [0, 4, 0, 0],
            width: 10,
            border: [false, false, false, false],
          },
          {
            color: ``,
            width: 5,
            decorationColor: ``,
            preserveLeadingSpaces: true,
            bold: false,
            text: ``,
            decoration: ``,
            alignment: `left`,
            decorationStyle: ``,
            fontSize: 15,
            pageBreak: ``,
          },
          {
            preserveLeadingSpaces: true,
            fontSize: 15,
            text: `สามารถเดินทางโดยอากาศยานได้เหมือนผู้โดยสารทั่วไป`,
            width: `auto`,
            color: ``,
            decorationColor: ``,
            decorationStyle: ``,
            pageBreak: ``,
            bold: false,
            decoration: ``,
            alignment: `left`,
          },
        ],
      },
      {
        columns: [
          {
            bold: false,
            decorationStyle: ``,
            decoration: ``,
            text: ``,
            pageBreak: ``,
            width: 30,
            preserveLeadingSpaces: true,
            fontSize: 15,
            color: ``,
            alignment: `left`,
            decorationColor: ``,
          },
          {
            image: props.items?.travelRecommend === "second" ? "checked" : "unchecked",
            margin: [0, 4, 0, 0],
            width: 10,
            border: [false, false, false, false],
          },
          {
            decorationColor: ``,
            bold: false,
            alignment: `left`,
            decorationStyle: ``,
            fontSize: 15,
            width: 5,
            preserveLeadingSpaces: true,
            text: ``,
            decoration: ``,
            color: ``,
            pageBreak: ``,
          },
          {
            color: ``,
            bold: false,
            fontSize: 15,
            width: `auto`,
            decorationStyle: ``,
            decoration: ``,
            decorationColor: ``,
            pageBreak: ``,
            preserveLeadingSpaces: true,
            text: `สามารถเดินทางโดยอากาศยานร่วมกับเพื่อน/ครอบครัว ที่ไม่ใช่บุคลากรทางการแพทย์`,
            alignment: `left`,
          },
        ],
      },
      {
        columns: [
          {
            bold: false,
            color: ``,
            width: 30,
            text: ``,
            decorationStyle: ``,
            pageBreak: ``,
            decoration: ``,
            alignment: `left`,
            preserveLeadingSpaces: true,
            decorationColor: ``,
            fontSize: 15,
          },
          {
            image: props.items?.travelRecommend === "third" ? "checked" : "unchecked",
            margin: [0, 4, 0, 0],
            width: 10,
            border: [false, false, false, false],
          },
          {
            width: 5,
            bold: false,
            alignment: `left`,
            decoration: ``,
            decorationColor: ``,
            pageBreak: ``,
            preserveLeadingSpaces: true,
            text: ``,
            decorationStyle: ``,
            fontSize: 15,
            color: ``,
          },
          {
            pageBreak: ``,
            decoration: ``,
            bold: false,
            preserveLeadingSpaces: true,
            fontSize: 15,
            decorationColor: ``,
            width: `auto`,
            alignment: `left`,
            color: ``,
            text: `สามารถเดินทางโดยอากาศ โดยต้องมีบุคลากรทางการแพทย์ร่วมเดินทางด้วยเท่านั้น`,
            decorationStyle: ``,
          },
        ],
      },
      {
        columns: [
          {
            decoration: ``,
            decorationColor: ``,
            pageBreak: ``,
            width: 30,
            color: ``,
            fontSize: 15,
            preserveLeadingSpaces: true,
            bold: false,
            decorationStyle: ``,
            alignment: `left`,
            text: ``,
          },
          {
            image: props.items?.travelRecommend === "fourth" ? "checked" : "unchecked",
            margin: [0, 4, 0, 0],
            width: 10,
            border: [false, false, false, false],
          },
          {
            decorationColor: ``,
            fontSize: 15,
            width: 5,
            decorationStyle: ``,
            decoration: ``,
            text: ``,
            preserveLeadingSpaces: true,
            color: ``,
            bold: false,
            alignment: `left`,
            pageBreak: ``,
          },
          {
            decorationColor: ``,
            text: `ไม่เหมาะ`,
            bold: true,
            color: ``,
            width: `auto`,
            pageBreak: ``,
            preserveLeadingSpaces: true,
            alignment: `left`,
            fontSize: 15,
            decorationStyle: ``,
            decoration: `underline`,
          },
          {
            pageBreak: ``,
            decorationColor: ``,
            decorationStyle: ``,
            preserveLeadingSpaces: true,
            fontSize: 15,
            text: ` สำหรับการเดินทางโดยอากาศยาน เนื่องจากปัจจัยเสี่ยงของผู้ป่วย`,
            decoration: ``,
            width: `auto`,
            color: ``,
            bold: false,
            alignment: `left`,
          },
        ],
      },
      {
        bold: false,
        alignment: `left`,
        decoration: ``,
        decorationColor: ``,
        fontSize: 15,
        pageBreak: ``,
        margin: [0, 0, 0, 0],
        color: ``,
        width: `auto`,
        decorationStyle: ``,
        preserveLeadingSpaces: true,
        text: ` `,
      },
      {
        columns: [
          {
            width: `auto`,
            color: ``,
            decoration: ``,
            pageBreak: ``,
            alignment: `left`,
            bold: false,
            text: `ข้อกำหนดพิเศษ : `,
            decorationColor: ``,
            fontSize: 15,
            decorationStyle: ``,
            preserveLeadingSpaces: true,
          },
          {
            preserveLeadingSpaces: true,
            bold: false,
            decorationStyle: ``,
            decoration: ``,
            decorationColor: ``,
            text: ``,
            fontSize: 15,
            alignment: `left`,
            pageBreak: ``,
            color: ``,
            width: 10,
          },
          {
            stack: [
              {
                width: `auto`,
                pageBreak: ``,
                bold: false,
                decorationColor: ``,
                decoration: ``,
                text: props.items?.requireFirstLine
                  ? props.items?.requireFirstLine.length > 0
                    ? props.items?.requireFirstLine
                    : " "
                  : " ",
                margin: [0, 0, 0, -15],
                color: ``,
                fontSize: 15,
                alignment: `left`,
                preserveLeadingSpaces: true,
                decorationStyle: ``,
              },
              {
                margin: [0, 0, 0, 0],
                width: `auto`,
                decorationColor: ``,
                decorationStyle: ``,
                bold: false,
                preserveLeadingSpaces: true,
                color: ``,
                text: `...........................................................................................................................................................................`,
                fontSize: 15,
                decoration: ``,
                pageBreak: ``,
                alignment: `left`,
              },
            ],
          },
        ],
      },
      {
        stack: [
          {
            width: `auto`,
            pageBreak: ``,
            bold: false,
            decorationColor: ``,
            decoration: ``,
            text: props.items?.requireSecondLine
              ? props.items?.requireSecondLine.length > 0
                ? props.items?.requireSecondLine
                : " "
              : " ",
            margin: [0, 0, 0, -15],
            color: ``,
            fontSize: 15,
            alignment: `left`,
            preserveLeadingSpaces: true,
            decorationStyle: ``,
          },
          {
            pageBreak: ``,
            text: `............................................................................................................................................................................................................`,
            alignment: `left`,
            decoration: ``,
            margin: [0, 0, 0, 0],
            fontSize: 15,
            width: `auto`,
            bold: false,
            decorationStyle: ``,
            decorationColor: ``,
            preserveLeadingSpaces: true,
            color: ``,
          },
        ],
      },
      {
        stack: [
          {
            width: `auto`,
            pageBreak: ``,
            bold: false,
            decorationColor: ``,
            decoration: ``,
            text: props.items?.requireThirdLine
              ? props.items?.requireThirdLine.length > 0
                ? props.items?.requireThirdLine
                : " "
              : " ",
            margin: [0, 0, 0, -15],
            color: ``,
            fontSize: 15,
            alignment: `left`,
            preserveLeadingSpaces: true,
            decorationStyle: ``,
          },
          {
            color: ``,
            preserveLeadingSpaces: true,
            width: `auto`,
            pageBreak: ``,
            alignment: `left`,
            decorationStyle: ``,
            bold: false,
            margin: [0, 0, 0, 0],
            decoration: ``,
            text: `............................................................................................................................................................................................................`,
            decorationColor: ``,
            fontSize: 15,
          },
        ],
      },
      {
        columns: [
          {
            color: ``,
            preserveLeadingSpaces: true,
            text: `ความคิดเห็น :  `,
            fontSize: 15,
            decoration: ``,
            alignment: `left`,
            decorationColor: ``,
            bold: false,
            pageBreak: ``,
            decorationStyle: ``,
            width: `auto`,
          },
          {
            pageBreak: ``,
            preserveLeadingSpaces: true,
            color: ``,
            decoration: ``,
            decorationColor: ``,
            decorationStyle: ``,
            width: 10,
            alignment: `left`,
            fontSize: 15,
            bold: false,
            text: ``,
          },
          {
            stack: [
              {
                bold: false,
                width: `auto`,
                pageBreak: ``,
                decoration: ``,
                decorationColor: ``,
                color: ``,
                fontSize: 15,
                preserveLeadingSpaces: true,
                alignment: `left`,
                decorationStyle: ``,
                margin: [0, 0, 0, -15],
                text:
                  props.items?.comment == null || props.items?.comment === ""
                    ? " "
                    : props.items?.comment,
              },
              {
                margin: [0, 0, 0, 0],
                pageBreak: ``,
                width: `auto`,
                text: `.................................................................................................................................................................................`,
                bold: false,
                alignment: `left`,
                decoration: ``,
                fontSize: 15,
                preserveLeadingSpaces: true,
                decorationStyle: ``,
                color: ``,
                decorationColor: ``,
              },
            ],
          },
        ],
      },
      {
        margin: [0, 0, 0, 0],
        width: `auto`,
        decorationColor: ``,
        decorationStyle: ``,
        alignment: `left`,
        preserveLeadingSpaces: true,
        fontSize: 15,
        bold: false,
        decoration: ``,
        color: ``,
        pageBreak: ``,
        text: `............................................................................................................................................................................................................`,
      },
      {
        fontSize: 15,
        decorationColor: ``,
        decoration: ``,
        pageBreak: ``,
        text: `............................................................................................................................................................................................................`,
        width: `auto`,
        preserveLeadingSpaces: true,
        color: ``,
        margin: [0, 0, 0, 0],
        alignment: `left`,
        decorationStyle: ``,
        bold: false,
      },
      {
        width: `auto`,
        color: ``,
        decorationStyle: ``,
        alignment: `left`,
        decorationColor: ``,
        pageBreak: ``,
        text: ` `,
        decoration: ``,
        bold: false,
        preserveLeadingSpaces: true,
        fontSize: 15,
      },
      {
        columns: [
          {
            width: `auto`,
            decorationStyle: ``,
            fontSize: 15,
            preserveLeadingSpaces: true,
            decoration: ``,
            color: ``,
            text: `ขอแจ้งให้ทราบว่าผู้ป่วยมีความเหมาะสม`,
            alignment: `left`,
            decorationColor: ``,
            bold: false,
            pageBreak: ``,
          },
          {
            alignment: `left`,
            text: ``,
            pageBreak: ``,
            decoration: ``,
            decorationColor: ``,
            width: 5,
            decorationStyle: ``,
            preserveLeadingSpaces: true,
            bold: false,
            color: ``,
            fontSize: 15,
          },
          {
            pageBreak: ``,
            color: ``,
            decoration: `underline`,
            decorationColor: ``,
            alignment: `left`,
            decorationStyle: `dotted`,
            fontSize: 15,
            text: `สำหรับการเดินทางโดยอากาศยาน`,
            preserveLeadingSpaces: true,
            width: `auto`,
            bold: `true`,
          },
          {
            color: ``,
            decorationColor: ``,
            alignment: `left`,
            preserveLeadingSpaces: true,
            pageBreak: ``,
            decorationStyle: ``,
            bold: `true`,
            decoration: ``,
            text: ` ตั้งแต่วันที่`,
            width: `auto`,
            fontSize: 15,
          },
          {
            color: ``,
            decorationColor: ``,
            preserveLeadingSpaces: true,
            alignment: `left`,
            text: ``,
            decorationStyle: ``,
            width: 2,
            fontSize: 15,
            decoration: ``,
            bold: false,
            pageBreak: ``,
          },
          {
            pageBreak: ``,
            decorationStyle: ``,
            decorationColor: ``,
            width: `auto`,
            text: `เมื่อ`,
            color: ``,
            fontSize: 15,
            preserveLeadingSpaces: true,
            alignment: `left`,
            decoration: ``,
            bold: `true`,
          },
          {
            width: 10,
            color: ``,
            decoration: ``,
            preserveLeadingSpaces: true,
            decorationColor: ``,
            fontSize: 15,
            alignment: `left`,
            bold: false,
            text: ``,
            pageBreak: ``,
            decorationStyle: ``,
          },
          {
            stack: [
              {
                color: ``,
                margin: [0, 0, 0, -15],
                preserveLeadingSpaces: true,
                bold: false,
                decoration: ``,
                alignment: `left`,
                width: `auto`,
                pageBreak: ``,
                fontSize: 15,
                text: props.items?.flightDate ? props.items?.flightDate : ` `,
                decorationColor: ``,
                decorationStyle: ``,
              },
              {
                preserveLeadingSpaces: true,
                decoration: ``,
                decorationStyle: ``,
                fontSize: 15,
                alignment: `left`,
                width: `auto`,
                margin: [0, 0, 0, 0],
                pageBreak: ``,
                text: `...................................................`,
                bold: false,
                decorationColor: ``,
                color: ``,
              },
            ],
          },
        ],
      },
      {
        decorationColor: ``,
        decoration: ``,
        pageBreak: ``,
        preserveLeadingSpaces: true,
        alignment: `left`,
        bold: false,
        fontSize: 15,
        color: ``,
        text: ` `,
        width: `auto`,
        decorationStyle: ``,
      },
      {
        columns: [
          {
            width: `auto`,
            color: ``,
            text: `ลงนามแพทย์ผู้ตรวจ `,
            decoration: ``,
            pageBreak: ``,
            decorationStyle: ``,
            decorationColor: ``,
            bold: false,
            alignment: `left`,
            fontSize: 15,
            preserveLeadingSpaces: true,
          },
          {
            alignment: `left`,
            text: ``,
            bold: false,
            decorationStyle: ``,
            fontSize: 15,
            pageBreak: ``,
            width: 30,
            preserveLeadingSpaces: true,
            decoration: ``,
            color: ``,
            decorationColor: ``,
          },
          {
            pageBreak: ``,
            text: `.............................................................`,
            width: `auto`,
            fontSize: 15,
            decorationStyle: ``,
            color: ``,
            decorationColor: ``,
            bold: false,
            decoration: ``,
            alignment: `center`,
            preserveLeadingSpaces: true,
          },
          {
            color: ``,
            pageBreak: ``,
            decoration: ``,
            text: ` `,
            fontSize: 15,
            alignment: `center`,
            bold: false,
            decorationStyle: ``,
            width: 30,
            decorationColor: ``,
            preserveLeadingSpaces: true,
          },
          {
            decoration: ``,
            pageBreak: ``,
            alignment: `left`,
            text: ` เลขใบประกอบวิชาชีพแพทย์ `,
            preserveLeadingSpaces: true,
            fontSize: 15,
            color: ``,
            width: `auto`,
            bold: false,
            decorationColor: ``,
            decorationStyle: ``,
          },
          {
            alignment: `left`,
            decorationStyle: ``,
            preserveLeadingSpaces: true,
            text: ``,
            decoration: ``,
            pageBreak: ``,
            bold: false,
            decorationColor: ``,
            width: 15,
            color: ``,
            fontSize: 15,
          },
          {
            text: props.items?.doctorCertNumber
              ? props.items?.doctorCertNumber
              : "                    ",
            decorationStyle: `dotted`,
            fontSize: 15,
            pageBreak: ``,
            bold: false,
            decorationColor: ``,
            preserveLeadingSpaces: true,
            color: ``,
            width: `auto`,
            alignment: `center`,
            decoration: `underline`,
          },
        ],
      },
      {
        columns: [
          {
            text: `		      `,
            pageBreak: ``,
            alignment: `left`,
            decorationStyle: ``,
            color: ``,
            width: 134,
            decorationColor: ``,
            fontSize: 15,
            bold: false,
            preserveLeadingSpaces: true,
            decoration: ``,
          },
          {
            preserveLeadingSpaces: true,
            decorationColor: ``,
            fontSize: 15,
            pageBreak: ``,
            decoration: ``,
            color: ``,
            decorationStyle: ``,
            width: `auto`,
            text: `(${props.items?.doctorName})`,
            alignment: `center`,
            bold: false,
          },
        ],
      },
    ],
    header: props.header,
    footer: props.footer,
    images: {
      checked: FormCheckboxImages.checked,
      unchecked: FormCheckboxImages.unchecked,
    },
  };
}
