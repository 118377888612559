import moment from "moment";
import { ContentStack, ContentTable, TDocumentDefinitions } from "pdfmake/interfaces";

import HeaderSapiensForm from "react-lib/apps/HISV3/DPO/sequence/HeaderSapiensForm";

type FormRadiologyReportProps = {
  actionLogs: { action_name: string; datetime: string; note: string; user_name: string }[];
  data?: Partial<{
    care_provider: string;
    created_utc: string;
    name_code: string;
  }> | null;
  divisionName: string;
  patient: { full_age: string; full_name: string; gender_name: string; hn: string };
  report?: Partial<{ impression: string; result: string }>;
};

interface ContentStackWithId extends Omit<ContentStack, "stack"> {
  id?: string;
  stack: ContentStack["stack"];
}

interface ITDocumentDefinitions extends TDocumentDefinitions {
  content: (ContentStackWithId | TDocumentDefinitions["content"])[];
}

const DATETIME_FORMAT = "DD/MM/YYYY HH:mm:ss";

const getDivider = (marginTop?: number): ContentTable => ({
  margin: [0, marginTop || 5.5, 0, 0.5],
  table: { widths: ["100%"], body: [[{ border: [false, true, false, false], text: "" }]] },
  layout: {
    hLineColor: () => "#cecece",
    hLineWidth: () => 0.25,
  },
});

const calculateAgeAndDOB = (ageString: string) => {
  const matches = ageString.match(/\d+/g);

  if (!matches) {
    return { birthdate: "", fullage: "" };
  }

  const [years, months, days] = matches.map(Number);

  const birthdate = moment()
    .subtract(years, "years")
    .subtract(months, "months")
    .subtract(days, "days");
  const formattedBirthdate = birthdate.format("DD/MM/YYYY");
  const formattedFullAge = `${years}Y ${months}M ${days}D`;

  return {
    birthdate: formattedBirthdate,
    fullage: formattedFullAge,
  };
};

const FormRadiologyReport = async (props: FormRadiologyReportProps): Promise<ITDocumentDefinitions> => {
  const headerSapiensForm = await HeaderSapiensForm({
    pageMargins: [25, 128.5, 25, 62.5],
    styles: {
      fieldHeader: {
        alignment: "center",
        bold: true,
        font: "Poppins",
        fontSize: 12,
        marginTop: 1.5,
      },
    },
    header: [{ style: "fieldHeader", text: "Radiology Report" }],
  });

  const { font, fontSizes, lineHeights, styles } = headerSapiensForm;

  const defaultFont = fontSizes[14.5];

  const { birthdate, fullage } = calculateAgeAndDOB(props.patient.full_age);

  const reportLog = props.actionLogs.find((item) => item.action_name === "REPORT");
  const reportDatetime = reportLog ? moment(reportLog.datetime).format(DATETIME_FORMAT) : "";

  const approveLog = props.actionLogs.find((item) => item.action_name === "APPROVE");

  const editReportLogs = props.actionLogs.filter((item) => item.action_name === "EDIT_REPORT");

  console.log("FormRadiologyReport", props, font, lineHeights);

  return {
    ...headerSapiensForm,
    defaultStyle: {
      font,
      fontSize: defaultFont,
      lineHeight: lineHeights[1.05],
    },
    pageSize: "A4",
    styles: {
      ...styles,
      fieldValue: {
        fontSize: fontSizes[13.5],
      },
      miniField: {
        fontSize: fontSizes[12.5],
      },
      subheader: {
        bold: true,
      },
    },
    content: [
      {
        columns: [
          {
            stack: [{ text: `Name: ${props.patient.full_name}` }, { text: `DOB: ${birthdate}` }],
            width: "32.5%",
          },
          {
            stack: [{ text: `HN: ${props.patient.hn}` }, { text: `Age: ${fullage}` }],
            width: "32.5%",
          },
          {
            stack: [{ text: " " }, { text: `Gender: ${props.patient.gender_name}` }],
            width: "*",
          },
        ],
      },
      getDivider(),
      { style: "subheader", text: `Order: ${props.data?.name_code || ""}` },
      {
        columns: [
          {
            stack: [
              {
                text: `Order Date: ${
                  props.data?.created_utc ? moment(props.data.created_utc).format("DD/MM/YYYY") : ""
                }`,
              },
            ],
            width: "32.5%",
          },
          {
            stack: [
              {
                text: `Order Time: ${
                  props.data?.created_utc ? moment(props.data.created_utc).format("HH:mm:ss") : ""
                }`,
              },
            ],
            width: "32.5%",
          },
          {
            stack: [{ text: `Department: ${props.divisionName}` }],
            width: "*",
          },
        ],
      },
      { text: `Request By: ${props.data?.care_provider || ""}` },
      getDivider(),
      {
        columns: [
          { text: "Report at", width: "35%" },
          { style: "fieldValue", text: reportDatetime, width: "*" },
        ],
        style: "subheader",
      },
      editReportLogs.length === 0 ? getDivider(2) : { text: "" },
      ...editReportLogs.map((item) => [
        getDivider(2),
        {
          columns: [
            { text: "Addendum Report at", width: "35%" },
            {
              style: "fieldValue",
              text: moment(item.datetime).format(DATETIME_FORMAT),
              width: "*",
            },
          ],
          style: "subheader",
        },
        {
          marginLeft: 2.5,
          marginTop: -1.5,
          text: item.note,
        },
      ]),
      { bold: true, margin: [0, 5, 0, 1.5], text: "FINDING:" },
      {
        lineHeight: lineHeights[0.95],
        marginLeft: 2.5,
        stack: (props.report?.result || "").split("\n"),
      },
      {
        id: "impression",
        stack: [
          { bold: true, margin: [0, 10, 0, 0.5], text: "IMPRESSION:" },
          {
            marginLeft: 2.5,
            stack: (props.report?.impression || "").split("\n"),
          },
        ],
      },
    ],
    footer: (currentPage: number, pageCount: number) => ({
      lineHeight: 1,
      margin: [25, approveLog ? 0 : defaultFont + 4, 25, 0],
      stack: [
        {
          columns: [
            [
              {
                alignment: "left",
                style: "fieldValue",
                text: [{ bold: true, text: "Reported by: " }, { text: reportLog?.user_name || "" }],
              },
              approveLog
                ? {
                    alignment: "left",
                    style: "fieldValue",
                    text: [{ bold: true, text: "Approved by: " }, { text: approveLog.user_name }],
                  }
                : { text: "" },
            ],
          ],
        },
        {
          columns: [
            {
              alignment: "left",
              style: "miniField",
              text: `วันที่พิมพ์/Printed date: ${moment().format("DD/MM/YYYY, HH:mm")}`,
            },
            {
              alignment: "right",
              style: "miniField",
              text: `Page ${currentPage} of ${pageCount}`,
            },
          ],
          marginTop: 5,
        },
      ],
    }),
    pageBreakBefore: (currentNode) => {
      const { id, pageNumbers, pages } = currentNode;

      if (id === "impression") {
        return pageNumbers.length !== 1 || pageNumbers[0] !== pages;
      }

      return false;
    },
  };
};

export default FormRadiologyReport;
