import WasmController from "react-lib/frameworks/WasmController";
import ChoiceView from "issara-sdk/apis/ChoiceView_core";
import TeamDetail from "issara-sdk/apis/TeamDetail_apps_ORM";
import OperatingTreatmentList from "issara-sdk/apis/OperatingTreatmentList_apps_ORM";
import icd9cmKeyUp from "issara-sdk/apis/icd9cmKeyUp_core";
import icd10KeyUp from "issara-sdk/apis/icd10KeyUp_core";
import OperatingDetailDetail from "issara-sdk/apis/OperatingDetailDetail_apps_ORM";
import OperativeNoteTemplateList from "issara-sdk/apis/OperativeNoteTemplateList_apps_ORM";
import OperativeNoteTemplateDetail from "issara-sdk/apis/OperativeNoteTemplateDetail_apps_ORM";
import OperativeImageList from "issara-sdk/apis/OperativeImageList_apps_ORM";
import OperativeImageDetail from "issara-sdk/apis/OperativeImageDetail_apps_ORM";
import TeamPrintDetail from "issara-sdk/apis/TeamPrintDetail_apps_ORM";

export type State = {
  // CommonInterface
  errorMessage?: any;
  successMessage?: any;
  buttonLoadCheck?: any;

  // sequence
  ORPostOperationSequence?: {
    swab_status?: any;
    type_wound?: any;
    type?: any;
    teamDetail?: any;
    searchPostResult?: any;
    searchPostProcedures?: any;
    searchTem?: any;
    operatingDetail?: any;
    uploadImg?: any;
  } | null;
};

const ICDAPI: Record<string, any> = {
  ICD10: icd10KeyUp,
  ICD9CM: icd9cmKeyUp,
};

export const StateInitial: State = {
  // sequence
  ORPostOperationSequence: null,
};

export type Event = { message: "RunSequence"; params: {} };

export type Data = {
  division?: number;
  device?: number;
};

export const DataInitial = {};

type Handler = (
  controller: WasmController<State, Event, Data>,
  params?: any
) => any;

export const Start: Handler = async (controller, params) => {
  const state = controller.getState();

  if (params.action === "getData") {
    const operatingDetail = await OperatingDetailDetail.retrieve({
      apiToken: controller.apiToken,
      pk: params.orDetailId,
      extra: {
        device: controller.data.device,
        division: controller.data.division,
      },
    });

    // const teamDetail = await TeamDetail.retrieve({
    //   apiToken: controller.apiToken,
    //   pk: params.teamsId,
    //   extra: {
    //     device: controller.data.device,
    //     division: controller.data.division,
    //   },
    // });

    const type = await ChoiceView.get({
      apiToken: controller.apiToken,
      params: {
        model: "core.Procedure",
        field: "type",
        name_as_id: "true",
      },
      extra: {
        device: controller.data.device,
        division: controller.data.division,
      },
    });

    const swab_status = await ChoiceView.get({
      apiToken: controller.apiToken,
      params: {
        model: "ORM.Team",
        field: "swab_instrument_status",
        name_as_id: "true",
      },
      extra: {
        device: controller.data.device,
        division: controller.data.division,
      },
    });

    const type_wound = await ChoiceView.get({
      apiToken: controller.apiToken,
      params: {
        model: "ORM.Team",
        field: "type_of_wound",
        name_as_id: "true",
      },
      extra: {
        device: controller.data.device,
        division: controller.data.division,
      },
    });

    controller.setState({
      ORPostOperationSequence: {
        ...state.ORPostOperationSequence,
        swab_status: swab_status[0],
        type_wound: type_wound[0],
        type: type[0],
        teamDetail: operatingDetail[0]?.surgery_teams?.[0],
        operatingDetail: operatingDetail[0],
      },
    });
  } else if (params.action === "saveData") {
    controller.setState({
      buttonLoadCheck: {
        ...state.buttonLoadCheck,
        [params.buttonLoadKey]: "LOADING",
      },
    });

    let pre_principal_diagnosis = [...params.data.pre_principal_diagnosis];
    pre_principal_diagnosis[0].detail = params.pre_principal_diagnosis?.detail || "";

    const data = {
      anesthesiologist_summary: params.data.anesthesiologist_summary,
      anesthetist_summary: params.data.anesthetist_summary,
      assistant_surgeons_summary: params.data.assistant_surgeons_summary,
      blood_loss: params?.blood_loss,
      circulating_nurse_summary: params.data.circulating_nurse_summary,
      operating_detail: params.data.operating_detail,
      operative_note: params.operative_note,
      operative_note_id: params.data.operative_note_id,
      post_implant: params.post_implant,
      post_operating_order_item: params.post_operating_order_item,
      post_principal_diagnosis: params.post_principal_diagnosis,
      post_secondary_diagnosis: params.post_secondary_diagnosis,
      pre_principal_diagnosis: pre_principal_diagnosis,
      pre_secondary_diagnosis: params.data.pre_secondary_diagnosis,
      specific_concern: params.specific_concern,
      specimen: params.specimen,
      tourniquet: params.tourniquet,
      type_of_wound: params.type_of_wound,
      swab_instrument_status: params.swab_instrument_status,
      swab_instrument_status_label: params.swab_instrument_status_label,
      chief_surgeon: params.chief_surgeon,
    };
    const teamDetail = await TeamDetail.update({
      apiToken: controller.apiToken,
      pk: params.teamsId,
      data: data,
      extra: {
        device: controller.data.device,
        division: controller.data.division,
      },
    });

    if (teamDetail[0]) {
      controller.setState({
        buttonLoadCheck: {
          ...state.buttonLoadCheck,
          [params.buttonLoadKey]: "SUCCESS",
        },
        ORPostOperationSequence: {
          ...state.ORPostOperationSequence,
          teamDetail: teamDetail[0],
        },
      });
    } else {
      controller.setState({
        errorMessage: { ...state.errorMessage, [params.card]: teamDetail[1] },
        buttonLoadCheck: {
          ...state.buttonLoadCheck,
          [params.buttonLoadKey]: "ERROR",
        },
      });
    }
  } else if (params.action === "printData") {
    controller.setState({
      buttonLoadCheck: {
        ...state.buttonLoadCheck,
        [params.buttonLoadKey]: "LOADING",
      },
    });

    const teamPrint = await TeamPrintDetail.retrieve({
      apiToken: controller.apiToken,
      pk: params.teamsId,
      extra: {
        device: controller.data.device,
        division: controller.data.division,
      },
    });

    console.log("test", teamPrint);

    if (teamPrint[0]) {
      var pdfWindow = window.open("_blank");
      pdfWindow.document.write(
        "<iframe width='100%' height='100%' src='data:application/pdf;base64, " +
          teamPrint[0]?.pdf_b64data +
          "'></iframe>"
      );

      controller.setState({
        buttonLoadCheck: {
          ...state.buttonLoadCheck,
          [params.buttonLoadKey]: "SUCCESS",
        },
      });
    } else {
      controller.setState({
        errorMessage: { ...state.errorMessage, [params.card]: teamPrint[1] },
        buttonLoadCheck: {
          ...state.buttonLoadCheck,
          [params.buttonLoadKey]: "ERROR",
        },
      });
    }
  } else if (params.action === "searchPostTreatment") {
    const search = await OperatingTreatmentList.list({
      apiToken: controller.apiToken,
      params: {
        search: params.search,
      },
      extra: {
        device: controller.data.device,
        division: controller.data.division,
      },
    });

    controller.setState({
      ORPostOperationSequence: {
        ...state.ORPostOperationSequence,
        searchPostResult: {
          data: search[0],
          index: params.index,
        },
      },
    });
  } else if (params.action === "searchICD") {
    var method = params.method;
    if (params.method === "medterm") {
      const search = await ICDAPI[params.icdType].post({
        method: method,
        apiToken: controller.apiToken,
        data: {
          medterm: params.search,
        },
        extra: {
          device: controller.data.device,
          division: controller.data.division,
        },
      });

      controller.setState({
        ORPostOperationSequence: {
          ...state.ORPostOperationSequence,
          searchPostProcedures: {
            data: search[0],
            index: params.index,
          },
        },
      });
    } else if (params.method === "icdterm") {
      const search = await ICDAPI[params.icdType].post({
        method: method,
        apiToken: controller.apiToken,
        data: {
          icdterm: params.search,
        },
        extra: {
          device: controller.data.device,
          division: controller.data.division,
        },
      });

      controller.setState({
        ORPostOperationSequence: {
          ...state.ORPostOperationSequence,
          searchPostProcedures: {
            data: search[0],
            index: params.index,
          },
        },
      });
    } else if (params.method === "icdcode") {
      const search = await ICDAPI[params.icdType].post({
        method: method,
        apiToken: controller.apiToken,
        data: {
          icdcode: params.search,
        },
        extra: {
          device: controller.data.device,
          division: controller.data.division,
        },
      });

      controller.setState({
        ORPostOperationSequence: {
          ...state.ORPostOperationSequence,
          searchPostProcedures: {
            data: search[0],
            index: params.index,
          },
        },
      });
    }
  } else if (params.action === "getTemData") {
    const temList = await OperativeNoteTemplateList.list({
      apiToken: controller.apiToken,
      params: {
        name: params.search,
      },
      extra: {
        device: controller.data.device,
        division: controller.data.division,
      },
    });

    controller.setState({
      ORPostOperationSequence: {
        ...state.ORPostOperationSequence,
        searchTem: temList[0],
      },
    });
  } else if (params.action === "saveTemData") {
    const temCreate = await OperativeNoteTemplateList.create({
      apiToken: controller.apiToken,
      data: {
        name: params.name,
        note: params.note,
      },
      extra: {
        device: controller.data.device,
        division: controller.data.division,
      },
    });

    Start(controller, { action: "getTemData" });
  } else if (params.action === "updateTemData") {
    const temUpdate = await OperativeNoteTemplateDetail.update({
      pk: params.pk,
      apiToken: controller.apiToken,
      data: {
        name: params.name,
        note: params.note,
      },
      extra: {
        device: controller.data.device,
        division: controller.data.division,
      },
    });

    Start(controller, { action: "getTemData" });
  } else if (params.action === "deleteTemData") {
    const temDelete = await OperativeNoteTemplateDetail.delete({
      pk: params.pk,
      apiToken: controller.apiToken,
      extra: {
        device: controller.data.device,
        division: controller.data.division,
      },
    });

    Start(controller, { action: "getTemData" });
  } else if (params.action === "getUploadImg") {
    const imgList = await OperativeImageList.list({
      operative_note: params.operative_note,
      apiToken: controller.apiToken,
      extra: {
        device: controller.data.device,
        division: controller.data.division,
      },
    });
    
    if (imgList[0]?.items?.lenght === 0) {
      controller.setState({
        ORPostOperationSequence: {
          ...state.ORPostOperationSequence,
          uploadImg: [],
        },
      });
    } else {
      controller.setState({
        ORPostOperationSequence: {
          ...state.ORPostOperationSequence,
          uploadImg: imgList[0],
        },
      });
    }
  } else if (params.action === "saveUploadImg") {
    const imgCreate = await OperativeImageList.create({
      operative_note: params.operative_note,
      apiToken: controller.apiToken,
      data: {
        description: params.description,
        image: params.image,
        is_active: true,
        operative_note: params.operative_note,
      },
      extra: {
        device: controller.data.device,
        division: controller.data.division,
      },
    });

    Start(controller, {
      action: "getUploadImg",
      operative_note: params.operative_note,
    });
  } else if (params.action === "updateUploadImg") {
    const imgUpdate = await OperativeImageDetail.update({
      pk: params.pk,
      apiToken: controller.apiToken,
      data: {
        description: params.description,
        image: params.image,
        is_active: params.is_active,
        operative_note: params.operative_note,
      },
      extra: {
        device: controller.data.device,
        division: controller.data.division,
      },
    });
    Start(controller, {
      action: "getUploadImg",
      operative_note: params.operative_note,
    });
  }
};
