import React, { useState, SyntheticEvent } from "react";
import { Dimmer, Loader } from "semantic-ui-react";
import { FormControlLabel, styled, Switch } from "@mui/material";

// Interface
import {
  RunSequence,
  AggStockSerializer,
  BUTTON_ACTIONS,
} from "./sequence/StockManagement";

// Types
type DisplayCountingProps = {
  // seq
  runSequence: RunSequence;
  // data
  data: AggStockSerializer;
  card: string;
  // config
  disabled?: boolean;
};

// Const
const COLORS = {
  blue: "rgb(2 52 150)",
};

const Android12Switch = styled(Switch)(({ theme }) => ({
  padding: 8,
  "& .MuiSwitch-track": {
    border: `3px solid ${COLORS.blue}`,
    opacity: 1,
    borderRadius: 22 / 2,
    background: "white",
    "&:before, &:after": {
      content: '""',
      position: "absolute",
      top: "50%",
      transform: "translateY(-50%)",
      width: 14,
      height: 14,
    },
  },
  "& .MuiSwitch-switchBase": {
    color: `${COLORS.blue} !important`,
    top: 1,
    left: 2,
  },
  "& .MuiSwitch-switchBase.Mui-disabled + .MuiSwitch-track": {
    opacity: "0.12 !important",
  },
  "& .MuiSwitch-colorSecondary": {
    "&.Mui-checked + .MuiSwitch-track": {
      background: COLORS.blue,
      opacity: 1,
    },
    "&.Mui-checked": {
      color: "white",
      top: 1,
      left: 1,
    },
  },
  "& .Mui-checked+.MuiSwitch-track": {
    backgroundColor: `${COLORS.blue} !important`,
    opacity: "1 !important",
  },
  "& .Mui-checked": {
    color: "white !important",
    top: 1,
    left: 1,
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "none",
    width: 14,
    height: 14,
    margin: 2,
  },
}));

const CountingSwitch = (props: DisplayCountingProps) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleCounting = (e: SyntheticEvent) => {
    handlePrevent(e);

    props.runSequence({
      sequence: "StockManagement",
      action: "COUNTING",
      stock: props.data,
      card: props.card,
      errorKey: props.card,
      btnAction: BUTTON_ACTIONS.COUNTING,
      onLoading: setIsLoading,
    });
  };

  const handlePrevent = (e: SyntheticEvent) => {
    e.preventDefault();
    e.stopPropagation();
  };

  return props.data.storage.id ? (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
      }}
      // onClick={handlePrevent}
    >
      <div style={{ position: "relative" }}>
        <FormControlLabel
          control={
            <Android12Switch
              checked={!!props.data.in_reconcile}
              disabled={props.disabled}
              onChange={handleCounting}
            />
          }
          label=""
          style={{ margin: "0 auto", transform: "scale(0.8)" }}
        />
        <Dimmer
          active={isLoading}
          size="mini"
          inverted
          style={{ background: "transparent", paddingRight: 0 }}
        >
          <Loader
            size="mini"
            inline={true}
            style={{
              marginLeft: props.data.in_reconcile ? "0.15rem" : "-1.55rem",
            }}
          ></Loader>
        </Dimmer>
      </div>
    </div>
  ) : null;
};

export default React.memo(CountingSwitch);
