import React, { useEffect, SyntheticEvent, useState } from "react";

import CardDischargeSummaryNurseUX from "./CardDischargeSummaryNurseUX";
import CardDischargePlanningUX from "./CardDischargePlanningUX";
import CardDisChargeTeachingUX from "./CardDischargeTeachingUX";
import CardSummaryPlanningUX from "./CardSummaryPlanningUX";
import SubDischargePlan from "react-lib/apps/HISV3/PTM/SubDischargePlan";
import {
  Button,
  Checkbox,
  Dimmer,
  FormField,
  FormGroup,
  Grid,
  Input,
  Loader,
} from "semantic-ui-react";
import ButtonLoadCheck from "react-lib/appcon/common/ButtonLoadCheck";

const CARD_DISCHARGE_SUMMARY_NURSE = "CardDischargeSummaryNurse";

const CardDischargeSummaryNurse = (props: any) => {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    props.runSequence({ sequence: "DischargeNurse", action: "Formdata" });
    props.runSequence({
      sequence: "DischargeNurse",
      action: "GetDischargePlanning",
    });
  }, []);

  const handleChangeDataPlanning = (_event: any, data: any) => {
    if (data.type === "checkbox") {
      props.setProp(`ClinicalTermPlanning.${data.name}`, data.checked);
    } else {
      props.setProp(`ClinicalTermPlanning.${data.name}`, data.value);
    }
  };

  const handleChangeValueTeach = (key: string) => (e: any, v: any) => {
    props.setProp(`ClinicalTermTeaching.${key}`, v.value);
  };

  const handleChangeValueNursing = (key: string) => (e: any, v: any) => {
    props.setProp(`ClinicalTermNursing.${key}`, v.value);
  };
  const handleCheckValueNursing = (key: string) => (e: any, v: any) => {
    props.setProp(`ClinicalTermNursing.${key}`, v.checked);
  };

  const handleAddOptions = (e: SyntheticEvent, v: any) => {
    if (v.name === "relative") {
      props.setProp("typeOptionsRelative", [
        ...props.typeOptionsRelative,
        { key: v.value, text: v.value, value: v.value },
      ]);
    }
    if (v.name === "knowledge") {
      props.setProp("typeOptionKnowledge", [
        ...props.typeOptionKnowledge,
        { key: v.value, text: v.value, value: v.value },
      ]);
    }
    if (v.name === "method") {
      props.setProp("typeOptionsMethod", [
        ...props.typeOptionsMethod,
        { key: v.value, text: v.value, value: v.value },
      ]);
    }
  };

  const handleAddDiscNursing3 = () => {
    props.setProp("ClinicalTermNursing.disc_nursing3.items", [
      ...props.ClinicalTermNursing?.disc_nursing3?.items,
      { problem: "", is_advise: false, is_forward: false, forward: "" },
    ]);
  };

  const handleDeleteDiscNursing3 = (index: number) => {
    let result = [...props.ClinicalTermNursing?.disc_nursing3?.items];
    result.splice(index, 1);
    props.setProp("ClinicalTermNursing.disc_nursing3.items", result);
  };

  useEffect(() => {
    if (props.ClinicalTermPlanning && props.ClinicalTermTeaching && props.ClinicalTermNursing) {
      setIsLoading(false);
    }
  }, [props.ClinicalTermPlanning, props.ClinicalTermTeaching, props.ClinicalTermNursing]);

  return (
    <div id="CardDischargeSummary" style={{ position: "relative", paddingBottom: "15rem" }}>
      <div>
        <Dimmer active={isLoading} inverted>
          <Loader inverted>{"loading"}</Loader>
        </Dimmer>
      </div>

      {!isLoading && (
        <CardDischargeSummaryNurseUX
          OnSave={() => {
            props.runSequence({ sequence: "DischargeNurse", action: "Save" });
          }}
          DischargePlanning={
            <CardDischargePlanningUX
              ClinicalTermPlanning={props.ClinicalTermPlanning}
              handleChangeDataPlanning={handleChangeDataPlanning}
              showStatus={props?.FormDataDischargePlanning?.form_data_status_name === "Draft"}
              subDischargePlan={
                <SubDischargePlan
                  setProp={props.setProp}
                  clinicalTermPlanning={props.ClinicalTermPlanning}
                />
              }
            />
          }
          onDischargeSummaryPrint={() => {
            props.runSequence({
              sequence: "DischargeNurse",
              action: "printDischargeSummary",
              cardName: CARD_DISCHARGE_SUMMARY_NURSE,
            });
          }}
          printLoading={props.buttonLoadCheck?.[CARD_DISCHARGE_SUMMARY_NURSE] === "LOADING"}
          DischargeTeaching={
            <CardDisChargeTeachingUX
              ClinicalTermTeaching={props.ClinicalTermTeaching}
              handleChangeValue={handleChangeValueTeach}
              onAddOptions={handleAddOptions}
              showStatus={props?.FormDataDischargeTeaching?.form_data_status_name === "Draft"}
              options={props.typeOptionsRelative}
              optionsmethod={props.typeOptionsMethod}
              optionstype={props.typeOptionKnowledge}
            />
          }
          DischargeSummaryPlanning={
            <CardSummaryPlanningUX
              ClinicalTermNursing={props.ClinicalTermNursing}
              dischargePlanning={props.dischargePlanning}
              handleChangeValue={handleChangeValueNursing}
              handleCheckValue={handleCheckValueNursing}
              showStatus={props?.FormDataDischargeNursing?.form_data_status_name === "Draft"}
              discNursing3={props.ClinicalTermNursing?.disc_nursing3?.items?.map(
                (item: any, index: number) => {
                  return (
                    <FormGroup>
                      <FormField inline={true} width={3}>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <div>{index + 1}</div>
                          <div style={{ width: "100%" }}>
                            <Input
                              style={{ width: "95%", paddingLeft: "10px" }}
                              type="text"
                              fluid={true}
                              value={item?.problem || ""}
                              onChange={handleChangeValueNursing(
                                `disc_nursing3.items.${index}.problem`
                              )}
                            />
                          </div>
                        </div>
                      </FormField>
                      <FormField inline={true} width={10}>
                        <div
                          style={{ display: "flex", whiteSpace: "nowrap", alignItems: "center" }}
                        >
                          ความช่วยเหลือ
                          <Checkbox
                            style={{ paddingLeft: "10px" }}
                            label="ให้คำแนะนำ"
                            checked={item?.is_advise}
                            onChange={handleCheckValueNursing(
                              `disc_nursing3.items.${index}.is_advise`
                            )}
                          />
                          <Checkbox
                            style={{
                              padding: "0 10px",
                              whiteSpace: "nowrap",
                              alignItems: "center",
                            }}
                            label="ส่งต่อ"
                            checked={item?.is_forward}
                            onChange={handleCheckValueNursing(
                              `disc_nursing3.items.${index}.is_forward`
                            )}
                          />
                          <Input
                            style={{ width: "55%", whiteSpace: "nowrap", alignItems: "center" }}
                            type="text"
                            fluid={true}
                            value={item?.forward || ""}
                            onChange={handleChangeValueNursing(
                              `disc_nursing3.items.${index}.forward`
                            )}
                          />
                        </div>
                      </FormField>
                      <FormField style={{ flex: "1" }}></FormField>
                      <FormField inline={true} width={1}>
                        <Button
                          color="red"
                          icon="minus"
                          onClick={() => handleDeleteDiscNursing3(index)}
                        />
                      </FormField>
                    </FormGroup>
                  );
                }
              )}
              onAddDiscNursing3={handleAddDiscNursing3}
            />
          }
          buttonSave={
            <ButtonLoadCheck
              // function
              setProp={props.setProp}
              onClick={() => {
                props.runSequence({
                  sequence: "DischargeNurse",
                  action: "Save",
                  card: CARD_DISCHARGE_SUMMARY_NURSE,
                });
              }}
              // data
              paramKey={`${CARD_DISCHARGE_SUMMARY_NURSE}_Save`}
              buttonLoadCheck={props.buttonLoadCheck?.[`${CARD_DISCHARGE_SUMMARY_NURSE}_Save`]}
              // config
              color={"teal"}
              title={"Save"}
            />
          }
        />
      )}
    </div>
  );
};

export default CardDischargeSummaryNurse;
