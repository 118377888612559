import WasmController from "react-lib/frameworks/WasmController";
import { State as MainState } from "../../../../../HIS/MainHISInterface";
// apis
import Icd10List from "issara-sdk/apis/Icd10List_core";
import FormDataList from "issara-sdk/apis/FormDataList_apps_PTM";
import DoctorList from "issara-sdk/apis/DoctorList_core";
import DoctorDetail from "issara-sdk/apis/DoctorDetail_core";
import DiagnosisMedicalRecordDetail from "issara-sdk/apis/DiagnosisMedicalRecordDetail_apps_DPO";
import MeasurementList from "issara-sdk/apis/MeasurementList_apps_PTM";
import AdmissionList from "issara-sdk/apis/AdmissionList_apps_DPI";
import FormDataActionLogAllList from "issara-sdk/apis/FormDataActionLogAllList_apps_PTM";
import FormDataDetail from "issara-sdk/apis/FormDataDetail_apps_PTM";
import ConstanceView from "issara-sdk/apis/ConstanceView_core";
import PatientEMRList from "issara-sdk/apis/PatientEMRList_apps_DPO";
import DoctorCertificatesTypeList from "issara-sdk/apis/DoctorCertificatesTypeList_apps_DPO";
// Utils
import moment from "moment";
import getPdfMake from "react-lib/appcon/common/pdfMake";
import { beToAd, formatDate } from "react-lib/utils/dateUtils";
// PDF TH
import FormDeathCertificate from "../pdfForm/FormDeathCertificate";
import { default as FormFiveDiseasesFoundCertificate } from "../pdfForm/FormFiveDiseasesFoundCertificate";
import { default as FormFiveDiseasesFreeCertificate } from "../pdfForm/FormFiveDiseasesFreeCertificate";
import { default as FormExamAbsenceCertificate } from "../pdfForm/FormExamAbsenceCertificate";
import { default as FormDrivingCertificate } from "../pdfForm/FormDrivingCertificate";
import { default as FormQuarantineCertificate } from "../pdfForm/FormQuarantineCertificate";
import { default as FormCovidCertificate } from "../pdfForm/FormCovidCertificate";
import { default as FormTravelCertificate } from "../pdfForm/FormTravelCertificate";
import { default as FormVisaExtensionCertificate } from "../pdfForm/FormVisaExtensionCertificate";
// PDF EN
import FormDeathCertificateEN from "../pdfForm/FormDeathCertificateEN";
import { default as FormFiveDiseasesFoundCertificateEN } from "../pdfForm/FormFiveDiseasesFoundCertificateEN";
import { default as FormFiveDiseasesFreeCertificateEN } from "../pdfForm/FormFiveDiseasesFreeCertificateEN";
import { default as FormExamAbsenceCertificateEN } from "../pdfForm/FormExamAbsenceCertificateEN";
import { default as FormDrivingCertificateEN } from "../pdfForm/FormDrivingCertificateEN";
import { default as FormQuarantineCertificateEN } from "../pdfForm/FormQuarantineCertificateEN";
import { default as FormCovidCertificateEN } from "../pdfForm/FormCovidCertficateEN";
import { default as FormTravelCertificateEN } from "../pdfForm/FormTravelCertificateEN";
import { default as FormVisaExtensionCertificateEN } from "../pdfForm/FormVisaExtensionCertificateEN";
// Sequence
import { AddHeaderMedCert, AddHeaderMedCertEN } from "./AddHeaderSapienForm";
// Config
import CONFIG from "config/config";

export type State = {
  // Sequence
  ButtonLoadCheck?: any;
  LoadingStatus?: any;
  masterOptions?: any;
  errorMessage?: any;
  DoctorCertificateSequence?: {
    sequenceIndex?: any;

    // CardMedCertificateReport
    // selectReportType?: number;
    searchReportLoading?: boolean;
    isDoctor?: boolean;
    reportOption?: any;
    // languageOption?: any;
    selectedReport?: any;
    originalReportList?: any;
    reportList?: any;
    reportTypeList?: any[];
    formCodeList?: any[];
    filterReport?: {
      startDate?: string;
      endDate?: string;
      doctorName?: any;
      reportLanguage?: string;
    };
    // CardDeathCertificate
    deathReport?: {
      patientInfo?: any;
      createdDate?: string;
      createdDateForm?: string;
      icd10Options?: any;
      deathDate?: string;
      deathLocate?: string;
      deathLocateEn?: string;
      icd10TypeA?: any;
      amountA?: string;
      amountTypeA?: string;
      icd10TypeB?: any;
      amountB?: string;
      amountTypeB?: string;
      icd10TypeC?: any;
      amountC?: string;
      amountTypeC?: string;
      icd10TypeD?: any;
      amountD?: string;
      amountTypeD?: string;
      condition1Type?: any;
      amountCondition1?: string;
      amountTypeCondition1?: string;
      condition2Type?: any;
      amountCondition2?: string;
      amountTypeCondition2?: string;
      deathManner?: string;
      doctorName?: string;
      doctorNameEn?: string;
      doctorCertNumber?: string;
      reportLanguage?: string;
      reportType?: string;
      reportDuplicate?: string;
      printCount?: number;
    };
    // CardFiveDiseasesCertificate
    fiveReport?: {
      patientInfo?: any;
      createdDate?: string;
      createdDateForm?: string;
      examDate?: string;
      isFoundDisease?: string;
      isFirstDisease?: boolean;
      isSecondDisease?: boolean;
      isThirdDisease?: boolean;
      isFourthDisease?: boolean;
      isFifthDisease?: boolean;
      otherNote?: string;
      doctorName?: string;
      doctorNameEn?: string;
      doctorCertNumber?: string;
      reportLanguage?: string;
      reportType?: string;
      reportDuplicate?: string;
      printCount?: number;
      // Logic Value
      ageYear?: number;
      ageMonth?: number;
      ageDay?: number;
    };
    // CardExamAbsenceCertificate
    examAbsenceReport?: {
      patientInfo?: any;
      createdDate?: string;
      createdDateForm?: string;
      chiefComplaint?: string;
      diagnosis?: string;
      comment?: string;
      examDate?: string;
      shouldOpdAbsence?: boolean;
      opdStartDate?: string;
      opdEndDate?: string;
      admitStartDate?: string;
      admitEndDate?: string;
      shouldIpdAbsence?: boolean;
      ipdStartDate?: string;
      ipdEndDate?: string;
      doctorName?: string;
      doctorNameEn?: string;
      doctorCertNumber?: string;
      reportLanguage?: string;
      reportType?: string;
      reportDuplicate?: string;
      printCount?: number;
      // Logic Value
      ageYear?: number;
      ageMonth?: number;
      ageDay?: number;
    };
    // CardDrivingCertificate
    drivingReport?: {
      patientInfo?: any;
      patientWeight?: any;
      patientHeight?: any;
      patientPressure?: any;
      patientPulse?: any;
      createdDate?: string;
      createdDateForm?: string;
      examDate?: string;
      hospitalName?: string;
      hospitalNameEn?: string;
      hospitalAddress?: string;
      isFirstOption?: string;
      explainFirst?: string;
      isSecondOption?: string;
      explainSecond?: string;
      isThirdOption?: string;
      explainThird?: string;
      isFourthOption?: string;
      explainFourth?: string;
      isFifthOption?: string;
      explainFifth?: string;
      summaryCheck?: string;
      explainSummary?: string;
      comment?: string;
      doctorName?: string;
      doctorNameEn?: string;
      doctorCertNumber?: string;
      reportLanguage?: string;
      reportType?: string;
      reportDuplicate?: string;
      printCount?: number;
    };
    // CardQuarantineCertificate
    quarantineReport?: {
      patientInfo?: any;
      createdDate?: string;
      createdDateForm?: string;
      chiefComplaint?: string;
      detectDate?: string;
      comment?: string;
      shouldAbsence?: boolean;
      absenceDuration?: string;
      absenceStartDate?: string;
      absenceEndDate?: string;
      doctorName?: string;
      doctorNameEn?: string;
      doctorCertNumber?: string;
      reportLanguage?: string;
      reportType?: string;
      reportDuplicate?: string;
      printCount?: number;
      // Logic Value
      ageYear?: number;
      ageMonth?: number;
      ageDay?: number;
    };
    // CardCovidCertificate
    covidReport?: {
      patientInfo?: any;
      createdDate?: string;
      createdDateForm?: string;
      examDate?: string;
      examTime?: string;
      testedResult?: string;
      testedMethod?: string;
      swabDate?: string;
      getAdvised?: boolean;
      comment?: string;
      shouldAbsence?: boolean;
      absenceDuration?: string;
      absenceStartDate?: string;
      absenceEndDate?: string;
      doctorName?: string;
      doctorNameEn?: string;
      doctorCertNumber?: string;
      reportLanguage?: string;
      reportType?: string;
      reportDuplicate?: string;
      printCount?: number;
      // Logic Value
      ageYear?: number;
      ageMonth?: number;
      ageDay?: number;
    };
    // CardTravelCertificate
    travelReport?: {
      patientInfo?: any;
      createdDate?: string;
      createdDateForm?: string;
      examDate?: string;
      visitType?: string;
      flightDate?: string;
      diagnosis?: string;
      travelRecommend?: string;
      specialRequire?: any;
      comment?: string;
      doctorName?: string;
      doctorNameEn?: string;
      doctorCertNumber?: string;
      reportLanguage?: string;
      reportType?: string;
      reportDuplicate?: string;
      printCount?: number;
      // Logic Value
      ageYear?: number;
      ageMonth?: number;
      ageDay?: number;
    };
    // CardVisaExtensionCertificate
    visaExtReport?: {
      patientInfo?: any;
      doctorEncounter?: any;
      createdDate?: string;
      createdDateForm?: string;
      patientNationality?: string;
      patientPassport?: string;
      visitType?: string;
      chiefComplaint?: string;
      diagnosis?: string;
      isDrug?: boolean; // ให้ยา
      isPhysicalTherapy?: boolean; // กายภาพบำบัด
      isXray?: boolean; // Xray
      isProcedure?: boolean; // หัตถการ
      isPathology?: boolean; // พยาธิวิทยา
      isSurgery?: boolean; // ผ่าตัด
      isBandage?: boolean; // ทำแผล
      isOther?: boolean; // อื่น ๆ
      isProcedureNote?: string;
      isSurgeryNote?: string;
      isOtherNote?: string;
      treatmentDuration?: string;
      treatmentDoctor?: string;
      doctorName?: string;
      doctorNameEn?: string;
      doctorCertNumber?: string;
      reportLanguage?: string;
      reportType?: string;
      reportDuplicate?: string;
      printCount?: number;
      // Logic Value
      ageYear?: number;
      ageMonth?: number;
      ageDay?: number;
    };
  };
};

export const StateInitial: State = {
  // Sequence
  DoctorCertificateSequence: {
    sequenceIndex: null,
  },
};

export type Event =
  | { message: "RunSequence"; params: {} }
  | { message: "GetMasterData"; params: {} };

export type Data = {
  division?: number;
};

type Picked = Partial<
  Pick<
    MainState,
    | "buttonLoadCheck"
    | "errorMessage"
    | "successMessage"
    | "masterOptions"
    | "django"
    | "selectedEncounter"
    | "selectedPatient"
    | "selectedEmr"
    | "vitalSignsInfo"
  >
>;

type Handler = (controller: WasmController<State & Picked, Event, Data>, params?: any) => any;

export const DataInitial = {};

const disableHeaderForm = CONFIG.DISABLE_HEADER_MEDCERT; // true = disable, false = enable

// Current Date
// const currentDate = new Date().toISOString().split("T")[0];
const currentDate = moment().format("YYYY-MM-DD, HH:mm");
const christYear = moment(currentDate).format("YYYY");
const buddhistYear = (parseInt(christYear) + 543).toString();
const formattedDate = moment(currentDate).format("DD/MM/YYYY".replace("YYYY", buddhistYear));
const createdDate = moment(currentDate).format("DD/MM/YYYY, HH:mm".replace("YYYY", buddhistYear));
const createDateForm = moment(currentDate).format("DD/MM/YYYY, HH:mm");

// Age Calculate (CurrentDate - BirthDate) // birth and current must be formatted by using beToAd() before.
const getAge = (birthDate: string, currentDate: string) => {
  const diffDate = moment(currentDate).diff(birthDate);
  var yearDuration = moment.duration(diffDate).years();
  var monthDuration = moment.duration(diffDate).months();
  var dayDuration = moment.duration(diffDate).days();
  // [0] = Year, [1] = Month, [2] = Day
  return [yearDuration, monthDuration, dayDuration];
};

// Get Day, Month, Year from Date Format
const getSliceDate = (date: any) => {
  var getDay = date.slice(0, 2);
  var getMonth = date.slice(3, 5);
  var getMonthName = moment(getMonth, "MM").locale("th").format("MMMM").toString();
  var getYear = date.slice(6, 10);
  // [0] = Day, [1] = Month, [2] = MonthName, [3] = Year
  return [getDay, getMonth, getMonthName, getYear];
};

// Identification Number Format X-XXXX-XXXXX-XX-X
const formatIdentification = (ident: string) => {
  if (ident != null && ident.length > 12) {
    return [
      ident.slice(0, 1),
      ident.slice(1, 5),
      ident.slice(5, 10),
      ident.slice(10, 12),
      ident.slice(12, 13),
    ].join("-");
  }
  return null;
};

// Undefined Check
const undefinedChecker = (value: any) => {
  if (value == null) {
    return "";
  }

  return value;
};

const THAI_MONTHS = {
  มกราคม: "January",
  กุมภาพันธ์: "February",
  มีนาคม: "March",
  เมษายน: "April",
  พฤษภาคม: "May",
  มิถุนายน: "June",
  กรกฎาคม: "July",
  สิงหาคม: "August",
  กันยายน: "September",
  ตุลาคม: "October",
  พฤศจิกายน: "November",
  ธันวาคม: "December",
} as any;

export const formCodeMap = {
  CardDeathCertificate: "CardDeathCertificate",
  CardFiveDiseasesCertificate: "CardFiveDiseasesCertificate",
  CardExamAbsenceCertificate: "CardExamAbsenceCertificate",
  CardDrivingCertificate: "CardDrivingCertificate",
  CardQuarantineCertificate: "CardQuarantineCertificate",
  CardCovidCertificate: "CardCovidCertificate",
  CardTravelCertificate: "CardTravelCertificate",
  CardVisaExtensionCertificate: "CardVisaExtensionCertificate",
} as const;

const Masters = [["nationality", {}]] as const;

export const Start: Handler = async (controller, params) => {
  // console.log("Start State Doctor Certificate");

  const state = controller.getState();

  controller.handleEvent({
    message: "GetMasterData",
    params: {
      master: Masters,
    },
  });

  // console.log("Start Action State: ", params);

  // get Report Type List
  const [getReportTypeResp, getReportTypeErr] = await DoctorCertificatesTypeList.list({
    apiToken: controller.apiToken,
  });

  let reportTypeList: any[] = [];
  let formCodeList: any[] = [];

  if (!getReportTypeErr) {
    reportTypeList = getReportTypeResp.items?.map((item: any) => {
      return {
        key: item.form_type_code,
        value: item.form_type_code,
        text: item.name,
        language_list: item.language_list.map((lang: any) => ({
          key: lang.id,
          value: lang.id,
          text: lang.name,
        })),
      };
    });

    formCodeList = getReportTypeResp.items
      ?.filter((item: any) => item.form_type_code != null)
      .map((item: any) => {
        return item.form_type_code;
      });
  }

  const filterReport = await GetFormDataActionLogAllList(controller, {
    filter: { start_date: formattedDate, end_date: formattedDate },
  });

  // const filterReportTest = await GetFormDataActionLogAllList(controller, {
  //   filter: { start_date: formattedDate, end_date: formattedDate, form_type_code: formCodeListTest[0] }
  // })

  // issue #69664
  const filterCertificateReportList = filterReport.filter((item: any) =>
    formCodeList.includes(item.form_type_code)
  );

  // console.log("Doctor Cert Type List: ", getReportTypeResp);
  // console.log("Doctor Cert Form Code List from Cert Type: ", formCodeList);
  // console.log("Doctor Cert Filter Report (Specific): ", filterReportTest);

  let isDoctor = state.django?.user?.role_types?.includes("DOCTOR");

  controller.setState({
    DoctorCertificateSequence: {
      sequenceIndex: "Action",
      reportOption: "default",
      // languageOption: [],
      isDoctor: isDoctor,
      selectedReport: null,
      originalReportList: filterCertificateReportList,
      reportList: filterCertificateReportList,
      reportTypeList: reportTypeList || [],
      formCodeList: formCodeList || [],
      filterReport: {
        startDate: formattedDate,
        endDate: formattedDate,
        doctorName: "",
        reportLanguage: "ไทย",
      },
      deathReport: {
        patientInfo: state.selectedPatient,
        createdDate: createdDate,
        createdDateForm: createDateForm,
        deathDate: formattedDate,
      },
      fiveReport: {
        patientInfo: state.selectedPatient,
        createdDate: createdDate,
        createdDateForm: createDateForm,
        examDate: formattedDate,
        isFoundDisease: "",
        isFirstDisease: false,
        isSecondDisease: false,
        isThirdDisease: false,
        isFourthDisease: false,
        isFifthDisease: false,
        otherNote: "",
      },
      examAbsenceReport: {
        patientInfo: state.selectedPatient,
        createdDate: createdDate,
        createdDateForm: createDateForm,
        examDate: formattedDate,
        shouldOpdAbsence: false,
        shouldIpdAbsence: false,
      },
      drivingReport: {
        patientInfo: state.selectedPatient,
        createdDate: createdDate,
        createdDateForm: createDateForm,
      },
      quarantineReport: {
        patientInfo: state.selectedPatient,
        createdDate: createdDate,
        createdDateForm: createDateForm,
        detectDate: formattedDate,
        shouldAbsence: false,
      },
      covidReport: {
        patientInfo: state.selectedPatient,
        createdDate: createdDate,
        createdDateForm: createDateForm,
        examDate: formattedDate,
        getAdvised: false,
        shouldAbsence: false,
      },
      travelReport: {
        patientInfo: state.selectedPatient,
        createdDate: createdDate,
        createdDateForm: createDateForm,
        examDate: formattedDate,
      },
      visaExtReport: {
        patientInfo: state.selectedPatient,
        createdDate: createdDate,
        createdDateForm: createDateForm,
        isDrug: false,
        isPhysicalTherapy: false,
        isXray: false,
        isProcedure: false,
        isPathology: false,
        isSurgery: false,
        isBandage: false,
        isOther: false,
        isProcedureNote: "",
        isSurgeryNote: "",
        isOtherNote: "",
        treatmentDuration: "",
      },
    },
  });

  console.log("Start State Props: ", state.DoctorCertificateSequence);
};

export const Action: Handler = async (controller, params) => {
  let state = controller.getState();
  let isDoctor = state.django?.user?.role_types?.includes("DOCTOR");

  // Set State isDoctor
  controller.setState({
    DoctorCertificateSequence: {
      ...state.DoctorCertificateSequence,
      isDoctor: isDoctor,
    },
  });

  // FETCH CERTIFICATE SEQUENCE
  if (params.action === "FETCH_CERTIFICATE") {
    if (!params.fetchType) {
      return;
    }

    // console.log("FETCH_CERTIFICATE Sequence");
    // console.log("FETCH Fetch Type: ", params.fetchType);
    // console.log(
    //   "FETCH Report Type: ",
    //   state.DoctorCertificateSequence?.reportOption
    // );

    // Call APIs
    const getUserName = state.django?.user?.full_name.split(" ");

    // Get Doctor License Number
    const [doctorListResp, doctorListErr, doctorListNet] = await DoctorList.list({
      apiToken: controller.apiToken,
      params: {
        first_name: getUserName[1],
        last_name: getUserName[2],
        // name_code: state.selectedEncounter?.doctor_name_code,
      },
    });

    const doctorCertificateNumber = doctorListResp.items.map((item: any) => ({
      key: item.certificate_no,
      value: item.certificate_no,
      text: item.certificate_no,
    }));

    const doctorInfo = doctorListResp?.items[0];
    const doctorName = doctorInfo ? `${doctorInfo.full_name}` : ` `;
    const doctorNameEn = doctorInfo
      ? `${doctorInfo.first_name_en} ${doctorInfo.last_name_en}`
      : ` `;

    const doctorCertNumber = doctorCertificateNumber[0]?.value ?? " ";

    // getMedicalRecord
    const [medicalRecordResp, medicalRecordErr] = await PatientEMRList.get({
      apiToken: controller.apiToken,
      emr: state.selectedEmr?.id,
      params: {
        auto_checkin: false,
      },
      extra: {
        division: controller.data.division,
      },
    });

    const getChiefComplaint = medicalRecordResp?.patient_illness?.chief_complaint || "";

    switch (params.fetchType) {
      // Death Certificate
      case formCodeMap.CardDeathCertificate:
        // Call ICD10 API
        const [deathIcd10Resp, deathIcdErr, deathIcd10Net] = await Icd10List.list({
          apiToken: controller.apiToken,
          params: { limit: 999 },
        });

        const newIcd10Options = deathIcd10Resp.items.map((item: { id: number; term: string }) => ({
          key: item.id,
          value: item.term,
          text: item.term,
        }));

        // Death Locate (Edit Here)
        const deathLocationTh = await ConstanceView.get({
          apiToken: controller.apiToken,
          params: {
            list: "core_HOSPITAL_NAME",
          },
          extra: {
            division: controller.data.division,
          },
        });

        const deathLocationEn = await ConstanceView.get({
          apiToken: controller.apiToken,
          params: {
            list: "core_HOSPITAL_NAME_EN",
          },
          extra: {
            division: controller.data.division,
          },
        });

        // Age Calculation (Compare with formattedDate) [0] -> ageYear, [1] -> ageMonth, [2] -> ageDay
        const patientAgeDeathReport = getAge(
          beToAd(state.selectedPatient?.birthdate),
          // beToAd(formattedDate)
          beToAd(state.DoctorCertificateSequence?.deathReport?.deathDate)
        );

        state = controller.getState();
        // Role condition at reportDuplicate if Doctor = true --> reportDuplicate = "ต้นฉบับ" else reportDuplicate = "สำเนา"
        if (state.DoctorCertificateSequence?.selectedReport) {
          controller.setState({
            DoctorCertificateSequence: {
              ...state.DoctorCertificateSequence,
              deathReport: {
                ...state.DoctorCertificateSequence?.selectedReport?.data,
              },
              // Other Report
              fiveReport: {
                patientInfo: state.selectedPatient,
                createdDate: createdDate,
                createdDateForm: createDateForm,
                examDate: formattedDate,
                isFoundDisease: "",
                isFirstDisease: false,
                isSecondDisease: false,
                isThirdDisease: false,
                isFourthDisease: false,
                isFifthDisease: false,
                otherNote: "",
              },
              examAbsenceReport: {
                patientInfo: state.selectedPatient,
                createdDate: createdDate,
                createdDateForm: createDateForm,
                examDate: formattedDate,
                shouldOpdAbsence: false,
                shouldIpdAbsence: false,
              },
              drivingReport: {
                patientInfo: state.selectedPatient,
                createdDate: createdDate,
                createdDateForm: createDateForm,
              },
              quarantineReport: {
                patientInfo: state.selectedPatient,
                createdDate: createdDate,
                createdDateForm: createDateForm,
                detectDate: formattedDate,
                shouldAbsence: false,
              },
              covidReport: {
                patientInfo: state.selectedPatient,
                createdDate: createdDate,
                createdDateForm: createDateForm,
                examDate: formattedDate,
                getAdvised: false,
                shouldAbsence: false,
              },
              travelReport: {
                patientInfo: state.selectedPatient,
                createdDate: createdDate,
                createdDateForm: createDateForm,
                examDate: formattedDate,
              },
              visaExtReport: {
                patientInfo: state.selectedPatient,
                createdDate: createdDate,
                createdDateForm: createDateForm,
              },
            },
          });
        } else {
          controller.setState({
            DoctorCertificateSequence: {
              ...state.DoctorCertificateSequence,
              deathReport: {
                ...state.DoctorCertificateSequence?.deathReport,
                // EN Ver use "Death Certificate", TH Ver use "ใบรับรองแพทย์สำหรับการเสียชีวิต"
                reportType: "ใบรับรองแพทย์สำหรับการเสียชีวิต",
                reportDuplicate: "ต้นฉบับ",
                reportLanguage: "ไทย",
                icd10Options: newIcd10Options,
                // Patient Info
                patientInfo: state.selectedPatient,
                deathLocate: deathLocationTh ? deathLocationTh[0]?.result?.core_HOSPITAL_NAME : " ",
                deathLocateEn: deathLocationEn
                  ? deathLocationEn[0]?.result?.core_HOSPITAL_NAME_EN
                  : " ",
                doctorName: doctorName,
                doctorNameEn: doctorNameEn,
                doctorCertNumber: doctorCertNumber,
                printCount: 1,
              },
              // Other Report
              fiveReport: {
                patientInfo: state.selectedPatient,
                createdDate: createdDate,
                createdDateForm: createDateForm,
                examDate: formattedDate,
                isFoundDisease: "",
                isFirstDisease: false,
                isSecondDisease: false,
                isThirdDisease: false,
                isFourthDisease: false,
                isFifthDisease: false,
                otherNote: "",
              },
              examAbsenceReport: {
                patientInfo: state.selectedPatient,
                createdDate: createdDate,
                createdDateForm: createDateForm,
                examDate: formattedDate,
                shouldOpdAbsence: false,
                shouldIpdAbsence: false,
              },
              drivingReport: {
                patientInfo: state.selectedPatient,
                createdDate: createdDate,
                createdDateForm: createDateForm,
              },
              quarantineReport: {
                patientInfo: state.selectedPatient,
                createdDate: createdDate,
                createdDateForm: createDateForm,
                detectDate: formattedDate,
                shouldAbsence: false,
              },
              covidReport: {
                patientInfo: state.selectedPatient,
                createdDate: createdDate,
                createdDateForm: createDateForm,
                examDate: formattedDate,
                getAdvised: false,
                shouldAbsence: false,
              },
              travelReport: {
                patientInfo: state.selectedPatient,
                createdDate: createdDate,
                createdDateForm: createDateForm,
                examDate: formattedDate,
              },
              visaExtReport: {
                patientInfo: state.selectedPatient,
                createdDate: createdDate,
                createdDateForm: createDateForm,
              },
            },
          });
        }

        // Get Report
        Action(controller, {
          action: "GET_REPORT",
          codeForm: "CardDeathCertificate",
        });
        break;
      // Five Diseases Certificate
      case formCodeMap.CardFiveDiseasesCertificate:
        // Age Calculation (Compare with formattedDate) [0] -> ageYear, [1] -> ageMonth, [2] -> ageDay
        const patientAgeFiveReport = getAge(
          beToAd(state.selectedPatient?.birthdate),
          // beToAd(formattedDate)
          beToAd(state.DoctorCertificateSequence?.fiveReport?.examDate)
        );

        state = controller.getState();

        if (state.DoctorCertificateSequence?.selectedReport) {
          controller.setState({
            DoctorCertificateSequence: {
              ...state.DoctorCertificateSequence,
              fiveReport: {
                ...state.DoctorCertificateSequence?.selectedReport?.data,
              },
              // Other Report
              deathReport: {
                patientInfo: state.selectedPatient,
                createdDate: createdDate,
                createdDateForm: createDateForm,
                deathDate: formattedDate,
              },
              examAbsenceReport: {
                patientInfo: state.selectedPatient,
                createdDate: createdDate,
                createdDateForm: createDateForm,
                examDate: formattedDate,
                shouldOpdAbsence: false,
                shouldIpdAbsence: false,
              },
              drivingReport: {
                patientInfo: state.selectedPatient,
                createdDate: createdDate,
                createdDateForm: createDateForm,
              },
              quarantineReport: {
                patientInfo: state.selectedPatient,
                createdDate: createdDate,
                createdDateForm: createDateForm,
                detectDate: formattedDate,
                shouldAbsence: false,
              },
              covidReport: {
                patientInfo: state.selectedPatient,
                createdDate: createdDate,
                createdDateForm: createDateForm,
                examDate: formattedDate,
                getAdvised: false,
                shouldAbsence: false,
              },
              travelReport: {
                patientInfo: state.selectedPatient,
                createdDate: createdDate,
                createdDateForm: createDateForm,
                examDate: formattedDate,
              },
              visaExtReport: {
                patientInfo: state.selectedPatient,
                createdDate: createdDate,
                createdDateForm: createDateForm,
              },
            },
          });
        } else {
          controller.setState({
            DoctorCertificateSequence: {
              ...state.DoctorCertificateSequence,
              fiveReport: {
                ...state.DoctorCertificateSequence?.fiveReport,
                reportType: "ใบรับรองแพทย์ 5 โรค",
                reportDuplicate: "ต้นฉบับ",
                reportLanguage: "ไทย",
                // Patient Info
                patientInfo: state.selectedPatient,
                doctorName: doctorName,
                doctorNameEn: doctorNameEn,
                doctorCertNumber: doctorCertNumber,
                isFoundDisease: "Free", // default
                printCount: 1,
                // Logic Value
                ageYear: patientAgeFiveReport[0],
                ageMonth: patientAgeFiveReport[1],
                ageDay: patientAgeFiveReport[2],
              },
              // Other Report
              deathReport: {
                patientInfo: state.selectedPatient,
                createdDate: createdDate,
                createdDateForm: createDateForm,
                deathDate: formattedDate,
              },
              examAbsenceReport: {
                patientInfo: state.selectedPatient,
                createdDate: createdDate,
                createdDateForm: createDateForm,
                examDate: formattedDate,
                shouldOpdAbsence: false,
                shouldIpdAbsence: false,
              },
              drivingReport: {
                patientInfo: state.selectedPatient,
                createdDate: createdDate,
                createdDateForm: createDateForm,
              },
              quarantineReport: {
                patientInfo: state.selectedPatient,
                createdDate: createdDate,
                createdDateForm: createDateForm,
                detectDate: formattedDate,
                shouldAbsence: false,
              },
              covidReport: {
                patientInfo: state.selectedPatient,
                createdDate: createdDate,
                createdDateForm: createDateForm,
                examDate: formattedDate,
                getAdvised: false,
                shouldAbsence: false,
              },
              travelReport: {
                patientInfo: state.selectedPatient,
                createdDate: createdDate,
                createdDateForm: createDateForm,
                examDate: formattedDate,
              },
              visaExtReport: {
                patientInfo: state.selectedPatient,
                createdDate: createdDate,
                createdDateForm: createDateForm,
              },
            },
          });
        }

        // Get Report
        Action(controller, {
          action: "GET_REPORT",
          codeForm: "CardFiveDiseasesCertificate",
        });
        break;
      // Examination Absence Certificate
      case formCodeMap.CardExamAbsenceCertificate:
        // Format Admit Date (XX/XX/XXX [XX:XX] -> XX/XX/XXXX)
        var originAdmitDate = state.selectedPatient?.admit_date;
        var formattedAdmitDate;
        if (originAdmitDate === undefined || originAdmitDate === null || originAdmitDate === "") {
          formattedAdmitDate = "invalid date";
        } else {
          const preAdmitDate = originAdmitDate.slice(0, 10);
          const newAdmitDate = moment(preAdmitDate, "DD/MM/YYYY").format("YYYY-MM-DD");
          const christYear = moment(newAdmitDate).format("YYYY");
          const buddhistYear = (parseInt(christYear) + 543).toString();
          formattedAdmitDate = moment(newAdmitDate).format(
            "DD/MM/YYYY".replace("YYYY", buddhistYear)
          );
        }

        // OPD ExamDate
        const opdExamDate = (date: any) => {
          return date.slice(0, 10);
        };

        // Call Diagnosis API
        const [diagExAbResp, diagExAbErr, diagExAbNet] =
          await DiagnosisMedicalRecordDetail.retrieve({
            apiToken: controller.apiToken,
            pk: state.selectedEmr?.id,
            params: { limit: 999 },
          });

        // Call Addmission Form API
        const [admisFormResp, admisFormErr, admisFormNet] = await AdmissionList.retrieve({
          apiToken: controller.apiToken,
          pk: state.selectedEmr?.id,
        });

        // console.log("Driving AdmissionForm: ", admisFormResp.admission_form?.chief_complaint);

        // Chief Complaint Condition (get Chief Complaint from Admission Form)
        if (state.selectedPatient?.encounter_type === "OPD") {
          var patientChiefComplaint = getChiefComplaint;
        } else if (state.selectedPatient?.encounter_type === "IPD") {
          patientChiefComplaint = admisFormResp.admission_form?.chief_complaint;
        } else {
          patientChiefComplaint = "";
        }

        // Age Calculation (Compare with formattedDate) [0] -> ageYear, [1] -> ageMonth, [2] -> ageDay
        const patientAgeExamAbsenceReport = getAge(
          beToAd(state.selectedPatient?.birthdate),
          // beToAd(formattedDate)
          beToAd(state.DoctorCertificateSequence?.examAbsenceReport?.examDate)
        );

        state = controller.getState();

        // Selected Data Check when Select Data from default Page
        if (state.DoctorCertificateSequence?.selectedReport) {
          // console.log(
          //   "Now use Selected Report: ",
          //   state.DoctorCertificateSequence?.selectedReport?.data
          // );

          controller.setState({
            DoctorCertificateSequence: {
              ...state.DoctorCertificateSequence,
              examAbsenceReport: {
                ...state.DoctorCertificateSequence?.selectedReport?.data,
              },
              // Other Report
              deathReport: {
                patientInfo: state.selectedPatient,
                createdDate: createdDate,
                createdDateForm: createDateForm,
                deathDate: formattedDate,
              },
              fiveReport: {
                patientInfo: state.selectedPatient,
                createdDate: createdDate,
                createdDateForm: createDateForm,
                examDate: formattedDate,
                isFoundDisease: "",
                isFirstDisease: false,
                isSecondDisease: false,
                isThirdDisease: false,
                isFourthDisease: false,
                isFifthDisease: false,
                otherNote: "",
              },
              drivingReport: {
                patientInfo: state.selectedPatient,
                createdDate: createdDate,
                createdDateForm: createDateForm,
              },
              quarantineReport: {
                patientInfo: state.selectedPatient,
                createdDate: createdDate,
                createdDateForm: createDateForm,
                detectDate: formattedDate,
                shouldAbsence: false,
              },
              covidReport: {
                patientInfo: state.selectedPatient,
                createdDate: createdDate,
                createdDateForm: createDateForm,
                examDate: formattedDate,
                getAdvised: false,
                shouldAbsence: false,
              },
              travelReport: {
                patientInfo: state.selectedPatient,
                createdDate: createdDate,
                createdDateForm: createDateForm,
                examDate: formattedDate,
              },
              visaExtReport: {
                patientInfo: state.selectedPatient,
                createdDate: createdDate,
                createdDateForm: createDateForm,
              },
            },
          });
        } else {
          // console.log(
          //   "Now Use Current Report: ",
          //   state.DoctorCertificateSequence?.examAbsenceReport
          // );

          controller.setState({
            DoctorCertificateSequence: {
              ...state.DoctorCertificateSequence,
              examAbsenceReport: {
                ...state.DoctorCertificateSequence?.examAbsenceReport,
                reportType: "ใบรับรองแพทย์สำหรับการตรวจ/ลางาน",
                reportDuplicate: "ต้นฉบับ",
                reportLanguage: "ไทย",
                // Patient Info
                patientInfo: state.selectedPatient,
                chiefComplaint: patientChiefComplaint,
                diagnosis:
                  (diagExAbResp.principal_diagnosis?.[0]?.icd_code || "") +
                    " " +
                    (diagExAbResp.principal_diagnosis?.[0]?.icd_term || "") +
                    (diagExAbResp.principal_diagnosis?.[0]?.medical_description || "") +
                    (diagExAbResp.secondary_diagnosis?.[0]?.icd_code &&
                    diagExAbResp.secondary_diagnosis?.[0]?.icd_term &&
                    diagExAbResp.secondary_diagnosis?.[0]?.medical_description
                      ? "," +
                        (diagExAbResp.secondary_diagnosis?.[0]?.icd_code || "") +
                        " " +
                        (diagExAbResp.secondary_diagnosis?.[0]?.icd_term || "") +
                        (diagExAbResp.secondary_diagnosis?.[0]?.medical_description || "")
                      : "") || "",
                // examDate: opdExamDate(state.selectedEncounter?.created),
                examDate: formattedDate,
                admitStartDate: formattedAdmitDate,
                admitEndDate: formattedDate,
                doctorName: doctorName,
                doctorNameEn: doctorNameEn,
                doctorCertNumber: doctorCertNumber,
                printCount: 1,
                // Logic Value
                ageYear: patientAgeExamAbsenceReport[0],
                ageMonth: patientAgeExamAbsenceReport[1],
                ageDay: patientAgeExamAbsenceReport[2],
              },
              // Other Report
              deathReport: {
                patientInfo: state.selectedPatient,
                createdDate: createdDate,
                createdDateForm: createDateForm,
                deathDate: formattedDate,
              },
              fiveReport: {
                patientInfo: state.selectedPatient,
                createdDate: createdDate,
                createdDateForm: createDateForm,
                examDate: formattedDate,
                isFoundDisease: "",
                isFirstDisease: false,
                isSecondDisease: false,
                isThirdDisease: false,
                isFourthDisease: false,
                isFifthDisease: false,
                otherNote: "",
              },
              drivingReport: {
                patientInfo: state.selectedPatient,
                createdDate: createdDate,
                createdDateForm: createDateForm,
              },
              quarantineReport: {
                patientInfo: state.selectedPatient,
                createdDate: createdDate,
                createdDateForm: createDateForm,
                detectDate: formattedDate,
                shouldAbsence: false,
              },
              covidReport: {
                patientInfo: state.selectedPatient,
                createdDate: createdDate,
                createdDateForm: createDateForm,
                examDate: formattedDate,
                getAdvised: false,
                shouldAbsence: false,
              },
              travelReport: {
                patientInfo: state.selectedPatient,
                createdDate: createdDate,
                createdDateForm: createDateForm,
                examDate: formattedDate,
              },
              visaExtReport: {
                patientInfo: state.selectedPatient,
                createdDate: createdDate,
                createdDateForm: createDateForm,
              },
            },
          });
        }

        // Get Report
        Action(controller, {
          action: "GET_REPORT",
          codeForm: "CardExamAbsenceCertificate",
        });
        break;
      // Driving Certificate
      case formCodeMap.CardDrivingCertificate:
        // Call Measurement API
        const [measurementResp, measurementErr, measurementNet] = await MeasurementList.list({
          apiToken: controller.apiToken,
          params: {
            limit: 9999,
            encounter: state.selectedEncounter?.id,
            from_date: formattedDate,
            to_date: formattedDate,
            use_patient: true,
            invert_order: true,
          },
        });

        var patientVitalInfo = null;

        if (measurementResp.items[0] == null) {
          patientVitalInfo = {};
        } else {
          const patientVitalInfo = measurementResp.items[0].vital_signs.reduce(
            (acc: any, item: any) => {
              let value = null;

              switch (item.vitalsign_type_code) {
                case "Weight":
                case "Height":
                case "SP":
                case "DP":
                case "PR":
                case "PULSE":
                  value = item.formatted_string;
                  break;
                default:
                  value = null;
                  break;
              }

              if (value !== null) {
                acc.push({ [`${item.vitalsign_type_code}`]: value });
              }

              return acc;
            },
            []
          );

          const systolicItem = measurementResp.items[0].vital_signs.find(
            (item: any) => item.vitalsign_type_code === "SP"
          );
          const diastolicItem = measurementResp.items[0].vital_signs.find(
            (item: any) => item.vitalsign_type_code === "DP"
          );
          const systolicValue = systolicItem ? systolicItem.formatted_string : null;
          const diastolicValue = diastolicItem ? diastolicItem.formatted_string : null;

          if (systolicValue !== null && diastolicValue !== null) {
            patientVitalInfo.push({
              bloodPressure: `${systolicValue}/${diastolicValue}`,
            });
          }

          const getVitalInfo = (vitalNames: string[]) => {
            for (const name of vitalNames) {
              const vital = patientVitalInfo.find((item) => Object.hasOwn(item, name));

              if (vital) {
                return vital[name];
              }
            }

            return null;
          };

          var patientWeight = getVitalInfo(["Weight"]);
          var patientHeight = getVitalInfo(["Height"]);
          var patientPressure = getVitalInfo(["bloodPressure"]);
          var patientPulse = getVitalInfo(["PULSE", "PR"]);
        }

        // console.log("Vital Sign Data: ", measurementResp.items[0].vital_signs);
        // console.log("Vital Sign Patient: ", patientVitalInfo);
        // console.log("Vital Sign Weight: ", patientWeight);
        // Get Hospital Name [Edit here for Hospital name and address]
        const hospitalNameData = await ConstanceView.get({
          apiToken: controller.apiToken,
          params: {
            list: "core_HOSPITAL_NAME",
          },
          extra: {
            division: controller.data.division,
          },
        });

        const hospitalNameDataEn = await ConstanceView.get({
          apiToken: controller.apiToken,
          params: {
            list: "core_HOSPITAL_NAME_EN",
          },
          extra: {
            division: controller.data.division,
          },
        });

        const hospitalAddressData = await ConstanceView.get({
          apiToken: controller.apiToken,
          params: {
            list: "core_HOSPITAL_ADDRESS",
          },
          extra: {
            division: controller.data.division,
          },
        });

        // Age Calculation (Compare with formattedDate) [0] -> ageYear, [1] -> ageMonth, [2] -> ageDay
        const patientAgeDrivingReport = getAge(
          beToAd(state.selectedPatient?.birthdate),
          // beToAd(formattedDate)
          beToAd(state.DoctorCertificateSequence?.drivingReport?.examDate)
        );

        state = controller.getState();

        if (state.DoctorCertificateSequence?.selectedReport) {
          controller.setState({
            DoctorCertificateSequence: {
              ...state.DoctorCertificateSequence,
              drivingReport: {
                ...state.DoctorCertificateSequence?.selectedReport?.data,
              },
              // Other Note
              deathReport: {
                patientInfo: state.selectedPatient,
                createdDate: createdDate,
                createdDateForm: createDateForm,
                deathDate: formattedDate,
              },
              fiveReport: {
                patientInfo: state.selectedPatient,
                createdDate: createdDate,
                createdDateForm: createDateForm,
                examDate: formattedDate,
                isFoundDisease: "",
                isFirstDisease: false,
                isSecondDisease: false,
                isThirdDisease: false,
                isFourthDisease: false,
                isFifthDisease: false,
                otherNote: "",
              },
              examAbsenceReport: {
                patientInfo: state.selectedPatient,
                createdDate: createdDate,
                createdDateForm: createDateForm,
                examDate: formattedDate,
                shouldOpdAbsence: false,
                shouldIpdAbsence: false,
              },
              quarantineReport: {
                patientInfo: state.selectedPatient,
                createdDate: createdDate,
                createdDateForm: createDateForm,
                detectDate: formattedDate,
                shouldAbsence: false,
              },
              covidReport: {
                patientInfo: state.selectedPatient,
                createdDate: createdDate,
                createdDateForm: createDateForm,
                examDate: formattedDate,
                getAdvised: false,
                shouldAbsence: false,
              },
              travelReport: {
                patientInfo: state.selectedPatient,
                createdDate: createdDate,
                createdDateForm: createDateForm,
                examDate: formattedDate,
              },
              visaExtReport: {
                patientInfo: state.selectedPatient,
                createdDate: createdDate,
                createdDateForm: createDateForm,
              },
            },
          });
        } else {
          controller.setState({
            DoctorCertificateSequence: {
              ...state.DoctorCertificateSequence,
              drivingReport: {
                ...state.DoctorCertificateSequence?.drivingReport,
                reportType: "ใบรับรองแพทย์สำหรับสมัคร/ต่อใบขับขี่",
                reportDuplicate: "ต้นฉบับ",
                reportLanguage: "ไทย",
                // Patient Info
                patientInfo: state.selectedPatient,
                patientWeight: patientWeight,
                patientHeight: patientHeight,
                patientPressure: patientPressure,
                patientPulse: patientPulse,
                examDate: formattedDate,
                hospitalName: hospitalNameData
                  ? hospitalNameData[0]?.result?.core_HOSPITAL_NAME
                  : " ",
                hospitalNameEn: hospitalNameDataEn
                  ? hospitalNameDataEn[0]?.result?.core_HOSPITAL_NAME_EN
                  : " ",
                hospitalAddress: hospitalAddressData
                  ? hospitalAddressData[0]?.result?.core_HOSPITAL_ADDRESS
                  : " ",
                doctorName: doctorName,
                doctorNameEn: doctorNameEn,
                doctorCertNumber: doctorCertNumber,
                printCount: 1,
              },
              // Other Note
              deathReport: {
                patientInfo: state.selectedPatient,
                createdDate: createdDate,
                createdDateForm: createDateForm,
                deathDate: formattedDate,
              },
              fiveReport: {
                patientInfo: state.selectedPatient,
                createdDate: createdDate,
                createdDateForm: createDateForm,
                examDate: formattedDate,
                isFoundDisease: "",
                isFirstDisease: false,
                isSecondDisease: false,
                isThirdDisease: false,
                isFourthDisease: false,
                isFifthDisease: false,
                otherNote: "",
              },
              examAbsenceReport: {
                patientInfo: state.selectedPatient,
                createdDate: createdDate,
                createdDateForm: createDateForm,
                examDate: formattedDate,
                shouldOpdAbsence: false,
                shouldIpdAbsence: false,
              },
              quarantineReport: {
                patientInfo: state.selectedPatient,
                createdDate: createdDate,
                createdDateForm: createDateForm,
                detectDate: formattedDate,
                shouldAbsence: false,
              },
              covidReport: {
                patientInfo: state.selectedPatient,
                createdDate: createdDate,
                createdDateForm: createDateForm,
                examDate: formattedDate,
                getAdvised: false,
                shouldAbsence: false,
              },
              travelReport: {
                patientInfo: state.selectedPatient,
                createdDate: createdDate,
                createdDateForm: createDateForm,
                examDate: formattedDate,
              },
              visaExtReport: {
                patientInfo: state.selectedPatient,
                createdDate: createdDate,
                createdDateForm: createDateForm,
              },
            },
          });
        }

        // Get Report
        Action(controller, {
          action: "GET_REPORT",
          codeForm: "CardDrivingCertificate",
        });

        break;
      // Quarantine Certificate
      case formCodeMap.CardQuarantineCertificate:
        // Age Calculation (Compare with formattedDate) [0] -> ageYear, [1] -> ageMonth, [2] -> ageDay
        const patientAgeQuarantineReport = getAge(
          beToAd(state.selectedPatient?.birthdate),
          // beToAd(formattedDate)
          beToAd(state.DoctorCertificateSequence?.quarantineReport?.detectDate)
        );
        // console.log("Quarantine BirthDate: ", beToAd(state.selectedPatient?.birthdate));
        // console.log(
        //   "Quarantine DetectDate: ",
        //   beToAd(state.DoctorCertificateSequence?.quarantineReport?.detectDate)
        // );

        state = controller.getState();

        if (state.DoctorCertificateSequence?.selectedReport) {
          controller.setState({
            DoctorCertificateSequence: {
              ...state.DoctorCertificateSequence,
              quarantineReport: {
                ...state.DoctorCertificateSequence?.selectedReport?.data,
              },
              // Other Note
              deathReport: {
                patientInfo: state.selectedPatient,
                createdDate: createdDate,
                createdDateForm: createDateForm,
                deathDate: formattedDate,
              },
              fiveReport: {
                patientInfo: state.selectedPatient,
                createdDate: createdDate,
                createdDateForm: createDateForm,
                examDate: formattedDate,
                isFoundDisease: "",
                isFirstDisease: false,
                isSecondDisease: false,
                isThirdDisease: false,
                isFourthDisease: false,
                isFifthDisease: false,
                otherNote: "",
              },
              examAbsenceReport: {
                patientInfo: state.selectedPatient,
                createdDate: createdDate,
                createdDateForm: createDateForm,
                examDate: formattedDate,
                shouldOpdAbsence: false,
                shouldIpdAbsence: false,
              },
              drivingReport: {
                patientInfo: state.selectedPatient,
                createdDate: createdDate,
                createdDateForm: createDateForm,
              },
              covidReport: {
                patientInfo: state.selectedPatient,
                createdDate: createdDate,
                createdDateForm: createDateForm,
                examDate: formattedDate,
                getAdvised: false,
                shouldAbsence: false,
              },
              travelReport: {
                patientInfo: state.selectedPatient,
                createdDate: createdDate,
                createdDateForm: createDateForm,
                examDate: formattedDate,
              },
              visaExtReport: {
                patientInfo: state.selectedPatient,
                createdDate: createdDate,
                createdDateForm: createDateForm,
              },
            },
          });
        } else {
          controller.setState({
            DoctorCertificateSequence: {
              ...state.DoctorCertificateSequence,
              quarantineReport: {
                ...state.DoctorCertificateSequence?.quarantineReport,
                reportType: "ใบรับรองการกักตัวหลังตรวจพบเชื้อโควิด",
                reportDuplicate: "ต้นฉบับ",
                reportLanguage: "ไทย",
                // Patient Info
                patientInfo: state.selectedPatient,
                chiefComplaint: getChiefComplaint,
                doctorName: doctorName,
                doctorNameEn: doctorNameEn,
                doctorCertNumber: doctorCertNumber,
                printCount: 1,
                // Logic Value
                ageYear: patientAgeQuarantineReport[0],
                ageMonth: patientAgeQuarantineReport[1],
                ageDay: patientAgeQuarantineReport[2],
              },
              // Other Note
              deathReport: {
                patientInfo: state.selectedPatient,
                createdDate: createdDate,
                createdDateForm: createDateForm,
                deathDate: formattedDate,
              },
              fiveReport: {
                patientInfo: state.selectedPatient,
                createdDate: createdDate,
                createdDateForm: createDateForm,
                examDate: formattedDate,
                isFoundDisease: "",
                isFirstDisease: false,
                isSecondDisease: false,
                isThirdDisease: false,
                isFourthDisease: false,
                isFifthDisease: false,
                otherNote: "",
              },
              examAbsenceReport: {
                patientInfo: state.selectedPatient,
                createdDate: createdDate,
                createdDateForm: createDateForm,
                examDate: formattedDate,
                shouldOpdAbsence: false,
                shouldIpdAbsence: false,
              },
              drivingReport: {
                patientInfo: state.selectedPatient,
                createdDate: createdDate,
                createdDateForm: createDateForm,
              },
              covidReport: {
                patientInfo: state.selectedPatient,
                createdDate: createdDate,
                createdDateForm: createDateForm,
                examDate: formattedDate,
                getAdvised: false,
                shouldAbsence: false,
              },
              travelReport: {
                patientInfo: state.selectedPatient,
                createdDate: createdDate,
                createdDateForm: createDateForm,
                examDate: formattedDate,
              },
              visaExtReport: {
                patientInfo: state.selectedPatient,
                createdDate: createdDate,
                createdDateForm: createDateForm,
              },
            },
          });
        }

        // Get Report
        Action(controller, {
          action: "GET_REPORT",
          codeForm: "CardQuarantineCertificate",
        });
        break;
      // Covid Certificate
      case formCodeMap.CardCovidCertificate:
        // Age Calculation (Compare with formattedDate) [0] -> ageYear, [1] -> ageMonth, [2] -> ageDay
        const patientAgeCovidReport = getAge(
          beToAd(state.selectedPatient?.birthdate),
          // beToAd(formattedDate)
          beToAd(state.DoctorCertificateSequence?.covidReport?.examDate)
        );

        state = controller.getState();

        if (state.DoctorCertificateSequence?.selectedReport) {
          controller.setState({
            DoctorCertificateSequence: {
              ...state.DoctorCertificateSequence,
              covidReport: {
                ...state.DoctorCertificateSequence?.selectedReport?.data,
              },
              // Other Note
              deathReport: {
                patientInfo: state.selectedPatient,
                createdDate: createdDate,
                createdDateForm: createDateForm,
                deathDate: formattedDate,
              },
              fiveReport: {
                patientInfo: state.selectedPatient,
                createdDate: createdDate,
                createdDateForm: createDateForm,
                examDate: formattedDate,
                isFoundDisease: "",
                isFirstDisease: false,
                isSecondDisease: false,
                isThirdDisease: false,
                isFourthDisease: false,
                isFifthDisease: false,
                otherNote: "",
              },
              examAbsenceReport: {
                patientInfo: state.selectedPatient,
                createdDate: createdDate,
                createdDateForm: createDateForm,
                examDate: formattedDate,
                shouldOpdAbsence: false,
                shouldIpdAbsence: false,
              },
              drivingReport: {
                patientInfo: state.selectedPatient,
                createdDate: createdDate,
                createdDateForm: createDateForm,
              },
              quarantineReport: {
                patientInfo: state.selectedPatient,
                createdDate: createdDate,
                createdDateForm: createDateForm,
                detectDate: formattedDate,
                shouldAbsence: false,
              },
              travelReport: {
                patientInfo: state.selectedPatient,
                createdDate: createdDate,
                createdDateForm: createDateForm,
                examDate: formattedDate,
              },
              visaExtReport: {
                patientInfo: state.selectedPatient,
                createdDate: createdDate,
                createdDateForm: createDateForm,
              },
            },
          });
        } else {
          controller.setState({
            DoctorCertificateSequence: {
              ...state.DoctorCertificateSequence,
              covidReport: {
                ...state.DoctorCertificateSequence?.covidReport,
                reportType: "ใบรับรองผลการตรวจโควิด",
                reportDuplicate: "ต้นฉบับ",
                reportLanguage: "ไทย",
                // Patient Info
                patientInfo: state.selectedPatient,
                doctorName: doctorName,
                doctorNameEn: doctorNameEn,
                doctorCertNumber: doctorCertNumber,
                printCount: 1,
                // Logic value
                ageYear: patientAgeCovidReport[0],
                ageMonth: patientAgeCovidReport[1],
                ageDay: patientAgeCovidReport[2],
              },
              // Other Note
              deathReport: {
                patientInfo: state.selectedPatient,
                createdDate: createdDate,
                createdDateForm: createDateForm,
                deathDate: formattedDate,
              },
              fiveReport: {
                patientInfo: state.selectedPatient,
                createdDate: createdDate,
                createdDateForm: createDateForm,
                examDate: formattedDate,
                isFoundDisease: "",
                isFirstDisease: false,
                isSecondDisease: false,
                isThirdDisease: false,
                isFourthDisease: false,
                isFifthDisease: false,
                otherNote: "",
              },
              examAbsenceReport: {
                patientInfo: state.selectedPatient,
                createdDate: createdDate,
                createdDateForm: createDateForm,
                examDate: formattedDate,
                shouldOpdAbsence: false,
                shouldIpdAbsence: false,
              },
              drivingReport: {
                patientInfo: state.selectedPatient,
                createdDate: createdDate,
                createdDateForm: createDateForm,
              },
              quarantineReport: {
                patientInfo: state.selectedPatient,
                createdDate: createdDate,
                createdDateForm: createDateForm,
                detectDate: formattedDate,
                shouldAbsence: false,
              },
              travelReport: {
                patientInfo: state.selectedPatient,
                createdDate: createdDate,
                createdDateForm: createDateForm,
                examDate: formattedDate,
              },
              visaExtReport: {
                patientInfo: state.selectedPatient,
                createdDate: createdDate,
                createdDateForm: createDateForm,
              },
            },
          });
        }

        // Get Report
        Action(controller, {
          action: "GET_REPORT",
          codeForm: "CardCovidCertificate",
        });
        break;
      // Travel Certificate
      case formCodeMap.CardTravelCertificate:
        // Call Diagnosis API
        const [diagTravelResp, diagTravelErr, diagTravelNet] =
          await DiagnosisMedicalRecordDetail.retrieve({
            apiToken: controller.apiToken,
            pk: state.selectedEmr?.id,
            params: { limit: 999 },
          });

        // Age Calculation (Compare with formattedDate) [0] -> ageYear, [1] -> ageMonth, [2] -> ageDay
        const patientAgeTravelReport = getAge(
          beToAd(state.selectedPatient?.birthdate),
          // beToAd(formattedDate)
          beToAd(state.DoctorCertificateSequence?.travelReport?.examDate)
        );

        state = controller.getState();

        if (state.DoctorCertificateSequence?.selectedReport) {
          controller.setState({
            DoctorCertificateSequence: {
              ...state.DoctorCertificateSequence,
              travelReport: {
                ...state.DoctorCertificateSequence?.selectedReport?.data,
              },
              // Other Note
              deathReport: {
                patientInfo: state.selectedPatient,
                createdDate: createdDate,
                createdDateForm: createDateForm,
                deathDate: formattedDate,
              },
              fiveReport: {
                patientInfo: state.selectedPatient,
                createdDate: createdDate,
                createdDateForm: createDateForm,
                examDate: formattedDate,
                isFoundDisease: "",
                isFirstDisease: false,
                isSecondDisease: false,
                isThirdDisease: false,
                isFourthDisease: false,
                isFifthDisease: false,
                otherNote: "",
              },
              examAbsenceReport: {
                patientInfo: state.selectedPatient,
                createdDate: createdDate,
                createdDateForm: createDateForm,
                examDate: formattedDate,
                shouldOpdAbsence: false,
                shouldIpdAbsence: false,
              },
              drivingReport: {
                patientInfo: state.selectedPatient,
                createdDate: createdDate,
                createdDateForm: createDateForm,
              },
              quarantineReport: {
                patientInfo: state.selectedPatient,
                createdDate: createdDate,
                createdDateForm: createDateForm,
                detectDate: formattedDate,
                shouldAbsence: false,
              },
              covidReport: {
                patientInfo: state.selectedPatient,
                createdDate: createdDate,
                createdDateForm: createDateForm,
                examDate: formattedDate,
                getAdvised: false,
                shouldAbsence: false,
              },
              visaExtReport: {
                patientInfo: state.selectedPatient,
                createdDate: createdDate,
                createdDateForm: createDateForm,
              },
            },
          });
        } else {
          controller.setState({
            DoctorCertificateSequence: {
              ...state.DoctorCertificateSequence,
              travelReport: {
                ...state.DoctorCertificateSequence?.travelReport,
                reportType: "ใบรับรองแพทย์สำหรับการเดินทาง",
                reportDuplicate: "ต้นฉบับ",
                reportLanguage: "ไทย",
                // Patient Info
                patientInfo: state.selectedPatient,
                diagnosis:
                  (diagTravelResp.principal_diagnosis?.[0]?.icd_code || "") +
                    " " +
                    (diagTravelResp.principal_diagnosis?.[0]?.icd_term || "") +
                    (diagTravelResp.principal_diagnosis?.[0]?.medical_description || "") +
                    (diagTravelResp.secondary_diagnosis?.[0]?.icd_code &&
                    diagTravelResp.secondary_diagnosis?.[0]?.icd_term &&
                    diagTravelResp.secondary_diagnosis?.[0]?.medical_description
                      ? "," +
                        (diagTravelResp.secondary_diagnosis?.[0]?.icd_code || "") +
                        " " +
                        (diagTravelResp.secondary_diagnosis?.[0]?.icd_term || "") +
                        (diagTravelResp.secondary_diagnosis?.[0]?.medical_description || "")
                      : "") || "",
                doctorName: doctorName,
                doctorNameEn: doctorNameEn,
                doctorCertNumber: doctorCertNumber,
                printCount: 1,
                // Logic Value
                ageYear: patientAgeTravelReport[0],
                ageMonth: patientAgeTravelReport[1],
                ageDay: patientAgeTravelReport[2],
              },
              // Other Note
              deathReport: {
                patientInfo: state.selectedPatient,
                createdDate: createdDate,
                createdDateForm: createDateForm,
                deathDate: formattedDate,
              },
              fiveReport: {
                patientInfo: state.selectedPatient,
                createdDate: createdDate,
                createdDateForm: createDateForm,
                examDate: formattedDate,
                isFoundDisease: "",
                isFirstDisease: false,
                isSecondDisease: false,
                isThirdDisease: false,
                isFourthDisease: false,
                isFifthDisease: false,
                otherNote: "",
              },
              examAbsenceReport: {
                patientInfo: state.selectedPatient,
                createdDate: createdDate,
                createdDateForm: createDateForm,
                examDate: formattedDate,
                shouldOpdAbsence: false,
                shouldIpdAbsence: false,
              },
              drivingReport: {
                patientInfo: state.selectedPatient,
                createdDate: createdDate,
                createdDateForm: createDateForm,
              },
              quarantineReport: {
                patientInfo: state.selectedPatient,
                createdDate: createdDate,
                createdDateForm: createDateForm,
                detectDate: formattedDate,
                shouldAbsence: false,
              },
              covidReport: {
                patientInfo: state.selectedPatient,
                createdDate: createdDate,
                createdDateForm: createDateForm,
                examDate: formattedDate,
                getAdvised: false,
                shouldAbsence: false,
              },
              visaExtReport: {
                patientInfo: state.selectedPatient,
                createdDate: createdDate,
                createdDateForm: createDateForm,
              },
            },
          });
        }

        // Get Report
        Action(controller, {
          action: "GET_REPORT",
          codeForm: "CardTravelCertificate",
        });
        break;
      // Visa Extension Certificate
      case formCodeMap.CardVisaExtensionCertificate:
        // Call Diagnosis API
        const [diagVisaResp, diagVisaErr] = await DiagnosisMedicalRecordDetail.retrieve({
          apiToken: controller.apiToken,
          pk: state.selectedEmr?.id,
          params: { limit: 999 },
        });

        // Age Calculation (Compare with formattedDate) [0] -> ageYear, [1] -> ageMonth, [2] -> ageDay
        const patientAgeVisaExtReport = getAge(
          beToAd(state.selectedPatient?.birthdate),
          // beToAd(formattedDate)
          beToAd(formattedDate)
        );

        state = controller.getState();

        if (state.DoctorCertificateSequence?.selectedReport) {
          controller.setState({
            DoctorCertificateSequence: {
              ...state.DoctorCertificateSequence,
              visaExtReport: {
                ...state.DoctorCertificateSequence?.selectedReport?.data,
              },
              // Other Note
              deathReport: {
                patientInfo: state.selectedPatient,
                createdDate: createdDate,
                createdDateForm: createDateForm,
                deathDate: formattedDate,
              },
              fiveReport: {
                patientInfo: state.selectedPatient,
                createdDate: createdDate,
                createdDateForm: createDateForm,
                examDate: formattedDate,
                isFoundDisease: "",
                isFirstDisease: false,
                isSecondDisease: false,
                isThirdDisease: false,
                isFourthDisease: false,
                isFifthDisease: false,
                otherNote: "",
              },
              examAbsenceReport: {
                patientInfo: state.selectedPatient,
                createdDate: createdDate,
                createdDateForm: createDateForm,
                examDate: formattedDate,
                shouldOpdAbsence: false,
                shouldIpdAbsence: false,
              },
              drivingReport: {
                patientInfo: state.selectedPatient,
                createdDate: createdDate,
                createdDateForm: createDateForm,
              },
              quarantineReport: {
                patientInfo: state.selectedPatient,
                createdDate: createdDate,
                createdDateForm: createDateForm,
                detectDate: formattedDate,
                shouldAbsence: false,
              },
              covidReport: {
                patientInfo: state.selectedPatient,
                createdDate: createdDate,
                createdDateForm: createDateForm,
                examDate: formattedDate,
                getAdvised: false,
                shouldAbsence: false,
              },
              travelReport: {
                patientInfo: state.selectedPatient,
                createdDate: createdDate,
                createdDateForm: createDateForm,
                examDate: formattedDate,
              },
            },
          });
        } else {
          controller.setState({
            DoctorCertificateSequence: {
              ...state.DoctorCertificateSequence,
              visaExtReport: {
                ...state.DoctorCertificateSequence?.visaExtReport,
                reportType: "ใบรับรองแพทย์สำหรับ Visa Extension",
                reportDuplicate: "ต้นฉบับ",
                reportLanguage: "ไทย",
                // Patient Info
                patientInfo: state.selectedPatient,
                doctorEncounter: state.selectedEncounter?.doctor || null,
                chiefComplaint: getChiefComplaint,
                diagnosis:
                  (diagVisaResp.principal_diagnosis?.[0]?.icd_code || "") +
                    " " +
                    (diagVisaResp.principal_diagnosis?.[0]?.icd_term || "") +
                    (diagVisaResp.principal_diagnosis?.[0]?.medical_description || "") +
                    (diagVisaResp.secondary_diagnosis?.[0]?.icd_code &&
                    diagVisaResp.secondary_diagnosis?.[0]?.icd_term &&
                    diagVisaResp.secondary_diagnosis?.[0]?.medical_description
                      ? "," +
                        (diagVisaResp.secondary_diagnosis?.[0]?.icd_code || "") +
                        " " +
                        (diagVisaResp.secondary_diagnosis?.[0]?.icd_term || "") +
                        (diagVisaResp.secondary_diagnosis?.[0]?.medical_description || "")
                      : "") || "",
                patientNationality: state.selectedPatient?.nationality,
                patientPassport: state.selectedPatient?.citizen_passport,
                doctorName: doctorName,
                doctorNameEn: doctorNameEn,
                doctorCertNumber: doctorCertNumber,
                printCount: 1,
                // Logic Value
                ageYear: patientAgeVisaExtReport[0],
                ageMonth: patientAgeVisaExtReport[1],
                ageDay: patientAgeVisaExtReport[2],
              },
              // Other Note
              deathReport: {
                patientInfo: state.selectedPatient,
                createdDate: createdDate,
                createdDateForm: createDateForm,
                deathDate: formattedDate,
              },
              fiveReport: {
                patientInfo: state.selectedPatient,
                createdDate: createdDate,
                createdDateForm: createDateForm,
                examDate: formattedDate,
                isFoundDisease: "",
                isFirstDisease: false,
                isSecondDisease: false,
                isThirdDisease: false,
                isFourthDisease: false,
                isFifthDisease: false,
                otherNote: "",
              },
              examAbsenceReport: {
                patientInfo: state.selectedPatient,
                createdDate: createdDate,
                createdDateForm: createDateForm,
                examDate: formattedDate,
                shouldOpdAbsence: false,
                shouldIpdAbsence: false,
              },
              drivingReport: {
                patientInfo: state.selectedPatient,
                createdDate: createdDate,
                createdDateForm: createDateForm,
              },
              quarantineReport: {
                patientInfo: state.selectedPatient,
                createdDate: createdDate,
                createdDateForm: createDateForm,
                detectDate: formattedDate,
                shouldAbsence: false,
              },
              covidReport: {
                patientInfo: state.selectedPatient,
                createdDate: createdDate,
                createdDateForm: createDateForm,
                examDate: formattedDate,
                getAdvised: false,
                shouldAbsence: false,
              },
              travelReport: {
                patientInfo: state.selectedPatient,
                createdDate: createdDate,
                createdDateForm: createDateForm,
                examDate: formattedDate,
              },
            },
          });
        }

        // Get Report
        Action(controller, {
          action: "GET_REPORT",
          codeForm: "CardVisaExtensionCertificate",
        });
        break;
      default:
        console.warn("Doesn't Exist!!");
        break;
    }
  } else if (params.action === "SAVE_REPORT") {
    // console.log("SAVE_REPORT Sequence");
    console.log("SAVE_REPORT Report Type: ", state.DoctorCertificateSequence?.reportOption);

    HandlePrintNSaveReport(controller, params);
  } else if (params.action === "GET_REPORT") {
    // console.log("GET_REPORT MAIN SEQUENCE");
    console.log("GET_REPORT Params: ", params);
    if (!params.codeForm) {
      return;
    }

    const reportList = await GetFormDataActionLogAllList(controller, {});

    // issue #69664
    const filterCertificateReportList = reportList.filter((item: any) =>
      state.DoctorCertificateSequence?.formCodeList?.includes(item.form_type_code)
    );

    state = controller.getState();

    controller.setState({
      DoctorCertificateSequence: {
        ...state.DoctorCertificateSequence,
        originalReportList: filterCertificateReportList,
        reportList: filterCertificateReportList,
      },
    });

    // console.log("GET_REPORT Filter Report: ", state.DoctorCertificateSequence?.filterReport);

    // console.log("GET_REPORT Report List (After): ", state.DoctorCertificateSequence?.reportList);

    // Have Restart Params from GET_REPORT ?
    if (params.restart === true) {
      Start(controller, {
        message: "From GET_REPORT Sequence",
      });
    }
  } else if (params.action === "PREVIEW_REPORT") {
    // console.log("PREVIEW_REPORT Sequence");
    // console.log("Preview selectPatient: ", state.selectedPatient);
    // console.log("Preview selectEncounter: ", state.selectedEncounter);
    // console.log("Preview selectEmr: ", state.selectedEmr);
    // console.log("Preview VitalSignInfo: ", state.vitalSignsInfo);
    // console.log("Preview EncounterVitalSign: ", state.encounterVitalSignList);
    // console.log("Preview VitalSign Weight: ", state.vitalSignsInfo?.items?.[1]?.formatted_string + " " + state.vitalSignsInfo?.items?.[1]?.unit)
    // console.log("Preview doctorDetail: ", doctorListResp);
    HandlePrintNSaveReport(controller, { ...params, save: false, preview: true });
  } else if (params.action === "REPRINT_REPORT") {
    // state = controller.getState();

    // console.log("REPRINT_REPORT Sequence");
    HandlePrintNSaveReport(controller, { ...params, reprint: true });
  } else if (params.action === "SELECT_DATA") {
    // console.log("SELECT_DATA Sequence");
    console.log("Select Report Type: ", state.DoctorCertificateSequence?.reportOption);
    console.log("SELECT_DATA params original reportType: ", params?.selectedReport);

    const [reportDetailResp] = await FormDataList.list({
      apiToken: controller.apiToken,
      params: {
        limit: 9999,
        encounter__patient: state.selectedEncounter?.patient,
      },
    });

    const reportSelected = reportDetailResp.items?.find(
      (item: any) => item.id === params?.selectedReport
    );
    // .map((item: any) => ({ ...item }));

    console.log("SELECT_DATA Raw Report: ", reportDetailResp);
    console.log("SELECT_DATA Report Map: ", reportSelected);
    console.log("SELECT_DATA Report Type: ", reportSelected.data?.reportType);

    const reportTypeMap = {
      ใบรับรองแพทย์สำหรับการเสียชีวิต: "CardDeathCertificate",
      "ใบรับรองแพทย์ 5 โรค": "CardFiveDiseasesCertificate",
      "ใบรับรองแพทย์สำหรับการตรวจ/ลางาน": "CardExamAbsenceCertificate",
      "ใบรับรองแพทย์สำหรับสมัคร/ต่อใบขับขี่": "CardDrivingCertificate",
      ใบรับรองการกักตัวหลังตรวจพบเชื้อโควิด: "CardQuarantineCertificate",
      ใบรับรองผลการตรวจโควิด: "CardCovidCertificate",
      ใบรับรองแพทย์สำหรับการเดินทาง: "CardTravelCertificate",
      "ใบรับรองแพทย์สำหรับ Visa Extension": "CardVisaExtensionCertificate",
    };

    const getReportType = (reportOption: string) => {
      // if (reportOption.endsWith("Report")) {
      //   return reportOption.replace("Report", "");
      // }
      return reportTypeMap[reportSelected.data?.reportType || ""];
    };

    const reportOption = state.DoctorCertificateSequence?.reportOption;
    const reportType = getReportType(reportOption);

    if (!reportType) {
      console.warn("Unsupported report type:", reportOption);

      return;
    }

    const reportKey = `${reportType.charAt(0).toLowerCase() + reportType.slice(1)}Report`;

    controller.setState({
      DoctorCertificateSequence: {
        ...state.DoctorCertificateSequence,
        reportOption: `${reportType}`,
        selectedReport: {
          ...reportSelected,
          logId: params.selectedReportId,
        },
        [reportKey]: {
          ...reportSelected.data,
        },
      },
    });
    // console.log("SELECT_DATA selected: ", state.DoctorCertificateSequence?.selectedReport?.id);
    // console.log("SELECT_DATA deathReport: ", state.DoctorCertificateSequence?.deathReport);
  } else if (params.action === "SEARCH_REPORT") {
    // console.log("SEARCH_REPORT Sequence");

    controller.setState({
      DoctorCertificateSequence: {
        ...state.DoctorCertificateSequence,
        // reportList: filteredList,
        searchReportLoading: true,
      },
    });
    const filter = state.DoctorCertificateSequence?.filterReport || {
      startDate: formattedDate,
      endDate: formattedDate,
      doctorName: "",
      reportLanguage: "",
    };

    const originalReportList = state.DoctorCertificateSequence?.originalReportList || [];

    const reportList =
      originalReportList > 0
        ? originalReportList
        : state.DoctorCertificateSequence?.reportList || [];

    // Check reportList after filtered
    // if(reportList.length === 0) {
    //   Action(controller, { action: "GET_REPORT" })
    // }

    // console.log("SEARCH Filter Report: ", filter);
    // console.log("SEARCH Original Report List: ", originalReportList);
    // console.log("SEARCH Report List: ", reportList);

    // Filter Doctor Name to Doctor ID
    const doctorFilterName = (input: any) => {
      if (input !== "") {
        if (state.selectedEncounter?.doctor_name.includes(input)) {
          return state.selectedEncounter?.doctor;
        } else {
          return 0;
        }
      }
      return state.selectedEncounter?.doctor;
    };

    // Call API
    const filterReport = await GetFormDataActionLogAllList(controller, {
      filter: {
        doctor: doctorFilterName(filter?.doctorName),
        start_date: filter?.startDate,
        end_date: filter?.endDate,
      },
    });

    // issue #69664
    const filterCertificateReportList = filterReport.filter((item: any) =>
      state.DoctorCertificateSequence?.formCodeList?.includes(item.form_type_code)
    );

    // console.log("SEARCH_MedCert: ", formFilterResp);
    // console.log("SEARCH_MedCert Map: ", filterReport);

    // console.log("SEARCH After Filtered: ", filteredList);

    controller.setState({
      DoctorCertificateSequence: {
        ...state.DoctorCertificateSequence,
        // reportList: filteredList,
        reportList: filterCertificateReportList,
        searchReportLoading: false,
      },
    });
  } else if (params.action === "CLEAR_REPORT") {
    // console.log("CLEAR_REPORT Sequence");

    if (params.callReport) {
      // Update Report
      Action(controller, {
        action: "GET_REPORT",
        codeForm: params.callReport,
        restart: true,
      });
    }

    Start(controller, {
      message: "From CLEAR_REPORT Sequence",
    });
  }
};

/* ------------------------------------------------------ */
/*                         Handler                        */
/* ------------------------------------------------------ */
const HandlePrintNSaveReport: Handler = async (controller, params) => {
  const state = controller.getState();

  const reportFormMap = {
    CardDeathCertificate: {
      formCode: "CardDeathCertificate",
      formName: "ใบรับรองแพทย์สำหรับการเสียชีวิต",
      pdfGenTh: FormDeathCertificate,
      pdfGenEn: FormDeathCertificateEN,
      data: state.DoctorCertificateSequence?.deathReport,
      formatter: formatDeathReport,
    },
    CardFiveDiseasesCertificate: {
      formCode: "CardFiveDiseasesCertificate",
      formName: "ใบรับรองแพทย์ 5 โรค",
      pdfGenTh: {
        normal: FormFiveDiseasesFoundCertificate,
        free: FormFiveDiseasesFreeCertificate,
      },
      pdfGenEn: {
        normal: FormFiveDiseasesFoundCertificateEN,
        free: FormFiveDiseasesFreeCertificateEN,
      },
      data: state.DoctorCertificateSequence?.fiveReport,
      formatter: formatFiveDiseasesReport,
    },
    CardExamAbsenceCertificate: {
      formCode: "CardExamAbsenceCertificate",
      formName: "ใบรับรองแพทย์สำหรับการตรวจ/ลางาน",
      pdfGenTh: FormExamAbsenceCertificate,
      pdfGenEn: FormExamAbsenceCertificateEN,
      data: state.DoctorCertificateSequence?.examAbsenceReport,
      formatter: formatExamAbsenceReport,
    },
    CardDrivingCertificate: {
      formCode: "CardDrivingCertificate",
      formName: "ใบรับรองแพทย์สำหรับสมัคร/ต่อใบขับขี่",
      pdfGenTh: FormDrivingCertificate,
      pdfGenEn: FormDrivingCertificateEN,
      data: state.DoctorCertificateSequence?.drivingReport,
      formatter: formatDrivingReport,
    },
    CardQuarantineCertificate: {
      formCode: "CardQuarantineCertificate",
      formName: "ใบรับรองการกักตัวหลังตรวจพบเชื้อโควิด",
      pdfGenTh: FormQuarantineCertificate,
      pdfGenEn: FormQuarantineCertificateEN,
      data: state.DoctorCertificateSequence?.quarantineReport,
      formatter: formatQuarantineReport,
    },
    CardCovidCertificate: {
      formCode: "CardCovidCertificate",
      formName: "ใบรับรองผลการตรวจโควิด",
      pdfGenTh: FormCovidCertificate,
      pdfGenEn: FormCovidCertificateEN,
      data: state.DoctorCertificateSequence?.covidReport,
      formatter: formatCovidReport,
    },
    CardTravelCertificate: {
      formCode: "CardTravelCertificate",
      formName: "ใบรับรองแพทย์สำหรับการเดินทาง",
      pdfGenTh: FormTravelCertificate,
      pdfGenEn: FormTravelCertificateEN,
      data: state.DoctorCertificateSequence?.travelReport,
      formatter: formatTravelReport,
    },
    CardVisaExtensionCertificate: {
      formCode: "CardVisaExtensionCertificate",
      formName: "ใบรับรองแพทย์สำหรับ Visa Extension",
      pdfGenTh: FormVisaExtensionCertificate,
      pdfGenEn: FormVisaExtensionCertificateEN,
      data: state.DoctorCertificateSequence?.visaExtReport,
      formatter: formatVisaExtReport,
    },
  };

  const selectedReportId = state.DoctorCertificateSequence?.selectedReport?.id;
  const reportOption = state.DoctorCertificateSequence?.reportOption || "";
  const reportForm = reportFormMap[reportOption];

  if (!reportForm) {
    console.error("Invalid report type");

    return;
  }

  const { isFreeDisease, error, ...formattedData } = await reportForm.formatter(reportForm.data, {
    nationality: state.masterOptions?.nationality,
    apiToken: controller.apiToken,
  });

  // * ถ้าไม่ส่ง save = false จะทำการบันทึก Form data
  if (params.save !== false) {
    if (error) {
      controller.setState({
        errorMessage: { ...state.errorMessage, [params.sequence]: error },
      });

      return;
    }

    const printCount = formattedData.printCount || 0;

    const formData = {
      action: "SAVE",
      data: {
        ...formattedData,
        ...(params.reprint && { printCount: printCount + 1 }),
      },
      encounter: state.selectedEncounter?.id,
      doctor: state.selectedEncounter?.doctor,
      form_code: reportForm.formCode,
      form_name: reportForm.formName,
      form_version: "1.0",
    };

    const response = selectedReportId
      ? await FormDataDetail.update({
          pk: selectedReportId,
          data: formData,
          extra: { division: controller.data.division },
          apiToken: controller.apiToken,
        })
      : await FormDataList.create({
          apiToken: controller.apiToken,
          data: formData,
          extra: { division: controller.data.division },
        });

    if (response[1]) {
      controller.setState({
        errorMessage: { ...state.errorMessage, [params.sequence]: response[1] },
      });

      return;
    }
  }

  const language = reportForm.data?.reportLanguage || "ไทย";

  let docDef: any = { content: [] };

  if (["ไทย", "EN"].includes(language)) {
    const pdfGen = language === "ไทย" ? reportForm.pdfGenTh : reportForm.pdfGenEn;
    const pdfGenerator = isFreeDisease ? pdfGen.free : pdfGen.normal || pdfGen;

    docDef = await pdfGenerator({
      items: { ...formattedData, dayDuration: formattedData.dayDuration || " " },
    });
    const headerAdder = language === "ไทย" ? AddHeaderMedCert : AddHeaderMedCertEN;
    docDef = await headerAdder(docDef, reportForm.data?.createdDateForm, disableHeaderForm);
  } else {
    console.error("Not Found Language of Form");
  }

  const pdfMake = (await getPdfMake(true)).createPdf(docDef);

  if (params.preview) {
    pdfMake.open();
  } else {
    pdfMake.print();
  }

  Action(controller, {
    action: "CLEAR_REPORT",
    callReport: reportForm.formCode,
  });
};

/* ------------------------------------------------------ */
/*                          APIS                          */
/* ------------------------------------------------------ */
const GetFormDataActionLogAllList: Handler = async (controller, params) => {
  const state = controller.getState();

  const [result] = await FormDataActionLogAllList.list({
    apiToken: controller.apiToken,
    params: {
      limit: 9999,
      patient: state.selectedEncounter?.patient,
      doctor: state.selectedEncounter?.doctor,
      start_date: state.DoctorCertificateSequence?.filterReport?.startDate,
      end_date: state.DoctorCertificateSequence?.filterReport?.endDate,
      ...params.filter,
    },
  });

  const items: any[] = result?.items || [];

  return [...new Map(items.map((item) => [item.statable, item])).values()];
};

/* ------------------------------------------------------ */
/*                         Formate                        */
/* ------------------------------------------------------ */
const formatDeathReport = (data: any) => {
  return {
    ...data,
    formatCreatedDate: formatCreatedDate(data?.createdDate)[0],
    formatCreatedTime: formatCreatedDate(data?.createdDate)[1],
  };
};

const formatFiveDiseasesReport = (data: any) => {
  const count = countDisease(data);
  const isFreeDisease = count.length === 0 && data?.isFoundDisease === "Free";

  return {
    ...data,
    count,
    isFreeDisease,
    formatCreatedDate: formatCreatedDate(data?.createdDate)[0],
    formatCreatedTime: formatCreatedDate(data?.createdDate)[1],
  };
};

const formatExamAbsenceReport = (data: any) => {
  if (data.opdEndDate && !data.opdStartDate) {
    data.opdStartDate = formatDate(moment());
  }

  const visitTypeInfo = visitTypeCheck(data);
  const error =
    data.opdStartDate && !data.opdEndDate
      ? {
          ระยะเวลาที่ควรพักงาน: ["กรุณาระบุวันสิ้นสุด"],
        }
      : null;

  return {
    ...data,
    dayDuration: visitTypeInfo[3],
    patientExamDate: visitTypeInfo[4],
    patientVisitType: visitTypeInfo[0],
    patientAbsenceStart: visitTypeInfo[1],
    patientAbsenceEnd: visitTypeInfo[2],
    formatCreatedDate: formatCreatedDate(data?.createdDate)[0],
    formatCreatedTime: formatCreatedDate(data?.createdDate)[1],
    error,
  };
};

const formatDrivingReport = (data: any) => {
  const patientAddress = data?.patientInfo?.present_address || {};
  const examDate = data?.examDate ? getSliceDate(data.examDate) : ["", "", "", ""];

  return {
    ...data,
    checkAddressNo: undefinedChecker(patientAddress.no),
    checkAddressName: undefinedChecker(patientAddress.name),
    checkAddressTown: undefinedChecker(patientAddress.town),
    checkAddressRoad: undefinedChecker(patientAddress.road),
    checkAddressCity: undefinedChecker(patientAddress.city_label),
    checkAddressDistrict: undefinedChecker(patientAddress.district_label),
    checkAddressProvince: undefinedChecker(patientAddress.province_label),
    checkAddressCityEn: undefinedChecker(patientAddress.city_en_label),
    checkAddressDistrictEn: undefinedChecker(patientAddress.district_en_label),
    checkAddressProvinceEn: undefinedChecker(patientAddress.province_en_label),
    formatIdentification: formatIdentification(data?.patientInfo?.citizen_no),
    examDay: examDate[0],
    examMonth: examDate[2],
    examMonthEn: THAI_MONTHS[examDate[2]],
    examYear: examDate[3],
    formatCreatedDate: formatCreatedDate(data?.createdDate)[0],
    formatCreatedTime: formatCreatedDate(data?.createdDate)[1],
  };
};

const formatQuarantineReport = (data: any) => {
  return {
    ...data,
    formatCreatedDate: formatCreatedDate(data?.createdDate)[0],
    formatCreatedTime: formatCreatedDate(data?.createdDate)[1],
  };
};

const formatCovidReport = (data: any) => {
  return {
    ...data,
    formatCreatedDate: formatCreatedDate(data?.createdDate)[0],
    formatCreatedTime: formatCreatedDate(data?.createdDate)[1],
  };
};

const formatTravelReport = (data: any) => {
  const formattedSpecialRequire = formatSpecialRequire(data?.specialRequire);

  return {
    ...data,
    formatCreatedDate: formatCreatedDate(data?.createdDate)[0],
    formatCreatedTime: formatCreatedDate(data?.createdDate)[1],
    requireFirstLine: formattedSpecialRequire[0],
    requireSecondLine: formattedSpecialRequire[1],
    requireThirdLine: formattedSpecialRequire[2],
  };
};

const formatVisaExtReport = async (data: any, params: any) => {
  const getNationalityText = params?.nationality
    ? params.nationality.find((item: any) => item.value === data?.patientNationality)?.text
    : " ";

  const [doctorDetailResp] = await DoctorDetail.retrieve({
    apiToken: params.apiToken,
    pk: data?.doctorEncounter,
  });

  const doctorData = doctorDetailResp || {};
  const doctorNameTh = doctorData ? `${doctorData.full_name}` : " ";
  const doctorNameEn = doctorData ? `${doctorData.first_name_en} ${doctorData.last_name_en}` : " ";

  const { nationEn, nationTh } = splitNationality(getNationalityText);

  return {
    ...data,
    formatCreatedDate: formatCreatedDate(data?.createdDate)[0],
    formatCreatedTime: formatCreatedDate(data?.createdDate)[1],
    patientNationalityText: getNationalityText,
    patientNationalityTextEn: nationEn,
    patientNationalityTextTh: nationTh,
    doctorNameFormTh: doctorNameTh,
    doctorNameFormEn: doctorNameEn,
  };
};

/* ------------------------------------------------------ */
/*                          Utils                         */
/* ------------------------------------------------------ */
const splitNationality = (text: string) => {
  if (!text) {
    return { nationEn: "", nationTh: "" };
  }

  const parts = text.split(" ");
  const nationEn = parts[0].trim();
  const nationTh = parts[parts.length - 1].trim();

  return { nationEn, nationTh };
};

const formatSpecialRequire = (require: string[] | null) => {
  if (!require) {
    return [" ", " ", " "];
  }

  const result = [];
  let currentLine = "";

  for (let i = 0; i < require.length; i++) {
    if (currentLine === "") {
      currentLine = require[i];
    } else {
      currentLine += `, ${require[i]}`;
    }

    if ((i + 1) % 4 === 0 || i === require.length - 1) {
      result.push(currentLine);
      currentLine = "";
    }
  }

  while (result.length < 3) {
    result.push(" ");
  }

  return result;
};

const visitTypeCheck = (data: any) => {
  // VisitType
  const patientVisitType = data?.patientInfo?.encounter_type;

  // [0] = "visitType Text", [1] = "visitType Start Date", [2] = "visitType End Date", [3] = "day Durations", [4] = "Exam Date"
  switch (patientVisitType) {
    case "OPD":
      return [
        ["ผู้ป่วยนอก", "Outpatient"],
        data?.opdStartDate,
        data?.opdEndDate,
        dayDurationCal(data?.opdStartDate, data?.opdEndDate),
        [data?.examDate, data?.examDate],
      ];
    case "IPD":
      return [
        ["ผู้ป่วยใน", "Inpatient"],
        data?.ipdStartDate,
        data?.ipdEndDate,
        dayDurationCal(data?.ipdStartDate, data?.ipdEndDate),
        [
          `${data?.admitStartDate} ถึง ${data?.admitEndDate}`,
          `${data?.admitStartDate} to ${data?.admitEndDate}`,
        ],
      ];
    default:
      return [["", ""], "", "", 0, ["", ""]];
  }
};

// Count Diseases
const countDisease = (data: any) => {
  const diseasesArray = [
    data?.isFirstDisease,
    data?.isSecondDisease,
    data?.isThirdDisease,
    data?.isFourthDisease,
    data?.isFifthDisease,
  ];
  return Array.from(
    { length: diseasesArray.filter(Boolean).length },
    (_, index) => `${index + 1}.`
  );
};

// Format Created Date
const formatCreatedDate = (date: any) => {
  // [0] = Date, [1] = Time
  return [date.slice(0, 10), date.slice(12, 17)];
};

// Calculate Day Duration between Start Absence to End Absence
const dayDurationCal = (inputStartDate: string, inputEndDate?: string): number | string => {
  if (!(inputStartDate && inputEndDate)) {
    return "";
  }

  const startDate = moment(beToAd(inputStartDate));
  const endDate = moment(beToAd(inputEndDate));

  // Format Date must be "YYYY-MM-DD" before
  return moment.duration(moment(endDate).diff(moment(startDate))).asDays() + 1; // include start date
};
