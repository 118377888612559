import React, { useEffect, useState } from "react";

import { Dimmer, Loader } from "semantic-ui-react";

import moment from "moment";

// UX
import CardExamAbsenceCertificateUX from "./CardExamAbsenceCertifiateUX";

// Interface
import { State } from "./sequence/DoctorCertificate";

// Utils
import { beToAd, formatDate } from "react-lib/utils/dateUtils";

// Main Props
type CardExamAbsenceCertificateProps = {
  // Function
  setProp: any;

  // Options
  masterOptions?: any;

  // Sequence
  runSequence?: any;
  DoctorCertificateSequence: State["DoctorCertificateSequence"];
};

// Main
const CardExamAbsenceCertificate = (props: CardExamAbsenceCertificateProps) => {
  // log Props
  // console.log("CardExamAbsenceCertificate Props: ", props.DoctorCertificateSequence);

  // Call Sequence ( Exam/Absence Certificate )
  useEffect(() => {
    props.runSequence({
      sequence: "DoctorCertificate",
      action: "FETCH_CERTIFICATE",
      fetchType: "CardExamAbsenceCertificate",
    });
  }, []);

  // Set State
  const [isLoading, setIsLoading] = useState(false);

  // Data Check [add chief complaint]
  useEffect(() => {
    const hasChiefComplaint =
      props.DoctorCertificateSequence?.examAbsenceReport?.chiefComplaint !==
      null;
    const hasDiagnosis =
      props.DoctorCertificateSequence?.examAbsenceReport?.diagnosis !== null;
    const hasPatientInfo =
      Object.keys(
        props.DoctorCertificateSequence?.examAbsenceReport?.patientInfo
      ).length > 0;
    const hasDoctorCertNumber =
      props.DoctorCertificateSequence?.examAbsenceReport?.doctorCertNumber !==
      null;

    if (
      hasChiefComplaint &&
      hasDiagnosis &&
      hasPatientInfo &&
      hasDoctorCertNumber
    ) {
      setIsLoading(false);
    } else {
      setIsLoading(true);
    }
  }, [
    props.DoctorCertificateSequence?.examAbsenceReport?.chiefComplaint,
    props.DoctorCertificateSequence?.examAbsenceReport?.diagnosis,
    props.DoctorCertificateSequence?.examAbsenceReport?.patientInfo,
    props.DoctorCertificateSequence?.examAbsenceReport?.doctorCertNumber,
  ]);

  // Change Text
  const handleChangeText = (
    name: any,
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    switch (name) {
      case "chiefComplaint":
      case "diagnosis":
      case "comment":
        props.setProp(
          `DoctorCertificateSequence.examAbsenceReport.${name}`,
          e.target.value
        );
        break;
      default:
        console.log("Doesn't Exist!!");
        break;
    }
  };

  // Change Date
  const handleChangeDate = async (name: any, date: string) => {
    const examAbsenceReport = props.DoctorCertificateSequence?.examAbsenceReport || {};

    if (date && name === "opdStartDate" && !examAbsenceReport.opdEndDate) {
      const inputDate = beToAd(date);
      const currentDate = moment();

      const updatedDate = inputDate?.isBefore(currentDate) ? currentDate : inputDate;

      const formattedDate = formatDate(updatedDate);

      await props.setProp("DoctorCertificateSequence.examAbsenceReport.opdEndDate", formattedDate);
    }

    // console.log("Key: ", name + " Date: ", date);
    props.setProp(`DoctorCertificateSequence.examAbsenceReport.${name}`, date);
  };

  // Select Radio ( OPD or IPD ) [Logic ถ้า Radio เป็น Type อะไรให้อีก Type เป็น disabled]
  // console.log("ExAb Patient Type: ", props.DoctorCertificateSequence?.examAbsenceReport?.patientInfo?.isVisitType);
  const handleCheckRadio = () => {
    switch (
      props.DoctorCertificateSequence?.examAbsenceReport?.patientInfo
        ?.encounter_type
    ) {
      case "OPD":
        if (
          props.DoctorCertificateSequence?.examAbsenceReport?.admitStartDate ||
          props.DoctorCertificateSequence?.examAbsenceReport?.admitEndDate
        ) {
          props.setProp(
            `DoctorCertificateSequence.examAbsenceReport.admitStartDate`,
            ""
          );
          props.setProp(
            `DoctorCertificateSequence.examAbsenceReport.admitEndDate`,
            ""
          );
        }
        return {
          disableOpd: false,
          disableIpd: true,
        };
      case "IPD":
        if (props.DoctorCertificateSequence?.examAbsenceReport?.examDate) {
          props.setProp(
            `DoctorCertificateSequence.examAbsenceReport.examDate`,
            ""
          );
        }
        return {
          disableOpd: true,
          disableIpd: false,
        };
      default:
        return {
          disableOpd: true,
          disableIpd: true,
        };
    }
  };

  // Checkbox Check
  const handleCheckCheckbox = (name: string) => {
    switch (name) {
      case "shouldOpdAbsence":
      case "shouldIpdAbsence":
        return props.DoctorCertificateSequence?.examAbsenceReport?.[name];
      default:
        console.log("Doesn't Exist!!");
        break;
    }
  };

  // Change Checkbox OPD/IPD
  const handleOpdCheckbox = (e: any, name: string, data: any) => {
    if (data.type === "checkbox") {
      var value = data.checked;
      props.setProp(
        `DoctorCertificateSequence.examAbsenceReport.${name}`,
        value
      );
    }
  };

  // Enable/Disable Date in OPD/IPD
  // console.log("ExAb shouldOpdAbsence: ", props.DoctorCertificateSequence?.examAbsenceReport?.shouldOpdAbsence);
  const handleDateDisable = (name: any) => {
    switch (name) {
      case "OPD":
        if (
          props.DoctorCertificateSequence?.examAbsenceReport
            ?.shouldOpdAbsence === true
        ) {
          return false;
        } else if (
          props.DoctorCertificateSequence?.examAbsenceReport
            ?.shouldOpdAbsence === false
        ) {
          if (
            props.DoctorCertificateSequence?.examAbsenceReport?.opdStartDate &&
            props.DoctorCertificateSequence?.examAbsenceReport?.opdEndDate
          ) {
            props.setProp(
              `DoctorCertificateSequence.examAbsenceReport.opdStartDate`,
              ""
            );
            props.setProp(
              `DoctorCertificateSequence.examAbsenceReport.opdEndDate`,
              ""
            );
          }
          return true;
        } else {
          return false;
        }
      case "IPD":
        if (
          props.DoctorCertificateSequence?.examAbsenceReport
            ?.shouldIpdAbsence === true
        ) {
          return false;
        } else if (
          props.DoctorCertificateSequence?.examAbsenceReport
            ?.shouldIpdAbsence === false
        ) {
          if (
            props.DoctorCertificateSequence?.examAbsenceReport?.ipdStartDate &&
            props.DoctorCertificateSequence?.examAbsenceReport?.ipdEndDate
          ) {
            props.setProp(
              `DoctorCertificateSequence.examAbsenceReport.ipdStartDate`,
              ""
            );
            props.setProp(
              `DoctorCertificateSequence.examAbsenceReport.ipdEndDate`,
              ""
            );
          }
          return true;
        } else {
          return false;
        }
      default:
        console.log("Doesn't Exist!!");
        break;
    }
  };

  // Condition Close Button
  // const clearSelectedData = () => {
  //   // Set selectedReport = null
  //   props.setProp(`DoctorCertificateSequence.selectedReport`, null);

  //   // Re-Fetch
  //   props.runSequence({
  //     sequence: "DoctorCertificate",
  //     action: "CLEAR_REPORT",
  //     callReport: "CardExamAbsenceCertificate",
  //   });
  // };

  // const closeSelectedCheck = (reportSelected: any) => {
  //   if (reportSelected != null) {
  //     return (
  //       <Button
  //         color="red"
  //         style={{ width: "8em" }}
  //         onClick={clearSelectedData}
  //       >
  //         ปิด
  //       </Button>
  //     );
  //   } else {
  //     return null;
  //   }
  // };

  // Disable when select FiveForm
  const handleDisableForm = (selected: any, reportType?: any) => {
    if (selected == null) {
      return false;
    } else if (
      selected !== null &&
      reportType === "ใบรับรองแพทย์สำหรับการตรวจ/ลางาน"
    ) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <>
      <CardExamAbsenceCertificateUX
        // Chief Complaint
        onChief={
          props.DoctorCertificateSequence?.examAbsenceReport?.chiefComplaint ||
          ""
        }
        onHandleChangeText={handleChangeText}
        // Diagnosis
        onDiagnosis={
          props.DoctorCertificateSequence?.examAbsenceReport?.diagnosis || ""
        }
        // Comment
        onComment={
          props.DoctorCertificateSequence?.examAbsenceReport?.comment || ""
        }
        // OPD Radio
        onOpdRadio={
          props.DoctorCertificateSequence?.examAbsenceReport?.patientInfo
            ?.encounter_type === "OPD"
        }
        // IPD Radio
        onIpdRadio={
          props.DoctorCertificateSequence?.examAbsenceReport?.patientInfo
            ?.encounter_type === "IPD"
        }
        // Examination date ( Open OPD Date as Default )
        examDate={
          props.DoctorCertificateSequence?.examAbsenceReport?.examDate || ""
        }
        onHandleChangeDate={handleChangeDate}
        // Min Date
        onExamMinDate={moment().subtract(1, "days")}
        // Radio Option Check
        onHandleCheckRadio={handleCheckRadio()}
        // Checkbox Check
        onHandleCheckCheckbox={handleCheckCheckbox}
        // Checkbox OPD/IPD
        onHandleOpdCheckbox={handleOpdCheckbox}
        // Enable/Disable Date OPD/IPD
        onHandleDateDisable={handleDateDisable}
        // OPD Absence Start Date
        opdAbsenceStartDate={
          props.DoctorCertificateSequence?.examAbsenceReport?.opdStartDate || ""
        }
        // OPD Absence End Date
        opdAbsenceEndDate={
          props.DoctorCertificateSequence?.examAbsenceReport?.opdEndDate || ""
        }
        // Admit Start Date
        admitStartDate={
          props.DoctorCertificateSequence?.examAbsenceReport?.admitStartDate ||
          ""
        }
        // Admit End Date
        admitEndDate={
          props.DoctorCertificateSequence?.examAbsenceReport?.admitEndDate || ""
        }
        // IPD Absence Start Date
        ipdAbsenceStartDate={
          props.DoctorCertificateSequence?.examAbsenceReport?.ipdStartDate || ""
        }
        // IPD Absence End Date
        ipdAbsenceEndDate={
          props.DoctorCertificateSequence?.examAbsenceReport?.ipdEndDate || ""
        }
        // Close Button When Selected Report
        // onCloseSelected={closeSelectedCheck(
        //   null
        // )}
        // Disable Input
        // onHandleDisableForm={handleDisableForm(
        //   null,
        //   props.DoctorCertificateSequence?.selectedReport?.data?.reportType
        // )}
      />
      <Dimmer active={isLoading} inverted>
        <Loader inverted>Loading</Loader>
      </Dimmer>
    </>
  );
};

export default CardExamAbsenceCertificate;
