import WasmController from "react-lib/frameworks/WasmController";

// CORE
import PatientListOptimized from "issara-sdk/apis/EncounterPatientListOptimized_core";
import ProgressionCycleList from "issara-sdk/apis/ProgressionCycleList_core";
import EncounterDetail from "issara-sdk/apis/EncounterDetail_core";
import EncounterMedicalRecordList from "issara-sdk/apis/EncounterMedicalRecordList_core";
import DoctorDetail from "issara-sdk/apis/DoctorDetail_core";
import DoctorList from "issara-sdk/apis/DoctorList_core";
import UserLoginView from "issara-sdk/apis/UserLoginView_core";
import EncounterActionOnly from "issara-sdk/apis/EncounterActionOnly_core";
import ProgressionCycleDetail from "issara-sdk/apis/ProgressionCycleDetail_core";
import DivisionList from "issara-sdk/apis/DivisionList_core";
// REG
import PatientDetailView from "issara-sdk/apis/PatientDetailView_apps_REG";
import ConnectUserWithPatient from "issara-sdk/apis/ConnectUserWithPatient_apps_REG";
// PTM
import FormDataLatest from "issara-sdk/apis/FormDataLatest_apps_PTM";
import VitalSignValueList from "issara-sdk/apis/VitalSignValueList_apps_PTM";
import FormScreenList from "issara-sdk/apis/FormScreenList_apps_PTM";
// DPO
import ProblemListList from "issara-sdk/apis/ProblemListList_apps_DPO";
import PatientEMREdit from "issara-sdk/apis/PatientEMREdit_apps_DPO";
import DoctorCheckoutProgressionCycle from "issara-sdk/apis/DoctorCheckoutProgressionCycle_apps_DPO";
// ADM
import AdmitOrderList from "issara-sdk/apis/AdmitOrderList_apps_ADM";
import AdmitOrderRoomItemDetail from "issara-sdk/apis/AdmitOrderRoomItemDetail_apps_ADM";
// PRX
import ProxyPatientHasDivisionChatDetail from "issara-sdk/apis/ProxyPatientHasDivisionChatDetail_apps_PRX";
import PatientAppointmentTelePharmacyList from "issara-sdk/apis/PatientAppointmentTelePharmacyList_apps_PRX";
import PatientDivisionChatChannelList from "issara-sdk/apis/PatientDivisionChatChannelList_apps_PRX";
import ClassifyList from "issara-sdk/apis/ClassifyList_apps_PRX";
import MessageTemplateList from "issara-sdk/apis/MessageTemplateList_apps_PRX";
// DPI
import MedicalRecordDischarge from "issara-sdk/apis/MedicalRecordDischarge_apps_DPI";
// DPO
import DoctorDischargePatient from "issara-sdk/apis/DoctorDischargePatient_apps_DPO";
import ProblemListDetail from "issara-sdk/apis/ProblemListDetail_apps_DPO";
import DoctorConsultAppointmentList from "issara-sdk/apis/DoctorConsultAppointmentList_apps_DPO";
import PatientEMRList from "issara-sdk/apis/PatientEMRList_apps_DPO";
import CheckApproveView from "issara-sdk/apis/CheckApproveView_apps_DPO";
// ADR
import DrugLabInteractionList from "issara-sdk/apis/DrugLabInteractionList_apps_ADR";
// QUE
import PatientAppointmentFinishConsult from "issara-sdk/apis/PatientAppointmentFinishConsult_apps_QUE";
// TPD
import TelepharDrugOrderUpdateCallStatus from "issara-sdk/apis/TelepharDrugOrderUpdateCallStatus_apps_TPD";
import TelepharDrugOrderSummaryListView from "issara-sdk/apis/TelepharDrugOrderSummaryListView_apps_TPD";
import DrugOrderDetailForAction from "issara-sdk/apis/DrugOrderDetailForAction_apps_TPD";
// IME
import ImagingOrderList from "issara-sdk/apis/ImagingOrderList_apps_IME";
// USERs
import UserListM from "issara-sdk/apis/UserList_usersM";
import UserEmployeeDetailAPIView from "issara-sdk/apis/UserEmployeeDetailAPIView_users";
import UserDetailAPIViewM from "issara-sdk/apis/UserDetailAPIViewM";
// MSD
import SupplyOrderDetail from "issara-sdk/apis/SupplyOrderDetail_apps_MSD";
// IME
import ImagingOrderDetail from "issara-sdk/apis/ImagingOrderDetail_apps_IME";

// Common
import PatientData from "react-lib/apps/common/PatientData";

// Interface
import { HandleGetProfileImage, HandleGetProfileInfo } from "../REG/sequence/SetPatient";
import { getSideMenuIndex } from "./CommonInterface";

// Config
import CONFIG from "../../../../config/config";

export type State = {
  django?: any;
  openDischargeReassessment?: any;
  loadingStatus?: any;
  selectedAdmitOrderRoomItem?: any;
  errorMessage?: any;
  divisionType?: string | null;
  encounterList?: any[];
  encounterDetailDict?: any;
  hasAdmitOrder?: boolean;
  selectedPatient?: any;
  patientADR?: any;
  selectedEncounter?: any | null;
  selectedEmr?: any | null;
  selectedImaging?: any | null;
  selectedProgressCycle?: any | null;
  patientData?: PatientData | null;
  chatDetail?: any;
  patientAssessment?: any;
  weightInfo?: any;
  heightInfo?: any;
  TriageSequence?: any;
  AssessmentSequence?: any;
  selectedRecordViewIndex?: any;
  vitalSignsInfo?: any;
  problemListDPO?: any;
  messageProblemDPO?: any;
  formScreen?: any[];
  LabResult?: any;
  SetPatientSequence?: any;
  selectedShippingOrder?: any;
  encounter?: number | null;
  chatMessageTemplateOptions?: any;
  classifyList?: any[];
  goToMenu?: string;
  goToTab?: string;
  dischargeDoctor?: any;
  loginVia?: boolean;
  approve_by?: any;
  medReconcileCheck?: any;
  isStudentUser?: boolean;
  patientEmr?: any;
  checkApprove?: any;
  masterOptions?: any;
  abortControllerEncounter?: Record<string, AbortController[]>;
  keysBeforeEncounter?: string[];
  goBackEncounterState?: any;
  goBackAppointmentState?: any;
  goToCreateAppointment?: any;
  divisionOrganization?: any[];
};

export const StateInitial: State = {
  encounterList: [],
  encounterDetailDict: {},
  hasAdmitOrder: false,
  selectedEncounter: null,
  selectedImaging: null,
  selectedEmr: null,
  selectedProgressCycle: null,
  chatDetail: null,
  formScreen: [],
  chatMessageTemplateOptions: [
    // {
    //   key: 1,
    //   text: "แจ้งเตือนใกล้ถึงคิวรับบริการ",
    //   value: 1,
    //   message:
    //     "ขณะนี้ใกล้ถึงคิวรับบริการของท่านแล้ว\nกรุณามารอรับบริการที่หน้าห้องตรวจ",
    // },
    {
      key: "OTHER",
      text: "อื่นๆ",
      value: "OTHER",
      message: "",
    },
  ],
  classifyList: [],
  dischargeDoctor: {
    // userId: null,
    username: "",
    password: "",
  },
  approve_by: null,
  medReconcileCheck: null,
  checkApprove: {
    can_checkout: false,
    action: "",
  },
};

export type Event =
  | { message: "GetPatientQueue"; params: {} }
  | { message: "SelectEncounter"; params: { encounter: any } }
  | { message: "GetDrugOrderList"; params: { emr_id: number } }
  | { message: "GetDrugOrderQueue"; params: any }
  | { message: "GetLabOrderQueue"; params: any }
  | { message: "GetUsernameForDischarge"; params: any }
  | { message: "GetDoctorSearch"; params: any }
  | { message: "CheckUserLogin"; params: any }
  | {
      message: "GetProblemList";
      params: {
        patient: number;
        is_active: boolean;
      };
    }
  | { message: "GetPatientDetailView"; params: any }
  // Discharge
  | { message: "HandleSaveMedicalRecordDischarge"; params: any }
  | {
      message: "UpdateEncounterStatus";
      params: {
        refresh?: boolean;
        target: string;
        current: string;
        id: number;
        emrId: number;
        doctorId: number;
      };
    }
  | { message: "DrugOrderUpdateCallTelephar"; params: { id: number; data: {} } }
  | { message: "HandleRefreshEncounter"; params: any }
  | { message: "HandleDischargeConsult"; params: any }
  // Classify
  | { message: "GetClassify"; params: any }
  // ProblemDPO
  | { message: "HandleAddMessageProblemDPO"; params: any }
  | { message: "HandleCheckApprove"; params: any }
  // DoctorOrder
  | { message: "HandleGetDoctorOrder"; params: any }
  | { message: "HandleGoToCreateAppointment"; params: any }
  | { message: "GetDivisionByOrganization"; params: any }
  | { message: "HandleGoBackEncounter"; params: any }
  | { message: "HandleGoBackAppointment"; params: any };

export type Data = {
  division?: number;
  divisionDict?: {};
  user?: number;
  masterData?: Record<string, any[]>;
};

export const DataInitial = {};

type Handler = (controller: WasmController<State, Event, Data>, params?: any) => any;

export const GetProblemList: Handler = async (controller, params) => {
  return await ProblemListList.list({
    params: params,
    apiToken: controller.apiToken,
  });
};

export const GetPatientQueue: Handler = async (controller, params) => {
  const state = controller.getState();
  controller.setState({
    loadingStatus: true,
  });
  // console.log("ประเภทหน่วยงาน:", state.divisionType || "ไม่ทราบ");
  if (
    [
      "หน่วยตรวจ",
      "หอผู้ป่วย",
      "ห้องผ่าตัด",
      "พื้นที่ดูแล",
      "คลินิกพรีเมี่ยม",
      "หอผู้ป่วยพรีเมี่ยม",
    ].includes(state.divisionType || "")
  ) {
    const encounterList = await PatientListOptimized.get({
      // const encounterList = await PatientQueueList.get({
      apiToken: controller.apiToken,
      params: {
        from_date: params.start_date,
        to_date: params.end_date,
        doctor: params.doctor?.id || null,
        patient__hn: params.hn || null,
        ...(params.first_name && { first_name: params.first_name }),
        ...(params.last_name && { last_name: params.last_name }),
        ...(params.patientName && { patient_name: params.patientName }),
        include_student: params.include_student,
        // patient_name: params.first_name || params.last_name || null,
        division: params.division || null,
        status: params.status || null,
        exclude_canceled: true,
        triage_level: params.triage_level,

        // telephar
        // ...(params.deliverNo && { delivery_no: params.deliverNo }),
        // ...(params.patientType && { patient_type: params.patientType }),
        // ...(params.deliveryType && { delivery_type: params.deliveryType }),
        // ...(params.deliveryStatus && {
        //   delivery_status: params.deliveryStatus,
        // }),
        // ...(params.consultStatus && { consult_status: params.consultStatus }),
      },
      extra: {
        division: controller.data.division,
      },
    });

    const encounterItems = await GetDrugOrderEncounterItems(controller, {
      items: encounterList[0]?.items || [],
    });

    controller.setState(
      {
        encounterList: encounterItems,
        loadingStatus: false,
      },
      async () => {
        const state = controller.getState();

        const divisionPatientList = (state.encounterList || [])
          .filter((item) => item.division)
          .map((item) => [item.division, item.patient]);

        const listOfAllDivision = Array.from(
          new Set((divisionPatientList || [])?.map((d: any) => d?.[0]))
        );
        // const listOfAllDivisionPromise = listOfAllDivision.map(
        //   (divisionId: number) =>
        //     DoctorConsultAppointmentList.list({
        //       apiToken: controller.apiToken,
        //       params: {
        //         division: divisionId,
        //         consult_user: controller.data.user,
        //       },
        //     })
        // );

        const [
          admitOrder,
          patientChat,
          messageTemplate,
          // ...consultOrders
        ] = await Promise.all([
          AdmitOrderList.list({
            params: {},
            apiToken: controller.apiToken,
          }),
          PatientDivisionChatChannelList.post({
            apiToken: controller.apiToken,
            data: { division_patient: divisionPatientList },
          }),
          MessageTemplateList.list({
            apiToken: controller.apiToken,
            params: {
              divisions: params.divisions,
            },
          }),
          // ...listOfAllDivisionPromise,
        ]);

        // let consultOrder = consultOrders.flatMap((c: any) => c[0]?.items);

        // Get additional details and store in encounterDetailDict
        console.log(admitOrder[1] ? admitOrder[1] : admitOrder[0]);
        let detailDict = state.encounterDetailDict;

        detailDict.admitorder = admitOrder[1]
          ? {}
          : admitOrder[0]?.items.reduce((acc: any, cur: any) => {
              if (!Object.keys(acc).includes(cur.encounter)) acc[cur.encounter] = [];
              acc[cur.encounter].push(cur);
              return acc;
            }, {});

        detailDict.admitorderfrom = admitOrder[1]
          ? {}
          : admitOrder[0]?.items.reduce((acc: any, cur: any) => {
              if (!Object.keys(acc).includes(cur.encounter_opd)) acc[cur.encounter_opd] = [];
              acc[cur.encounter_opd].push(cur);
              return acc;
            }, {});

        const filterMessages: any[] = (messageTemplate[0]?.items || []).filter(
          (item: any) => item.active && item.divisions.includes(controller.data.division)
        );

        const messageTemplateData = filterMessages.map((item) => ({
          key: item.id + 2,
          text: item.header,
          value: item.id + 2,
          message: item.detail,
        }));

        controller.setState({
          encounterDetailDict: detailDict,
          encounterList: (state.encounterList || []).map((item) => {
            const divisionPatient =
              patientChat[0]?.find(
                (acc: any) => acc.patient_id === item.patient && acc.division === item.division
              ) || null;

            // order อื่นๆ จะไม่แสดงในหน้า PatientQueue
            // let doctor_orders = (item.doctor_orders || []).map((order: any) => {
            //   let consultMatchOrderEncounter =
            //     [...(consultOrder || [])].find(
            //       (acc: any) =>
            //         acc?.order_encounter === item.id || acc?.encounter === item.id
            //     ) || null;
            //   if (consultMatchOrderEncounter) {
            //     console.log(
            //       "consultMatchOrderEncounter: ",
            //       consultMatchOrderEncounter
            //     );
            //     return {
            //       ...order,
            //       ...consultMatchOrderEncounter,
            //       real_encounter: consultMatchOrderEncounter.encounter,
            //     };
            //   }
            //   return { ...order };
            // });

            // let consultMatchEncounter =
            // [...(consultOrder?.[0]?.items || [])].find(
            //     (acc: any) => acc.encounter === item.id
            //     || acc.order_encounter === item.id
            //   ) || null;

            // console.log("consultMatchEncounter: ", consultMatchEncounter, " consultMatchOrderEncounter:", consultMatchOrderEncounter)
            // if (consultMatchEncounter) {
            //   consultMatchEncounter.specific_type = "doctorconsultorder";
            //   doctor_orders.push(consultMatchEncounter);
            // } else if (
            //   !state.django?.user?.role_types?.includes("DOCTOR") &&
            //   state.django?.user?.role_types?.includes("REGISTERED_NURSE")
            // ) {
            //   doctor_orders = item.doctor_orders.filter(
            //     (i: any) => i.specific_type === "doctorconsultorder"
            //   );
            // }

            return {
              ...item,
              doctor_orders: item.doctor_orders || [], //doctor_orders,
              can_chat: !!divisionPatient,
              unread_message_count: divisionPatient?.unread_message_count || null,
              chat_channel: divisionPatient?.chat_channel || null,
            };
          }),
          chatMessageTemplateOptions: [
            // {
            //   key: 1,
            //   text: "แจ้งเตือนใกล้ถึงคิวรับบริการ",
            //   value: 1,
            //   message:
            //     "ขณะนี้ใกล้ถึงคิวรับบริการของท่านแล้ว\nกรุณามารอรับบริการที่หน้าห้องตรวจ",
            // },
            ...messageTemplateData,
            {
              key: "OTHER",
              text: "อื่นๆ",
              value: "OTHER",
              message: "",
            },
          ],
        });
      }
    );
  } else if (state.divisionType === "ห้องยา") {
    controller.handleEvent({
      message: "GetDrugOrderQueue",
      params: { fetchDrugVerbalOnly: true },
    });
  } else if (state.divisionType === "แผนกเก็บ Specimen") {
    controller.handleEvent({
      message: "GetLabOrderQueue",
      params: { action: "search" },
    });
  } else {
    // console.log("divisionDict", controller.data.divisionDict);
    // console.log("division_id", controller.data.division);
  }
};

const GetDrugOrderEncounterItems: Handler = async (controller, params) => {
  const drugHomeOpdIds = params.items.flatMap((item: any) =>
    item.doctor_orders
      .filter((acc: any) => ["drugopdhomeorder", "drugorder"].includes(acc.specific_type))
      .map((acc: any) => acc.id)
  );

  const [result] = await TelepharDrugOrderSummaryListView.list({
    apiToken: controller.apiToken,
    params: { id: drugHomeOpdIds },
  });

  const summary = (result?.items || []).reduce((r: any, item: any) => {
    return { ...r, [item.id]: item };
  }, {});

  const items = params.items.map((item: any) => ({
    ...item,
    doctor_orders: item.doctor_orders.map((acc: any) => ({
      ...acc,
      telephar_type: summary[acc.id]?.telephar_type || "NONE",
      consult_status: summary[acc.id]?.consult_status || "IDLE",
    })),
  }));

  return items;
};

export const GetPatientDetailView: Handler = async (controller, params) => {
  const [patientRes, patientErr, patientNetwort] = await PatientDetailView.retrieve({
    pk: params?.patient,
    apiToken: controller.apiToken,
  });

  if (patientRes) {
    controller.setState({
      selectedPatient: patientRes,
    });
  }
};

export const SelectEncounter: Handler = async (controller, params) => {
  const state = controller.getState();

  console.log("SelectEncounter params: ", params);

  // Clear info
  // #if (params?.encounter?.id !== state.selectedEncounter?.id) {
  // console.log("selectedEncounter: null");
  // //Duplicate to TPDInterface
  // controller.setState({
  //   selectedEncounter: null,
  //   selectedEmr: null,
  //   selectedImaging: null,
  //   selectedPatient: null,
  //   patientADR: null,
  //   patientData: null,
  //   patientAssessment: null,
  //   weightInfo: null,
  //   heightInfo: null,
  //   chatDetail: null,
  //   vitalSignsInfo: null,
  //   problemListDPO: null,
  //   LabResult: null,
  //   selectedRecordViewIndex: -1,
  //   selectedProgressCycle: null,
  //   ...ResetSequence,
  // });
  // }
  // * ออกจากหน้า Encounter ก่อนไม่รอโหลดเสร็จ
  if (!state.keysBeforeEncounter?.length && params.checkEncounterUnmount) {
    return;
  }

  // * Get ข้อมูลที่ต้องการใน patient panel
  HandleGetPatientPanelData(controller, params);

  // * Get ข้อมูลที่ต้องการใน encounter
  const { patientInfo, emr } = await HandleGetEncounterContentData(controller, params);

  let patientData = new PatientData(() => {});

  patientData.setPatientId(params.encounter.patient_id);
  patientData.setEncounterId(params.encounter.id);
  patientData.setEmrId(emr?.id);

  // let emr = null
  if (!emr?.id) {
    controller.setState({
      selectedPatient: patientInfo,
      patientData: patientData,
    });

    if (CONFIG.PATIENT_IMAGE_URL && patientInfo) {
      patientInfo.profile_image = null;

      HandleGetProfileImage(controller as any, { hn: patientInfo?.hn });
    }

    if (CONFIG.SHOW_PATIENT_INFO_FROM_BDMS) {
      HandleGetProfileInfo(controller as any, {
        hn: patientInfo?.hn,
        patient_id: patientInfo?.patient_id,
      });
    }
    return console.log("Can't get emr for encounter_id:", params.encounter.id);
  }

  controller.setState({
    selectedPatient: patientInfo,
  });

  if (CONFIG.PATIENT_IMAGE_URL && patientInfo) {
    patientInfo.profile_image = null;

    HandleGetProfileImage(controller as any, { hn: patientInfo?.hn });
  }

  if (CONFIG.SHOW_PATIENT_INFO_FROM_BDMS) {
    HandleGetProfileInfo(controller as any, {
      hn: patientInfo?.hn,
      patient_id: patientInfo?.patient_id,
    });
  }

  let selectedProgressCycle: any = null;

  if (params.encounter?.type === "OPD") {
    // Create progress cycle for OPD encounter if none exists
    selectedProgressCycle = await GetProgressCycleData(controller, {
      emrId: emr?.id,
    });
  }

  if (selectedProgressCycle?.id) {
    patientData.setProgressionCycleId(selectedProgressCycle?.id);
  }

  console.log("SelectEncounter selectedProgressCycle ", selectedProgressCycle)
  controller.setState({
    patientData: patientData,
    selectedProgressCycle: selectedProgressCycle,
  });

  // * Get ข้อมูล Imaging
  const imagingApis = {
    imagingOrder: {
      api: ImagingOrderList.list,
      payload: {
        apiToken: controller.apiToken,
        params: { encounter: params?.encounter?.id },
      },
    },
  };

  const promises = CreateApiRequests(controller, {
    apis: imagingApis,
    key: "imagingApis",
  });

  Promise.all(promises).then(([result]) => {
    controller.setState({
      selectedImaging: { ...result?.[0]?.items?.[0] },
    });
  });

  // * Get Master Data
  controller.handleEvent({
    message: "GetMasterData" as any,
    params: {
      masters: [
        ["patientCondition", {}],
        ["patientDischarge", {}],
        ["cleaningChoice", {}],
      ],
    },
  });
};

const GetProgressCycleData: Handler = async (controller, params) => {
  let selectedProgressCycle: any = null;

  // Get basic data - translate from MainDPO
  const progressionInfo = await ProgressionCycleList.list({
    params: {
      emr: params.emrId,
    },
    apiToken: controller.apiToken,
  });

  if (progressionInfo[0] && progressionInfo[0]?.total > 0) {
    selectedProgressCycle = progressionInfo[0]?.items?.[0];
  } else {
    const progressCycle = await ProgressionCycleList.create({
      data: {
        emr: params.emrId,
        checkout: false,
        progression_note: "",
      } as any,
      apiToken: controller.apiToken,
    });

    if (progressCycle[0]) {
      selectedProgressCycle = progressCycle[0];
    }
  }

  return selectedProgressCycle;
};

const HandleGetEncounterContentData: Handler = async (controller, params) => {
  console.log('HandleGetEncounterContentData: params: ', params);
  let encounter = params.encounter;

  const encounterApis = {
    patientInfo: {
      api: PatientDetailView.retrieve,
      payload: {
        pk: encounter.patient_id,
        apiToken: controller.apiToken,
      },
    },
    encounterMedicalRecord: {
      api: EncounterMedicalRecordList.list,
      payload: {
        apiToken: controller.apiToken,
        pk: encounter.id,
      },
    },
    chatDetail: {
      api: ProxyPatientHasDivisionChatDetail.retrieve,
      payload: {
        pk: encounter.patient_id,
        apiToken: controller.apiToken,
        params: {
          force_create: true,
        },
        extra: { division: params.encounter.division },
      },
    },
    formScreenRes: {
      api: FormScreenList.get,
      payload: {
        params: {
          encounter: params?.encounter?.id,
          division: controller.data.division,
        },
        apiToken: controller.apiToken,
        extra: { division: controller.data?.division },
      },
    },
  };

  const promises = CreateApiRequests(controller, {
    apis: encounterApis,
    key: "encounterApis",
  });

  const responses = await Promise.all(promises);

  const [
    patientInfo,
    // medicalRecord,
    encounterMedicalRecord,
    chatDetail,
    formScreenRes,
  ] = responses;

  const state = controller.getState();

  // * มีการ Abort request
  const isAbort = responses.some((res) => res[1] === "canceled");

  if (isAbort) {
    return { patientInfo: null, emr: null };
  }

  const hasAdmitOrder = !!state.encounterDetailDict?.admitorderfrom?.[encounter?.id || ""];

  let emr = encounterMedicalRecord?.[0]?.items?.[0];
  console.log('HandleGetEncounterContentData emr: ', emr);
  // #let encounterMedicalRecord: any = null;
  let patientEmr: any = null;

  // #if (medicalRecord[1]) {
  //   // * Get ข้อมูล Encounter Medical Record หาก Medical Record Error
  //   [encounterMedicalRecord] = await EncounterMedicalRecordList.list({
  //     apiToken: controller.apiToken,
  //     pk: encounter.id,
  //   });
  // }
  // emr = encounterMedicalRecord?.items?.[0] || emr;

  if (["IN_EXAM", "IN_QUEUE"].includes(encounter.status || "")) {
    // * Get ข้อมูล Patient Emr
    [patientEmr] = await GetPatientEmr(controller, {
      id: emr?.id,
      autoCheckIn: false,
    });

    const isDoctor = state.django?.user?.role_types?.includes("DOCTOR");

    // * Role::DOCTOR -> IN_QUEUE มี patient info -> Update status IN_EXAM
    if (patientEmr?.patient_illness?.id && encounter.status === "IN_QUEUE" && isDoctor) {
      await GetPatientEmr(controller, emr);

      [encounter] = await EncounterDetail.retrieve({
        pk: encounter.id,
        apiToken: controller.apiToken,
      });
    }
  }

  console.log('HandleGetEncounterContentData patientEmr: ', patientEmr);

  let selectedProgressCycle = null
  if (encounter?.type === "OPD") {
    // Create progress cycle for OPD encounter if none exists
    selectedProgressCycle = await GetProgressCycleData(controller, {
      emrId: emr?.id,
    });
  }

  const commonState = {
    chatDetail: chatDetail[0],
    goToMenu: params.goToMenu,
    goToTab: params.goToTab,
    ...(!params?.frezzeRecordViewIndex && {
      selectedRecordViewIndex: params.goToMenu ? -1 : params.selectedRecordViewIndex || 0,
    }),
    selectedAdmitOrderRoomItem: params.admitRoom || null,
    selectedEncounter: encounter,
    patientEmr,
    ...(emr && { selectedEmr: emr ? { ...emr } : null }),
    ...(selectedProgressCycle?.id && { selectedProgressCycle }) // หา progress เข้ามาเซ็ตตอนนี้เลย
  };

  controller.setState({
    ...commonState,
    ...(!emr?.id
      ? {
          hasAdmitOrder: hasAdmitOrder,
          selectedPatient: patientInfo[0],
        }
      : {
          isStudentUser: params.encounter?.student_user === state.django?.user?.id,
          hasAdmitOrder: hasAdmitOrder || !!emr?.id,
          formScreen: formScreenRes?.[0]?.items === undefined ? [] : formScreenRes?.[0]?.items,
        }),
  });

  return { patientInfo: patientInfo[0], emr };
};

export const CreateApiRequests: Handler = (
  controller,
  params: {
    apis: Record<string, { payload: any; api: (payload: any) => Promise<any> }>;
    key: string;
  }
) => {
  const abortControllers: AbortController[] = [];

  const promises: any[] = [];

  for (const value of Object.values(params.apis)) {
    const ac = new AbortController();

    const payload = value.payload as any;

    payload.extra = { ...(payload.extra || {}), signal: ac.signal };

    promises.push(value.api(payload));
    abortControllers.push(ac);
  }

  const state = controller.getState();

  const acEncounter = state.abortControllerEncounter || {};

  acEncounter[params.key] = abortControllers;

  controller.setState({
    abortControllerEncounter: acEncounter,
  });

  return promises;
};

const HandleGetPatientPanelData: Handler = async (controller, params) => {
  const patientPanelApis = {
    patientAssessment: {
      api: FormDataLatest.retrieve,
      payload: {
        params: {
          patient: params.encounter?.patient_id,
          form_version: 0.1,
          form_code: "CardPatientAssessment",
        },
        apiToken: controller.apiToken,
      },
    },
    weightInfo: {
      api: VitalSignValueList.list,
      payload: {
        params: {
          patient: params.encounter?.patient_id,
          type_code: "Weight",
          latest_measurement: true,
        },
        apiToken: controller.apiToken,
      },
    },
    heightInfo: {
      api: VitalSignValueList.list,
      payload: {
        params: {
          patient: params.encounter?.patient_id,
          type_code: "Height",
          latest_measurement: true,
        },
        apiToken: controller.apiToken,
      },
    },
    // adrInfo: {
    //   api: AdverseReactionList.list,
    //   payload: {
    //     params: {
    //       patient: params.encounter.patient_id,
    //       exclude_cancel: true,
    //       // exclude_unused: true,
    //       severe_first: true,
    //     },
    //     apiToken: controller.apiToken,
    //   },
    // },
    vitalSignsInfo: {
      api: VitalSignValueList.list,
      payload: {
        params: {
          patient: params.encounter.patient_id,
          latest_measurement: true,
        },
        apiToken: controller.apiToken,
      },
    },
    problemListDPO: {
      api: ProblemListList.list,
      payload: {
        params: {
          patient: params.encounter.patient_id,
        },
        apiToken: controller.apiToken,
      },
    },
    LabResult: {
      api: DrugLabInteractionList.get,
      payload: {
        // pk: params.encounter.patient_id,
        apiToken: controller.apiToken,
        params: { patient: params?.encounter.patient_id },
      },
    },
    selectedShippingOrder: {
      api: PatientAppointmentTelePharmacyList.list,
      payload: {
        apiToken: controller.apiToken,
      },
    },
  };

  const promises = CreateApiRequests(controller, {
    apis: patientPanelApis,
    key: "patientPanelApis",
  });

  const responses = await Promise.all(promises);

  const [
    patientAssessment,
    weightInfo,
    heightInfo,
    // adrInfo,
    vitalSignsInfo,
    problemListDPO,
    LabResult,
    selectedShippingOrder,
  ] = responses;

  // * มีการ Abort request
  const isAbort = responses.some((res) => res[1] === "canceled");

  if (isAbort) {
    return;
  }

  const shippingOrder = selectedShippingOrder?.[0]?.items?.filter(
    (item: any) => item.encounter === params.encounter?.id
  );

  controller.setState({
    // patientADR: adrInfo[0],
    selectedShippingOrder: shippingOrder,
    patientAssessment: patientAssessment[0] ? patientAssessment[0] : {},
    weightInfo: {
      weight: weightInfo[0] ? weightInfo[0].items?.[0]?.result : null,
      weight_unit: weightInfo[0] ? weightInfo[0].items?.[0]?.unit : null,
    },
    heightInfo: {
      height: heightInfo[0] ? heightInfo[0].items?.[0]?.result : null,
      height_unit: heightInfo[0] ? heightInfo[0].items?.[0]?.unit : null,
    },
    // patientData: patientData,
    // selectedProgressCycle: null,
    vitalSignsInfo: vitalSignsInfo?.[0] ? vitalSignsInfo?.[0] : {},
    problemListDPO: problemListDPO[0],
    LabResult: LabResult[0] ? LabResult[0]?.items : [],
  });
};

export const HandleSaveMedicalRecordDischarge: Handler = async (controller, params) => {
  const state = controller.getState();

  const data = {
    checkout_cause: params.checkout_cause,
    death_datetime: "",
    // doctor_fee: doctorFee,
    // doctor_fee_rule: doctorFeeRule,
    // doctor_fee_remark: doctorFeeRemark,
    require_diagnosis: params.require_diagnosis,
    approve_by: params.approve_by,
  };

  let discharge: any = [null, null, null];

  if (params.type === "Discharge") {
    discharge = await MedicalRecordDischarge.update({
      apiToken: controller.apiToken,
      pk: params.emr_id,
      data,
    });
  } else if (params.type === "Request discharge") {
    discharge = await AdmitOrderRoomItemDetail.update({
      apiToken: controller.apiToken,
      pk: params.admitRoom.id,
      data: {
        action: "CASHIER_REQUEST",
      },
    });
  } else if (data.checkout_cause === "WAIT_RESULT") {
    // * หาก checkout แล้วครั้งนึง ให้ clear เวลา เพื่อ checkout ใหม่
    if (state.selectedProgressCycle?.checkout_time) {
      await ProgressionCycleDetail.update({
        apiToken: controller.apiToken,
        pk: state.selectedProgressCycle?.id,
        data: { checkout_time: null },
      });
    }

    discharge = await DoctorCheckoutProgressionCycle.put({
      apiToken: controller.apiToken,
      pk: state.selectedProgressCycle?.id,
      data: { action: "OPD_SEND_TREATMENT" },
      extra: {
        division: controller.data.division,
      },
    });
  } else if (params.type === "OPD_CHECK_OUT") {
    discharge = await DoctorDischargePatient.update({
      apiToken: controller.apiToken,
      pk: state.selectedEmr?.id,
      data,
      extra: {
        division: controller.data.division,
      },
    });
  } else if (["IPD_CANCEL_CHECK_OUT", "IPD_CANCEL_CASHIER_REQUEST"].includes(params.type)) {
    discharge = await AdmitOrderRoomItemDetail.update({
      apiToken: controller.apiToken,
      pk: params.admitRoom.id,
      data: {
        action: params.type.replace("IPD_", ""),
      },
    });
  } else if (["Ward Discharge", "OPD_DISCHARGE"].includes(params.type)) {
    controller.setState({
      loadingStatus: { ...state.loadingStatus, [params.card]: true },
    });

    if (params.type === "Ward Discharge") {
      discharge = await AdmitOrderRoomItemDetail.update({
        apiToken: controller.apiToken,
        pk: params.admitRoom.id,
        data: {
          action: "DISCHARGE",
          cleaning: params.cleaning,
          discharge_note: params.discharge_note,
          discharge_status: params.discharge_status,
          predischarge_condition: params.predischarge_condition,
        },
      });
    } else if (params.type === "OPD_DISCHARGE") {
      discharge = await EncounterDetail.update({
        apiToken: controller.apiToken,
        pk: params.encounter,
        data: {
          action: "OPD_DISCHARGE",
          type: "OPD",
          patient: params.patientId,
          predischarge_condition: params.predischarge_condition,
          discharge_status: params.discharge_status,
          discharge_note: params.discharge_note,
        },
        extra: {
          division: controller.data.division,
        },
      });
    }

    controller.setState({
      loadingStatus: { ...state.loadingStatus, [params.card]: false },
    });

    if (!discharge[1]) {
      if (params.onModInfo) {
        return params.onModInfo?.(discharge[0]?.go_to || []);
      }

      const backElm = document.querySelector(".patient-layout-backward") as HTMLDivElement;

      if (backElm) {
        backElm.click();
      }

      return;
    }
  } else if (params.type === "BIND_ID") {
    controller.setState({
      loadingStatus: { ...state.loadingStatus, [params.card]: true },
    });

    const result = await ConnectUserWithPatient.post({
      apiToken: controller.apiToken,
      data: {
        patient: params.patientId,
        user_patient: Number(params.ihid),
        // user: Number(params.ihid)
      },
      extra: {
        division: controller.data.division,
      },
    });

    console.log("BIND_ID", controller);

    if (result[1]) {
      controller.setState({
        loadingStatus: { ...state.loadingStatus, [params.card]: false },
        errorMessage: { ...state.errorMessage, [params.card]: result[1] },
      });
    } else {
      params.onSuccess?.();
      controller.setState(
        {
          loadingStatus: { ...state.loadingStatus, [params.card]: false },
          errorMessage: { ...state.errorMessage, [params.card]: null },
        },
        () => {
          controller.setState(
            {
              SetPatientSequence: {
                ...state.SetPatientSequence,
                sequenceIndex: "SearchOrNew",
              },
            },
            () =>
              controller.handleEvent({
                message: "RunSequence" as any,
                params: {
                  ...params,
                  sequence: "SetPatient",
                  action: "select",
                  patient: state.SetPatientSequence.patient,
                },
              })
          );
        }
      );
    }

    return;
  }

  if (!discharge[1]) {
    if (params.admitRoom?.id) {
      // await controller.handleEvent({
      //   message: "HandleSelectAdmitEncounter" as any,
      //   params: {
      //     encounterId: params.encounter || null,
      //     admitRoom: {
      //       room_item_id: params.admitRoom.id,
      //     },
      //   },
      // });
    }

    controller.setState(
      {
        errorMessage: { ...state.errorMessage, [params.card]: null },
        dischargeDoctor: StateInitial.dischargeDoctor,
        approve_by: null,
      },
      async () => {
        const message = data.checkout_cause === "WAIT_RESULT" ? { back: true } : {};

        if (!message.back) {
          await controller.handleEvent({
            message: "HandleRefreshEncounter" as any,
            params: { admitRoomId: params.admitRoom?.id },
          });
        }

        params.onSuccess?.(message);
      }
    );

    return discharge;
  } else {
    controller.setState({
      errorMessage: { ...state.errorMessage, [params.card]: discharge[1] },
    });

    return discharge;
  }
};

export const HandleRefreshEncounter: Handler = async (controller, params) => {
  console.log('HandleRefreshEncounter: setup EMR start');
  let state = controller.getState();

  const promiseArr = [
    EncounterDetail.retrieve({
      pk: state.selectedEncounter?.id,
      apiToken: controller.apiToken,
    }),
    EncounterMedicalRecordList.list({
      apiToken: controller.apiToken,
      pk: state.selectedEncounter?.id,
    }),
  ];

  if (params?.admitRoomId) {
    promiseArr.push(
      ...[
        AdmitOrderRoomItemDetail.retrieve({
          pk: params.admitRoomId,
          apiToken: controller.apiToken,
        }),
        DoctorConsultAppointmentList.list({
          apiToken: controller.apiToken,
          params: { order_encounter: state.selectedEncounter?.id },
        }),
      ]
    );
  }

  const [[encounter], emr, admit, consult] = await Promise.all(promiseArr);

  let patientEmr: any = null;

  if (encounter?.status === "IN_EXAM") {
    [patientEmr] = await GetPatientEmr(controller, emr?.[0]?.items?.[0]);
  }

  if (params?.admitRoomId && encounter) {
    encounter.doctor_orders = [
      { ...consult?.[0]?.items?.[0], specific_type: "doctorconsultorder" },
    ];
  }

  state = controller.getState()
  console.log('HandleRefreshEncounter: setup EMR emr:', emr);
  console.log('HandleRefreshEncounter: setup EMR state:', state);
  controller.setState({
    selectedEncounter: { ...encounter },
    selectedEmr: { ...emr?.[0]?.items?.[0] },
    patientEmr,
    openDischargeReassessment:
      typeof params.isOpen === "boolean" ? params.isOpen : state.openDischargeReassessment,
    ...(admit && { selectedAdmitOrderRoomItem: admit[0] }),
  });
};

export const HandleDischargeConsult: Handler = async (controller, params) => {
  const state = controller.getState();

  const [r, e, n] = await PatientAppointmentFinishConsult.post({
    data: { patient_appointment: params?.patientAppointment?.id },
    apiToken: controller.apiToken,
    extra: { division: controller.data?.division },
  });

  if (e) {
    controller.setState({
      errorMessage: { ...state.errorMessage, [params.card]: e },
    });
  } else {
    const refreshConsult = await PatientAppointmentTelePharmacyList.list({
      apiToken: controller.apiToken,
    });

    const ShippingOrder = refreshConsult?.[0].items?.filter(
      (item: any) => item.encounter === params?.patientAppointment?.encounter
    );

    controller.setState({
      selectedShippingOrder: ShippingOrder,
    });
  }
};

export const GetClassify: Handler = async (controller, params) => {
  const state = controller.getState();
  let classify = {
    division: params?.division,
  };
  const [classifyListRes, classifyListError] = await ClassifyList.get({
    params: classify,
    apiToken: controller.apiToken,
  });
  console.log("classifyListRes: ", classifyListRes);
  controller.setState({ classifyList: classifyListRes?.classify || [] });
};

export const HandleAddMessageProblemDPO: Handler = async (controller, params) => {
  const state = controller.getState();

  if (params?.action === "createProblemList") {
    const [r, e, n] = await ProblemListList.create({
      data: {
        patient: params.patient,
        message: state.messageProblemDPO,
      },
      apiToken: controller.apiToken,
    });

    controller.setState({
      messageProblemDPO: "",
    });

    if (e) {
      controller.setState({
        errorMessage: { ...state.errorMessage, [params.card]: e },
      });
    } else {
      HandleAddMessageProblemDPO(controller, {
        action: "getProblemList",
        patient: params.patient,
      });
    }
  } else if (params?.action === "editProblemList") {
    const [r, e, n] = await ProblemListDetail.update({
      pk: params.pk,
      data: {
        patient: params.patient,
        message: params.message,
        is_active: params.is_active,
      },
      apiToken: controller.apiToken,
    });

    controller.setState({
      messageProblemDPO: "",
    });

    if (e) {
      controller.setState({
        errorMessage: { ...state.errorMessage, [params.card]: e },
      });
    } else {
      HandleAddMessageProblemDPO(controller, {
        action: "getProblemList",
        patient: params.patient,
      });
    }
  } else if (params?.action === "getProblemList") {
    const problemListDPO = await ProblemListList.list({
      params: {
        patient: params.patient,
      },
      apiToken: controller.apiToken,
    });

    controller.setState({
      problemListDPO: problemListDPO[0],
    });
  }
};

export const GetUsernameForDischarge: Handler = async (controller, params) => {
  console.log("GetUsername: ", params);

  await controller.handleEvent({
    message: "GetMasterData" as any,
    params: { masters: [["positionAll", {}]] },
  });

  const state = controller.getState();

  if (params.doctor) {
    const [doctor] = await DoctorDetail.retrieve({
      apiToken: controller.apiToken,
      pk: params.doctor,
    });

    params.pk = doctor?.user;
  }

  const [[r, e], [employee]] = await Promise.all([
    UserListM.list({
      apiToken: controller.apiToken,
      params: { ...params, limit: 99999 },
    }),
    UserEmployeeDetailAPIView.retrieve({
      apiToken: controller.apiToken,
      pk: params.pk,
    }),
  ]);

  if (e) {
    console.log("GetUsernam  error", e);
    return [[], e];
  }

  let bypass = false;
  let userId = null;
  let username = "";
  let fullName = "";

  const position =
    controller.data.masterData?.positionAll?.find((data: any) => data.id === employee?.position) ||
    {};

  if (params.checkBypass) {
    const doctorId = state.selectedEncounter?.approve_by || state.selectedEncounter?.doctor;

    bypass = r.items[0]?.id === state.django?.user?.id && params.doctor === doctorId;
  }

  if (
    (typeof position.student_flag !== "undefined" && !position.student_flag) ||
    position.teacher_flag
  ) {
    userId = r.items[0].id;
    username = r.items[0].username;
    fullName = r.items[0].full_name;
  }

  console.log(" GetUsername r:", r);
  console.log(" GetUsername e:", e);

  let userData = {
    userId,
    username,
    fullName: fullName,
    // password: state.django?.user?.id === r.items[0].id ? "12345678" : "",
    password: "",
    bypass: bypass && !!userId,
  };
  // console.log("user django: ", state.django?.user, r.items[0])
  controller.setState({ dischargeDoctor: userData });
};

export const GetDoctorSearch: Handler = async (controller, params) => {
  const [r, e, n] = await DoctorList.list({
    apiToken: controller.apiToken,
    params: { ...params, limit: 99999 },
  });
  if (e) {
    console.log("doctor search list error", e);
    return [[], e];
  }
  return [r.items, e];
};

export const CheckUserLogin: Handler = async (controller, data) => {
  const state = controller.getState()

  let [r, e] = [null, null];

  if (!data.bypass) {
    [r, e] = await UserLoginView.post({
      apiToken: controller.apiToken,
      data: { ...data },
    });
  }

  if (e) {
    controller.setState({
      errorMessage: { ...state.errorMessage, CardPatientDischarge: e },
      loginVia: false,
    });
    return false;
  }

  if (data.checkMedicalHistory) {
    // get doctor id
    const [userResp] = await UserDetailAPIViewM.retrieve({
      apiToken: controller.apiToken,
      pk: data.userId,
    });

    // update approve_by and approve_by_name in encounterDetail
    await EncounterDetail.patch({
      apiToken: controller.apiToken,
      pk: state.selectedEncounter?.id,
      params: {},
      data: {
        action: "OPD_EDIT",
        approve_by: userResp?.doctor,
      },
    });
  }

  controller.setState({
    loginVia: true,
    // dischargeDoctor: StateInitial.dischargeDoctor,
  });
};

const GetPatientEmr: Handler = (controller, params) => {
  return PatientEMRList.get({
    emr: params?.id,
    apiToken: controller.apiToken,
    params: { auto_checkin: params?.autoCheckIn },
    extra: { division: controller.data.division },
  });
};

export const HandleCheckApprove: Handler = async (controller, params) => {
  const state = controller.getState();

  const [checkApproveRes, checkApproveErr, checkApproveNet] = await CheckApproveView.get({
    encounter: state.selectedEncounter.id,
    extra: {
      division: controller.data.division,
    },
    apiToken: controller.apiToken,
  });

  if (checkApproveRes) {
    controller.setState({
      checkApprove: {
        can_checkout: checkApproveRes.can_checkout,
        action: params.action,
      },
    });
  }
};

export const HandleGetDoctorOrder: Handler = async (controller, params) => {
  const state = controller.getState();

  const items: any[] = params.items || [];

  const showDetail = !items.every((value: any) => typeof value === "string");

  if (params.order_type === "doctorconsultorder") {
    controller.setState({
      loadingStatus: { ...state.loadingStatus, [params.card]: true },
    });

    const consultOrder = await DoctorConsultAppointmentList.list({
      apiToken: controller.apiToken,
      params: {
        id_list: items.map((item: any) => item.id),
      },
    });

    if (!consultOrder[1]) {
      controller.setState(
        { loadingStatus: { ...state.loadingStatus, [params.card]: false } },
        () => {
          params.onCallBack?.(consultOrder[0]?.items || []);
        }
      );
      return;
    }
  } else if (showDetail) {
    params.onCallBack?.([{ ...params.items[0], loading: true }]);

    controller.handleEvent({
      message: "GetMasterData" as any,
      params: { masters: [["unit", {}]] },
    });

    let orderType = params.order_type as "drug" | "imagingorder" | "supply";

    if (orderType.includes("supply")) {
      orderType = "supply";
    } else if (orderType.includes("drug")) {
      orderType = "drug";
    }

    const apis = {
      drug: DrugOrderDetailForAction.retrieve,
      imagingorder: ImagingOrderDetail.retrieve,
      supply: SupplyOrderDetail.retrieve,
    };

    const api = apis[orderType];

    const promiseArr = items.map(async (item: any) => {
      const promise: Promise<any> = api({
        apiToken: controller.apiToken,
        pk: item.id,
      });

      return promise.then((res: any) => ({
        ...item,
        ...res[0],
        loading: false,
        order_type_label: orderType,
      }));
    });

    const response = await Promise.all(promiseArr);

    return params.onCallBack?.(response);
  }

  return params.onCallBack?.(params.items);
};

export const DrugOrderUpdateCallTelephar: Handler = async (controller, params) => {
  return await TelepharDrugOrderUpdateCallStatus.patch({
    apiToken: controller.apiToken,
    pk: params.id,
    data: params.data, // telephar_type, consult_status
  });
};

export const UpdateEncounterStatus: Handler = async (controller, params) => {
  // -const state = controller.getState();
  // -const patientConditionId = state.masterOptions?.patientCondition?.[0]?.value;
  // -const patientDischargeId = state.masterOptions?.patientDischarge?.[0]?.value;

  const status = {
    "IN_EXAM:IN_QUEUE": {
      api: EncounterActionOnly.patch,
      pk: params.id,
      data: { action: "OPD_CANCEL_CHECK_IN" },
    }, // ---> รอพบแพทย์
    "IN_QUEUE:IN_EXAM": [
      {
        api: PatientEMREdit.put,
        data: {
          patient_illness: { emr: params.emrId, doctor: params.doctorId },
          patient_exam_other: { emr: params.emrId, doctor: params.doctorId },
          patient_exam: [],
        },
      },
      {
        api: PatientEMRList.get,
        pk: params.emrId,
      },
    ], // ---> กำลังพบแพทย์
    "IN_EXAM:OPD_CHECK_OUT": {
      api: DoctorDischargePatient.update,
      pk: params.emrId,
      data: { checkout_cause: "BY_APPROVAL", require_diagnosis: false },
    }, // ---> ออกจากห้องตรวจ
    "CHECKED_OUT:CANCEL_CHECKOUT": [
      {
        api: EncounterActionOnly.patch,
        pk: params.id,
        data: { action: "OPD_QUEUE_EXAM" },
      },
      {
        api: PatientEMRList.get,
        pk: params.emrId,
      },
    ], // ---> กำลังพบแพทย์
  };

  const { seq } = Object.keys(status)
    .reverse()
    .reduce(
      (result, key) => {
        const [curr, next] = key.split(":");

        if ((next === params.target || next === result.prev) && params.current !== result.prev) {
          result.prev = curr;
          result.seq.unshift(...[(status as any)[key]].flat());
        }

        return result;
      },
      { seq: [] as any[], prev: "" }
    );

  for await (const value of seq) {
    await value.api({
      apiToken: controller.apiToken,
      pk: value.pk,
      emr: value.pk,
      data: value.data,
      extra: { division: controller.data.division },
    });
  }

  if (params.refresh) {
    controller.handleEvent({
      message: "HandleRefreshEncounter" as any,
      params: {},
    });
  }
};

export const HandleGoToCreateAppointment: Handler = (controller, params) => {
  const state = controller.getState();

  const index = getSideMenuIndex({ tab: "Appointment" });

  if (index >= 0) {
    params.tabPatientRef?.changeTab(index);

    controller.setState({
      goBackEncounterState: { ...state },
      goToCreateAppointment: {
        selectedPatient: state.selectedPatient,
        locationSearch: globalThis.location.search,
      },
    });
  }
};

export const HandleGoBackEncounter: Handler = (controller, params) => {
  const state = controller.getState();

  const { locationSearch } = params;

  if (state.goBackEncounterState) {
    if (locationSearch) {
      setTimeout(() => {
        window.history.replaceState({}, "", locationSearch);
      }, 100);
    }

    controller.setState({
      ...state.goBackEncounterState,
      goBackEncounterState: null,
      goToCreateAppointment: null,
      initialLayout: 1,
    });
  } else {
    controller.setState({
      ...state.goBackEncounterState,
      goBackEncounterState: null,
      goToCreateAppointment: null,
    });
  }
};

export const HandleGoBackAppointment: Handler = async (controller, params) => {
  const state = controller.getState();

  if (state.goBackAppointmentState) {
    await controller.handleEvent({
      message: "ChangeDivision" as any,
      params: {
        divisionId: state.goBackAppointmentState?.selectedDivision?.id,
        isRefresh: true,
        noDefaultTabIndex: true,
        resetLayout: false,
      },
    });

    controller.setState({
      ...state.goBackAppointmentState,
      goBackAppointmentState: null,
      patientList: [],
      goToCreateAppointment: {
        selectedPatient: state.goBackAppointmentState.selectedPatient,
        selectedAppointment: state.goBackAppointmentState.selectedAppointment,
      },
    });
  } else {
    controller.setState({ goBackAppointmentState: null });
  }
};

export const GetDivisionByOrganization: Handler = async (controller, params) => {

  const divisionList = await DivisionList.list({
    params: params,
    apiToken: controller.apiToken
  });

  const divisionOptions = (divisionList[0] || []).items?.map((item: any) => (

      {
        key: item.id,
        value: item.id,
        text: item.name,
      }
    ));

    controller.setState({
      divisionOrganization: divisionOptions
    });
}
