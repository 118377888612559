import moment from "moment";
import { ContentStack, ContentText, Position, TDocumentDefinitions } from "pdfmake/interfaces";

import CompanyLogoForm, { CompanyType, FontType } from "./CompanyLogoForm";

import { beToAd } from "react-lib/utils/dateUtils";

import CONFIG from "config/config";

// Common type for shared properties
type CommonProps = {
  font?: FontType;
  form: string;
  headerMargins?: TDocumentDefinitions["pageMargins"];
  images?: Record<string, any>;
  logoHeight?: number;
  maxWidth?: number;
  pageMarginBottom?: number;
  pageMarginLeft?: number;
  pageMarginRight?: number;
  pageMarginTop?: number;
  pageMargins?: TDocumentDefinitions["pageMargins"];
  styles?: TDocumentDefinitions["styles"];
  type?: number;
};

// Specific types extending CommonProps
type HeaderLogoWithAddressProps = {
  titleContent?: ContentStack["stack"];
} & CommonProps;

type HeaderWithTitleContentProps = {
  hideLogo?: boolean;
  logoPosition?: Position;
  titleContent: ContentStack["stack"];
  titleMargins?: TDocumentDefinitions["pageMargins"];
  titleName?: string;
} & CommonProps;

type HeaderLogoWithPublishProps = {
  haveBorder?: boolean;
  hospitalFontSize?: number;
  isHospitalVertical?: boolean;
  publishContent: ContentStack["stack"];
  titleContent: ContentStack["stack"];
} & CommonProps;

export type HeaderLogoWithPatientProps = {
  detail: {
    divisionName: string;
    doctorName: string;
    hn: string;
    number: string;
    patient_age: string; // 20 ปี 5 เดือน 14 วัน
    patient_birthdate: string; // 04/02/2547; BE.;
    patient_gender_name: string;
    patientName: string;
    profileImage?: string | null;
    started: string;
  };
  titleContent: ContentStack["stack"];
} & CommonProps;

type HeaderOperativeScheduleProps = {
  hospitalContent: ContentStack["stack"];
  printDateContent: ContentStack["stack"];
  titleContent: ContentText["text"];
} & CommonProps;

const FORM_NAME = "HeaderPdfFormTemplate";

const getNameCompanyTH = (company: CompanyType = CONFIG.COMPANY) =>
  company === "CU" ? "โรงพยาบาลคณะทันตแพทยศาสตร์ จุฬาลงกรณ์มหาวิทยาลัย" : "";

const getNameCompanyEN = (company: CompanyType = CONFIG.COMPANY) =>
  company === "CU" ? "Chulalongkorn University Faculty of Dentistry Hospital" : "";

const getAddressCompany = (company: CompanyType = CONFIG.COMPANY) => {
  switch (company) {
    case "CU": {
      return {
        stack: [
          { alignment: "right", text: "โรงพยาบาลคณะทันตแพทยศาสตร์ จุฬาลงกรณ์มหาวิทยาลัย" },
          {
            text: [
              { alignment: "right", bold: true, text: "ที่อยู่ : " },
              { alignment: "right", text: "34  ถนนอังรีดูนังต์  แขวงวังใหม่  เขตปทุมวัน" },
            ],
          },
          { alignment: "right", text: "กรุงเทพมหานคร  10330  โทร 0 2218 8635" },
        ],
      };
    }
    case "SAPIENS": {
      return {
        table: {
          body: [
            [
              {
                alignment: "right",
                border: [false, false, true, false],
                stack: [
                  {
                    bold: true,
                    font: "KanitLM",
                    fontSize: 7,
                    text: "โรงพยาบาลเซเปี้ยนซ์",
                  },
                  {
                    font: "KanitLM",
                    fontSize: 7,
                    noWrap: true,
                    text: "498/7 ซอยรามคำแหง 39 (เทพลีลา 1)",
                  },
                  {
                    font: "KanitLM",
                    fontSize: 7,
                    noWrap: true,
                    text: "แขวงวังทองหลาง เขตวังทองหลาง",
                  },
                  {
                    font: "KanitLM",
                    fontSize: 7,
                    text: "กรุงเทพมหานคร 10310",
                  },
                ],
              },
              {
                alignment: "right",
                border: [true, false, false, false],
                stack: [
                  {
                    bold: true,
                    font: "Poppins",
                    fontSize: 7,
                    text: "Sapiens Pain Hospital",
                  },
                  {
                    font: "Poppins",
                    fontSize: 7,
                    noWrap: true,
                    text: "498/7 Soi Ramkhamhaeng 39 (Thepleela 1)",
                  },
                  {
                    font: "Poppins",
                    fontSize: 7,
                    noWrap: true,
                    text: "Wang Thonglang, Wang thonglang",
                  },
                  {
                    font: "Poppins",
                    fontSize: 7,
                    text: "Bangkok 10310",
                  },
                ],
              },
            ],
            [
              {
                alignment: "right",
                border: [false, false, false, false],
                colSpan: 2,
                font: "Poppins",
                fontSize: 7,
                text: "Tel. 02-111-3703",
              },
              {},
            ],
          ],
        },
      };
    }

    default: {
      return {
        alignment: "right",
        text: `${company} Have no Address`,
      };
    }
  }
};

// Common setup functions
const setupLogoAndFont = async (props: CommonProps) =>
  CompanyLogoForm({
    font: props.font || "KanitLM",
    form: props.form || FORM_NAME,
    height: props.logoHeight || 50,
    maxWidth: props.maxWidth,
    type: props.type,
  });

const getPageMargins = (props: CommonProps): TDocumentDefinitions["pageMargins"] => {
  const defaultMargin = 15;
  const defaultTop = 100;

  if (props.pageMargins !== undefined) {
    return props.pageMargins;
  }

  const left = props.pageMarginLeft ?? defaultMargin;
  const top = props.pageMarginTop ?? defaultTop;
  const right = props.pageMarginRight ?? defaultMargin;
  const bottom = props.pageMarginBottom ?? defaultMargin;

  if (left === right && top === bottom && left === top) {
    return left;
  } else if (left === right && top === bottom) {
    return [left, top];
  }

  return [left, top, right, bottom];
};

const shouldShowLogo = () => !CONFIG.HIDE_COMPANY_LOGO_PRINT;

const getCompanyName = (isVertical: boolean, fontSize: number) => ({
  stack: [
    { bold: true, fontSize, text: getNameCompanyTH() },
    { fontSize, text: getNameCompanyEN() },
  ],
});

// Template #1 Logo Left and Address Right (Can Add Title Content in Center)
export const HeaderLogoWithAddress = async (props: HeaderLogoWithAddressProps) => {
  const { columns, font, fontSizes, images, lineHeights } = await setupLogoAndFont(props);
  const pageMargins = getPageMargins(props);

  return {
    font,
    fontSizes,
    images: { ...images, ...props.images },
    lineHeights,
    pageMargins,
    styles: { ...props.styles },
    header: () => ({
      margin: props.headerMargins || [15, 15, 15, 0],
      table: {
        widths: shouldShowLogo() ? ["25%", "*", "auto"] : ["25%", "*", "45%"],
        body: [
          ...(columns.length > 0 ? [[{ columns }, "", getAddressCompany()]] : []),
          props.titleContent
            ? [{ colSpan: 3, stack: props.titleContent }]
            : [{ colSpan: 3, text: "" }],
        ],
      },
      layout: "noBorders", // Comment this For Checking Widths and Layout of Header
    }),
  };
};

// Template #2 Logo and Title Content Report
export const HeaderLogoWithTitleContent = async (props: HeaderWithTitleContentProps) => {
  const { columns, font, fontSizes, images, lineHeights } = await setupLogoAndFont(props);
  const pageMargins = getPageMargins(props);
  const defaultLogoPosition = props.logoPosition || { x: 20, y: 30 };

  return {
    font,
    fontSizes,
    images: { ...images, ...props.images },
    lineHeights,
    pageMargins,
    styles: { ...props.styles },
    header: () => ({
      margin: props.headerMargins || [15, 15, 15, 0],
      // Table Solution
      // table: {
      //   widths: ["10%", "90%"],
      //   body: !CONFIG.HIDE_COMPANY_LOGO_PRINT
      //   ? [
      //       [
      //         {image: "logo", width: companyLogoForm.width, height: defaultLogoHeight, absolutePosition: {x: 20, y: 24}},
      //         {
      //           stack: CONFIG.COMPANY === "CU"
      //             ? [
      //                 {text: `CHULALONGKORN UNIVERSITY, FACULTY OF DENTISTRY`, bold: true, fontSize: 18},
      //                 ...props.titleContent
      //             ]
      //             : props.titleContent
      //         }
      //       ], // issue 65030
      //   ]
      //   : [
      //     [{stack: props.titleContent, colSpan: 2}],
      //   ],
      // },
      // layout: 'noBorders', // Comment this For Checking Widths and Layout of Header

      // Stack Solution
      stack: [
        props.hideLogo
          ? { text: "" }
          : {
              absolutePosition: defaultLogoPosition,
              columns,
            },
        {
          stack:
            CONFIG.COMPANY === "CU"
              ? [
                  {
                    alignment: "center",
                    bold: true,
                    fontSize: 18,
                    margin: props.titleMargins || [0, 0, 0, 0],
                    text: props.titleName || "CHULALONGKORN UNIVERSITY, FACULTY OF DENTISTRY",
                  },
                  ...props.titleContent,
                ]
              : props.titleContent,
        },
      ],
    }),
  };
};

// Tempalte #3 Header Logo + Title + Publish Detail
export const HeaderLogoWithPublishDetail = async (props: HeaderLogoWithPublishProps) => {
  const { columns, font, fontSizes, images, lineHeights } = await setupLogoAndFont(props);
  const pageMargins = getPageMargins(props);
  const defaultHospitalFontSize = props.hospitalFontSize || 10;
  const isDefaultHaveBorder = props.haveBorder ?? false;

  return {
    font,
    fontSizes,
    images: { ...images, ...props.images },
    lineHeights,
    pageMargins,
    styles: {
      ...props.styles,
    },
    header: (currentPage: number, countPage: number) => ({
      margin: props.headerMargins || [15, 15, 15, 0],
      table: {
        widths: ["auto", "*", "20%"],
        body: [
          [
            props.isHospitalVertical
              ? {
                  border: isDefaultHaveBorder ? [true, true, false, true] : [],
                  margin: [2, 1, 0, 1],
                  stack:
                    columns.length > 0
                      ? [{ columns }, getCompanyName(true, defaultHospitalFontSize)]
                      : [],
                }
              : {
                  border: isDefaultHaveBorder ? [true, true, false, true] : [],
                  columns:
                    columns.length > 0
                      ? [{ columns }, getCompanyName(false, defaultHospitalFontSize)]
                      : [],
                  margin: [2, 1, 0, 1],
                },
            {
              border: isDefaultHaveBorder ? [false, true, false, true] : [],
              stack: props.titleContent,
            },
            {
              border: isDefaultHaveBorder ? [false, true, true, true] : [],
              margin: [0, 7.5, 2, 0],
              stack: [
                ...props.publishContent,
                {
                  alignment: "right",
                  fontSize: 12,
                  text: `หน้าที่ ${currentPage}/${countPage}`,
                },
              ],
            },
          ],
        ],
      },
    }),
  };
};

// Tempalte #4 Header for FormOperativeSchedule
export const HeaderOperativeSchedule = async (props: HeaderOperativeScheduleProps) => {
  const { columns, font, fontSizes, images, lineHeights } = await setupLogoAndFont(props);
  const pageMargins = getPageMargins(props);

  return {
    font,
    fontSizes,
    images: { ...images, ...props.images },
    lineHeights,
    pageMargins,
    styles: { ...props.styles },
    header: () => ({
      margin: props.headerMargins || [15, 15, 15, 0],
      stack: [
        {
          columns: [
            { columns },
            { stack: props.hospitalContent, width: "*" },
            { stack: props.printDateContent, width: "auto" },
          ],
        },
        {
          columns: [
            {
              canvas: [
                {
                  lineColor: "#E25F9E",
                  lineWidth: 2,
                  type: "line",
                  x1: 0,
                  x2: 890,
                  y1: 5,
                  y2: 5,
                },
              ],
            },
          ],
        },
        {
          columns: [props.titleContent],
        },
      ],
    }),
  };
};

// Tempalte #5 Header Logo + Title + Patient Detail
export const HeaderLogoWithPatientDetail = async (props: HeaderLogoWithPatientProps) => {
  const { columns, font, fontSizes, images, lineHeights } = await setupLogoAndFont({
    ...props,
    maxWidth: 230,
  });
  const pageMargins = getPageMargins(props);

  return {
    font,
    fontSizes,
    images: {
      ...images,
      ...props.images,
      userProfile: props.detail.profileImage || `${origin}/static/images/user.png`,
    },
    lineHeights,
    pageMargins,
    styles: {
      ...props.styles,
      fieldHeader: { alignment: "center", bold: true, fontSize: fontSizes[14.5] },
      fieldHeaderDivision: { alignment: "center", fontSize: fontSizes[13] },
      fieldHeaderKey: { bold: true, font: "PoppinsLM", fontSize: fontSizes[13] },
      fieldHeaderValue: { fontSize: fontSizes[13.5] },
    } as TDocumentDefinitions["styles"],
    header: () => ({
      stack: [
        {
          columns: [
            {
              stack: [
                {
                  columns,
                  marginTop: -5,
                },
                {
                  margin: [-60, 10, 0, 0],
                  style: "fieldHeader",
                  text: "OPD SUMMARY REPORT",
                },
                {
                  margin: [-60, -2.5, 0, 0],
                  style: "fieldHeaderDivision",
                  text: props.detail.divisionName,
                },
              ],
              width: "51%",
            },
            {
              lineHeight: lineHeights[0.9],
              marginTop: -2.5,
              stack: [
                {
                  text: [
                    { style: "fieldHeaderKey", text: "HN [EN]: " },
                    {
                      style: "fieldHeaderValue",
                      text: `${props.detail.hn} [${props.detail.number}]`,
                    },
                  ],
                },
                {
                  text: [
                    { style: "fieldHeaderKey", text: "Name: " },
                    { style: "fieldHeaderValue", text: props.detail.patientName },
                  ],
                },
                {
                  columns: [
                    {
                      text: [
                        { style: "fieldHeaderKey", text: "Birthdate: " },
                        {
                          style: "fieldHeaderValue",
                          text: beToAd(props.detail.patient_birthdate)?.format("D MMMM YYYY"),
                        },
                      ],
                      width: "auto",
                    },
                    {
                      marginLeft: 20,
                      text: [
                        { style: "fieldHeaderKey", text: "Sex: " },
                        { style: "fieldHeaderValue", text: props.detail.patient_gender_name },
                      ],
                      width: "*",
                    },
                  ],
                },
                {
                  text: [
                    { style: "fieldHeaderKey", text: "Age: " },
                    { style: "fieldHeaderValue", text: formatAge(props.detail.patient_age) },
                  ],
                },
                {
                  text: [
                    { style: "fieldHeaderKey", text: "Visit date: " },
                    {
                      style: "fieldHeaderValue",
                      text: moment(props.detail.started).format("D/M/YYYY"),
                    },
                  ],
                },
                {
                  text: [
                    { style: "fieldHeaderKey", text: "Physician: " },
                    { style: "fieldHeaderValue", text: props.detail.doctorName },
                  ],
                },
              ],
              style: "miniField",
              width: "*",
            },
            { text: "", width: 12.5 },
            {
              alignment: "center",
              height: 85,
              image: "userProfile",
              width: 70,
            },
            { text: "", width: 0 },
          ],
          margin: [10, 10, 10, 0],
        },
        {
          marginTop: 5,
          table: {
            widths: ["100%"],
            body: [[{ border: [false, true, false, false], text: "" }]],
          },
        },
      ] as ContentStack["stack"],
    }),
  };
};

/* ------------------------------------------------------ */

/*                          Utils                         */

/* ------------------------------------------------------ */
const formatAge = (ageString: string) => {
  // แยกข้อมูลปี เดือน วัน
  const [years, months, days] = ageString
    .split(" ")
    .filter((part) => /\d+/.exec(part))
    .map((part) => Number.parseInt(part));

  // สร้างรูปแบบใหม่
  return `${years} Y ${months} M ${days} D`;
};
