import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  Form,
  FormGroup,
  Input,
  Radio,
  TextArea,
  Checkbox,
  Dropdown
} from 'semantic-ui-react'

const CardVisaExtensionCertificateUX = (props: any) => {
    return(
      <div>
        <Form>
          <FormGroup
            className="ui form"
            style={{display: "flex", padding: "1rem 0rem 0rem 1rem"}}>
            <label
              style={{display: "flex",  alignItems: "center", width: "12rem", fontWeight: "bold"}}>
              สัญชาติ
            </label>
            <Dropdown
              disabled={props.disableNationality || props.onHandleDisableForm}
              icon="search"
              id="CardVisaExtensionCertificate-Dropdown-Nationality"
              onChange={props.onHandleChangeNationality}
              options={props.nationalityOptions}
              search={true}
              selection={true}
              style={{minWidth: "20rem"}}
              value={props.nationalityValue}>
            </Dropdown>
          </FormGroup>
          <FormGroup
            className="ui form"
            style={{display: "flex", padding: "0rem 0rem 0rem 1rem"}}>
            <label
              style={{display: "flex",  alignItems: "center", width: "12rem", fontWeight: "bold"}}>
              หนังสือเดินทางเลขที่
            </label>
            <Input
              disabled={props.onHandleDisableForm}
              id="CardVisaExtensionCertificate-Input-Passport"
              onChange={(e) => props.onHandleChangeText("patientPassport", e)}
              style={{width: "20rem"}}
              value={props.patientPassport}>
            </Input>
          </FormGroup>
          <FormGroup
            className="ui form"
            style={{display: "flex", padding: "0rem 0rem 0rem 1rem"}}>
            <label
              style={{display: "flex",  alignItems: "center", width: "12rem", fontWeight: "bold"}}>
              มารับการตรวจ
            </label>
            <Radio
              checked={props.opdRadio}
              disabled={props.onHandleDisableRadio.disableOpd || props.onHandleDisableForm}
              label="ผู้ป่วยนอก"
              style={{width: "8rem"}}>
            </Radio>
            <Radio
              checked={props.ipdRadio}
              disabled={props.onHandleDisableRadio.disableIpd || props.onHandleDisableForm}
              label="ผู้ป่วยใน"
              style={{width: "8rem"}}>
            </Radio>
          </FormGroup>
          <FormGroup
            className="ui form"
            style={{display: "flex", padding: "0rem 0rem 0rem 1rem"}}>
            <label
              style={{display: "flex",  alignItems: "center", width: "12rem", fontWeight: "bold"}}>
              อาการนำ
            </label>
            <TextArea
              disabled={props.onHandleDisableForm}
              id="CardVisaExtensionCertificate-TextArea-CheifComplaint"
              onChange={(e) => props.onHandleChangeText("chiefComplaint", e)}
              style={{width: "25rem"}}
              value={props.chiefComplaintValue}>
            </TextArea>
          </FormGroup>
          <FormGroup
            className="ui form"
            style={{display: "flex", padding: "0rem 0rem 0rem 1rem"}}>
            <label
              style={{display: "flex",  alignItems: "center", width: "12rem", fontWeight: "bold"}}>
              การวินิจฉัยโรค
            </label>
            <TextArea
              disabled={props.onHandleDisableForm}
              id="CardVisaExtensionCertificate-TextArea-Diagnosis"
              onChange={(e) => props.onHandleChangeText("diagnosis", e)}
              style={{width: "25rem"}}
              value={props.diagnosisValue}>
            </TextArea>
          </FormGroup>
          <FormGroup
            className="ui form"
            style={{display: "flex", padding: "0rem 0rem 0rem 1rem"}}>
            <label
              style={{display: "flex", fontWeight: "bold"}}>
              การรักษา/การตรวจวินิจฉัย (Treatment/Investigation)
            </label>
          </FormGroup>
          <FormGroup
            className="ui form"
            style={{display: "flex", padding: "1rem 0rem 0rem 2rem"}}>
            <Checkbox
              checked={props.onHandleGetCheckboxValue("isDrug")}
              disabled={props.onHandleDisableForm}
              id="CardVisaExtensionCertificate-Checkbox-IsDrug"
              label="ให้ยา"
              onChange={(e, data) => props.onHandleChangeCheckbox(e, "isDrug", data)}
              style={{display: "flex",  alignItems: "center", width: "14rem", fontSize: "12px"}}>
            </Checkbox>
            <Checkbox
              checked={props.onHandleGetCheckboxValue("isPhysicalTherapy")}
              disabled={props.onHandleDisableForm}
              id="CardVisaExtensionCertificate-Checkbox-IsPhysicalTherapy"
              label="กายภาพบำบัด"
              onChange={(e, data) => props.onHandleChangeCheckbox(e, "isPhysicalTherapy", data)}
              style={{display: "flex",  alignItems: "center", fontSize: "12px"}}>
            </Checkbox>
          </FormGroup>
          <FormGroup
            className="ui form"
            style={{display: "flex", padding: "0rem 0rem 0rem 2rem"}}>
            <Checkbox
              checked={props.onHandleGetCheckboxValue("isXray")}
              disabled={props.onHandleDisableForm}
              id="CardVisaExtensionCertificate-Checkbox-IsXray"
              label="เอ็กซเรย์"
              onChange={(e, data) => props.onHandleChangeCheckbox(e, "isXray", data)}
              style={{display: "flex",  alignItems: "center", width: "14rem", fontSize: "12px"}}>
            </Checkbox>
            <Checkbox
              checked={props.onHandleGetCheckboxValue("isProcedure")}
              disabled={props.onHandleDisableForm}
              id="CardVisaExtensionCertificate-Checkbox-IsProcedure"
              label="ทำหัตถการ กรุณาระบุ"
              onChange={(e, data) => props.onHandleChangeCheckbox(e, "isProcedure", data)}
              style={{display: "flex",  alignItems: "center", width: "10rem", fontSize: "12px"}}>
            </Checkbox>
            <Input
              disabled={props.disableProcedureNote || props.onHandleDisableForm}
              id="CardVisaExtensionCertificate-Input-NoteProcedure"
              onChange={(e) => props.onHandleChangeText("isProcedureNote", e)}
              value={props.isProcedureNoteValue}>
            </Input>
          </FormGroup>
          <FormGroup
            className="ui form"
            style={{display: "flex", padding: "0rem 0rem 0rem 2rem"}}>
            <Checkbox
              checked={props.onHandleGetCheckboxValue("isPathology")}
              disabled={props.onHandleDisableForm}
              id="CardVisaExtensionCertificate-Checkbox-IsPathology"
              label="การตรวจวิเคราะห์ทางพยาธิวิทยา"
              onChange={(e, data) => props.onHandleChangeCheckbox(e, "isPathology", data)}
              style={{display: "flex",  alignItems: "center", width: "14rem", fontSize: "12px"}}>
            </Checkbox>
            <Checkbox
              checked={props.onHandleGetCheckboxValue("isSurgery")}
              disabled={props.onHandleDisableForm}
              id="CardVisaExtensionCertificate-Checkbox-IsSurgery"
              label="ผ่าตัด กรุณาระบุ"
              onChange={(e, data) => props.onHandleChangeCheckbox(e, "isSurgery", data)}
              style={{display: "flex",  alignItems: "center", width: "10rem", fontSize: "12px"}}>
            </Checkbox>
            <Input
              disabled={props.disableSurgeryNote || props.onHandleDisableForm}
              id="CardVisaExtensionCertificate-Input-NoteSurgery"
              onChange={(e) => props.onHandleChangeText("isSurgeryNote", e)}
              value={props.isSurgeryNoteValue}>
            </Input>
          </FormGroup>
          <FormGroup
            className="ui form"
            style={{display: "flex", padding: "0rem 0rem 0rem 2rem"}}>
            <Checkbox
              checked={props.onHandleGetCheckboxValue("isBandage")}
              disabled={props.onHandleDisableForm}
              id="CardVisaExtensionCertificate-Checkbox-IsBandage"
              label="ทำแผล"
              onChange={(e, data) => props.onHandleChangeCheckbox(e, "isBandage", data)}
              style={{display: "flex",  alignItems: "center", width: "14rem", fontSize: "12px"}}>
            </Checkbox>
            <Checkbox
              checked={props.onHandleGetCheckboxValue("isOther")}
              disabled={props.onHandleDisableForm}
              id="CardVisaExtensionCertificate-Checkbox-IsOther"
              label="อื่น ๆ กรุณาระบุ"
              onChange={(e, data) => props.onHandleChangeCheckbox(e, "isOther", data)}
              style={{display: "flex",  alignItems: "center", width: "10rem", fontSize: "12px"}}>
            </Checkbox>
            <Input
              disabled={props.disableOtherNote || props.onHandleDisableForm}
              id="CardVisaExtensionCertificate-Input-NoteOther"
              onChange={(e) => props.onHandleChangeText("isOtherNote", e)}
              value={props.isOtherNoteValue}>
            </Input>
          </FormGroup>
          <FormGroup
            className="ui form"
            style={{display: "flex", padding: "0rem 0rem 0rem 1rem"}}>
            <div
              style={{display: "flex",  fontWeight: "bold"}}>
              ความเห็นของแพทย์:
            </div>
          </FormGroup>
          <FormGroup
            className="ui form"
            style={{display: "flex", padding: "0rem 0rem 0rem 1rem"}}>
            <label
              style={{display: "flex",  alignItems: "center", width: "16rem", fontWeight: "bold", fontSize: "12px"}}>
              ผู้ป่วยจำเป็นต้องได้รับการรักษาต่อเนื่อง
            </label>
            <Checkbox
              checked={props.durationSelected("15")}
              disabled={props.onHandleDisableForm}
              id="CardVisaExtensionCertificate-Checkbox-15"
              label="15 วัน"
              onChange={(e) => props.onHandleChangeDuration(e, "15")}
              style={{display: "flex",  alignItems: "center", width: "4.5rem", fontSize: "12px"}}>
            </Checkbox>
            <Checkbox
              checked={props.durationSelected("21")}
              disabled={props.onHandleDisableForm}
              id="CardVisaExtensionCertificate-Checkbox-21"
              label="21 วัน"
              onChange={(e) => props.onHandleChangeDuration(e, "21")}
              style={{display: "flex",  alignItems: "center", width: "4.5rem", fontSize: "12px"}}>
            </Checkbox>
            <Checkbox
              checked={props.durationSelected("30")}
              disabled={props.onHandleDisableForm}
              id="CardVisaExtensionCertificate-Checkbox-30"
              label="30 วัน"
              onChange={(e) => props.onHandleChangeDuration(e, "30")}
              style={{display: "flex",  alignItems: "center", width: "4.5rem", fontSize: "12px"}}>
            </Checkbox>
          </FormGroup>
          <FormGroup
            className="ui form"
            style={{display: "flex", padding: "0rem 0rem 0rem 17rem"}}>
            <Checkbox
              checked={props.durationSelected("45")}
              disabled={props.onHandleDisableForm}
              id="CardVisaExtensionCertificate-Checkbox-45"
              label="45 วัน"
              onChange={(e) => props.onHandleChangeDuration(e, "45")}
              style={{display: "flex",  alignItems: "center", width: "4.5rem", fontSize: "12px"}}>
            </Checkbox>
            <Checkbox
              checked={props.durationSelected("60")}
              disabled={props.onHandleDisableForm}
              id="CardVisaExtensionCertificate-Checkbox-60"
              label="60 วัน"
              onChange={(e) => props.onHandleChangeDuration(e, "60")}
              style={{display: "flex",  alignItems: "center", width: "4.5rem", fontSize: "12px"}}>
            </Checkbox>
            <Checkbox
              checked={props.durationSelected("90")}
              disabled={props.onHandleDisableForm}
              id="CardVisaExtensionCertificate-Checkbox-90"
              label="90 วัน"
              onChange={(e) => props.onHandleChangeDuration(e, "90")}
              style={{display: "flex",  alignItems: "center", width: "4.5rem", fontSize: "12px"}}>
            </Checkbox>
            <label
              style={{display: "flex",  alignItems: "center", fontSize: "12px"}}>
              จึงเป็นอุปสรรคต่อการเดินทาง
            </label>
          </FormGroup>
          <FormGroup
            className="ui form"
            style={{display: "flex", padding: "0rem 0rem 0rem 1rem"}}>
            <label
              style={{display: "flex",  alignItems: "center", width: "12rem", fontWeight: "bold"}}>
              แพทย์ผู้ตรวจรักษา
            </label>
            <Dropdown
              disabled={props.onHandleDisableForm}
              icon="search"
              id="CardVisaExtensionCertificate-Dropdown-Doctor"
              onChange={props.onHandleChangeDoctor}
              options={props.doctorOptions}
              search={true}
              selection={true}
              style={{minWidth: "20rem"}}
              value={props.doctorValue}>
            </Dropdown>
          </FormGroup>
          <FormGroup
            className="ui form"
            style={{display: "flex", justifyContent: "flex-end"}}>
            <label>
              {props.onCloseSelected}
            </label>
          </FormGroup>
        </Form>
      </div>
    )
}


export default CardVisaExtensionCertificateUX

export const screenPropsDefault = {}

/* Date Time : Tue Aug 06 2024 10:19:00 GMT+0700 (Indochina Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "seq": 0
    },
    {
      "from": "semantic-ui-react",
      "id": 1,
      "name": "Form",
      "parent": 0,
      "props": {
      },
      "seq": 1,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 2,
      "name": "FormGroup",
      "parent": 1,
      "props": {
        "className": {
          "type": "value",
          "value": "ui form"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", padding: \"1rem 0rem 0rem 1rem\"}"
        }
      },
      "seq": 2,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 3,
      "name": "FormGroup",
      "parent": 1,
      "props": {
        "className": {
          "type": "value",
          "value": "ui form"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", padding: \"0rem 0rem 0rem 1rem\"}"
        }
      },
      "seq": 3,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 4,
      "name": "FormGroup",
      "parent": 1,
      "props": {
        "className": {
          "type": "value",
          "value": "ui form"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", padding: \"0rem 0rem 0rem 1rem\"}"
        }
      },
      "seq": 4,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 5,
      "name": "FormGroup",
      "parent": 1,
      "props": {
        "className": {
          "type": "value",
          "value": "ui form"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", padding: \"0rem 0rem 0rem 1rem\"}"
        }
      },
      "seq": 5,
      "void": false
    },
    {
      "from": null,
      "id": 6,
      "name": "label",
      "parent": 2,
      "props": {
        "children": {
          "type": "value",
          "value": "สัญชาติ"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\",  alignItems: \"center\", width: \"12rem\", fontWeight: \"bold\"}"
        }
      },
      "seq": 6,
      "void": false
    },
    {
      "from": null,
      "id": 10,
      "name": "label",
      "parent": 3,
      "props": {
        "children": {
          "type": "value",
          "value": "หนังสือเดินทางเลขที่"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\",  alignItems: \"center\", width: \"12rem\", fontWeight: \"bold\"}"
        }
      },
      "seq": 10,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 11,
      "name": "Input",
      "parent": 3,
      "props": {
        "disabled": {
          "type": "code",
          "value": "props.onHandleDisableForm"
        },
        "id": {
          "type": "value",
          "value": "CardVisaExtensionCertificate-Input-Passport"
        },
        "onChange": {
          "type": "code",
          "value": "(e) => props.onHandleChangeText(\"patientPassport\", e)"
        },
        "readOnly": {
          "type": "code",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{width: \"20rem\"}"
        },
        "value": {
          "type": "code",
          "value": "props.patientPassport"
        }
      },
      "seq": 11,
      "void": false
    },
    {
      "from": null,
      "id": 13,
      "name": "label",
      "parent": 4,
      "props": {
        "children": {
          "type": "value",
          "value": "มารับการตรวจ"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\",  alignItems: \"center\", width: \"12rem\", fontWeight: \"bold\"}"
        }
      },
      "seq": 13,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 14,
      "name": "Radio",
      "parent": 4,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.opdRadio"
        },
        "disabled": {
          "type": "code",
          "value": "props.onHandleDisableRadio.disableOpd || props.onHandleDisableForm"
        },
        "label": {
          "type": "value",
          "value": "ผู้ป่วยนอก"
        },
        "style": {
          "type": "code",
          "value": "{width: \"8rem\"}"
        }
      },
      "seq": 14,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 15,
      "name": "Radio",
      "parent": 4,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.ipdRadio"
        },
        "disabled": {
          "type": "code",
          "value": "props.onHandleDisableRadio.disableIpd || props.onHandleDisableForm"
        },
        "label": {
          "type": "value",
          "value": "ผู้ป่วยใน"
        },
        "style": {
          "type": "code",
          "value": "{width: \"8rem\"}"
        }
      },
      "seq": 15,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 16,
      "name": "FormGroup",
      "parent": 1,
      "props": {
        "className": {
          "type": "value",
          "value": "ui form"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", padding: \"0rem 0rem 0rem 1rem\"}"
        }
      },
      "seq": 16,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 17,
      "name": "FormGroup",
      "parent": 1,
      "props": {
        "className": {
          "type": "value",
          "value": "ui form"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", padding: \"1rem 0rem 0rem 2rem\"}"
        }
      },
      "seq": 19,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 18,
      "name": "FormGroup",
      "parent": 1,
      "props": {
        "className": {
          "type": "value",
          "value": "ui form"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", padding: \"0rem 0rem 0rem 2rem\"}"
        }
      },
      "seq": 24,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 19,
      "name": "FormGroup",
      "parent": 1,
      "props": {
        "className": {
          "type": "value",
          "value": "ui form"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", padding: \"0rem 0rem 0rem 2rem\"}"
        }
      },
      "seq": 27,
      "void": false
    },
    {
      "from": null,
      "id": 20,
      "name": "label",
      "parent": 5,
      "props": {
        "children": {
          "type": "value",
          "value": "อาการนำ"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\",  alignItems: \"center\", width: \"12rem\", fontWeight: \"bold\"}"
        }
      },
      "seq": 20,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 21,
      "name": "TextArea",
      "parent": 5,
      "props": {
        "disabled": {
          "type": "code",
          "value": "props.onHandleDisableForm"
        },
        "id": {
          "type": "value",
          "value": "CardVisaExtensionCertificate-TextArea-CheifComplaint"
        },
        "onChange": {
          "type": "code",
          "value": "(e) => props.onHandleChangeText(\"chiefComplaint\", e)"
        },
        "style": {
          "type": "code",
          "value": "{width: \"25rem\"}"
        },
        "value": {
          "type": "code",
          "value": "props.chiefComplaintValue"
        }
      },
      "seq": 21,
      "void": true
    },
    {
      "from": null,
      "id": 22,
      "name": "label",
      "parent": 16,
      "props": {
        "children": {
          "type": "value",
          "value": "การวินิจฉัยโรค"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\",  alignItems: \"center\", width: \"12rem\", fontWeight: \"bold\"}"
        }
      },
      "seq": 22,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 23,
      "name": "TextArea",
      "parent": 16,
      "props": {
        "disabled": {
          "type": "code",
          "value": "props.onHandleDisableForm"
        },
        "id": {
          "type": "value",
          "value": "CardVisaExtensionCertificate-TextArea-Diagnosis"
        },
        "onChange": {
          "type": "code",
          "value": "(e) => props.onHandleChangeText(\"diagnosis\", e)"
        },
        "style": {
          "type": "code",
          "value": "{width: \"25rem\"}"
        },
        "value": {
          "type": "code",
          "value": "props.diagnosisValue"
        }
      },
      "seq": 23,
      "void": true
    },
    {
      "from": "semantic-ui-react",
      "id": 25,
      "name": "Checkbox",
      "parent": 17,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.onHandleGetCheckboxValue(\"isDrug\")"
        },
        "disabled": {
          "type": "code",
          "value": "props.onHandleDisableForm"
        },
        "id": {
          "type": "value",
          "value": "CardVisaExtensionCertificate-Checkbox-IsDrug"
        },
        "label": {
          "type": "value",
          "value": "ให้ยา"
        },
        "onChange": {
          "type": "code",
          "value": "(e, data) => props.onHandleChangeCheckbox(e, \"isDrug\", data)"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\",  alignItems: \"center\", width: \"14rem\", fontSize: \"12px\"}"
        }
      },
      "seq": 25,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 26,
      "name": "Checkbox",
      "parent": 17,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.onHandleGetCheckboxValue(\"isPhysicalTherapy\")"
        },
        "disabled": {
          "type": "code",
          "value": "props.onHandleDisableForm"
        },
        "id": {
          "type": "value",
          "value": "CardVisaExtensionCertificate-Checkbox-IsPhysicalTherapy"
        },
        "label": {
          "type": "value",
          "value": "กายภาพบำบัด"
        },
        "onChange": {
          "type": "code",
          "value": "(e, data) => props.onHandleChangeCheckbox(e, \"isPhysicalTherapy\", data)"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\",  alignItems: \"center\", fontSize: \"12px\"}"
        }
      },
      "seq": 26,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 27,
      "name": "FormGroup",
      "parent": 1,
      "props": {
        "className": {
          "type": "value",
          "value": "ui form"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", padding: \"0rem 0rem 0rem 2rem\"}"
        }
      },
      "seq": 29,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 29,
      "name": "FormGroup",
      "parent": 1,
      "props": {
        "className": {
          "type": "value",
          "value": "ui form"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", padding: \"0rem 0rem 0rem 1rem\"}"
        }
      },
      "seq": 17,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 30,
      "name": "Checkbox",
      "parent": 18,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.onHandleGetCheckboxValue(\"isXray\")"
        },
        "disabled": {
          "type": "code",
          "value": "props.onHandleDisableForm"
        },
        "id": {
          "type": "value",
          "value": "CardVisaExtensionCertificate-Checkbox-IsXray"
        },
        "label": {
          "type": "value",
          "value": "เอ็กซเรย์"
        },
        "onChange": {
          "type": "code",
          "value": "(e, data) => props.onHandleChangeCheckbox(e, \"isXray\", data)"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\",  alignItems: \"center\", width: \"14rem\", fontSize: \"12px\"}"
        }
      },
      "seq": 30,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 31,
      "name": "Checkbox",
      "parent": 18,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.onHandleGetCheckboxValue(\"isProcedure\")"
        },
        "disabled": {
          "type": "code",
          "value": "props.onHandleDisableForm"
        },
        "id": {
          "type": "value",
          "value": "CardVisaExtensionCertificate-Checkbox-IsProcedure"
        },
        "label": {
          "type": "value",
          "value": "ทำหัตถการ กรุณาระบุ"
        },
        "onChange": {
          "type": "code",
          "value": "(e, data) => props.onHandleChangeCheckbox(e, \"isProcedure\", data)"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\",  alignItems: \"center\", width: \"10rem\", fontSize: \"12px\"}"
        }
      },
      "seq": 31,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 32,
      "name": "Input",
      "parent": 18,
      "props": {
        "disabled": {
          "type": "code",
          "value": "props.disableProcedureNote || props.onHandleDisableForm"
        },
        "id": {
          "type": "value",
          "value": "CardVisaExtensionCertificate-Input-NoteProcedure"
        },
        "onChange": {
          "type": "code",
          "value": "(e) => props.onHandleChangeText(\"isProcedureNote\", e)"
        },
        "value": {
          "type": "code",
          "value": "props.isProcedureNoteValue"
        }
      },
      "seq": 32,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 33,
      "name": "Checkbox",
      "parent": 19,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.onHandleGetCheckboxValue(\"isPathology\")"
        },
        "disabled": {
          "type": "code",
          "value": "props.onHandleDisableForm"
        },
        "id": {
          "type": "value",
          "value": "CardVisaExtensionCertificate-Checkbox-IsPathology"
        },
        "label": {
          "type": "value",
          "value": "การตรวจวิเคราะห์ทางพยาธิวิทยา"
        },
        "onChange": {
          "type": "code",
          "value": "(e, data) => props.onHandleChangeCheckbox(e, \"isPathology\", data)"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\",  alignItems: \"center\", width: \"14rem\", fontSize: \"12px\"}"
        }
      },
      "seq": 33,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 34,
      "name": "Checkbox",
      "parent": 19,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.onHandleGetCheckboxValue(\"isSurgery\")"
        },
        "disabled": {
          "type": "code",
          "value": "props.onHandleDisableForm"
        },
        "id": {
          "type": "value",
          "value": "CardVisaExtensionCertificate-Checkbox-IsSurgery"
        },
        "label": {
          "type": "value",
          "value": "ผ่าตัด กรุณาระบุ"
        },
        "onChange": {
          "type": "code",
          "value": "(e, data) => props.onHandleChangeCheckbox(e, \"isSurgery\", data)"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\",  alignItems: \"center\", width: \"10rem\", fontSize: \"12px\"}"
        }
      },
      "seq": 34,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 35,
      "name": "Checkbox",
      "parent": 27,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.onHandleGetCheckboxValue(\"isBandage\")"
        },
        "disabled": {
          "type": "code",
          "value": "props.onHandleDisableForm"
        },
        "id": {
          "type": "value",
          "value": "CardVisaExtensionCertificate-Checkbox-IsBandage"
        },
        "label": {
          "type": "value",
          "value": "ทำแผล"
        },
        "onChange": {
          "type": "code",
          "value": "(e, data) => props.onHandleChangeCheckbox(e, \"isBandage\", data)"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\",  alignItems: \"center\", width: \"14rem\", fontSize: \"12px\"}"
        }
      },
      "seq": 35,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 36,
      "name": "Checkbox",
      "parent": 27,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.onHandleGetCheckboxValue(\"isOther\")"
        },
        "disabled": {
          "type": "code",
          "value": "props.onHandleDisableForm"
        },
        "id": {
          "type": "value",
          "value": "CardVisaExtensionCertificate-Checkbox-IsOther"
        },
        "label": {
          "type": "value",
          "value": "อื่น ๆ กรุณาระบุ"
        },
        "onChange": {
          "type": "code",
          "value": "(e, data) => props.onHandleChangeCheckbox(e, \"isOther\", data)"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\",  alignItems: \"center\", width: \"10rem\", fontSize: \"12px\"}"
        }
      },
      "seq": 36,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 39,
      "name": "Input",
      "parent": 19,
      "props": {
        "disabled": {
          "type": "code",
          "value": "props.disableSurgeryNote || props.onHandleDisableForm"
        },
        "id": {
          "type": "value",
          "value": "CardVisaExtensionCertificate-Input-NoteSurgery"
        },
        "onChange": {
          "type": "code",
          "value": "(e) => props.onHandleChangeText(\"isSurgeryNote\", e)"
        },
        "value": {
          "type": "code",
          "value": "props.isSurgeryNoteValue"
        }
      },
      "seq": 39,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 40,
      "name": "Input",
      "parent": 27,
      "props": {
        "disabled": {
          "type": "code",
          "value": "props.disableOtherNote || props.onHandleDisableForm"
        },
        "id": {
          "type": "value",
          "value": "CardVisaExtensionCertificate-Input-NoteOther"
        },
        "onChange": {
          "type": "code",
          "value": "(e) => props.onHandleChangeText(\"isOtherNote\", e)"
        },
        "value": {
          "type": "code",
          "value": "props.isOtherNoteValue"
        }
      },
      "seq": 40,
      "void": false
    },
    {
      "from": null,
      "id": 41,
      "name": "div",
      "parent": 55,
      "props": {
        "children": {
          "type": "value",
          "value": "ความเห็นของแพทย์:"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\",  fontWeight: \"bold\"}"
        }
      },
      "seq": 41,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 42,
      "name": "FormGroup",
      "parent": 1,
      "props": {
        "className": {
          "type": "value",
          "value": "ui form"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", padding: \"0rem 0rem 0rem 1rem\"}"
        }
      },
      "seq": 43,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 43,
      "name": "FormGroup",
      "parent": 1,
      "props": {
        "className": {
          "type": "value",
          "value": "ui form"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", padding: \"0rem 0rem 0rem 1rem\"}"
        }
      },
      "seq": 58,
      "void": false
    },
    {
      "from": null,
      "id": 44,
      "name": "label",
      "parent": 42,
      "props": {
        "children": {
          "type": "value",
          "value": "ผู้ป่วยจำเป็นต้องได้รับการรักษาต่อเนื่อง"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\",  alignItems: \"center\", width: \"16rem\", fontWeight: \"bold\", fontSize: \"12px\"}"
        }
      },
      "seq": 44,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 45,
      "name": "Checkbox",
      "parent": 42,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.durationSelected(\"15\")"
        },
        "disabled": {
          "type": "code",
          "value": "props.onHandleDisableForm"
        },
        "id": {
          "type": "value",
          "value": "CardVisaExtensionCertificate-Checkbox-15"
        },
        "label": {
          "type": "value",
          "value": "15 วัน"
        },
        "onChange": {
          "type": "code",
          "value": "(e) => props.onHandleChangeDuration(e, \"15\")"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\",  alignItems: \"center\", width: \"4.5rem\", fontSize: \"12px\"}"
        }
      },
      "seq": 45,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 46,
      "name": "Checkbox",
      "parent": 42,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.durationSelected(\"21\")"
        },
        "disabled": {
          "type": "code",
          "value": "props.onHandleDisableForm"
        },
        "id": {
          "type": "value",
          "value": "CardVisaExtensionCertificate-Checkbox-21"
        },
        "label": {
          "type": "value",
          "value": "21 วัน"
        },
        "onChange": {
          "type": "code",
          "value": "(e) => props.onHandleChangeDuration(e, \"21\")"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\",  alignItems: \"center\", width: \"4.5rem\", fontSize: \"12px\"}"
        }
      },
      "seq": 46,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 47,
      "name": "Checkbox",
      "parent": 42,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.durationSelected(\"30\")"
        },
        "disabled": {
          "type": "code",
          "value": "props.onHandleDisableForm"
        },
        "id": {
          "type": "value",
          "value": "CardVisaExtensionCertificate-Checkbox-30"
        },
        "label": {
          "type": "value",
          "value": "30 วัน"
        },
        "onChange": {
          "type": "code",
          "value": "(e) => props.onHandleChangeDuration(e, \"30\")"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\",  alignItems: \"center\", width: \"4.5rem\", fontSize: \"12px\"}"
        }
      },
      "seq": 47,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 48,
      "name": "Checkbox",
      "parent": 58,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.durationSelected(\"45\")"
        },
        "disabled": {
          "type": "code",
          "value": "props.onHandleDisableForm"
        },
        "id": {
          "type": "value",
          "value": "CardVisaExtensionCertificate-Checkbox-45"
        },
        "label": {
          "type": "value",
          "value": "45 วัน"
        },
        "onChange": {
          "type": "code",
          "value": "(e) => props.onHandleChangeDuration(e, \"45\")"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\",  alignItems: \"center\", width: \"4.5rem\", fontSize: \"12px\"}"
        }
      },
      "seq": 48,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 49,
      "name": "Checkbox",
      "parent": 58,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.durationSelected(\"60\")"
        },
        "disabled": {
          "type": "code",
          "value": "props.onHandleDisableForm"
        },
        "id": {
          "type": "value",
          "value": "CardVisaExtensionCertificate-Checkbox-60"
        },
        "label": {
          "type": "value",
          "value": "60 วัน"
        },
        "onChange": {
          "type": "code",
          "value": "(e) => props.onHandleChangeDuration(e, \"60\")"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\",  alignItems: \"center\", width: \"4.5rem\", fontSize: \"12px\"}"
        }
      },
      "seq": 49,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 50,
      "name": "Checkbox",
      "parent": 58,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.durationSelected(\"90\")"
        },
        "disabled": {
          "type": "code",
          "value": "props.onHandleDisableForm"
        },
        "id": {
          "type": "value",
          "value": "CardVisaExtensionCertificate-Checkbox-90"
        },
        "label": {
          "type": "value",
          "value": "90 วัน"
        },
        "onChange": {
          "type": "code",
          "value": "(e) => props.onHandleChangeDuration(e, \"90\")"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\",  alignItems: \"center\", width: \"4.5rem\", fontSize: \"12px\"}"
        }
      },
      "seq": 50,
      "void": false
    },
    {
      "from": null,
      "id": 51,
      "name": "label",
      "parent": 58,
      "props": {
        "children": {
          "type": "value",
          "value": "จึงเป็นอุปสรรคต่อการเดินทาง"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\",  alignItems: \"center\", fontSize: \"12px\"}"
        }
      },
      "seq": 51,
      "void": false
    },
    {
      "from": null,
      "id": 52,
      "name": "label",
      "parent": 43,
      "props": {
        "children": {
          "type": "value",
          "value": "แพทย์ผู้ตรวจรักษา"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\",  alignItems: \"center\", width: \"12rem\", fontWeight: \"bold\"}"
        }
      },
      "seq": 52,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 55,
      "name": "FormGroup",
      "parent": 1,
      "props": {
        "className": {
          "type": "value",
          "value": "ui form"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", padding: \"0rem 0rem 0rem 1rem\"}"
        }
      },
      "seq": 42,
      "void": false
    },
    {
      "from": null,
      "id": 57,
      "name": "label",
      "parent": 29,
      "props": {
        "children": {
          "type": "value",
          "value": "การรักษา/การตรวจวินิจฉัย (Treatment/Investigation)"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", fontWeight: \"bold\"}"
        }
      },
      "seq": 57,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 58,
      "name": "FormGroup",
      "parent": 1,
      "props": {
        "className": {
          "type": "value",
          "value": "ui form"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", padding: \"0rem 0rem 0rem 17rem\"}"
        }
      },
      "seq": 55,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 59,
      "name": "Dropdown",
      "parent": 2,
      "props": {
        "disabled": {
          "type": "code",
          "value": "props.disableNationality || props.onHandleDisableForm"
        },
        "icon": {
          "type": "value",
          "value": "search"
        },
        "id": {
          "type": "value",
          "value": "CardVisaExtensionCertificate-Dropdown-Nationality"
        },
        "onChange": {
          "type": "code",
          "value": "props.onHandleChangeNationality"
        },
        "options": {
          "type": "code",
          "value": "props.nationalityOptions"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"20rem\"}"
        },
        "value": {
          "type": "code",
          "value": "props.nationalityValue"
        }
      },
      "seq": 59,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 60,
      "name": "Dropdown",
      "parent": 43,
      "props": {
        "disabled": {
          "type": "code",
          "value": "props.onHandleDisableForm"
        },
        "icon": {
          "type": "value",
          "value": "search"
        },
        "id": {
          "type": "value",
          "value": "CardVisaExtensionCertificate-Dropdown-Doctor"
        },
        "onChange": {
          "type": "code",
          "value": "props.onHandleChangeDoctor"
        },
        "options": {
          "type": "code",
          "value": "props.doctorOptions"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"20rem\"}"
        },
        "value": {
          "type": "code",
          "value": "props.doctorValue"
        }
      },
      "seq": 60,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 61,
      "name": "FormGroup",
      "parent": 1,
      "props": {
        "className": {
          "type": "value",
          "value": "ui form"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", justifyContent: \"flex-end\"}"
        }
      },
      "seq": 61,
      "void": false
    },
    {
      "from": null,
      "id": 62,
      "name": "label",
      "parent": 61,
      "props": {
        "children": {
          "type": "value",
          "value": "{props.onCloseSelected}"
        }
      },
      "seq": 62,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 80,
  "name": "CardVisaExtensionCertificateUX",
  "project": "IsHealth",
  "screenPropsDefault": {
  },
  "width": 80
}

*********************************************************************************** */
