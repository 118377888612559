import React from "react";
import PropTypes from "prop-types";
import {
  Segment,
  Icon,
  Form,
  Input,
  TextArea,
  Button,
  Item
} from "semantic-ui-react";
import { RightLabel, DateTextBox, TimeTextBox } from "../common";
import _ from "../../compat/lodashplus";
import * as Util from "../../utils";
import * as Common from "../common";
import moment from "moment";

const SubNurseNoteItem = React.forwardRef((props, ref) => {
  const [saveDate, setSaveDate] = React.useState(Util.formatDate(moment()));
  const [saveTime, setSaveTime] = React.useState(moment().format("HH:mm"));
  const [workingShift, setWorkingShift] = React.useState("");
  const [plan, setPlan] = React.useState("");
  const [goal, setGoal] = React.useState("");
  const [diagnosis, setDiagnosis] = React.useState("");
  const [username, setUsername] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [error, setError] = React.useState(null)
  const [isLoading, setIsLoading] = React.useState(false)
  const [nurseNoteItemId, setNurseNoteItemId] = React.useState(null)

	React.useImperativeHandle(ref, () => ({
		clear: () => {
      setSaveDate(Util.formatDate(moment()))
      setSaveTime(Util.formatDate(moment()))
      setWorkingShift("")
      setPlan("")
      setGoal("")
      setDiagnosis("")
      setUsername("")
      setPassword("")
      setNurseNoteItemId(null)  
    }
	}));

  React.useEffect(() => {
    if(!props.date){
      return
    }
    let date = props.date.split(' [')[0]
    let time = props.date.split(' [')[1].slice(0, -1);
    setSaveDate(date)
    setSaveTime(time)
  }, [props.date])

  React.useEffect(() => {
    setNurseNoteItemId(props.nurseNoteItemId)
  }, [props.nurseNoteItemId])

  React.useEffect(() => {
    setWorkingShift(props.workingShift)
  }, [props.workingShift])

  React.useEffect(() => {
    setPlan(props.plan)
  }, [props.plan])

  React.useEffect(() => {
    setGoal(props.goal)
  }, [props.goal])

  React.useEffect(() => {
    setDiagnosis(props.diagnosis)
  }, [props.diagnosis])

  const handleEdit = async (params) => {
    setIsLoading(true)
    const [data, error] = await props.controller.putNurseNoteItem({...params, nurseNoteItemId});
    if(error){
      setError(error)
    } else if (data){
      props.onSaved()
    }
    setIsLoading(false)
  }

  const handleCreate = async (params) => {
    setIsLoading(true)
    const [data, error] = await props.controller.postNurseNoteItem({...params});
    if(error){
      setError(error)
    } else if (data){
      props.onSaved()
    }
    setIsLoading(false)
  }

  const handleSave = () => {
    let token = props.django.csrf;
    setError(null)
    let params = {
      date: saveDate,
      time: saveTime,
      workingShift,
      plan,
      goal,
      diagnosis,
      username,
      password,
      nurseNoteId: props.nurseNoteId,
      encounter: props.patientData && props.patientData.ENCOUNTER ? props.patientData.ENCOUNTER.encounter_id : null,
      token,
      division: props.division
    }
    if(nurseNoteItemId){
      handleEdit(params)
    } else {
      handleCreate(params)
    }
  };

  return (
    <Segment raised color="green" loading={isLoading}>
      <Form>
        <Button
          className="noBoxShadow"
          style={styles.closeButton}
          onClick={props.onClose}
          type='button'
        >
          <Icon
            style={styles.closeIcon}
            size="large"
            color="red"
            name="close"
          />
        </Button>
        <Common.ErrorMessage error={error}/>
        <Form.Group inline style={{ display: !props.isIPD ? "none" : null }}>
          <Form.Field width={4}>
            <RightLabel>เวร</RightLabel>
          </Form.Field>
          <Form.Field width={5}>
            <Input
              testid="txtWorkingShift"
              value={workingShift}
              onChange={e => setWorkingShift(e.target.value)}
            />
          </Form.Field>
        </Form.Group>
        <Form.Group inline>
          <Form.Field width={4}>
            <RightLabel>วันที่และเวลาที่บันทึก</RightLabel>
          </Form.Field>
          <Form.Field width={5}>
            <DateTextBox
              testid="dtbSave"
              value={saveDate}
              onChange={date => setSaveDate(date)}
            />
          </Form.Field>
          <Form.Field width={3}>
            <TimeTextBox
              testid="ttbSave"
              search
              value={saveTime}
              onTimeChange={(e, value) => setSaveTime(value)}
            />
          </Form.Field>
        </Form.Group>
        <Form.Group inline>
          <Form.Field width={4}>
            <RightLabel>
              {props.isIPD ? _("การรักษาพยาบาล") : _("ข้อมูล/อาการ/ปัญหา")}
            </RightLabel>
          </Form.Field>
          <Form.Field width={12}>
            <TextArea
              testid="txtPlan"
              rows={5}
              value={plan}
              onChange={e => setPlan(e.target.value)}
            />
          </Form.Field>
        </Form.Group>
        <Form.Group inline>
          <Form.Field width={4}>
            <RightLabel>
              {props.isIPD
                ? _("ข้อวินิจฉัยการพยาบาลและข้อมูลสนับสนุน")
                : _("การพยาบาล Note")}
            </RightLabel>
          </Form.Field>
          <Form.Field width={12}>
            <TextArea
              testid="txtDiagnosis"
              rows={5}
              value={diagnosis}
              onChange={e => setDiagnosis(e.target.value)}
            />
          </Form.Field>
        </Form.Group>
        <Form.Group inline>
          <Form.Field width={4}>
            <RightLabel>
              {props.isIPD
                ? _("กิจกรรมการพยาบาลและการประเมินผล")
                : _("ผลการพยาบาล")}
            </RightLabel>
          </Form.Field>
          <Form.Field width={12}>
            <TextArea
              testid="txtGoal"
              rows={5}
              value={goal}
              onChange={e => setGoal(e.target.value)}
            />
          </Form.Field>
        </Form.Group>
        <Form.Group inline>
          <Form.Field width={4}>
            <RightLabel>Username</RightLabel>
          </Form.Field>
          <Form.Field width={4}>
            <Input
              testid="txtUsername"
              value={username}
              placeholder='Username'
              onChange={e => setUsername(e.target.value)}
            />
          </Form.Field>
          <Form.Field width={5}>
            <label>Password</label>
            <Input
              type="password"
              testid="txtPassword"
              value={password}
              placeholder='Password'
              onChange={e => setPassword(e.target.value)}
            />
          </Form.Field>
          <Form.Field width={3}>
            <Button fluid color="green" content="บันทึก" onClick={handleSave} type='submit'/>
          </Form.Field>
        </Form.Group>
      </Form>
    </Segment>
  );
});

const styles = {
  closeButton: {
    position: "absolute",
    right: "5px",
    padding: 0,
    border: "0px solid",
    background: "transparent"
  },
  closeIcon: {
    margin: 0
  }
};

SubNurseNoteItem.defaultProps = {
  onClose: () => {},
  controller: {},
  patientData: {},
  readOnly: false,
  isIPD: false,
  django: {},
  nurseNoteId: null,
  onSaved: () => {},
  workingShift: '',
  plan: '',
  goal: '',
  diagnosis: '',
  id: null,
  division: null,
};

SubNurseNoteItem.propTypes = {
  onClose: PropTypes.func,
  controller: PropTypes.object,
  patientData: PropTypes.object,
  readOnly: PropTypes.bool,
  isIPD: PropTypes.bool,
  django: PropTypes.object,
  nurseNoteId: PropTypes.number,
  onSaved: PropTypes.func,
  workingShift: PropTypes.string,
  plan: PropTypes.string,
  goal: PropTypes.string,
  diagnosis: PropTypes.string,
  id: PropTypes.number,
  division: PropTypes.number,
};

export default React.memo(SubNurseNoteItem);
