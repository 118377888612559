import React, { useEffect, useRef, useState, useMemo } from "react";

// UX
import CardMedCertificateReportUX from "./CardMedCertificateReportUX";

// Interface
import { State, formCodeMap } from "./sequence/DoctorCertificate";

// Utils
import moment from "moment";
import { dateToStringWithoutTimeBE } from "react-lib/utils/dateUtils";
import { Button, Dimmer, Dropdown, FormGroup, Loader, Form } from "semantic-ui-react";

// Common
import SnackMessage from "react-lib/apps/common/SnackMessage";

// Card
import CardDeathCertificate from "./CardDeathCertificate";
import CardFiveDiseasesCertificate from "./CardFiveDiseasesCertificate";
import CardExamAbsenceCertificate from "./CardExamAbseneCertifiate";
import CardDrivingCertificate from "./CardDrivingCertificate";
import CardQuarantineCertificate from "./CardQuarantineCertificate";
import CardCovidCertificate from "./CardCovidCertificate";
import CardTravelCertificate from "./CardTravelCertificate";
import CardDefaultCertificate from "./CardDefaultCertificate";
import CardVisaExtensionCertificate from "./CardVisaExtensionCertificate";

// Main Component Props
type CardMedCertificateProps = {
  onEvent: any;
  setProp: any;
  runSequence?: any;
  DoctorCertificateSequence: State["DoctorCertificateSequence"];
  masterOptions?: any;
  errorMessage: any;
};

// Function

// Current Date
const defaultDate = dateToStringWithoutTimeBE(moment());

// Options
const langOptions = [
  { key: 1, value: "ไทย", text: "ไทย" },
  { key: 2, value: "EN", text: "EN" },
];

// Main
const CardMedCertificateReport = (props: CardMedCertificateProps) => {
  // Call Sequence ( Start Seq )
  useEffect(() => {
    props.runSequence({
      sequence: "DoctorCertificate",
      // restart: true,
    });
  }, []);

  // Set State
  // const [isLoading, setIsLoading] = useState(false);
  // const [printCountFiveReport, setPrintCountFiveReport] = useState(props.DoctorCertificateSequence?.fiveReport?.printCount);
  // const [printCount, setPrintCount] = useState(1);

  // 2 Second Loading to Preparing State
  // useEffect(() => {
  //   setIsLoading(true);

  //   setTimeout(() => {
  //     setIsLoading(false);
  //   }, 2000);
  // }, []);

  // Call Get Report Seqeunce
  // console.log("CertReport Report Option: ", props.DoctorCertificateSequence?.reportOption);
  // console.log("CertReport Report List: ", props.DoctorCertificateSequence?.reportList);

  // All Props Value
  // console.log("Props DoctorCertificate: ", props.DoctorCertificateSequence);

  // Change Date
  const handleChangeDateNew = (name: any, date: string) => {
    switch (name) {
      case "startDate":
      case "endDate":
        props.setProp(`DoctorCertificateSequence.filterReport.${name}`, date);
        break;
      default:
        console.log("Doesn't Exist!!");
        break;
    }
  };

  // Language Value
  const handleLanguageValue = (reportType: any) => {
    switch (reportType) {
      case formCodeMap.CardDeathCertificate:
        return props.DoctorCertificateSequence?.deathReport?.reportLanguage;
      case formCodeMap.CardFiveDiseasesCertificate:
        return props.DoctorCertificateSequence?.fiveReport?.reportLanguage;
      case formCodeMap.CardExamAbsenceCertificate:
        return props.DoctorCertificateSequence?.examAbsenceReport?.reportLanguage;
      case formCodeMap.CardDrivingCertificate:
        return props.DoctorCertificateSequence?.drivingReport?.reportLanguage;
      case formCodeMap.CardQuarantineCertificate:
        return props.DoctorCertificateSequence?.quarantineReport?.reportLanguage;
      case formCodeMap.CardCovidCertificate:
        return props.DoctorCertificateSequence?.covidReport?.reportLanguage;
      case formCodeMap.CardTravelCertificate:
        return props.DoctorCertificateSequence?.travelReport?.reportLanguage;
      case formCodeMap.CardVisaExtensionCertificate:
        return props.DoctorCertificateSequence?.visaExtReport?.reportLanguage;
      case "default":
        return props.DoctorCertificateSequence?.filterReport?.reportLanguage;
      default:
        console.error("Report Type Error", reportType);
        break;
    }
  };
  // Change Language
  const handleChangeLanguage = (language: string) => {
    switch (props.DoctorCertificateSequence?.reportOption) {
      case formCodeMap.CardDeathCertificate:
        props.setProp(`DoctorCertificateSequence.deathReport.reportLanguage`, language);
        break;
      case formCodeMap.CardFiveDiseasesCertificate:
        props.setProp(`DoctorCertificateSequence.fiveReport.reportLanguage`, language);
        break;
      case formCodeMap.CardExamAbsenceCertificate:
        props.setProp(`DoctorCertificateSequence.examAbsenceReport.reportLanguage`, language);
        break;
      case formCodeMap.CardDrivingCertificate:
        props.setProp(`DoctorCertificateSequence.drivingReport.reportLanguage`, language);
        break;
      case formCodeMap.CardQuarantineCertificate:
        props.setProp(`DoctorCertificateSequence.quarantineReport.reportLanguage`, language);
        break;
      case formCodeMap.CardCovidCertificate:
        props.setProp(`DoctorCertificateSequence.covidReport.reportLanguage`, language);
        break;
      case formCodeMap.CardTravelCertificate:
        props.setProp(`DoctorCertificateSequence.travelReport.reportLanguage`, language);
        break;
      case formCodeMap.CardVisaExtensionCertificate:
        props.setProp(`DoctorCertificateSequence.visaExtReport.reportLanguage`, language);
        break;
      case "default":
        props.setProp(`DoctorCertificateSequence.filterReport.reportLanguage`, language);
        break;
      default:
        console.error("Report Type Error ", language);
        break;
    }
  };

  // Change Report
  const handleChangeReport = (report: string) => {
    props.setProp(`DoctorCertificateSequence.reportOption`, report);
  };

  // get Value from Report Dropdown
  const handleSelectedReport = (e: any, { value }: { value: string }) => {
    //console.log(value) // formCodeMap.CardDeathCertificate, "Test1"
    if (value === "") {
      value = "default";
    }
    handleChangeReport(value);
  };

  const reportTypeSelect = useMemo(() => {
    switch (props.DoctorCertificateSequence?.reportOption) {
      case formCodeMap.CardDeathCertificate:
        return (
          <CardDeathCertificate
            runSequence={props.runSequence}
            setProp={props.setProp}
            DoctorCertificateSequence={props.DoctorCertificateSequence}
            masterOptions={props.masterOptions}
          />
        );
      case formCodeMap.CardFiveDiseasesCertificate:
        return (
          <CardFiveDiseasesCertificate
            runSequence={props.runSequence}
            setProp={props.setProp}
            DoctorCertificateSequence={props.DoctorCertificateSequence}
            masterOptions={props.masterOptions}
          />
        );
      case formCodeMap.CardExamAbsenceCertificate:
        return (
          <CardExamAbsenceCertificate
            runSequence={props.runSequence}
            setProp={props.setProp}
            DoctorCertificateSequence={props.DoctorCertificateSequence}
            masterOptions={props.masterOptions}
          />
        );
      case formCodeMap.CardDrivingCertificate:
        return (
          <CardDrivingCertificate
            runSequence={props.runSequence}
            setProp={props.setProp}
            DoctorCertificateSequence={props.DoctorCertificateSequence}
            masterOptions={props.masterOptions}
          />
        );
      case formCodeMap.CardQuarantineCertificate:
        return (
          <CardQuarantineCertificate
            runSequence={props.runSequence}
            setProp={props.setProp}
            DoctorCertificateSequence={props.DoctorCertificateSequence}
            masterOptions={props.masterOptions}
          />
        );
      case formCodeMap.CardCovidCertificate:
        return (
          <CardCovidCertificate
            runSequence={props.runSequence}
            setProp={props.setProp}
            DoctorCertificateSequence={props.DoctorCertificateSequence}
            masterOptions={props.masterOptions}
          />
        );
      case formCodeMap.CardTravelCertificate:
        return (
          <CardTravelCertificate
            runSequence={props.runSequence}
            setProp={props.setProp}
            DoctorCertificateSequence={props.DoctorCertificateSequence}
            masterOptions={props.masterOptions}
          />
        );
      case formCodeMap.CardVisaExtensionCertificate:
        return (
          <CardVisaExtensionCertificate
            runSequence={props.runSequence}
            setProp={props.setProp}
            DoctorCertificateSequence={props.DoctorCertificateSequence}
            masterOptions={props.masterOptions}
          />
        );
      case "default":
        return (
          <CardDefaultCertificate
            runSequence={props.runSequence}
            setProp={props.setProp}
            DoctorCertificateSequence={props.DoctorCertificateSequence}
            masterOptions={props.masterOptions}
          />
        );
      default:
        return null;
    }
  }, [props.DoctorCertificateSequence]);

  // Change Doctor Input
  const handleChangeDoctor = (name: any, e: React.ChangeEvent<HTMLInputElement>) => {
    switch (name) {
      case "doctorName":
        props.setProp(`DoctorCertificateSequence.filterReport.${name}`, e.target.value);
        break;
      default:
        console.log("Doesn't Exist!!");
        break;
    }
  };

  // Call Sequence ( Save Report )
  const handleSaveReport = () => {
    props.runSequence({
      sequence: "DoctorCertificate",
      action: "SAVE_REPORT",
      // formCode: formCodeMap[props.DoctorCertificateSequence?.reportOption]
    });
  };

  // Call Sequence ( Preview Report )
  const handlePreviewReport = () => {
    props.runSequence({
      sequence: "DoctorCertificate",
      action: "PREVIEW_REPORT",
    });
  };

  // Call Sequence ( Search Report )
  const handleSearchReport = () => {
    props.runSequence({
      sequence: "DoctorCertificate",
      action: "SEARCH_REPORT",
    });
  };

  // Call Sequence ( Re-Print Report )
  const handleRePrintReport = (reportType: any) => {
    // console.log("Now you're clicking Re-Print Button");

    switch (reportType) {
      case formCodeMap.CardDeathCertificate:
      case formCodeMap.CardFiveDiseasesCertificate:
      case formCodeMap.CardExamAbsenceCertificate:
      case formCodeMap.CardDrivingCertificate:
      case formCodeMap.CardQuarantineCertificate:
      case formCodeMap.CardCovidCertificate:
      case formCodeMap.CardTravelCertificate:
      case formCodeMap.CardVisaExtensionCertificate:
        if (props.DoctorCertificateSequence?.selectedReport?.id) {
          // console.log("Form ID", props.DoctorCertificateSequence?.selectedReport?.id);

          // Call Sequence ( Reprint Report )
          props.runSequence({
            sequence: "DoctorCertificate",
            action: "REPRINT_REPORT",
          });
        } else {
          console.error("Form ID Not Found!!");
        }
        break;
      case "default":
        console.error("Error Selected Form");
        break;
    }
  };

  // Disable Report Type Select
  const handleDisableForm = (selected: any) => {
    if (!props.DoctorCertificateSequence?.isDoctor) {
      return true;
    }

    return selected !== null;
  };

  const handleClearSelectData = () => {
    const reportOption = props.DoctorCertificateSequence?.reportOption;
    // Set selectedReport = null
    props.setProp(`DoctorCertificateSequence.selectedReport`, null);

    // Re-Fetch
    props.runSequence({
      sequence: "DoctorCertificate",
      action: "CLEAR_REPORT",
      callReport: formCodeMap[reportOption || ""],
    });
  };

  // Report Language and Save
  const saveReport = (selected: any) => {
    // Select Report Check
    if (selected == null) {
      switch (props.DoctorCertificateSequence?.reportOption) {
        case formCodeMap.CardDeathCertificate:
        case formCodeMap.CardFiveDiseasesCertificate:
        case formCodeMap.CardExamAbsenceCertificate:
        case formCodeMap.CardDrivingCertificate:
        case formCodeMap.CardQuarantineCertificate:
        case formCodeMap.CardCovidCertificate:
        case formCodeMap.CardTravelCertificate:
        case formCodeMap.CardVisaExtensionCertificate:
          return (
            <Form>
              <FormGroup className="ui form" style={{ justifyContent: "flex-end", gap: "1em" }}>
                <label
                  style={{
                    display: "flex",
                    alignItems: "center",
                    fontWeight: "bold",
                  }}
                >
                  ภาษา
                </label>
                <Dropdown
                  onChange={(e, { value }) => handleChangeLanguage(value)}
                  options={langOptions}
                  selection={true}
                  value={handleLanguageValue(props.DoctorCertificateSequence?.reportOption)}
                  style={{ minWidth: "6em" }}
                ></Dropdown>
                <Button color="green" onClick={handleSaveReport} style={{ width: "10em" }}>
                  บันทึกและพิมพ์
                </Button>
              </FormGroup>
            </Form>
          );
        case "default":
        default:
          return null;
      }
    } else {
      return (
        <Form style={{ marginTop: "-1rem" }}>
          <FormGroup className="ui form" style={{ justifyContent: "flex-end", gap: "1em" }}>
            <Button color="yellow" onClick={handleSaveReport} style={{ width: "10em" }}>
              แก้ไขและพิมพ์
            </Button>
            <Button color="red" style={{ width: "8em" }} onClick={handleClearSelectData}>
              ปิด
            </Button>
          </FormGroup>
        </Form>
      );
    }
  };

  // Re-print Option
  const duplicateReport = (selected: any) => {
    // Select Report Check
    if (selected == null) {
      return (
        <Form>
          <FormGroup
            className="ui form"
            style={{
              justifyContent: "flex-end",
              padding: "2em 0em 0em 0em",
              gap: "1em",
            }}
          >
            <Button color="yellow" onClick={handlePreviewReport} style={{ width: "10em" }}>
              Preview
            </Button>
          </FormGroup>
        </Form>
      );
    } else {
      return (
        <Form>
          <FormGroup className="ui form" style={{ padding: "2em 0em 0em 4em", gap: "1em" }}>
            <label
              style={{
                display: "flex",
                alignItems: "center",
                fontWeight: "bold",
              }}
            >
              เลือกภาษาเพื่อพิมพ์ซ้ำ
            </label>
          </FormGroup>
          <FormGroup className="ui form" style={{ justifyContent: "flex-end", gap: "1em" }}>
            <Dropdown
              placeholder="โปรดเลือกภาษาของเอกสาร"
              selection={true}
              onChange={(e, { value }) => handleChangeLanguage(value)}
              options={langOptions}
              // value={handleLanguageValue(
              //   props.DoctorCertificateSequence?.reportOption
              // )}
            ></Dropdown>
            <Button
              color="green"
              onClick={(e) => handleRePrintReport(props.DoctorCertificateSequence?.reportOption)}
              style={{ width: "10em" }}
            >
              พิมพ์ซ้ำ
            </Button>
            <Button color="yellow" onClick={handlePreviewReport} style={{ width: "10em" }}>
              Preview
            </Button>
          </FormGroup>
        </Form>
      );
    }
  };

  // isDoctor Check
  const handleDoctorCheck = (isDoctor: any) => {
    if (isDoctor === false) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <>
      <SnackMessage
        onEvent={props.onEvent}
        onClose={() => {
          props.setProp("errorMessage.DoctorCertificate", null);
        }}
        error={props.errorMessage?.DoctorCertificate}
        success={null}
      />

      <CardMedCertificateReportUX
        // Report Option Props
        // reportOption={reportOptions}
        reportOption={props.DoctorCertificateSequence?.reportTypeList || []}
        onReportValue={
          props.DoctorCertificateSequence?.reportOption === "default"
            ? ""
            : props.DoctorCertificateSequence?.reportOption || ""
        }
        onHandleReport={handleSelectedReport}
        // Date Props
        startDate={props.DoctorCertificateSequence?.filterReport?.startDate || defaultDate}
        endDate={props.DoctorCertificateSequence?.filterReport?.endDate || defaultDate}
        disabledReportOption={props.DoctorCertificateSequence?.selectedReport}
        onHandleDateNew={handleChangeDateNew}
        // Doctor Props
        onDoctorName={props.DoctorCertificateSequence?.filterReport?.doctorName || ""}
        onHandleDoctorName={handleChangeDoctor}
        // Table Data
        // keyTable={`created, encounter, data.reportType, data.reportLanguage, data.doctorName, data.reportDuplicate`}
        keyTable={`edited, statable, form_type_name, tracked_data.reportLanguage, name, printCount`}
        dataTable={props.DoctorCertificateSequence?.reportList?.map((item: any) => ({
          ...item,
          printCount: item.tracked_data.printCount > 1 ? "สำเนา" : "ต้นฉบับ",
        }))}
        rowProps={(state: any, rowInfo: any, column: any, instance: any) => ({
          style: {
            backgroundColor:
              props.DoctorCertificateSequence?.selectedReport &&
              rowInfo?.original?.statable === props.DoctorCertificateSequence?.selectedReport?.id &&
              rowInfo?.original?.id === props.DoctorCertificateSequence?.selectedReport?.logId
                ? "#a5dae6"
                : "white",
          },

          onClick: (e: any) => {
            // console.log("SELECT Click rowInfo: ", rowInfo?.original);
            // console.log("SELECT Click original id: ", rowInfo?.original?.id);
            // console.log(
            //   "SELECT Click selectedReport id: ",
            //   props.DoctorCertificateSequence?.selectedReport?.id
            // );
            // console.log(
            //   "SELECT Click reportType: ",
            //   rowInfo?.original?.data?.reportType
            // );
            // console.log("SELECT Click id Form: ", rowInfo?.original?.statable);
            // console.log("SELECT Click reportType: ", props.DoctorCertificateSequence?.selectedReport?.id)

            props.runSequence({
              sequence: "DoctorCertificate",
              action: "SELECT_DATA",
              selectedItem: rowInfo?.original,
              selectedReport: rowInfo?.original?.statable,
              selectedReportId: rowInfo?.original?.id,
            });
          },
        })}
        // Language Option Props
        langOptions={langOptions}
        onLanguageValue={handleLanguageValue(props.DoctorCertificateSequence?.reportOption)}
        onHandleLanguage={handleChangeLanguage}
        // Save and Print Certificate [Component]
        onSaveReport={saveReport(props.DoctorCertificateSequence?.selectedReport)}
        // Preview Certificate
        onPreviewReport={handlePreviewReport}
        // Search Certificate
        onSearchReport={handleSearchReport}
        // Certificate Report Props [Component]
        reportTypeSelect={
          // Add new Report at this Function
          reportTypeSelect
        }
        // Disable Report Type Option
        onHandleDisableForm={handleDisableForm(null)}
        // isDoctor Check for Report Type Option
        onHandleDoctorCheck={handleDoctorCheck(props.DoctorCertificateSequence?.isDoctor)}
        // Re-Print [Component]
        onDuplicateReport={duplicateReport(props.DoctorCertificateSequence?.selectedReport)}
        searchReportLoading={props.DoctorCertificateSequence?.searchReportLoading}
        // onLoading={
        //   <Dimmer active={isLoading} inverted>
        //   <Loader inverted>Loading</Loader>
        // </Dimmer>
        // }
      />
    </>
  );
};

export default CardMedCertificateReport;
