import React, { useEffect, useState } from "react";
import { Modal } from "semantic-ui-react";
// UI Common
import ButtonLoadCheck from "react-lib/appcon/common/ButtonLoadCheck";
import SnackMessage from "react-lib/apps/common/SnackMessage";
// UI ANE
import CardPreAnestheticFormUX from "./CardPreAnestheticFormUX";
import CardPreAnestheticTabUX from "./CardPreAnestheticTabUX";
import CardPreAnestheticHistoryUX from "./CardPreAnestheticHistoryUX";
import CardPreAnestheticAirwayEvaluationUX from "./CardPreAnestheticAirwayEvaluationUX";
import CardPreAnestheticBloodChemistryUX from "./CardPreAnestheticBloodChemistryUX";
import CardPreAnestheticProblemsConsiderationsUX from "./CardPreAnestheticProblemsConsiderationsUX";
import CardPreAnestheticRiskUX from "./CardPreAnestheticRiskUX";
import CardPreAnestheticReEvaluationUX from "./CardPreAnestheticReEvaluationUX";
import CardPhysicianEvaluation from "react-lib/apps/HISV3/ANE/CardPhysicianEvaluation";
import SubAssistantsInput from "../ORM/SubAssistantsInput";
import SearchBoxWithKey from "react-lib/appcon/common/SearchBoxWithKey";
//
import CardANSLog from "react-lib/apps/ANS/CardANSLog";
// seq
import { FORM_DATA_ACTION } from "./sequence/PreAnesthetic";

const CARD_KEY: string = "CardPreAnesthetic";
const FORM_CODE: string = "CardPreanestheticAnesthesiaRecord";
const FORM_VERSION: string = "1.0";

type CardPreAnestheticProps = {
  // function
  onEvent: any;
  setProp: any;

  // CommonInterface
  errorMessage?: any;
  successMessage?: any;
  buttonLoadCheck?: any;

  // options
  masterOptions?: any;
  masterData?: any;

  // seq
  runSequence?: any;
  PreAnestheticSequence?: any;
  selectedDoctor?: any;
  selectedAnesthesiologist?: any;
  searchedItemListWithKey?: any;
};

const CardPreAnesthetic = (props: CardPreAnestheticProps) => {
  const [tab, setTab] = useState<string>("History");

  useEffect(() => {
    props.runSequence({
      sequence: "PreAnesthetic",
      cardKey: CARD_KEY,
      formCode: FORM_CODE,
      formVersion: FORM_VERSION,
      restart: true,
      action: "FETCH_PRE_ANESTHETIC",
    });
  }, []);

  // useEffect(() => {
  //   props.runSequence({
  //     sequence: "PreAnesthetic",
  //     action: "FETCH_PRE_ANESTHETIC",
  //     cardKey: CARD_KEY,
  //     formCode: FORM_CODE,
  //     formVersion: FORM_VERSION,
  //   });
  // }, []);

  // #useEffect(() => {
  //   if (
  //     props.PreAnestheticSequence?.data?.Anesthesiologist !== "" &&
  //     props.masterData?.doctor instanceof Array
  //   ) {
  //     let doctorData = props.masterData?.doctor?.filter(
  //       (item: any) =>
  //         item.id === props.PreAnestheticSequence?.data?.Anesthesiologist
  //     );

  //     if (doctorData?.[0]?.user !== undefined) {
  //       props.runSequence({
  //         sequence: "PreAnesthetic",
  //         action: "GET_LICENSE",
  //         cardKey: "Anesthesiologist",
  //         paramKey: doctorData?.[0]?.user,
  //       });
  //     }
  //   }
  // }, [props.PreAnestheticSequence?.data?.Anesthesiologist]);

  // #useEffect(() => {
  //   if (
  //     props.PreAnestheticSequence?.data?.Anesthetist !== "" &&
  //     props.masterData?.doctor instanceof Array
  //   ) {
  //     let doctorData = props.masterData.doctor?.filter(
  //       (item: any) =>
  //         item.id === props.PreAnestheticSequence?.data?.Anesthetist
  //     );

  //     if (doctorData?.[0]?.user !== undefined) {
  //       props.runSequence({
  //         sequence: "PreAnesthetic",
  //         action: "GET_LICENSE",
  //         cardKey: "Anesthetist",
  //         paramKey: doctorData[0]?.user,
  //       });
  //     }
  //   }
  // }, [props.PreAnestheticSequence?.data?.Anesthetist]);

  const handleChangeContainerName = (_event: any, data: any) => {
    setTab(data.name);
  };

  const handleChangeData = (_event: any, data: any) => {
    if (data.type === "checkbox") {
      props.setProp(`PreAnestheticSequence.data.${data.name}`, data.checked);
    } else {
      if (preAnestheticHistory.includes(data?.name) && (data.value === "No" || data.value === "")) {
        props.setProp(`PreAnestheticSequence.data`, {
          ...props.PreAnestheticSequence?.data,
          [`${data.name}`]: data.value,
          [`${data.name}Remark`]: "",
        });
      } else if (
        preAnestheticAirwayEvaluation.includes(data?.name) &&
        (data.value === "Normal" || data.value === "")
      ) {
        props.setProp(`PreAnestheticSequence.data`, {
          ...props.PreAnestheticSequence?.data,
          [`${data.name}`]: data.value,
          [`${data.name}Remark`]: "",
        });
      } else {
        props.setProp(`PreAnestheticSequence.data.${data.name}`, data.value);
      }
    }
  };

  // #const handleGetUserTokenizeLicense = (event: any, paramKey: string) => {
  //   if (event.key === "Enter") {
  //     props.runSequence({
  //       sequence: "PreAnesthetic",
  //       action: "GET_USER_TOKENIZE_LICENSE",
  //       cardKey: CARD_KEY,
  //       paramKey: paramKey,
  //     });
  //   }
  // };

  const handleCloseModLog = () => {
    props.setProp("PreAnestheticSequence.modLogInfo", {
      open: false,
      logList: [],
    });
  };

  const handleUpdateScrubNurse = (index: number) => (items: any[]) => {
    props.setProp(`PreAnestheticSequence.data.AnesthesiaNurse`, items);
  };

  const handleAnesthesiologist = (value: any) => {
    handleChangeData(null, { name: "Anesthesiologist", value });
  };

  return (
    <div>
      <SnackMessage
        onEvent={props.onEvent}
        onClose={() => {
          props.setProp(`errorMessage.${CARD_KEY}`, null);
          props.setProp(`successMessage.${CARD_KEY}`, null);
        }}
        error={props.errorMessage?.[CARD_KEY]}
        success={props.successMessage?.[CARD_KEY]}
      />
      <div>
        <CardPreAnestheticFormUX
          // function
          onChangeData={handleChangeData}
          // onKeyAnesthesiologist={(event: any) =>
          //   handleGetUserTokenizeLicense(event, "Anesthesiologist")
          // }
          // onKeyAnesthetist={(event: any) =>
          //   handleGetUserTokenizeLicense(event, "Anesthetist")
          // }
          // options
          masterOptions={props.masterOptions}
          // seq
          PreAnestheticSequence={props.PreAnestheticSequence}
          // component
          buttonLog={
            <ButtonLoadCheck
              // function
              setProp={props.setProp}
              onClick={() => {
                props.runSequence({
                  sequence: "PreAnesthetic",
                  action: "LOG",
                  cardKey: CARD_KEY,
                  formCode: FORM_CODE,
                  formVersion: FORM_VERSION,
                });
              }}
              // data
              paramKey={`${CARD_KEY}_LOG`}
              buttonLoadCheck={props.buttonLoadCheck?.[`${CARD_KEY}_LOG`]}
              // config
              color="orange"
              size="medium"
              style={{ width: "120px" }}
              title="Log"
            />
          }
          buttonPrint={
            <ButtonLoadCheck
              // function
              setProp={props.setProp}
              onClick={() => {
                props.runSequence({
                  sequence: "PreAnesthetic",
                  action: "PRINT",
                  cardKey: CARD_KEY,
                });
              }}
              // data
              paramKey={`${CARD_KEY}_PRINT`}
              buttonLoadCheck={props.buttonLoadCheck?.[`${CARD_KEY}_PRINT`]}
              // config
              color="blue"
              size="medium"
              style={{ width: "120px" }}
              title="Print"
            />
          }
          buttonSave={
            <ButtonLoadCheck
              // function
              setProp={props.setProp}
              onClick={() => {
                props.runSequence({
                  sequence: "PreAnesthetic",
                  action: FORM_DATA_ACTION.SAVE,
                  cardKey: CARD_KEY,
                  formCode: FORM_CODE,
                  formVersion: FORM_VERSION,
                });
              }}
              // data
              paramKey={`${CARD_KEY}_${FORM_DATA_ACTION.SAVE}`}
              buttonLoadCheck={props.buttonLoadCheck?.[`${CARD_KEY}_${FORM_DATA_ACTION.SAVE}`]}
              // config
              color={"orange"}
              size="medium"
              style={{ width: "120px" }}
              title={"Save Draft"}
            />
          }
          buttonConfirm={
            <ButtonLoadCheck
              // function
              setProp={props.setProp}
              onClick={() => {
                props.runSequence({
                  sequence: "PreAnesthetic",
                  action: FORM_DATA_ACTION.CONFIRM,
                  cardKey: CARD_KEY,
                  formCode: FORM_CODE,
                  formVersion: FORM_VERSION,
                });
              }}
              // data
              paramKey={`${CARD_KEY}_${FORM_DATA_ACTION.CONFIRM}`}
              buttonLoadCheck={props.buttonLoadCheck?.[`${CARD_KEY}_${FORM_DATA_ACTION.CONFIRM}`]}
              // config
              color="green"
              size="medium"
              style={{ width: "120px" }}
              title="Confirm"
            />
          }
          SurgeonElement={
            <SearchBoxWithKey
              // callback
              onEvent={props.onEvent}
              type="Doctor"
              id={"Anesthesiologist_1"}
              searchedItemListWithKey={props.searchedItemListWithKey}
              selectedItem={props.PreAnestheticSequence?.data?.Anesthesiologist || null}
              setSelected={handleAnesthesiologist}
            />
          }
          AnesthetistElement={
            <SubAssistantsInput
              // callback
              onEvent={props.onEvent}
              // data
              index={0}
              items={props.PreAnestheticSequence?.data?.AnesthesiaNurse || []}
              type="User"
              role="REGISTERED_NURSE"
              field="Anesthetist"
              searchedItemListWithKey={props.searchedItemListWithKey}
              // setSelected={handleActionScrubNurse(0)}
              // onClick={handleActionScrubNurse(0)}
              onUpdated={handleUpdateScrubNurse(0)}
            />
          }
        />

        <CardPreAnestheticTabUX onChangeContainerName={handleChangeContainerName} tab={tab} />

        {tab === "PhysicianEvaluation" ? (
          <CardPhysicianEvaluation
            onEvent={props.onEvent}
            setProp={props.setProp}
            runSequence={props.runSequence}
            masterOptions={props.masterOptions}
            masterData={props.masterData}
            PreAnestheticSequence={props.PreAnestheticSequence}
            buttonLoadCheck={props.buttonLoadCheck}
          />
        ) : tab === "AirwayEvaluation" ? (
          <CardPreAnestheticAirwayEvaluationUX
            onChangeData={handleChangeData}
            masterOptions={props.masterOptions}
            PreAnestheticSequence={props.PreAnestheticSequence}
            buttonSave={
              <ButtonLoadCheck
                // function
                setProp={props.setProp}
                onClick={() => {
                  props.runSequence({
                    sequence: "PreAnesthetic",
                    action: FORM_DATA_ACTION.SAVE,
                    cardKey: "CardPreAnestheticAirwayEvaluation",
                    formCode: "CardPreAnestheticAirwayEvaluation",
                    formName: "CardPreAnestheticAirwayEvaluation",
                    formVersion: FORM_VERSION,
                    tabForm: true,
                  });
                }}
                // data
                paramKey={`CardPreAnestheticAirwayEvaluation_${FORM_DATA_ACTION.SAVE}`}
                buttonLoadCheck={
                  props.buttonLoadCheck?.[
                    `CardPreAnestheticAirwayEvaluation_${FORM_DATA_ACTION.SAVE}`
                  ]
                }
                // config
                color={"orange"}
                size="medium"
                style={{ width: "120px" }}
                title={"Save Draft"}
              />
            }
          />
        ) : tab === "BloodChemistry" ? (
          <CardPreAnestheticBloodChemistryUX
            onChangeData={handleChangeData}
            PreAnestheticSequence={props.PreAnestheticSequence}
            buttonSave={
              <ButtonLoadCheck
                // function
                setProp={props.setProp}
                onClick={() => {
                  props.runSequence({
                    sequence: "PreAnesthetic",
                    action: FORM_DATA_ACTION.SAVE,
                    cardKey: "CardPreAnestheticBloodChemistry",
                    formCode: "CardPreAnestheticBloodChemistry",
                    formName: "CardPreAnestheticBloodChemistry",
                    formVersion: FORM_VERSION,
                    tabForm: true,
                  });
                }}
                // data
                paramKey={`CardPreAnestheticBloodChemistry_${FORM_DATA_ACTION.SAVE}`}
                buttonLoadCheck={
                  props.buttonLoadCheck?.[
                    `CardPreAnestheticBloodChemistry_${FORM_DATA_ACTION.SAVE}`
                  ]
                }
                // config
                color={"orange"}
                size="medium"
                style={{ width: "120px" }}
                title={"Save Draft"}
              />
            }
          />
        ) : tab === "AnestheticProblems" ? (
          <CardPreAnestheticProblemsConsiderationsUX
            onChangeData={handleChangeData}
            masterOptions={props.masterOptions}
            PreAnestheticSequence={props.PreAnestheticSequence}
            buttonSave={
              <ButtonLoadCheck
                // function
                setProp={props.setProp}
                onClick={() => {
                  props.runSequence({
                    sequence: "PreAnesthetic",
                    action: FORM_DATA_ACTION.SAVE,
                    cardKey: "CardPreAnestheticProblemsConsiderations",
                    formCode: "CardPreAnestheticProblemsConsiderations",
                    formName: "CardPreAnestheticProblemsConsiderations",
                    formVersion: FORM_VERSION,
                    tabForm: true,
                  });
                }}
                // data
                paramKey={`CardPreAnestheticProblemsConsiderations_${FORM_DATA_ACTION.SAVE}`}
                buttonLoadCheck={
                  props.buttonLoadCheck?.[
                    `CardPreAnestheticProblemsConsiderations_${FORM_DATA_ACTION.SAVE}`
                  ]
                }
                // config
                color={"orange"}
                size="medium"
                style={{ width: "120px" }}
                title={"Save Draft"}
              />
            }
          />
        ) : tab === "RiskAnesthesia" ? (
          <CardPreAnestheticRiskUX
            onChangeData={handleChangeData}
            PreAnestheticSequence={props.PreAnestheticSequence}
            buttonSave={
              <ButtonLoadCheck
                // function
                setProp={props.setProp}
                onClick={() => {
                  props.runSequence({
                    sequence: "PreAnesthetic",
                    action: FORM_DATA_ACTION.SAVE,
                    cardKey: "CardPreAnestheticRisk",
                    formCode: "CardPreAnestheticRisk",
                    formName: "CardPreAnestheticRisk",
                    formVersion: FORM_VERSION,
                    tabForm: true,
                  });
                }}
                // data
                paramKey={`CardPreAnestheticRisk_${FORM_DATA_ACTION.SAVE}`}
                buttonLoadCheck={
                  props.buttonLoadCheck?.[`CardPreAnestheticRisk_${FORM_DATA_ACTION.SAVE}`]
                }
                // config
                color={"orange"}
                size="medium"
                style={{ width: "120px" }}
                title={"Save Draft"}
              />
            }
          />
        ) : tab === "ReEvaluation" ? (
          <CardPreAnestheticReEvaluationUX
            onChangeData={handleChangeData}
            onChangeTimeForm={(value: string) =>
              props.setProp("PreAnestheticSequence.data.PREANESTHETICREEVALUATIONTime", value)
            }
            onChangeDateForm={(date: string) =>
              props.setProp("PreAnestheticSequence.data.PREANESTHETICREEVALUATIONDate", date)
            }
            // onKeyAnesthetsiologisAnesthetist={(event: any) =>
            //   handleGetUserTokenizeLicense(
            //     event,
            //     "PreanestheticReEvaluationAnesthetsiologisAnesthetist"
            //   )
            // }
            masterOptions={props.masterOptions}
            PreAnestheticSequence={props.PreAnestheticSequence}
            buttonSave={
              <ButtonLoadCheck
                // function
                setProp={props.setProp}
                onClick={() => {
                  props.runSequence({
                    sequence: "PreAnesthetic",
                    action: FORM_DATA_ACTION.SAVE,
                    cardKey: "CardPreAnestheticReEvaluation",
                    formCode: "CardPreAnestheticReEvaluation",
                    formName: "CardPreAnestheticReEvaluation",
                    formVersion: FORM_VERSION,
                    tabForm: true,
                  });
                }}
                // data
                paramKey={`CardPreAnestheticReEvaluation_${FORM_DATA_ACTION.SAVE}`}
                buttonLoadCheck={
                  props.buttonLoadCheck?.[`CardPreAnestheticReEvaluation_${FORM_DATA_ACTION.SAVE}`]
                }
                // config
                color={"orange"}
                size="medium"
                style={{ width: "120px" }}
                title={"Save Draft"}
              />
            }
          />
        ) : (
          <CardPreAnestheticHistoryUX
            onChangeDate={(date: string) => {
              props.setProp("PreAnestheticSequence.data.FunctionalLMP", date);
            }}
            onChangeData={handleChangeData}
            masterOptions={props.masterOptions}
            PreAnestheticSequence={props.PreAnestheticSequence}
            buttonSave={
              <ButtonLoadCheck
                // function
                setProp={props.setProp}
                onClick={() => {
                  props.runSequence({
                    sequence: "PreAnesthetic",
                    action: FORM_DATA_ACTION.SAVE,
                    cardKey: "CardPreAnestheticHistory",
                    formCode: "CardPreAnestheticHistory",
                    formName: "CardPreAnestheticHistory",
                    formVersion: FORM_VERSION,
                    tabForm: true,
                  });
                }}
                // data
                paramKey={`CardPreAnestheticHistory_${FORM_DATA_ACTION.SAVE}`}
                buttonLoadCheck={
                  props.buttonLoadCheck?.[`CardPreAnestheticHistory_${FORM_DATA_ACTION.SAVE}`]
                }
                // config
                color={"orange"}
                size="medium"
                style={{ width: "120px" }}
                title={"Save Draft"}
              />
            }
          />
        )}
      </div>
      <Modal
        open={props.PreAnestheticSequence?.modLogInfo?.open}
        closeOnDimmerClick={true}
        onClose={handleCloseModLog}
      >
        <CardANSLog
          showAction={true}
          createDetail={true}
          dataList={props.PreAnestheticSequence?.modLogInfo?.logList || []}
          hideCallback={handleCloseModLog}
        />
      </Modal>
    </div>
  );
};

export default React.memo(CardPreAnesthetic);

const preAnestheticHistory = [
  "PreviousAnesthesia",
  "Complication",
  "KnownDisease",
  "FamilyHistory",
  "CurrentMedications",
];

const preAnestheticAirwayEvaluation = [
  "Respiratory",
  "Cardiovascular",
  "Neurologic",
  "EENT",
  "GI",
  "Genitourinary",
  "EndocrineAndMetabolic",
  "SkinBreast",
  "ImmunologyHematology",
];
