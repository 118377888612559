import moment from "moment";

import HeaderSapiensForm from "../DPO/sequence/HeaderSapiensForm";
import HeaderCUForm from "../DPO/sequence/HeaderCUForm";

// Utils
import { formatDate } from "react-lib/utils/dateUtils";
import CONFIG from "config/config";

type FormPrescriptionDeliveryProps = {
  encounter_type: "IPD" | "OPD";
  division_type: string;
  requested: string;
  order_div_name: string;
  items: Record<string, any>[];
};

const FormPrescriptionDelivery = async (props: FormPrescriptionDeliveryProps) => {

  let headerForm = CONFIG.COMPANY === "CU" ? await HeaderCUForm({
    pageMargins: [20, 165, 20, 55],
    header: {
      margin: [0, 15, 0, 0],
      stack: [
        {
          text: `รายการจัดส่งยา ประจำวันที่ ${formatDate(
            moment(props.requested)
          )}, 16:29 น.`,
          style: "additionHeader",
        },
        {
          text: `${props.division_type} ${props.order_div_name}`,
          style: "additionHeader",
        },
      ],
      alignment: "center",
      style: { fontSize: 20, bold: true },
    },
    styles: {
      additionHeader: {
        fontSize: 18,
        bold: true,
        alignment: "center",
      },
    },
  }) : await HeaderSapiensForm({
    pageMargins: [20, 165, 20, 55],
    header: {
      margin: [0, 15, 0, 0],
      stack: [
        {
          text: `รายการจัดส่งยา ประจำวันที่ ${formatDate(
            moment(props.requested)
          )}, 16:29 น.`,
          style: "additionHeader",
        },
        {
          text: `${props.division_type} ${props.order_div_name}`,
          style: "additionHeader",
        },
      ],
      alignment: "center",
      style: { fontSize: 20, bold: true },
    },
    styles: {
      additionHeader: {
        fontSize: 18,
        bold: true,
        alignment: "center",
      },
    },
  });

  const widths =
    props.encounter_type === "OPD"
      ? ["19%", "27%", "13%", "11%", "14%", "16%"]
      : ["8%", "18%", "24%", "12%", "10%", "13%", "15%"];

  console.log("FormPrescriptionDelivery", headerForm);

  return {
    pageSize: "A4",
    defaultStyle: {
      font: "THSarabunNew",
      lineHeight: 1,
      fontSize: 18,
    },
    ...headerForm,
    content: [
      {
        table: {
          headerRows: 1,
          widths: widths,
          body: [
            [
              ...(props.encounter_type === "OPD"
                ? []
                : [
                    {
                      text: "ห้อง",
                      style: "tableHeader",
                      alignment: "center",
                    },
                  ]),
              {
                text: props.encounter_type === "OPD" ? "HN [EN]" : "HN [AN]",
                style: "tableHeader",
                alignment: "center",
              },
              {
                text: "ชื่อ-นามสกุล ผู้ป่วย",
                style: "tableHeader",
                alignment: "center",
              },
              {
                text: "เลขที่ใบสั่งยา",
                style: "tableHeader",
                alignment: "center",
              },
              {
                text: "ประเภท",
                style: "tableHeader",
                alignment: "center",
              },
              {
                text: "จำนวนรายการยา",
                style: "tableHeader",
                alignment: "center",
              },
              {
                text: "หมายเหตุ",
                style: "tableHeader",
                alignment: "center",
              },
            ],
            ...props.items.map((item: any, index: number) => [
              ...(props.encounter_type === "OPD"
                ? []
                : [
                    {
                      text: item.room_no,
                      style: "tableData",
                      alignment: "center",
                    },
                  ]),
              {
                text: `${item.hn} [${
                  props.encounter_type === "OPD" ? "EN" : "AN"
                } : ${item.en_number}]`,
                style: "tableData",
                alignment: "center",
              },
              {
                text: item.patient,
                style: "tableData",
              },
              {
                text: item.code,
                style: "tableData",
                alignment: "center",
              },
              {
                text: item.type,
                style: "tableData",
                alignment: "center",
              },
              {
                text: item.items_count,
                style: "tableData",
                alignment: "center",
              },
              {
                text: "",
                style: "tableData",
                alignment: "center",
              },
            ]),
          ],
        },
      },
    ],
    styles: {
      ...headerForm?.styles,
      tableHeader: {
        bold: true,
        fontSize: 13,
        color: "black",
      },
      tableData: { fontSize: 13 },
    },
    footer: function (currentPage: number, pageCount: number) {
      return {
        margin: [15, 0, 0, 0],
        stack: [
          {
            table: {
              headerRows: 1,
              widths: ["*", "*", "*"],
              body: [
                [
                  {
                    text: [
                      { text: `ผู้ส่งมอบ${new Array(47).fill("").join(".")}` },
                      { text: `เวลา${new Array(20).fill("").join(".")}` },
                    ],
                    border: [false, false, false, false],
                    fontSize: 13,
                  },
                  {
                    text: [
                      { text: `ผู้จัดส่ง${new Array(47).fill("").join(".")}` },
                      { text: `เวลา${new Array(20).fill("").join(".")}` },
                    ],
                    border: [true, false, false, false],
                    fontSize: 13,
                  },
                  {
                    text: [
                      { text: `ผู้รับ${new Array(47).fill("").join(".")}` },
                      { text: `เวลา${new Array(20).fill("").join(".")}` },
                    ],
                    border: [true, false, false, false],
                    fontSize: 13,
                  },
                ],
              ],
            },
            height: 15,
          },
          {
            text: "วันที่และเวลาพิมพ์ 29/12/2566, 17:44",
            fontSize: 13,
            alignment: "right",
            margin: [0, 5, 20, 0],
          },
        ],
      };
    },
    images: {
      ...headerForm.images,
    },
  };
};

export default FormPrescriptionDelivery;
