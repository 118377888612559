import React, { useEffect, useMemo, useState, useCallback } from "react";
import moment from "moment";
import {
  Button,
  Checkbox,
  Dropdown,
  Form,
  Header,
  Input,
  Label,
  Modal,
  Popup,
  Segment,
} from "semantic-ui-react";

// ui common
import SnackMessage from "react-lib/apps/common/SnackMessage";
import ButtonLoadCheck from "react-lib/appcon/common/ButtonLoadCheck";

// ui FOD
import CardFoodListUX from "./CardFoodListUX";
import CardFoodRequestUX from "./CardFoodRequestUX";
import ActionMenuColumn from "react-lib/apps/HISV3/DPI/ActionMenuColumn";

const FOOD_TYPE: any = {
  GENERAL: "G",
  ROUTINE: "R",
};

const PLAN_STATUS_LABEL: any = {
  ACTIVE: "Active",
  HELD: "Hold",
  OFF: "Off",
  ENDED: "End",
  CANCELED: "Cancel",
  RENEWED: "Renew",
};

const STATUS_LABEL: any = {
  NEW: "New",
  PROCESS: "Process",
  COMPLETE: "Complete",
  NPO: "NPO",
  OFF: "OFF",
  CANCELLED: "Cancel",
};

const FOOD_ACTION: any = {
  EDIT: "EDIT",
  EDIT_QUANTITY: "EDIT_QUANTITY",
};
const PLAN_ACTION: any = {
  HOLD: "HOLD",
  RESUME: "RESUME",
  OFF: "OFF",
  RENEW: "RENEW",
};

const CARD_FOOD_ID: string = "CardFood";
const SUB_CARD_FOOD_ID: string = "SubFoodActionMenu";

// ================================================== ActionMenuColumn
// export const ActionMenuColumn = (props: any) => {
//   const [isOpen, setIsOpen] = useState<boolean>(false);
//   const [currentAction, setCurrentAction] = useState<string>("");

//   useEffect(() => {
//     if (
//       currentAction !== "" &&
//       ["ERROR", "SUCCESS"].includes(props.buttonLoadCheck?.[`${SUB_CARD_FOOD_ID}_${currentAction}`])
//     ) {
//       setIsOpen(false);
//       setCurrentAction("");
//     }
//   }, [props.buttonLoadCheck]);

//   const handleActionOrder = (_event: any, data: any) => {
//     setCurrentAction(data.name);

//     if (data.name === FOOD_ACTION.EDIT) {
//       setIsOpen(false);
//       setCurrentAction("");
//     } else if (data.name === FOOD_ACTION.EDIT_QUANTITY) {
//       props.setProp("foodRequestOptions.openModEditQuantity", true);
//       setIsOpen(false);
//       setCurrentAction("");
//     } else {
//       props.runSequence({
//         sequence: "FoodRequest",
//         action: data.name,
//         card: SUB_CARD_FOOD_ID,
//       });
//     }
//   };

//   return (
//     <>
//       {
//         <div style={{ display: "flex", justifyContent: "center" }}>
//           <Popup
//             open={isOpen}
//             style={{ widdth: "180px" }}
//             content={
//               <>
//                 <ButtonLoadCheck
//                   // function
//                   setProp={props.setProp}
//                   onClick={handleActionOrder}
//                   // data
//                   paramKey={`${SUB_CARD_FOOD_ID}_${FOOD_ACTION.EDIT}`}
//                   buttonLoadCheck={
//                     props.buttonLoadCheck?.[`${SUB_CARD_FOOD_ID}_${FOOD_ACTION.EDIT}`]
//                   }
//                   // config
//                   color="green"
//                   fluid={true}
//                   name={FOOD_ACTION.EDIT}
//                   style={{
//                     marginBottom: "10px",
//                     display: (props.order?.plan_order_allowed_actions || [])?.includes(
//                       FOOD_ACTION.EDIT
//                     )
//                       ? ""
//                       : "none",
//                   }}
//                   title="แก้ไขรายละเอียด"
//                 />
//                 <ButtonLoadCheck
//                   // function
//                   setProp={props.setProp}
//                   onClick={handleActionOrder}
//                   // data
//                   paramKey={`${SUB_CARD_FOOD_ID}_${FOOD_ACTION.EDIT_QUANTITY}`}
//                   buttonLoadCheck={
//                     props.buttonLoadCheck?.[`${SUB_CARD_FOOD_ID}_${FOOD_ACTION.EDIT_QUANTITY}`]
//                   }
//                   // config
//                   color="brown"
//                   fluid={true}
//                   name={FOOD_ACTION.EDIT_QUANTITY}
//                   style={{
//                     marginBottom: "10px",
//                     display:
//                       props.DJANGO?.user?.role_types.includes("REGISTERED_NURSE") &&
//                       props.order?.plan_order_allowed_actions?.includes(FOOD_ACTION.EDIT_QUANTITY)
//                         ? ""
//                         : "none",
//                   }}
//                   title="แก้ไขจำนวนมื้อ"
//                 />
//                 <ButtonLoadCheck
//                   // function
//                   setProp={props.setProp}
//                   onClick={handleActionOrder}
//                   // data
//                   paramKey={`${SUB_CARD_FOOD_ID}_${PLAN_ACTION.RESUME}`}
//                   buttonLoadCheck={
//                     props.buttonLoadCheck?.[`${SUB_CARD_FOOD_ID}_${PLAN_ACTION.RESUME}`]
//                   }
//                   // config
//                   color="teal"
//                   fluid={true}
//                   name={PLAN_ACTION.RESUME}
//                   style={{
//                     marginBottom: "10px",
//                     display:
//                       props.DJANGO?.user?.role_types?.includes("DOCTOR") &&
//                       props.order?.plan_item_allowed_actions?.includes(PLAN_ACTION.RESUME)
//                         ? ""
//                         : "none",
//                   }}
//                   title={PLAN_ACTION.RESUME}
//                 />
//                 <ButtonLoadCheck
//                   // function
//                   setProp={props.setProp}
//                   onClick={handleActionOrder}
//                   // data
//                   paramKey={`${SUB_CARD_FOOD_ID}_${PLAN_ACTION.RENEW}`}
//                   buttonLoadCheck={
//                     props.buttonLoadCheck?.[`${SUB_CARD_FOOD_ID}_${PLAN_ACTION.RENEW}`]
//                   }
//                   // config
//                   color="teal"
//                   fluid={true}
//                   name={PLAN_ACTION.RENEW}
//                   style={{
//                     marginBottom: "10px",
//                     display:
//                       props.DJANGO?.user?.role_types?.includes("DOCTOR") &&
//                       props.order?.plan_item_allowed_actions?.includes(PLAN_ACTION.RENEW)
//                         ? ""
//                         : "none",
//                   }}
//                   title={PLAN_ACTION.RENEW}
//                 />
//                 <ButtonLoadCheck
//                   // function
//                   setProp={props.setProp}
//                   onClick={handleActionOrder}
//                   // data
//                   paramKey={`${SUB_CARD_FOOD_ID}_${PLAN_ACTION.HOLD}`}
//                   buttonLoadCheck={
//                     props.buttonLoadCheck?.[`${SUB_CARD_FOOD_ID}_${PLAN_ACTION.HOLD}`]
//                   }
//                   // config
//                   color="yellow"
//                   fluid={true}
//                   name={PLAN_ACTION.HOLD}
//                   style={{
//                     marginBottom: "10px",
//                     display: props.order?.plan_item_allowed_actions?.includes(PLAN_ACTION.HOLD)
//                       ? ""
//                       : "none",
//                   }}
//                   title="NPO"
//                 />
//                 <ButtonLoadCheck
//                   // function
//                   setProp={props.setProp}
//                   onClick={handleActionOrder}
//                   // data
//                   paramKey={`${SUB_CARD_FOOD_ID}_${PLAN_ACTION.OFF}`}
//                   buttonLoadCheck={
//                     props.buttonLoadCheck?.[`${SUB_CARD_FOOD_ID}_${PLAN_ACTION.OFF}`]
//                   }
//                   // config
//                   color="teal"
//                   fluid={true}
//                   name={PLAN_ACTION.OFF}
//                   style={{
//                     display:
//                       props.DJANGO?.user?.role_types?.includes("DOCTOR") &&
//                       props.order?.plan_item_allowed_actions?.includes(PLAN_ACTION.OFF)
//                         ? ""
//                         : "none",
//                   }}
//                   title={PLAN_ACTION.OFF}
//                 />
//               </>
//             }
//             position="bottom right"
//             onOpen={() => setIsOpen(true)}
//             onClose={() => setIsOpen(false)}
//             on="click"
//             trigger={
//               <Button
//                 icon="list layout"
//                 size="small"
//                 onDoubleClick={(e: any) => e.stopPropagation()}
//               ></Button>
//             }
//           />
//         </div>
//       }
//     </>
//   );
// };

// ================================================== CardFood
type CardFoodProps = {
  // function
  onEvent: any;
  setProp: any;

  // CommonInterface
  errorMessage?: any;
  successMessage?: any;
  buttonLoadCheck?: any;
  DJANGO?: any;

  // seq
  runSequence: any;
  FoodListSequence?: any;
  FoodRequestSequence?: any;
  AllergySequence: any;

  // options
  foodRequestOptions?: any;
};

const CardFoodInitial: CardFoodProps = {
  // funtion
  onEvent: () => null,
  setProp: () => null,

  // CommonInterface
  errorMessage: null,
  successMessage: null,
  buttonLoadCheck: null,
  DJANGO: null,

  // seq
  runSequence: null,
  FoodListSequence: null,
  FoodRequestSequence: null,

  // options
  foodRequestOptions: {},
};

const CardFood: React.FC<CardFoodProps> = (props: any) => {
  const [foodType, setFoodType] = useState<string>(FOOD_TYPE.GENERAL);

  useEffect(() => {
    props.runSequence({ sequence: "FoodList", restart: true, orderOnly: true });
    props.runSequence({ sequence: "FoodRequest", restart: true });
  }, []);

  useEffect(() => {
    if (
      [
        props.buttonLoadCheck?.[`${CARD_FOOD_ID}_SAVE`],
        props.buttonLoadCheck?.[`${CARD_FOOD_ID}_${PLAN_ACTION.HOLD}`],
        props.buttonLoadCheck?.[`${CARD_FOOD_ID}_${PLAN_ACTION.RESUME}`],
        props.buttonLoadCheck?.[`${CARD_FOOD_ID}_${PLAN_ACTION.OFF}`],
        props.buttonLoadCheck?.[`${CARD_FOOD_ID}_${PLAN_ACTION.RENEW}`],
        props.buttonLoadCheck?.[`${SUB_CARD_FOOD_ID}_${PLAN_ACTION.RESUME}`],
        props.buttonLoadCheck?.[`${SUB_CARD_FOOD_ID}_${PLAN_ACTION.RENEW}`],
        props.buttonLoadCheck?.[`${SUB_CARD_FOOD_ID}_${PLAN_ACTION.HOLD}`],
        props.buttonLoadCheck?.[`${SUB_CARD_FOOD_ID}_${PLAN_ACTION.OFF}`],
        props.buttonLoadCheck?.[`${SUB_CARD_FOOD_ID}_${FOOD_ACTION.EDIT_QUANTITY}`],
      ].includes("SUCCESS")
    ) {
      props.runSequence({
        sequence: "FoodList",
        restart: true,
        orderOnly: true,
      });
    }
  }, [props.buttonLoadCheck]);

  useEffect(() => {
    const selected: any[] = (props.foodRequestOptions?.foodTypeOptions || [])?.filter(
      (item: any) => item?.value === props.FoodRequestSequence?.food_type
    );
    if (selected?.length > 0) {
      if (selected?.[0]?.type !== FOOD_TYPE.GENERAL) {
        props.setProp("FoodRequestSequence.general_time", []);
        setFoodType(FOOD_TYPE.ROUTINE);
      } else {
        props.setProp("FoodRequestSequence.routine_time", []);
        setFoodType(FOOD_TYPE.GENERAL);
      }
    }
  }, [props.FoodRequestSequence?.food_type]);

  useEffect(() => {
    if (
      props.FoodRequestSequence?.food_nutrition_control?.length === 0 &&
      props.foodRequestOptions?.nutritionList?.length > 0
    ) {
      props.setProp(
        "FoodRequestSequence.food_nutrition_control",
        props.foodRequestOptions?.nutritionList
      );
    }
  }, [props.foodRequestOptions?.nutritionList, props.FoodRequestSequence?.food_nutrition_control]);

  const getButtonStyleConfig = useCallback(
    (item: any) => {
      const roleTypes: string[] = props.DJANGO?.user?.role_types || []
      const isDoctor = roleTypes.includes("DOCTOR");
      const isNurse = roleTypes.includes("REGISTERED_NURSE");

      const checkActionAllowed = (action: string, actionType: string) => {
        const allowedActions: any[] | undefined =
          actionType === "plan_order"
            ? item.plan_order_allowed_actions
            : item.plan_item_allowed_actions;

        return (allowedActions || []).includes(action);
      };

      return (action: string) => {
        const baseStyle = { marginBottom: "10px" };

        const actionConditions: Record<string, boolean> = {
          [FOOD_ACTION.EDIT]: checkActionAllowed(FOOD_ACTION.EDIT, "plan_order"),
          [FOOD_ACTION.EDIT_QUANTITY]:
            isNurse && checkActionAllowed(FOOD_ACTION.EDIT_QUANTITY, "plan_order"),
          [PLAN_ACTION.HOLD]: checkActionAllowed(PLAN_ACTION.HOLD, "plan_item"),
          [PLAN_ACTION.OFF]: isDoctor && checkActionAllowed(PLAN_ACTION.OFF, "plan_item"),
          [PLAN_ACTION.RENEW]: isDoctor && checkActionAllowed(PLAN_ACTION.RENEW, "plan_item"),
          [PLAN_ACTION.RESUME]: isDoctor && checkActionAllowed(PLAN_ACTION.RESUME, "plan_item"),
        };

        return {
          ...baseStyle,
          display: actionConditions[action] ? "" : "none",
        };
      };
    },
    []
  );

  const foodList = useMemo(() => {
    return (props.FoodListSequence?.items || [])?.map((item: any, index: number) => {
      let labelColor: string = "";
      if (item?.plan_item_status_label !== "") {
        switch (item?.plan_item_status_label) {
          case PLAN_STATUS_LABEL.ACTIVE:
            labelColor = "blue";
            break;
          case PLAN_STATUS_LABEL.RENEWED:
            labelColor = "teal";
            break;
          case PLAN_STATUS_LABEL.ENDED:
            labelColor = "green";
            break;
          case PLAN_STATUS_LABEL.HELD:
            labelColor = "yellow";
            break;
          case PLAN_STATUS_LABEL.OFF:
            labelColor = "red";
            break;
          default:
            labelColor = "gray";
        }
      } else {
        switch (item?.status_label) {
          case STATUS_LABEL.NEW:
            labelColor = "blue";
            break;
          case STATUS_LABEL.PROCESS:
            labelColor = "teal";
            break;
          case STATUS_LABEL.COMPLETE:
            labelColor = "green";
            break;
          case STATUS_LABEL.NPO:
            labelColor = "yellow";
            break;
          case STATUS_LABEL.OFF:
            labelColor = "red";
            break;
          default:
            labelColor = "gray";
        }
      }

      return {
        ...item,

        statusLabel: (
          <Label color={labelColor}>
            {item.plan_item_status_label || item.status_label}
            {item.plan_item_status_label === PLAN_STATUS_LABEL.OFF && (
              <>
                <br />
                {item.plan_item_time}
              </>
            )}
          </Label>
        ),

        type: <label>{`${item.is_extra_meal ? "[Extra]" : ""}  ${item.food_type_name}`}</label>,

        special: (
          <label>
            {item?.special_diet_detail}
            {item?.special_diet_detail && <br />}
            <div
              dangerouslySetInnerHTML={{
                __html: item?.nutrition_control_detail,
              }}
            />
          </label>
        ),

        detailText: <div dangerouslySetInnerHTML={{ __html: item.detail }} />,

        menu: (
          // <ActionMenuColumn
          //   // seq
          //   runSequence={props.runSequence}
          //   // function
          //   onEvent={props.onEvent}
          //   setProp={props.setProp}
          //   // data
          //   DJANGO={props.DJANGO}
          //   buttonLoadCheck={props.buttonLoadCheck}
          //   order={item}
          // />
          <ActionMenuColumn
            setProp={props.setProp}
            actions={[
              FOOD_ACTION.EDIT,
              FOOD_ACTION.EDIT_QUANTITY,
              PLAN_ACTION.RESUME,
              PLAN_ACTION.RENEW,
              PLAN_ACTION.HOLD,
              PLAN_ACTION.OFF,
            ]}
            buttonColors={{
              [FOOD_ACTION.EDIT]: "green",
              [FOOD_ACTION.EDIT_QUANTITY]: "brown",
              [PLAN_ACTION.RESUME]: "teal",
              [PLAN_ACTION.HOLD]: "yellow",
              [PLAN_ACTION.OFF]: "teal",
            }}
            buttonLabels={{
              [FOOD_ACTION.EDIT]: "แก้ไขรายละเอียด",
              [FOOD_ACTION.EDIT_QUANTITY]: "แก้ไขจำนวนมื้อ",
              [PLAN_ACTION.HOLD]: "NPO",
            }}
            cardId={SUB_CARD_FOOD_ID}
            fluidButtons={true}
            item={item}
            buttonLoadCheck={props.buttonLoadCheck}
            allowPropagation
            getButtonStyle={getButtonStyleConfig(item)}
            onCallback={(action) => {
              if (action === FOOD_ACTION.EDIT_QUANTITY) {
                props.setProp("foodRequestOptions.openModEditQuantity", true);
              } else {
                props.runSequence({
                  sequence: "FoodRequest",
                  action: action,
                  card: SUB_CARD_FOOD_ID,
                });
              }
            }}
          />
        ),
      };
    });
  }, [props.FoodListSequence?.items, props.buttonLoadCheck, getButtonStyleConfig]);

  const formReadOnly = useMemo(() => {
    if (
      props.FoodRequestSequence?.nurse_status &&
      props.FoodRequestSequence?.nurse_status !== "รอรับคำสั่ง"
    ) {
      return true;
    } else {
      return false;
    }
  }, [props.FoodRequestSequence?.nurse_status]);

  const allergyFood = useMemo(() => {
    let allergyData = "";
    let adrListFood = props.AllergySequence?.adrList.filter(
      (item) => item?.type_name_name === "FOOD"
    );

    if (adrListFood?.length === 1 && adrListFood?.[0]?.status === "NO_REACTION") {
      return "ผู้ป่วยปฏิเสธแพ้อาหาร";
    } else {
      (adrListFood || [])
        .filter((item: any) => item?.status !== "NO_REACTION")
        .map((item: any, index: number) => {
          allergyData = index === 0 ? item?.name : `${allergyData}, ${item?.name}`;
        });

      return allergyData;
    }
  }, [props.AllergySequence?.adrList]);

  const handleAddOptions = (_event: any, data: any) => {
    if (data.name === "routine_type") {
      props.setProp("foodRequestOptions.routineTypeOptions", [
        ...props.foodRequestOptions?.routineTypeOptions,
        { key: data.value, text: data.value, value: data.value },
      ]);
    } else if (data.name === "supplement_food") {
      props.setProp("foodRequestOptions.supplementFoodOptions", [
        ...props.foodRequestOptions?.supplementFoodOptions,
        { key: data.value, text: data.value, value: data.value },
      ]);
    } else if (data.name === "allergy_food") {
      props.setProp("foodRequestOptions.allergyFoodOptions", [
        ...props.foodRequestOptions?.allergyFoodOptions,
        { key: data.value, text: data.value, value: data.value },
      ]);
    } else if (data.name === "restriction_food") {
      props.setProp("foodRequestOptions.restrictionFoodOptions", [
        ...props.foodRequestOptions?.restrictionFoodOptions,
        { key: data.value, text: data.value, value: data.value },
      ]);
    } else if (data.name === "mixture") {
      props.setProp("foodRequestOptions.mixtureOptions", [
        ...props.foodRequestOptions?.mixtureOptions,
        { key: data.value, text: data.value, value: data.value },
      ]);
    }
  };

  const handleChangeGeneralTime = (_event: any, data: any) => {
    const result: any[] = [...(props.FoodRequestSequence?.general_time || [])];
    if (data?.checked) {
      result.push(data?.value);
    } else {
      const index: number = result.indexOf(data?.value);
      result.splice(index, 1);
    }
    props.setProp("FoodRequestSequence.general_time", result);
  };

  const handleChangeSpecialDiets = (_event: any, data: any) => {
    const result: any[] = [...(props.FoodRequestSequence?.special_diet || [])];

    if (data?.checked) {
      result.push(data?.value);
    } else {
      const index: number = result.indexOf(data?.value);
      result.splice(index, 1);
    }

    props.setProp("FoodRequestSequence.special_diet", result);
  };

  const handleChangeData = (_event: any, data: any) => {
    if (data?.type === "checkbox") {
      props.setProp(`FoodRequestSequence.${data?.name}`, data?.checked);
    } else {
      props.setProp(`FoodRequestSequence.${data?.name}`, data?.value);
    }
  };

  const handleChangeNPODate = (date: string) => {
    props.setProp("FoodRequestSequence.npo_date", date);
  };

  const handleActionFood = (_event: any, data: any) => {
    props.runSequence({
      sequence: "FoodRequest",
      action: data.name,
      card: CARD_FOOD_ID,
      allergyFood: allergyFood,
    });
  };

  // mod
  const handleCloseModEditQuantity = () => {
    props.setProp("foodRequestOptions.openModEditQuantity", false);
  };

  return (
    <>
      {(props.errorMessage?.[CARD_FOOD_ID] || props.successMessage?.[CARD_FOOD_ID]) && (
        <SnackMessage
          onEvent={props.onEvent}
          onClose={() => {
            props.setProp(`errorMessage.${CARD_FOOD_ID}`, null);
            props.setProp(`successMessage.${CARD_FOOD_ID}`, null);
          }}
          error={props.errorMessage?.[CARD_FOOD_ID]}
          success={props.successMessage?.[CARD_FOOD_ID]}
        />
      )}

      <CardFoodListUX
        // seq
        FoodListSequence={props.FoodListSequence}
        //
        foodList={foodList}
        foodListProps={(state: any, rowInfo: any, column: any, instance: any) => {
          return {
            style: {
              backgroundColor:
                props.FoodRequestSequence?.id &&
                rowInfo?.original?.id === props.FoodRequestSequence?.id
                  ? "#cccccc"
                  : "white",
            },
            onClick: () => {
              if (rowInfo?.original?.id !== props.FoodRequestSequence?.id) {
                props.runSequence({
                  sequence: "FoodRequest",
                  data: rowInfo?.original,
                  action: "SELECTED",
                  card: CARD_FOOD_ID,
                });
              }
            },
          };
        }}
      />

      <CardFoodRequestUX
        // function
        onAddOptions={handleAddOptions}
        onChangeData={handleChangeData}
        onChangeNPODate={handleChangeNPODate}
        // seq
        FoodRequestSequence={props.FoodRequestSequence}
        // options
        foodRequestOptions={props.foodRequestOptions}
        // data
        foodType={foodType}
        formReadOnly={formReadOnly}
        allergyFood={allergyFood}
        // component
        betoday={moment().locale("th").format("DD/MMYYYY")}
        generalTimeComponent={
          <>
            {(props.foodRequestOptions?.generalTimeList || [])?.map((item: any, index: number) => {
              return (
                <Checkbox
                  key={index}
                  style={{ marginRight: "20px" }}
                  disabled={formReadOnly || !!props.FoodRequestSequence?.id}
                  label={item?.name}
                  checked={(props.FoodRequestSequence?.general_time || [])?.includes(
                    item?.regular_time
                  )}
                  value={item?.regular_time}
                  onChange={handleChangeGeneralTime}
                />
              );
            })}
          </>
        }
        specialDiet={
          <Segment secondary>
            <Form>
              <Header as="h4">
                <Form.Field width={16}>อาหารเฉพาะโรค</Form.Field>
              </Header>
              {(props.foodRequestOptions?.specialDietList || [])?.map(
                (item: any, index: number) => {
                  return (
                    <Form.Field key={index} width={16}>
                      <Checkbox
                        style={{ marginRight: "20px" }}
                        label={item.name}
                        checked={(props.FoodRequestSequence?.special_diet || [])?.includes(item.id)}
                        value={item.id}
                        onChange={handleChangeSpecialDiets}
                      />
                    </Form.Field>
                  );
                }
              )}
              <Form.Group inline={true}>
                <Form.Field width={16}>
                  <div
                    style={{
                      display: "flex",
                      width: "100%",
                      alignItems: "center",
                    }}
                  >
                    <label style={{ marginRight: "10px" }}>หมายเหตุ</label>
                    <Input
                      name="special_diet_remark"
                      value={props.FoodRequestSequence?.special_diet_remark || ""}
                      onChange={handleChangeData}
                    />
                  </div>
                </Form.Field>
              </Form.Group>
            </Form>
          </Segment>
        }
        nutrition={
          <Segment secondary style={{ marginTop: "20px", marginBottom: "20px" }}>
            <Form>
              <Header as="h4">
                <Form.Field width={16}>คำสั่งควบคุมต่อวัน</Form.Field>
              </Header>
              {(props.FoodRequestSequence?.food_nutrition_control || [])?.map(
                (item: any, index: number) => {
                  return (
                    <Form.Group key={index} inline={true}>
                      <Form.Field width={16}>
                        <div
                          style={{
                            display: "flex",
                            width: "100%",
                            gap: "10px",
                            alignItems: "center",
                          }}
                        >
                          <div style={{ width: "40%" }}>{item?.name}</div>
                          <div style={{ width: "40%" }}>
                            <Input
                              name="food_nutrition_control"
                              value={item?.result || ""}
                              onChange={(_event: any, data: any) => {
                                props.setProp(
                                  `FoodRequestSequence.food_nutrition_control.${index}.result`,
                                  data?.value
                                );
                              }}
                            />
                          </div>
                          <div style={{ width: "20%" }}>{item?.unit_name}</div>
                        </div>
                      </Form.Field>
                    </Form.Group>
                  );
                }
              )}
              <Form.Group inline={true}>
                <Form.Field width={16}>
                  <div
                    style={{
                      display: "flex",
                      width: "100%",
                      alignItems: "center",
                    }}
                  >
                    <label style={{ marginRight: "10px" }}>หมายเหตุ</label>
                    <Input
                      name="nutrition_control_remark"
                      value={props.FoodRequestSequence?.nutrition_control_remark || ""}
                      onChange={handleChangeData}
                    />
                  </div>
                </Form.Field>
              </Form.Group>
            </Form>
          </Segment>
        }
        buttonSave={
          <ButtonLoadCheck
            // function
            setProp={props.setProp}
            onClick={handleActionFood}
            // data
            paramKey={`${CARD_FOOD_ID}_SAVE`}
            buttonLoadCheck={props.buttonLoadCheck?.[`${CARD_FOOD_ID}_SAVE`]}
            // config
            color="green"
            disabled={formReadOnly}
            name="SAVE"
            size="small"
            style={{
              display: (
                (props.FoodRequestSequence?.plan_item_status_name &&
                  !props.FoodRequestSequence?.plan_item_status_name) ||
                []
              )?.includes("ACTIVE")
                ? "none"
                : "",
            }}
            title="SAVE"
          />
        }
        buttonResume={
          <ButtonLoadCheck
            // function
            setProp={props.setProp}
            onClick={handleActionFood}
            // data
            paramKey={`${CARD_FOOD_ID}_${PLAN_ACTION.RESUME}`}
            buttonLoadCheck={props.buttonLoadCheck?.[`${CARD_FOOD_ID}_${PLAN_ACTION.RESUME}`]}
            // config
            color="teal"
            disabled={formReadOnly}
            name={PLAN_ACTION.RESUME}
            size="small"
            style={{
              display:
                props.DJANGO?.user?.role_types.includes("DOCTOR") &&
                (props.FoodRequestSequence?.plan_item_allowed_actions || [])?.includes(
                  PLAN_ACTION.RESUME
                )
                  ? ""
                  : "none",
            }}
            title={PLAN_ACTION.RESUME}
          />
        }
        buttonNPO={
          <ButtonLoadCheck
            // function
            setProp={props.setProp}
            onClick={handleActionFood}
            // data
            paramKey={`${CARD_FOOD_ID}_${PLAN_ACTION.HOLD}`}
            buttonLoadCheck={props.buttonLoadCheck?.[`${CARD_FOOD_ID}_${PLAN_ACTION.HOLD}`]}
            // config
            color="yellow"
            disabled={formReadOnly}
            name={PLAN_ACTION.HOLD}
            size="small"
            style={{
              display: (props.FoodRequestSequence?.plan_item_allowed_actions || [])?.includes(
                PLAN_ACTION.HOLD
              )
                ? ""
                : "none",
            }}
            title="NPO"
          />
        }
        buttonOff={
          <ButtonLoadCheck
            // function
            setProp={props.setProp}
            onClick={handleActionFood}
            // data
            paramKey={`${CARD_FOOD_ID}_${PLAN_ACTION.OFF}`}
            buttonLoadCheck={props.buttonLoadCheck?.[`${CARD_FOOD_ID}_${PLAN_ACTION.OFF}`]}
            // config
            color="red"
            disabled={formReadOnly}
            name={PLAN_ACTION.OFF}
            size="small"
            style={{
              display: (
                (props.DJANGO?.user?.role_types.includes("DOCTOR") &&
                  props.FoodRequestSequence?.plan_item_allowed_actions) ||
                []
              )?.includes(PLAN_ACTION.OFF)
                ? ""
                : "none",
            }}
            title={PLAN_ACTION.OFF}
          />
        }
        buttonRenew={
          <ButtonLoadCheck
            // function
            setProp={props.setProp}
            onClick={handleActionFood}
            // data
            paramKey={`${CARD_FOOD_ID}_${PLAN_ACTION.RENEW}`}
            buttonLoadCheck={props.buttonLoadCheck?.[`${CARD_FOOD_ID}_${PLAN_ACTION.RENEW}`]}
            // config
            color="teal"
            disabled={formReadOnly}
            name={PLAN_ACTION.RENEW}
            size="small"
            style={{
              display: (
                (props.DJANGO?.user?.role_types.includes("DOCTOR") &&
                  props.FoodRequestSequence?.plan_item_allowed_actions) ||
                []
              )?.includes(PLAN_ACTION.RENEW)
                ? ""
                : "none",
            }}
            title={PLAN_ACTION.RENEW}
          />
        }
        buttonClear={
          <ButtonLoadCheck
            // function
            setProp={props.setProp}
            onClick={handleActionFood}
            // data
            paramKey={`${CARD_FOOD_ID}_CLEAR`}
            buttonLoadCheck={props.buttonLoadCheck?.[`${CARD_FOOD_ID}_CLEAR`]}
            // config
            color="yellow"
            name="CLEAR"
            size="small"
            title="ล้างหน้าจอ"
          />
        }
      />

      {/* ModEditQuantity */}
      <Modal
        closeIcon
        open={props.foodRequestOptions?.openModEditQuantity}
        closeOnDimmerClick={true}
        onClose={handleCloseModEditQuantity}
      >
        <Modal.Content>
          <Form>
            <Form.Group inline={true}>
              <Form.Field width={2}>ชนิดอาหาร</Form.Field>
              <Form.Field width={6}>
                <Input readOnly value={props.FoodRequestSequence?.food_type_name} />
              </Form.Field>
              <Form.Field width={2}>เวลาอาหาร</Form.Field>
              <Form.Field width={6}>
                <Input readOnly value={props.FoodRequestSequence?.meal_time} />
              </Form.Field>
            </Form.Group>
            <Form.Group inline={true}>
              <Form.Field width={2}>รายละเอียด</Form.Field>
              <Form.Field width={14}>
                <div
                  dangerouslySetInnerHTML={{
                    __html: props.FoodRequestSequence?.detail,
                  }}
                />
              </Form.Field>
            </Form.Group>
            <Form.Group inline={true}>
              <Form.Field width={2}>จำนวนมื้อ</Form.Field>
              <Form.Field width={14}>
                <Input
                  name="quantity"
                  value={props.FoodRequestSequence?.quantity}
                  onChange={handleChangeData}
                />
              </Form.Field>
            </Form.Group>
            <Form.Group inline={true}>
              <Form.Field width={2}>มื้ออาหาร</Form.Field>
              {foodType === "G" ? (
                <Form.Field width={14}>
                  {(props.foodRequestOptions?.generalTimeList || [])?.map(
                    (item: any, index: number) => {
                      return (
                        <Checkbox
                          key={index}
                          style={{ marginRight: "20px" }}
                          label={item.name}
                          checked={(props.FoodRequestSequence?.general_time || [])?.includes(
                            item.regular_time
                          )}
                          value={item.regular_time}
                          onChange={handleChangeGeneralTime}
                        />
                      );
                    }
                  )}
                </Form.Field>
              ) : (
                <Form.Field width={14}>
                  <Dropdown
                    clearable={true}
                    fluid={true}
                    multiple={true}
                    name="routine_time"
                    onChange={handleChangeData}
                    options={props.foodRequestOptions?.routineTimeOptions || []}
                    search={true}
                    selection={true}
                    value={props.FoodRequestSequence?.routine_time || []}
                  />
                </Form.Field>
              )}
            </Form.Group>
            <Form.Group inline={true}>
              <Form.Field width={14}></Form.Field>
              <Form.Field width={2}>
                <ButtonLoadCheck
                  // function
                  setProp={props.setProp}
                  onClick={() => {
                    props.runSequence({
                      sequence: "FoodRequest",
                      action: FOOD_ACTION.EDIT_QUANTITY,
                      card: SUB_CARD_FOOD_ID,
                    });
                  }}
                  // data
                  paramKey={`${SUB_CARD_FOOD_ID}_${FOOD_ACTION.EDIT_QUANTITY}`}
                  buttonLoadCheck={
                    props.buttonLoadCheck?.[`${SUB_CARD_FOOD_ID}_${FOOD_ACTION.EDIT_QUANTITY}`]
                  }
                  // config
                  color="green"
                  name={FOOD_ACTION.EDIT_QUANTITY}
                  size="small"
                  title="SAVE"
                />
              </Form.Field>
            </Form.Group>
          </Form>
        </Modal.Content>
      </Modal>
    </>
  );
};

CardFood.defaultProps = CardFoodInitial;

export default React.memo(CardFood);
