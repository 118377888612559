import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  Form,
  FormGroup,
  TextArea,
  Radio,
  Checkbox
} from 'semantic-ui-react'
import {
  DateTextBox
} from 'react-lib/apps/common'

const CardExamAbsenceCertificateUX = (props: any) => {
    return(
      <div>
        <Form>
          <FormGroup
            className="ui form"
            style={{display: "flex", padding: "1em 0em 0em 1em", gap: "1em"}}>
            <label
              style={{display: "flex", fontWeight: "bold", width: "6em"}}>
              {props.languageUX === "EN" ? "Chief Complaint" : "อาการนำ"}
            </label>
            <TextArea
              disabled={props.onHandleDisableForm}
              id="CardExamAbsenceCertificate-TextArea-CheifComplaint"
              onChange={(e) => props.onHandleChangeText("chiefComplaint", e)}
              style={{width: "26em"}}
              value={props.onChief}>
            </TextArea>
          </FormGroup>
          <FormGroup
            className="ui form"
            style={{display: "flex", padding: "0em 0em 0em 1em", gap: "1em"}}>
            <label
              style={{display: "flex", fontWeight: "bold", width: "6em"}}>
              {props.languageUX === "EN" ? "Diagnosis" : "การวินิจฉัย"}
            </label>
            <TextArea
              disabled={props.onHandleDisableForm}
              id="CardExamAbsenceCertificate-TextArea-Diagnosis"
              onChange={(e) => props.onHandleChangeText("diagnosis", e)}
              style={{width: "26em"}}
              value={props.onDiagnosis}>
            </TextArea>
          </FormGroup>
          <FormGroup
            className="ui form"
            style={{display: "flex", padding: "0em 0em 0em 1em", gap: "1em"}}>
            <label
              style={{display: "flex", fontWeight: "bold", width: "6em"}}>
              {props.languageUX === "EN" ? "Comment" : "ความคิดเห็น"}
            </label>
            <TextArea
              disabled={props.onHandleDisableForm}
              id="CardExamAbsenceCertificate-TextArea-Comment"
              onChange={(e) => props.onHandleChangeText("comment", e)}
              style={{width: "26em"}}
              value={props.onComment}>
            </TextArea>
          </FormGroup>
          <Form>
            <FormGroup
              className="ui form"
              style={{display: "flex", padding: "0em 0em 0em 1em"}}>
              <label
                style={{display: "flex", fontWeight: "bold"}}>
                Visit Type
              </label>
            </FormGroup>
            <FormGroup
              className="ui form"
              style={{display: "flex", padding: "0em 0em 0em 1em",  gap: "1em"}}>
              <Radio
                checked={props.onOpdRadio}
                disabled={props.onHandleCheckRadio.disableOpd || props.onHandleDisableForm}
                id="CardExamAbsenceCertificate-Radio-IsOpd"
                label="OPD"
                style={{display: "flex",  alignItems: "center", width: "4em"}}>
              </Radio>
              <label
                style={{display: "flex",  alignItems: "center"}}>
                {props.languageUX === "EN" ? "Date of Examination" : "วันที่ตรวจ"}
              </label>
              <DateTextBox
                disabled={props.onHandleCheckRadio.disableOpd || props.onHandleDisableForm}
                id="CardExamAbsenceCertificate-DateTextBox-OpdExamDate"
                minDate={props.onExamMinDate}
                onChange={(date: string) => props.onHandleChangeDate("examDate", date)}
                value={props.examDate}>
              </DateTextBox>
            </FormGroup>
            <FormGroup
              className="ui form"
              style={{display: "flex", padding: "0em 0em 0em 2em",  gap: "1em"}}>
              <Checkbox
                checked={props.onHandleCheckCheckbox("shouldOpdAbsence")}
                disabled={props.onHandleCheckRadio.disableOpd || props.onHandleDisableForm}
                id="CardExamAbsenceCertificate-Checkbox-OpdAbsenceDuration"
                label={props.languageUX === "EN" ? "Period of absence required" : "ระยะเวลาที่ควรพักงาน"}
                onChange={(e, data) => props.onHandleOpdCheckbox(e, "shouldOpdAbsence", data)}
                style={{display: "flex",  alignItems: "center"}}>
              </Checkbox>
              <label
                style={{display: "flex",  alignItems: "center"}}>
                {props.languageUX === "EN" ? "From" : "ตั้งแต่"}
              </label>
              <DateTextBox
                disabled={props.onHandleDateDisable("OPD") || props.onHandleDisableForm}
                id="CardExamAbsenceCertificate-DateTextBox-OpdStartAbsence"
                minDate={props.onExamMinDate}
                onChange={(date: string) => props.onHandleChangeDate("opdStartDate", date)}
                style={{width: "9.5em"}}
                value={props.opdAbsenceStartDate}>
              </DateTextBox>
              <label
                style={{display: "flex",  alignItems: "center"}}>
                {props.languageUX === "EN" ? "To" : "ถึง"}
              </label>
              <DateTextBox
                disabled={props.onHandleDateDisable("OPD") || props.onHandleDisableForm}
                id="CardExamAbsenceCertificate-DateTextBox-OpdEndAbsence"
                minDate={props.onExamMinDate}
                onChange={(date: string) => props.onHandleChangeDate("opdEndDate", date)}
                style={{width: "9.5em"}}
                value={props.opdAbsenceEndDate}>
              </DateTextBox>
            </FormGroup>
            <div
              style={{padding: "0em 0em 0em 2em", width: "550px"}}>
              
              <hr>
              </hr>
            </div>
            <FormGroup
              className="ui form"
              style={{display: "flex", padding: "0.5em 0em 0em 1em",  gap: "1em"}}>
              <Radio
                checked={props.onIpdRadio}
                disabled={props.onHandleCheckRadio.disableIpd || props.onHandleDisableForm}
                id="CardExamAbsenceCertificate-Radio-IsIpd"
                label="IPD"
                style={{display: "flex",  alignItems: "center", width: "4em"}}>
              </Radio>
              <label
                style={{display: "flex",  alignItems: "center", padding: "0em 0.2em 0em 0em"}}>
                Admission
              </label>
              <label
                style={{display: "flex",  alignItems: "center",  padding: "0em 0em 0em 2em"}}>
                {props.languageUX === "EN" ? "From" : "ตั้งแต่"}
              </label>
              <DateTextBox
                disabled={props.onHandleCheckRadio.disableIpd || props.onHandleDisableForm}
                id="CardExamAbsenceCertificate-DateTextBox-IpdStartAdmission"
                onChange={(date: string) => props.onHandleChangeDate("admitStartDate", date)}
                style={{width: "9.5em"}}
                value={props.admitStartDate}>
              </DateTextBox>
              <label
                style={{display: "flex",  alignItems: "center", padding: "0em 0em 0em 0em"}}>
                {props.languageUX === "EN" ? "To" : "ถึง"}
              </label>
              <DateTextBox
                disabled={props.onHandleCheckRadio.disableIpd || props.onHandleDisableForm}
                id="CardExamAbsenceCertificate-DateTextBox-IpdEndAdmission"
                minDate={props.onExamMinDate}
                onChange={(date: string) => props.onHandleChangeDate("admitEndDate", date)}
                style={{width: "9.5em"}}
                value={props.admitEndDate}>
              </DateTextBox>
            </FormGroup>
            <FormGroup
              className="ui form"
              style={{display: "flex", padding: "0em 0em 0em 2em",  gap: "1em"}}>
              <Checkbox
                checked={props.onHandleCheckCheckbox("shouldIpdAbsence")}
                disabled={props.onHandleCheckRadio.disableIpd || props.onHandleDisableForm}
                id="CardExamAbsenceCertificate-Checkbox-IpdAbsenceDuration"
                label={props.languageUX === "EN" ? "Period of absence required" : "ระยะเวลาที่ควรพักงาน"}
                onChange={(e, data) => props.onHandleOpdCheckbox(e, "shouldIpdAbsence", data)}
                style={{display: "flex",  alignItems: "center"}}>
              </Checkbox>
              <label
                style={{display: "flex",  alignItems: "center"}}>
                {props.languageUX === "EN" ? "From" : "ตั้งแต่"}
              </label>
              <DateTextBox
                disabled={props.onHandleDateDisable("IPD") || props.onHandleDisableForm}
                id="CardExamAbsenceCertificate-DateTextBox-IpdStartAbsence"
                minDate={props.onExamMinDate}
                onChange={(date: string) => props.onHandleChangeDate("ipdStartDate", date)}
                style={{width: "9.5em"}}
                value={props.ipdAbsenceStartDate}>
              </DateTextBox>
              <label
                style={{display: "flex",  alignItems: "center"}}>
                {props.languageUX === "EN" ? "To" : "ถึง"}
              </label>
              <DateTextBox
                disabled={props.onHandleDateDisable("IPD") || props.onHandleDisableForm}
                id="CardExamAbsenceCertificate-DateTextBox-IpdEndAbsence"
                minDate={props.onExamMinDate}
                onChange={(date: string) => props.onHandleChangeDate("ipdEndDate", date)}
                style={{width: "9.5em"}}
                value={props.ipdAbsenceEndDate}>
              </DateTextBox>
            </FormGroup>
          </Form>
          <FormGroup
            className="ui form"
            style={{display: "flex", justifyContent: "flex-end"}}>
            <label>
              {props.onCloseSelected}
            </label>
          </FormGroup>
        </Form>
      </div>
    )
}


export default CardExamAbsenceCertificateUX

export const screenPropsDefault = {"language":"TH"}

/* Date Time : Thu Aug 22 2024 13:47:11 GMT+0700 (Indochina Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "props": {
        "className": {
          "type": "value",
          "value": ""
        },
        "id": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 0
    },
    {
      "from": "semantic-ui-react",
      "id": 155,
      "name": "Form",
      "parent": 0,
      "props": {
      },
      "seq": 155,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 156,
      "name": "FormGroup",
      "parent": 155,
      "props": {
        "className": {
          "type": "value",
          "value": "ui form"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", padding: \"1em 0em 0em 1em\", gap: \"1em\"}"
        }
      },
      "seq": 156,
      "void": false
    },
    {
      "from": null,
      "id": 157,
      "name": "label",
      "parent": 156,
      "props": {
        "children": {
          "type": "value",
          "value": "อาการนำ",
          "valueEN": "Chief Complaint"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", fontWeight: \"bold\", width: \"6em\"}"
        }
      },
      "seq": 157,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 158,
      "name": "TextArea",
      "parent": 156,
      "props": {
        "disabled": {
          "type": "code",
          "value": "props.onHandleDisableForm"
        },
        "id": {
          "type": "value",
          "value": "CardExamAbsenceCertificate-TextArea-CheifComplaint"
        },
        "onChange": {
          "type": "code",
          "value": "(e) => props.onHandleChangeText(\"chiefComplaint\", e)"
        },
        "style": {
          "type": "code",
          "value": "{width: \"26em\"}"
        },
        "value": {
          "type": "code",
          "value": "props.onChief"
        }
      },
      "seq": 158,
      "void": true
    },
    {
      "from": "semantic-ui-react",
      "id": 159,
      "name": "FormGroup",
      "parent": 155,
      "props": {
        "className": {
          "type": "value",
          "value": "ui form"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", padding: \"0em 0em 0em 1em\", gap: \"1em\"}"
        }
      },
      "seq": 159,
      "void": false
    },
    {
      "from": null,
      "id": 160,
      "name": "label",
      "parent": 159,
      "props": {
        "children": {
          "type": "value",
          "value": "การวินิจฉัย",
          "valueEN": "Diagnosis"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", fontWeight: \"bold\", width: \"6em\"}"
        }
      },
      "seq": 160,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 161,
      "name": "TextArea",
      "parent": 159,
      "props": {
        "disabled": {
          "type": "code",
          "value": "props.onHandleDisableForm"
        },
        "id": {
          "type": "value",
          "value": "CardExamAbsenceCertificate-TextArea-Diagnosis"
        },
        "onChange": {
          "type": "code",
          "value": "(e) => props.onHandleChangeText(\"diagnosis\", e)"
        },
        "style": {
          "type": "code",
          "value": "{width: \"26em\"}"
        },
        "value": {
          "type": "code",
          "value": "props.onDiagnosis"
        }
      },
      "seq": 161,
      "void": true
    },
    {
      "from": "semantic-ui-react",
      "id": 162,
      "name": "FormGroup",
      "parent": 155,
      "props": {
        "className": {
          "type": "value",
          "value": "ui form"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", padding: \"0em 0em 0em 1em\", gap: \"1em\"}"
        }
      },
      "seq": 162,
      "void": false
    },
    {
      "from": null,
      "id": 163,
      "name": "label",
      "parent": 162,
      "props": {
        "children": {
          "type": "value",
          "value": "ความคิดเห็น",
          "valueEN": "Comment"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", fontWeight: \"bold\", width: \"6em\"}"
        }
      },
      "seq": 163,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 164,
      "name": "TextArea",
      "parent": 162,
      "props": {
        "disabled": {
          "type": "code",
          "value": "props.onHandleDisableForm"
        },
        "id": {
          "type": "value",
          "value": "CardExamAbsenceCertificate-TextArea-Comment"
        },
        "onChange": {
          "type": "code",
          "value": "(e) => props.onHandleChangeText(\"comment\", e)"
        },
        "style": {
          "type": "code",
          "value": "{width: \"26em\"}"
        },
        "value": {
          "type": "code",
          "value": "props.onComment"
        }
      },
      "seq": 164,
      "void": true
    },
    {
      "from": "semantic-ui-react",
      "id": 165,
      "name": "FormGroup",
      "parent": 177,
      "props": {
        "className": {
          "type": "value",
          "value": "ui form"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", padding: \"0em 0em 0em 1em\"}"
        }
      },
      "seq": 165,
      "void": false
    },
    {
      "from": null,
      "id": 166,
      "name": "label",
      "parent": 165,
      "props": {
        "children": {
          "type": "value",
          "value": "Visit Type"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", fontWeight: \"bold\"}"
        }
      },
      "seq": 166,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 167,
      "name": "FormGroup",
      "parent": 177,
      "props": {
        "className": {
          "type": "value",
          "value": "ui form"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", padding: \"0em 0em 0em 1em\",  gap: \"1em\"}"
        }
      },
      "seq": 167,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 168,
      "name": "Radio",
      "parent": 167,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.onOpdRadio"
        },
        "disabled": {
          "type": "code",
          "value": "props.onHandleCheckRadio.disableOpd || props.onHandleDisableForm"
        },
        "id": {
          "type": "value",
          "value": "CardExamAbsenceCertificate-Radio-IsOpd"
        },
        "label": {
          "type": "value",
          "value": "OPD"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\",  alignItems: \"center\", width: \"4em\"}"
        }
      },
      "seq": 168,
      "void": false
    },
    {
      "from": null,
      "id": 169,
      "name": "label",
      "parent": 167,
      "props": {
        "children": {
          "type": "value",
          "value": "วันที่ตรวจ",
          "valueEN": "Date of Examination"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\",  alignItems: \"center\"}"
        }
      },
      "seq": 169,
      "void": false
    },
    {
      "from": "react-lib/apps/common",
      "id": 170,
      "name": "DateTextBox",
      "parent": 167,
      "props": {
        "disabled": {
          "type": "code",
          "value": "props.onHandleCheckRadio.disableOpd || props.onHandleDisableForm"
        },
        "id": {
          "type": "value",
          "value": "CardExamAbsenceCertificate-DateTextBox-OpdExamDate"
        },
        "minDate": {
          "type": "code",
          "value": "props.onExamMinDate"
        },
        "onChange": {
          "type": "code",
          "value": "(date: string) => props.onHandleChangeDate(\"examDate\", date)"
        },
        "value": {
          "type": "code",
          "value": "props.examDate"
        }
      },
      "seq": 170,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 171,
      "name": "FormGroup",
      "parent": 177,
      "props": {
        "className": {
          "type": "value",
          "value": "ui form"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", padding: \"0em 0em 0em 2em\",  gap: \"1em\"}"
        }
      },
      "seq": 171,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 172,
      "name": "Checkbox",
      "parent": 171,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.onHandleCheckCheckbox(\"shouldOpdAbsence\")"
        },
        "disabled": {
          "type": "code",
          "value": "props.onHandleCheckRadio.disableOpd || props.onHandleDisableForm"
        },
        "id": {
          "type": "value",
          "value": "CardExamAbsenceCertificate-Checkbox-OpdAbsenceDuration"
        },
        "label": {
          "type": "value",
          "value": "ระยะเวลาที่ควรพักงาน",
          "valueEN": "Period of absence required"
        },
        "onChange": {
          "type": "code",
          "value": "(e, data) => props.onHandleOpdCheckbox(e, \"shouldOpdAbsence\", data)"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\",  alignItems: \"center\"}"
        }
      },
      "seq": 172,
      "void": false
    },
    {
      "from": null,
      "id": 173,
      "name": "label",
      "parent": 171,
      "props": {
        "children": {
          "type": "value",
          "value": "ตั้งแต่",
          "valueEN": "From"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\",  alignItems: \"center\"}"
        }
      },
      "seq": 173,
      "void": false
    },
    {
      "from": "react-lib/apps/common",
      "id": 174,
      "name": "DateTextBox",
      "parent": 171,
      "props": {
        "disabled": {
          "type": "code",
          "value": "props.onHandleDateDisable(\"OPD\") || props.onHandleDisableForm"
        },
        "id": {
          "type": "value",
          "value": "CardExamAbsenceCertificate-DateTextBox-OpdStartAbsence"
        },
        "minDate": {
          "type": "code",
          "value": "props.onExamMinDate"
        },
        "onChange": {
          "type": "code",
          "value": "(date: string) => props.onHandleChangeDate(\"opdStartDate\", date)"
        },
        "style": {
          "type": "code",
          "value": "{width: \"9.5em\"}"
        },
        "value": {
          "type": "code",
          "value": "props.opdAbsenceStartDate"
        }
      },
      "seq": 174,
      "void": false
    },
    {
      "from": null,
      "id": 175,
      "name": "label",
      "parent": 171,
      "props": {
        "children": {
          "type": "value",
          "value": "ถึง",
          "valueEN": "To"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\",  alignItems: \"center\"}"
        }
      },
      "seq": 175,
      "void": false
    },
    {
      "from": "react-lib/apps/common",
      "id": 176,
      "name": "DateTextBox",
      "parent": 171,
      "props": {
        "disabled": {
          "type": "code",
          "value": "props.onHandleDateDisable(\"OPD\") || props.onHandleDisableForm"
        },
        "id": {
          "type": "value",
          "value": "CardExamAbsenceCertificate-DateTextBox-OpdEndAbsence"
        },
        "minDate": {
          "type": "code",
          "value": "props.onExamMinDate"
        },
        "onChange": {
          "type": "code",
          "value": "(date: string) => props.onHandleChangeDate(\"opdEndDate\", date)"
        },
        "style": {
          "type": "code",
          "value": "{width: \"9.5em\"}"
        },
        "value": {
          "type": "code",
          "value": "props.opdAbsenceEndDate"
        }
      },
      "seq": 176,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 177,
      "name": "Form",
      "parent": 155,
      "props": {
      },
      "seq": 177,
      "void": false
    },
    {
      "from": null,
      "id": 178,
      "name": "div",
      "parent": 177,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{padding: \"0em 0em 0em 2em\", width: \"550px\"}"
        }
      },
      "seq": 178,
      "void": false
    },
    {
      "from": null,
      "id": 179,
      "name": "hr",
      "parent": 178,
      "props": {
      },
      "seq": 179,
      "void": true
    },
    {
      "from": "semantic-ui-react",
      "id": 180,
      "name": "FormGroup",
      "parent": 177,
      "props": {
        "className": {
          "type": "value",
          "value": "ui form"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", padding: \"0.5em 0em 0em 1em\",  gap: \"1em\"}"
        }
      },
      "seq": 180,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 181,
      "name": "Radio",
      "parent": 180,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.onIpdRadio"
        },
        "disabled": {
          "type": "code",
          "value": "props.onHandleCheckRadio.disableIpd || props.onHandleDisableForm"
        },
        "id": {
          "type": "value",
          "value": "CardExamAbsenceCertificate-Radio-IsIpd"
        },
        "label": {
          "type": "value",
          "value": "IPD"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\",  alignItems: \"center\", width: \"4em\"}"
        }
      },
      "seq": 181,
      "void": false
    },
    {
      "from": null,
      "id": 182,
      "name": "label",
      "parent": 180,
      "props": {
        "children": {
          "type": "value",
          "value": "Admission"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\",  alignItems: \"center\", padding: \"0em 0.2em 0em 0em\"}"
        }
      },
      "seq": 182,
      "void": false
    },
    {
      "from": "react-lib/apps/common",
      "id": 183,
      "name": "DateTextBox",
      "parent": 180,
      "props": {
        "disabled": {
          "type": "code",
          "value": "props.onHandleCheckRadio.disableIpd || props.onHandleDisableForm"
        },
        "id": {
          "type": "value",
          "value": "CardExamAbsenceCertificate-DateTextBox-IpdStartAdmission"
        },
        "onChange": {
          "type": "code",
          "value": "(date: string) => props.onHandleChangeDate(\"admitStartDate\", date)"
        },
        "style": {
          "type": "code",
          "value": "{width: \"9.5em\"}"
        },
        "value": {
          "type": "code",
          "value": "props.admitStartDate"
        }
      },
      "seq": 190,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 184,
      "name": "FormGroup",
      "parent": 177,
      "props": {
        "className": {
          "type": "value",
          "value": "ui form"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", padding: \"0em 0em 0em 2em\",  gap: \"1em\"}"
        }
      },
      "seq": 184,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 185,
      "name": "Checkbox",
      "parent": 184,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.onHandleCheckCheckbox(\"shouldIpdAbsence\")"
        },
        "disabled": {
          "type": "code",
          "value": "props.onHandleCheckRadio.disableIpd || props.onHandleDisableForm"
        },
        "id": {
          "type": "value",
          "value": "CardExamAbsenceCertificate-Checkbox-IpdAbsenceDuration"
        },
        "label": {
          "type": "value",
          "value": "ระยะเวลาที่ควรพักงาน",
          "valueEN": "Period of absence required"
        },
        "onChange": {
          "type": "code",
          "value": "(e, data) => props.onHandleOpdCheckbox(e, \"shouldIpdAbsence\", data)"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\",  alignItems: \"center\"}"
        }
      },
      "seq": 185,
      "void": false
    },
    {
      "from": null,
      "id": 186,
      "name": "label",
      "parent": 184,
      "props": {
        "children": {
          "type": "value",
          "value": "ตั้งแต่",
          "valueEN": "From"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\",  alignItems: \"center\"}"
        }
      },
      "seq": 186,
      "void": false
    },
    {
      "from": "react-lib/apps/common",
      "id": 187,
      "name": "DateTextBox",
      "parent": 184,
      "props": {
        "disabled": {
          "type": "code",
          "value": "props.onHandleDateDisable(\"IPD\") || props.onHandleDisableForm"
        },
        "id": {
          "type": "value",
          "value": "CardExamAbsenceCertificate-DateTextBox-IpdStartAbsence"
        },
        "minDate": {
          "type": "code",
          "value": "props.onExamMinDate"
        },
        "onChange": {
          "type": "code",
          "value": "(date: string) => props.onHandleChangeDate(\"ipdStartDate\", date)"
        },
        "style": {
          "type": "code",
          "value": "{width: \"9.5em\"}"
        },
        "value": {
          "type": "code",
          "value": "props.ipdAbsenceStartDate"
        }
      },
      "seq": 187,
      "void": false
    },
    {
      "from": null,
      "id": 188,
      "name": "label",
      "parent": 184,
      "props": {
        "children": {
          "type": "value",
          "value": "ถึง",
          "valueEN": "To"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\",  alignItems: \"center\"}"
        }
      },
      "seq": 188,
      "void": false
    },
    {
      "from": "react-lib/apps/common",
      "id": 189,
      "name": "DateTextBox",
      "parent": 184,
      "props": {
        "disabled": {
          "type": "code",
          "value": "props.onHandleDateDisable(\"IPD\") || props.onHandleDisableForm"
        },
        "id": {
          "type": "value",
          "value": "CardExamAbsenceCertificate-DateTextBox-IpdEndAbsence"
        },
        "minDate": {
          "type": "code",
          "value": "props.onExamMinDate"
        },
        "onChange": {
          "type": "code",
          "value": "(date: string) => props.onHandleChangeDate(\"ipdEndDate\", date)"
        },
        "style": {
          "type": "code",
          "value": "{width: \"9.5em\"}"
        },
        "value": {
          "type": "code",
          "value": "props.ipdAbsenceEndDate"
        }
      },
      "seq": 189,
      "void": false
    },
    {
      "from": null,
      "id": 190,
      "name": "label",
      "parent": 180,
      "props": {
        "children": {
          "type": "value",
          "value": "ตั้งแต่",
          "valueEN": "From"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\",  alignItems: \"center\",  padding: \"0em 0em 0em 2em\"}"
        }
      },
      "seq": 183,
      "void": false
    },
    {
      "from": null,
      "id": 191,
      "name": "label",
      "parent": 180,
      "props": {
        "children": {
          "type": "value",
          "value": "ถึง",
          "valueEN": "To"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\",  alignItems: \"center\", padding: \"0em 0em 0em 0em\"}"
        }
      },
      "seq": 191,
      "void": false
    },
    {
      "from": "react-lib/apps/common",
      "id": 192,
      "name": "DateTextBox",
      "parent": 180,
      "props": {
        "disabled": {
          "type": "code",
          "value": "props.onHandleCheckRadio.disableIpd || props.onHandleDisableForm"
        },
        "id": {
          "type": "value",
          "value": "CardExamAbsenceCertificate-DateTextBox-IpdEndAdmission"
        },
        "minDate": {
          "type": "code",
          "value": "props.onExamMinDate"
        },
        "onChange": {
          "type": "code",
          "value": "(date: string) => props.onHandleChangeDate(\"admitEndDate\", date)"
        },
        "style": {
          "type": "code",
          "value": "{width: \"9.5em\"}"
        },
        "value": {
          "type": "code",
          "value": "props.admitEndDate"
        }
      },
      "seq": 192,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 193,
      "name": "FormGroup",
      "parent": 155,
      "props": {
        "className": {
          "type": "value",
          "value": "ui form"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", justifyContent: \"flex-end\"}"
        }
      },
      "seq": 193,
      "void": false
    },
    {
      "from": null,
      "id": 194,
      "name": "label",
      "parent": 193,
      "props": {
        "children": {
          "type": "value",
          "value": "{props.onCloseSelected}"
        }
      },
      "seq": 194,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 80,
  "name": "CardExamAbsenceCertificateUX",
  "project": "IsHealth",
  "screenPropsDefault": {
    "language": "TH"
  },
  "width": 55
}

*********************************************************************************** */
