import WasmController from "react-lib/frameworks/WasmController";

import moment from "moment";

// APIs
// TPD
import DrugOrderActionView from "issara-sdk/apis/DrugOrderActionView_apps_TPD";
import DrugOrderDetailForAction from "issara-sdk/apis/DrugOrderDetailForAction_apps_TPD";
import DrugOrderDetailForRefillPlanning from "issara-sdk/apis/DrugOrderDetailForRefillPlanning_apps_TPD";
import DrugRefillOrderCreate from "issara-sdk/apis/DrugRefillOrderCreate_apps_TPD";
import DrugOrderQueueList from "issara-sdk/apis/DrugOrderQueueList_apps_TPD";
import MatchedAutoDiscountPolicyItemForEditList from "issara-sdk/apis/MatchedAutoDiscountPolicyItemForEditList_apps_TPD";
import ApplyAutoDiscountPolicyItemView from "issara-sdk/apis/ApplyAutoDiscountPolicyItemView_apps_TPD";
// PRX
import ShippingOrderPerformedList from "issara-sdk/apis/ShippingOrderPerformedList_apps_PRX";

// Common
import getPdfMake from "react-lib/appcon/common/pdfMake";

// Form
import FormHomeMed from "../FormHomeMed";
import FormQrCodeDrugDelivery from "react-lib/appcon/common/FormQrCodeDrugDelivery";

// Interface
import { GetDrugPermission } from "./DrugSelect";
import { SORT_ADR_ORDER } from "./Allergy";

// Utils
import { beToAd, formatDate } from "react-lib/utils";

// Config
import CONFIG from "config/config";
import UserLoginView from "issara-sdk/apis/UserLoginView_core";

export type State = {
  // CommonInterface
  successMessage?: any;
  errorMessage?: any;
  buttonLoadCheck?: any; // {cardName: LOADING || SUCCESS || ERROR}
  modConfirmAction?: any;

  // TPDInterface
  drugDelivery?: {
    token?: string;
    employeeName?: string;
    isTransport?: boolean | null;
    error?: any;
    loading?: boolean;
    isDrugDelivery?: boolean;
    showDrugDelivery?: boolean;
    shipper?: string | null;
    trackingNo?: string | null;
    address?: string | null;
    shippingOrder?: any[] | null;
    personName?: string;
    isOthers?: boolean | null;
    telepharType?: string;
  };

  // selected
  selectedEncounter?: any;
  selectedDrugOrderWorking?: any;
  selectedPatient?: any;

  // modNoteReject
  modNoteReject?: {
    open?: boolean;
    note?: any;
  };

  loadingStatus?: any;
  // seq
  DrugOrderActionSequence?: {
    sequenceIndex?: string | null;
    noteReprint?: string | null;
    language?: string | null;
    printPharma?: boolean | null;
    printPatient?: boolean | null;
    printLabel?: boolean | null;
    printMedReport?: boolean | null;
    isReprintSuccess?: boolean | null;
    modDiscount?: {
      action: "APPLY" | "EDIT";
      items: any[];
    } | null;
  } | null;
  DrugRefillSequence?: any;
  DrugOrderPendingList?: any;

  // permission
  drugPermission?: any;
};

export const StateInitial: State = {
  DrugOrderActionSequence: {
    sequenceIndex: null,
    printMedReport: false,
    isReprintSuccess: false,
  },
  DrugRefillSequence: null,
  DrugOrderPendingList: null,
  modNoteReject: {
    open: false,
    note: null,
  },
  drugDelivery: {
    isDrugDelivery: false,
    showDrugDelivery: false,
  },
};

export type Event =
  | { message: "RunSequence"; params: {} }
  | { message: "GetMasterData"; params: any }
  | { message: "HandleQRDrugDeliveryReport"; params: {} }
  | { message: "SelectDrugOrderWorking"; params: {} };

export type Data = {
  division?: number;
  device?: number;
};

export const DataInitial = {};

export const COLORS = {
  red: "#FF0000",
  yellow: "#F7AC08",
  blue: "#0072BC",
  light_blue: "#5DBCD2",
  green: "#27AE60",
  violet: "#8649E9",
  grey: "#746A6A",
  orange: "#F2711C",
  black: "#000000",
} as const;

export const ORDER_TYPE_COLORS = {
  STAT: COLORS.red,
  ONE_DAY: COLORS.yellow,
  ONE_DOSE: COLORS.yellow,
  CONTINUE: COLORS.blue,
  CONTINUE_PLAN: COLORS.light_blue,
  HOME_OPD: COLORS.green,
  HOME_IPD: COLORS.green,
  OPERATING: COLORS.violet,
  REFILL: COLORS.light_blue,
  PICKING: COLORS.black,
} as const;

export const ORDER_STATUS_COLORS = {
  REQUESTED: COLORS.red,
  PRINTED: COLORS.yellow,
  CHECKED: COLORS.green,
  TRANSPORTED: COLORS.blue,
  RECEIVED: COLORS.blue,
  REJECTED: COLORS.grey,
  DIVIDED: COLORS.light_blue,
  REFILL_PLANNED: COLORS.black,
  PICKED: COLORS.black,
} as const;

type Handler = (controller: WasmController<State, Event, Data>, params?: any) => any;

export const Start: Handler = async (controller, params) => {
  let state = controller.getState();

  if (!state.DrugOrderActionSequence) {
    return;
  }

  controller.handleEvent({
    message: "GetMasterData",
    params: {
      masters: [
        ["unit", {}],
        ["route", {}],
        ["frequency", {}],
        ["method", {}],
        ["site", {}],
        ["shippingEmployee", {}],
      ],
    },
  });

  let permission = state.drugPermission;

  if (!permission) {
    permission = await GetDrugPermission(controller as any, params);
  }

  // check REFILL_PLANNED Queue
  getDrugRefillPlaned(controller, params);

  state = controller.getState();

  controller.setState(
    {
      drugPermission: permission,
      DrugOrderActionSequence: {
        ...state.DrugOrderActionSequence,
        sequenceIndex: "POOL",
      },
    },
    async () => {
      // * มี Shipping order
      if (state.drugDelivery?.shipper) {
        controller.handleEvent({
          // get drugOrderQueue with out filter
          message: "GetDrugOrderQueue" as any,
          params: {
            patient: state.selectedEncounter?.patient_id || "",
          },
        });
      }
    }
  );
};

const printFormHomeMedPDF: Handler = async (controller, resultSelectedDrugOrderWorking: any) => {
  let state = controller.getState();
  const promiseArray = (resultSelectedDrugOrderWorking?.items || []).map(
    (item: any) => {
      return new Promise((resolve, reject) => {
        const img = document.createElement("img");
        img.crossOrigin = "anonymous";
        img.src = item.drug_image;
        img.onload = () => {
          resolve({
            ...item,
            drug_image: convertImageToPNG(img),
          });
        };
        img.onerror = () => {
          resolve({ ...item, drug_image: null });
        };
      });
    }
  );
  const items = await Promise.all(promiseArray);
  const data = Object.assign({
    selectedPatient: state.selectedPatient,
    selectedDrugOrder: { ...resultSelectedDrugOrderWorking, items },
  });

  const adrList: any[] = state.AllergySequence.adrList || [];

  adrList.sort(
    (a: any, b: any) => SORT_ADR_ORDER[a.type_name_name] - SORT_ADR_ORDER[b.type_name_name]
  );

  const adrText = adrList
    .map((acc: any) => acc.name)
    .filter(Boolean)
    .join(", ");

  let docDef: any = { content: [] };
  docDef = await FormHomeMed({ ...data, adrText });

  const pdfMake = (await getPdfMake(true)).createPdf(docDef);
  pdfMake.open();
};

export const PerformDrugOrderAction: Handler = async (controller, params) => {
  let state = controller.getState();

  if (!state.DrugOrderActionSequence) {
    return;
  }

  if (!params?.action) {
    return;
  }

  if (params.action === "UPDATE_CONSULT_STATUS") {
    controller.setState({
      buttonLoadCheck: {
        ...state.buttonLoadCheck,
        [params.buttonLoadKey]: "LOADING",
      },
    });

    let status = "IDLE";

    // * RECEIVED INTRA TELEPHAR
    if (state.selectedDrugOrderWorking?.status === "RECEIVED") {
      status = "FINISH";
    }

    await controller.handleEvent({
      message: "DrugOrderUpdateCallTelephar" as any,
      params: {
        id: state.selectedDrugOrderWorking.pk,
        data: { consult_status: status },
      },
    });

    return refreshDrugOrderQueue(controller, params);
  } else {
    const actionMap = {
      DELIVER: deliveryAction,
      WAIVE: wuncAction,
      UNVERIFY: wuncAction,
      UNCHECK: wuncAction,
      UNDELIVER: wuncAction,
      UNDIVIDE: wuncAction,
      CANCEL_PLAN: wuncAction,
      PRINTQR: printQR,
      REPRINT: reprint,
      REJECT: reject,
      getDrugRefillPlaned: getDrugRefillPlaned,
      getRefill: getRefill,
      saveRefill: saveRefill,
      addOrderList: addOrderList,
      calculatorSplit: calculatorSplit,
      createRefillOrder: createRefillOrder,
      changeOrderItemData: changeOrderItemData,
      PRINT: printAction,
      getDiscount: getDiscount,
      editDiscount: editDiscount,
    } as any;

    let paramAction: any = {};

    const actionFunction = actionMap[params?.action];

    if (actionFunction) {
      paramAction = await actionFunction(controller, params);

      if (paramAction === null) {
        return;
      }
    } else {
      paramAction = {
        action: params.action,
        ...params,
      };
    }

    if (paramAction === undefined) {
      return;
    }

    controller.setState({
      buttonLoadCheck: {
        ...state.buttonLoadCheck,
        [params.buttonLoadKey]: "LOADING",
      },
    });

    // Feature 68258
    if (paramAction?.action === "RECEIVE") {
      if (!params?.repassword) {
        controller.setState({
          buttonLoadCheck: {
            ...state.buttonLoadCheck,
            [params.buttonLoadKey]: "ERROR",
          },
          loadingStatus: { ...state.loadingStatus, [params.card]: false },
          errorMessage: {
            ...state.errorMessage,
            [params.card]: { error: { "กด รับยา/เวชภัณฑ์ ไม่สำเร็จ": "จำเป็นต้องระบุรหัสผ่าน" } },
          },
        });
        return;
      }

      // เช็ค password ก่อน
      const checkLogin = await UserLoginView.post({
        apiToken: controller.apiToken,
        data: {
          username: controller.data?.userProfile?.username,
          password: params?.repassword,
          userId: state.userId,
        },
      });

      if (checkLogin[1] && checkLogin[2]?.response?.status === 400) {
        controller.setState({
          buttonLoadCheck: {
            ...state.buttonLoadCheck,
            [params.buttonLoadKey]: "ERROR",
          },
          loadingStatus: { ...state.loadingStatus, [params.card]: false },
          errorMessage: {
            ...state.errorMessage,
            [params.card]: { error: { บันทึกไม่สำเร็จ: "กรุณาระบุรหัสผ่านให้ถูกต้อง" } },
          },
        });
        return;
      }
    }

    /// PRINT and Change Status
    let [response, error] = await UpdateDrugOrderAction(controller, paramAction);

    if (error) {
      if (params.card) {
        const key = Array.isArray(error.last_edited) ? `${params.card}_LAST_EDITED` : params.card;

        const coUserError = error?.co_user;

        if (coUserError) {
          error = "<center>กรุณาระบุรหัสพนักงานของ<br/>เจ้าหน้าที่ผู้มารับ</center>";
        }

        controller.setState({
          errorMessage: { ...state.errorMessage, [key]: { error: error } },
          drugDelivery: { ...state.drugDelivery, error: !!coUserError },
        });
      }

      controller.setState({
        buttonLoadCheck: {
          ...state.buttonLoadCheck,
          [params.buttonLoadKey]: "ERROR",
        },
        loadingStatus: { ...state.loadingStatus, [params.card]: false },
      });

      if (["PRINT", "REPRINT"].includes(params.action)) {
        // กรณีจะ print จาก เภสัช บางที error ด้วยเหตุ ยังไม่ได้ รับทราบ
        return;
      }
    } else {
      // Success DrugOrderActionView

      if (params.action === "DELIVER") {
        if (state.drugDelivery?.isDrugDelivery) {
          await saveShippingOrder(controller, params);
        }

        if (CONFIG.ENABLE_INTRA_TELEPHAR) {
          const telepharType = state.drugDelivery?.telepharType || "NONE";

          await controller.handleEvent({
            message: "DrugOrderUpdateCallTelephar" as any,
            params: {
              id: state.selectedDrugOrderWorking.pk,
              data: { telephar_type: telepharType },
            },
          });
        }
      }

      state = controller.getState();

      controller.setState({
        drugDelivery: {
          ...state?.drugDelivery,
          personName: "",
          isOthers: false,
          error: null,
        },
        DrugOrderActionSequence: {
          ...state.DrugOrderActionSequence,
          isReprintSuccess: true,
        },
      });

      if (params.getRefill || response?.status === "DIVIDED") {
        // check REFILL_PLANNED Queue
        PerformDrugOrderAction(controller, { action: "getRefill" });
      }
    }

    // Refresh to get new allowed actions
    console.log("DrugOrderDetailForAction: ");

    refreshDrugOrderQueue(controller, params);
  }
};

/* ------------------------------------------------------ */

/*                         ACTION                         */

/* ------------------------------------------------------ */

const deliveryAction: Handler = async (controller, params) => {
  let paramAction = {};
  let state = controller.getState();

  const delivery = state.drugDelivery || {};

  if (delivery.isDrugDelivery) {
    let textAlert = [
      !delivery.shipper && "กรุณาระบุค่าใน Shipper",
      !delivery.trackingNo && "กรุณาระบุค่าใน TrackingNo",
      !delivery.address && "กรุณาระบุค่าใน สถานที่จัดส่ง",
    ];

    textAlert = textAlert.filter(Boolean);

    if (!textAlert.length) {
      paramAction = { action: params.action };
    } else {
      if (params.card) {
        controller.setState({
          errorMessage: {
            ...state.errorMessage,
            [params.card]: { error: textAlert },
          },
        });
      }

      controller.setState({
        buttonLoadCheck: {
          ...state.buttonLoadCheck,
          [params.buttonLoadKey]: "ERROR",
        },
      });

      return null;
    }
  } else {
    if (delivery.isOthers && !delivery.personName) {
      controller.setState({
        errorMessage: {
          ...state.errorMessage,
          [params.card]: { error: ["กรุณาระบุชื่อผู้รับยา"] },
        },
      });

      return null;
    }

    paramAction = {
      action: params.action,
      co_user: delivery.isTransport ? delivery.token : undefined,
      note: delivery.isOthers ? delivery.personName : undefined,
      is_transporter: !!delivery.isTransport,
      items: [],
      language: "TH",
    };
  }

  return paramAction;
};

const wuncAction: Handler = async (controller, params) => {
  let paramAction = {};
  let state = controller.getState();

  if (!params?.confirm) {
    controller.setState({
      modConfirmAction: {
        ...state.modConfirmAction,
        open: true,
        action: params.action,
      },
    });

    return null;
  } else {
    paramAction = {
      action: params.action,
      note: state.modConfirmAction.text,
    };

    controller.setState({
      modConfirmAction: {
        ...state.modConfirmAction,
        open: false,
        value: null,
        action: "",
        text: "",
      },
    });
  }
  return paramAction;
};

const printQR: Handler = async (controller, params) => {
  let state = controller.getState();

  return controller.setState(
    {
      drugDelivery: {
        ...state?.drugDelivery,
        shippingOrder: state.drugDelivery?.shippingOrder,
      },
    },
    () => HandleQRDrugDeliveryReport(controller, params)
  );
};

const reprint: Handler = async (controller, params) => {
  let state = controller.getState();
  const seq = state?.DrugOrderActionSequence || {};

  controller.setState({
    loadingStatus: { ...state.loadingStatus, [params.card]: true },
  });

  let paramAction = {
    action: params.action,
    language: seq.language || "TH",
    note: seq.noteReprint || "เนื่องจาก ",
    print_drug_label: seq.printLabel || false,
    print_patient: seq.printPatient || false,
    print_pharma: seq.printPharma || false,
  };

  return paramAction;
};
const reject: Handler = async (controller, params) => {
  let paramAction = null;

  if (params.confirm) {
    const items = params.items;
    const drugOrder = items.map((item: any) => ({
      ...item,
      note_rejected: item.note_reject,
    }));

    paramAction = { action: params.action, items: drugOrder };
  } else {
    controller.setState({
      modNoteReject: { open: true, note: "" },
    });
  }

  return paramAction;
};

export const getDrugRefillPlaned: Handler = async (controller, params) => {
  let state = controller.getState();
  let encounterType = state.selectedEncounter?.type;

  const encounterTypeMapping: { [key: string]: number } = {
    OPD: 1,
    IPD: 7,
  };

  const [drugRes] = await DrugOrderQueueList.list({
    params: {
      is_opd: true,
      is_ipd: true,
      order_div: "",
      type: encounterTypeMapping[encounterType] || "",
      patient: state.selectedEncounter?.patient_id,
      is_shipping: true,
      status: "REFILL_PLANNED",
      shipping_company: "",
      order_perform_div: controller.data.division,
    },
    apiToken: controller.apiToken,
    extra: {
      division: controller.data.division,
      device: (controller.data as any).device,
    },
  });

  if (drugRes) {
    let drugRefillPlaned = drugRes.items.filter(
      (item: any) => item.hn === state.selectedEncounter?.hn
    );

    controller.handleEvent({
      message: "SelectDrugOrderWorking",
      params: {
        drugOrder: {
          id: drugRefillPlaned[0]?.id,
          encounter: state?.selectedEncounter?.pk,
        },
      },
    });

    controller.setState({
      DrugOrderPendingList: drugRes.items,
    });
  }
};

export const getRefill: Handler = async (controller, params) => {
  let state = controller.getState();

  const drugOrderWorking = await DrugOrderDetailForRefillPlanning.retrieve({
    pk: state.selectedDrugOrderWorking.pk,
    apiToken: controller.apiToken,
    extra: {
      division: controller.data.division,
      device: (controller.data as any).device,
    },
  });

  if (drugOrderWorking[0]) {
    controller.setState({
      DrugRefillSequence: drugOrderWorking[0],
    });
  }

  // check REFILL_PLANNED Queue
  PerformDrugOrderAction(controller, { action: "getDrugRefillPlaned" });
};

const getDiscount: Handler = async (controller, params) => {
  const items = await GetAutoDiscountPolicyItem(controller, params);

  const state = controller.getState();

  controller.setState({
    DrugOrderActionSequence: {
      ...state.DrugOrderActionSequence,
      modDiscount: { action: "EDIT", items },
    },
  });

  return null;
};

const editDiscount: Handler = async (controller, params) => {
  let state = controller.getState();

  if (typeof params.items !== "undefined") {
    const [_, error] = await ApplyAutoDiscountPolicyItemView.post({
      apiToken: controller.apiToken,
      data: {
        encounter: params.encounterId,
        drug_order: params.orderId,
        auto_discount_items: params.items,
      },
    });

    if (error) {
      controller.setState({
        errorMessage: { ...state.errorMessage, [params.card]: { error } },
      });

      return null;
    }
  }

  state = controller.getState();

  const action = state.DrugOrderActionSequence?.modDiscount?.action;

  controller.setState({
    DrugOrderActionSequence: {
      ...state.DrugOrderActionSequence,
      modDiscount: null,
    },
  });

  if (action === "APPLY") {
    return { action: "PRINT" };
  }

  return null;
};

export const saveRefill: Handler = async (controller, params) => {
  let state = controller.getState();

  controller.setState({
    buttonLoadCheck: {
      ...state.buttonLoadCheck,
      [params.buttonLoadKey]: "LOADING",
    },
  });

  const drugOrderWorking = await DrugOrderDetailForRefillPlanning.update({
    pk: state.selectedDrugOrderWorking.pk,
    data: state.DrugRefillSequence,
    apiToken: controller.apiToken,
    extra: {
      division: controller.data.division,
      device: (controller.data as any).device,
    },
  });

  if (drugOrderWorking[0]) {
    controller.setState({
      buttonLoadCheck: {
        ...state.buttonLoadCheck,
        [params.buttonLoadKey]: "SUCCESS",
      },
      successMessage: {
        ...state.successMessage,
        [params.card]: {
          ...drugOrderWorking[0],
          edit: params.buttonLoadKey !== "CardDrugOrderRefill_EDIT",
        },
      },
    });

    PerformDrugOrderAction(controller, { action: "getRefill" });
  } else {
    controller.setState({
      buttonLoadCheck: {
        ...state.buttonLoadCheck,
        [params.buttonLoadKey]: "ERROR",
      },
      errorMessage: {
        ...state.errorMessage,
        [`${params.card}_saveRefill`]: { error: drugOrderWorking[1] },
      },
      loadingStatus: { ...state.loadingStatus, [params.card]: false },
    });
  }
};

export const addOrderList: Handler = async (controller, params) => {
  let state = controller.getState();

  const seq = state?.DrugRefillSequence || {};
  let dataOrderList: any[] = [];
  let dataRefill: any[] = [];

  if (seq.appointments !== undefined) {
    seq.appointments.map((item: any) => {
      dataOrderList.push(item);
    });
  }

  seq.order_items.map((item: any) => {
    dataRefill.push({ order_item: item.pk, quantity: 0 });
  });

  dataOrderList.push({
    start_date: "",
    end_date: "",
    is_editable: true,
    refreshCount: 1,
    plannings: dataRefill,
  });

  controller.setState({
    DrugRefillSequence: { ...seq, appointments: dataOrderList },
  });
};

export const calculatorSplit: Handler = async (controller, params) => {
  let state = controller.getState();
  let dataOrderList: any[] = [];
  let newSplitDate = moment(beToAd(state?.DrugRefillSequence?.splitDate)).format("DD/MM/YYYY");

  let splitCount = state?.DrugRefillSequence?.splitCount || 1;
  let splitDay = state?.DrugRefillSequence?.splitDay || 1;
  let splitDate = moment(newSplitDate, "DD/MM/YYYY") || moment();

  let newOrderItems: any = [];
  (state.DrugRefillSequence?.order_items || []).forEach((item: any) => {
    newOrderItems.push({ ...item, planned_quantity: 0 });
  });

  for (let i = 0; i < splitCount; i++) {
    let plan_date = formatDate(splitDate);
    let plannings: any = [];

    newOrderItems.map((item: any) => {
      let expected_quantity = parseInt((item.quantity / splitCount) * (i + 1));
      let planning = {
        order_item: item.pk,
        quantity: expected_quantity - item.planned_quantity,
      };

      item.planned_quantity += planning.quantity;

      plannings.push(planning);
    });

    dataOrderList.push({
      start_date: plan_date,
      end_date: plan_date,
      is_editable: true,
      refreshCount: 1,
      plannings: plannings,
    });

    splitDate.add(splitDay, "days");
  }

  newOrderItems = [];

  (state.DrugRefillSequence?.order_items || []).forEach((item: any) => {
    newOrderItems.push({ ...item, planned_quantity: item.quantity });
  });

  controller.setState({
    DrugRefillSequence: {
      ...state?.DrugRefillSequence,
      appointments: dataOrderList,
      order_items: newOrderItems,
    },
  });
};

export const createRefillOrder: Handler = async (controller, params) => {
  let state = controller.getState();
  const pk =
    state.DrugRefillSequence?.appointments[state.DrugRefillSequence?.selectedRefill]?.refill || 0;

  controller.setState({
    buttonLoadCheck: {
      ...state.buttonLoadCheck,
      CardDrugOrderRefill_REFILLPRINT: "LOADING",
    },
  });

  const drugRefillOrderCreate = await DrugRefillOrderCreate.create({
    data: {
      action: "PRINT",
      appointment:
        state.DrugRefillSequence?.appointments[state.DrugRefillSequence?.selectedRefill]?.pk,
      pk: pk,
    },
    apiToken: controller.apiToken,
    extra: {
      division: controller.data.division,
      device: (controller.data as any).device,
    },
  });

  if (drugRefillOrderCreate[1]) {
    controller.setState({
      buttonLoadCheck: {
        ...state.buttonLoadCheck,
        CardDrugOrderRefill_REFILLPRINT: "ERROR",
      },
    });
  } else {
    controller.setState({
      buttonLoadCheck: {
        ...state.buttonLoadCheck,
        CardDrugOrderRefill_REFILLPRINT: "SUCCESS",
      },
      successMessage: {
        ...state.successMessage,
        [params.card]: {
          edit: params.buttonLoadKey !== "CardDrugOrderRefill_EDIT",
        },
      },
    });

    controller.handleEvent({
      message: "SelectDrugOrderWorking",
      params: {
        drugOrder: {
          id: drugRefillOrderCreate[0].pk,
          encounter: state?.selectedEncounter?.pk,
        },
      },
    });
  }
};

export const changeOrderItemData: Handler = async (controller, params) => {
  const state = controller.getState();

  const updatedItems = [...(state.selectedDrugOrderWorking?.items || [])].map((item: any) => {
    if (Array.isArray(params.id) ? params.id.includes(item.id) : item.id === params.id) {
      return { ...item, [params.name]: params.value };
    }

    return { ...item };
  });

  controller.setState({
    selectedDrugOrderWorking: {
      ...state.selectedDrugOrderWorking,
      items: updatedItems,
    },
  });
};

export const printAction: Handler = async (controller, params) => {
  const state = controller.getState();

  const drug = state.selectedDrugOrderWorking || {};
  const encounter = state.selectedEncounter || {};

  const printIds = [...(drug.items || [])]
    .filter((item: any) => item.print)
    .map((item: any) => item.id);

  const items = await GetAutoDiscountPolicyItem(controller, {
    encounterId: encounter.id,
    orderId: drug.pk,
  });

  if (items.length) {
    controller.setState({
      DrugOrderActionSequence: {
        ...state.DrugOrderActionSequence,
        modDiscount: { action: "APPLY", items },
      },
    });

    return null;
  }

  return {
    action: params.action,
    print_ids: printIds,
    last_edited: params.last_edited,
    language: params.language,
  };
};

const saveShippingOrder: Handler = async (controller, params) => {
  const state = controller.getState();

  const drug = state?.selectedDrugOrderWorking;
  const orderdrug = drug?.items?.map((item: any) => ({
    id: item.id,
    item_type: 1,
  }));

  const [shippingOrderRes, shippingOrderErr] = await ShippingOrderPerformedList.create({
    data: {
      encounter: state.selectedEncounter?.pk,
      shipping_employee: state.drugDelivery?.shipper,
      tracking_number: state.drugDelivery?.trackingNo,
      transport_address: state.drugDelivery?.address,
      order_items: orderdrug,
    },
    extra: {
      division: controller.data.division,
      device: (controller.data as any).device,
    },
    apiToken: controller.apiToken,
  });

  if (shippingOrderErr) {
    if (params.card) {
      controller.setState({
        errorMessage: {
          ...state.errorMessage,
          [params.card]: { error: shippingOrderErr },
        },
      });
    }

    controller.setState({
      buttonLoadCheck: {
        ...state.buttonLoadCheck,
        [params.buttonLoadKey]: "ERROR",
      },
      drugDelivery: {
        ...state?.drugDelivery,
        shipper: null,
        trackingNo: null,
        address: null,
      },
    });
  } else {
    const transportTypeMapping: { [key: number]: string } = {
      1: "present",
      2: "home",
    };

    const transportType = transportTypeMapping[shippingOrderRes?.transport_to_type] || "home";

    controller.setState(
      {
        drugDelivery: {
          ...state?.drugDelivery,
          shipper: shippingOrderRes?.shipping_employee,
          address: transportType,
          trackingNo: shippingOrderRes?.tracking_number,
          isDrugDelivery: true,
          showDrugDelivery: true,
          shippingOrder: shippingOrderRes,
        },
      },
      () => HandleQRDrugDeliveryReport(controller, params)
    );
  }
};

const refreshDrugOrderQueue: Handler = async (controller, params) => {
  const state = controller.getState();

  const drugOrderWorking = await DrugOrderDetailForAction.retrieve({
    pk: state.selectedDrugOrderWorking.pk,
    apiToken: controller.apiToken,
  });

  if (drugOrderWorking[1]) {
    if (params.card) {
      return controller.setState({
        errorMessage: {
          ...state.errorMessage,
          [params.card]: { error: drugOrderWorking[1] },
        },
      });
    }

    return console.log("Error: ", drugOrderWorking[1].toString());
  }

  // ไม่แน่ใจว่า เฉพาะ Action
  // params?.action === "WAIVE" ||
  // params?.action === "UNVERIFY" ||
  // params?.action === "UNCHECK" ||
  // params?.action === "UNDELIVER" ||
  // params?.action === "UNDIVIDE" ||
  // params?.action === "CANCEL_PLAN"
  // REJECT หรือ เปล่า

  const resultSelectedDrugOrderWorking: any = {
    ...drugOrderWorking[0],
    items: drugOrderWorking[0].items.map((drug: any, index: number) => ({
      ...drug,
      print: state.selectedDrugOrderWorking?.items[index]?.print || false,
    })),
  };

  if (
    ["HOME_OPD", "HOME_IPD"].includes(resultSelectedDrugOrderWorking?.type) &&
    (params.action === "PRINT" ||
      (params.action === "REPRINT" && state?.DrugOrderActionSequence?.printMedReport))
  ) {
    printFormHomeMedPDF(controller, resultSelectedDrugOrderWorking);
  }

  controller.setState(
    {
      selectedDrugOrderWorking: { ...resultSelectedDrugOrderWorking },
      loadingStatus: { ...state.loadingStatus, [params.card]: false },
      buttonLoadCheck: {
        ...state.buttonLoadCheck,
        [params.buttonLoadKey]: "SUCCESS",
      },
    },
    async () =>
      await controller.handleEvent({
        message: "GetDrugOrderQueue" as any,
        params: {
          patient: state.selectedEncounter?.patient_id || "",
        },
      })
  );
};

// -----

export const HandleQRDrugDeliveryReport: Handler = async (controller, params) => {
  const state = controller.getState();
  const patient = state?.selectedPatient;
  const orderDrug = state?.selectedDrugOrderWorking;

  // ----- createPDFQRcode
  const data = Object.assign({
    // --- Patien and OrderDrug
    encounter: state?.selectedEncounter?.pk,
    order: orderDrug?.code,
    patient_name: patient?.full_name_th,
    division: "หน่วยเภสัชกรรม",
    shipping: state?.drugDelivery?.shippingOrder?.id,
  });

  // await createPDFQrCodeDrugDelivery(data);
  // Call FormQrCodeDrugDelivery
  const docDef: any = await FormQrCodeDrugDelivery(data);
  const pdfMake = await getPdfMake(true);
  const pdf = pdfMake.createPdf(docDef);
  pdf.open();
};

const convertImageToPNG = (image: any) => {
  // create an off-screen canvas
  const canvas = document.createElement("canvas");
  const ctx = canvas.getContext("2d");

  // set its dimension to target size
  canvas.width = image.width;
  canvas.height = image.height;

  // draw source image into the off-screen canvas:
  ctx?.drawImage(image, 0, 0, image.width, image.height);

  // encode image to data-uri with base64 version of the image
  try {
    return canvas.toDataURL("image/png");
  } catch (err) {
    console.log("Error: convertImageToPNG", err, image);
    return "";
  }
};

/* ------------------------------------------------------ */

/*                           API                          */

/* ------------------------------------------------------ */
const UpdateDrugOrderAction: Handler = async (controller, params) => {
  const state = controller.getState();

  return await DrugOrderActionView.update({
    pk: state.selectedDrugOrderWorking.pk,
    data: {
      ...params,
    } as any,
    extra: {
      division: controller.data.division,
      device: (controller.data as any).device,
    },
    apiToken: controller.apiToken,
  });
};

const GetAutoDiscountPolicyItem: Handler = async (controller, params) => {
  const [result] = await MatchedAutoDiscountPolicyItemForEditList.get({
    apiToken: controller.apiToken,
    params: { encounter: params.encounterId, drug_order: params.orderId },
  });

  return result?.items || [];
};
