import WasmController from "react-lib/frameworks/WasmController";
import * as TriageI from "./sequence/Triage";
import * as AssessmentI from "./sequence/Assessment";
import * as ReceiveOrderI from "./sequence/ReceiveOrder";
import * as NursingDiagnosisI from "./sequence/NursingDiagnosis";
import * as ReturnOrderI from "./sequence/ReturnOrder";
import * as DischargeNurseI from "./sequence/DischargeSummary";
// APIS
import NurseNoteItemList from "issara-sdk/apis/NurseNoteItemList_apps_PTM";
import NurseNoteItemDetail from "issara-sdk/apis/NurseNoteItemDetail_apps_PTMM";
import NurseNoteTemplateItemList from "issara-sdk/apis/NurseNoteTemplateItemList_apps_PTM";
import NurseNoteLatest from "issara-sdk/apis/NurseNoteLatest_apps_PTM";
import EncounterDetail from "issara-sdk/apis/EncounterDetail_core";
import PatientDetailView from "issara-sdk/apis/PatientDetailView_apps_REG";
import TriageLatest from "issara-sdk/apis/TriageLatest_core";
import FormDataLatest from "issara-sdk/apis/FormDataLatest_apps_PTM";
import AdverseReactionList from "issara-sdk/apis/AdverseReactionList_apps_ADR";
import VitalSignValueList from "issara-sdk/apis/VitalSignValueList_apps_PTM";
import DoctorPendingTaskList from "issara-sdk/apis/DoctorPendingTaskList_apps_DPO";
import DoctorPendingTaskOrder from "issara-sdk/apis/DoctorPendingTaskOrderAPIView_apps_DPOM";
import DoctorOrderDetail from "issara-sdk/apis/DoctorOrderDetail_apps_PTM";
import UserTokenizeView from "issara-sdk/apis/UserTokenizeView_users";
import MeasurementList from "issara-sdk/apis/MeasurementList_apps_PTM";
import DoctorOrderCancel from "issara-sdk/apis/DoctorOrderCancel_apps_DPO";

import { formatUTCtoBEString, formatDate } from "react-lib/utils/dateUtils";
import { FormNurseNote } from "react-lib/appcon/common/FormNurseNote";
import moment from "moment";
import DoctorOrderForPTMList from "issara-sdk/apis/DoctorOrderForPTMList_apps_PTM";
import getPdfMake from "react-lib/appcon/common/pdfMake";

import RequestToken from "issara-sdk/apis/RequestTokenAPI_users";
import CONFIG from "config/config";
import {
  getDeviceIdAndUserSubScription,
  getBrowserName,
  getOSName,
} from "react-lib/apps/HISV3/common/sequence/GetUserSubScription";
import UserLoginView from "issara-sdk/apis/UserLoginView_core";
export type State = {
  goToMenu?: string;
  doctorNoteOrderEditId?: number | null;
  selectVitalSignLoading?: any;
  getEncounterVitalSignListErr?: any;
  encounterVitalSignList?: any[];
  multidisciplinaryLoading?: boolean;
  multidisciplinaryNumberOfPage?: number;
  multidisciplinaryData?: any;
  loadingSummary?: boolean;
  // nurse note
  selectedNurseNote?: {
    focus?: any;
    diagnosis?: any;
    plan?: any;
    goal?: any;
    division?: number;
    nurse_note?: number | null;
  };
  openModNurseNoteForm?: boolean;
  nurseNoteLoading?: boolean;
  nurseNoteLatest?: number | null;
  nurseNoteTemplateList?: any[];
  nurseNoteFocusOptions?: any[];
  nurseNoteFilter?: {
    startDate?: any;
    endDate?: any;
    nurseNoteList?: any[];
  };

  // lab verbal order
  promptMessage?: any;
  duplicateLabDetected?: boolean;

  // verbal order history
  verbalOrderHistoryFilter?: {
    optionsSeach?: any[];
    selectedSearch?: string | null;
    hn?: any;
    en?: any;
    editor?: any;
    startDate?: string | null;
    endDate?: string | null;
    checkedTypes: string[];
  };
  verbalOrderHistoryList?: any[];
  // PasswordRecheck?: {
  //   // verbalOrderLogin
  //   loading?: boolean;
  //   error?: any;
  //   name?: any;
  //   pass?: boolean;
  // } | null;

  // OPD Order Summary
  orderSummaryOPDList?: any[];
  orderSummaryOPDFilter?: {
    isOrder?: boolean;
    order?: any;
    isLab?: boolean;
    isTreatment?: boolean;
    isImaging?: boolean;
    isOthers?: boolean;
    isOutPerformDiv?: boolean;
  };
  orderSummaryOPDOptions?: any[];
  nurseOPDOrderPerformed?: {
    modOpen?: boolean;
    selectedOrder?: any;
    performedTime?: any;
    performedDate?: any;
    nurseName?: any;
    nurseToken?: any;
    nurseError?: any;
    nurseLoading?: any;
  };
  selectedPatient?: any;
  masterOptions?: any;
} & TriageI.State &
  AssessmentI.State &
  ReceiveOrderI.State &
  ReturnOrderI.State &
  DischargeNurseI.State;

export const StateInitial: State = {
  // nurse note
  selectedNurseNote: {
    focus: "",
    diagnosis: "",
    plan: "",
    goal: "",
  },
  nurseNoteTemplateList: [],
  nurseNoteLoading: false,
  nurseNoteFocusOptions: [],
  openModNurseNoteForm: false,
  nurseNoteFilter: {
    startDate: "",
    endDate: "",
    nurseNoteList: [],
  },
  loadingSummary: false,
  // lab verbal order
  promptMessage: "",
  duplicateLabDetected: false,

  // verbal order history
  verbalOrderHistoryFilter: {
    optionsSeach: [
      { key: 1, text: "HN", value: "HN" },
      { key: 2, text: "EN", value: "EN" },
    ],
    selectedSearch: "HN",
    hn: null,
    en: null,
    editor: null,
    startDate: formatDate(moment()),
    endDate: formatDate(moment()),
    checkedTypes: [],
  },

  // OPD Summary Order
  verbalOrderHistoryList: [],
  // PasswordRecheck: {},
  orderSummaryOPDList: [],
  orderSummaryOPDFilter: {
    isOrder: false,
    order: null,
    isLab: true,
    isTreatment: true,
    isImaging: true,
    isOthers: true,
    isOutPerformDiv: true,
  },
  orderSummaryOPDOptions: [
    { key: 1, text: "รอรับคําสั่ง", value: 1 },
    { key: 2, text: "รับทราบแล้ว", value: 2 },
    { key: 3, text: "ทําแล้ว", value: 3 },
  ],
  nurseOPDOrderPerformed: {
    modOpen: false,
    selectedOrder: null,
    performedTime: "",
    performedDate: formatDate(moment()),
    nurseName: null,
    nurseToken: null,
    nurseError: null,
    nurseLoading: null,
  },
  // ...TriageI.StateInitial,
  // ...AssessmentI.StateInitial,
  // ...ReceiveOrderI.StateInitial,
  // ...ReturnOrderI.StateInitial,
  // ...DischargeNurseI.StateInitial,
};

export type Event =
  | { message: "DidMount"; params: any }
  | { message: "HandleNurseNote"; params: any }
  | { message: "handleNurseNoteReport"; params: any }
  | { message: "HandleGetVerbalHistory"; params: any }
  | { message: "HandleVerbalOrderAction"; params: any }
  | { message: "SaveVerbalOrder"; params: any }
  | { message: "CheckPasswordVerbalOrder"; params: { password: string } }
  | { message: "handleGetEncounterVitalSignList"; params: any }
  | { message: "HandleOPDOrderSummary"; params: any }
  | { message: "HandleEditOPDOrderSummary"; params: any }
  | { message: "HandleDeleteOPDOrderSummary"; params: any }
  | TriageI.Event
  | AssessmentI.Event
  | ReceiveOrderI.Event
  | ReturnOrderI.Event
  | DischargeNurseI.Event
  | NursingDiagnosisI.Event;

export type Data = {
  division?: number;
  login?: {
    deviceId: string;
    deviceName: string;
    deviceToken: string;
    username: string;
  };
};

export const DataInitial = {};

type Handler = (controller: WasmController<State, Event, Data>, params?: any) => any;

export const DidMount: Handler = async (controller, { cloi }) => {};

export const HandleNurseNote: Handler = async (controller, params) => {
  const state = controller.getState();
  let nurseNoteLatestSearch = null;
  if (params?.action === "search") {
    if (params?.encounterId) {
      const [nurseNoteL, nurseNoteLError] = await NurseNoteLatest.retrieve({
        params: { encounter: params?.encounterId },
        apiToken: controller.apiToken,
        extra: {
          division: controller.data.division,
        },
      });

      if (nurseNoteL?.id && !nurseNoteLError) {
        nurseNoteLatestSearch = nurseNoteL?.id;
        console.log("HandleNurseNote  nurseNoteLatest (API): ", nurseNoteLatestSearch); // unused
      }

      controller.setState({
        nurseNoteLoading: true,
      });

      const [noteList, noteTemplate] = await Promise.all([
        NurseNoteItemList.list({
          apiToken: controller.apiToken,
          params: {
            encounter: params.encounterId,
            from_date: state.nurseNoteFilter?.startDate || "",
            to_date: state.nurseNoteFilter?.endDate || "",
          },
        }),
        NurseNoteTemplateItemList.list({
          apiToken: controller.apiToken,
          params: {
            division: controller.data.division,
          },
        }),
      ]);

      if (noteList[1]) {
        if (params?.card) {
          controller.setState({
            errorMessage: {
              ...state.errorMessage,
              [params.card]: { error: noteList[1] },
            },
            nurseNoteLoading: false,
          });
        }
        console.log("Get nurse note list by encounter failure by: ", noteList[1]);
        return;
      }
      let nnl = noteList[0]?.items || [];

      const nurseNoteLatest = nnl?.length > 0 ? nnl.slice(-1)[0]?.nurse_note : null; // ไม่ดึง nurse_note_latest api แล้วแต่การใช้งานว่า ใช้ในมุมไหน
      console.log("HandleNurseNote nurseNoteLatest (slice): ", nurseNoteLatest);

      controller.setState({
        nurseNoteFilter: {
          ...state.nurseNoteFilter,
          nurseNoteList: nnl,
        },
        nurseNoteLoading: false,
        nurseNoteLatest: nurseNoteLatest,
        nurseNoteTemplateList: noteTemplate[1] ? [] : noteTemplate[0]?.items,
        nurseNoteFocusOptions: noteTemplate[0]?.items?.map((item: any, index: number) => ({
          key: index + 1,
          value: item.focus,
          text: item.focus,
        })),
      });
    }
    return;
  } else if (params?.action === "add_option") {
    let focusOptions = [...state.nurseNoteFocusOptions];
    focusOptions.push({
      key: focusOptions.length,
      text: params.data,
      value: params.data,
    });
    return controller.setState({
      nurseNoteFocusOptions: [...focusOptions],
    });
  } else if (params?.action === "change_option") {
    let selectedChoice: any = {};
    let findOption = state.nurseNoteTemplateList?.find((item: any) => item.focus === params.data);
    if (findOption) {
      selectedChoice["focus"] = findOption.focus;
      selectedChoice["diagnosis"] = findOption.diagnosis;
      selectedChoice["plan"] = findOption.plan;
      selectedChoice["goal"] = findOption.goal;
    } else {
      selectedChoice["focus"] = params.data;
      selectedChoice["diagnosis"] = "";
      selectedChoice["plan"] = "";
      selectedChoice["goal"] = "";
    }

    return controller.setState({
      selectedNurseNote: {
        ...state.selectedNurseNote,
        ...selectedChoice,
      },
    });
  } else if (params?.action === "new") {
    return controller.setState({
      openModNurseNoteForm: true,
      selectedNurseNote: {
        focus: "",
        diagnosis: "",
        plan: "",
        goal: "",
      },
    });
  } else if (params?.action === "edit") {
    // selectedNurseNote
    return controller.setState(
      {
        openModNurseNoteForm: true,
        selectedNurseNote: params.selectedNurseNote,
      },
      () => {
        let findOption = state.nurseNoteFocusOptions.find(
          (item) => item.value === params.selectedNurseNote.focus
        );
        if (!findOption) {
          HandleNurseNote(controller, {
            data: params.selectedNurseNote.focus,
            card: params?.card,
          });
        }
      }
    );
  } else if (params?.action === "remove") {
    const removeNote = await NurseNoteItemDetail.delete({
      pk: state.selectedNurseNote.id,
      apiToken: controller.apiToken,
      data: {
        username: params.username,
        password: params.password,
      },
      extra: {
        device: (controller.data as any).device,
        division: controller.data.division,
      },
    });
    if (removeNote[1]) {
      if (params?.card) {
        controller.setState({
          errorMessage: {
            ...state.errorMessage,
            [params.card]: { error: removeNote[1] },
          },
        });
      }
      console.log("remove error:", removeNote[1]);
      return;
    }

    controller.setState(
      {
        successMessage: { ...state.successMessage, [params.card]: "ลบสำเร็จ" },
        openModNurseNoteForm: false,
      },
      async () => {
        await HandleNurseNote(controller, {
          action: "search",
          encounterId: params.encounterId,
        });
      }
    );
    return;
  } else if (params?.action === "save") {
    let result: any;
    if (state.selectedNurseNote?.id) {
      result = await NurseNoteItemDetail.update({
        pk: state.selectedNurseNote.id,
        apiToken: controller.apiToken,
        data: {
          ...state.nurseNoteFilter.nurseNoteList.find(
            (item) => item.id === state.selectedNurseNote.id
          ),
          encounter: params.encounterId,
          focus: state.selectedNurseNote.focus || "",
          diagnosis: state.selectedNurseNote.diagnosis || "",
          plan: state.selectedNurseNote.plan || "",
          goal: state.selectedNurseNote.goal || "",
          nurse_note_id: state.nurseNoteLatest || null,
          username: params.username,
          password: params.password,
          date: params.date,
        },
        extra: {
          device: (controller.data as any).device,
          division: controller.data.division,
        },
      });
    } else {
      result = await NurseNoteItemList.create({
        apiToken: controller.apiToken,
        data: {
          ...state.selectedNurseNote,
          date: params.date,
          encounter: params.encounterId,
          nurse_note_id: state.nurseNoteLatest || null,
          username: params.username,
          password: params.password,
        },
        extra: {
          device: (controller.data as any).device,
          division: controller.data.division,
        },
      });
    }

    if (result[1]) {
      if (params?.card) {
        controller.setState({
          errorMessage: {
            ...state.errorMessage,
            [params.card]: { error: result[1] },
          },
        });
      }
      console.log("save error:", result[1]);
      return;
    }

    return controller.setState(
      {
        successMessage: {
          ...state.successMessage,
          [params.card]: "บันทึกสำเร็จ",
        },
        openModNurseNoteForm: false,
      },
      async () => {
        await HandleNurseNote(controller, {
          action: "search",
          encounterId: params.encounterId,
        });
      }
    );
  } else if (params?.action === "close") {
    controller.setState({
      openModNurseNoteForm: false,
      selectedNurseNote: {
        focus: "",
        diagnosis: "",
        plan: "",
        goal: "",
      },
    });
  } else if (params?.action === "clearLatest") {
    controller.setState({
      nurseNoteLatest: null,
    });
  }
};

export const SaveVerbalOrder: Handler = async (controller, params) => {
  const state = controller.getState();

  const orderId = params.id || null;
  const data = {
    encounter: params.encounterId,
    id: orderId,
    note: params.note,
    order: params.order,
    order_by: params.order_by,
    order_specific_type: params.order_specific_type,
    order_type: params.order_type,
  };

  if (params?.action === "REQUEST") {

    controller.setState({
      successMessage: { ...state.successMessage, CardVerbalOrder: null },
      errorMessage: { ...state.errorMessage, CardVerbalOrder: null },
    });

    const checkLogin = await UserLoginView.post({
      apiToken: controller.apiToken,
      data: {
        username: controller.data?.userProfile?.username,
        password: params?.repassword,
        userId: state.userId
      },
    });

    if (checkLogin[1] && checkLogin[2]?.response?.status === 400) {
      controller.setState({
        errorMessage: { ...state.errorMessage, CardVerbalOrder: {"บันทึกไม่สำเร็จ": "ยืนยันรหัสผู้รับคำสั่ง ไม่ถูกต้อง"} },
      });
      return
    }

    const api = orderId ? DoctorPendingTaskOrder.put : DoctorPendingTaskOrder.post;

    const [response, error] = await api({
      pk: orderId,
      data: data,
      apiToken: controller.apiToken,
      extra: {
        division: controller.data.division,
        device: (controller.data as any).device,
      },
    });

    if (response) {
      controller.setState({
        errorMessage: { ...state.errorMessage, CardVerbalOrder: null },
        successMessage: {
          ...state.successMessage,
          CardVerbalOrder: "บันทึกสำเร็จ",
          duplicateLabDetected: false,
          promptMessage: "",
        },
      });
    }

    if (error) {
      if (error.code && error.code[0] === "DUPLICATE_CENTRAL_LAB_ITEM_NAME") {
        controller.setState({
          duplicateLabDetected: true,
          promptMessage: error.message,
        });
      } else {
        controller.setState({
          errorMessage: { ...state.errorMessage, CardVerbalOrder: error },
          duplicateLabDetected: false,
          promptMessage: "",
        });
      }
    }
  } else if (params.action === "clearMessage") {
    controller.setState({
      successMessage: { ...state.successMessage, CardVerbalOrder: null },
      errorMessage: { ...state.errorMessage, CardVerbalOrder: null },
    });
  }

  return;
};

// export const CheckPasswordVerbalOrder: Handler = async (controller, params) => {
//   let state = controller.getState()
//   if (params.action === "CheckPasswordKeying") {

//     // const [deviceId, userSubscription] = await getDeviceIdAndUserSubScription();
//     // let deviceName = `${getBrowserName()} on ${getOSName()}`;
//     // let deviceToken = userSubscription ? JSON.stringify(userSubscription) : "";

//     controller.setState({
//       PasswordRecheck: { ...state.PasswordRecheck, name: "", loading: true },
//     });

//     const login = await UserLoginView.post({
//       apiToken: controller.apiToken,
//       data: {
//         username: controller.data?.userProfile?.username,
//         password: params?.password,
//         userId: state.userId
//       },
//     });

//     // const login = await RequestToken.post({
//     //   data: {
//     //     username: controller.data?.userProfile?.username,
//     //     password: params?.password,
//     //     device_id: deviceId,
//     //     device_type: "webpush",
//     //     device_name: deviceName,
//     //     device_token: deviceToken,
//     //     application: CONFIG.APP_NAME,
//     //   },
//     //   apiToken: controller.apiToken,
//     // });
//     state = controller.getState();

//     if (login[0]?.token) {
//       controller.setState({
//         PasswordRecheck: {
//           ...state.PasswordRecheck,
//           name: "ยืนยันเรียบร้อย",
//           pass: true,
//           loading: false,
//         },
//       });
//     } else {
//       controller.setState({
//         PasswordRecheck: {
//           ...state.PasswordRecheck,
//           name: "รหัสไม่ถูกต้อง",
//           pass: false,
//           loading: false,
//         },
//       });
//     }
//   } else if (params.action === "Clear") {

//     controller.setState({
//       PasswordRecheck: {
//         ...state.PasswordRecheck,
//         name: "",
//         pass: false,
//         loading: false,
//       },
//     });
//   }
// };

export const handleNurseNoteReport: Handler = async (controller, params) => {
  const state = controller.getState();

  controller.setState({
    buttonLoadCheck: {
      ...state.buttonLoadCheck,
      [`${params.card}_PRINT`]: "LOADING",
    },
  });

  const { encounter, started } = params;

  // ----- Get Encounter
  const enResp = state.selectedEncounter;
  // const [enResp, enError, enNetwork] = await EncounterDetail.retrieve({
  //   apiToken: controller.apiToken,
  //   pk: encounter,
  // });
  const patientId = enResp.patient_id;

  // ----- Get Patient
  // Issue พศ หรือ คศ แก้กันที่หน้าบ้าน
  const patientResp = state.selectedPatient;
  // const [
  //   patientResp,
  //   patientError,
  //   patientNetwork,
  // ] = await PatientDetailView.retrieve({
  //   apiToken: controller.apiToken,
  //   pk: patientId,
  // });

  if (!enResp) {
    controller.setState({
      buttonLoadCheck: {
        ...state.buttonLoadCheck,
        [`${params.card}_PRINT`]: null,
      },
      openModMessage: true,
      messageTitle: "ไม่พบ Encounter",
      errorMessage: enError,
      showGenDiagramPlate: false,
      genReportEncounter: null,
      genReportEmr: null,
      genReportEnStarted: null,
    });
    return;
  }

  if (!patientResp) {
    // console.error(patientError);
    controller.setState({
      buttonLoadCheck: {
        ...state.buttonLoadCheck,
        [`${params.card}_PRINT`]: null,
      },
      openModMessage: true,
      messageTitle: "ไม่พบผู้ป่วย",
      errorMessage: patientError,
      showGenDiagramPlate: false,
      genReportEncounter: null,
      genReportEmr: null,
      genReportEnStarted: null,
    });
    return;
  }

  const [
    [triageResp],
    [formDataResp],
    [adverseReactionResp, adverseReactionError],
    [vitalResp, vitalError],
  ] = await Promise.all([
    // ----- Get Triage
    TriageLatest.retrieve({
      apiToken: controller.apiToken,
      params: {
        patient: patientId,
        start_date: state.StartDate,
        end_date: state.ToDate,
      },
    }),
    // ----- Get Form Data
    FormDataLatest.retrieve({
      apiToken: controller.apiToken,
      params: {
        encounter: encounter,
        form_code: "CardPreAssessment",
        form_version: "1.0",
        start_date: started,
        end_date: started,
      },
    }),
    // ----- Get Adverse Reaction
    AdverseReactionList.list({
      apiToken: controller.apiToken,
      params: { patient: patientId },
    }),
    // ----- Vitalsign
    VitalSignValueList.list({
      apiToken: controller.apiToken,
      params: { encounter: encounter, latest: true },
    }),
  ]);

  // ----- Get Triage
  const [enCreateDate, enCreateTime] = formatUTCtoBEString(enResp.created_utc);

  // ----- Get Form Data
  let congenital_disease = [];
  let other = "";
  const cdResp = formDataResp?.data?.congenital_disease;
  let respitory_evaluate = "-";

  if (formDataResp?.data?.is_respiratory_disease) {
    if (formDataResp.data.is_respiratory_disease === "pass") {
      respitory_evaluate = "ผ่าน";
    }
  }

  if (cdResp?.congenital_disease?.value === "yes") {
    Object.keys(cdResp).map((key) => {
      if (["is_other", "other"].includes(key) && cdResp?.is_other?.value) {
        other = cdResp.other.value;
      } else if (key !== "congenital_disease" && cdResp[key].value) {
        congenital_disease.push(cdResp[key].label);
      }
    });
  } else {
    // console.log("formDataResp?.data?.congenital_disease", cdResp?.congenital_disease?.label);
    if (cdResp?.congenital_disease?.label) {
      congenital_disease.push(cdResp.congenital_disease.label);
    } else {
      congenital_disease.push(other);
    }
  }

  congenital_disease.push(other);
  congenital_disease = congenital_disease.filter((item) => item !== "");

  // ----- Get Adverse Reaction
  let adverseReaction = { drugAllergy: "-", foodAllergy: "-" };
  let adverseReactionText = "";

  if (!adverseReactionError) {
    await adverseReactionResp.items
      .reverse()
      .filter((item) => item.name !== "")
      .map((item, index) => {
        if (item.type_name_name === "DRUG") {
          adverseReaction.drugAllergy = item.note === "" ? "ไม่มี" : item.note;
        } else if (item.type_name_name === "FOOD") {
          adverseReaction.foodAllergy = item.note === "" ? "ไม่มี" : item.note;
        }

        if (index === 0) {
          adverseReactionText = `${item.name}`;
        } else {
          adverseReactionText = `${adverseReactionText}, ${item.name}`;
        }
      });
  }

  // ----- Vitalsign
  let vital = {
    temp: "-",
    sp: "-",
    dp: "-",
    weight: "-",
    height: "-",
    pulse: "-",
    created: "",
    editUser: "",
    tempColor: "black",
  };
  if (!vitalError) {
    vitalResp.items.forEach((resp) => {
      if (["TEMP", "BT"].includes(resp.vitalsign_type_code)) {
        vital.temp = resp.result;
        if (parseFloat(resp.result) < 36.5) {
          vital.tempColor = "orange";
        }
        if (parseFloat(resp.result) > 37.3) {
          vital.tempColor = "red";
        }
      } else if (resp.vitalsign_type_code === "SP") {
        vital.sp = resp.result;
      } else if (resp.vitalsign_type_code === "DP") {
        vital.dp = resp.result;
      } else if (resp.vitalsign_type_code === "Weight") {
        vital.weight = resp.result;
      } else if (resp.vitalsign_type_code === "Height") {
        vital.height = resp.result;
      } else if (["PULSE", "PR"].includes(resp.vitalsign_type_code)) {
        vital.pulse = resp.result;
      }
      if (resp.created) {
        const [dateComponent, timeComponent] = formatUTCtoBEString(resp.created);
        vital.created = timeComponent;
      }
      console.log(resp);
      if (resp.measure_edit_user_fullname) {
        vital.editUser = resp.measure_edit_user_fullname;
      }
    });
  }

  const divisionName = state.masterOptions?.division?.find(
    (option: any) =>
      option.value === params.nurseNoteData?.division ||
      option.value === state.selectedNurseNote?.division
  )?.text;
  // ----- Dental Medical History
  // const medHistoryResp = await getMedicalHistory(controller, { id: encounter });

  // ----- createPDFNurseNote
  let toDate = moment();
  let year = Number(toDate.format("YYYY")) + 543;

  let encounterDate = patientResp?.encounter_created?.split("[")?.[0];
  let admitDate = patientResp?.admit_date?.split("[")?.[0];

  const data = Object.assign(
    {
      // --- Image
      faceURL: params.faceURL,
      softURL: params.softURL,
      muscleURL: params.muscleURL,
      jawURL: params.jawURL,
      plaqueURL: params.plaqueURL,
      toothURL: params.toothURL,
      headNeckURL: params.headNeckURL,
      mouthURL: params.mouthURL,
      toothPlaqueURL: params.toothPlaqueURL,
      scorePlaqueURL: params.scorePlaqueURL,

      author: state.django?.user?.full_name,

      // --- enResp
      hn: enResp.hn,
      patient_name: patientResp.full_name,
      patient_birthdate: enResp.patient_birthdate,
      doctor_name: enResp.doctor_name,
      created_utc: `${enCreateDate} ${enCreateTime}`,
      encounter_type: patientResp.encounter_type,
      encounter_id: patientResp.encounter_no,
      encounterCreated: encounterDate,
      adverseReactionText: adverseReactionText,
      printDate: `${toDate.format("DD/MM")}/${year}, ${toDate.format("HH:mm")}`,
      profileImage: patientResp.profile_image?.image || null,
      admitDate: `${moment(admitDate, "DD/MM/YYYY").format("DD/MM")}/${
        Number(moment(admitDate, "DD/MM/YYYY").format("YYYY")) + 543
      }`,

      // --- patientResp
      career: patientResp.career || "-",
      tel_mobile: patientResp.present_address?.tel_mobile || "-",
      gender_name: patientResp.gender_name || "-",
      full_age: patientResp.full_age || "-",
      imageProfile: patientResp?.profile_image?.image || "/static/images/user.png",

      // --- triageResp
      arrive_status_label: triageResp?.arrive_status_label || "-",
      triage_level: triageResp?.triage_level || "-",
      chief_complaint: triageResp?.chief_complaint || "-",

      // --- formDataResp
      is_respiratory_disease: respitory_evaluate,
      congenital_disease: congenital_disease.join(", ") || "",
      cautions: !formDataResp?.data?.cautions?.no ? "มี" : "ไม่มี",
      pregnancy_breastfeeding: formDataResp?.data?.pregnancy_breastfeeding || "-",
      medication: formDataResp?.data?.medication?.drug_name.join(", ") || "-",
      narcotic: formDataResp?.data?.narcotic?.is_narcotic ? "ไม่มี" : "มี",

      // --- vitalResp
      temp: `${vital.temp}  ํC`,
      bp: `${vital.sp}/${vital.dp} mmHg.`,
      wh: `${vital.weight} kg./ ${vital.height} cm.`,
      pulse: `${vital.pulse} beats/min`,
      vitalCreated: `${vital.editUser} เวลา ${vital.created}`,
      tempColor: vital.tempColor,

      // --- medHistoryResp
      chief_complaints: "-",
      present_illness: "-",
      additional_comment: "-",

      selectedNurseNote: params.nurseNoteData || state.selectedNurseNote,
      divisionName: divisionName,

      nurseNoteList: params.nurseNoteList || state.nurseNoteFilter.nurseNoteList || [],
    },
    adverseReaction
  );

  // Call FormNurseNote
  await FormNurseNote(data);

  controller.setState({
    buttonLoadCheck: {
      ...state.buttonLoadCheck,
      [`${params.card}_PRINT`]: null,
    },
  });
};

export const HandleGetVerbalHistory: Handler = async (controller, params) => {
  const state = controller.getState();

  if (params.card) {
    controller.setState({
      loadingStatus: { ...state.loadingStatus, [params.card]: true },
    });
  }

  const history = await DoctorPendingTaskList.list({
    apiToken: controller.apiToken,
    params: {
      patient:
        state.verbalOrderHistoryFilter?.selectedSearch === "HN"
          ? state.verbalOrderHistoryFilter?.hn?.id || ""
          : "",
      encounter:
        state.verbalOrderHistoryFilter?.selectedSearch === "EN"
          ? state.verbalOrderHistoryFilter?.en?.id || ""
          : "",
      created_by: state.verbalOrderHistoryFilter?.editor?.id || "",
      start_date: convertFormatDate(state.verbalOrderHistoryFilter?.startDate || ""),
      end_date: convertFormatDate(state.verbalOrderHistoryFilter?.endDate || ""),
      order_specific_types: state.verbalOrderHistoryFilter?.checkedTypes,
    },
  });

  if (history[1]) {
    console.log("get verbal history error: ", history[1]);
    if (params.card) {
      controller.setState({
        errorMessage: {
          ...state.errorMessage,
          [params.card]: { error: history[1] },
        },
        loadingStatus: { ...state.loadingStatus, [params.card]: false },
      });
    }
    return;
  }

  return controller.setState({
    verbalOrderHistoryList: history[0].items || [],
    loadingStatus: { ...state.loadingStatus, [params.card]: false },
  });
};

export const HandleVerbalOrderAction = async (controller, params) => {
  const state = controller.getState();

  if (params?.action === "edit") {
    // do something
  } else if (params?.action === "remove") {
    const verbalOrder = await DoctorPendingTaskOrder.delete({
      apiToken: controller.apiToken,
      pk: params.pendingTaskOrderId,
      extra: {
        division: controller.data.division,
        device: (controller.data as any).device,
      },
    });

    if (verbalOrder[1]) {
      console.log("remove verbal order error: ", verbalOrder[1]);
      if (params.card) {
        controller.setState({
          errorMessage: {
            ...state.errorMessage,
            [params.card]: { error: verbalOrder[1] },
          },
        });
      }
      return;
    }

    return controller.setState(
      {
        successMessage: {
          ...state.successMessage,
          [params.card]: "ลบรายการสำเร็จ",
        },
      },
      async () => {
        await HandleGetVerbalHistory(controller, { card: params?.card || "" });
      }
    );
  }
};

export const HandleOPDOrderSummary: Handler = async (controller, params) => {
  const state = controller.getState();

  if (params?.action === "search") {
    controller.setState({
      loadingSummary: "true",
    });
    const isOrder = state.orderSummaryOPDFilter?.isOrder;
    const order = state.orderSummaryOPDFilter?.order;

    const orderOPD = await DoctorOrderForPTMList.list({
      apiToken: controller.apiToken,
      params: {
        encounter: state.selectedEncounter?.id,
        // status: state.orderSummaryOPDFilter?.isOrder
        //   ? state.orderSummaryOPDFilter.order
        //   : null,
        lab: state.orderSummaryOPDFilter?.isLab,
        imaging: state.orderSummaryOPDFilter?.isImaging,
        treatment: state.orderSummaryOPDFilter?.isTreatment,
        other: state.orderSummaryOPDFilter?.isOthers,
        out_perform_div: state.orderSummaryOPDFilter?.isOutPerformDiv,
      },
    });

    let items: any[] = orderOPD[0]?.items || [];

    if (isOrder && order) {
      const filterMap = {
        1: (data: any) => !data.order_ack_by_name,
        2: (data: any) => data.order_ack_by_name,
        3: (data: any) => data.order_status === "PERFORMED",
      } as any;

      items = items.filter((item) => filterMap[order]?.(item));
    }

    if (orderOPD[1]) {
      if (params?.card) {
        controller.setState({
          errorMessage: { ...state.errorMessage, [params.card]: orderOPD[1] },
          loadingSummary: false,
        });
      }
      return console.log("opd order summary error: ", orderOPD[1]);
    }

    return controller.setState({
      orderSummaryOPDList: items,
      loadingSummary: false,
    });
  } else if (params?.action === "open") {
    // const time = params.order?.order_ack_time?.match(/\d+:\d+/)?.[0] || "";

    return controller.setState({
      nurseOPDOrderPerformed: {
        ...state.nurseOPDOrderPerformed,
        selectedOrder: params?.order,
        modOpen: true,
        performedTime: moment().format("HH:mm"),
      },
    });
  } else if (params?.action === "tokenize") {
    controller.setState({
      nurseOPDOrderPerformed: {
        ...state.nurseOPDOrderPerformed,
        nurseLoading: true,
      },
    });

    const tokenize = await UserTokenizeView.post({
      apiToken: controller.apiToken,
      data: { code: params.code },
    });

    if (tokenize[1]) {
      return controller.setState({
        nurseOPDOrderPerformed: {
          ...state.nurseOPDOrderPerformed,
          nurseLoading: false,
          nurseError: true,
        },
      });
    }

    return controller.setState({
      nurseOPDOrderPerformed: {
        ...state.nurseOPDOrderPerformed,
        nurseLoading: false,
        nurseName: decodeURIComponent(atob(tokenize[0]?.token.split(".")[1])) || "",
        nurseToken: tokenize[0]?.token,
      },
    });
  } else if (params?.action === "confirm_performed") {
    let data: any = {
      order_ack_by: state.nurseOPDOrderPerformed?.nurseToken || "",
      order_ack_time: `${state.nurseOPDOrderPerformed?.performedDate || ""}-${
        state.nurseOPDOrderPerformed?.performedTime || ""
      }`,
    }

    if (
      ["doctornoteorder", "NORMAL"].includes(
        state.nurseOPDOrderPerformed?.selectedOrder?.specific_type_name
      )
    ) {
      data.order_status = "PERFORMED";
    }

    const orderPerformed = await DoctorOrderDetail.update({
      pk: state.nurseOPDOrderPerformed.selectedOrder?.id,
      apiToken: controller.apiToken,
      data: data,
      extra: {
        division: controller.data?.division,
        device: controller.data?.device,
      },
    });

    if (orderPerformed[1]) {
      if (params?.card) {
        controller.setState({
          errorMessage: {
            ...state.errorMessage,
            [params.card]: orderPerformed[1],
          },
        });
      }
      return console.log("opd order summary error: ", orderPerformed[1]);
    }

    return controller.setState(
      {
        successMessage: { ...state.successMessage, [params?.card || ""]: "บันทึกสำเร็จ" },
        nurseOPDOrderPerformed: {
          modOpen: false,
          selectedOrder: null,
          performedTime: moment().format("HH:mm"),
          performedDate: formatDate(moment()),
          nurseName: null,
          nurseToken: null,
          nurseError: null,
          nurseLoading: null,
        },
      },
      async () => {
        await HandleOPDOrderSummary(controller, { action: "search" });
      }
    );
  }
};

export const HandleEditOPDOrderSummary: Handler = async (controller, params) => {
  const state = controller.getState();

  controller.setState({ goToMenu: "คำสั่งอื่น ๆ", doctorNoteOrderEditId: params?.orderId });
};

export const HandleDeleteOPDOrderSummary: Handler = async (controller, params) => {
  const state = controller.getState();

  controller.setState({
    errorMessage: {
      ...state.errorMessage,
      [params?.card]: null,
    },
  });

  if (params.note === "") {
    controller.setState({
      errorMessage: {
        ...state.errorMessage,
        [params?.card]: "ยกเลิกไม่สำเร็จ กรุณาระบุเหตุผลในการยกเลิก",
      },
    });
  } else {
    const [doctorOrderRes, doctorOrderErr, doctorOrderNet] = await DoctorOrderCancel.put({
      pk: params.id,
      data: {
        action: "CANCEL",
        note: params.note,
      },
      apiToken: controller.apiToken,
      extra: {
        division: controller.data.division,
      },
    });

    if (doctorOrderRes) {
      params.callback?.("", null);
      HandleOPDOrderSummary(controller, { action: "search", card: params.card });
    }
  }
};

export const handleGetEncounterVitalSignList: Handler = async (controller, params) => {
  const state = controller.getState();

  controller.setState({ selectVitalSignLoading: true, getEncounterVitalSignListErr: null });

  const [res, error] = await MeasurementList.list({
    apiToken: controller.apiToken,
    params: {
      encounter: state.selectedEncounter?.id,
      ...params,
    },
  });

  controller.setState({ selectVitalSignLoading: false });

  if (res) {
    controller.setState({
      encounterVitalSignList: res?.items || [],
    });
  } else {
    controller.setState({
      encounterVitalSignList: [],
      getEncounterVitalSignListErr: error[1],
    });
  }
};

// UTILITIES
const convertFormatDate = (date: string) => {
  // only BE format at "dd/mm/YYYY"
  if (!date) {
    return "";
  }
  let formatSubstring = date.split("/");
  return `${formatSubstring[2]}-${formatSubstring[1]}-${formatSubstring[0]}`;
};

const FOCUS_ITEMS = [
  {
    text: "ผู้ป่วยมีโอกาสเกิดภาวะแทรกซ้อนเกี่ยวกับโรคหลอดเลือดสมอง",
    a: `o ผู้ป่วยแจ้งอาการผิดปกติ เกิดขึ้นทันทีทันใด
    o ปวดศีรษะมาก เวียนศีรษะ บ้านหมุน ทรงตัวไม่ได้
    o ตามัว เห็นภาพซ้อน ตามองไม่เห็น ข้างเดียวหรือสองข้าง
    o หน้าเบี้ยว ปากเบี้ยวครึ่งซีก
    o แขนขาชา อ่อนแรงครึ่งซีก
    o พูดไม่ออก พูดไม่ชัด สื่อสารไม่เข้าใจ
o สัญญาณชีพแรกรับ  เวลา …. : ….. น.  BP…… /……mmHg  PR…… / min  RR…… / min O2 sat. …….%`,
    i: `o Rest
    o sit
    o bed rest
    o absolute bed rest
o ประเมินระดับสัญญานชีพทุก 15 นาที
1. เวลา ….. : ….. น.  BP…… /……mmHg  PR…… / min  RR…… / min O2 sat. ……. %
2. เวลา ….. : ….. น.  BP…… /……mmHg  PR…… / min  RR…… / min O2 sat. ……. %
3. เวลา ….. : ….. น.  BP…… /……mmHg  PR…… / min  RR…… / min O2 sat. ……. %
o เฝ้าระวังใกล้ชิดและสังเกตการเปลี่ยนแปลงของอาการผิดปกติที่เกิดขึ้นทันทีทันใด
        o B  Balance ปวดศีรษะมาก เวียนศีรษะ บ้านหมุน ทรงตัวไม่ได้
        o E  Eye ตามัว เห็นภาพซ้อน ตามองไม่เห็น ข้างเดียวหรือสองข้าง
        o F  Face หน้าเบี้ยว ปากเบี้ยวครึ่งซีก
        o A Arm แขนขาชา อ่อนแรงครึ่งซีก
        o S Speech พูดไม่ออก พูดไม่ชัด สื่อสารไม่เข้าใจ
        o T Time ….. : ….. น. เวลาโทรแจ้ง 1669
        o รายงานอาจารย์ทันตแพทย์ทันที
        o ดำเนินการประสานงานทีมช่วยเหลือฉุกเฉินคณะฯ`,
    e: `o ความดันโลหิตลดลง
    เวลา ….. : ….. น.  BP…… /……mmHg  PR…… / min  RR…… / min O2 sat..……. %
o ไม่มีภาวะแทรกซ้อนเกี่ยวกับโรคหลอดเลือดสมอง
    …………………………………………………………………………………………………………………….

o ผู้ป่วยได้รับการส่งต่ออย่างรวดเร็ว ทีม 1669 มาถึงเวลา ….. : ….. น.
    เวลา ….. :..... น. BP…… /……mmHg  PR…… / min  RR…… / min O2 sat. ……. %
    …………………………………………………………………………………………………………………….`,
  },
  {
    text: "ผู้ป่วยมีโอกาสเกิดภาวะหัวใจล้มเหลว",
    a: `o ผู้ป่วยแจ้งอาการ
    o เจ็บแน่นหน้าอก
    o หอบเหนื่อย
    o ใจสั่น
    o ปวดสะบัก
    o ปวดไหล่ ร้าวไปที่แขน
    o ชีพจรเต้นผิดจังหวะ/ ไม่สม่ำเสมอ
o สัญญาณชีพแรกรับ  เวลา …. : ….. น.  BP…… /……mmHg  PR…… / min  RR…… / min O2 sat. …….%`,
    i: `o Rest
    o sit
    o bed rest
    o absolute bed rest
o O2 cannula
o O2 mask & bag
o ประเมินระดับสัญญานชีพทุก 15 นาที
1. เวลา ….. : ….. น.  BP…… /……mmHg  PR…… / min  RR…… / min O2 sat. ……. %
2. เวลา ….. : ….. น.  BP…… /……mmHg  PR…… / min  RR…… / min O2 sat. ……. %
o เฝ้าระวังใกล้ชิดและสังเกตการเปลี่ยนแปลงของอาการผิดปกติ
o รายงานอาจารย์ทันตแพทย์ทันที
o ดำเนินการประสานงานทีมช่วยเหลือฉุกเฉินคณะฯ
o โทรแจ้ง 1669 เวลา ….. : ….. น`,
    e: `o ไม่เกิดภาวะหัวใจล้มเหลว
เวลา ….. : ….. น.  BP…… /……mmHg  PR…… / min  RR…… / min O2 sat. ……. %
o ผู้ป่วยได้รับการส่งต่อสถานพยาบาลทันเวลา ทีม 1669 มาถึงเวลา ….. : ….. น.
เวลา ….. : ….. น.  BP…… /……mmHg  PR…… / min  RR…… / min O2 sat. ……. %`,
  },
  {
    text: "ผู้ป่วยมีโอกาสเกิดภาวะอุดกลั้นทางเดินหายใจ",
    a: `o ผู้ป่วยแจ้งอาการ
    o มีเลือดออก กัดผ้าก๊อสแล้วไม่หยุด ตั้งแต่วัน ...... เวลา ….. : ….. น.
o มีเลือดออกในช่องปากชุ่มผ้าก๊อส
    o มีลิ่มเลือด             		o อ่อนเพลีย
    o กระสับกระส่าย      		o หายใจลำบาก
    o มีประวัติเลือดออกง่าย/ หยุดยาก   	o รับประทานยาต้านการแข็งตัวของ
o บวมใบหน้า/ ลำคอ
o ผู้ป่วยช่วยเหลือตัวเองได้น้อย
    o มีปัญหาการกลืน     	o ไอ/ สำลัก      	o หายใจลำบาก
o สัญญาณชีพแรกรับ  เวลา …. : ….. น.  BP…… /……mmHg  PR…… / min  RR…… / min O2 sat. …….%`,
    i: `o จัดให้ผู้ป่วยอยู่ในท่า
    o Fowler’s position     	o High Fowler’s position
o Clear air way
o Gauze pressure
o O2 cannula
o ประเมินระดับสัญญานชีพทุก 15 นาที
1. เวลา ….. : ….. น.  BP…… /……mmHg  PR…… / min  RR…… / min O2 sat. ……. %
2. เวลา ….. : ….. น.  BP…… /……mmHg  PR…… / min  RR…… / min O2 sat. ……. %
3. เวลา ….. : ….. น.  BP…… /……mmHg  PR…… / min  RR…… / min O2 sat. ……. %
o เฝ้าระวังใกล้ชิดและสังเกตการเปลี่ยนแปลงของอาการผิดปกติ
o รายงานอาจารย์ทันตแพทย์ทันที
o ดำเนินการประสานงานทีมช่วยเหลือฉุกเฉินคณะฯ`,
    e: `o ไม่เกิดภาวะอุดกลั้นทางเดินหายใจ
เวลา ….. : ….. น.  BP…… /……mmHg  PR…… / min  RR…… / min O2 sat. ……. %

o ผู้ป่วยได้รับการรักษาทันเวลา
เวลา ….. : ….. น.  BP…… /……mmHg  PR…… / min  RR…… / min O2 sat. ……. %`,
  },
  {
    text: "ผู้ป่วยมีโอกาสเกิดภาวะความดันหิตสูง",
    a: `o ผู้ป่วยแจ้งอาการ
    o ปวดศีรษะมาก เวียนศีรษะ
    o คลื่นไส้ อาเจียน
    o ตามัว เห็นภาพซ้อน
    o วันนี้ไม่ได้รับประทานยาหลังอาหารเช้า
    o ปวดศีรษะมาก เวียนศีรษะ
o มีประวัติมีภาวะโรคร่วม
    o ระดับไขมันในเลือดสูง
    o หัวใจขาดเลือดหรือมีภาวะการเต้นหัวใจผิดจังหวะ รักษาด้วยการรับประทานยา
o สัญญาณชีพแรกรับ  เวลา …. : ….. น.  BP…… /……mmHg  PR…… / min  RR…… / min O2 sat. …….%`,
    i: `o Rest
    o sit
    o bed rest
    o absolute bed rest

o ให้รับประทานยา (ถ้ามี) ประเมินความดันโลหิตซ้ำหลังรับประทานยา 15 นาที
1. เวลา ….. : ….. น.  BP…… /……mmHg  PR…… / min  RR…… / min O2 sat. ……. %
2. เวลา ….. : ….. น.  BP…… /……mmHg  PR…… / min  RR…… / min O2 sat. ……. %
o เฝ้าระวังใกล้ชิดและสังเกตการเปลี่ยนแปลงของอาการผิดปกติ
o รายงานอาจารย์ทันตแพทย์ทันที
o ดำเนินการประสานงานทีมช่วยเหลือฉุกเฉินคณะฯ`,
    e: `o ความดันโลหิตลดลง
 เวลา ….. : ….. น.  BP…… /……mmHg  PR…… / min  RR…… / min O2 sat. ……. %
o  ไม่มีภาวะแทรกซ้อนเกี่ยวกับภาวะความดันหิตสูง /รับการรักษาตามแผน

o  ความดันหิตสูง Observe อาการต่อ ประเมินความดันโลหิตซ้ำทุก 15 นาที
เวลา ….. : ….. น.  BP…… /……mmHg  PR…… / min  RR…… / min O2 sat. ……. %
เวลา ….. : ….. น.  BP…… /……mmHg  PR…… / min  RR…… / min O2 sat. ……. %
เวลา ….. : ….. น.  BP…… /……mmHg  PR…… / min  RR…… / min O2 sat. ……. %
o  ความดันโลหิตลดลง ให้กลับบ้าน	o  ส่งปรึกษาแพทย์เฉพาะทาง
เวลา ….. : ….. น.  BP…… /……mmHg  PR…… / min  RR…… / min O2 sat. ……. %`,
  },
];
