import * as DrugSelectI from "./DrugSelect";
import * as DrugOrderActionI from "./DrugOrderAction";
import * as SetDrugOrderQueue from "./SetDrugOrderQueue";
import * as DrugOrderHistory from "./DrugOrderHistory";
import * as MedErrorList from "./MedErrorList";
import * as AllergyI from "./Allergy";
import * as DrugTransferRequest from "./DrugTransferRequest";
import * as DrugTransferRequestHistory from "./DrugTransferRequestHistory";
import * as DrugReturnRequestHistoryI from "./DrugReturnRequestHistory";
import * as AppointmentTelepharI from "./AppointmentTelephar";
import * as DrugResuscitationI from "./DrugResuscitation";
import * as DrugResuscitationOPDI from "./DrugResuscitationOPD";
import * as StockManagementI from "./StockManagement";
import * as MedReconcileI from "./MedReconcile";
import * as StockTransferOrderI from "./StockTransferOrder";
import * as DrugOrderTemplateI from "./DrugOrderTemplate";
import * as ReportDrugUseRecordI from "./ReportDrugUseRecord";
import * as DrugCounsellingI from "./DrugCounselling";
import * as TelePharmacyI from "./TelePharmacy";
import * as TransportationI from "./Transportation";
import * as DashboardTelepharI from "./DashboardTelephar";
import * as DrugOrderListI from "./DrugOrderList";
import * as IntraTelepharQueueI from "./IntraTelepharQueue";
import * as DrugPickingOrderI from "./DrugPickingOrder";

//  TODO - หากเพิ่ม sequence index ใน sequence ด้านล่าง ให้ไปแก้ sequence index ใน raks-thai ไฟล์ RTSequencePattern ด้วย
// [
//   -StockManagement,
//   -StockTransferOrder
// ]

export const SequencePattern: {
  [name: string]: { [flavor: string]: { [func: string]: any } };
} = {
  DrugSelect: {
    default: {
      START: DrugSelectI.Start,
      CheckADR: DrugSelectI.CheckADR,
      FeedbackADR: DrugSelectI.FeedbackADR,
      CheckDI: DrugSelectI.CheckDI,
      FeedbackDI: DrugSelectI.FeedbackDI,
      CheckDrugLabInteraction: DrugSelectI.CheckDrugLabInteraction,
      FeedbackDrugLabInteraction: DrugSelectI.FeedbackDrugLabInteraction,
      CheckDrugDisease: DrugSelectI.CheckDrugDisease,
      FeedbackDrugDisease: DrugSelectI.FeedbackDrugDisease,
      GetDrugDetail: DrugSelectI.GetDrugDetail,
    },
  },
  DrugOrderAction: {
    default: {
      START: DrugOrderActionI.Start,
      POOL: DrugOrderActionI.PerformDrugOrderAction,
    },
  },
  SetDrugOrderQueue: {
    default: {
      START: SetDrugOrderQueue.Start,
      preparedMasterData: SetDrugOrderQueue.preparedMasterData,
      SearchDrugOrder: SetDrugOrderQueue.SearchDrugOrder,
    },
  },
  DrugOrderHistory: {
    default: {
      START: DrugOrderHistory.Start,
      SearchDrugHistoryOrder: DrugOrderHistory.SearchDrugHistoryOrder,
    },
  },
  MedErrorList: {
    default: {
      START: MedErrorList.Start,
      SearchMedError: MedErrorList.SearchMedError,
      EditMedError: MedErrorList.EditMedError,
      SaveMedError: MedErrorList.SaveMedError,
    },
  },
  Allergy: {
    default: {
      START: AllergyI.Start,
      Edit: AllergyI.Edit,
      Fetch: AllergyI.Fetch,
    },
  },
  DrugTransferRequest: {
    default: {
      START: DrugTransferRequest.Start,
      SaveDrugTransferRequest: DrugTransferRequest.SaveDrugTransferRequest,
    },
  },
  DrugTransferRequestHistory: {
    default: {
      START: DrugTransferRequestHistory.Start,
      SearchDrugTransferRequestHistory:
        DrugTransferRequestHistory.SearchDrugTransferRequestHistory,
    },
  },
  DrugReturnRequestHistory: {
    default: {
      START: DrugReturnRequestHistoryI.Start,
      SearchDrugReturnRequestHistory:
        DrugReturnRequestHistoryI.SearchDrugReturnRequestHistory,
    },
  },
  AppointmentTelephar: {
    default: {
      START: AppointmentTelepharI.start,
      SearchAppointmentTelephar: AppointmentTelepharI.SearchAppointmentTelephar,
    },
  },
  DrugResuscitation: {
    default: {
      START: DrugResuscitationI.start,
      SearchDrugResuscitation: DrugResuscitationI.SearchDrugResuscitation,
    },
  },
  DrugResuscitationOPD: {
    default: {
      START: DrugResuscitationOPDI.Start,
      Edit: DrugResuscitationOPDI.Edit,
    },
  },
  StockManagement: {
    default: {
      START: StockManagementI.GetMaster,
      Action: StockManagementI.Action,
    },
  },
  MedReconcile: {
    default: {
      START: MedReconcileI.Start,
      Edit: MedReconcileI.Edit,
    },
  },
  StockTransferOrder: {
    default: {
      START: StockTransferOrderI.start,
      Action: StockTransferOrderI.Action,
    },
  },
  DrugOrderTemplate: {
    default: {
      START: DrugOrderTemplateI.Start,
      Action: DrugOrderTemplateI.HandleAction,
    },
  },
  ReportDrugUseRecord: {
    default: {
      START: ReportDrugUseRecordI.GetMaster,
      Action: ReportDrugUseRecordI.Action,
    },
  },
  DrugCounselling: {
    default: {
      START: DrugCounsellingI.Start,
      Action: DrugCounsellingI.Action,
    },
  },
  TelePharmacy: {
    default: {
      START: TelePharmacyI.Start,
      Action: TelePharmacyI.Action,
    },
  },
  Transportation: {
    default: {
      START: TransportationI.Start,
      Action: TransportationI.Action,
    },
  },
  DrugOrderList: {
    default: {
      START: DrugOrderListI.Start,
      Action: DrugOrderListI.Action,
    },
  },
  DashboardTelephar: {
    default: {
      START: DashboardTelepharI.Start,
      Action: DashboardTelepharI.Action,
    },
  },
  IntraTelepharQueue: {
    default: {
      START: IntraTelepharQueueI.GetMaster,
      Action: IntraTelepharQueueI.Action,
    },
  },
  DrugPickingOrder: {
    default: {
      START: DrugPickingOrderI.GetMaster,
      Action: DrugPickingOrderI.Action,
    },
  },
};

export default SequencePattern;
