import WasmController from "react-lib/frameworks/WasmController";

// APIs
// Core
import { PDFDocument } from "pdf-lib";
import moment, { Moment } from "moment";

import AggregateStockList from "issara-sdk/apis/AggregateStockList_core";
import DefaultBarcodeView from "issara-sdk/apis/DefaultBarcodeView_core";
import DispenseOrderDetail from "issara-sdk/apis/DispenseOrderDetail_core";
import DispenseOrderItemList from "issara-sdk/apis/DispenseOrderItemList_core";
import DispenseOrderItemPlanList from "issara-sdk/apis/DispenseOrderItemPlanList_core";
import DispenseOrderList from "issara-sdk/apis/DispenseOrderList_core";
import DivisionList from "issara-sdk/apis/DivisionList_core";
import DrugCreate from "issara-sdk/apis/DrugCreate_apps_TPD";
import ProductList from "issara-sdk/apis/ProductList_core";
import ProductLotDetail from "issara-sdk/apis/ProductLotDetail_core";
import ProductLotListCreate from "issara-sdk/apis/ProductLotListCreate_core";
import ProductStockList from "issara-sdk/apis/ProductStockList_coreM";
import ProductStockReconcileView from "issara-sdk/apis/ProductStockReconcileView_core";
import ProductTypeList from "issara-sdk/apis/ProductTypeList_core";
import StockAdd from "issara-sdk/apis/StockAdd_core";
import StockLogList from "issara-sdk/apis/StockLogList_core";
import StockReconcileDetail from "issara-sdk/apis/StockReconcileView_core";
import SupplyNewList from "issara-sdk/apis/SupplyNewList_apps_MSD";
import UpdateAggregateStock from "issara-sdk/apis/UpdateAggregateStock_core";
// Bil
import BillModeList from "issara-sdk/apis/BillModeList_apps_BIL";
// TPD
import DrugOrderActionView from "issara-sdk/apis/DrugOrderActionView_apps_TPD";
import DrugOrderDispenseReport from "issara-sdk/apis/DrugOrderDispenseReport_apps_TPD";
import DrugTransferRequestItemPlanList from "issara-sdk/apis/DrugTransferRequestItemPlanList_apps_TPD";
import DrugTransferRequestList from "issara-sdk/apis/DrugTransferRequestList_apps_TPD";
import ExportDrugStockReplenishmentView from "issara-sdk/apis/ExportDrugStockReplenishmentView_apps_TPD";
// MSD
import SupplyOrderDetail from "issara-sdk/apis/SupplyOrderDetail_apps_MSD";
import SupplyOrderDispenseReport from "issara-sdk/apis/SupplyOrderDispenseReport_apps_MSD";
import SupplyTransferRequestItemPlanList from "issara-sdk/apis/SupplyTransferRequestItemPlanList_apps_MSD";
import SupplyTransferRequestListCreate from "issara-sdk/apis/SupplyTransferRequestListCreate_apps_MSD";
// USERS
import MyPermissionView from "issara-sdk/apis/MyPermissionView_users";
// REG
import PatientDetailView from "issara-sdk/apis/PatientDetailView_apps_REG";

// Serializer
import StockLogSerializerI from "issara-sdk/types/StockLogSerializer_core";
import StockReconcileSerializerI from "issara-sdk/types/StockReconcileSerializer_core";

import getPdfMake from "react-lib/appcon/common/pdfMake";
import {
  SetErrorMessage,
  base64toBlob,
  downloadXLSX,
  getLogoReport,
  mapOptions,
} from "react-lib/apps/HISV3/common/CommonInterface";

// Form
import FormAddStock from "../FormAddStock";
import FormIssueStock from "../FormIssueStock";
import FormPatientMedicationDispense from "../FormPatientMedicationDispense";
import FormRequestStock from "../FormRequestStock";
import FormTransferStock from "../FormTransferStock";

import { State as MainState } from "HIS/MainHISInterface";

import { beToAd, formatDate } from "react-lib/utils/dateUtils";

import CONFIG from "config/config";

export type State = Partial<{
  // sequence
  StockManagementSequence: Partial<{
    sequenceIndex: "Action" | "Start" | null;
    activePage: number;
    auditLogList: StockLogSerializer[];
    filterHistory: Partial<HistoryFilterType>;
    filterStock: FilterStockType;
    isCounting: boolean;
    myPermissionList: any[];
    pagination: PaginationType[];
    permissions: PermissionsType;
    productDetail: Partial<ProductDetailType>;
    productLotList: ProductLotSerializer[];
    productStockByLotList: ProductStockSerializer[];
    productStockList: ProductStockSerializer[];
    selectedStock: AggStockSerializer | null;
    showRequiredField: RequiredFieldType | null;
    stockList: AggStockSerializer[];
    stockLogList: StockLogSerializer[];
    stockReconcileList: StockReconcileSerializer[];
    stockStorageDetail: StockStorageDetailType;
    tempFilterStock: FilterStockType;
    billModeOptions: OptionType[];
    divisionTypeDrugOptions: OptionType[];
    modeOptions: OptionType[];
    movementTypeOptions: OptionType[];
    productTypeOptions: OptionType[];
  }> | null;
  firstName?: string;
  lastName?: string;
  // CommonInterface
  masterOptions?: MasterOptionsType;
}>;

type ValueOf<T> = T[keyof T];

export type AggStockSerializer = {
  id: number;
  active?: boolean;
  active_count?: number;
  active_flag?: ValueOf<typeof ACTIVE_FLAGS>;
  bin_location?: string;
  grand_qty?: number;
  in_reconcile?: number;
  max_qty?: number;
  min_exp?: string | null;
  min_qty?: number;
  product: {
    id: number;
    code: string;
    name: string;
    name_en: string;
    p_type_name?: ValueOf<typeof PRODUCT_TYPES>;
    unit_name?: string;
  };
  storage: Partial<{
    id: number;
    code: string;
    name: string;
    name_en: string;
  }>;
  total_qty?: number;
  unexp_qty?: number;
};

export type StockStorageDetailType = {
  items: AggStockSerializer["storage"][];
  product_id?: number;
};

type FilterStockType = Partial<{
  counting: CountingStatusType;
  isExpiryDate: boolean;
  isGrandTotal: boolean;
  isMinQTY: boolean;
  product: number;
  productName: string;
  productType: string; // "ALL"
  status: ActiveStatusType;
  storage: "ALL" | number;
}>;

export type RequiredFieldType = Partial<{
  base_unit: string[];
  code: string[];
  dosage_form: string[];
  full_pricings: {
    bill_mode: string[];
    code: string[];
    price_normal: string[];
  }[];
  mode: string[];
  name: string[];
  stock_size: string[];
  stock_unit: string[];
  strength: string[];
}>;

export type ProductStockSerializer = {
  id: number;
  active: boolean;
  bin_location: string;
  counting_datetime: string | null;
  lot: ProductLotSerializer | null;
  max_quantity: number;
  min_quantity: number;
  product: AggStockSerializer["product"];
  product_storage_key: number;
  quantity: number;
  storage: AggStockSerializer["storage"];
};

export type ProductLotSerializer = {
  id: number;
  exp_datetime: string | null;
  is_default: boolean;
  mfc_datetime: string | null;
  mfc_no: string;
  product: number;
};

export type StockLogSerializer = {
  lot: ProductLotSerializer | null;
  type: keyof typeof MOVEMENT_TYPE;
} & StockLogSerializerI;

export type StockReconcileSerializer = {
  initial_user: {
    id: number;
    full_name: string;
    username: string;
  };
} & StockReconcileSerializerI;

export type ProductDetailType = {
  active_flag: boolean;
  bill_mode: number;
  code: string;
  container: string;
  dosage_form: number;
  drug: Partial<{
    drug_ingredients: Partial<IngredientType>[];
  }>;
  drug_name?: string;
  end_date: string;
  is_outside_drug: boolean;
  max_discount: number;
  name: string;
  overall_cose: number;
  price_foreign: number;
  price_normal: number;
  price_pledge: number;
  price_premium: number;
  price_special: number;
  product_type: "DRUG" | "EQUIP" | "SUPPLY";
  pronunciation: string;
  pronunciation_trade: string;
  start_date: string;
  strength: string;
  supply: Partial<{
    base_unit: number;
    manufacturer: number;
    mode: number;
    stock_size: number;
    stock_unit: number;
  }>;
  unit: number;
};

export type IngredientType = {
  sequence: number;
  ingredient: number;
  strength: string;
};

export type StockDetailType = {
  bin_location: string;
  expire_date: moment.Moment;
  index?: number;
  lot_id: number | null;
  lot_no: string;
  product?: AggStockSerializer["product"];
  quantity: string;
  reference_text: string;
  storage: number;
  options: OptionType[];
};

type GroupDrugType = {
  children: {
    expire_date: string;
    lot_no: string;
    quantity: number;
    total: number;
  }[];
  code: string;
  name: string;
}[];

export type TransferDetailType = {
  product: AggStockSerializer["product"];
  provider: number | string;
  quantity: string;
  requester: number | string;
  storage: AggStockSerializer["storage"];
};

export type IssueStockDetailType = {
  lot: ProductLotSerializer | null;
  max_quantity: number;
  product: AggStockSerializer["product"];
  provider: number | string;
  quantity: string;
  reason: string;
  remark: string;
  requester: number | string;
  stock_id: number;
  storage: AggStockSerializer["storage"];
};

export type HistoryFilterType = {
  endDate: string;
  isMoveIn: boolean;
  isMoveOut: boolean;
  lotNo: string[];
  movementType: "ALL" | number;
  startDate: string;
};

export type MasterOptionsType = Record<(typeof Masters)[number][0], OptionType[]>;

export type ActiveStatusType = "ACTIVE" | "ALL" | "INACTIVE";

export type CountingStatusType = "ALL" | "COUNTING" | "UNCOUNTING";

export type PermissionsKey =
  | "ADJUST_DATA_EDIT"
  | "ADJUST_DATA_VIEW"
  | "AUDIT_LOG_VIEW"
  | "COUNTING_EDIT"
  | "OTHER_STORE_ACTIVE"
  | "OTHER_STORE_VIEW"
  | "PRODUCT_ADD" //* สร้างรหัสสินค้า
  | "STOCK_ACTIVE" //* ModProductDetail
  | "STOCK_EDIT" //* ModProductDetail
  | "STOCK_MANAGEMENT_VIEW" //* ค้นหา, เคลียร์
  | "STOCK_VIEW" //* ModProductDetail
  | "TAB_ADD_ADD"
  | "TAB_HISTORY_VIEW"
  | "TAB_ISSUE_STOCK_ISSUE"
  | "TAB_LOT_VIEW"
  | "TAB_TRANSFER_TRANSFER";

export type PermissionsType = Record<PermissionsKey, boolean>;

type MyPermissionType =
  | "STOCK_EDIT_ADD"
  | "STOCK_EDIT_SET_ACTIVE"
  | "STOCK_TRANSFER_DELIVER"
  | "STOCK_TRANSFER_REQUEST"
  | "STOCK_VIEW";

export type PrintFormActionType =
  | "PRINT_ADD_STOCK_FORM"
  | "PRINT_ISSUE_STOCK_FORM"
  | "PRINT_REQUEST_STOCK_FORM"
  | "PRINT_TRANSFER_STOCK_FORM"
  | "REPRINT_DRUG_SUPPLY";

type RowSpanItem = {
  [key: string]: any;
  children?: RowSpanItem[];
  code?: string;
  name?: string;
  rowSpan?: number;
};

type TransferRequestItem = {
  id: string;
  [key: string]: any;
  items: Record<string, any>[];
};

export type OptionType = {
  key: number | string;
  text: string;
  value: number | string;
};

type PaginationType = {
  api: ("product" | "stock")[];
  limit: number[];
  offset: number[];
};

type ButtonActionKey = keyof typeof BUTTON_ACTIONS;

type Picked = Partial<
  Pick<MainState, "buttonLoadCheck" | "django" | "errorMessage" | "searchedItemListWithKey">
>;

// Sequence
type SeqState = {
  sequence: "StockManagement";
  card?: string;
  clear?: boolean;
  restart?: boolean;
};

// Common Params
type LoadCheck = {
  btnAction: string;
  card: string;
  errorKey: string;
};

// Handle Action
type ActionType =
  | ({
      action: "ADD_STOCK";
      stockList: Partial<StockDetailType>[];
      onSuccess?: () => any;
    } & LoadCheck)
  | ({
      action: "COUNTING";
      stock: AggStockSerializer | null;
      onLoading?: (loading: boolean) => any;
    } & LoadCheck)
  | ({
      action: "EDIT_STOCK";
      data: Partial<AggStockSerializer>;
      onSuccess?: () => any;
    } & LoadCheck)
  | {
      action: "GET_PRODUCT_STOCK_LOT";
      lot?: "ALL" | number;
      product?: number;
      status?: ActiveStatusType;
    }
  | {
      action: "PRINT_ADD_STOCK_FORM";
      data: StockLogSerializer;
      errorKey: string;
      onLoading?: (loading: boolean) => any;
    }
  | {
      action: "PRINT_ISSUE_STOCK_FORM";
      code: string;
      data: StockLogSerializer;
      errorKey: string;
      onLoading?: (loading: boolean) => any;
    }
  | {
      action: "PRINT_REQUEST_STOCK_FORM" | "PRINT_TRANSFER_STOCK_FORM" | "REPRINT_DRUG_SUPPLY";
      code: string;
      errorKey: string;
      onLoading?: (loading: boolean) => any;
    }
  | ({
      action: "SAVE_ADJUST_DATA";
      data?: Partial<ProductStockSerializer>;
      expireDate?: Moment;
      interQuantity?: number | string;
      isCounting?: boolean;
      note?: string;
      onSuccess?: (data: boolean) => any;
    } & LoadCheck)
  | ({
      action: "SAVE_ISSUE_STOCK";
      issueStockList: Partial<IssueStockDetailType>[];
      onSuccess?: () => any;
    } & LoadCheck)
  | ({
      action: "SAVE_STOCK_OTHER_STORE";
      offStorageList: {
        active?: boolean;
        lot?: number;
        product?: number;
        storage?: number;
      }[];
      onSuccess?: () => any;
    } & LoadCheck)
  | ({
      action: "SAVE_TRANSFER";
      transferList: Partial<TransferDetailType>[];
      onSuccess?: () => any;
    } & LoadCheck)
  | ({
      action: "SEARCH_LOT";
      lot?: "ALL" | number;
      status?: ActiveStatusType;
    } & LoadCheck)
  | {
      action: "SEARCH_PAGINATION";
      activePage: number;
      btnAction: ButtonActionKey;
      card: string;
    }
  | {
      action: "SELECT_PRODUCT";
      errorKey: string;
      stock: AggStockSerializer | null;
    }
  | ({ action: "ADD_PRODUCT"; onSuccess?: () => any } & LoadCheck)
  | { action: "GET_AUDIT_LOG" | "GET_STOCK_RECONCILE"; stockId?: number }
  | { action: "GET_STOCK_STORAGE"; product: AggStockSerializer["product"] }
  | ({ action: "PRINT_REPORT" | "SEARCH_HISTORY" } & LoadCheck)
  | { action: "SEARCH"; card: string; noError?: boolean }
  | { action: "STOCK_REPORT"; btnAction: ButtonActionKey; card: string };

type SeqAct = ActionType & SeqState;
type SeqType<K> = K extends { action: string } ? Extract<SeqAct, K> : SeqState;

export type RunSequence = <K extends keyof SeqAct>(params: SeqType<Pick<SeqAct, K>>) => any;

type CustomExtract<T, U> = T extends T ? (U extends Partial<T> ? T : never) : never;

type Params<A extends ActionType["action"]> = CustomExtract<ActionType, { action: A }>;

export const StateInitial: State = {
  // sequence
  StockManagementSequence: {
    sequenceIndex: null,
  },
};

export type Event = { message: "RunSequence"; params: Record<string, unknown> };

export type Data = {
  device?: number;
  division?: number;
};

export const DataInitial = {};

export const BUTTON_ACTIONS = {
  COUNTING: "COUNTING",
  PRINT: "PRINT",
  SAVE: "SAVE",
  SEARCH: "SEARCH",
  STOCK_REPORT: "STOCK_REPORT",
} as const;

export const DISTRIBUTION_REASON = {
  DISPOSAL: "สินค้ารอทำลาย",
  INTERNAL_DIVISION: "ตัดจ่ายเพื่อใช้งานภายในแผนก",
  OTHER_DIVISION: "ตัดจ่ายไปยังหน่วยงานอื่น",
  // VENDOR: "คืนสินค้าให้ผู้ขาย",
  PATIENT: "ตัดจ่ายสินค้าให้ผู้ป่วย",
  TREATMENT: "ตัดจ่ายเพื่อทำหัตการ",
} as const;

export const PRODUCT_TYPES = {
  DRUG: "ค่ายาและสารอาหาร",
  EQUIP: "ค่าอุปกรณ์ของใช้และเครื่องมือทางการแพทย์",
  SUPPLY: "ค่าเวชภัณฑ์",
} as const;

const LIMIT = 20;

const Masters = [
  ["productTypeDrugSupply", {}],
  ["unitCore", {}],
  ["storage", {}],
  ["dosageForm", {}],
  ["stockAddRef", {}],
  ["stockReconcileNote", {}],
  ["division", {}],
] as const;

const MOVEMENT_TYPE = {
  ADD_PERFORM: "ADD_PERFORM",
  DISPENSE_CANCEL: "DISPENSE_CANCEL",
  DISPENSE_PERFORM: "DISPENSE_PERFORM",
  MOVEMENT_PERFORM: "MOVEMENT_PERFORM",
  RECONCILE_PERFORM: "RECONCILE_PERFORM",
  RETURN_PERFORM: "RETURN_PERFORM",
  TRANSFER_CANCEL: "TRANSFER_CANCEL",
  TRANSFER_PERFORM: "TRANSFER_PERFORM",
};

const ACTIVE_FLAGS = {
  ACTIVE: 1,
  INACTIVE: 5,
  INSUFFICIENT: 2,
  TERMINATED: 4,
  UNAVAILABLE: 3,
} as const;

export const DATE_FORMAT = "YYYY-MM-DD";

const P_TYPE_CODE = "DRUG,SUPPLY,EQUIP";

const EXPIRATION_MONTHS = 6;

type Handler<P = unknown, R = void> = (
  controller: WasmController<Picked & State, Event, Data>,
  ...params: unknown extends P ? [params?: P] : [params: P]
) => R;

/* ------------------------------------------------------ */

/*                          START                         */

/* ------------------------------------------------------ */
export const GetMaster: Handler<SeqState> = async (controller, params) => {
  controller.handleEvent({
    message: "GetMasterData",
    params: {
      masters: Masters,
    },
  } as any);

  const [bil, productType, division, permission] = await Promise.all([
    BillModeList.list({
      apiToken: controller.apiToken,
      params: { search: "ยา" },
    }),
    ProductTypeList.list({
      apiToken: controller.apiToken,
      params: { code__in: P_TYPE_CODE },
    }),
    DivisionList.list({
      apiToken: controller.apiToken,
      params: { set_storage: true },
    }),
    MyPermissionView.list({
      apiToken: controller.apiToken,
      params: {
        search: "STOCK_",
        type: "CUSTOM",
      },
      extra: {
        division: controller.data.division,
        location: 1,
      },
    }),
  ]);

  const state = controller.getState();

  const billItems: any[] = bil[0]?.items || [];
  const productTypeItems: any[] = productType[0]?.items || [];
  const divisionItems: any[] = division[0]?.items || [];

  const permissions: { [key: string]: any; identifier: MyPermissionType }[] = permission[0] || [];
  const permissionItems = new Set(permissions.map((item) => item.identifier));

  const setPermission = (permission: MyPermissionType, keys: PermissionsKey[]) =>
    Object.fromEntries(keys.map((key) => [key, permissionItems.has(permission)]));

  const settings: [MyPermissionType, PermissionsKey[]][] = [
    ["STOCK_TRANSFER_REQUEST", ["TAB_TRANSFER_TRANSFER"]],
    ["STOCK_EDIT_SET_ACTIVE", ["STOCK_ACTIVE", "OTHER_STORE_ACTIVE"]],
    [
      "STOCK_VIEW",
      [
        "STOCK_VIEW",
        "TAB_LOT_VIEW",
        "OTHER_STORE_VIEW",
        "ADJUST_DATA_VIEW",
        "AUDIT_LOG_VIEW",
        "TAB_HISTORY_VIEW",
        "STOCK_MANAGEMENT_VIEW",
      ],
    ],
    [
      "STOCK_EDIT_ADD",
      ["PRODUCT_ADD", "STOCK_EDIT", "COUNTING_EDIT", "ADJUST_DATA_EDIT", "TAB_ADD_ADD"],
    ],
    ["STOCK_TRANSFER_DELIVER", ["TAB_ISSUE_STOCK_ISSUE"]],
  ];

  const formattedPermissions: PermissionsType = Object.assign(
    {},
    ...settings.map((item) => setPermission(item[0], item[1]))
  );

  const date = formatDate(moment());

  controller.setState(
    {
      StockManagementSequence: {
        ...state.StockManagementSequence,
        billModeOptions: mapOptions(billItems, "id", "name"),
        divisionTypeDrugOptions: mapOptions(divisionItems, "id", "name", "storage"),
        filterHistory: {
          endDate: date,
          isMoveIn: true,
          isMoveOut: true,
          movementType: "ALL",
          startDate: date,
        },
        filterStock: {
          counting: "ALL",
          productType: "ALL",
          status: "ALL",
          storage: "ALL",
        },
        modeOptions: mapOptions(["EQUIPMENT", "SUPPLY", "BOTH"]),
        movementTypeOptions: [
          { key: "DISPENSE_PERFORM", text: "จ่ายออก", value: 2 },
          { key: "RETURN_PERFORM", text: "คืนยา", value: 4 },
          { key: "TRANSFER_PERFORM", text: "โอนสินค้า", value: 6 },
          { key: "TRANSFER_CANCEL", text: "ปฏิเสธสถานะขอสินค้า", value: 7 },
          { key: "MOVEMENT_PERFORM", text: "จาก INF", value: 8 },
          { key: "RECONCILE_PERFORM", text: "Adjust data", value: 10 },
          { key: "ADD_PERFORM", text: "การเติมสินค้าจาก", value: 12 },
        ],
        myPermissionList: permission[0] || [],
        permissions: formattedPermissions,
        productTypeOptions: mapOptions(productTypeItems, "code", "name", "id"),
        selectedStock: null,
        sequenceIndex: "Action",
      },
    },
    () => {
      Action(controller, {
        action: "SEARCH",
        card: params.card || "",
        noError: true,
      });
    }
  );
};

/* ------------------------------------------------------ */

/*                      Handle Action                     */

/* ------------------------------------------------------ */
export const Action: Handler<ActionType> = async (controller, params) => {
  const actionHandlers: Partial<{ [K in ActionType["action"]]: Handler<Params<K>> }> = {
    ADD_PRODUCT: HandleAddProduct,
    ADD_STOCK: HandleAddStock,
    COUNTING: HandleCounting,
    EDIT_STOCK: HandleEditStock,
    GET_AUDIT_LOG: HandleGetAuditLog,
    GET_PRODUCT_STOCK_LOT: HandleGetProductStockLot,
    GET_STOCK_RECONCILE: HandleGetStockReconcile,
    GET_STOCK_STORAGE: HandleGetStockStorage,
    PRINT_ADD_STOCK_FORM: HandlePrintAddStockForm,
    PRINT_ISSUE_STOCK_FORM: HandlePrintIssueStockForm,
    PRINT_REPORT: HandlePrintReport,
    PRINT_REQUEST_STOCK_FORM: HandlePrintRequestStockForm,
    PRINT_TRANSFER_STOCK_FORM: HandlePrintTransferStockForm,
    REPRINT_DRUG_SUPPLY: HandleDrugSupplyReprint,
    SAVE_ADJUST_DATA: HandleSaveAdjustData,
    SAVE_ISSUE_STOCK: HandleSaveIssue,
    SAVE_STOCK_OTHER_STORE: HandleSaveStockOtherStore,
    SAVE_TRANSFER: HandleSaveTransfer,
    SEARCH: HandleSearch,
    SEARCH_HISTORY: HandleSearchHistory,
    SEARCH_LOT: HandleSearchLot,
    SEARCH_PAGINATION: HandleSearchPagination,
    SELECT_PRODUCT: HandleSelectProduct,
    STOCK_REPORT: HandleStockReport,
  };

  const { action } = params;

  return actionHandlers[action]?.(controller, params as Params<typeof params.action>);
};

const HandleSearch: Handler<Params<"SEARCH">> = async (controller, params) => {
  let state = controller.getState();

  if (!state.StockManagementSequence?.permissions?.STOCK_MANAGEMENT_VIEW) {
    return;
  }

  controller.setState({
    buttonLoadCheck: {
      ...state.buttonLoadCheck,
      [`${params.card}_${params.action}`]: "LOADING",
    },
  });

  const promiseArr = [GetProductList(controller, {}), GetAggStockList(controller, {})];

  const [[product], [stock]] = await Promise.all(promiseArr);

  const pagination = GetPagination(product?.total, stock?.total);

  const allList = ConcatProductStockList(product?.items, stock?.items);

  // -----
  state = controller.getState();

  controller.setState({
    StockManagementSequence: {
      ...state.StockManagementSequence,
      activePage: 1,
      pagination,
      stockList: allList.map((item, index: number) => ({
        ...item,
        id: index + 1,
      })),
      tempFilterStock: JSON.parse(JSON.stringify(state.StockManagementSequence?.filterStock || {})),
    },
  });

  const commonStateUpdate = {
    buttonLoadCheck: {
      ...state.buttonLoadCheck,
      [`${params.card}_${params.action}`]: "SUCCESS",
    },
  };

  if (allList.length > 0) {
    controller.setState(commonStateUpdate);
  } else if (params.noError) {
    controller.setState(commonStateUpdate);
  } else {
    controller.setState({
      ...commonStateUpdate,
      errorMessage: {
        ...state.errorMessage,
        [`${params.card}_${params.action}`]: "NOT_FOUND",
      },
    });
  }
};

const HandleSearchPagination: Handler<
  Params<"SEARCH_PAGINATION">,
  Promise<AggStockSerializer[]>
> = async (controller, params) => {
  let state = controller.getState();

  const btnKey = `${params.card}_${params.btnAction}`;

  controller.setState({ buttonLoadCheck: { ...state.buttonLoadCheck, [btnKey]: "LOADING" } });

  const pagination = state.StockManagementSequence?.pagination || [];
  const tempFilterStock = state.StockManagementSequence?.tempFilterStock || {};

  // * activePage เริ่มต้นที่ 1
  const target = pagination[params.activePage - 1];

  const api = {
    product: GetProductList,
    stock: GetAggStockList,
  };

  const promiseArr = (["product", "stock"] as const).map(async (key) => {
    const index = target.api.indexOf(key);

    return index >= 0
      ? api[key](controller, {
          ...params,
          filter: tempFilterStock,
          limit: target.limit[index],
          offset: target.offset[index],
        })
      : [];
  });

  const [product, stock] = await Promise.all(promiseArr);

  const allList = ConcatProductStockList(product[0]?.items, stock[0]?.items);

  // -----
  state = controller.getState();

  controller.setState({
    StockManagementSequence: {
      ...state.StockManagementSequence,
      activePage: params.activePage,
      stockList: allList.map((item, index: number) => ({
        ...item,
        id: index + 1 + (params.activePage - 1) * LIMIT,
      })),
    },
    buttonLoadCheck: { ...state.buttonLoadCheck, [btnKey]: "SUCCESS" },
  });

  return allList as AggStockSerializer[];
};

const HandleAddProduct: Handler<Params<"ADD_PRODUCT">> = async (controller, params) => {
  let state = controller.getState();

  const btnKey = `${params.card}_${params.btnAction}`;

  controller.setState({ buttonLoadCheck: { ...state.buttonLoadCheck, [btnKey]: "LOADING" } });

  const detail = state.StockManagementSequence?.productDetail || {};
  const productTypeOptions = state.StockManagementSequence?.productTypeOptions || [];

  const pricingKey =
    "bill_mode,price_normal,price_special,price_premium,price_foreign,price_pledge,max_discount,start_date,end_date".split(
      ","
    ) as (keyof ProductDetailType)[];
  const fullPricing = Object.fromEntries(pricingKey.map((key) => [key, detail[key]]));

  const data = prepareProductData(detail, fullPricing, productTypeOptions, pricingKey);

  // ---- แสดง error จาก frontend
  const isDrug = detail.product_type === "DRUG";
  const isSupply = detail.product_type === "SUPPLY";

  const shouldValidateSupply = detail.active_flag !== undefined && isSupply;

  const requiredFields = ["base_unit", "stock_unit", "stock_size"] as const;
  const errMsg: Partial<Record<"base_unit" | "stock_size" | "stock_unit" | "strength", string[]>> =
    {};

  if (shouldValidateSupply) {
    for (const key of requiredFields) {
      if (!data[key]) {
        errMsg[key] = ["This field is required."];
      }
    }
  }

  // เพิ่มการตรวจสอบ strength สำหรับ Drug
  if (isDrug && !data.strength) {
    errMsg.strength = ["This field is required."];
  }

  if (Object.keys(errMsg).length > 0) {
    controller.setState(
      {
        StockManagementSequence: {
          ...state.StockManagementSequence,
          showRequiredField: errMsg,
        },
      },
      () => {
        SetErrorMessage(controller, { ...params, error: errMsg });
      }
    );

    return;
  }

  const api = isDrug ? DrugCreate : SupplyNewList;

  const [, error] = await api.create({
    apiToken: controller.apiToken,
    data,
  });

  if (isDrug && error) {
    delete error.stock_unit;
  }

  if (error) {
    controller.setState(
      {
        StockManagementSequence: {
          ...state.StockManagementSequence,
          showRequiredField: error,
        },
      },
      () => {
        SetErrorMessage(controller, { ...params, error });
      }
    );

    return;
  }

  state = controller.getState();

  controller.setState(
    {
      StockManagementSequence: {
        ...state.StockManagementSequence,
        productLotList: [],
        productStockList: [],
        selectedStock: null,
        showRequiredField: null,
        stockLogList: [],
      },
      buttonLoadCheck: { ...state.buttonLoadCheck, [btnKey]: "SUCCESS" },
    },
    () => {
      Action(controller, { action: "SEARCH", card: params.card });
    }
  );

  params.onSuccess?.();
};

const HandleEditStock: Handler<Params<"EDIT_STOCK">> = async (controller, params) => {
  const state = controller.getState();

  const { btnAction, card, data } = params;
  const btnKey = `${card}_${btnAction}`;

  controller.setState({ buttonLoadCheck: { ...state.buttonLoadCheck, [btnKey]: "LOADING" } });

  const stock = await UpdateAggStockUpdateList(controller, data);

  if (stock[1]) {
    SetErrorMessage(controller, { ...params, error: stock[1] });
  } else {
    controller.setState(
      {
        buttonLoadCheck: {
          ...state.buttonLoadCheck,
          [btnKey]: "SUCCESS",
        },
      },
      () => {
        Action(controller, {
          action: "SEARCH_PAGINATION",
          activePage: state.StockManagementSequence?.activePage || 1,
          btnAction: BUTTON_ACTIONS.SEARCH,
          card,
        });
      }
    );

    params.onSuccess?.();
  }
};

const HandleAddStock: Handler<Params<"ADD_STOCK">> = async (controller, params) => {
  const state = controller.getState();
  const btnKey = `${params.card}_${params.btnAction}`;

  controller.setState({ buttonLoadCheck: { ...state.buttonLoadCheck, [btnKey]: "LOADING" } });

  let lotRes: any[] = [];

  const stockUpdate: Record<string, any> = {};

  const updatedLot = params.stockList.filter((item) => !item.lot_id);

  // หากเป็น lot no ใหม่ให้ทำการ create ก่อน
  if (updatedLot.length > 0) {
    const promiseArr = updatedLot.map(
      async (item) =>
        ProductLotListCreate.create({
          apiToken: controller.apiToken,
          data: {
            exp_datetime: item.expire_date ? formatDatetime(item.expire_date) : "",
            mfc_datetime: null, // formatDate(moment()),
            mfc_no: item.lot_no,
          },
          pk: item.product?.id,
        }) as Promise<[unknown, unknown]>
    );

    const promiseResults = await Promise.all(promiseArr);

    lotRes = promiseResults.map((res) => res[0] || {});
  }

  const groupStorage = new Map<string, Partial<StockDetailType>[]>();

  for (const item of params.stockList) {
    const storage = item.storage?.toString() || "";
    const existingItems = groupStorage.get(storage);

    if (existingItems) {
      existingItems.push(item);
    } else {
      groupStorage.set(storage, [item]);
    }
  }

  const result = Object.fromEntries(groupStorage);

  const stockList = Object.values(result).flat();

  for (const item of stockList) {
    const lotId =
      lotRes.find(
        (accumulator) =>
          accumulator.product === item.product?.id && accumulator.mfc_no === item.lot_no
      )?.id || null;

    const res = await StockAdd.create({
      apiToken: controller.apiToken,
      data: {
        lot: item.lot_id || lotId,
        product: item.product?.id,
        quantity: item.quantity,
        reference_text: item.reference_text,
        storage: item.storage,
      } as any,
    });

    stockUpdate[item.product?.id || ""] = {
      bin_location: item.bin_location,
      product: { id: res[0]?.stock?.product },
      storage: { id: res[0]?.stock?.storage },
    };
  }

  const promiseArr = Object.values(stockUpdate).map(async (item) =>
    UpdateAggStockUpdateList(controller, item)
  );

  const response = await Promise.all(promiseArr);

  if (response.some((res) => res[1])) {
    SetErrorMessage(controller, {
      ...params,
      error: response.map((res) => res[1]),
    });

    return;
  }

  await HandlePrintFormAddStock(controller, { groupStorage: result });

  params.onSuccess?.();

  controller.setState(
    {
      buttonLoadCheck: {
        ...state.buttonLoadCheck,
        [btnKey]: "SUCCESS",
      },
    },
    async () => {
      HandleSearchNSelect(controller, params);
    }
  );
};

const HandleSearchNSelect: Handler<{
  card: string;
  errorKey: string;
}> = async (controller, params) => {
  const state = controller.getState();

  const items = await HandleSearchPagination(controller, {
    action: "SEARCH_PAGINATION",
    activePage: state.StockManagementSequence?.activePage || 1,
    btnAction: BUTTON_ACTIONS.SEARCH,
    card: params.card,
  });

  const stock = state.StockManagementSequence?.selectedStock;
  const target = items.find(
    (item) => item.product.id === stock?.product.id && item.storage.id === stock.storage.id
  );

  if (target && stock) {
    stock.in_reconcile = target.in_reconcile;
  }

  Action(controller, {
    action: "SELECT_PRODUCT",
    errorKey: params.errorKey,
    stock: target ? stock || null : null,
  });
};

const HandleSearchLot: Handler<Params<"SEARCH_LOT">> = async (controller, params) => {
  let state = controller.getState();

  if (!state.StockManagementSequence?.permissions?.TAB_LOT_VIEW) {
    return;
  }

  const btnKey = `${params.card}_${params.btnAction}`;

  controller.setState({ buttonLoadCheck: { ...state.buttonLoadCheck, [btnKey]: "LOADING" } });

  const data = state.StockManagementSequence.selectedStock;

  const result = await GetProductStock(controller, {
    lot: params.lot,
    product: data?.product.id,
    status: params.status,
    storage: data?.storage.id,
  });

  // -----
  state = controller.getState();

  const items = result[0]?.items || [];

  if (result[1]) {
    SetErrorMessage(controller, { ...params, error: result[1] });
  } else {
    controller.setState({
      StockManagementSequence: {
        ...state.StockManagementSequence,
        productStockList: items,
      },
      buttonLoadCheck: { ...state.buttonLoadCheck, [btnKey]: "SUCCESS" },
    });
  }
};

const HandleSelectProduct: Handler<Params<"SELECT_PRODUCT">> = async (controller, params) => {
  let state = controller.getState();

  if (!params.stock?.product.id) {
    controller.setState({
      StockManagementSequence: {
        ...state.StockManagementSequence,
        productLotList: [],
        productStockList: [],
        selectedStock: null,
        stockLogList: [],
      },
    });

    return;
  }

  const productId = params.stock.product.id;

  const lot = await ProductLotListCreate.list({
    apiToken: controller.apiToken,
    pk: productId,
  });

  const lotItems: Record<string, any>[] = lot[0]?.items || [];
  const items: any[] = lotItems.filter((item: any) => item.mfc_no);

  // -----
  state = controller.getState();

  controller.setState(
    {
      StockManagementSequence: {
        ...state.StockManagementSequence,
        filterHistory: {
          ...state.StockManagementSequence?.filterHistory,
          lotNo: [],
        },
        productLotList: items,
        selectedStock: params.stock,
      },
    },
    () => {
      Action(controller, {
        action: "SEARCH_LOT",
        btnAction: "",
        card: "",
        errorKey: params.errorKey,
      });
      Action(controller, {
        action: "SEARCH_HISTORY",
        btnAction: "",
        card: "",
        errorKey: params.errorKey,
      });
    }
  );
};

const HandleSearchHistory: Handler<Params<"SEARCH_HISTORY">> = async (controller, params) => {
  const state = controller.getState();

  if (!state.StockManagementSequence?.permissions?.TAB_HISTORY_VIEW) {
    return;
  }

  const btnKey = `${params.card}_${params.btnAction}`;

  controller.setState({ buttonLoadCheck: { ...state.buttonLoadCheck, [btnKey]: "LOADING" } });

  const data = state.StockManagementSequence.selectedStock;
  const filter = state.StockManagementSequence.filterHistory;

  const currentDate = formatDate(moment());
  const startDate = filter?.startDate ?? currentDate;
  const endDate = filter?.endDate ?? currentDate;

  const { quantityGt, quantityLt } = getQuantityRange(filter);

  const result = await StockLogList.list({
    apiToken: controller.apiToken,
    params: {
      datetime__gte: startDate ? formatDatetime(beToAd(startDate) as any) : undefined,
      datetime__lte: endDate ? formatDatetime(beToAd(endDate)?.endOf("day") as any) : undefined,
      quantity__gt: quantityGt,
      quantity__lt: quantityLt,
      stock__lot__mfc_no__in: filter?.lotNo?.join(","),
      stock__product: data?.product.id,
      stock__storage: data?.storage.id,
      type: filter?.movementType === "ALL" ? undefined : filter?.movementType,
    },
  });

  if (result[1]) {
    SetErrorMessage(controller, {
      ...params,
      error: result[1],
    });
  } else {
    const state = controller.getState();

    controller.setState({
      StockManagementSequence: {
        ...state.StockManagementSequence,
        stockLogList: result[0]?.items || [],
      },
      buttonLoadCheck: { ...state.buttonLoadCheck, [btnKey]: "SUCCESS" },
    });
  }
};

const HandleSaveStockOtherStore: Handler<Params<"SAVE_STOCK_OTHER_STORE">> = async (
  controller,
  params
) => {
  const state = controller.getState();
  const btnKey = `${params.card}_${params.btnAction}`;

  controller.setState({ buttonLoadCheck: { ...state.buttonLoadCheck, [btnKey]: "LOADING" } });

  const promiseArr = params.offStorageList.map(
    async (item) =>
      ProductStockList.create({
        apiToken: controller.apiToken,
        data: { active: item.active },
        params: {
          lot: item.lot,
          storage: item.storage,
        },
        pk: item.product,
      }) as Promise<[unknown, unknown]>
  );

  const response = await Promise.all(promiseArr);

  if (response.some((res) => res[1])) {
    SetErrorMessage(controller, {
      ...params,
      error: response.map((res) => res[1]),
    });
  } else {
    params.onSuccess?.();

    controller.setState({ buttonLoadCheck: { ...state.buttonLoadCheck, [btnKey]: "SUCCESS" } });
  }
};

const HandleSaveTransfer: Handler<Params<"SAVE_TRANSFER">> = async (controller, params) => {
  const state = controller.getState();
  const btnKey = `${params.card}_${params.btnAction}`;

  controller.setState({ buttonLoadCheck: { ...state.buttonLoadCheck, [btnKey]: "LOADING" } });

  const list = params.transferList;

  const { provider, requester } = list[0];
  const isDrug = list[0].product?.p_type_name === PRODUCT_TYPES.DRUG;

  const items = list.map((item) => ({
    code: item.product?.code,
    [isDrug ? "drug" : "supply"]: item.product?.id,
    name: item.product?.name,
    request_quantity: item.quantity,
    stock_unit_name: item.product?.unit_name,
  }));

  const api = isDrug ? DrugTransferRequestList : SupplyTransferRequestListCreate;

  const result = await api.create({
    apiToken: controller.apiToken,
    data: {
      action: "REQUEST",
      code: "NEW",
      is_provider: false,
      is_requester: false,
      items,
      provider,
      provider_name: "",
      requester,
      requester_name: "",
      status_name: "NEW",
    },
    extra: {
      division: controller.data.division,
    },
  });

  if (result[1]) {
    SetErrorMessage(controller, { ...params, error: result[1] });

    return;
  }

  // #await CreatePDFStockTransfer(controller, { data: result[0], isSupply });
  await HandlePrintFormRequestStock(controller, {
    data: result[0],
    items,
  });

  params.onSuccess?.();

  controller.setState({ buttonLoadCheck: { ...state.buttonLoadCheck, [btnKey]: "SUCCESS" } });
};

const HandleSaveIssue: Handler<Params<"SAVE_ISSUE_STOCK">> = async (controller, params) => {
  const state = controller.getState();
  const btnKey = `${params.card}_${params.btnAction}`;

  controller.setState({ buttonLoadCheck: { ...state.buttonLoadCheck, [btnKey]: "LOADING" } });

  const list = params.issueStockList.filter((item) => !!Number(item.quantity));
  const detail = list[0];

  const extra = {
    division_id: detail.reason === DISTRIBUTION_REASON.OTHER_DIVISION ? detail.provider : null,
    note: detail.remark || "",
    patient_id: detail.reason === DISTRIBUTION_REASON.PATIENT ? detail.provider : null,
    reason: detail.reason,
  };

  const [orderRes] = await DispenseOrderList.create({
    apiToken: controller.apiToken,
    data: {
      order_div: controller.cookies.get("division_id"),
      storage: detail.storage?.id,
      extra,
    } as any,
  });

  // group by product id เพื่อทำการบันทึกและอัพเดต dispense order
  const groupByProduct = new Map<string, any[]>();

  for (const item of list) {
    const groupKey = item.product?.id.toString() || "";
    const data = groupByProduct.get(groupKey);

    if (data) {
      data.push(item);
    } else {
      groupByProduct.set(groupKey, [item]);
    }
  }

  const result = Object.fromEntries(groupByProduct);

  for (const [key, items] of Object.entries(result)) {
    // add item ไปใน dispense order
    const [itemRes] = await DispenseOrderItemList.create({
      apiToken: controller.apiToken,
      data: {
        product: key,
        quantity: 0,
      },
      order_id: orderRes?.id,
    });

    for (const accumulator of items) {
      // เลือก lot ที่ต้องการจะดึง
      await DispenseOrderItemPlanList.create({
        apiToken: controller.apiToken,
        data: {
          quantity: -Number(accumulator.quantity),
          stock: accumulator.stock_id,
        },
        item_id: itemRes?.id,
        order_id: orderRes?.id,
      });
    }
  }

  // update status เป็น REQUESTED
  const promise: Promise<any> = DispenseOrderDetail.patch({
    apiToken: controller.apiToken,
    data: { action: "REQUEST" },
    pk: orderRes?.id,
  });

  const orderDetail = await promise.then(
    async () =>
      // update status เป็น DELIVERED
      DispenseOrderDetail.patch({
        apiToken: controller.apiToken,
        data: { action: "DELIVER" },
        pk: orderRes?.id,
      }) as Promise<[any, any]>
  );

  if (orderDetail[1]) {
    SetErrorMessage(controller, { ...params, error: orderDetail[1] });

    return;
  }

  await HandlePrintFormIssueStock(controller, {
    detail: {
      ...extra,
      code: orderDetail[0].code,
      requester: detail.requester,
    },
    items: list,
  });

  params.onSuccess?.();

  controller.setState(
    { buttonLoadCheck: { ...state.buttonLoadCheck, [btnKey]: "SUCCESS" } },
    () => {
      Action(controller, {
        action: "SEARCH_PAGINATION",
        activePage: state.StockManagementSequence?.activePage || 1,
        btnAction: BUTTON_ACTIONS.SEARCH,
        card: params.card,
      });
    }
  );
};

const HandleCounting: Handler<Params<"COUNTING">> = async (controller, params) => {
  const state = controller.getState();

  params.onLoading?.(true);

  const storageId = params.stock?.storage.id;
  const productId = params.stock?.product.id;
  const isStarting = !!params.stock?.in_reconcile;

  let reconcile: any = null;

  reconcile = await (isStarting
    ? ProductStockReconcileView.patch({
        apiToken: controller.apiToken,
        product_id: productId,
        storage_id: storageId,
      })
    : ProductStockReconcileView.create({
        apiToken: controller.apiToken,
        product_id: productId,
        storage_id: storageId,
      }));

  if (reconcile[0]) {
    HandleSearchNSelect(controller, params);
  } else {
    controller.setState({
      errorMessage: {
        ...state.errorMessage,
        [params.errorKey]: reconcile[0],
      },
    });
  }

  params.onLoading?.(false);
};

export const HandleSaveAdjustData: Handler<Params<"SAVE_ADJUST_DATA">> = async (
  controller,
  params
) => {
  const state = controller.getState();
  const btnKey = `${params.card}_${params.btnAction}`;

  controller.setState({ buttonLoadCheck: { ...state.buttonLoadCheck, [btnKey]: "LOADING" } });

  const lot = params.data?.lot;

  const isDateChange =
    moment(lot?.exp_datetime).format(DATE_FORMAT) !==
      moment(params.expireDate).format(DATE_FORMAT) && lot?.mfc_no !== "N/A";

  const productLot = isDateChange
    ? await UpdateProductLot(controller, {
        expireDate: params.expireDate?.toISOString(),
        pid: params.data?.product?.id,
        pk: params.data?.lot?.id,
      })
    : [];

  // * หากบันทึก product lot แล้ว error ให้แสดง error message
  if (productLot[1]) {
    SetErrorMessage(controller, { ...params, error: productLot[1] });

    return;
  }

  const reconcile = params.isCounting
    ? await StockReconcileDetail.patch({
        apiToken: controller.apiToken,
        data: {
          inter_quantity: params.interQuantity,
          note: params.note || "",
        } as any,
        pk: params.data?.id,
      })
    : [];

  if (reconcile?.[1]) {
    SetErrorMessage(controller, { ...params, error: reconcile[1] });
  } else {
    controller.setState({ buttonLoadCheck: { ...state.buttonLoadCheck, [btnKey]: "SUCCESS" } });

    params.onSuccess?.(isDateChange);
  }
};

const HandleGetProductStockLot: Handler<Params<"GET_PRODUCT_STOCK_LOT">> = async (
  controller,
  params
) => {
  const result = await GetProductStock(controller, {
    lot: params.lot,
    product: params.product,
    status: params.status,
  });

  const state = controller.getState();

  controller.setState({
    StockManagementSequence: {
      ...state.StockManagementSequence,
      productStockByLotList: result[0]?.items || [],
    },
  });
};

const HandleGetAuditLog: Handler<Params<"GET_AUDIT_LOG">> = async (controller, params) => {
  const state = controller.getState();
  const options = state.StockManagementSequence?.movementTypeOptions || [];

  const result = await StockLogList.list({
    apiToken: controller.apiToken,
    params: {
      stock: params.stockId,
      type: options.find((option) => option.key === MOVEMENT_TYPE.RECONCILE_PERFORM)?.value,
    },
  });

  controller.setState({
    StockManagementSequence: {
      ...state.StockManagementSequence,
      auditLogList: result[0]?.items || [],
    },
  });
};

const HandleGetStockReconcile: Handler<Params<"GET_STOCK_RECONCILE">> = async (
  controller,
  params
) => {
  const result = await StockReconcileDetail.list({
    apiToken: controller.apiToken,
    pk: params.stockId,
  });

  const state = controller.getState();

  controller.setState({
    StockManagementSequence: {
      ...state.StockManagementSequence,
      stockReconcileList: result[0]?.items || [],
    },
  });
};

const HandleGetStockStorage: Handler<Params<"GET_STOCK_STORAGE">> = async (controller, params) => {
  let state = controller.getState();

  const productTypeOptions = state.StockManagementSequence?.productTypeOptions || [];
  const { product } = params;

  const [result] = await GetAggStockList(controller, {
    filter: {
      productName: product.name,
      productType: productTypeOptions.find((option) => option.text === product.p_type_name)
        ?.value as string,
    },
  });

  state = controller.getState();

  const items = (result?.items || []).flatMap((item) =>
    item.product.code === product.code ? [item.storage] : []
  );

  controller.setState({
    StockManagementSequence: {
      ...state.StockManagementSequence,
      stockStorageDetail: {
        items,
        product_id: product.id,
      },
    },
  });
};

const HandleStockReport: Handler<Params<"STOCK_REPORT">> = async (controller, params) => {
  const state = controller.getState();
  const btnKey = `${params.card}_${params.btnAction}`;

  controller.setState({ buttonLoadCheck: { ...state.buttonLoadCheck, [btnKey]: "LOADING" } });

  const [result, error] = await ExportDrugStockReplenishmentView.get({
    apiToken: controller.apiToken,
    params: { division: controller.data.division },
    extra: { responseType: "arraybuffer" },
  });

  if (error) {
    SetErrorMessage(controller, { ...params, error });
  }

  downloadXLSX(result, "รายงานการเติมเต็มสินค้า");

  controller.setState({ buttonLoadCheck: { ...state.buttonLoadCheck, [btnKey]: "SUCCESS" } });
};

const HandlePrintReport: Handler<Params<"PRINT_REPORT">> = async (controller, params) => {
  const state = controller.getState();
  const btnKey = `${params.card}_${params.btnAction}`;

  controller.setState({ buttonLoadCheck: { ...state.buttonLoadCheck, [btnKey]: "LOADING" } });

  const data = state.StockManagementSequence?.selectedStock;
  const filter = state.StockManagementSequence?.filterHistory || {};

  const urlParams = {
    last_date: filter.endDate ? beToAd(filter.endDate)?.format(DATE_FORMAT) : undefined,
    product: data?.product.id,
    start_date: filter.startDate ? beToAd(filter.startDate)?.format(DATE_FORMAT) : undefined,
    storage: data?.storage.id,
  } as any;

  const api =
    data?.product.p_type_name === PRODUCT_TYPES.DRUG
      ? DrugOrderDispenseReport
      : SupplyOrderDispenseReport;

  const dispense = await api.list({
    apiToken: controller.apiToken,
    params: urlParams as any,
  });

  if (dispense[1]) {
    SetErrorMessage(controller, { ...params, error: dispense[1] });
  } else {
    await HandlePrintFormPatientMedicationDispense(controller, {
      ...params,
      items: dispense?.[0]?.items || [],
    });

    controller.setState({ buttonLoadCheck: { ...state.buttonLoadCheck, [btnKey]: "SUCCESS" } });
  }
};

const HandlePrintRequestStockForm: Handler<Params<"PRINT_REQUEST_STOCK_FORM">> = async (
  controller,
  params
) => {
  params.onLoading?.(true);

  const detail = await GetDrugSupplyDetail(controller, params);

  if (detail.data) {
    await HandlePrintFormRequestStock(controller, {
      data: detail.data,
      items: detail.data.items,
    });
  }

  params.onLoading?.(false);
};

const HandlePrintTransferStockForm: Handler<Params<"PRINT_TRANSFER_STOCK_FORM">> = async (
  controller,
  params
) => {
  params.onLoading?.(true);

  const detail = await GetDrugSupplyDetail(controller, {
    ...params,
    plan: true,
  });

  if (detail.data) {
    await HandlePrintFormTransferStock(controller, {
      data: detail.data,
      items: detail.data.items,
    });
  }

  params.onLoading?.(false);
};

const HandlePrintAddStockForm: Handler<Params<"PRINT_ADD_STOCK_FORM">> = async (
  controller,
  params
) => {
  params.onLoading?.(true);

  const state = controller.getState();
  const stock = state.StockManagementSequence?.selectedStock;
  const { data } = params;

  const groupStorage: Record<string, Partial<StockDetailType>[]> = {
    [`${stock?.storage.id}`]: [
      {
        bin_location: data.bin_location,
        expire_date: moment(data.lot?.exp_datetime),
        lot_id: data.lot?.id,
        lot_no: data.lot?.mfc_no,
        product: stock?.product,
        quantity: data.quantity,
        reference_text: data.reference_text,
        storage: stock?.storage.id,
      },
    ],
  };

  await HandlePrintFormAddStock(controller, {
    datetime: data.datetime,
    editor: data.edit_user_name,
    groupStorage,
  });

  params.onLoading?.(false);
};

const HandleDrugSupplyReprint: Handler<Params<"REPRINT_DRUG_SUPPLY">> = async (
  controller,
  params
) => {
  params.onLoading?.(true);

  const state = controller.getState();

  const selected = state.StockManagementSequence?.selectedStock;

  if (selected?.product.p_type_name === PRODUCT_TYPES.DRUG) {
    await HandleDrugReprint(controller, params);
  } else if (selected?.product.p_type_name === PRODUCT_TYPES.SUPPLY) {
    await HandleSupplyReprint(controller, params);
  }

  params.onLoading?.(false);
};

const HandlePrintIssueStockForm: Handler<Params<"PRINT_ISSUE_STOCK_FORM">> = async (
  controller,
  params
) => {
  params.onLoading?.(true);

  const state = controller.getState();
  const selected = state.StockManagementSequence?.selectedStock;

  const [order] = await DispenseOrderList.list({
    apiToken: controller.apiToken,
    params: {
      code: params.code,
    },
  });

  const items: any[] = order?.items || [];

  if (
    !items[0] &&
    ([PRODUCT_TYPES.DRUG, PRODUCT_TYPES.SUPPLY] as string[]).includes(
      selected?.product.p_type_name || ""
    )
  ) {
    HandleDrugSupplyReprint(controller, {
      ...params,
      action: "REPRINT_DRUG_SUPPLY",
    });

    return;
  }

  const detail = items[0] || {};
  const detailItems: any[] = detail.items || [];

  const list = detailItems.flatMap((item: any) => {
    const plans: any[] = item.plans || [];
    const reverse: any[] = plans.reverse();

    const result: { items: Partial<IssueStockDetailType>[]; sum: number } = {
      items: [],
      sum: 0,
    };

    for (const accumulator of reverse) {
      const sum = Math.abs(accumulator.quantity) + result.sum;

      if (sum <= item.quantity) {
        result.items.push(accumulator);
        result.sum = sum;
      }
    }

    return result.items.reverse().map((plan) => ({
      ...plan,
      product: item.product,
      quantity: Math.abs(item.quantity).toString(),
    }));
  });

  await HandlePrintFormIssueStock(controller, {
    detail: {
      ...detail.extra,
      code: params.code,
      requester: detail.order_div,
      userName: params.data.edit_user_name,
    },
    items: list,
  });

  params.onLoading?.(false);
};

/* ------------------------------------------------------ */

/*                        PRINT PDF                       */

/* ------------------------------------------------------ */
export const HandlePrintFormTransferStock: Handler<
  {
    data: Record<string, any>;
    items: any[];
  },
  Promise<void>
> = async (controller, params) => {
  const formattedItems = params.items.flatMap((item) => {
    const transferItems: any[] = item.items || [];

    return {
      // group ตาม product name
      children: transferItems.map((accumulator: any, index: number) => ({
        expire_date: formatDate(moment(accumulator.lot.exp_datetime)),
        lot_no: accumulator.lot.mfc_no,
        quantity: Math.abs(accumulator.quantity),
        // หาผลรวม
        total: transferItems
          .slice(0, index + 1)
          .reduce<number>((result, object) => result + Math.abs(object.quantity as number), 0),
      })),
      code: item.code,
      name: item.name,
    };
  });

  const data = {
    ...params.data,
    items: getRowSpanColumns(formattedItems),
  };

  const docDef = await FormTransferStock({ ...data });

  const pdfMake = await getPdfMake();

  pdfMake.createPdf(docDef).open();
};

const HandlePrintFormRequestStock: Handler<
  {
    data: Record<string, any>;
    items: any[];
  },
  Promise<void>
> = async (controller, params) => {
  const data = {
    ...params.data,
    items: params.items,
  };

  const docDef = await FormRequestStock({ ...data });

  const pdfMake = await getPdfMake(true);

  pdfMake.createPdf(docDef).open();
};

const HandlePrintFormPatientMedicationDispense: Handler<
  {
    btnAction: string;
    card: string;
    items: any[];
  },
  Promise<void>
> = async (controller, params) => {
  const state = controller.getState();

  let docDef: any = { content: [] };

  const selected = state.StockManagementSequence?.selectedStock;
  const filter = state.StockManagementSequence?.filterHistory || {};

  const product = selected?.product;

  const fullName = state.django?.user?.full_name;

  const userName = fullName || `${state.firstName ?? ""} ${state.lastName ?? ""}`;

  const headerName =
    product?.p_type_name === PRODUCT_TYPES.DRUG
      ? "รายงานการจ่ายยาผู้ป่วย"
      : "รายงานการจ่ายเวชภัณฑ์ผู้ป่วย";

  const foundLogo = await getLogoReport();

  const data = {
    endDate: filter.endDate,
    headerName,
    hideAddress: CONFIG.RAKSTHAI_STOCK_REPORT,
    items: params.items,
    // logo: CONFIG.LOGO_REPORT.find((i: any) => i.type === 1).src || "/static/images/logochula_dent.jpg",
    logo: foundLogo.src || "/static/images/logochula_dent.jpg",
    startDate: filter.startDate,
    storageName: selected?.storage.name,
    titleName: product?.id ? `[${product.code || ""}]-${product.name || ""}` : "",
    userName,
  };

  docDef = await FormPatientMedicationDispense({ ...data });

  const pdfMake = await getPdfMake(true);

  pdfMake.createPdf(docDef).open();
};

const HandleDrugReprint: Handler<Params<"REPRINT_DRUG_SUPPLY">, Promise<void>> = async (
  controller,
  params
) => {
  const state = controller.getState();

  const barcode = await GetDefaultBarcode(controller, {
    code: params.code,
    prefix: "D01",
  });

  const print = await DrugOrderActionView.update({
    apiToken: controller.apiToken,
    data: {
      action: "REPRINT",
      language: "TH",
      note: "เนื่องจาก ",
      print_drug_label: false,
      print_patient: false,
      print_pharma: true,
    },
    pk: barcode[0]?.pk,
    extra: {
      device: controller.data.device,
      division: controller.data.division,
    },
  });

  if (print[1]) {
    controller.setState({
      errorMessage: {
        ...state.errorMessage,
        [params.errorKey]: print[1],
      },
    });
  }
};

const HandleSupplyReprint: Handler<Params<"REPRINT_DRUG_SUPPLY">, Promise<void>> = async (
  controller,
  params
) => {
  const state = controller.getState();

  const barcode = await GetDefaultBarcode(controller, {
    code: params.code,
    prefix: "S01",
  });

  const supply = await SupplyOrderDetail.retrieve({
    apiToken: controller.apiToken,
    pk: barcode[0]?.pk,
    extra: {
      division: controller.data.division,
    },
  });

  const print = await SupplyOrderDetail.update({
    apiToken: controller.apiToken,
    data: {
      action: "REPRINT",
      co_user: "",
      is_transporter: false,
      items: supply[0]?.items,
    },
    pk: barcode[0]?.pk,
    extra: {
      device: controller.data.device,
      division: controller.data.division,
    },
  });

  if (print[1]) {
    controller.setState({
      errorMessage: {
        ...state.errorMessage,
        [params.errorKey]: print[1],
      },
    });
  }
};

const HandlePrintFormAddStock: Handler<
  {
    datetime?: string;
    editor?: string;
    groupStorage: Record<string, Partial<StockDetailType>[]>;
  },
  Promise<void>
> = async (controller, params) => {
  const state = controller.getState();

  const storageOptions = state.masterOptions?.storage || [];
  const momentDate = moment();
  const fullName = state.django?.user?.full_name;

  const userName = fullName || `${state.firstName ?? ""} ${state.lastName ?? ""}`;

  const entries = Object.entries(params.groupStorage);
  const groupStorageItem: Record<
    string,
    {
      datetime: string;
      editor: string;
      hideCode?: boolean;
      items: GroupDrugType;
      staff: string;
      storageName: string;
    }
  > = {};

  for (const [key, value] of entries) {
    groupStorageItem[key] = {
      datetime: params.datetime
        ? `${formatDate(moment(params.datetime))} [${moment(params.datetime).format("HH:mm")}]`
        : `${formatDate(momentDate)} [${momentDate.format("HH:mm")}]`,
      editor: params.editor || userName,
      hideCode: true,
      items: groupItemsByProduct(value),
      staff: userName,
      storageName: storageOptions.find((option) => option.value === Number(key))?.text || "",
    };
  }

  const result = Object.fromEntries(Object.entries(groupStorageItem));

  // Create PDF: Add stock
  const pdfMake = await getPdfMake();

  const createPDFBase64 = async (data: any): Promise<string> => {
    let docDef: any = { content: [] };

    docDef = await FormAddStock(data);

    return new Promise((resolve) => {
      pdfMake.createPdf(docDef).getBase64((result: any) => {
        resolve(result);
      });
    });
  };

  const promiseArr = Object.values(result).map(async (data) =>
    createPDFBase64({
      ...data,
      items: getRowSpanColumns(data.items),
    })
  );

  const pdfBase64 = await Promise.all(promiseArr);

  const pdfDocument = await PDFDocument.create();

  for (const base64 of pdfBase64) {
    const document = await PDFDocument.load(base64);
    const copiedPages = await pdfDocument.copyPages(document, document.getPageIndices());

    for (const page of copiedPages) {
      pdfDocument.addPage(page);
    }
  }

  const base64Data = await pdfDocument.saveAsBase64();

  const blob = base64toBlob(`data:application/pdf;base64,${base64Data}`);

  const bloburl = URL.createObjectURL(blob);

  window.open(bloburl);
};

const HandlePrintFormIssueStock: Handler<
  {
    detail: {
      code: string;
      division_id?: number | string | null;
      note?: string;
      patient_id?: number | string | null;
      reason?: string;
      requester?: number | string;
      userName?: string;
    };
    items: Partial<IssueStockDetailType>[];
  },
  Promise<void>
> = async (controller, params) => {
  const state = controller.getState();

  const list = params.items.map((item) => ({
    expire_date: moment(item.lot?.exp_datetime),
    lot_no: item.lot?.mfc_no,
    product: item.product,
    quantity: item.quantity,
  }));

  let providerName = "";

  const groupItems = groupItemsByProduct(list);

  const momentDate = moment();
  const { detail } = params;

  const divisionName =
    state.masterOptions?.division.find((option) => option.value === detail.requester)?.text || "";
  const fullName = state.django?.user?.full_name;

  const userName = fullName || `${state.firstName ?? ""} ${state.lastName ?? ""}`;

  // แสดงชื่อ division
  if (detail.division_id) {
    providerName =
      state.masterOptions?.division.find((option) => option.value === detail.division_id)?.text ||
      "";
  }
  // แสดงชื่อผู้ป่วย
  else if (detail.patient_id) {
    const [patient] = await PatientDetailView.retrieve({
      apiToken: controller.apiToken,
      pk: detail.patient_id,
    });

    providerName = patient?.full_name || "";
  }

  const data = {
    code: detail.code,
    datetime: `${formatDate(momentDate)} [${momentDate.format("HH:mm")}]`,
    divisionName,
    items: getRowSpanColumns(groupItems),
    providerName,
    reason: detail.reason,
    remark: detail.note,
    userName: detail.userName || userName,
  };

  const docDef = await FormIssueStock({ ...data });

  const pdfMake = await getPdfMake();

  pdfMake.createPdf(docDef).open();
};

/* ------------------------------------------------------ */

/*                           API                          */

/* ------------------------------------------------------ */
const GetAggStockList: Handler<
  {
    filter?: FilterStockType;
    limit?: number;
    offset?: number;
  },
  Promise<[{ items: AggStockSerializer[]; total: number } | null, any]>
> = async (controller, params) => {
  const state = controller.getState();

  const filter = params.filter || state.StockManagementSequence?.filterStock || {};
  const productTypeOptions = state.StockManagementSequence?.productTypeOptions || [];

  const isActive = {
    ACTIVE: true,
    ALL: undefined,
    INACTIVE: false,
  }[filter.status || "ALL"];

  const formatDate = (date: moment.Moment) =>
    `${date.format(DATE_FORMAT)}T${date.format("HH:mm")}+07`;

  let isInReconcile: boolean | undefined;

  if (filter.counting === "COUNTING") {
    isInReconcile = true;
  } else if (filter.counting === "UNCOUNTING") {
    isInReconcile = false;
  }

  return AggregateStockList.list({
    apiToken: controller.apiToken,
    params: {
      active: isActive,
      exp_dt: filter.isExpiryDate
        ? formatDate(moment().add(EXPIRATION_MONTHS, "months"))
        : undefined,
      in_reconcile: isInReconcile,
      // has_lot: true,
      limit: params.limit ?? LIMIT,
      min_qty: filter.isMinQTY,
      offset: params.offset,
      product__p_type:
        filter.productType === "ALL"
          ? undefined
          : productTypeOptions.find((option) => option.value === filter.productType)?.key,
      product__p_type__in:
        filter.productType === "ALL"
          ? productTypeOptions.map((option) => option.key).join(",")
          : undefined,
      qty_0: filter.isGrandTotal,
      search: filter.productName,
      storage: filter.storage === "ALL" ? undefined : filter.storage,
    },
  }) as Promise<[{ items: AggStockSerializer[]; total: number } | null, any]>;
};

const GetProductList: Handler<
  {
    filter?: FilterStockType;
    limit?: number;
    offset?: number;
  },
  Promise<[any, any]>
> = async (controller, params) => {
  const state = controller.getState();

  const filter = params.filter || state.StockManagementSequence?.filterStock || {};

  const active = {
    ACTIVE: "1,2,3,4",
    ALL: undefined,
    INACTIVE: "5",
  }[filter.status || "ALL"];

  if (filter.isMinQTY || filter.isExpiryDate || filter.isGrandTotal || filter.storage !== "ALL") {
    return [[], null];
  }

  // GET Product
  return ProductList.list({
    apiToken: controller.apiToken,
    params: {
      active_flag__in: active,
      limit: params.limit ?? LIMIT,
      no_stock: true,
      offset: params.offset,
      ordering: "-id",
      p_type__code: filter.productType === "ALL" ? undefined : filter.productType,
      p_type__code__in: filter.productType === "ALL" ? P_TYPE_CODE : undefined,
      search: filter.productName,
    },
  }) as Promise<[any, any]>;
};

const UpdateAggStockUpdateList: Handler<Record<string, any>, Promise<[unknown, unknown]>> = async (
  controller,
  params
) =>
  UpdateAggregateStock.post({
    apiToken: controller.apiToken,
    data: {
      active: params.active,
      bin_location: params.bin_location,
      max_quantity: params.max_qty,
      min_quantity: params.min_qty,
      product: params.product?.id,
      storage: params.storage?.id,
    },
  }) as Promise<[unknown, unknown]>;

const GetProductStock: Handler<
  Partial<{
    lot: "ALL" | number;
    product: number;
    status: ActiveStatusType;
    storage: number;
  }>,
  Promise<[any, any]>
> = async (controller, params) => {
  const isActive = {
    ACTIVE: true,
    ALL: undefined,
    INACTIVE: false,
  }[params.status || "ALL"];

  return ProductStockList.list({
    apiToken: controller.apiToken,
    params: {
      active: isActive,
      lot: params.lot === "ALL" ? undefined : params.lot,
      storage: params.storage,
    },
    pk: params.product,
  }) as Promise<[any, any]>;
};

const GetDefaultBarcode: Handler<
  { code: string; prefix: "D01" | "S01" },
  Promise<[any, any]>
> = async (controller, params) =>
  DefaultBarcodeView.get({
    apiToken: controller.apiToken,
    barcode: `${params.prefix}${params.code}`,
    extra: {
      device: controller.data.device,
      division: controller.data.division,
    },
  }) as Promise<[any, any]>;

const GetDrugSupplyDetail: Handler<
  { code: string; plan?: boolean },
  Promise<{ data: any; type: "DRUG" | "SUPPLY" }>
> = async (controller, params) => {
  const [[drug], [supply]] = await Promise.all([
    DrugTransferRequestList.list({
      apiToken: controller.apiToken,
      params: { code: params.code },
    }),
    SupplyTransferRequestListCreate.list({
      apiToken: controller.apiToken,
      params: { code: params.code },
    }),
  ]);

  const drugItems: TransferRequestItem | undefined = drug.items[0];
  const supplyItems: TransferRequestItem | undefined = supply.items?.[0];

  const detail = drug.items?.length
    ? { data: drugItems, type: "DRUG" as const }
    : { data: supplyItems, type: "SUPPLY" as const };

  if (detail.data) {
    const api =
      detail.type === "DRUG"
        ? DrugTransferRequestItemPlanList.list
        : SupplyTransferRequestItemPlanList.list;

    const promiseArr = detail.data.items.map(async (item) => {
      const fetchItemPlanList: Promise<[TransferRequestItem | undefined, any]> = api({
        apiToken: controller.apiToken,
        pk: item.id,
        extra: {
          division: controller.data.division,
        },
      });

      return fetchItemPlanList.then(([res]) => ({ ...item, items: res?.items || [] }));
    });

    detail.data.items = await Promise.all(promiseArr);
  }

  return detail;
};

const UpdateProductLot: Handler<
  Partial<{
    expireDate: string;
    pid: number;
    pk: number;
  }>,
  Promise<[any, any]>
> = async (controller, params) =>
  ProductLotDetail.patch({
    apiToken: controller.apiToken,
    data: {
      exp_datetime: params.expireDate || "",
    },
    pid: params.pid,
    pk: params.pk,
  }) as Promise<[any, any]>;

/* ------------------------------------------------------ */

/*                        Formatted                       */

/* ------------------------------------------------------ */
const prepareProductData = (
  detail: Partial<ProductDetailType>,
  fullPricing: Record<string, any>,
  productTypeOptions: OptionType[],
  pricingKey: (keyof ProductDetailType)[]
) => {
  const data = {
    ...detail,
    ...detail.drug,
    ...detail.supply,
    active_flag: detail.active_flag ? 1 : ACTIVE_FLAGS.INACTIVE,
    full_pricings: [
      {
        ...fullPricing,
        end_date:
          typeof fullPricing.end_date === "string"
            ? beToAd(fullPricing.end_date)?.format(DATE_FORMAT)
            : undefined,
        start_date:
          typeof fullPricing.start_date === "string"
            ? beToAd(fullPricing.start_date)?.format(DATE_FORMAT)
            : undefined,
      },
    ],
    p_type: productTypeOptions.find((option: any) => option.value === detail.product_type)?.key,
  };

  if (detail.product_type === "DRUG") {
    data.stock_unit = detail.unit;
    data.base_unit = detail.unit;
    data.drug_name = detail.name;
  }

  for (const key of [...pricingKey, "drug", "supply", "product_type"]) {
    delete (data as any)[key];
  }

  if (Object.keys(data.drug_ingredients?.[0] || {}).length === 0) {
    data.drug_ingredients = [];
  }

  return data;
};

const getQuantityRange = (filter?: Partial<HistoryFilterType>) => {
  const isAllQuantity = filter?.isMoveIn && filter.isMoveOut;
  const isNotQuantity = !(filter?.isMoveIn || filter?.isMoveOut);

  let quantityGt: number | undefined;
  let quantityLt: number | undefined;

  if (!isAllQuantity) {
    if (filter?.isMoveIn || isNotQuantity) {
      quantityGt = -1;
    }

    if (filter?.isMoveOut || isNotQuantity) {
      quantityLt = -1;
    }
  }

  return { quantityGt, quantityLt };
};

/* ------------------------------------------------------ */

/*                          Utils                         */

/* ------------------------------------------------------ */
/**
 *
 * @param pTotal // * จำนวนรายการทั้งหมดของ api product
 * @param sTotal // * จำนวนรายการทั้งหมดของ api agg-stock
 */
const GetPagination = (pTotal = 0, sTotal = 0) => {
  // * start เริ่มต้น offset default ที่ 0
  const setOffsetApi = (api: "product" | "stock", total: number, start = 0) =>
    Array.from({ length: Math.ceil((total - start) / LIMIT) })
      .fill("")
      .map((unused, index) => {
        const offset = index * LIMIT + start;
        // * หากรายการที่เหลือน้อยกว่า limit ให้ set limit เท่ากับรายการที่เหลือ
        const limit = total - offset < LIMIT ? total - offset : LIMIT;

        return {
          api: [api],
          limit: [limit],
          offset: [offset],
        };
      });

  let sOffset = 0;

  const pagination = setOffsetApi("product", pTotal);

  const last: Partial<PaginationType> = pagination.slice(-1)[0] || {};

  const lastLimit = last.limit?.[0] || 0;

  // * product มีรายการอยู่ && offset สุดท้ายมีรายการน้อยกว่า limit && stock มีรายการอยู่
  if (pagination.length > 0 && lastLimit < LIMIT && !!sTotal) {
    sOffset = LIMIT - (last.limit?.[0] || 0);

    pagination[pagination.length - 1] = {
      api: [...(last.api || []), "stock"],
      limit: [...(last.limit || []), sOffset],
      offset: [...(last.offset || []), 0],
    };
  }

  if (!!sTotal && sTotal > sOffset) {
    pagination.push(...setOffsetApi("stock", sTotal, sOffset));
  }

  return pagination;
};

// * รวม product กับ stock โดย product ไว้บนสุด
const ConcatProductStockList = (
  productItems: Record<string, any>[] = [],
  stockItems: AggStockSerializer[] = []
) => {
  const updatedProductItems = productItems.map((item) => ({
    active_flag: item.active_flag,
    product: {
      id: item.id,
      code: item.code,
      name: item.name,
      name_en: item.name_en,
      p_type_name: item.p_type_name,
      unit_name: item.unit_name,
    },
    storage: {},
  }));

  return [...updatedProductItems, ...stockItems].slice(0, LIMIT);
};

const formatDatetime = (date: moment.Moment) =>
  `${date.format(DATE_FORMAT)}T${date.format("HH:mm")}+07`;

// ** exp_datetime ISO string
export const stockExpired = (datetime: string) => {
  const expirationDate = moment().add(EXPIRATION_MONTHS, "months");

  return datetime && moment(datetime).valueOf() < expirationDate.valueOf();
};

const groupItemsByProduct = (items: Partial<StockDetailType>[]): GroupDrugType => {
  const drug: Record<string, GroupDrugType[number]> = {};

  for (const item of items) {
    const productId = item.product?.id || "";
    const lotData = {
      expire_date: formatDate(item.expire_date),
      lot_no: item.lot_no || "",
      quantity: Number(item.quantity) || 0,
    };

    if (productId in drug) {
      const { length } = drug[productId].children;

      drug[productId].children.push({
        ...lotData,
        total: lotData.quantity + drug[productId].children[length - 1].total,
      });
    } else {
      drug[productId] = {
        children: [
          {
            ...lotData,
            total: lotData.quantity,
          },
        ],
        code: item.product?.code || "",
        name: item.product?.name || "",
      };
    }
  }

  return Object.values(drug);
};

const getRowSpanColumns = (items: RowSpanItem[]): RowSpanItem[] =>
  items.flatMap((item) =>
    item.children
      ? item.children.map((child, index) => ({
          ...child,
          code: index === 0 ? item.code : "",
          name: index === 0 ? item.name : "",
          rowSpan: index === 0 ? item.children?.length || 0 : 1,
        }))
      : [item]
  );
