import React, { forwardRef, RefObject, useImperativeHandle, useRef } from "react";
import { Icon, Tab, TabProps } from "semantic-ui-react";

// UI
import { MenuItem } from "./CardSurgeryTeam";
import CardAnesthesiaTeamUX from "./CardAnesthesiaTeamUX";
import SubAssistantsInput from "./SubAssistantsInput";
import SearchBoxWithKey from "react-lib/appcon/common/SearchBoxWithKey";

// Interface
import { AnesthesiaTeamType, SEARCH_KEYS, SetProp, shiftArrayWithKey } from "./sequence/ORRequest";

// Types
type CardAnesthesiaTeamProps = {
  onEvent: (e: any) => any;
  setProp: SetProp;
  // seq
  runSequence: any;
  // data
  teams: AnesthesiaTeamType[];
  activeIndex: number;
  // CommonInterface
  searchedItemListWithKey?: any;
  // callback
  onChangeValue: (e: any, data: any) => any;
  isReadOnly?: boolean;
};

export type CardAnesthesiaTeamRef = {
  containerRef: RefObject<HTMLDivElement>;
};

const CardAnesthesiaTeam = forwardRef<CardAnesthesiaTeamRef, CardAnesthesiaTeamProps>(
  (props, ref) => {
    const containerRef = useRef<HTMLDivElement | null>(null);

    useImperativeHandle(ref, () => ({
      containerRef,
    }));

    const handleChange = (key: string, index: number) => async (e: any, v: any) => {
      props.onChangeValue(e, { ...v, name: `${key}.${index}.${v.name}` });
    };

    const handleAddTeam = (e: any) => {
      props.runSequence({ sequence: "ORRequest", action: "anesthesia_team" });
    };

    const handleSelectTeam = (e: any, data: TabProps) => {
      if (props.teams?.length === Number(data.activeIndex)) {
        if (props.isReadOnly) {
          return;
        }
        handleAddTeam(e);
        return;
      }

      props.setProp("ORRequestSequence.activeAnesthesiaTeam", Number(data.activeIndex));
    };

    const handleDeleteTeam = async () => {
      const teams = props.teams || [];

      teams.splice(props.activeIndex, 1);

      const index = props.activeIndex - 2;

      await props.setProp("ORRequestSequence.activeAnesthesiaTeam", index < 0 ? 0 : index);

      const listWithKey = props.searchedItemListWithKey || {};

      shiftArrayWithKey(
        listWithKey,
        {
          [SEARCH_KEYS.DOCTOR_ANESTHESIOLOGIST]: "I",
          [SEARCH_KEYS.USER_ANESTHETIST]: "CI",
        },
        props.activeIndex + 1
      );

      props.setProp("searchedItemListWithKey", { ...listWithKey });
      props.setProp("ORRequestSequence.anesthesia_teams", [...teams]);
    };

    const handleUpdateAnesthetistSurgeon = (index: number) => (items: any[]) => {
      props.setProp(`ORRequestSequence.anesthesia_teams.${index}.anesthetists`, items);
    };

    const handleAnesthesiologist = (index: number) => async (value: any) => {
      props.setProp(`ORRequestSequence.anesthesia_teams.${index}.anesthesiologist`, value || null);
      // #props.setProp(`ORRequestSequence.anesthesia_teams.${index}.anesthesiologist`, item);
    };

    return (
      <div ref={containerRef} style={{ padding: "1rem", marginTop: "-1rem" }}>
        <Tab
          activeIndex={props.activeIndex}
          menu={{ secondary: true, pointing: true, color: "purple" }}
          onTabChange={handleSelectTeam}
          panes={[
            ...(props.teams || []).map((item, index: number) => ({
              menuItem: {
                key: `MenuItem-team${index}`,
                content: (
                  <MenuItem
                    index={index}
                    activeIndex={props.activeIndex}
                    title="Anesthesia Team"
                    // isStar={item.is_main}
                    color="#a333c8"
                  />
                ),
              },
              render: () => (
                <CardAnesthesiaTeamUX
                  // callback
                  onChangeValue={
                    props.isReadOnly ? () => {} : handleChange("anesthesia_teams", index)
                  }
                  onDeleteTeam={props.isReadOnly ? () => {} : handleDeleteTeam}
                  // Element
                  AnesthetistElement={
                    <SubAssistantsInput
                      // callback
                      onEvent={props.onEvent}
                      // data
                      index={index}
                      items={item.anesthetists || []}
                      type="User"
                      field="Anesthetist"
                      role="REGISTERED_NURSE"
                      searchedItemListWithKey={props.searchedItemListWithKey}
                      // setSelected={handleActionAnesthetistSurgeon(index)}
                      // onClick={handleActionAnesthetistSurgeon(index)}
                      onUpdated={handleUpdateAnesthetistSurgeon(index)}
                      isReadOnly={props.isReadOnly}
                    />
                  }
                  AnesthesiologistElement={
                    <SearchBoxWithKey
                      // callback
                      onEvent={props.onEvent}
                      type="Doctor"
                      id={`Anesthesiologist_${index + 1}`}
                      searchedItemListWithKey={props.searchedItemListWithKey}
                      selectedItem={item.anesthesiologist || null}
                      setSelected={handleAnesthesiologist(index)}
                      disabled={props.isReadOnly}
                    />
                  }
                />
              ),
            })),
            {
              menuItem: {
                key: "MenuItem-teams",
                content: (
                  <div>
                    <Icon name="add circle" color="purple" style={{ cursor: "pointer" }} />
                  </div>
                ),
              },
            },
          ]}
        />
      </div>
    );
  }
);

CardAnesthesiaTeam.displayName = "CardAnesthesiaTeam";

export default React.memo(CardAnesthesiaTeam);
