import React, { useState, useEffect, useMemo, useCallback } from "react";
import {
  Button,
  Checkbox,
  Dropdown,
  Form,
  Header,
  Icon,
  Input,
  ItemMeta,
  Label,
  Message,
  Modal,
  Radio,
  Select,
  TextArea,
  Divider,
} from "semantic-ui-react";
import CardMedReconcileUX from "./CardMedReconcileUX";
import SearchBox from "react-lib/appcon/common/SearchBox";
import { DateTextBox, TimeFreeTextBox24 } from "react-lib/apps/common";
import SnackMessage from "react-lib/apps/common/SnackMessage";
import PickerColumn from "react-lib/appcon/common/PickerColumn";
import EditNoteColumn from "react-lib/appcon/common/EditNoteColumn";
import ModMedicationSummaryUX from "./ModMedicationSummaryUX";
import CardMedReconcileLogsUX from "./CardMedReconcileLogsUX";
import { dateToStringBE } from "react-lib/utils/dateUtils";
import ModMedReconcileNeedRemark from "react-lib/apps/common/ModMedReconcileNeedRemark";
import { ModInfo, ErrorMessage, ModConfirm } from "react-lib/apps/common";
import { convertBEDateToSerial } from "react-lib/utils/tsUtils";
import ReactTable, { CellInfo } from "react-table-6";
import CONFIG from "config/config";

const CARD_MED_RECONCILE = "CardMedReconcile";
const DISCHARGE_STATUS = "DISCHARGE";

const CardMedReconcile = (props: any) => {
  const [selectDrugRow, setSelectDrugRow] = useState<any>(null);
  const [external, setExternal] = useState(false);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [externalType, setExternalType] = useState("custom_drug");
  const [externalId, setExternalId] = useState(null);
  const [externalDrug, setExternalDrug] = useState("");
  const [selectedDrug, setSelectedDrug] = useState<any>(null);
  const [qty, setQty] = useState("");
  const [dose, setDose] = useState<number | null>(null);
  const [unit, setUnit] = useState<any>(null);
  const [route, setRoute] = useState<any>(null);
  const [site, setSite] = useState<any>(null);
  const [frequency, setFrequency] = useState<any>(null);
  const [method, setMethod] = useState<any>(null);
  const [startDate, setStartDate] = useState<string | null>(null);
  const [startTime, setStartTime] = useState<string | null>(null);
  const [lastDate, setLastDate] = useState<string | null>(null);
  const [lastTime, setLastTime] = useState<string | null>(null);
  const [note, setNote] = useState("");
  const [unknowLastDose, setUnknowLastDose] = useState<boolean>(false);
  const [reason, setReason] = useState<string | null>(null);
  const [otherReason, setOtherReason] = useState<string | null>("");
  const [remark, setRemark] = useState<string>("");
  const [showMedList, setShowMedList] = useState<boolean>(false);
  const [selectedMedReconcile, setSelectedMedReconcile] = useState<any>(null);
  const [selectedIndex, setSelectedIndex] = useState<any>(null);
  const [selectedExpanded, setSelectedExpanded] = useState<any>([]);

  const [remarkErrorIdx, setRemarkErrorIdx] = useState<number>(-1);

  // const [internalDrugsSummary, setInternalDrugsSummary] = useState([]);
  // const [externalDrugsSummary, setExternalDrugsSummary] = useState([]);
  const [customDrugsSummary, setCustomDrugsSummary] = useState([]);

  const [enableZIndex, setEnableZIndex] = useState("");

  const externalTypeOptions = [
    // {
    //   key: "tmt_subs",
    //   value: "tmt_subs",
    //   text: "Substance",
    //   url: "/apis/TPD/substance/",
    // },
    // {
    //   key: "tmt_vtm",
    //   value: "tmt_vtm",
    //   text: "VTM",
    //   url: "/apis/TPD/virtual-therapeutic-moiety/",
    // },
    // {
    //   key: "tmt_gp",
    //   value: "tmt_gp",
    //   text: "Generic Product",
    //   url: "/apis/TPD/generic-product/",
    // },
    {
      key: "tmt_tp",
      value: "tmt_tp",
      text: "Generic/Trade Product",
      url: "/apis/TPD/trade-product/",
    },
    // {
    //   key: "tmt_gpu",
    //   value: "tmt_gpu",
    //   text: "Generic Product Use",
    //   url: "/apis/TPD/generic-product-use/",
    // },
    // {
    //   key: "tmt_tpu",
    //   value: "tmt_tpu",
    //   text: "Trade Product Use",
    //   url: "/apis/TPD/trade-product-use/",
    // },
    { key: "custom_drug", value: "custom_drug", text: "Custom", url: "" },
  ];

  const reasonOptions = [
    { key: 1, text: "-", value: "-" },
    { key: 2, text: "ผู้ป่วยไม่ได้ทานยา", value: "ผู้ป่วยไม่ได้ทานยา" },
    { key: 3, text: "ไม่สามารถระบุได้", value: "ไม่สามารถระบุได้" },
    { key: 4, text: "อื่นๆ", value: "etc" },
  ];

  useEffect(() => {
    console.log("CardMedReconcile props: ", props);
    if (props.MedReconcileSequence?.sequenceIndex === "Edit") {
      props.onEvent({
        message: "GetMedReconcile",
        params: { encounter: props.selectedEncounter, didMount: true },
      });
    }

    return () => {
      document.onclick = null; // Issue 58969
    };
  }, [props.MedReconcileSequence?.sequenceIndex]);

  useEffect(() => {
    return () => {
      // Clear All medreconcile Data
      props.onEvent({
        message: "ClearMedReconcile",
        params: { card: CARD_MED_RECONCILE },
      });
    };
  }, []);

  useEffect(() => {
    // console.log("props.userProfile: ", props.userProfile);
    if (props.userProfile?.username) {
      setUsername(props.userProfile?.username);
    }
  }, [props.userProfile]);

  useEffect(() => {
    if (
      selectedDrug?.id &&
      (!props.selectedExternalDrug || selectedDrug.id !== props.selectedExternalDrug?.id)
    ) {
      props.onEvent({
        message: "HandleGetDetailForExternalDrug",
        params: { card: CARD_MED_RECONCILE, selectedDrug: selectedDrug.id },
      });
    }
  }, [selectedDrug]);

  useEffect(() => {
    if (!unit && props.selectedExternalDrug?.default_dosage_unit) {
      setUnit(props.selectedExternalDrug?.default_dosage_unit);
    }
    if (!route && props.selectedExternalDrug?.default_route) {
      setRoute(props.selectedExternalDrug?.default_route);
    }
    if (!site && props.selectedExternalDrug?.default_site) {
      setSite(props.selectedExternalDrug?.default_site);
    }
    if (!frequency && props.selectedExternalDrug?.default_frequency) {
      setFrequency(props.selectedExternalDrug?.default_frequency);
    }
    if (!method && props.selectedExternalDrug?.default_method) {
      setMethod(props.selectedExternalDrug?.default_method);
    }
  }, [props.selectedExternalDrug]);

  useEffect(() => {
    if (props.medReconcile) {
      setSelectedMedReconcile(props.medReconcile);
    }
  }, [props.medReconcile]);

  useEffect(() => {
    if (props.successMessage?.[CARD_MED_RECONCILE] === "รับทราบ Med Reconcile : บันทึกสำเร็จ") {
      setRemark("");
    }
  }, [props.successMessage]);

  const resetExternal = () => {
    console.log("resetExternal: ");
    props.setProp("selectedExternalDrug", {});
    setSelectDrugRow(null);
    setSelectedIndex(null);
    setExternalId(null);
    setExternalType("custom_drug");
    setExternalDrug("");
    setSelectedDrug(null);
    setQty("");
    setDose(1);
    setUnit(null);
    setRoute(null);
    setSite(null);
    setFrequency(null);
    setMethod(null);
    setStartDate(null);
    setStartTime(null);
    setLastDate(null);
    setLastTime(null);
    setNote("");
    setUnknowLastDose(false);
    setReason(null);
    setOtherReason("");
  };

  const acknowledge =
    (role: string, is_ipd: boolean = false) =>
    () => {
      // console.log(role, is_ipd);
      props.onEvent({
        message: "SetMedReconcile",
        params: {
          action: "acknowledge",
          role,
          is_ipd,
        },
      });
    };

  const handleSelectedInternalItem =
    (id: number) =>
    (e: any, { checked }: any = {}) => {
      let selectedInternalItems = [...(props.medReconcileInternalCheckedItem || [])];
      if (selectedInternalItems.includes(id)) {
        selectedInternalItems = selectedInternalItems.filter((item: any) => item !== id);
      } else {
        selectedInternalItems.push(id);
      }
      props.setProp("medReconcileInternalCheckedItem", selectedInternalItems);
    };

  const handleSetOptionMaster = (limited: string, field: string) => {
    if (props.selectedExternalDrug?.[limited]?.length > 0) {
      return (
        props.masterOptions?.[field]?.filter((item: any) =>
          props.selectedExternalDrug?.[limited].includes(item.value)
        ) || []
      );
    }
    return props.masterOptions?.[field] || [];
  };

  const medReconcileTypeOptions = useMemo(() => {
    if (props.selectedEncounter?.type === "OPD") {
      return props.masterOptions?.medReconcileType || [{ key: 1, text: "OPD", value: "OPD" }];
    } else {
      return (
        props.masterOptions?.medReconcileType || [
          { key: 1, text: "OPD", value: "OPD" },
          { key: 2, text: "IPD Admit แรกรับ", value: "IPD_ADMIT" },
          { key: 3, text: "Transfer", value: "TRANSFER" },
          { key: 4, text: "Discharge", value: "DISCHARGE" },
        ]
      );
    }
  }, [props.masterOptions?.medReconcileType, props.selectedEncounter?.type]);

  const drugOrderHistoryItems = useMemo(() => {
    // console.log("recalculate drugOrderHistoryItems props.drugOrderHistoryItems :", props.drugOrderHistoryItems);
    // console.log("recalculate drugOrderHistoryItems props.medReconcile?.items: ", props.medReconcile?.items );
    let temp = (props.drugOrderHistoryItems || []).map((item: any) => {
      // let isFoundDuplicated = (props.medReconcile?.items || []).find(
      //   (medItem: any) => medItem?.code === item?.code
      // );
      // console.log('isFoundDuplicated: ', isFoundDuplicated);
      return {
        ...item,
        _add: (
          <Checkbox
            checked={props.medReconcileInternalCheckedItem.includes(item.id)}
            onChange={handleSelectedInternalItem(item.id)}
            // disabled={isFoundDuplicated}
          />
          // <Button
          //   color="green"
          //   size="tiny"
          //   onClick={(e: any) => {
          //     e.stopPropagation();
          //     props.onEvent({
          //       message: "SetMedReconcile",
          //       params: {
          //         action: "addOrderItem",
          //         item: item,
          //       },
          //     });
          //   }}
          // >
          //   เพิ่มรายการ
          // </Button>
        ),
      };
    });
    console.log("temp: ", temp);
    return temp;
  }, [
    props.drugOrderHistoryItems,
    props.medReconcileInternalCheckedItem,
    props.medReconcile?.items,
  ]);

  // const internalItems = useMemo(() => {
  //   return (
  //     props.medReconcile?.items.filter(
  //       (item: any) => !item.is_external && item.active
  //     ) || []
  //   ).map((item: any, index: number) => ({
  //     ...item,
  //     _action: (
  //       <div
  //         style={{
  //           display: "flex",
  //           justifyContent: "space-between",
  //           alignItems: "center",
  //         }}
  //       >
  //         <Radio
  //           checked={item.status === "OFF"}
  //           onChange={(e: any, { checked }: any = {}) => {
  //             props.onEvent({
  //               message: "SetMedReconcile",
  //               params: {
  //                 action: "checked",
  //                 index: index,
  //                 status: "OFF",
  //                 isExternal: false,
  //               },
  //             });
  //           }}
  //         />
  //         <Radio
  //           checked={item.status === "HOLD"}
  //           onChange={(e: any, { checked }: any = {}) => {
  //             props.onEvent({
  //               message: "SetMedReconcile",
  //               params: {
  //                 action: "checked",
  //                 index: index,
  //                 status: "HOLD",
  //                 isExternal: false,
  //               },
  //             });
  //           }}
  //         />
  //         <Radio
  //           checked={item.status === "CONTINUE"}
  //           onChange={(e: any, { checked }: any = {}) => {
  //             props.onEvent({
  //               message: "SetMedReconcile",
  //               params: {
  //                 action: "checked",
  //                 index: index,
  //                 status: "CONTINUE",
  //                 isExternal: false,
  //               },
  //             });
  //           }}
  //         />
  //       </div>
  //     ),
  //     remove: (
  //       <Button
  //         color="red"
  //         size="tiny"
  //         onClick={(e: any) => {
  //           e.stopPropagation();
  //           props.onEvent({
  //             message: "SetMedReconcile",
  //             params: {
  //               action: "deleteOrderItem",
  //               item: item,
  //               card: CARD_MED_RECONCILE,
  //             },
  //           });
  //         }}
  //       >
  //         ลบรายการ
  //       </Button>
  //     ),
  //   }));
  // }, [props.medReconcile?.items]);

  // const externalItems = useMemo(() => {
  //   return (
  //     props.medReconcile?.items?.filter(
  //       (item: any) => item.is_external && item.active
  //     ) || []
  //   ).map((item: any, index: number) => ({
  //     ...item,
  //     typeName: externalTypeOptions.find(
  //       (option: any) => option.value === item.target_type
  //     )?.text,

  //     remove: (
  //       <Button
  //         color="red"
  //         size="tiny"
  //         onClick={(e: any) => {
  //           e.stopPropagation();
  //           props.onEvent({
  //             message: "SetMedReconcile",
  //             params: {
  //               action: "deleteExternalItem",
  //               index: index,
  //             },
  //           });
  //         }}
  //       >
  //         ลบรายการ
  //       </Button>
  //     ),
  //   }));
  // }, []);
  const handleOnExpandedItems = (selectedOrderId: number) => {
    let expandedItem = [...(selectedExpanded || [])];
    if (expandedItem.includes(selectedOrderId)) {
      expandedItem = expandedItem.filter((item: any) => item !== selectedOrderId);
    } else {
      expandedItem.push(selectedOrderId);
    }
    setSelectedExpanded(expandedItem);
  };

  const medReconcileIPDAdmitItem = useMemo(() => {
    console.log("useMemo medReconcileIPDAdmitItem: ");

    let showItems: any = [];
    (props.medReconcileIPDAdmitItem || []).forEach((item: any) => {
      showItems.push({
        ...item,
        _add: (
          <Checkbox
            checked={props.medReconcileIPDAdmitSelectOrder?.includes(item.id)}
            onChange={() => {
              props.runSequence({
                sequence: "MedReconcile",
                action: "selected_drug_in_ipd_admit",
                card: CARD_MED_RECONCILE,
                orderId: item.id,
              });
            }}
          />
        ),
        code: <div style={{ width: "0px" }}></div>,
        nameDiv: (
          <div style={{ display: "flex", alignItems: "center" }}>
            {selectedExpanded?.includes(item.id) ? (
              <Icon
                name="triangle down"
                onClick={() => {
                  handleOnExpandedItems(item.id);
                }}
              />
            ) : (
              <Icon
                name="triangle right"
                onClick={() => {
                  handleOnExpandedItems(item.id);
                }}
              />
            )}
            <div style={{ marginRight: "10px" }}>{item.med_reconcile_label}</div>
          </div>
        ),
      });
      if (selectedExpanded?.includes(item.id)) {
        showItems = showItems.concat(
          (item?.children || []).map((drug: any) => ({
            ...drug,
            _add: (
              <Checkbox
                checked={props.medReconcileIPDAdmitSelectItem?.includes(drug.id)}
                onChange={() => {
                  props.runSequence({
                    sequence: "MedReconcile",
                    action: "selected_drug_in_ipd_admit",
                    card: CARD_MED_RECONCILE,
                    orderId: item.id,
                    itemId: drug.id,
                  });
                }}
              />
            ),
            nameDiv: drug.name,
            start_date_text: drug.start_date,
            start_time_text: drug.start_time,
            last_date_text: drug.last_dose_date,
            last_time_text: drug.last_dose_time,
            _action_hold: <Radio checked={drug.status === "HOLD"} />,
            _action_off: <Radio checked={drug.status === "OFF"} />,
            _action_cont: <Radio checked={drug.status === "CONTINUE"} />,
          }))
        );
      }
    });
    console.log("showItems: ", showItems);
    return showItems;
  }, [
    props.medReconcileIPDAdmitItem,
    selectedExpanded,
    props.medReconcileIPDAdmitSelectItem,
    props.medReconcileIPDAdmitSelectOrder,
  ]);

  // const rowStyling = (row: any) => {
  //   const targetField = ["code", "name", "label", "quantity"];
  //   let styling = {};
  //   if (!(row.acknowledge_by_users?.length > 0)) {
  //     styling = { color: "red" };
  //   }
  //   if (!row.active) {
  //     styling = { ...styling, textDecoration: "line-through" };
  //   }
  //   targetField.forEach((field: string) => {
  //     if (row?.[field] !== undefined) {
  //       // console.log("here are styling: ", styling, row[field])
  //       row = { ...row, [field]: <div style={styling}>{row[field]}</div> };
  //     }
  //   });
  //   return row;
  // };

  const medicationItems = useMemo(() => {
    console.log("medicationItems useMemo props.medReconcile?.items: ", props.medReconcile?.items);
    let temp: any = [];
    // handle for case index not match the item
    props.medReconcile?.items?.forEach((item: any, index: number) => {
      let rowItem = {
        ...item,
        isExternal: (
          <div
            style={{
              textAlign: "center",
              display: "flex",
              justifyContent: "center",
            }}
            onClick={(e: any) => {
              if (
                props.medReconcile?.med_reconciliation_type === "DISCHARGE" &&
                item?.is_from_mr_ipd === false &&
                item?.is_suggestion === false
              ) {
                e.stopPropagation();
                console.log("Home IPD Click");
                props.setProp("goToMenu", "Med Order");
                props.setProp("goToTab", "HOME_IPD");
                props.setProp("drugMedReconcileEditId", item.order_item);
              }
            }}
          >
            {props.medReconcile?.med_reconciliation_type === "DISCHARGE" ? (
              item.is_from_mr_ipd ? (
                <Label color={"green"}>MR IPD แรกรับ</Label>
              ) : item.is_suggestion ? (
                <Label color={"blue"}>Suggestion</Label>
              ) : (
                <Label color={"purple"}>Home IPD</Label>
              )
            ) : item.is_external ? (
              <Label color={"orange"}>External</Label>
            ) : (
              <Label color={"blue"}>Internal</Label>
            )}
          </div>
        ),
        code: item.code || "",
        // <div style={{ textAlign: "center" }}>
        //   {(item.is_external || item.is_suggestion) && item.code ? (
        //     <Label
        //       color={item.is_suggestion ? "blue" : "orange"}
        //       content={item.code}
        //     />
        //   ) : (
        //     item.code || ""
        //   )}
        // </div>
        _action_off: (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Radio
              checked={item.status === "OFF"}
              disabled={!props.django?.user?.role_types?.includes("DOCTOR")}
              onChange={(e: any, { checked }: any = {}) => {
                props.onEvent({
                  message: "SetMedReconcile",
                  params: {
                    action: "checked",
                    index: index,
                    item: item,
                    status: "OFF",
                  },
                });
              }}
            />
          </div>
        ),
        _action_hold: (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Radio
              checked={item.status === "HOLD"}
              disabled={!props.django?.user?.role_types?.includes("DOCTOR")}
              onChange={(e: any, { checked }: any = {}) => {
                props.onEvent({
                  message: "SetMedReconcile",
                  params: {
                    action: "checked",
                    index: index,
                    item: item,
                    status: "HOLD",
                  },
                });
              }}
            />
          </div>
        ),
        _action_cont: (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Radio
              checked={item.status === "CONTINUE"}
              disabled={!props.django?.user?.role_types?.includes("DOCTOR")}
              onChange={(e: any, { checked }: any = {}) => {
                props.onEvent({
                  message: "SetMedReconcile",
                  params: {
                    action: "checked",
                    index: index,
                    item: item,
                    status: "CONTINUE",
                  },
                });
              }}
            />
          </div>
        ),
        // _action: (
        //   <div
        //     style={{
        //       display: "flex",
        //       justifyContent: "space-between",
        //       alignItems: "center",
        //     }}
        //   >
        //   </div>
        // ),
        edit_time: (
          <div style={{ display: "flex", justifyContent: "center" }}>
            {dateToStringBE(item.edited)}
          </div>
        ),
        btn_note: (
          <EditNoteColumn
            value={item.note}
            onDataChanged={(note: string) => {
              setNote(note);

              props.onEvent({
                message: "SetMedReconcile",
                params: {
                  action: "change_note",
                  index: index,
                  item: item,
                  value: note,
                  name: "note",
                },
              });
            }}
          />
        ),
        _is_cont: (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Radio
              checked={item.take_medicine_status === "ACTIVE"}
              disabled={!props.django?.user?.role_types?.includes("DOCTOR")}
              onChange={(e: any, { checked }: any = {}) => {
                props.runSequence({
                  sequence: "MedReconcile",
                  card: CARD_MED_RECONCILE,
                  action: "change_status",
                  index,
                  checked,
                  status: "ACTIVE",
                });
              }}
            />
          </div>
        ),
        _is_stop: (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Radio
              checked={item.take_medicine_status === "OFF"}
              disabled={!props.django?.user?.role_types?.includes("DOCTOR")}
              onChange={(e: any, { checked }: any = {}) => {
                props.runSequence({
                  sequence: "MedReconcile",
                  card: CARD_MED_RECONCILE,
                  action: "change_status",
                  index,
                  checked,
                  status: "OFF",
                });
              }}
            />
          </div>
        ),
      };
      console.log("rowItem: ", rowItem);
      if (
        // item.active &&
        props.medReconcile?.med_reconciliation_type !== "DISCHARGE"
      ) {
        // console.log(
        //   "  !== DISCHARGE and props.checkedIPDHome: ",
        //   props.checkedIPDHome
        // );

        // if (props.checkIPDHome) {
        console.log(
          "props.medReconcile?.med_reconciliation_type !== DISCHARG checkedIPDHome ",
          props.checkedIPDHome
        );
        temp.push({
          ...rowItem,
          start_date_text: (
            <PickerColumn
              value={item.start_date}
              type="date"
              clearable={false}
              position="bottom right"
              changeOnBlur={false}
              onDataChanged={(value: string, humanAction: boolean) => {
                props.onEvent({
                  message: "SetMedReconcile",
                  params: {
                    action: "change_date_time",
                    index: index,
                    item: item,
                    name: "start_date",
                    humanAction: humanAction,
                    value: value,
                  },
                });
              }}
            />
          ),
          start_time_text: (
            <PickerColumn
              value={item.start_time}
              type="time"
              clearable={false}
              position="bottom right"
              changeOnBlur={false}
              onDataChanged={(value: string, humanAction: boolean) => {
                props.onEvent({
                  message: "SetMedReconcile",
                  params: {
                    action: "change_date_time",
                    index: index,
                    item: item,
                    name: "start_time",
                    humanAction: humanAction,
                    value: value,
                  },
                });
              }}
            />
          ),
          last_date_text: (
            <PickerColumn
              value={item.last_dose_date}
              type="date"
              clearable={false}
              position="bottom right"
              changeOnBlur={false}
              onDataChanged={(value: string, humanAction: boolean) => {
                props.onEvent({
                  message: "SetMedReconcile",
                  params: {
                    action: "change_date_time",
                    index: index,
                    item: item,
                    name: "last_dose_date",
                    humanAction: humanAction,
                    value: value,
                  },
                });
              }}
            />
          ),
          last_time_text: (
            <PickerColumn
              value={item.last_dose_time}
              type="time"
              clearable={false}
              position="bottom right"
              changeOnBlur={false}
              onDataChanged={(value: string, humanAction: boolean) => {
                props.onEvent({
                  message: "SetMedReconcile",
                  params: {
                    action: "change_date_time",
                    index: index,
                    item: item,
                    name: "last_dose_time",
                    humanAction: humanAction,
                    value: value,
                  },
                });
              }}
            />
          ),
          delete: (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Button
                icon
                color={item.active ? "red" : "teal"}
                size="mini"
                onClick={(e: any) => {
                  e.stopPropagation();
                  props.onEvent({
                    message: "SetMedReconcile",
                    params: {
                      action: "deleteExternalItem",
                      index: index,
                      item: item,
                    },
                  });
                }}
              >
                <Icon name={item.active ? "trash" : "redo"} />
              </Button>
            </div>
          ),
        });
        // }
      } else if (props.medReconcile?.med_reconciliation_type === "DISCHARGE") {
        console.log(" discharge rowItem: ", rowItem);
        console.log(" discharge rowItem.name: ", rowItem.name);
        // let styling = {};
        // if (!(item.acknowledge_by_users?.length > 0)) {
        //   styling = { color: "red" };
        // }
        // if (!item.active) {
        //   styling = { ...styling, textDecoration: "line-through" };
        // }
        let rowDischarge = {
          ...rowItem,
          // ...rowStyling({ ...item }),
          start_date_text: (
            <PickerColumn
              value={item.start_date}
              type="date"
              clearable={false}
              // textStyle={styling}
              position="bottom right"
              changeOnBlur={false}
              onDataChanged={(value: string, humanAction: boolean) => {
                props.onEvent({
                  message: "SetMedReconcile",
                  params: {
                    action: "change_date_time",
                    index: index,
                    item: item,
                    name: "start_date",
                    humanAction: humanAction,
                    value: value,
                  },
                });
              }}
            />
          ),
          start_time_text: (
            <PickerColumn
              value={item.start_time}
              type="time"
              // textStyle={styling}
              clearable={false}
              position="bottom right"
              changeOnBlur={false}
              onDataChanged={(value: string, humanAction: boolean) => {
                props.onEvent({
                  message: "SetMedReconcile",
                  params: {
                    action: "change_date_time",
                    index: index,
                    item: item,
                    name: "start_time",
                    humanAction: humanAction,
                    value: value,
                  },
                });
              }}
            />
          ),
          last_date_text: (
            <PickerColumn
              value={item.last_dose_date}
              type="date"
              // textStyle={styling}
              clearable={false}
              position="bottom right"
              changeOnBlur={false}
              onDataChanged={(value: string, humanAction: boolean) => {
                props.onEvent({
                  message: "SetMedReconcile",
                  params: {
                    action: "change_date_time",
                    index: index,
                    item: item,
                    name: "last_dose_date",
                    humanAction: humanAction,
                    value: value,
                  },
                });
              }}
            />
          ),
          last_time_text: (
            <PickerColumn
              value={item.last_dose_time}
              type="time"
              // textStyle={styling}
              clearable={false}
              position="bottom right"
              changeOnBlur={false}
              onDataChanged={(value: string, humanAction: boolean) => {
                props.onEvent({
                  message: "SetMedReconcile",
                  params: {
                    action: "change_date_time",
                    index: index,
                    item: item,
                    name: "last_dose_time",
                    humanAction: humanAction,
                    value: value,
                  },
                });
              }}
            />
          ),
          delete:
            props.medReconcile?.med_reconciliation_type === "DISCHARGE" &&
            !item.is_from_mr_ipd &&
            !item.is_suggestion ? (
              <></>
            ) : (
              <div style={{ display: "flex", justifyContent: "center" }}>
                {item.active ? (
                  <Button
                    icon
                    color="red"
                    size="mini"
                    onClick={(e: any) => {
                      e.stopPropagation();
                      props.runSequence({
                        sequence: "MedReconcile",
                        card: CARD_MED_RECONCILE,
                        action: "delete_item",
                        index,
                      });
                    }}
                  >
                    <Icon name="trash" />
                  </Button>
                ) : (
                  <Button
                    icon
                    color="teal"
                    size="mini"
                    onClick={(e: any) => {
                      e.stopPropagation();
                      props.runSequence({
                        sequence: "MedReconcile",
                        card: CARD_MED_RECONCILE,
                        action: "delete_item",
                        index,
                      });
                    }}
                  >
                    <Icon name="refresh" />
                  </Button>
                )}
              </div>
            ),
        };

        console.log(" rowDischarge", rowDischarge);
        // case HomeIPD
        if (props.checkIPDHome && !item.is_from_mr_ipd && !item.is_suggestion) {
          temp.push({ ...rowDischarge });
          // case MR Admit
        } else if (props.checkMRAdmit && item.is_from_mr_ipd) {
          temp.push({ ...rowDischarge });
          // case add other drug
        } else if (props.checkSuggestion && item.is_suggestion && !item.is_from_mr_ipd) {
          temp.push({ ...rowDischarge });
        }
        // temp.push({
        //   )

        // console.log("medicationItems useMemo temp", temp);
      }
    });
    // let temp = (props.medReconcile?.items || [])
    //   .filter((item: any) => item.active)
    //   .map((item: any, index: number) => ());
    console.log("medicationItems useMemo temp: ", temp);
    return temp;
  }, [props.medReconcile?.items, props.checkIPDHome, props.checkMRAdmit, props.checkSuggestion]);

  ///
  /// Med Reconcile Transfer
  ///
  const transferOneDayData = useMemo(() => {
    console.log(
      " transferOneDayData useMemo props.medReconcile?.items_current_one_day: ",
      props.medReconcile?.items_current_one_day
    );
    let a = (props.medReconcile?.items_current_one_day || []).map((item: any, index: number) => ({
      ...item,
      type: item.is_external ? (
        <Label color={"orange"}>External</Label>
      ) : (
        <Label color={"blue"}>Internal</Label>
      ),
      lastDoseDate: (
        <PickerColumn
          value={item.last_dose_date}
          type="date"
          clearable={false}
          position="bottom right"
          changeOnBlur={false}
          onDataChanged={(value: string, humanAction: boolean) => {
            props.runSequence({
              sequence: "MedReconcile",
              card: CARD_MED_RECONCILE,
              action: "changeDateTime",
              index: index,
              item: item,
              name: "last_dose_date",
              value: value,
              type: "items_current_one_day",
              humanAction: humanAction,
            });
          }}
        />
      ),
      lastDoseTime: (
        <PickerColumn
          value={item.last_dose_time}
          type="time"
          clearable={false}
          position="bottom right"
          changeOnBlur={false}
          onDataChanged={(value: string, humanAction: boolean) => {
            props.runSequence({
              sequence: "MedReconcile",
              card: CARD_MED_RECONCILE,
              action: "changeDateTime",
              index: index,
              item: item,
              name: "last_dose_time",
              value: value,
              type: "items_current_one_day",
              humanAction: humanAction,
            });
          }}
        />
      ),
      startDate: (
        <PickerColumn
          value={item.start_date}
          type="date"
          clearable={false}
          position="bottom right"
          changeOnBlur={false}
          onDataChanged={(value: string, humanAction: boolean) => {
            props.runSequence({
              sequence: "MedReconcile",
              card: CARD_MED_RECONCILE,
              action: "changeDateTime",
              index: index,
              item: item,
              name: "start_date",
              value: value,
              type: "items_current_one_day",
              humanAction: humanAction,
            });
          }}
        />
      ),
      startTime: (
        <PickerColumn
          value={item.start_time}
          type="time"
          clearable={false}
          position="bottom right"
          changeOnBlur={false}
          onDataChanged={(value: string, humanAction: boolean) => {
            props.runSequence({
              sequence: "MedReconcile",
              card: CARD_MED_RECONCILE,
              action: "changeDateTime",
              index: index,
              item: item,
              name: "start_time",
              value: value,
              type: "items_current_one_day",
              humanAction: humanAction,
            });
          }}
        />
      ),
      noteIcon: (
        <EditNoteColumn
          value={item.note}
          onDataChanged={(note: string) => {
            // setNote(note);

            props.runSequence({
              sequence: "MedReconcile",
              card: CARD_MED_RECONCILE,
              action: "editNote",
              index: index,
              item: item,
              note: note,
              type: "items_current_one_day",
            });

            // props.onEvent({
            //   message: "SetMedReconcile",
            //   params: {
            //     action: "change_note",
            //     index: index,
            //     item: item,
            //     value: note,
            //     name: "note",
            //   },
            // });
          }}
        />
      ),
      on: (
        <Radio
          checked={item.status === "ON"}
          disabled={!props.django?.user?.role_types?.includes("DOCTOR")}
          onChange={(e: any, { checked }: any = {}) => {
            props.runSequence({
              sequence: "MedReconcile",
              card: CARD_MED_RECONCILE,
              action: "changeActionTransfer",
              index: index,
              item: item,
              status: "ON",
              type: "items_current_one_day",
            });
          }}
        />
      ),
      off: (
        <Radio
          disabled={!props.django?.user?.role_types?.includes("DOCTOR")}
          checked={item.status === "OFF"}
          onChange={(e: any, { checked }: any = {}) => {
            props.runSequence({
              sequence: "MedReconcile",
              card: CARD_MED_RECONCILE,
              action: "changeActionTransfer",
              index: index,
              item: item,
              status: "OFF",
              type: "items_current_one_day",
            });
          }}
        />
      ),
    }));

    console.log("transferDrugOneDay: ", a);
    return a;
  }, [props.medReconcile?.items_current_one_day]);

  const transferContData = useMemo(() => {
    console.log(
      "transferContData props.medReconcile?.items_current_continue: ",
      props.medReconcile?.items_current_continue
    );
    let a = (props.medReconcile?.items_current_continue || []).map((item: any, index: number) => {
      let isStatusEnabled =
        !props.django?.user?.role_types?.includes("DOCTOR") ||
        item.active == false ||
        item.status === "OFF";

      return {
        ...item,
        type: item.is_external ? (
          <Label color={"orange"}>External</Label>
        ) : (
          <Label color={"blue"}>Internal</Label>
        ),
        lastDoseDate: item.is_no_last_dose_date ? (
          item.last_dose_date
        ) : (
          <PickerColumn
            value={item.last_dose_date}
            type="date"
            clearable={false}
            position="bottom right"
            changeOnBlur={false}
            onDataChanged={(value: string, humanAction: boolean) => {
              props.runSequence({
                sequence: "MedReconcile",
                card: CARD_MED_RECONCILE,
                action: "changeDateTime",
                index: index,
                item: item,
                name: "last_dose_date",
                value: value,
                type: "items_current_continue",
                humanAction: humanAction,
              });
            }}
          />
        ),
        lastDoseTime: (
          <PickerColumn
            value={item.last_dose_time}
            type="time"
            clearable={false}
            position="bottom right"
            changeOnBlur={false}
            onDataChanged={(value: string, humanAction: boolean) => {
              props.runSequence({
                sequence: "MedReconcile",
                card: CARD_MED_RECONCILE,
                action: "changeDateTime",
                index: index,
                item: item,
                name: "last_dose_time",
                value: value,
                type: "items_current_continue",
                humanAction: humanAction,
              });
            }}
          />
        ),

        startDate: (
          <PickerColumn
            value={item.start_date}
            type="date"
            clearable={false}
            position="bottom right"
            changeOnBlur={false}
            onDataChanged={(value: string, humanAction: boolean) => {
              props.runSequence({
                sequence: "MedReconcile",
                card: CARD_MED_RECONCILE,
                action: "changeDateTime",
                index: index,
                item: item,
                name: "start_date",
                value: value,
                type: "items_current_continue",
                humanAction: humanAction,
              });
            }}
          />
        ),
        startTime: (
          <PickerColumn
            value={item.start_time}
            type="time"
            clearable={false}
            position="bottom right"
            changeOnBlur={false}
            onDataChanged={(value: string, humanAction: boolean) => {
              props.runSequence({
                sequence: "MedReconcile",
                card: CARD_MED_RECONCILE,
                action: "changeDateTime",
                index: index,
                item: item,
                name: "start_time",
                value: value,
                type: "items_current_continue",
                humanAction: humanAction,
              });
            }}
          />
        ),

        noteIcon: (
          <EditNoteColumn
            value={item.note}
            onDataChanged={(note: string) => {
              // setNote(note);

              props.runSequence({
                sequence: "MedReconcile",
                card: CARD_MED_RECONCILE,
                action: "editNote",
                index: index,
                item: item,
                note: note,
                type: "items_current_continue",
              });

              // props.onEvent({
              //   message: "SetMedReconcile",
              //   params: {
              //     action: "change_note",
              //     index: index,
              //     item: item,
              //     value: note,
              //     name: "note",
              //   },
              // });
            }}
          />
        ),

        off: (
          <Radio
            checked={item.status === "OFF"}
            disabled={isStatusEnabled}
            onChange={(e: any, { checked }: any = {}) => {
              props.runSequence({
                sequence: "MedReconcile",
                card: CARD_MED_RECONCILE,
                action: "changeActionTransfer",
                index: index,
                item: item,
                status: "OFF",
                type: "items_current_continue",
              });
            }}
          />
        ),
        hold: (
          <Radio
            checked={item.status === "HOLD"}
            disabled={isStatusEnabled}
            onChange={(e: any, { checked }: any = {}) => {
              props.runSequence({
                sequence: "MedReconcile",
                card: CARD_MED_RECONCILE,
                action: "changeActionTransfer",
                index: index,
                item: item,
                status: "HOLD",
                type: "items_current_continue",
              });
            }}
          />
        ),
        continue: (
          <Radio
            checked={item.status === "CONTINUE"}
            disabled={isStatusEnabled}
            onChange={(e: any, { checked }: any = {}) => {
              props.runSequence({
                sequence: "MedReconcile",
                card: CARD_MED_RECONCILE,
                action: "changeActionTransfer",
                index: index,
                item: item,
                status: "CONTINUE",
                type: "items_current_continue",
              });
            }}
          />
        ),
      };
    });
    console.log("transferDrugContinue: ", a);
    return a;
  }, [props.medReconcile?.items_current_continue]);

  const transferOneDayTrProps = useCallback(
    (state: any, rowInfo: any, column: any, instance: any) => {
      return {
        style: {
          ...(rowInfo?.original?.has_changed && { color: "red" }),
          ...(!rowInfo?.original?.active && { textDecoration: "line-through" }),
        },
      };
    },
    []
  );

  const transferContTrProps = useCallback(
    (state: any, rowInfo: any, column: any, instance: any) => {
      console.log("transferContTrProps rowInfo: ", rowInfo);
      return {
        style: {
          ...(rowInfo?.original?.has_changed && { color: "red" }),
          ...(!rowInfo?.original?.active && { textDecoration: "line-through" }),
        },
      };
    },
    []
  );

  const transferOneDayTdProps = useCallback(
    (state: any, rowInfo: any, column: any, instance: any) => {
      let keys = [
        "type",
        "quantity",
        "start_date",
        "start_time",
        "last_dose_date",
        "last_dose_time",
        "off",
        "on",
      ];
      if (keys.includes(column.id)) {
        return {
          style: {
            textAlign: "center",
          },
        };
      } else {
        return {};
      }
    },
    []
  );

  const transferContTdProps = useCallback(
    (state: any, rowInfo: any, column: any, instance: any) => {
      let keys = [
        "type",
        "quantity",
        "start_date",
        "start_time",
        "last_dose_date",
        "last_dose_time",
        "off",
        "hold",
        "continue",
      ];
      if (keys.includes(column.id)) {
        return {
          style: {
            textAlign: "center",
          },
        };
      } else {
        return {};
      }
    },
    []
  );

  const handleSelectedRow = (rowInfo: any) => {
    console.log("handleSelectedRow rowInfo: ", rowInfo);
    setExternal(true);
    const row = rowInfo?.original || {};
    setSelectDrugRow(row);
    setSelectedIndex(rowInfo?.index);
    setExternalId(row.id);
    setExternalType(
      row?.is_external ? (row?.target_type || row?.product ? "tmt_tp" : "custom_drug") : "tmt_tp"
    );
    setExternalDrug(row.target_name || row.name);
    setQty(row.quantity);
    setStartDate(row.start_date);
    setStartTime(row.start_time);
    setLastDate(row.last_dose_date);
    setLastTime(row.last_dose_time);
    setNote(row.note);
    setDose(row.dose);
    setUnit(row.unit);
    setRoute(row.route);
    setSite(row.site);
    setFrequency(row.frequency);
    setMethod(row.method);
    setUnknowLastDose(row.is_no_last_dose_date);
    if (row.is_no_last_dose_date) {
      if (reasonOptions.map((item: any) => item.value).includes(row?.no_last_dose_date_remark)) {
        setReason(row?.no_last_dose_date_remark);
      } else {
        setReason("etc");
        setOtherReason(row?.no_last_dose_date_remark);
      }
    } else {
      setReason("-");
      setOtherReason("");
    }

    if (row?.drug && Object.keys(row.drug).length > 0) {
      // เลือกยา ใหม่ จาก Search Box จะได้ drug ทั้งตั้ว
      setSelectedDrug(row?.drug);
    } else if (row?.name) {
      // เลือกยา จากหลังบ้าน ชื่อ (ตกเคสนี้ ใน IPD/Admit )
      setSelectedDrug(row?.name);
    } else if (row?.product) {
      // เลือกยา จากหลังบ้าน ตัวเลข
      setSelectedDrug(row.product);
    } else if (row?.product_name) {
      // เลือกยา จากหลังบ้าน ชื่อ
      setExternalDrug(row?.product_name);
    }
  };

  // his/apps/TPD/serializers/serializers_medication.py backend logic
  const createLabel = (obj: any) => {
    let allowed_fraction = {
      0.25: "เศษหนึ่งส่วนสี่",
      0.5: "ครึ่ง",
      0.75: "เศษสามส่วนสี่",
    };

    let result: string[] = [];
    if (obj?.route) {
      //get route_text
      let routeText = props.masterOptions?.route?.find(
        (item: any) => item.value === obj.route
      )?.text;
      if (routeText) {
        result.push(routeText);
      }
    }

    if (obj?.site) {
      //get site_text
      let siteText = props.masterOptions?.site?.find((item: any) => item.value === obj.site)?.text;
      if (siteText) {
        result.push(siteText);
      }
    }

    let unitText: string = props.masterOptions?.unit?.find(
      (item: any) => item.value === obj.unit
    )?.text;

    if (obj?.unit) {
      let fraction = obj.dose - (obj.dose | 0);
      //get unit_text

      if (Object.keys(allowed_fraction).includes(fraction.toString())) {
        if (obj.dose < 1) {
          result.push(allowed_fraction[fraction] + unitText);
        }
      } else if (obj.dose > 1 && fraction === 0.5) {
        result.push((obj.dose | 0) + unitText + allowed_fraction[fraction]);
      } else {
        result.push(obj.dose + unitText);
      }
    } else {
      result.push(obj.dose + unitText);
    }

    if (obj?.frequency) {
      let frequencyText = props.masterOptions?.frequency?.find(
        (item: any) => item.value === obj.frequency
      )?.text;
      if (frequencyText) {
        result.push(frequencyText);
      }
    }

    if (obj?.method) {
      let methodText = props.masterOptions?.method?.find(
        (item: any) => item.value === obj.method
      )?.text;
      if (methodText) {
        result.push(methodText);
      }
    }
    console.log("result: ", result);
    return result.join(" ");
  };

  const handleAddOtherDrug = () => {
    console.log("handleAddOtherDrug props: ", props);
    // console.log(
    //   "handleAddOtherDrug props.selectedExternalDrug?.code: ",
    //   props.selectedExternalDrug?.code
    // );

    // console.log("handleAddOtherDrug externalId", externalId);
    // console.log("handleAddOtherDrug externalType: ", externalType);
    // console.log("handleAddOtherDrug externalDrug: ", externalDrug);
    // console.log("handleAddOtherDrug selectedDrug: ", selectedDrug);
    // console.log("handleAddOtherDrug qty: ", qty);
    // console.log("handleAddOtherDrug selectDrugRow: ", selectDrugRow);
    // console.log('handleAddOtherDrug startDate: ', startDate);
    // console.log('handleAddOtherDrug startTime: ', startTime);
    // console.log('handleAddOtherDrug lastDate: ', lastDate);
    // console.log('handleAddOtherDrug lastTime: ', lastTime);

    let item = {
      id: externalId, // should have this field cause compare between exist drug or new drug
      target_type: externalType,
      // target_pk: externalType === "custom_drug" ? 0 : selectedDrug?.id,
      name: externalType === "custom_drug" ? externalDrug : selectedDrug?.name_code,
      product_name:
        externalType === "custom_drug" ? externalDrug : props.selectedExternalDrug?.full_name,
      code: props.selectedExternalDrug?.code || "",
      generic_name:
        externalType === "custom_drug" ? null : props.selectedExternalDrug?.generic_name,
      quantity: qty,
      dose: !dose ? 0 : dose, // ?49547
      unit,
      route,
      site,
      frequency,
      method,
      // Move to TPDInterface (SetMedReconcile)
      start_date: startDate,
      start_time: startTime,

      // label from backend ( new Case )
      last_dose_date: lastDate,
      last_dose_time: lastTime,
      note: note,
      active: true,
      is_external:
        props.medReconcile?.med_reconciliation_type !== DISCHARGE_STATUS
          ? selectDrugRow?.is_external !== undefined
            ? selectDrugRow?.is_external
            : true
          : false,
      is_suggestion: props.medReconcile?.med_reconciliation_type === DISCHARGE_STATUS,
      is_no_last_dose_date: unknowLastDose,
      no_last_dose_date_remark: unknowLastDose ? (reason !== "etc" ? reason : otherReason) : "",
      drug: externalType !== "custom_drug" ? props.selectedExternalDrug : {},
      ...(externalType !== "custom_drug" && {
        product: props.selectedExternalDrug?.id,
      }),
      label: "",
    };

    item = {
      ...item,
      label: createLabel(item),
    };

    console.log("handleAddOtherDrug item: ", item);
    if (props.medReconcile?.med_reconciliation_type === "DISCHARGE") {
      props.runSequence({
        sequence: "MedReconcile",
        card: CARD_MED_RECONCILE,
        action: "add_other_item",
        index: selectedIndex,
        item: { ...item, is_suggestion: true },
      });
    } else {
      props.onEvent({
        message: "SetMedReconcile",
        card: CARD_MED_RECONCILE,
        params: {
          action: "setExternalItem",
          index: selectedIndex, // may be null or not depent on save item before
          item,
        },
      });
    }

    resetExternal();
  };

  // console.log("CardMedReconcile externalDrug: ", externalDrug);
  // console.log("CardMedReconcile externalType: ", externalType);
  // console.log("CardMedReconcile selectedDrug: ", selectedDrug);
  // console.log("CardMedReconcile props: ", props);
  // console.log(
  //   "CardMedReconcile props.searchedItemList: ",
  //   props.searchedItemList
  // );
  // console.log(
  //   "CardMedReconcile pass to SearchBox",
  //   props.searchedItemList?.length > 0
  //     ? selectedDrug
  //     : selectedDrug || externalDrug || null
  // );
  // console.log("CardMedReconcile medicationItems: ", medicationItems);

  // const remarkErrorIdx = useMemo(() => {
  //   let remarkKeyword = "กรุณาระบุหมายเหตุ";
  //   let error = props.errorMessage?.[CARD_MED_RECONCILE];
  //   console.log('props.errorMessage: ', props.errorMessage);
  //   console.log('error: ', error);

  //   if (error) {
  //     let errors = props.errorMessage?.[CARD_MED_RECONCILE]?.error;
  //     let idx = (errors || []).findIndex((item: any) =>
  //       item.includes(remarkKeyword)
  //     );
  //     return idx;
  //   }
  //   return -1;
  // }, [props.errorMessage]);

  useEffect(() => {
    let remarkKeyword = "กรุณาระบุหมายเหตุ";
    let indexErrorIdx = -1;
    let error = props.errorMessage?.[CARD_MED_RECONCILE]?.error;
    // console.log("props.errorMessage: ", props.errorMessage);
    // console.log("error: ", error);

    if (Array.isArray(error) && error) {
      let errors = props.errorMessage?.[CARD_MED_RECONCILE]?.error;
      let idx = (errors || []).findIndex((item: any) => item.includes(remarkKeyword));
      indexErrorIdx = idx;
    }

    // console.log("useEffect indexErrorIdx: ", indexErrorIdx);
    setRemarkErrorIdx(indexErrorIdx);

    if (indexErrorIdx === -1) {
      if (username && password) {
        setUsername("");
        setPassword("");
      }
    }
  }, [props.errorMessage?.[CARD_MED_RECONCILE], props.successMessage?.[CARD_MED_RECONCILE]]);

  const clearErrorWithClear = () => {
    props.setProp("medReconcilePendingOrdering", null);
    clearError();
    setUsername("");
    setPassword("");
  };

  const clearError = () => {
    let remainError = props.errorMessage;
    let { [CARD_MED_RECONCILE]: e, ...restError } = remainError || {};
    props.setProp("errorMessage", restError);
  };

  const clearSuccess = () => {
    let remainSuccess = props.successMessage;
    let { [CARD_MED_RECONCILE]: e, ...restSuccess } = remainSuccess || {};
    props.setProp("successMessage", restSuccess);
  };

  // console.log("remarkErrorIdx", remarkErrorIdx);
  // console.log("  props.controller.data.userProfile: ", props.userProfile);

  // จากหลังบ้าน

  // ENABLE_MED_RECONCILIATION_DRAFT_BUTTON_AFTER_AVAILABLE_TIME
  // เปิดใช้งานปุ่ม Save Draft หลังจากเกินเวลาใน config MED_RECONCILIATION_AVAILABLE_TIME

  // ENABLE_MED_RECONCILIATION_NOT_AVAILABLE_TIME_AFTER_CHECKED_EDIT
  // เปิดใช้งานการแก้ไข checkbox Not available at admit time หลังจาก checked'

  // MED_RECONCILIATION_AVAILABLE_TIME (24)
  // 'ระบบจะอนุญาตให้บันทึกข้อมูลผู้ป่วย '
  // 'กรณี Medication Reconciliation field not_available_at_admit_time'
  // 'เป็น True และเวลายังไม่เกินค่านี้ (ชั่วโมง)'
  // 'นับจากเวลาที่พยาบาลหอผู้ป่วยกดรับผู้ป่วยเข้าหอผู้ป่วย'

  const notAvailableInRange = useMemo(() => {
    // ถ้า config_TPD_ENABLE_MED_RECONCILIATION_DRAFT_BUTTON_AFTER_AVAILABLE_TIME === false
    // คำนวน Range config_TPD_MED_RECONCILIATION_AVAILABLE_TIME ( อยู่ใน Range / นอก Range )

    // ถ้า config_TPD_ENABLE_MED_RECONCILIATION_DRAFT_BUTTON_AFTER_AVAILABLE_TIME === true
    // คำนวน Range อยู่ใน Range เสมอ

    let isInRange = true;
    if (
      !props.MedReconcileSequence?.permission
        ?.config_TPD_ENABLE_MED_RECONCILIATION_DRAFT_BUTTON_AFTER_AVAILABLE_TIME
    ) {
      if (props.MedReconcileSequence?.wardAt != null) {
        let validUntil = new Date(props.MedReconcileSequence?.wardAt);
        validUntil.setHours(
          validUntil.getHours() +
            props.MedReconcileSequence?.permission?.config_TPD_MED_RECONCILIATION_AVAILABLE_TIME
        );
        let now = new Date();
        // console.log("now: ", now);
        // console.log("validUntil: ", validUntil);
        if (now > validUntil) {
          // console.log("  isEnable = false; ");
          isInRange = false;
        }
      }
    }
    return isInRange;
  }, [
    props.MedReconcileSequence?.permission
      ?.config_TPD_ENABLE_MED_RECONCILIATION_DRAFT_BUTTON_AFTER_AVAILABLE_TIME,
    props.MedReconcileSequence?.permission?.config_TPD_MED_RECONCILIATION_AVAILABLE_TIME,
    props.MedReconcileSequence?.wardAt,
  ]);

  const isDisabledNotAvailableAtAdmitTime = useMemo(() => {
    console.log("useMemo props", props);
    let isDisabled = false;
    console.log("isDisabled: ", isDisabled);

    // จากที่่รวบรวม คำให้การของ Dev และ Tester ได้ดังนี้ Checkbox ตัวนี้
    // เลย เวลา กดไม่ได้ (disabled) ยกเว้น ENABLE_MED_RECONCILIATION_DRAFT_BUTTON_AFTER_AVAILABLE_TIME
    // กด แล้ว นำออกไม่ได้ (disable)
    // กด Save & Acknowledge แล้ว กดจาก Uncheck -> check ไม่ได้ (disabled)  (ต่างจาก CNMI)
    //
    // (Bypass) แต่ถ้า config_TPD_ENABLE_MED_RECONCILIATION_NOT_AVAILABLE_TIME_AFTER_CHECKED_EDIT
    // เปิดใช้งานการแก้ไข checkbox Not available at admit time หลังจาก checked'
    //

    // Bypass
    /// กรณี Check + config_TPD_ENABLE_MED_RECONCILIATION_NOT_AVAILABLE_TIME_AFTER_CHECKED_EDIT (true)
    if (
      props.medReconcile?.not_available_at_admit_time &&
      !props.MedReconcileSequence?.permission
        ?.config_TPD_ENABLE_MED_RECONCILIATION_NOT_AVAILABLE_TIME_AFTER_CHECKED_EDIT
    ) {
      return isDisabled;
    }

    // เลย เวลา กดไม่ได้ (disabled) ยกเว้น ENABLE_MED_RECONCILIATION_DRAFT_BUTTON_AFTER_AVAILABLE_TIME
    if (!notAvailableInRange) {
      // อยู่นอก Range + Config  หรือ อยู่ใน Range
      isDisabled = true;
      console.log("isDisabled: ", isDisabled);
    }

    // กด แล้ว นำออกไม่ได้ (disable)
    if (props.medReconcile?.not_available_at_admit_time) {
      isDisabled = true;
      console.log("isDisabled: ", isDisabled);
    }

    // กด Save & Acknowledge แล้ว กดจาก Uncheck -> check ไม่ได้ (disabled)  (ต่างจาก CNMI)
    // ไม่มี ทางที่หน้าบ้านจะรู้ว่า ถูก Save จาก SAVE DRAFT หรือ SAVE & ACKNOWLEDGE
    // if (props.medReconcile?.acknowledge) {
    //   isDisabled = true;
    //   console.log('isDisabled: ', isDisabled);
    // }
    console.log(
      "props.medReconcile?.not_available_at_admit_time: ",
      props.medReconcile?.not_available_at_admit_time
    );
    console.log("props.medReconcile?.acknowledge: ", props.medReconcile?.acknowledge);
    console.log("Summary isDisabled: ", isDisabled);
    return isDisabled;
  }, [
    props.MedReconcileSequence?.permission
      ?.config_TPD_ENABLE_MED_RECONCILIATION_NOT_AVAILABLE_TIME_AFTER_CHECKED_EDIT,
    notAvailableInRange,
    props.medReconcile?.not_available_at_admit_time,
  ]);

  const limitReconcileDateTime = useMemo(() => {
    if (
      props.MedReconcileSequence?.wardAt != null &&
      props.MedReconcileSequence?.permission?.config_TPD_MED_RECONCILIATION_AVAILABLE_TIME != null
    ) {
      // console.log(
      //   "props.MedReconcileSequence.wardAt",
      //   props.MedReconcileSequence?.wardAt
      // );
      let wardAt = new Date(props.MedReconcileSequence?.wardAt);
      // console.log("wardAt: ", wardAt.toString());
      // console.log(
      //   "props.MedReconcileSequence?.permission?.config_TPD_MED_RECONCILIATION_AVAILABLE_TIME: ",
      //   props.MedReconcileSequence?.permission
      //     ?.config_TPD_MED_RECONCILIATION_AVAILABLE_TIME
      // );
      wardAt.setHours(
        wardAt.getHours() +
          Number(
            props.MedReconcileSequence?.permission?.config_TPD_MED_RECONCILIATION_AVAILABLE_TIME
          )
      );
      if (wardAt) {
        let wardAtString = " (" + dateToStringBE(wardAt) + ")";
        return wardAtString;
      }
    }

    return " (ผู้ป่วยยังไม่ได้รับเข้า Ward)";
  }, [props.MedReconcileSequence?.wardAt, props.MedReconcileSequence?.permission]);

  useEffect(() => {
    // console.log(" selectedEncounter ", props.selectedEncounter?.id);
    props.runSequence({
      sequence: "MedReconcile",
      card: CARD_MED_RECONCILE,
      restart: true,
      selectedEncounterId: props.selectedEncounter?.id,
    });
  }, [props.selectedEncounter?.id]);

  useEffect(() => {
    const filterOptions = (
      options: { key: any; value: any; text: any }[],
      limits: any[]
    ): any[] => {
      return Array.isArray(limits) && limits.length > 0
        ? options?.filter((opt: any) => limits?.includes(opt.value))
        : options;
    };

    if (props.openMedReconcileOrder) {
      // computed Data
      // let internal = props.medReconcileOrder?.internal_items || [];
      // let external = props.medReconcileOrder?.external_product_items || [];
      let custom = props.medReconcileOrder?.external_custom_items || [];

      // internal = internal.map((item: any) => {
      //   console.log('internal_items item: ', item);
      //   let targetKey = "internal_items";

      //   return {
      //     ...item,
      //     targetKey,
      //     drug: (
      //       <>
      //         {item.warning ? (
      //           <div style={{ color: "red", margin: "4px" }}>
      //             {" "}
      //             {item.warning}{" "}
      //           </div>
      //         ) : null}

      //         <div style={enableZIndex === `${targetKey}_drug_${item.id}` ? {position:"absolute", zIndex: 10} : {}}>
      //         <Dropdown
      //           selection
      //           disabled={item.has_continue_order}
      //           upward={false}
      //           fluid
      //           options={item.product_list?.map((p: any) => ({
      //             key: p.id,
      //             text: p.name,
      //             value: p.id,
      //           }))}
      //           value={item.selected_product}
      //           onClick={() => {
      //             setEnableZIndex(`${targetKey}_drug_${item.id}`)
      //           }}
      //           onClose={() => {
      //             setEnableZIndex(``)
      //           }}
      //           onChange={async (e, data) => {
      //             await handleChangeMedOrder({
      //               keyId: "selected_product",
      //               keyName: "selected_product_name",
      //               value: data?.value,
      //               text: data?.options?.find(
      //                 (item: any) => item.value === data?.value
      //               )?.text,
      //               item,
      //               targetKey,
      //             });

      //             await handleChangeMedOrder({
      //               keyId: "product",
      //               keyName: "product_name",
      //               value: data?.value,
      //               text: data?.options?.find(
      //                 (item: any) => item.value === data?.value
      //               )?.text,
      //               item,
      //               targetKey,
      //             });

      //             // console.log("onchange dropdown :", value, text)
      //             // props.onEvent({
      //             //   message: "MedReconcileDrugOrder",
      //             //   params: {
      //             //     action: "change",
      //             //     keyId: "selected_product",
      //             //     keyName: "selected_product_name",
      //             //     chooseId: value,
      //             //     chooseName: text,
      //             //     itemTarget: item,
      //             //     targetKey: targetKey,
      //             //   },
      //             // });
      //           }}
      //         />
      //         </div>
      //       </>
      //     ),
      //     dose: (
      //       <Input
      //         min={0}
      //         disabled={item.has_continue_order}
      //         style={{ width: "100%", height: "100%" }}
      //         type="number"
      //         value={item.dose}
      //         onChange={(e: any) => {
      //           handleChangeMedOrder({
      //             keyId: "dose",
      //             keyName: null,
      //             value: e.target.value,
      //             text: "",
      //             item,
      //             targetKey,
      //           });
      //         }}
      //       />
      //     ),
      //     unit: (
      //       <div style={enableZIndex === `${targetKey}_unit_${item.id}` ? {position:"absolute", zIndex: 10} : {}}>
      //       <Dropdown
      //         selection
      //         upward={false}
      //         disabled={item.has_continue_order}
      //         fluid
      //         options={filterOptions(
      //           props.masterOptions?.unit,
      //           item?.drugDetail?.limited_dosage_units
      //         )}
      //         value={item.unit}
      //         onClick={() => {
      //           setEnableZIndex(`${targetKey}_unit_${item.id}`)
      //         }}
      //         onClose={() => {
      //           setEnableZIndex(``)
      //         }}
      //         onChange={(e, data) => {
      //           handleChangeMedOrder({
      //             keyId: "unit",
      //             keyName: "unit_name",
      //             value: data?.value,
      //             text: data?.options?.find(
      //               (item: any) => item.value === data?.value
      //             )?.text,
      //             item,
      //             targetKey,
      //           });
      //         }}
      //       />
      //       </div>
      //     ),
      //     route: (
      //       <div style={enableZIndex === `${targetKey}_route_${item.id}` ? {position:"absolute", zIndex: 10} : {}}>
      //       <Dropdown
      //         selection
      //         upward={false}
      //         disabled={item.has_continue_order}
      //         fluid
      //         options={filterOptions(
      //           props.masterOptions.route,
      //           item?.drugDetail?.limited_routes
      //         )}
      //         value={item.route}
      //         onClick={() => {
      //           setEnableZIndex(`${targetKey}_route_${item.id}`)
      //         }}
      //         onClose={() => {
      //           setEnableZIndex(``)
      //         }}
      //         onChange={(e, data) => {
      //           handleChangeMedOrder({
      //             keyId: "route",
      //             keyName: "route_name",
      //             value: data?.value,
      //             text: data?.options?.find(
      //               (item: any) => item.value === data?.value
      //             )?.text,
      //             item,
      //             targetKey,
      //           });
      //         }}
      //       />
      //       </div>
      //     ),
      //     site: (
      //       <div style={enableZIndex === `${targetKey}_site_${item.id}` ? {position:"absolute", zIndex: 10} : {}}>
      //       <Dropdown
      //         selection
      //         upward={false}
      //         disabled={item.has_continue_order}
      //         fluid
      //         options={filterOptions(
      //           props.masterOptions.site,
      //           item?.drugDetail?.limited_sites
      //         )}
      //         value={item.site}
      //         onClick={() => {
      //           setEnableZIndex(`${targetKey}_site_${item.id}`)
      //         }}
      //         onClose={() => {
      //           setEnableZIndex(``)
      //         }}
      //         onChange={(e, data) => {
      //           handleChangeMedOrder({
      //             keyId: "site",
      //             keyName: "site_name",
      //             value: data?.value,
      //             text: data?.options?.find(
      //               (item: any) => item.value === data?.value
      //             )?.text,
      //             item,
      //             targetKey,
      //           });
      //         }}
      //       />
      //       </div>
      //     ),
      //     freq: (
      //       <div style={enableZIndex === `${targetKey}_freq_${item.id}` ? {position:"absolute", zIndex: 10} : {}}>
      //       <Dropdown
      //         selection
      //         upward={false}
      //         disabled={item.has_continue_order}
      //         fluid
      //         options={filterOptions(
      //           props.masterOptions.frequency,
      //           item?.drugDetail?.limited_frequencies
      //         )}
      //         value={item.frequency}
      //         onClick={() => {
      //           setEnableZIndex(`${targetKey}_freq_${item.id}`)
      //         }}
      //         onClose={() => {
      //           setEnableZIndex(``)
      //         }}
      //         onChange={(e, data) => {
      //           handleChangeMedOrder({
      //             keyId: "frequency",
      //             keyName: "frequency_name",
      //             value: data?.value,
      //             text: data?.options?.find(
      //               (item: any) => item.value === data?.value
      //             )?.text,
      //             item,
      //             targetKey,
      //           });
      //         }}
      //       />
      //       </div>

      //     ),

      //     prn: (
      //       <Form>
      //         <TextArea
      //           rows={1}
      //           disabled={item.has_continue_order}
      //           style={{
      //             width: "100%",
      //             height: "100%",
      //             minWidth: "100%",
      //             maxWidth: "100%",
      //           }}
      //           type="text"
      //           value={item.prn}
      //           onChange={(e, { value }) => {
      //             handleChangeMedOrder({
      //               keyId: "prn",
      //               keyName: null,
      //               value,
      //               text: "",
      //               item,
      //               targetKey,
      //             });
      //           }}
      //         />
      //       </Form>
      //     ),
      //     method: (
      //       <div style={enableZIndex === `${targetKey}_method_${item.id}` ? {position:"absolute", zIndex: 10} : {}}>
      //       <Dropdown
      //         selection
      //         upward={false}
      //         disabled={item.has_continue_order}
      //         fluid
      //         options={filterOptions(
      //           props.masterOptions.method,
      //           item?.drugDetail?.limited_methods
      //         )}
      //         value={item.method}
      //         onClick={() => {
      //           setEnableZIndex(`${targetKey}_method_${item.id}`)
      //         }}
      //         onClose={() => {
      //           setEnableZIndex(``)
      //         }}
      //         onChange={(e, data) => {
      //           handleChangeMedOrder({
      //             keyId: "method",
      //             keyName: "method_name",
      //             value: data?.value,
      //             text: data?.options?.find(
      //               (item: any) => item.value === data?.value
      //             )?.text,
      //             item,
      //             targetKey,
      //           });
      //         }}
      //       />
      //       </div>
      //     ),
      //     oneDose: (
      //       <Checkbox
      //         checked={item.order_one_dose}
      //         disabled={item.has_continue_order}
      //         onChange={(e: any, { checked }) => {
      //           props.onEvent({
      //             message: "MedReconcileDrugOrder",
      //             params: {
      //               action: "one_dose_check",
      //               itemTarget: item,
      //               value: checked,
      //               targetKey: targetKey,
      //             },
      //           });
      //         }}
      //       />
      //     ),
      //     delete: (
      //       <Button
      //         icon
      //         color="red"
      //         disabled={item.has_continue_order}
      //         onClick={() => {
      //           props.onEvent({
      //             message: "MedReconcileDrugOrder",
      //             params: {
      //               action: "remove_item",
      //               itemTarget: item,
      //               targetKey: targetKey,
      //             },
      //           });
      //         }}
      //       >
      //         <Icon name="trash alternate" />
      //       </Button>
      //     ),
      //   };
      // });

      // external = external.map((item: any) => {
      //   let targetKey = "external_product_items";

      //   return {
      //     ...item,
      //     targetKey,
      //     drug: (
      //       <>
      //         {item.warning ? (
      //           <div style={{ color: "red", margin: "4px" }}>
      //             {" "}
      //             {item.warning}{" "}
      //           </div>
      //         ) : null}
      //         <div style={enableZIndex === `${targetKey}_drug_${item.id}` ? {position:"absolute", zIndex: 10} : {}}>
      //         <Dropdown
      //           selection
      //           disabled={item.has_continue_order}
      //           upward={false}
      //           fluid
      //           options={item.product_list?.map((p: any) => ({
      //             key: p.id,
      //             text: p.name,
      //             value: p.id,
      //           }))}
      //           value={item.selected_product}
      //           onClick={() => {
      //             setEnableZIndex(`${targetKey}_drug_${item.id}`)
      //           }}
      //           onClose={() => {
      //             setEnableZIndex(``)
      //           }}
      //           onChange={async (e, data) => {
      //             console.log("data: ", data);
      //             console.log("onChange value, ", data?.value);
      //             console.log("onChange text, ", data?.text);
      //             console.log("e.target", e.target);
      //             await handleChangeMedOrder({
      //               keyId: "selected_product",
      //               keyName: "selected_product_name",
      //               value: data?.value,
      //               text: data?.options?.find(
      //                 (item: any) => item.value === data?.value
      //               )?.text,
      //               item,
      //               targetKey,
      //             });

      //             await handleChangeMedOrder({
      //               keyId: "product",
      //               keyName: "product_name",
      //               value: data?.value,
      //               text: data?.options?.find(
      //                 (item: any) => item.value === data?.value
      //               )?.text,
      //               item,
      //               targetKey,
      //             });
      //           }}
      //         />
      //         </div>
      //       </>
      //     ), // item.selected_product_name,
      //     dose: (
      //       <Input
      //         min={0}
      //         disabled={item.has_continue_order}
      //         style={{ width: "100%", height: "100%" }}
      //         type="number"
      //         value={item.dose}
      //         onChange={(e: any) => {
      //           handleChangeMedOrder({
      //             keyId: "dose",
      //             keyName: null,
      //             value: e.target.value,
      //             text: "",
      //             item,
      //             targetKey,
      //           });
      //         }}
      //       />
      //     ),
      //     unit: (
      //       <div style={enableZIndex === `${targetKey}_unit_${item.id}` ? {position:"absolute", zIndex: 10} : {}}>
      //       <Dropdown
      //         selection
      //         upward={false}
      //         disabled={item.has_continue_order}
      //         fluid
      //         options={filterOptions(
      //           props.masterOptions?.unit,
      //           item?.drugDetail?.limited_dosage_units
      //         )}
      //         value={item.unit}
      //         onClick={() => {
      //           setEnableZIndex(`${targetKey}_unit_${item.id}`)
      //         }}
      //         onClose={() => {
      //           setEnableZIndex(``)
      //         }}
      //         onChange={(e, data) => {
      //           handleChangeMedOrder({
      //             keyId: "unit",
      //             keyName: "unit_name",
      //             value: data?.value,
      //             text: data?.options?.find(
      //               (item: any) => item.value === data?.value
      //             )?.text,
      //             item,
      //             targetKey,
      //           });
      //         }}
      //       />
      //       </div>
      //     ),
      //     route: (
      //       <div style={enableZIndex === `${targetKey}_route_${item.id}` ? {position:"absolute", zIndex: 10} : {}}>
      //       <Dropdown
      //         selection
      //         upward={false}
      //         disabled={item.has_continue_order}
      //         fluid
      //         options={filterOptions(
      //           props.masterOptions.route,
      //           item?.drugDetail?.limited_routes
      //         )}
      //         value={item.route}
      //         onClick={() => {
      //           setEnableZIndex(`${targetKey}_route_${item.id}`)
      //         }}
      //         onClose={() => {
      //           setEnableZIndex(``)
      //         }}
      //         onChange={(e, data) => {
      //           handleChangeMedOrder({
      //             keyId: "route",
      //             keyName: "route_name",
      //             value: data?.value,
      //             text: data?.options?.find(
      //               (item: any) => item.value === data?.value
      //             )?.text,
      //             item,
      //             targetKey,
      //           });
      //         }}
      //       />
      //       </div>
      //     ),
      //     site: (
      //       <div style={enableZIndex === `${targetKey}_site_${item.id}` ? {position:"absolute", zIndex: 10} : {}}>
      //       <Dropdown
      //         selection
      //         upward={false}
      //         disabled={item.has_continue_order}
      //         fluid
      //         options={filterOptions(
      //           props.masterOptions.site,
      //           item?.drugDetail?.limited_sites
      //         )}
      //         value={item.site}
      //         onClick={() => {
      //           setEnableZIndex(`${targetKey}_site_${item.id}`)
      //         }}
      //         onClose={() => {
      //           setEnableZIndex(``)
      //         }}
      //         onChange={(e, data) => {
      //           handleChangeMedOrder({
      //             keyId: "site",
      //             keyName: "site_name",
      //             value: data?.value,
      //             text: data?.options?.find(
      //               (item: any) => item.value === data?.value
      //             )?.text,
      //             item,
      //             targetKey,
      //           });
      //         }}
      //       />
      //       </div>
      //     ),
      //     freq: (
      //       <div style={enableZIndex === `${targetKey}_freq_${item.id}` ? {position:"absolute", zIndex: 10} : {}}>
      //       <Dropdown
      //         selection
      //         upward={false}
      //         disabled={item.has_continue_order}
      //         fluid
      //         options={filterOptions(
      //           props.masterOptions.frequency,
      //           item?.drugDetail?.limited_frequencies
      //         )}
      //         value={item.frequency}
      //         onClick={() => {
      //           setEnableZIndex(`${targetKey}_freq_${item.id}`)
      //         }}
      //         onClose={() => {
      //           setEnableZIndex(``)
      //         }}
      //         onChange={(e, data) => {
      //           handleChangeMedOrder({
      //             keyId: "frequency",
      //             keyName: "frequency_name",
      //             value: data?.value,
      //             text: data?.options?.find(
      //               (item: any) => item.value === data?.value
      //             )?.text,
      //             item,
      //             targetKey,
      //           });
      //         }}
      //       />
      //       </div>
      //     ),
      //     prn: (
      //       <Form>
      //         <TextArea
      //           rows={1}
      //           disabled={item.has_continue_order}
      //           style={{
      //             width: "100%",
      //             height: "100%",
      //             minWidth: "100%",
      //             maxWidth: "100%",
      //           }}
      //           type="text"
      //           value={item.prn}
      //           onChange={(e, { value }) => {
      //             handleChangeMedOrder({
      //               keyId: "prn",
      //               keyName: null,
      //               value,
      //               text: "",
      //               item,
      //               targetKey,
      //             });
      //           }}
      //         />
      //       </Form>
      //     ),
      //     method: (
      //       <div style={enableZIndex === `${targetKey}_method_${item.id}` ? {position:"absolute", zIndex: 10} : {}}>
      //       <Dropdown
      //         selection
      //         upward={false}
      //         disabled={item.has_continue_order}
      //         fluid
      //         options={filterOptions(
      //           props.masterOptions.method,
      //           item?.drugDetail?.limited_methods
      //         )}
      //         value={item.method}
      //         onClick={() => {
      //           setEnableZIndex(`${targetKey}_method_${item.id}`)
      //         }}
      //         onClose={() => {
      //           setEnableZIndex(``)
      //         }}
      //         onChange={(e, data) => {
      //           handleChangeMedOrder({
      //             keyId: "method",
      //             keyName: "method_name",
      //             value: data?.value,
      //             text: data?.options?.find(
      //               (item: any) => item.value === data?.value
      //             )?.text,
      //             item,
      //             targetKey,
      //           });
      //         }}
      //       />
      //       </div>
      //     ),

      //     internalUse: (
      //       <Checkbox
      //         disabled={item.is_outside || item.has_continue_order}
      //         checked={item.is_to_internal_order}
      //         onChange={(e: any, { checked }) => {
      //           props.onEvent({
      //             message: "MedReconcileDrugOrder",
      //             params: {
      //               action: "internalUseCheck",
      //               itemTarget: item,
      //               value: checked,
      //               targetKey: targetKey,
      //             },
      //           });
      //         }}
      //       />
      //     ),
      //     oneDose: (
      //       <Checkbox
      //         checked={item.order_one_dose}
      //         disabled={item.has_continue_order}
      //         onChange={(e: any, { checked }) => {
      //           props.onEvent({
      //             message: "MedReconcileDrugOrder",
      //             params: {
      //               action: "one_dose_check",
      //               itemTarget: item,
      //               value: checked,
      //               targetKey: targetKey,
      //             },
      //           });
      //         }}
      //       />
      //     ),

      //     delete: (
      //       <Button
      //         icon
      //         color="red"
      //         disabled={item.has_continue_order}
      //         onClick={() => {
      //           props.onEvent({
      //             message: "MedReconcileDrugOrder",
      //             params: {
      //               action: "remove_item",
      //               itemTarget: item,
      //               targetKey: targetKey,
      //             },
      //           });
      //         }}
      //       >
      //         <Icon name="trash alternate" />
      //       </Button>
      //     ),
      //   };
      // });

      custom = custom.map((item: any) => {
        let targetKey = "external_custom_items";
        return {
          ...item,
          targetKey,
          drug: item.product_name,
          dose: item.dose,
          usage: item.label,
          startDate: item.start_date,
          oneDose: <Checkbox />,
          delete: (
            <Button
              icon
              color="red"
              onClick={() => {
                props.onEvent({
                  message: "MedReconcileDrugOrder",
                  params: {
                    action: "remove_item",
                    itemTarget: item,
                    targetKey: targetKey,
                  },
                });
              }}
            >
              <Icon name="trash alternate" />
            </Button>
          ),
        };
      });

      // setInternalDrugsSummary(internal);
      // setExternalDrugsSummary(external);
      setCustomDrugsSummary(custom);

      // console.log("update internal", internal)
      // console.log("update external", external)
      // console.log("update custom", custom)
    }
  }, [props.openMedReconcileOrder, props.medReconcileOrder, enableZIndex]);

  // Drug,Dose,Unit,Route,Site,Frequency,PRN,Method,One Dose,
  // drug,dose,unit,route,site,freq,prn,method,oneDose,delete
  // ^150,80,120,200,180,auto,100,190,80,60
  let internalTargetKey = "internal_items";

  const filterOptions = (options: { key: any; value: any; text: any }[], limits: any[]): any[] => {
    return Array.isArray(limits) && limits.length > 0
      ? options?.filter((opt: any) => limits?.includes(opt.value))
      : options;
  };

  const internalColumns = useMemo(
    () => [
      {
        Header: "Drug",
        id: "drug",
        accessor: "drug",
        width: 300,
        Cell: (cellInfo: CellInfo, column: any) => {
          // console.log('internalColumns cellInfo: ', cellInfo);
          return (
            <>
              {cellInfo?.original?.warning ? (
                <div style={{ color: "red", margin: "4px" }}> {cellInfo?.original?.warning} </div>
              ) : null}

              <div
                style={
                  enableZIndex === `drug_${cellInfo?.original?.id}`
                    ? {
                        position: "absolute",
                        zIndex: 10,
                        width: `${cellInfo?.width}px`,
                        paddingRight: "7px",
                      }
                    : {}
                }
              >
                <Dropdown
                  selection
                  disabled={cellInfo?.original?.has_continue_order}
                  upward={false}
                  fluid
                  options={cellInfo?.original?.product_list?.map((p: any) => ({
                    key: p.id,
                    text: p.name,
                    value: p.id,
                  }))}
                  value={cellInfo?.original?.selected_product}
                  onClick={() => {
                    setEnableZIndex(`drug_${cellInfo?.original?.id}`);
                  }}
                  onClose={() => {
                    setEnableZIndex(``);
                  }}
                  onChange={async (e, data) => {
                    await handleChangeMedOrder({
                      keyId: "selected_product",
                      keyName: "selected_product_name",
                      value: data?.value,
                      text: data?.options?.find((item: any) => item?.value === data?.value)?.text,
                      item: cellInfo?.original,
                      targeKey: internalTargetKey,
                    });

                    await handleChangeMedOrder({
                      keyId: "product",
                      keyName: "product_name",
                      value: data?.value,
                      text: data?.options?.find((item: any) => item?.value === data?.value)?.text,
                      item: cellInfo?.original,
                      targeKey: internalTargetKey,
                    });

                    // console.log("onchange dropdown :", value, text)
                    // props.onEvent({
                    //   message: "MedReconcileDrugOrder",
                    //   params: {
                    //     action: "change",
                    //     keyId: "selected_product",
                    //     keyName: "selected_product_name",
                    //     chooseId: value,
                    //     chooseName: text,
                    //     itemTarget: item,
                    //     targetKey: targetKey,
                    //   },
                    // });
                  }}
                />
              </div>
            </>
          );
        },
      },
      {
        Header: "Dose",
        id: "dose",
        accessor: "dose",
        Cell: (cellInfo: CellInfo, column: any) => {
          return (
            <Input
              min={0}
              disabled={cellInfo?.original.has_continue_order}
              style={{ width: "100%", height: "100%" }}
              type="number"
              value={cellInfo?.original.dose}
              onChange={(e: any) => {
                handleChangeMedOrder({
                  keyId: "dose",
                  keyName: null,
                  value: e.target.value,
                  text: "",
                  item: cellInfo?.original,
                  targetKey: internalTargetKey,
                });
              }}
            />
          );
        },
        width: 80,
      },
      {
        Header: "Unit",
        id: "unit",
        accessor: "unit",
        Cell: (cellInfo: CellInfo, column: any) => {
          return (
            <div
              style={
                enableZIndex === `unit_${cellInfo?.original?.id}`
                  ? {
                      position: "absolute",
                      zIndex: 10,
                      width: `${cellInfo?.width}px`,
                      paddingRight: "7px",
                    }
                  : {}
              }
            >
              <Dropdown
                selection
                upward={false}
                disabled={cellInfo?.original?.has_continue_order}
                fluid
                options={filterOptions(
                  props.masterOptions?.unit,
                  cellInfo?.original?.drugDetail?.limited_dosage_units
                )}
                value={cellInfo?.original?.unit}
                onClick={() => {
                  setEnableZIndex(`unit_${cellInfo?.original?.id}`);
                }}
                onClose={() => {
                  setEnableZIndex(``);
                }}
                onChange={(e, data) => {
                  handleChangeMedOrder({
                    keyId: "unit",
                    keyName: "unit_name",
                    value: data?.value,
                    text: data?.options?.find((item: any) => item.value === data?.value)?.text,
                    item: cellInfo?.original,
                    targetKey: internalTargetKey,
                  });
                }}
              />
            </div>
          );
        },
        width: 120,
      },
      {
        Header: "Route",
        id: "route",
        accessor: "route",
        Cell: (cellInfo: CellInfo, column: any) => {
          return (
            <div
              style={
                enableZIndex === `route_${cellInfo?.original?.id}`
                  ? {
                      position: "absolute",
                      zIndex: 10,
                      width: `${cellInfo?.width}px`,
                      paddingRight: "7px",
                    }
                  : {}
              }
            >
              <Dropdown
                selection
                upward={false}
                disabled={cellInfo?.original?.has_continue_order}
                fluid
                options={filterOptions(
                  props.masterOptions.route,
                  cellInfo?.original?.drugDetail?.limited_routes
                )}
                value={cellInfo?.original?.route}
                onClick={() => {
                  setEnableZIndex(`route_${cellInfo?.original?.id}`);
                }}
                onClose={() => {
                  setEnableZIndex(``);
                }}
                onChange={(e, data) => {
                  handleChangeMedOrder({
                    keyId: "route",
                    keyName: "route_name",
                    value: data?.value,
                    text: data?.options?.find((item: any) => item.value === data?.value)?.text,
                    item: cellInfo?.original,
                    targetKey: internalTargetKey,
                  });
                }}
              />
            </div>
          );
        },
        width: 200,
      },
      {
        Header: "Site",
        id: "site",
        accessor: "site",
        Cell: (cellInfo: CellInfo, column: any) => {
          return (
            <div
              style={
                enableZIndex === `site_${cellInfo?.original?.id}`
                  ? {
                      position: "absolute",
                      zIndex: 10,
                      width: `${cellInfo?.width}px`,
                      paddingRight: "7px",
                    }
                  : {}
              }
            >
              <Dropdown
                selection
                upward={false}
                disabled={cellInfo?.original?.has_continue_order}
                fluid
                options={filterOptions(
                  props.masterOptions.site,
                  cellInfo?.original?.drugDetail?.limited_sites
                )}
                value={cellInfo?.original?.site}
                onClick={() => {
                  setEnableZIndex(`site_${cellInfo?.original?.id}`);
                }}
                onClose={() => {
                  setEnableZIndex(``);
                }}
                onChange={(e, data) => {
                  handleChangeMedOrder({
                    keyId: "site",
                    keyName: "site_name",
                    value: data?.value,
                    text: data?.options?.find((item: any) => item.value === data?.value)?.text,
                    item: cellInfo?.original,
                    targetKey: internalTargetKey,
                  });
                }}
              />
            </div>
          );
        },
        width: 180,
      },
      {
        Header: "Frequency",
        id: "freq",
        accessor: "freq",
        width: 250,
        Cell: (cellInfo: CellInfo, column: any) => {
          console.log("Frequency column: ", column);
          console.log("Frequency cellInfo", cellInfo);
          return (
            <div
              style={
                enableZIndex === `freq_${cellInfo?.original?.id}`
                  ? {
                      position: "absolute",
                      zIndex: 10,
                      width: `${cellInfo?.width}px`,
                      paddingRight: "7px",
                    }
                  : {}
              }
            >
              <Dropdown
                selection
                upward={false}
                disabled={cellInfo?.original?.has_continue_order}
                fluid
                options={filterOptions(
                  props.masterOptions.frequency,
                  cellInfo?.original?.drugDetail?.limited_frequencies
                )}
                value={cellInfo?.original?.frequency}
                onClick={() => {
                  setEnableZIndex(`freq_${cellInfo?.original?.id}`);
                }}
                onClose={() => {
                  setEnableZIndex(``);
                }}
                onChange={(e, data) => {
                  handleChangeMedOrder({
                    keyId: "frequency",
                    keyName: "frequency_name",
                    value: data?.value,
                    text: data?.options?.find((item: any) => item.value === data?.value)?.text,
                    item: cellInfo?.original,
                    targetKey: internalTargetKey,
                  });
                }}
              />
            </div>
          );
        },
      },
      {
        Header: "PRN",
        id: "prn",
        accessor: "prn",
        Cell: (cellInfo: CellInfo, column: any) => {
          return (
            <Form>
              <TextArea
                rows={1}
                disabled={cellInfo?.original?.has_continue_order}
                style={{
                  width: "100%",
                  height: "100%",
                  minWidth: "100%",
                  maxWidth: "100%",
                }}
                type="text"
                value={cellInfo?.original?.prn}
                onChange={(e, { value }) => {
                  handleChangeMedOrder({
                    keyId: "prn",
                    keyName: null,
                    value,
                    text: "",
                    item: cellInfo?.original,
                    targetKey: internalTargetKey,
                  });
                }}
              />
            </Form>
          );
        },
        width: 100,
      },
      {
        Header: "Method",
        id: "method",
        accessor: "method",
        Cell: (cellInfo: CellInfo, column: any) => {
          return (
            <div
              style={
                enableZIndex === `method_${cellInfo?.original?.id}`
                  ? {
                      position: "absolute",
                      zIndex: 10,
                      width: `${cellInfo?.width}px`,
                      paddingRight: "7px",
                    }
                  : {}
              }
            >
              <Dropdown
                selection
                upward={false}
                disabled={cellInfo?.original?.has_continue_order}
                fluid
                options={filterOptions(
                  props.masterOptions.method,
                  cellInfo?.original?.drugDetail?.limited_methods
                )}
                value={cellInfo?.original?.method}
                onClick={() => {
                  setEnableZIndex(`method_${cellInfo?.original?.id}`);
                }}
                onClose={() => {
                  setEnableZIndex(``);
                }}
                onChange={(e, data) => {
                  handleChangeMedOrder({
                    keyId: "method",
                    keyName: "method_name",
                    value: data?.value,
                    text: data?.options?.find((item: any) => item.value === data?.value)?.text,
                    item: cellInfo?.original,
                    targetKey: internalTargetKey,
                  });
                }}
              />
            </div>
          );
        },
        width: 190,
      },
      {
        Header: "One Dose",
        id: "oneDose",
        accessor: "oneDose",
        Cell: (cellInfo: CellInfo, column: any) => {
          return (
            <Checkbox
              checked={cellInfo?.original?.order_one_dose}
              disabled={cellInfo?.original?.has_continue_order}
              onChange={(e: any, { checked }) => {
                props.onEvent({
                  message: "MedReconcileDrugOrder",
                  params: {
                    action: "one_dose_check",
                    itemTarget: cellInfo?.original,
                    value: checked,
                    targetKey: internalTargetKey,
                  },
                });
              }}
            />
          );
        },
        width: 80,
      },
      {
        Header: "",
        id: "delete",
        accessor: "delete",
        Cell: (cellInfo: CellInfo, column: any) => {
          return (
            <Button
              icon
              color="red"
              disabled={cellInfo?.original?.has_continue_order}
              onClick={() => {
                props.onEvent({
                  message: "MedReconcileDrugOrder",
                  params: {
                    action: "remove_item",
                    itemTarget: cellInfo?.original,
                    targetKey: internalTargetKey,
                  },
                });
              }}
            >
              <Icon name="trash alternate" />
            </Button>
          );
        },
        width: 60,
      },
    ],
    [enableZIndex]
  );

  const externalTargetKey = "external_product_items";
  //Drug,Dose,Unit,Route,Site,Frequency,PRN,Method,One Dose,ยาใช้ในรพ.,
  //drug,dose,unit,route,site,freq,prn,method,oneDose,internalUse,delete
  //^150,80,120,200,180,250,100,150,80,80,60
  const externalColumns = useMemo(
    () => [
      {
        Header: "Drug",
        id: "drug",
        accessor: "drug",
        width: 300,
        Cell: (cellInfo: CellInfo, column: any) => {
          return (
            <>
              {cellInfo?.original?.warning ? (
                <div style={{ color: "red", margin: "4px" }}> {cellInfo?.original?.warning} </div>
              ) : null}
              <div
                style={
                  enableZIndex === `drug_${cellInfo?.original?.id}`
                    ? {
                        position: "absolute",
                        zIndex: 10,
                        width: `${cellInfo?.width}px`,
                        paddingRight: "7px",
                      }
                    : {}
                }
              >
                <Dropdown
                  selection
                  disabled={cellInfo?.original?.has_continue_order}
                  upward={false}
                  fluid
                  options={cellInfo?.original?.product_list?.map((p: any) => ({
                    key: p.id,
                    text: p.name,
                    value: p.id,
                  }))}
                  value={cellInfo?.original?.selected_product}
                  onClick={() => {
                    setEnableZIndex(`drug_${cellInfo?.original?.id}`);
                  }}
                  onClose={() => {
                    setEnableZIndex(``);
                  }}
                  onChange={async (e, data) => {
                    console.log("data: ", data);
                    console.log("onChange value, ", data?.value);
                    console.log("onChange text, ", data?.text);
                    console.log("e.target", e.target);
                    await handleChangeMedOrder({
                      keyId: "selected_product",
                      keyName: "selected_product_name",
                      value: data?.value,
                      text: data?.options?.find((item: any) => item.value === data?.value)?.text,
                      item: cellInfo?.original,
                      targetKey: externalTargetKey,
                    });

                    await handleChangeMedOrder({
                      keyId: "product",
                      keyName: "product_name",
                      value: data?.value,
                      text: data?.options?.find((item: any) => item.value === data?.value)?.text,
                      item: cellInfo?.original,
                      targetKey: externalTargetKey,
                    });
                  }}
                />
              </div>
            </>
          );
        },
      },
      {
        Header: "Dose",
        id: "dose",
        accessor: "dose",
        width: 80,
        Cell: (cellInfo: CellInfo, column: any) => {
          return (
            <Input
              min={0}
              disabled={cellInfo?.original?.has_continue_order}
              style={{ width: "100%", height: "100%" }}
              type="number"
              value={cellInfo?.original?.dose}
              onChange={(e: any) => {
                handleChangeMedOrder({
                  keyId: "dose",
                  keyName: null,
                  value: e.target.value,
                  text: "",
                  item: cellInfo?.original,
                  targetKey: externalTargetKey,
                });
              }}
            />
          );
        },
      },
      {
        Header: "Unit",
        id: "unit",
        accessor: "unit",
        width: 120,
        Cell: (cellInfo: CellInfo, column: any) => {
          return (
            <div
              style={
                enableZIndex === `unit_${cellInfo?.original?.id}`
                  ? {
                      position: "absolute",
                      zIndex: 10,
                      width: `${cellInfo?.width}px`,
                      paddingRight: "7px",
                    }
                  : {}
              }
            >
              <Dropdown
                selection
                upward={false}
                disabled={cellInfo?.original?.has_continue_order}
                fluid
                options={filterOptions(
                  props.masterOptions?.unit,
                  cellInfo?.original?.drugDetail?.limited_dosage_units
                )}
                value={cellInfo?.original?.unit}
                onClick={() => {
                  setEnableZIndex(`unit_${cellInfo?.original?.id}`);
                }}
                onClose={() => {
                  setEnableZIndex(``);
                }}
                onChange={(e, data) => {
                  handleChangeMedOrder({
                    keyId: "unit",
                    keyName: "unit_name",
                    value: data?.value,
                    text: data?.options?.find((item: any) => item.value === data?.value)?.text,
                    item: cellInfo?.original,
                    targetKey: externalTargetKey,
                  });
                }}
              />
            </div>
          );
        },
      },
      {
        Header: "Route",
        id: "route",
        accessor: "route",
        width: 200,
        Cell: (cellInfo: CellInfo, column: any) => {
          return (
            <div
              style={
                enableZIndex === `route_${cellInfo?.original?.id}`
                  ? {
                      position: "absolute",
                      zIndex: 10,
                      width: `${cellInfo?.width}px`,
                      paddingRight: "7px",
                    }
                  : {}
              }
            >
              <Dropdown
                selection
                upward={false}
                disabled={cellInfo?.original?.has_continue_order}
                fluid
                options={filterOptions(
                  props.masterOptions.route,
                  cellInfo?.original?.drugDetail?.limited_routes
                )}
                value={cellInfo?.original?.route}
                onClick={() => {
                  setEnableZIndex(`route_${cellInfo?.original?.id}`);
                }}
                onClose={() => {
                  setEnableZIndex(``);
                }}
                onChange={(e, data) => {
                  handleChangeMedOrder({
                    keyId: "route",
                    keyName: "route_name",
                    value: data?.value,
                    text: data?.options?.find((item: any) => item.value === data?.value)?.text,
                    item: cellInfo?.original,
                    targetKey: externalTargetKey,
                  });
                }}
              />
            </div>
          );
        },
      },
      {
        Header: "Site",
        id: "site",
        accessor: "site",
        width: 180,
        Cell: (cellInfo: CellInfo, column: any) => {
          return (
            <div
              style={
                enableZIndex === `site_${cellInfo?.original?.id}`
                  ? {
                      position: "absolute",
                      zIndex: 10,
                      width: `${cellInfo?.width}px`,
                      paddingRight: "7px",
                    }
                  : {}
              }
            >
              <Dropdown
                selection
                upward={false}
                disabled={cellInfo?.original?.has_continue_order}
                fluid
                options={filterOptions(
                  props.masterOptions.site,
                  cellInfo?.original?.drugDetail?.limited_sites
                )}
                value={cellInfo?.original?.site}
                onClick={() => {
                  setEnableZIndex(`site_${cellInfo?.original?.id}`);
                }}
                onClose={() => {
                  setEnableZIndex(``);
                }}
                onChange={(e, data) => {
                  handleChangeMedOrder({
                    keyId: "site",
                    keyName: "site_name",
                    value: data?.value,
                    text: data?.options?.find((item: any) => item.value === data?.value)?.text,
                    item: cellInfo?.original,
                    targetKey: externalTargetKey,
                  });
                }}
              />
            </div>
          );
        },
      },
      {
        Header: "Frequency",
        id: "freq",
        accessor: "freq",
        width: 250,
        Cell: (cellInfo: CellInfo, column: any) => {
          return (
            <div
              style={
                enableZIndex === `freq_${cellInfo?.original?.id}`
                  ? {
                      position: "absolute",
                      zIndex: 10,
                      width: `${cellInfo?.width}px`,
                      paddingRight: "7px",
                    }
                  : {}
              }
            >
              <Dropdown
                selection
                upward={false}
                disabled={cellInfo?.original?.has_continue_order}
                fluid
                options={filterOptions(
                  props.masterOptions.frequency,
                  cellInfo?.original?.drugDetail?.limited_frequencies
                )}
                value={cellInfo?.original?.frequency}
                onClick={() => {
                  setEnableZIndex(`freq_${cellInfo?.original?.id}`);
                }}
                onClose={() => {
                  setEnableZIndex(``);
                }}
                onChange={(e, data) => {
                  handleChangeMedOrder({
                    keyId: "frequency",
                    keyName: "frequency_name",
                    value: data?.value,
                    text: data?.options?.find((item: any) => item.value === data?.value)?.text,
                    item: cellInfo?.original,
                    targetKey: externalTargetKey,
                  });
                }}
              />
            </div>
          );
        },
      },
      {
        Header: "PRN",
        id: "prn",
        accessor: "prn",
        width: 100,
        Cell: (cellInfo: CellInfo, column: any) => {
          return (
            <Form>
              <TextArea
                rows={1}
                disabled={cellInfo?.original?.has_continue_order}
                style={{
                  width: "100%",
                  height: "100%",
                  minWidth: "100%",
                  maxWidth: "100%",
                }}
                type="text"
                value={cellInfo?.original?.prn}
                onChange={(e, { value }) => {
                  handleChangeMedOrder({
                    keyId: "prn",
                    keyName: null,
                    value,
                    text: "",
                    item: cellInfo?.original,
                    targetKey: externalTargetKey,
                  });
                }}
              />
            </Form>
          );
        },
      },
      {
        Header: "Method",
        id: "method",
        accessor: "method",
        width: 150,
        Cell: (cellInfo: CellInfo, column: any) => {
          return (
            <div
              style={
                enableZIndex === `method_${cellInfo?.original?.id}`
                  ? {
                      position: "absolute",
                      zIndex: 10,
                      width: `${cellInfo?.width}px`,
                      paddingRight: "7px",
                    }
                  : {}
              }
            >
              <Dropdown
                selection
                upward={false}
                disabled={cellInfo?.original?.has_continue_order}
                fluid
                options={filterOptions(
                  props.masterOptions.method,
                  cellInfo?.original?.drugDetail?.limited_methods
                )}
                value={cellInfo?.original?.method}
                onClick={() => {
                  setEnableZIndex(`method_${cellInfo?.original?.id}`);
                }}
                onClose={() => {
                  setEnableZIndex(``);
                }}
                onChange={(e, data) => {
                  handleChangeMedOrder({
                    keyId: "method",
                    keyName: "method_name",
                    value: data?.value,
                    text: data?.options?.find((item: any) => item.value === data?.value)?.text,
                    item: cellInfo?.original,
                    targetKey: externalTargetKey,
                  });
                }}
              />
            </div>
          );
        },
      },
      {
        Header: "One Dose",
        id: "oneDose",
        accessor: "oneDose",
        width: 80,
        Cell: (cellInfo: CellInfo, column: any) => {
          return (
            <Checkbox
              checked={cellInfo?.original?.order_one_dose}
              disabled={cellInfo?.original?.has_continue_order || CONFIG.DISABLE_ONE_DOSE_ORDER_MED_EXTERNAL}
              onChange={(e: any, { checked }) => {
                props.onEvent({
                  message: "MedReconcileDrugOrder",
                  params: {
                    action: "one_dose_check",
                    itemTarget: cellInfo?.original,
                    value: checked,
                    targetKey: externalTargetKey,
                  },
                });
              }}
            />
          );
        },
      
      },
      {
        Header: "ยาใช้ในรพ.",
        id: "internalUse",
        accessor: "internalUse",
        width: 80,
        Cell: (cellInfo: CellInfo, column: any) => {
          return (
            <Checkbox
              disabled={cellInfo?.original?.is_outside || cellInfo?.original?.has_continue_order}
              checked={cellInfo?.original?.is_to_internal_order}
              onChange={(e: any, { checked }) => {
                props.onEvent({
                  message: "MedReconcileDrugOrder",
                  params: {
                    action: "internalUseCheck",
                    itemTarget: cellInfo?.original,
                    value: checked,
                    targetKey: externalTargetKey,
                  },
                });
              }}
            />
          );
        },
      },
      {
        Header: "",
        id: "delete",
        accessor: "delete",
        width: 60,
        Cell: (cellInfo: CellInfo, column: any) => {
          return (
            <Button
              icon
              color="red"
              disabled={cellInfo?.original?.has_continue_order}
              onClick={() => {
                props.onEvent({
                  message: "MedReconcileDrugOrder",
                  params: {
                    action: "remove_item",
                    itemTarget: cellInfo?.original,
                    targetKey: externalTargetKey,
                  },
                });
              }}
            >
              <Icon name="trash alternate" />
            </Button>
          );
        },
      },
    ],
    [enableZIndex]
  );

  const getTdProps = useCallback(
    (state: any, rowInfo: any, column: any, instance: any) => {
      let isCenter = enableZIndex !== `${column?.id}_${rowInfo?.original?.id}`;
      // console.log('getTdProps `${rowInfo?.original?.targetKey}_${column?.id}_${rowInfo?.original?.id}`: ', `${column?.id}_${rowInfo?.original?.id}`);
      // console.log('getTdProps enableZIndex: ', enableZIndex);

      return {
        style: {
          overflow: "visible",
          ...(isCenter && { alignSelf: "center" }),
          // ...(!isCenter && { minWidth: `${width}px`}),
          ...((column?.id === "oneDose" ||
            column?.id === "internalUse" ||
            column?.id === "delete") && {
            display: "flex",
            justifyContent: "center",
          }),
          height: "100%",
        },
      };
    },
    [enableZIndex]
  );

  const getTbodyProps = useCallback((state: any, rowInfo: any, column: any, instance: any) => {
    // console.log(" getTbodyProps", state, rowInfo, instance);
    return {
      style: {
        overflow: "visible",
      },
    };
  }, []);

  const getTableProps = useCallback((state: any, rowInfo: any, column: any, instance: any) => {
    // console.log(" getTableProps", state, rowInfo, instance);
    return {
      style: {
        // overflowY: "visible",
        // overflowX: "clip",
      },
    };
  }, []);

  const handleChangeMedOrder = ({ keyId, keyName, value, text, item, targetKey }: any) => {
    console.log("handleChangeMedOrder keyName: ", keyName);
    console.log("handleChangeMedOrder keyId: ", keyId);
    console.log("handleChangeMedOrder text: ", text);
    console.log("handleChangeMedOrder value: ", value);

    props.onEvent({
      message: "MedReconcileDrugOrder",
      params: {
        action: "change",
        keyId,
        keyName,
        chooseId: value,
        chooseName: text,
        itemTarget: item,
        targetKey,
      },
    });
  };

  const handlePrintMedReconcile = ()=>{
    if ( props.medReconcile) { 
      props.runSequence({
        sequence: "MedReconcile",
        card: CARD_MED_RECONCILE,
        action: "print_med_reconcile",
      });
    } else { 
      alert("กรุณาเลือก รายการ med reconcile")
    }
  
  };

  // console.log("CardMedReconcile externalDrug: ", externalDrug);
  // console.log("CardMedReconcile externalType: ", externalType);
  // console.log("CardMedReconcile selectedDrug: ", selectedDrug);
  // console.log("CardMedReconcile props: ", props);

  // console.log(
  //   "CardMedReconcile props.medReconcile?.not_available_at_admit_time_disabled_checkbox && !notAvailableInRange: ",
  //   props.medReconcile?.not_available_at_admit_time_disabled_checkbox &&
  //     !notAvailableInRange
  // );
  // console.log("CardMedReconcile notAvailableInRange: ", notAvailableInRange);
  // console.log(
  //   "CardMedReconcile props.medReconcile?.not_available_at_admit_time_disabled_checkbox: ",
  //   props.medReconcile?.not_available_at_admit_time_disabled_checkbox
  // );
  // console.log(
  //   "CardMedReconcile props.errorMessage?.[CARD_MED_RECONCILE]?.error: ",
  //   props.errorMessage?.[CARD_MED_RECONCILE]?.error
  // );
  // console.log(
  //   "CardMedReconcile Array.isArray(props.errorMessage?.[CARD_MED_RECONCILE]?.error): ",
  //   Array.isArray(props.errorMessage?.[CARD_MED_RECONCILE]?.error)
  // );
  // console.log("CardMedReconcile medicationItems: ", medicationItems);

  // console.log("CardMedReconcile internalDrugsSummary", internalDrugsSummary ); //, setInternalDrugsSummary] = useState([]);
  // console.log("CardMedReconcile externalDrugsSummary", externalDrugsSummary ); //, setExternalDrugsSummary] = useState([]);
  // console.log("CardMedReconcile customDrugsSummary", customDrugsSummary ); //, setCustomDrugsSummary] = useState([]);

  // console.log('CardMedReconcile props.medReconcileIPDAdmitSelectOrder: ', props.medReconcileIPDAdmitSelectOrder);
  // console.log('CardMedReconcile props.medReconcileIPDAdmitSelectItem: ', props.medReconcileIPDAdmitSelectItem);

  console.log("CardMedReconcile props.medReconcile: ", props.medReconcile);
  console.log("CardMedReconcile notAvailableInRange: ", notAvailableInRange);

  console.log(
    "CardMedReconcile props.medReconcile?.items?.length === 0: ",
    props.medReconcile?.items?.length === 0
  );
  console.log(
    "CardMedReconcile props.medReconcile?.has_no_past_med: ",
    props.medReconcile?.has_no_past_med
  );
  console.log("CardMedReconcile props.medReconcileList: ", props.medReconcileList);
  console.log(
    'CardMedReconcile props.medReconcile?.med_reconciliation_type === "OPD": ',
    props.medReconcile?.med_reconciliation_type === "OPD"
  );
  console.log(
    "CardMedReconcile props.medReconcile?.not_available_at_admit_time: ",
    props.medReconcile?.not_available_at_admit_time
  );
  console.log(
    "CardMedReconcile props.medReconcile?.med_reconciliation_type !== 'TRANSFER'",
    props.medReconcile?.med_reconciliation_type !== "TRANSFER"
  );

  console.log("CardMedReconcile props.medReconcile", props.medReconcile);

  return (
    <div id="CardMedReconcile">
      {remarkErrorIdx >= 0 ? (
        <ModMedReconcileNeedRemark
          open={remarkErrorIdx >= 0}
          onClose={clearErrorWithClear}
          onApprove={(remark: string) => {
            // console.log("ModMedReconcileNeedRemark Remark: ", remark);

            props.onEvent({
              message: "SetMedReconcile",
              params: {
                action: "save",
                username,
                password,
                remark,
                card: CARD_MED_RECONCILE,
              },
            });
            if (remark) {
              setUsername("");
              setPassword("");
            }
          }}
          onCancel={clearErrorWithClear}
        >
          {props.errorMessage?.[CARD_MED_RECONCILE]?.error?.[remarkErrorIdx]}
        </ModMedReconcileNeedRemark>
      ) : Array.isArray(props.errorMessage?.[CARD_MED_RECONCILE]?.error) &&
        (props.errorMessage?.[CARD_MED_RECONCILE]?.error || []).find((item: any) => {
          console.log(
            "Array.isArray(props.errorMessage?.[CARD_MED_RECONCILE]?.error): ",
            Array.isArray(props.errorMessage?.[CARD_MED_RECONCILE]?.error)
          );
          console.log(
            "props.errorMessage?.[CARD_MED_RECONCILE]?.error : ",
            props.errorMessage?.[CARD_MED_RECONCILE]?.error
          );
          console.log("item", item);
          return item?.includes("ต้องระบุ last dose เมื่อเลือกเป็น Cont.");
        }) ? (
        <ModInfo
          open={props.errorMessage?.[CARD_MED_RECONCILE]?.error}
          titleColor={"red"}
          titleName={(props.errorMessage?.[CARD_MED_RECONCILE]?.error || [])?.find((item: any) =>
            item?.includes("ต้องระบุ last dose เมื่อเลือกเป็น Cont.")
          )}
          onApprove={clearError}
          onClose={clearError}
        ></ModInfo>
      ) : Array.isArray(props.errorMessage?.[CARD_MED_RECONCILE]) &&
        props.errorMessage?.[CARD_MED_RECONCILE]?.length > 0 &&
        (props.errorMessage?.[CARD_MED_RECONCILE] || []).find((item: any) => {
          console.log("item: ", item);
          if (Array.isArray(item)) {
            return item?.find((i: any) =>
              (i || []).includes("ลบรายการยา External ออกจากหน้าต่างนี้")
            );
          } else {
            return false;
          }
        }) ? (
        <ModInfo
          open={props.errorMessage?.[CARD_MED_RECONCILE]?.length > 0}
          titleColor={"red"}
          titleName={"แจ้งเตือน"}
          alertText={
            <>
              <div
                dangerouslySetInnerHTML={{
                  __html:
                    props.errorMessage?.[CARD_MED_RECONCILE]?.find((item: any) =>
                      item?.find((i: any) =>
                        (i || []).includes("ลบรายการยา External ออกจากหน้าต่างนี้")
                      )
                    )?.[0] || "",
                }}
              />
            </>
          }
          onApprove={clearError}
          onClose={clearError}
        ></ModInfo>
      ) : props.successMessage?.[CARD_MED_RECONCILE] === "สั่งยา: บันทึกสำเร็จ" ? (
        <>
          <ModInfo
            open={props.successMessage?.[CARD_MED_RECONCILE]}
            titleColor={"green"}
            titleName={"สั่งยา"}
            alertText={
              <div style={{ margin: "14px" }}>{props.successMessage?.[CARD_MED_RECONCILE]}</div>
            }
            onApprove={clearSuccess}
            onClose={clearSuccess}
          ></ModInfo>
        </>
      ) : props.errorMessage?.[CARD_MED_RECONCILE]?.error?.name ===
        "Duplicated copy internal drug to order" ? (
        <ModConfirm
          openModal={true}
          titleName="แจ้งเตือนการเลือกยาซ้ำ"
          titleColor="red"
          size="mini"
          denyButtonColor="red"
          denyButtonText="ยกเลิก"
          approveButtonColor="green"
          approveButtonText="ยืนยัน"
          customStyleBasic={{ margin: 0 }}
          customStyleHeader={{ margin: 0 }}
          customGridButton={{ marginBottom: "10px" }}
          oneBr={true}
          content={
            <div>
              ระบบตรวจพบยาซ้ำดังนี้ <br />
              <br />
              {props.errorMessage?.[CARD_MED_RECONCILE]?.error?.duplicatedList?.map((item: any) => {
                return <li key={item.id}>{item.product_name}</li>;
              })}
              <br />
              <div style={{ fontWeight: "bolder", textAlign: "center" }}>
                {" "}
                ต้องการยืนยันรายการการเลือกซ้ำหรือไม่{" "}
              </div>
            </div>
          }
          onApprove={() => {
            //resend
            let { [CARD_MED_RECONCILE]: e, ...remainError } = props.errorMessage;
            props.setProp(`errorMessage`, remainError);

            props.onEvent({
              message: "SetMedReconcile",
              params: {
                action: "addOrderItem",
                card: CARD_MED_RECONCILE,
                allowDuplicated: true,
              },
            });
          }}
          onDeny={() => {
            let { [CARD_MED_RECONCILE]: e, ...remainError } = props.errorMessage;
            props.setProp(`errorMessage`, remainError);
          }}
          onCloseWithDimmerClick={() => {
            let { [CARD_MED_RECONCILE]: e, ...remainError } = props.errorMessage;
            props.setProp(`errorMessage`, remainError);
          }}
        ></ModConfirm>
      ) : (
        <SnackMessage
          onEvent={props.onEvent}
          onClose={() => {
            clearError();
            clearSuccess();
          }}
          error={props.errorMessage?.[CARD_MED_RECONCILE]}
          success={props.successMessage?.[CARD_MED_RECONCILE]}
        />
      )}

      {/**
       *  CardMedReconcileUX Beware confuse !!
       *
       *  internalDrug, externalDrug
       *  start_date, start_time,last_dose_date,last_dose_time
       *
       *  drugIPDAdmit, medicationItems
       *  start_date_text, start_time_text, last_date_text, last_time_text
       *
       *  API start_date, start_time, last_dose_date, last_dose_time
       *
       */}
      <CardMedReconcileUX
        medReconcileLoading={props.medReconcileLoading}
        medReconcileIPDAdmitSelectOrder={props.medReconcileIPDAdmitSelectOrder}
        medReconcileIPDAdmitSelectItem={props.medReconcileIPDAdmitSelectItem}
        pastMedRecordDate={
          props.medReconcile?.recorded_at
            ? `[Recorded date : ${
                props.medReconcile?.recorded_at
                  ? `${dateToStringBE(props.medReconcile?.recorded_at)}]`
                  : "]"
              }`
            : ""
        }
        isNew={!props.medReconcile?.id}
        // IHV3 Medreconcile Spec 6.1
        limitDateTime={limitReconcileDateTime}
        configMedReconcileAvailable={
          props.MedReconcileSequence?.permission?.config_TPD_MED_RECONCILIATION_AVAILABLE_TIME
        }
        onSaveDraft={() => {
          props.onEvent({
            message: "SetMedReconcile",
            params: {
              action: "save",
              username,
              password,
              remark,
              card: CARD_MED_RECONCILE,
              acknowledge: false,
            },
          });
        }}
        hideSaveDraft={!(notAvailableInRange && props.medReconcile?.not_available_at_admit_time)}
        disabledSaveBtn={
          // !hideSaveDraft || OPD Medrec when have IPD แรกรับ
          props.medReconcile?.med_reconciliation_type !== "TRANSFER" &&
          ((notAvailableInRange && props.medReconcile?.not_available_at_admit_time) ||
            (props.medReconcile?.med_reconciliation_type === "OPD" &&
              props.medReconcileList?.find(
                (item: any) => item.med_reconciliation_type === "IPD_ADMIT"
              )) ||
            (!props.medReconcile?.has_no_past_med &&
              props.medReconcile?.items?.length === 0 &&
              props.medReconcile?.med_reconciliation_type !== "DISCHARGE"))
        }
        disabledOrderButton={
          props.medReconcile?.med_reconciliation_type !== "IPD_ADMIT" ||
          (!props.medReconcile?.not_available_at_admit_time &&
            !props.medReconcile?.has_no_past_med &&
            props.medReconcile?.items?.length === 0) ||
          !(props.django?.user?.role_types || []).includes("DOCTOR") ||
          (!props.medReconcile?.has_no_past_med && props.medReconcile?.items?.length === 0)
        }
        // checked data
        hasNoPastMed={props.medReconcile?.has_no_past_med}
        changeHasNoPastMed={(e: any, { checked }: any = {}) => {
          props.setProp("medReconcile.has_no_past_med", checked);
        }}
        notAvailableAtAdmit={props.medReconcile?.not_available_at_admit_time}
        notAvailableDisabled={isDisabledNotAvailableAtAdmitTime}
        changeNotAvailableAtAdmit={(e: any, { checked }: any = {}) => {
          props.setProp("medReconcile.not_available_at_admit_time", checked);
        }}
        disableDrugType={
          props.medReconcile?.med_reconciliation_type === "DISCHARGE"
            ? selectDrugRow?.is_from_mr_ipd === true
            : selectDrugRow && "is_external" in selectDrugRow
            ? !selectDrugRow?.is_external
            : false
        }
        // externalNameBtn={props.medReconcile?.med_reconciliation_type === "DISCHARGE" ? (selectDrugRow?.is_suggestion ? "Suggestion Medication" : "Edit") : "เพิ่มจากประวัติการใช้ยานอกโรงพยาบาล"}
        externalNameBtn={
          props.medReconcile?.med_reconciliation_type === "DISCHARGE"
            ? selectDrugRow?.is_from_mr_ipd === true
              ? "External Drug"
              : "Suggestion Medication"
            : "เพิ่มจากประวัติการใช้ยานอกโรงพยาบาล"
        }
        // List
        // internalItems={internalItems}
        // externalItems={externalItems}
        // showHomeMedication={
        //   props.medReconcile?.med_reconciliation_type === "DISCHARGE"
        // }
        drugIPDAdmit={medReconcileIPDAdmitItem}
        // Edit Table 63017
        medicationItems={medicationItems}
        // Edit Table 63017
        medicationRowProps={(state: any, rowInfo: any, column: any, instance: any) => {
          // console.log("medicationRowProps state", state);
          // console.log("medicationRowProps rowInfo: ", rowInfo);
          // console.log("medicationRowProps column: ", column);
          // console.log("medicationRowProps instance: ", instance);

          // console.log("rowInfo?.original: ", rowInfo?.original);

          // console.log(" props.medReconcileList: ", props.medReconcileList);
          let prepare = {
            style: {
              ...(rowInfo?.original?.acknowledge_by_users?.length === 0 && {
                color: "red",
              }),
              ...(rowInfo?.original?.is_lated && {
                backgroundColor: "rgb(252,200,212)",
              }),
              ...(rowInfo?.original?.has_changed && { color: "rgb(255,0,0)" }),
              ...(rowInfo?.original?.active === false && {
                textDecoration: "line-through",
              }),
              ...(!rowInfo?.original?.id && { color: "rgb(255,40,10)" }),
              cursor: "pointer",
              ...(props?.medReconcile?.med_reconciliation_type === "OPD" &&
                props.medReconcileList?.find(
                  (item: any) => item.med_reconciliation_type === "IPD_ADMIT"
                ) && {
                  cursor: "auto",
                }),
            },
            className:
              Number.isInteger(selectedIndex) && rowInfo?.index === selectedIndex
                ? "blueSelectedRow"
                : "",
            onClick: () => {
              // console.log("onClick !!! ", props.medReconcile)
              // if (
              //   props.medReconcile?.med_reconciliation_type !== DISCHARGE_STATUS ||
              //   (props.medReconcile?.med_reconciliation_type === DISCHARGE_STATUS)
              // ) {
              if (
                props.medReconcile?.med_reconciliation_type === DISCHARGE_STATUS &&
                rowInfo?.original?.is_from_mr_ipd === false &&
                rowInfo?.original?.is_suggestion === false
              ) {
                console.log(" Home IPD Case ");
                // Home IPD Case
                return;
              }

              if (
                props.medReconcile?.med_reconciliation_type === "OPD" &&
                props.medReconcileList?.find(
                  (item: any) => item.med_reconciliation_type === "IPD_ADMIT"
                )
              ) {
                // Medreconcile OPD
                console.log(" Medreconcile OPD Case ");
                return;
              }

              //   console.log("selectedRow", rowInfo)
              // if (props.)
              handleSelectedRow(rowInfo);
              // }
            },
          };
          // console.log("rowInfo", rowInfo);
          console.log("prepare: ", prepare);
          if (props.medReconcile?.med_reconciliation_type === "DISCHARGE") {
            return prepare;
          } else {
            return prepare;
          }
        }}
        external={external}
        // selected Medreconcile item
        enableNewButtonInMedReconcileList={
          props.MedReconcileSequence?.permission?.config_TPD_ENABLE_MED_RECONCILIATION_NEW_BUTTON
        }
        showMedReconcileList={showMedList}
        onShowMedReconcileList={(e: any) => {
          setShowMedList(!showMedList);
        }}
        medReconcileTypeOptions={medReconcileTypeOptions}
        medReconcileType={props.medReconcile?.med_reconciliation_type || ""}
        disabledMedReconcileType={
          props.medReconcile?.id || props.selectedEncounter?.type?.toLowerCase() === "opd"
        }
        changeMedReconcileType={(e: any, data: any) => {
          props.setProp("medReconcile.med_reconciliation_type", data.value);
        }}
        medReconcileList={props.medReconcileList}
        getTrPropsMedReconcileList={(state: any, rowInfo: any, column: any, instance: any) => ({
          style: {
            backgroundColor:
              rowInfo?.original?.id && rowInfo?.original?.id === selectedMedReconcile?.id
                ? "#cccccc"
                : "white",
            cursor: "pointer",
          },
          onClick: () => {
            setSelectedMedReconcile(rowInfo?.original);
          },
          onDoubleClick: () => {
            props.onEvent({
              message: "SetMedReconcile",
              params: {
                action: "selected_med_reconcile",
                selectedMedReconcile: rowInfo?.original,
              },
            });
            setShowMedList(false);
          },
        })}
        disabledLogBtn={!props.medReconcile?.id}
        onOpenLog={() => {
          // console.log("CardMedReconcile props,", props);
          props.onEvent({
            message: "GetMedReconcileLog",
            params: {
              medReconcileId: props.medReconcile?.id,
              card: "CardMedReconcile",
            },
          });
        }}
        onNewMedReconcile={() => {
          // console.log("CardMedReconcile new_med_reconcile", props);
          props.onEvent({
            message: "SetMedReconcile",
            params: {
              action: "new_med_reconcile",
              type: props?.selectedEncounter?.type?.toLowerCase() === "opd" ? "OPD" : "IPD_ADMIT",
            },
          });
          setShowMedList(false);
        }}
        onMedReconcileSelect={() => {
          props.onEvent({
            message: "SetMedReconcile",
            params: {
              action: "selected_med_reconcile",
              selectedMedReconcile: selectedMedReconcile,
            },
          });
          setShowMedList(false);
        }}
        // Editor
        setExternal={() => setExternal(true)}
        setInternal={() => setExternal(false)}
        // Internal search action
        onSearchOrderHistory={(e: any) =>
          props.onEvent({
            message: "GetMedReconcileInternal",
            params: {},
          })
        }
        drugOrderHistoryItems={drugOrderHistoryItems}
        drugOrderHistoryTrProps={(state: any, rowInfo: any, column: any, instance: any) => {
          // console.log("getTrProps rowInfo: ", rowInfo);

          // if (
          //   (medicationItems || []).find(
          //     (item: any) =>
          //       item?.product === rowInfo?.original?.product ||
          //       item?.code === rowInfo?.original?.code
          //   )
          // ) {
          //   return {
          //     style: {
          //       backgroundColor: "lightGray",
          //     },
          //     // style: {
          //     //   background: rowInfo.row.age > 20 ? 'red' : 'green',
          //     //   color: 'white'
          //     // }
          //   };
          // }
          return {};
        }}
        // Internal Editor
        checkedDoctor={props.medReconcileInternalFilter?.checkedDoctor}
        changeCheckedDoctor={(e: any, { checked }: any = {}) => {
          props.setProp("medReconcileInternalFilter", {
            ...props.medReconcileInternalFilter,
            checkedDoctor: checked,
            doctor: null,
          });
        }}
        // checkbox in med reconcile discharge
        checkedIPDHome={props.checkIPDHome}
        checkedMRIPD={props.checkMRAdmit}
        checkedSuggestion={props.checkSuggestion}
        onChangeCheckedIPDHome={(e: any, { checked }: any) => {
          props.setProp("checkIPDHome", checked);
        }}
        onChangeCheckedMRIPD={(e: any, { checked }: any) => {
          props.setProp("checkMRAdmit", checked);
        }}
        onChangeCheckedSuggestion={(e: any, { checked }: any) => {
          props.setProp("checkSuggestion", checked);
        }}
        searchDoctor={
          <SearchBox
            disabled={!props.medReconcileInternalFilter?.checkedDoctor}
            onEvent={props.onEvent}
            searchedItemList={props.searchedItemList}
            selectedItem={props.medReconcileInternalFilter?.doctor}
            setSelectedItem={(item: any) => {
              props.setProp("medReconcileInternalFilter.doctor", item);
            }}
            type="Doctor"
            toDisplay={(item: any) => item.full_name}
            style={{ width: "100%" }}
            fluid={true}
          />
        }
        checkedDrug={props.medReconcileInternalFilter?.checkedDrug}
        changeCheckedDrug={(e: any, { checked }: any = {}) => {
          props.setProp("medReconcileInternalFilter", {
            ...props.medReconcileInternalFilter,
            checkedDrug: checked,
            drug: null,
          });
        }}
        // Element
        StartTimeFreeTextBox={
          <TimeFreeTextBox24
            onChange={setStartTime}
            className="time-custom-react-picker"
            value={startTime}
            iconClockStyle={{ margin: "auto 5px" }}
          ></TimeFreeTextBox24>
        }
        EndTimeFreeTextBox={
          <TimeFreeTextBox24
            onChange={setLastTime}
            className="time-custom-react-picker"
            value={lastTime}
            iconClockStyle={{ margin: "auto 5px" }}
          ></TimeFreeTextBox24>
        }
        searchDrug={
          <SearchBox
            verbose={true}
            disabled={!props.medReconcileInternalFilter?.checkedDrug}
            onEvent={props.onEvent}
            searchedItemList={props.searchedItemList}
            selectedItem={props.medReconcileInternalFilter?.drug}
            setSelectedItem={(item: any) => {
              props.setProp("medReconcileInternalFilter.drug", item);
            }}
            type={"ADRproduct"}
            toDisplay={(item: any) => item.name_code}
            style={{ width: "100%" }}
            fluid={true}
          />
        }
        checkedDateInternal={props.medReconcileInternalFilter?.checkedDate}
        changeCheckedDateInternal={(e: any, { checked }: any = {}) => {
          props.setProp("medReconcileInternalFilter", {
            ...props.medReconcileInternalFilter,
            checkedDate: checked,
            startDate: null,
            endDate: null,
          });
        }}
        startDateInternal={
          <DateTextBox
            disabled={!props.medReconcileInternalFilter?.checkedDate}
            // style={{ width: "100%" }}
            value={props.medReconcileInternalFilter?.startDate}
            inputStyle={{ width: "145px" }}
            onChange={(date: string) => {
              props.setProp("medReconcileInternalFilter.startDate", date);
            }}
          />
        }
        endDateInternal={
          <DateTextBox
            disabled={!props.medReconcileInternalFilter?.checkedDate}
            // style={{ width: "100%" }}
            inputStyle={{ width: "145px" }}
            value={props.medReconcileInternalFilter?.endDate}
            onChange={(date: string) => {
              props.setProp("medReconcileInternalFilter.endDate", date);
            }}
          />
        }
        startDateElement={
          <DateTextBox inputStyle={{ width: "155px" }} value={startDate} onChange={setStartDate} />
        }
        endDateElement={
          <DateTextBox inputStyle={{ width: "155px" }} value={lastDate} onChange={setLastDate} />
        }
        onAddDrugToMRDischarge={() => {
          props.runSequence({
            sequence: "MedReconcile",
            card: CARD_MED_RECONCILE,
            action: "add_to_mr_discharge_list",
          });
        }}
        onAddInternalItem={() => {
          props.onEvent({
            message: "SetMedReconcile",
            params: {
              action: "addOrderItem",
              card: CARD_MED_RECONCILE,
            },
          });
        }}
        // External Editor
        externalTypeOptions={externalTypeOptions}
        externalType={externalType}
        changeExternalType={(e: any, v: any) => setExternalType(v.value)}
        externalDrug={
          externalType === "custom_drug" ? (
            <Input
              fluid
              value={externalDrug}
              onChange={(e: any) => setExternalDrug(e.target.value)}
            />
          ) : (
            <SearchBox
              verbose={true}
              fluid={true}
              style={{ width: "100%" }}
              onEvent={props.onEvent}
              searchedItemList={props.searchedItemList}
              // selectedItem={ props.searchedItemList?.length > 0 ? selectedDrug : selectedDrug || externalDrug || null}
              selectedItem={selectedDrug}
              setSelectedItem={(data: any) => {
                setSelectedDrug(data);
                // props.setProp("searchedItemList", [])
              }}
              type={"ADRproduct"}
              placeholder={`กรอกชื่อ ${
                externalTypeOptions.find((option: any) => option.value === externalType)?.text
              } ที่ต้องการค้นหา`}
              toDisplay={(item: any) => `${item?.name_code ? item?.name_code : item}`}
              disabled={
                props.medReconcile?.med_reconciliation_type === DISCHARGE_STATUS &&
                selectDrugRow?.is_from_mr_ipd === true
              }
            />
          )
        }
        qty={qty}
        changeQty={(e: any) => setQty(e.target.value)}
        dose={dose != null ? dose : ""}
        changeDose={(e: any) => setDose(e.target.value)}
        unit={unit}
        unitOptions={handleSetOptionMaster("limited_dosage_units", "unit")}
        changeUnit={(e: any, data: any) => setUnit(data.value)}
        route={route}
        routeOptions={handleSetOptionMaster("limited_routes", "route")}
        changeRoute={(e: any, data: any) => setRoute(data.value)}
        site={site}
        siteOptions={handleSetOptionMaster("limited_sites", "site")}
        changeSite={(e: any, data: any) => setSite(data.value)}
        frequency={frequency}
        frequencyOptions={handleSetOptionMaster("limited_frequencies", "frequency")}
        changeFrequency={(e: any, data: any) => setFrequency(data.value)}
        method={method}
        methodOptions={handleSetOptionMaster("limited_methods", "method")}
        changeMethod={(e: any, data: any) => setMethod(data.value)}
        startDate={startDate}
        changeStartDate={setStartDate}
        startTime={startTime}
        changeStartTime={setStartTime}
        lastDate={lastDate}
        changeLastDate={setLastDate}
        lastTime={lastTime}
        changeLastTime={setLastTime}
        note={note}
        changeNote={(e: any) => setNote(e.target.value)}
        checkedUnknowLastDose={unknowLastDose}
        changeUnknowLastDose={(e: any, { checked }: any = {}) => {
          setUnknowLastDose(checked);
          setReason(null);
          setOtherReason(null);
        }}
        reason={reason}
        changeReason={(e: any, data: any) => {
          setReason(data.value);
        }}
        reasonOptions={reasonOptions}
        otherReason={otherReason}
        changeOtherReason={(e: any) => {
          setOtherReason(e.target.value);
        }}
        addOtherDrug={(e: any) => {
          handleAddOtherDrug();
        }}
        clearExternalDrug={resetExternal}
        // Acknowledge
        acknowledge={acknowledge}
        doctor={props.medReconcile?.doctor_name}
        doctorRemark={props.medReconcile?.doctor_remark}
        pharmacist={props.medReconcile?.pharmacist_name}
        pharmacistRemark={props.medReconcile?.pharmacist_remark}
        nurseOPD={props.medReconcile?.nurse_name}
        nurseOPDRemark={props.medReconcile?.nurse_remark}
        nurseIPD={props.medReconcile?.nurse_ipd_name}
        nurseIPDRemark={props.medReconcile?.nurse_ipd_remark}
        editUser={props.medReconcile?.latest_edited_user_name || props.medReconcile?.edit_user_name}
        editDate={props.medReconcile?.latest_edited_datetime || props.medReconcile?.edit_date}
        // remark={props.medReconcile?.remark}
        username={username}
        changeUsername={(e: any) => setUsername(e.target.value)}
        password={password}
        changePassword={(e: any) => setPassword(e.target.value)}
        remark={remark}
        changeRemark={(e: any) => setRemark(e.target.value)}
        onSaveMedReconcile={() => {
          props.onEvent({
            message: "SetMedReconcile",
            params: {
              action: "save",
              username,
              password,
              remark,
              card: CARD_MED_RECONCILE,
              continueOrdering: null,
            },
          });

          // setUsername("");
          // setPassword("");
        }}
        disabledPrintMedReconcile={!props.medReconcile}
        onPrintMedReconcile={handlePrintMedReconcile}
        onOrder={() => {
          // process
          props.onEvent({
            message: "SetMedReconcile",
            params: {
              action: "save",
              username,
              password,
              remark,
              card: CARD_MED_RECONCILE,
              continueOrdering: {
                action: "open_order",
                card: CARD_MED_RECONCILE,
              },
            },
          });

          // props.onEvent({
          //   message: "MedReconcileDrugOrder",
          //   params: { action: "open_order", card: CARD_MED_RECONCILE },
          // });
        }}
        ///
        ///  Med Reconcile Transfer
        ///
        onClickSyncDrugTransfer={() => {
          props.runSequence({
            sequence: "MedReconcile",
            card: CARD_MED_RECONCILE,
            action: "syncDrugTransfer",
          });
        }}
        // Edit Table 63017
        transferOneDayData={transferOneDayData}
        transferContData={transferContData}
        // Edit Table 63017
        transferOneDayTrProps={transferOneDayTrProps}
        transferContTrProps={transferContTrProps}
        transferOneDayTdProps={transferOneDayTdProps}
        transferContTdProps={transferContTdProps}
        // Hide ACK & ORDER 64895
        hideAckOrderBtn={
          props.medReconcile?.med_reconciliation_type === "DISCHARGE" ||
          props.medReconcile?.med_reconciliation_type === "TRANSFER" ||
          !props.django?.user?.role_types?.includes("DOCTOR")
        }
      />
      <Modal
        open={props.openMedReconcileLogs}
        size="fullscreen"
        closeIcon
        onClose={() => props.setProp("openMedReconcileLogs", false)}
      >
        <CardMedReconcileLogsUX
          closeIcon={
            <Icon
              name="cancel"
              color="red"
              onClick={(e: any) => {
                props.setProp("openMedReconcileLogs", false);
              }}
            />
          }
          medReconcileLogs={props.medReconcileLogs}
        />
      </Modal>

      <Modal
        closeIcon
        onClose={() => {
          props.setProp("openMedReconcileOrder", false);
        }}
        open={props.openMedReconcileOrder}
        size="fullscreen"
      >
        <Header size="large" content="Medication Summary" />
        <ModMedicationSummaryUX
          error={
            props.medReconcileOrder?.error && (
              <>
                <div style={{ color: "red", padding: "10px", fontSize: "18px" }}>
                  <Message error>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: props.medReconcileOrder?.error,
                      }}
                    ></div>
                  </Message>

                  {/* {props.medReconcileOrder?.error} */}
                </div>
                <br />
              </>
            )
          }
          // closeIcon={
          //   <Icon
          //     name="cancel"
          //     color="red"
          //     onClick={(e: any) => {
          //       props.setProp("openMedReconcileOrder", false);
          //     }}
          //   />
          // }
          showInternal={props.medReconcileOrder?.internal_items?.length > 0}
          // internalItems={
          //   <RowOrder
          //     options={props.masterOptions}
          //     targetKey={"internal_items"}
          //     onEvent={props.onEvent}
          //     type={"InternalItems"}
          //     items={props.medReconcileOrder?.internal_items || []}
          //   />
          // }
          internalItemsData={props.medReconcileOrder?.internal_items || []}
          internalColumns={internalColumns}
          showExternalInHospital={props.medReconcileOrder?.external_product_items?.length > 0}
          // internalTable={<></>}

          getExternalTdProps={getTdProps}
          getInternalTdProps={getTdProps}
          getCustomTdProps={getTdProps}
          getInternalTbodyProps={getTbodyProps}
          getExternalTbodyProps={getTbodyProps}
          getCustomTbodyProps={getTbodyProps}
          getInternalTableProps={getTableProps}
          getExternalTableProps={getTableProps}
          getCustomTableProps={getTableProps}
          // externalTable={
          //   <ReactTable
          //     data={props.medReconcileOrder?.external_product_items}
          //     // getTbodyProps={() => {
          //     //   return {
          //     //     style: {
          //     //       overflow: "visible"
          //     //     }
          //     //   }
          //     // }}
          //     // getTrProps={() =>{
          //     //   return {style: {
          //     //     overflow: "visible"
          //     //   }}
          //     // }}
          //     getTdProps={() => {
          //       return {
          //         style: {
          //           overflow: "visible",
          //         },
          //       };
          //     }}
          //     columns={[
          //       {
          //         Header: "Drug",
          //         // accessor: "unit_name",
          //         Cell: ({ original }) => {
          //           return (
          //             <Dropdown
          //               fluid
          //               upward={false}
          //               options={original.product_list?.map((p: any) => ({
          //                 key: p.id,
          //                 text: p.name,
          //                 value: p.name,
          //               }))}
          //             />
          //           );
          //         },
          //       },
          //       {
          //         Header: "Unit",
          //         accessor: "unit_name",
          //       },
          //     ]}
          //   />
          // }

          // externalInHospitalItems={
          //   <RowOrder
          //     options={props.masterOptions}
          //     targetKey={"external_product_items"}
          //     onEvent={props.onEvent}
          //     type={"ExternalProductItems"}
          //     items={props.medReconcileOrder?.external_product_items || []}
          //   />
          // }
          externalInHospitalItemsData={props.medReconcileOrder?.external_product_items || []}
          externalColumns={externalColumns}
          showExternalOutHospital={props.medReconcileOrder?.external_custom_items?.length > 0}
          // externalOutHospitalItems={
          //   <RowOrder
          //     options={props.masterOptions}
          //     targetKey={"external_custom_items"}
          //     onEvent={props.onEvent}
          //     type={"ExternalCustomItems"}
          //     items={props.medReconcileOrder?.external_custom_items || []}
          //   />

          // }
          externalOutHospitalItemsData={customDrugsSummary}
          customMin={customDrugsSummary.length}
          externalMin={(props.medReconcileOrder?.external_product_items || []).length}
          internalMin={(props.medReconcileOrder?.internal_items || []).length}
          onConfirmOrder={() => {
            props.onEvent({
              message: "MedReconcileDrugOrder",
              params: {
                action: "order",
                card: CARD_MED_RECONCILE,
                loading: `${CARD_MED_RECONCILE}ConfirmOrder`,
              },
            });
          }}
          loading={props.loadingStatus?.[`${CARD_MED_RECONCILE}ConfirmOrder`]}
          disabledConfirm={
            props.medReconcileOrder?.internal_items?.length === 0 &&
            props.medReconcileOrder?.external_product_items?.length === 0
          }
        />
      </Modal>
    </div>
  );
};

const RowOrder = (props: any) => {
  // console.log("RowOrder :", props);
  if (!props.items || props.items?.length === 0) {
    return <div />;
  }

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      {props.type === "ExternalProductItems"
        ? props.items.map((item: any, index: number) => {
            return (
              <div
                key={props.targetKey + index}
                style={{
                  display: "flex",
                  padding: "15px",
                  justifyContent: "space-evenly",
                  alignItems: "center",
                }}
              >
                <Dropdown
                  style={{ flex: 1 }}
                  selection={true}
                  options={item.product_list?.map((item: any) => {
                    return { ...item, text: item.name, value: item.id };
                  })}
                  disabled={item.has_continue_order}
                  value={item.selected_product}
                  onChange={(e: any, data: any) => {
                    props.onEvent({
                      message: "MedReconcileDrugOrder",
                      params: {
                        action: "change",
                        name: "selected_product",
                        value: data.value,
                        itemTarget: item,
                        targetKey: props.targetKey,
                      },
                    });
                  }}
                />
                <Input
                  style={{ flex: 1 }}
                  disabled={item.has_continue_order}
                  value={item.dose}
                  type="number"
                  onChange={(e: any) => {
                    props.onEvent({
                      message: "MedReconcileDrugOrder",
                      params: {
                        action: "change",
                        name: "dose",
                        value: e.target.value,
                        itemTarget: item,
                        targetKey: props.targetKey,
                      },
                    });
                  }}
                />
                <Dropdown
                  style={{ flex: 1 }}
                  options={props.options.unit}
                  disabled={item.has_continue_order}
                  value={item.unit}
                  onChange={(e: any, data: any) => {
                    props.onEvent({
                      message: "MedReconcileDrugOrder",
                      params: {
                        action: "change",
                        name: "unit",
                        value: data.value,
                        itemTarget: item,
                        targetKey: props.targetKey,
                      },
                    });
                  }}
                />
                <Dropdown
                  style={{ flex: 1 }}
                  options={props.options.route}
                  disabled={item.has_continue_order}
                  value={item.route}
                  onChange={(e: any, data: any) => {
                    props.onEvent({
                      message: "MedReconcileDrugOrder",
                      params: {
                        action: "change",
                        name: "route",
                        value: data.value,
                        itemTarget: item,
                        targetKey: props.targetKey,
                      },
                    });
                  }}
                />
                <Dropdown
                  style={{ flex: 1 }}
                  options={props.options.site}
                  disabled={item.has_continue_order}
                  value={item.site}
                  onChange={(e: any, data: any) => {
                    props.onEvent({
                      message: "MedReconcileDrugOrder",
                      params: {
                        action: "change",
                        name: "site",
                        value: data.value,
                        itemTarget: item,
                        targetKey: props.targetKey,
                      },
                    });
                  }}
                />

                <Dropdown
                  style={{ flex: 1 }}
                  options={props.options.frequency}
                  disabled={item.has_continue_order}
                  value={item.frequency}
                  onChange={(e: any, data: any) => {
                    props.onEvent({
                      message: "MedReconcileDrugOrder",
                      params: {
                        action: "change",
                        name: "frequency",
                        value: data.value,
                        itemTarget: item,
                        targetKey: props.targetKey,
                      },
                    });
                  }}
                />
                <Dropdown
                  style={{ flex: 1 }}
                  options={props.options.method}
                  disabled={item.has_continue_order}
                  value={item.method}
                  onChange={(e: any, data: any) => {
                    props.onEvent({
                      message: "MedReconcileDrugOrder",
                      params: {
                        action: "change",
                        name: "method",
                        value: data.value,
                        itemTarget: item,
                        targetKey: props.targetKey,
                      },
                    });
                  }}
                />
                <Checkbox
                  style={{ flex: 1 }}
                  label="One Dose"
                  onChange={(e: any, { checked }) => {
                    props.onEvent({
                      message: "MedReconcileDrugOrder",
                      params: {
                        action: "one_dose_check",
                        itemTarget: item,
                        value: checked,
                        targetKey: props.targetKey,
                      },
                    });
                  }}
                />
                <Button
                  color="red"
                  icon="trash alternate"
                  onClick={(e: any) => {
                    props.onEvent({
                      message: "MedReconcileDrugOrder",
                      params: {
                        action: "remove_item",
                        itemTarget: item,
                        targetKey: props.targetKey,
                      },
                    });
                  }}
                />
              </div>
            );
          })
        : props.items.map((item: any, index: number) => {
            return (
              <div
                key={props.targetKey + index}
                style={{
                  display: "flex",
                  padding: "15px",
                  // justifyContent: "space-evenly",
                  alignItems: "center",
                }}
              >
                <div style={{ flex: 1 }}>{item.selected_product_name}</div>
                <div style={{ flex: 1 }}>{item.label}</div>
                <div style={{ flex: 1 }}>{item.start_date}</div>
                <Checkbox
                  style={{ flex: 1 }}
                  label="One Dose"
                  onChange={(e: any, { checked }) => {
                    props.onEvent({
                      message: "MedReconcileDrugOrder",
                      params: {
                        action: "one_dose_check",
                        itemTarget: item,
                        value: checked,
                        targetKey: props.targetKey,
                      },
                    });
                  }}
                />
                <Button
                  color="red"
                  icon="trash alternate"
                  onClick={(e: any) => {
                    props.onEvent({
                      message: "MedReconcileDrugOrder",
                      params: {
                        action: "remove_item",
                        itemTarget: item,
                        targetKey: props.targetKey,
                      },
                    });
                  }}
                />
              </div>
            );
          })}
    </div>
  );
};

export default CardMedReconcile;
