import HeaderSapiensForm from "./HeaderSapiensForm";

export const AddHeaderMedCert = async (doc: any, createdDate?: any, disableHeader?: boolean) => {
  let headerSapiensForm: any = null;

  const HeaderDefaultForm = () => {
    return {
      pageMargins: [43, 100, 43, 40],
      header: () => {
        return {};
      },
      styles: {
        hospitalName: {
          fontSize: 7,
          bold: true,
        },
        hospitalInfo: {
          fontSize: 7,
        },
        header: {
          fontSize: 20,
          bold: true,
          margin: [0, 0, 0, 2],
        },
      },
    };
  };

  if (!disableHeader) {
    headerSapiensForm = await HeaderSapiensForm(doc);
  } else {
    headerSapiensForm = HeaderDefaultForm();
  }

  return {
    ...doc,
    ...headerSapiensForm,
    footer: () => {
      return {
        stack: [
          {
            columns: [
              {
                text: `หมายเหตุ`,
                bold: `true`,
                decorationStyle: ``,
                pageBreak: ``,
                preserveLeadingSpaces: true,
                color: ``,
                width: `auto`,
                font: `THSarabunNew`,
                fontSize: 15,
                alignment: `left`,
                decorationColor: ``,
                decoration: `underline`,
              },
              {
                alignment: `left`,
                pageBreak: ``,
                decorationStyle: ``,
                decoration: ``,
                text: ` : `,
                font: `THSarabunNew`,
                fontSize: 15,
                width: `auto`,
                preserveLeadingSpaces: true,
                bold: false,
                decorationColor: ``,
                color: ``,
              },
              {
                decorationStyle: ``,
                preserveLeadingSpaces: true,
                color: ``,
                pageBreak: ``,
                decoration: ``,
                font: `THSarabunNew`,
                fontSize: 15,
                text: ` ผู้ใดแก้ไข เปลี่ยนแปลง ปลอมหรือทำใบรับรองแพทย์อันเป็นเท็จ แต่เพียงส่วนหนึ่งส่วนใด หรือทั้งฉบับ`,
                bold: false,
                decorationColor: ``,
                width: `auto`,
                alignment: `left`,
              },
            ],
          },
          {
            alignment: `left`,
            pageBreak: ``,
            color: ``,
            font: `THSarabunNew`,
            fontSize: 15,
            decorationColor: ``,
            preserveLeadingSpaces: true,
            decoration: ``,
            decorationStyle: ``,
            width: `auto`,
            text: `			   หรือใช้หรืออ้างใบรับรองอันเป็นเท็จ จะต้องถูกดำเนินคดีตามกฎหมาย`,
            bold: false,
          },
          {
            text: `วันที่พิมพ์/Printed date : ${createdDate}`,
            alignment: "right",
            font: `Kanit`,
            style: "hospitalInfo",
            margin: [0, 16, 16, 16],
          },
        ],
        margin: [43, -36, 0, 0],
      };
    },
  };
};

export const AddHeaderMedCertEN = async (doc: any, createdDate?: any, disableHeader?: boolean) => {
  let headerSapiensForm: any = null;

  const HeaderDefaultForm = () => {
    return {
      pageMargins: [43, 100, 43, 40],
      header: () => {
        return {};
      },
      styles: {
        hospitalName: {
          fontSize: 7,
          bold: true,
        },
        hospitalInfo: {
          fontSize: 7,
        },
        header: {
          fontSize: 20,
          bold: true,
          margin: [0, 0, 0, 2],
        },
      },
    };
  };

  if (!disableHeader) {
    headerSapiensForm = await HeaderSapiensForm(doc);
  } else {
    headerSapiensForm = HeaderDefaultForm();
  }

  return {
    ...doc,
    ...headerSapiensForm,
    footer: () => {
      return {
        stack: [
          {
            columns: [
              {
                text: `Remark`,
                bold: `true`,
                decorationStyle: ``,
                pageBreak: ``,
                preserveLeadingSpaces: true,
                color: ``,
                width: `auto`,
                font: `THSarabunNew`,
                fontSize: 15,
                alignment: `left`,
                decorationColor: ``,
                decoration: `underline`,
              },
              {
                alignment: `left`,
                pageBreak: ``,
                decorationStyle: ``,
                decoration: ``,
                text: ` : `,
                font: `THSarabunNew`,
                fontSize: 15,
                width: `auto`,
                preserveLeadingSpaces: true,
                bold: false,
                decorationColor: ``,
                color: ``,
              },
              {
                decorationStyle: ``,
                preserveLeadingSpaces: true,
                color: ``,
                pageBreak: ``,
                decoration: ``,
                font: `THSarabunNew`,
                fontSize: 15,
                text: ` The person who modifiers or makes a false medical certificate only partial or the whole version or`,
                bold: false,
                decorationColor: ``,
                width: `auto`,
                alignment: `left`,
              },
            ],
          },
          {
            alignment: `left`,
            pageBreak: ``,
            color: ``,
            font: `THSarabunNew`,
            fontSize: 15,
            decorationColor: ``,
            preserveLeadingSpaces: true,
            decoration: ``,
            decorationStyle: ``,
            width: `auto`,
            text: ` 			 utilize or claim the false medical certificate must be prosecuted according to laws.`,
            bold: false,
          },
          {
            text: `วันที่พิมพ์/Printed date : ${createdDate}`,
            alignment: "right",
            font: `Kanit`,
            style: "hospitalInfo",
            margin: [0, 16, 16, 16],
          },
        ],
        margin: [43, -36, 0, 0],
      };
    },
  };
};
