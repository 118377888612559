import WasmController from "react-lib/frameworks/WasmController";

// APIS
import DischargeSummaryDetailByEmr from "issara-sdk/apis/DischargeSummaryDetailByEmr_apps_DPI";
import DiagnosisDetailByEmr from "issara-sdk/apis/DiagnosisDetailByEmr_apps_DPI";
import ProcedureByEmr from "issara-sdk/apis/ProcedureByEmr_apps_DPI";
import AdmissionList from "issara-sdk/apis/AdmissionList_apps_DPI";
import DRGDetail from "issara-sdk/apis/DRGDetail_apps_DPI";
import icd10KeyUp from "issara-sdk/apis/icd10KeyUp_core";
import icd9cmKeyUp from "issara-sdk/apis/icd9cmKeyUp_core";
import DischargeSummaryList from "issara-sdk/apis/DischargeSummaryList_apps_DPI";
import DiagnosisCreate from "issara-sdk/apis/DiagnosisCreate_apps_DPI";
import ProcedureCreate from "issara-sdk/apis/ProcedureCreate_apps_DPI";
import DischargeSummaryDetail from "issara-sdk/apis/DischargeSummaryDetail_apps_DPI";
import AdmitOrderRoomItemDetail from "issara-sdk/apis/AdmitOrderRoomItemDetail_apps_ADM"
import UserPermissionView from "issara-sdk/apis/UserPermissionView_users";
import MedicationReconciliationDischargeByEncounterList from "issara-sdk/apis/MedicationReconciliationDischargeByEncounterList_apps_TPD";

// Interface
import { State as MainState } from "../../MainHISInterface";

// DrugOrderQueueList_apps_TPD
export type State = Partial<{
  selectedAdmitOrderRoomItem?: any;
  loadingStatus?: any;
  dischargeSummaryPermission?: {
    config_TPD_ENABLE_MED_RECONCILIATION_V2?: boolean;
  },
  DischargeSummarySequence: {
    id: number | null;
    discharge: any;
    diagnosis: any;
    procedure: any;
    admission: any;
    drgDetail: any;
    drgErrorMsg: any;
    planDischargeType: string;
    datePlanDischarge: string;
    dischargeStatus: string;
    readmissionType: string;
    brief: string;
    course: string;
    condition: string;
    followup: string;
    provisionalItems: any[];
    diagnosisItems: any[];
    procedureItems: any[];
    sequenceIndex: string;
  } | null;
}>;

type Picked = Partial<Pick<
  MainState,
  | "selectedEmr"
  | "selectedEncounter"
  | "errorMessage"
  | "successMessage"
  | "loadingStatus"
>>

export const StateInitial: State = {
  dischargeSummaryPermission: {
    config_TPD_ENABLE_MED_RECONCILIATION_V2: false
  },
  DischargeSummarySequence: null,
};

export type Event =
  | { message: "RunSequence"; params: {} }
  | { message: "GetMasterData"; params: {} };

export type Data = {
  user?: number;
  division?: number;
};

export const DataInitial = {};

type Handler = (
  controller: WasmController<State & Picked, Event, Data>,
  params?: any
) => any;

const ItemInitial = {
  medterm: "",
  icdterm: "",
  icdcode: "",
  icd10_id: null,
  sublevel: false,
  medterm_id: null,
};

const ICDAPI: Record<string, any> = {
  ICD10: icd10KeyUp,
  ICD9CM: icd9cmKeyUp,
};

export const formatItems = (
  primary: any,
  secondary: any[] = [],
  icdType: string
) => {
  primary = primary || {};
  let items: any[] = [];

  const formatData = (item: any) => {
    const data: any = {
      ...item,
      medterm: item.medical_description || "",
      icdterm: item[`${icdType}_term`] || item[`icd_term`] || "",
      icdcode: item[`${icdType}_code`] || item[`icd_code`] || "",
      icd10_id: item[`${icdType}`] || null,
      medterm_id: item[`${icdType}_med_term`] || null,
      sublevel: false,
    };

    if (!["undefined", "number"].includes(typeof item.type)) {
      data.subType = item.type;
    }

    return data;
  };

  items = secondary.map((item: any) => formatData(item));

  items = [formatData(primary), ...items];

  return items;
};

export const Start: Handler = async (controller, params) => {
  const state = controller.getState();

  if (!state.DischargeSummarySequence) return;

  // controller.setState({
  //   loadingStatus: { ...state.loadingStatus, [params.card]: true },
  // })
  // Master data
  controller.handleEvent({
    message: "GetMasterData",
    params: {
      masters: [
        ["planDischargeType", {}],
        ["dischargeStatus", {}],
        ["readmissionType", {}],
        ["diagnosisType", {}],
      ],
    },
  });


  const getAPI = [
    DischargeSummaryDetailByEmr,
    DiagnosisDetailByEmr,
    ProcedureByEmr,
    AdmissionList,
    // DRGDetail,
  ];

  const promiseArray = getAPI.map((api) =>
    api.retrieve({
      apiToken: controller.apiToken,
      pk: state.selectedEmr?.id,
    })
  );

  const userPermission = UserPermissionView.post({
    apiToken: controller.apiToken,
    data: {
      config_TPD_ENABLE_MED_RECONCILIATION_V2: false,
    }
  })

  promiseArray.push(userPermission)
  const [discharge, diagnosis, procedure, admission, permission] = await Promise.all(
    promiseArray
  );

  const detail = discharge[0] || {};
  // controller.setState({
  //   loadingStatus: { ...state.loadingStatus, [params.card]: false },
  // })

  const dischargeSequence = {
    ...state.DischargeSummarySequence,
    ...(discharge[0] || {}),
    dischargeStatus: detail.discharge_status,
    followup: detail.followup_plan,
    datePlanDischarge: detail.plan_discharge,
    planDischargeType: detail.plan_discharge_type,
    readmissionType: detail.readmission_type,
    diagnosis: diagnosis[0] || {},
    procedure: procedure[0] || {},
    admission: admission[0] || {},
    provisionalItems: formatItems(
      admission[0].provisional_diagnosis?.primary,
      admission[0].provisional_diagnosis?.secondary,
      "icd10"
    ),
    diagnosisItems: formatItems(
      diagnosis[0].primary,
      diagnosis[0].secondary,
      "icd10"
    ),
    procedureItems: formatItems(
      procedure[0].primary_procedure,
      procedure[0].secondary_procedures,
      "icd9cm"
    ),
    sequenceIndex: "SearchAndEdit",
  }

  controller.setState({
    DischargeSummarySequence: dischargeSequence
  }, async () => {
    let state = controller.getState()

    controller.setState({
      loadingStatus: { ...state.loadingStatus, [params.cardDrg]: true },
    })

    const drg = await DRGDetail.retrieve({
      apiToken: controller.apiToken,
      pk: state.selectedEmr?.id,
      extra: {
        division: controller.data.division
      }
    })

    controller.setState({
      loadingStatus: { ...state.loadingStatus, [params.cardDrg]: false },
    })

    state = controller.getState()

    if (!state.DischargeSummarySequence) return;

    controller.setState({
      dischargeSummaryPermission: { ...(permission?.[0] || {}) },
      DischargeSummarySequence: {
        ...state.DischargeSummarySequence,
        drgDetail: drg[0] || {},
        drgErrorMsg: drg[1],
      }
    })
  });
};

export const HandleSearch: Handler = async (controller, params) => {
  let result: any = [null, null, null];
  const isSubLevel = params.method === "sublevel";

  if (params.search.length >= 3) {
    result = await ICDAPI[params.icdType].post({
      apiToken: controller.apiToken,
      method: params.method,
      data: {
        [isSubLevel ? "icdcode" : params.method]: params.search,
      },
    });
  }

  const data = {
    ...(params.data || {}),
    ...(isSubLevel ? { icdterm: "Please Select Sublevel" } : {}),
    table: result[0]?.response || [],
  };

  params.onChange?.(data)

  if (!params.clearSearchList) {
    params.onClear?.({ ...params, action: "clear" })
  }
}

export const HandleClear: Handler = (controller, params) => {
  const data = {
    ...(params.data || {}),
    ...ItemInitial,
    ...(params.method && params.method !== "medterm"
      ? { [params.method]: params.search }
      : {}),
  };

  params.onChange?.(data)
}

export const HandleSelect: Handler = (controller, params) => {
  if (params.selectItem.sublevel) {
    return params.onSearch({
      ...params,
      action: "search",
      method: "sublevel",
      search: params.selectItem.icdcode,
      clearSearchList: true,
    })
  }

  const data = {
    ...(params.data || {}),
    ...params.selectItem,
    table: [],
  };

  params.onChange?.(data)
}

export const SearchAndEdit: Handler = async (controller, params) => {
  const state = controller.getState();

  if (!state.DischargeSummarySequence) {
    return;
  }

  const dischargeSummary = state.DischargeSummarySequence;

  switch (params.action) {
    case "search":
      const searchData = (dischargeSummary as any)[params.key][params.index]
      HandleSearch(controller, {
        ...params,
        data: searchData,
        onChange: (data: any) => {
          (dischargeSummary as any)[params.key][params.index] = data;

          controller.setState({
            DischargeSummarySequence: { ...dischargeSummary },
          });
        },
        callback: (params: any) =>
          controller.handleEvent({ message: "RunSequence", params, })
      })

      break;
    case "select":
      const selectData = (dischargeSummary as any)[params.key][params.index]

      HandleSelect(controller, {
        ...params,
        data: selectData,
        onSearch: (params: any) => controller.handleEvent({
          message: "RunSequence",
          params
        }),
        onChange: (data: any) => {
          (dischargeSummary as any)[params.key][params.index] = data;

          controller.setState({
            DischargeSummarySequence: { ...dischargeSummary },
          });
        },
      })
      break;
    case "add_diag":
      dischargeSummary.diagnosisItems.push({
        ...ItemInitial,
        active_disease: false,
        subType: "SECONDARY_COMORBID",
      });

      controller.setState({
        DischargeSummarySequence: { ...dischargeSummary },
      });
      break;
    case "add_procedure":
      controller.setState({
        DischargeSummarySequence: {
          ...dischargeSummary,
          procedureItems: [...dischargeSummary.procedureItems, ItemInitial],
        },
      });
      break;
    case "clear":
      const clearData = (dischargeSummary as any)[params.key][params.index]
      HandleClear(controller, {
        ...params,
        data: clearData,
        onChange: (data: any) => {
          (dischargeSummary as any)[params.key][params.index] = data;

          controller.setState({
            DischargeSummarySequence: { ...dischargeSummary },
          });
        },
      })
      break;
    case "remove":
      if (params.index !== 0) {
        const filterItems = (dischargeSummary as any)?.[params.key]?.filter(
          (_: any, idx: number) => idx !== params.index
        );

        controller.setState({
          DischargeSummarySequence: {
            ...state.DischargeSummarySequence,
            [params.key]: filterItems,
          },
        });
      }
      break;
    case "change":
      (dischargeSummary as any)[params.key][params.index][params.data.name] =
        params.data.value;

      controller.setState({
        DischargeSummarySequence: { ...dischargeSummary },
      });
      break;
    case "save":
      controller.setState(
        {
          DischargeSummarySequence: {
            ...dischargeSummary,
            sequenceIndex: "CreateAndUpdate",
          },
        },
        () => {
          controller.handleEvent({
            message: "RunSequence",
            params: { ...params },
          });
        }
      );
      break;

    default:
      break;
  }
};

export const formatData = (item: any, icdType: string) => {
  if (!item.icd10_id) {
    item = { ...item, medterm: "", icdterm: "", icdcode: "" };
  }

  delete item.subType
  delete item.type
  delete item.table

  return {
    medical_description: item.medterm || "",
    [`${icdType}`]: item.icd10_id || null,
    [`${icdType}_med_term`]: item.medterm_id || null,
    detail: "",
    [`${icdType}_term`]: item.icdterm || null,
    [`${icdType}_code`]: item.icdcode || null,
  };
};

export const CreateAndUpdate: Handler = async (controller, params) => {
  const state = controller.getState();

  if (!state.DischargeSummarySequence) {
    return;
  }

  controller.setState({
    loadingStatus: { ...state.loadingStatus, [params.card]: true },
  });

  const dischargeSummary = state.DischargeSummarySequence;
  const primaryDiag = dischargeSummary.diagnosisItems[0] || {};
  const secondaryDiag = (dischargeSummary.diagnosisItems || []).slice(1);
  const primaryProcedure = dischargeSummary.procedureItems[0] || {};
  const secondaryProcedure = (dischargeSummary.procedureItems || []).slice(1);

  const dischargeData = {
    brief: dischargeSummary.brief || "",
    condition: dischargeSummary.condition || "",
    course: dischargeSummary.course || "",
    discharge_status: dischargeSummary.dischargeStatus || null,
    emr: state.selectedEmr?.id || null,
    encounter: state.selectedEncounter?.id || null,
    followup_plan: dischargeSummary.followup || "",
    hasError: false,
    id: dischargeSummary?.id || null,
    plan_discharge: dischargeSummary.datePlanDischarge || "",
    plan_discharge_type: dischargeSummary.planDischargeType || null,
    readmission_type: dischargeSummary.readmissionType || null,
  };

  const diagnosisData = {
    hasError: false,
    emr: state.selectedEmr?.id || null,
    secondary: secondaryDiag.map((item: any) => ({
      ...item,
      type: item.subType,
      ...formatData(item, "icd10"),
      active_disease: item.active_disease || false,
    })),
    primary: {
      ...primaryDiag,
      ...formatData(primaryDiag, "icd10"),
      active_disease: primaryDiag.active_disease || false,
    },
  };

  const procedureData = {
    hasError: false,
    emr: state.selectedEmr?.id || null,
    secondary_procedures: secondaryProcedure.map((item: any) => ({
      ...item,
      ...formatData(item, "icd9cm"),
    })),
    primary_procedure: {
      ...primaryProcedure,
      ...formatData(primaryProcedure, "icd9cm"),
    },
  };

  const promiseArr: any = [];

  if (!dischargeSummary?.id) {
    const create = await DischargeSummaryList.create({
      apiToken: controller.apiToken,
      data: dischargeData,
      extra: {
        device: (controller.data as any).device,
        division: controller.data.division,
      },
    });

    if (create[1]) {
      return controller.setState({
        DischargeSummarySequence: {
          ...dischargeSummary,
          sequenceIndex: "SearchAndEdit",
        },
        loadingStatus: { ...state.loadingStatus, [params.card]: false },
        errorMessage: { ...state.errorMessage, [params.card]: create[1] },
      });
    }

    if (state.dischargeSummaryPermission?.config_TPD_ENABLE_MED_RECONCILIATION_V2) {
      const medReconcileDischarge = MedicationReconciliationDischargeByEncounterList.retrieve({
        pk: state.selectedEncounter?.id,
        apiToken: controller.apiToken,
        extra: {
          device: (controller.data as any).device,
          division: controller.data.division,
        },
      });
      promiseArr.push(medReconcileDischarge);
    }

    // promiseArr.push(create);
  } else {
    const update = DischargeSummaryDetail.update({
      pk: dischargeSummary?.id,
      apiToken: controller.apiToken,
      data: dischargeData,
      extra: {
        device: (controller.data as any).device,
        division: controller.data.division,
      },
    });

    promiseArr.push(update);
  }

  const createDiagnosis = DiagnosisCreate.create({
    apiToken: controller.apiToken,
    data: diagnosisData as any,
    extra: {
      device: (controller.data as any).device,
      division: controller.data.division,
    },
  });

  promiseArr.push(createDiagnosis);

  const createProcedure = ProcedureCreate.create({
    apiToken: controller.apiToken,
    data: procedureData as any,
    extra: {
      device: (controller.data as any).device,
      division: controller.data.division,
    },
  });

  promiseArr.push(createProcedure);

  const response = await Promise.all(promiseArr);

  const errors = response
    .map((res: any) => (typeof res[1] === "string" ? "" : res[1]))
    .filter(Boolean);

  controller.setState({
    loadingStatus: { ...state.loadingStatus, [params.card]: false },
  });

  if (!!errors.length) {
    return controller.setState({
      DischargeSummarySequence: {
        ...dischargeSummary,
        sequenceIndex: "SearchAndEdit",
      },
      errorMessage: { ...state.errorMessage, [params.card]: errors },
    });
  } else {
    controller.setState({
      successMessage: { ...state.successMessage, [params.card]: true },
    });

    if (!dischargeSummary?.id && state.selectedAdmitOrderRoomItem?.id) {
      const admitRes = await AdmitOrderRoomItemDetail.retrieve({
        pk: state.selectedAdmitOrderRoomItem?.id,
        apiToken: controller.apiToken,
      })

      controller.setState({
        selectedAdmitOrderRoomItem: admitRes[0] || null
      });
    }
  }

  controller.setState(
    {
      DischargeSummarySequence: {
        ...dischargeSummary,
        sequenceIndex: "START",
      },
    },
    () =>
      controller.handleEvent({ message: "RunSequence", params: { ...params } })
  );
};
