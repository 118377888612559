import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  Form,
  FormGroup,
  TextArea,
  Checkbox,
  Input
} from 'semantic-ui-react'
import {
  DateTextBox
} from 'react-lib/apps/common'

const CardQuarantineCertificateUX = (props: any) => {
    return(
      <div>
        <Form>
          <FormGroup
            className="ui form"
            style={{display: "flex", padding: "1em 0em 0em 1em"}}>
            <label
              style={{display: "flex",  alignItems: "center", fontWeight: "bold"}}>
              ประวัติการป่วย :
            </label>
          </FormGroup>
          <FormGroup
            className="ui form"
            style={{display: "flex", padding: "0em 0em 0em 1em",  gap: "1em"}}>
            <label
              style={{display: "flex",  alignItems: "center"}}>
              ทำการตรวจ RT-PCR พบเชื้อ COVID-19 ในวันที่ 
            </label>
            <DateTextBox
              disabled={props.onHandleDisableForm}
              id="CardQuarantineCertificate-DateTextBox-DetectDate"
              minDate={props.onDetectMinDate}
              onChange={(date: string) => props.onHandleChangeDate("detectDate", date)}
              value={props.onDetectDate}>
            </DateTextBox>
          </FormGroup>
          <FormGroup
            className="ui form"
            style={{display: "flex", padding: "0em 0em 1em 1em",  gap: "6em"}}>
            <label
              style={{display: "flex",  alignItems: "center"}}>
              ผู้ป่วยได้เสร็จสิ้นกระบวนการรักษา / กักตัวตามคำแนะนำของกระทรวงสาธารณสุขแล้ว
            </label>
          </FormGroup>
          <FormGroup
            className="ui form"
            style={{display: "flex", padding: "0em 0em 0em 1em"}}>
            <label
              style={{display: "flex",  alignItems: "center", fontWeight: "bold"}}>
              ข้อสรุป และ/หรือ ข้อเสนอแนะ
            </label>
          </FormGroup>
          <FormGroup
            style={{padding: "0em 0em 1em 2em"}}>
            <label
              style={{width: "30.8134em"}}>
              หลังการติดเชื้อ COVID-19 : เมื่อหายป่วยตามอาการทางคลินิก แนะนำให้สวมหน้ากากอนามัย ล้างมือ เว้นระยะห่างทางสังคม และหลีกเลี่ยงสถานที่แออัด
            </label>
          </FormGroup>
          <FormGroup
            className="ui form"
            style={{display: "flex", padding: "0em 0em 0em 1em"}}>
            <label
              style={{display: "flex",  alignItems: "center", fontWeight: "bold"}}>
              ข้อแนะนำอื่น ๆ
            </label>
          </FormGroup>
          <FormGroup
            className="ui form"
            style={{display: "flex", padding: "0em 0em 0em 2em"}}>
            <TextArea
              disabled={props.onHandleDisableForm}
              id="CardQuarantineCertificate-TextArea-Comment"
              onChange={(e) => props.onHandleChangeText("comment", e)}
              style={{width: "30em"}}
              value={props.onComment}>
            </TextArea>
          </FormGroup>
          <FormGroup
            className="ui form"
            style={{display: "flex", padding: "0em 0em 0em 2em", gap: "1em"}}>
            <Checkbox
              checked={props.onShouldAbsence}
              disabled={props.onHandleDisableForm}
              id="CardQuarantineCertificate-Checkbox-ShouldAbsence"
              label="ระยะเวลาที่ควรพักงาน"
              onChange={(e, data) => props.onHandleChangeCheckbox(e, data)}
              style={{display: "flex",  alignItems: "center",  padding: "0em 2.1em 0em 0em"}}>
            </Checkbox>
            <Input
              disabled={props.onHandleDurationDisable() || props.onHandleDisableForm}
              id="CardQuarantineCertificate-Input-AbsenceDuration"
              onChange={(e) => props.onHandleChangeText("absenceDuration", e)}
              style={{width: "6em"}}
              value={props.onAbsenceDuration}>
            </Input>
            <label
              style={{display: "flex",  alignItems: "center"}}>
              วัน
            </label>
          </FormGroup>
          <FormGroup
            className="ui form"
            style={{display: "flex", padding: "0em 0em 0em 2em", gap: "1em"}}>
            <label
              style={{display: "flex",  alignItems: "center"}}>
              ตั้งแต่
            </label>
            <DateTextBox
              disabled={props.onHandleDurationDisable() || props.onHandleDisableForm}
              id="CardQuarantineCertificate-DateTextBox-StartDate"
              minDate={props.onAbsenceMinDate}
              onChange={(date: string) => props.onHandleChangeDate("absenceStartDate", date)}
              style={{width: "12em"}}
              value={props.onAbsenceStartDate}>
            </DateTextBox>
            <label
              style={{display: "flex",  alignItems: "center"}}>
              ถึง
            </label>
            <DateTextBox
              disabled={props.onHandleDurationDisable() || props.onHandleDisableForm}
              id="CardQuarantineCertificate-DateTextBox-EndDate"
              minDate={props.onAbsenceMinDate}
              onChange={(date: string) => props.onHandleChangeDate("absenceEndDate", date)}
              style={{width: "12em"}}
              value={props.onAbsenceEndDate}>
            </DateTextBox>
          </FormGroup>
          <FormGroup
            className="ui form"
            style={{display: "flex", justifyContent: "flex-end"}}>
            <label>
              {props.onCloseSelected}
            </label>
          </FormGroup>
        </Form>
      </div>
    )
}


export default CardQuarantineCertificateUX

export const screenPropsDefault = {"language":"TH"}

/* Date Time : Thu Aug 01 2024 16:35:59 GMT+0700 (Indochina Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "props": {
        "className": {
          "type": "value",
          "value": ""
        },
        "id": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 0
    },
    {
      "from": "semantic-ui-react",
      "id": 246,
      "name": "Form",
      "parent": 0,
      "props": {
      },
      "seq": 246,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 247,
      "name": "FormGroup",
      "parent": 246,
      "props": {
        "className": {
          "type": "value",
          "value": "ui form"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", padding: \"1em 0em 0em 1em\"}"
        }
      },
      "seq": 247,
      "void": false
    },
    {
      "from": null,
      "id": 248,
      "name": "label",
      "parent": 247,
      "props": {
        "children": {
          "type": "value",
          "value": "ประวัติการป่วย :"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\",  alignItems: \"center\", fontWeight: \"bold\"}"
        }
      },
      "seq": 248,
      "void": false
    },
    {
      "from": "react-lib/apps/common",
      "id": 249,
      "name": "DateTextBox",
      "parent": 270,
      "props": {
        "disabled": {
          "type": "code",
          "value": "props.onHandleDisableForm"
        },
        "id": {
          "type": "value",
          "value": "CardQuarantineCertificate-DateTextBox-DetectDate"
        },
        "minDate": {
          "type": "code",
          "value": "props.onDetectMinDate"
        },
        "onChange": {
          "type": "code",
          "value": "(date: string) => props.onHandleChangeDate(\"detectDate\", date)"
        },
        "value": {
          "type": "code",
          "value": "props.onDetectDate"
        }
      },
      "seq": 271,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 250,
      "name": "FormGroup",
      "parent": 246,
      "props": {
        "className": {
          "type": "value",
          "value": "ui form"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", padding: \"0em 0em 1em 1em\",  gap: \"6em\"}"
        }
      },
      "seq": 252,
      "void": false
    },
    {
      "from": null,
      "id": 251,
      "name": "label",
      "parent": 250,
      "props": {
        "children": {
          "type": "value",
          "value": "ผู้ป่วยได้เสร็จสิ้นกระบวนการรักษา / กักตัวตามคำแนะนำของกระทรวงสาธารณสุขแล้ว"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\",  alignItems: \"center\"}"
        }
      },
      "seq": 251,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 252,
      "name": "FormGroup",
      "parent": 246,
      "props": {
        "className": {
          "type": "value",
          "value": "ui form"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", padding: \"0em 0em 0em 1em\"}"
        }
      },
      "seq": 256,
      "void": false
    },
    {
      "from": null,
      "id": 253,
      "name": "label",
      "parent": 252,
      "props": {
        "children": {
          "type": "value",
          "value": "ข้อสรุป และ/หรือ ข้อเสนอแนะ"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\",  alignItems: \"center\", fontWeight: \"bold\"}"
        }
      },
      "seq": 253,
      "void": false
    },
    {
      "from": null,
      "id": 255,
      "name": "label",
      "parent": 255,
      "props": {
        "children": {
          "type": "value",
          "value": "หลังจากการติดเชื้อ COVID-19 : เมื่อหายป่วยตามอาการทางคลินิก แนะนำให้สวมหน้ากากอนามัย ล้างมือ เว้นระยะห่างทางสังคม และหลีกเลี่ยงสถานที่แออัด"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\",  alignItems: \"center\"}"
        }
      },
      "seq": 255,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 256,
      "name": "FormGroup",
      "parent": 246,
      "props": {
        "className": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{padding: \"0em 0em 1em 2em\"}"
        }
      },
      "seq": 258,
      "void": false
    },
    {
      "from": null,
      "id": 257,
      "name": "label",
      "parent": 256,
      "props": {
        "children": {
          "type": "value",
          "value": "หลังการติดเชื้อ COVID-19 : เมื่อหายป่วยตามอาการทางคลินิก แนะนำให้สวมหน้ากากอนามัย ล้างมือ เว้นระยะห่างทางสังคม และหลีกเลี่ยงสถานที่แออัด"
        },
        "style": {
          "type": "code",
          "value": "{width: \"30.8134em\"}"
        }
      },
      "seq": 257,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 258,
      "name": "FormGroup",
      "parent": 246,
      "props": {
        "className": {
          "type": "value",
          "value": "ui form"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", padding: \"0em 0em 0em 1em\"}"
        }
      },
      "seq": 262,
      "void": false
    },
    {
      "from": null,
      "id": 259,
      "name": "label",
      "parent": 258,
      "props": {
        "children": {
          "type": "value",
          "value": "ข้อแนะนำอื่น ๆ"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\",  alignItems: \"center\", fontWeight: \"bold\"}"
        }
      },
      "seq": 259,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 260,
      "name": "TextArea",
      "parent": 272,
      "props": {
        "disabled": {
          "type": "code",
          "value": "props.onHandleDisableForm"
        },
        "id": {
          "type": "value",
          "value": "CardQuarantineCertificate-TextArea-Comment"
        },
        "onChange": {
          "type": "code",
          "value": "(e) => props.onHandleChangeText(\"comment\", e)"
        },
        "style": {
          "type": "code",
          "value": "{width: \"30em\"}"
        },
        "value": {
          "type": "code",
          "value": "props.onComment"
        }
      },
      "seq": 260,
      "void": true
    },
    {
      "from": "semantic-ui-react",
      "id": 261,
      "name": "FormGroup",
      "parent": 246,
      "props": {
        "className": {
          "type": "value",
          "value": "ui form"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", padding: \"0em 0em 0em 2em\", gap: \"1em\"}"
        },
        "widths": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 272,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 262,
      "name": "FormGroup",
      "parent": 246,
      "props": {
        "className": {
          "type": "value",
          "value": "ui form"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", padding: \"0em 0em 0em 2em\", gap: \"1em\"}"
        }
      },
      "seq": 273,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 263,
      "name": "Checkbox",
      "parent": 261,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.onShouldAbsence"
        },
        "disabled": {
          "type": "code",
          "value": "props.onHandleDisableForm"
        },
        "id": {
          "type": "value",
          "value": "CardQuarantineCertificate-Checkbox-ShouldAbsence"
        },
        "label": {
          "type": "value",
          "value": "ระยะเวลาที่ควรพักงาน"
        },
        "onChange": {
          "type": "code",
          "value": "(e, data) => props.onHandleChangeCheckbox(e, data)"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\",  alignItems: \"center\",  padding: \"0em 2.1em 0em 0em\"}"
        }
      },
      "seq": 263,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 264,
      "name": "Input",
      "parent": 261,
      "props": {
        "disabled": {
          "type": "code",
          "value": "props.onHandleDurationDisable() || props.onHandleDisableForm"
        },
        "id": {
          "type": "value",
          "value": "CardQuarantineCertificate-Input-AbsenceDuration"
        },
        "onChange": {
          "type": "code",
          "value": "(e) => props.onHandleChangeText(\"absenceDuration\", e)"
        },
        "style": {
          "type": "code",
          "value": "{width: \"6em\"}"
        },
        "value": {
          "type": "code",
          "value": "props.onAbsenceDuration"
        }
      },
      "seq": 264,
      "void": false
    },
    {
      "from": null,
      "id": 265,
      "name": "label",
      "parent": 261,
      "props": {
        "children": {
          "type": "value",
          "value": "วัน"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\",  alignItems: \"center\"}"
        }
      },
      "seq": 265,
      "void": false
    },
    {
      "from": null,
      "id": 266,
      "name": "label",
      "parent": 262,
      "props": {
        "children": {
          "type": "value",
          "value": "ตั้งแต่"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\",  alignItems: \"center\"}"
        }
      },
      "seq": 266,
      "void": false
    },
    {
      "from": "react-lib/apps/common",
      "id": 267,
      "name": "DateTextBox",
      "parent": 262,
      "props": {
        "disabled": {
          "type": "code",
          "value": "props.onHandleDurationDisable() || props.onHandleDisableForm"
        },
        "id": {
          "type": "value",
          "value": "CardQuarantineCertificate-DateTextBox-StartDate"
        },
        "minDate": {
          "type": "code",
          "value": "props.onAbsenceMinDate"
        },
        "onChange": {
          "type": "code",
          "value": "(date: string) => props.onHandleChangeDate(\"absenceStartDate\", date)"
        },
        "style": {
          "type": "code",
          "value": "{width: \"12em\"}"
        },
        "value": {
          "type": "code",
          "value": "props.onAbsenceStartDate"
        }
      },
      "seq": 267,
      "void": false
    },
    {
      "from": null,
      "id": 268,
      "name": "label",
      "parent": 262,
      "props": {
        "children": {
          "type": "value",
          "value": "ถึง"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\",  alignItems: \"center\"}"
        }
      },
      "seq": 268,
      "void": false
    },
    {
      "from": "react-lib/apps/common",
      "id": 269,
      "name": "DateTextBox",
      "parent": 262,
      "props": {
        "disabled": {
          "type": "code",
          "value": "props.onHandleDurationDisable() || props.onHandleDisableForm"
        },
        "id": {
          "type": "value",
          "value": "CardQuarantineCertificate-DateTextBox-EndDate"
        },
        "minDate": {
          "type": "code",
          "value": "props.onAbsenceMinDate"
        },
        "onChange": {
          "type": "code",
          "value": "(date: string) => props.onHandleChangeDate(\"absenceEndDate\", date)"
        },
        "style": {
          "type": "code",
          "value": "{width: \"12em\"}"
        },
        "value": {
          "type": "code",
          "value": "props.onAbsenceEndDate"
        }
      },
      "seq": 269,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 270,
      "name": "FormGroup",
      "parent": 246,
      "props": {
        "className": {
          "type": "value",
          "value": "ui form"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", padding: \"0em 0em 0em 1em\",  gap: \"1em\"}"
        }
      },
      "seq": 250,
      "void": false
    },
    {
      "from": null,
      "id": 271,
      "name": "label",
      "parent": 270,
      "props": {
        "children": {
          "type": "value",
          "value": "ทำการตรวจ RT-PCR พบเชื้อ COVID-19 ในวันที่ "
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\",  alignItems: \"center\"}"
        }
      },
      "seq": 249,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 272,
      "name": "FormGroup",
      "parent": 246,
      "props": {
        "className": {
          "type": "value",
          "value": "ui form"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", padding: \"0em 0em 0em 2em\"}"
        }
      },
      "seq": 270,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 273,
      "name": "FormGroup",
      "parent": 246,
      "props": {
        "className": {
          "type": "value",
          "value": "ui form"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", justifyContent: \"flex-end\"}"
        }
      },
      "seq": 274,
      "void": false
    },
    {
      "from": null,
      "id": 274,
      "name": "label",
      "parent": 273,
      "props": {
        "children": {
          "type": "value",
          "value": "{props.onCloseSelected}"
        }
      },
      "seq": 274,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 80,
  "name": "CardQuarantineCertificateUX",
  "project": "IsHealth",
  "screenPropsDefault": {
    "language": "TH"
  },
  "width": 55
}

*********************************************************************************** */
