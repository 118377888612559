import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  Form,
  FormGroup,
  FormField,
  Checkbox,
  Button,
  Icon,
  Dropdown
} from 'semantic-ui-react'
import {
  Table
} from 'react-lib/frameworks/Table'

const SubIcdDetailUX = (props: any) => {

    const isMounted = React.useRef(true);
    const intl = useIntl();

    React.useEffect(() => {
        return () => {
            isMounted.current = false;
        };
    }, []);


    return(
      <div
        style={{  width: "100%" }}>
        <div
          className="ui divider horizontal"
          style={{display: props.showHeader ? "" : "none", ...(props.primaryTextStyle || {})}}>
          {props.primaryText}
        </div>
        <div
          className="ui segment"
          style={{backgroundColor:  "white", ...props.boxStyle }}>

          <div
            className="ui form"
            style={{display: props.showSubType ? "" : "none"}}>

            <FormGroup
              inline={true}>
              <FormField
                className="required"
                inline={true}
                width={10}>
                <label
                  style={{width: "100px", margin: 0}}>
                  ประเภท
                </label>
                <Dropdown
                  disabled={props.readOnly}
                  name="subType"
                  onChange={props.onChangeValue}
                  options={props.typeOptions}
                  selection={true}
                  style={{width: "100%", opacity: 1}}
                  value={props.subType || ""}>
                </Dropdown>
              </FormField>
              <FormField>
                <Button
                  className="icon"
                  color="red"
                  onClick={props.onDelete}
                  style={{display: props.deletable ? "" : "none"}}>
                  <Icon
                    name={"minus"}>
                  </Icon>
                </Button>
              </FormField>
              <FormField>
                <Button
                  className="icon"
                  color="green"
                  onClick={props.onAdd}
                  style={{display: props.addable? "" : "none"}}>
                  <Icon
                    name={"plus"}>
                  </Icon>
                </Button>
              </FormField>
            </FormGroup>
          </div>
          <Form>
            <FormGroup
              inline={true}>
              <FormField
                inline={true}
                width={6}>
                <div
                  style={{display: "flex",width: props.requiredMedicalTerm ? "130px": "115px"}}>

                  <label
                    style={{fontWeight: "bold", textAlign: "center",}}>
                    Medical Term
                  </label>
                  <label
                    style={{color: "red", display : props.requiredMedicalTerm ? "":"none"}}>
                    *
                  </label>
                </div>
                <div
                  style={{width: "100%"}}>
                  {props.SearchBoxMedTerm}
                </div>
              </FormField>
              <FormField
                inline={true}
                width={6}>
                <label
                  style={{fontWeight: "bold", textAlign: "center", width: "114px"}}>
                  {`${props.icdType || ""} Term`}
                </label>
                <div
                  style={{width: "100%"}}>
                  {props.SearchBoxICDTerm}
                </div>
              </FormField>
              <FormField
                inline={true}
                width={6}>
                <label
                  style={{fontWeight: "bold", textAlign: "center", width: "116px"}}>
                  {`${props.icdType || ""} Code`}
                </label>
                <div
                  style={{width: "100%"}}>
                  {props.SearchBoxICDCode}
                </div>
              </FormField>
              <FormField>
                <Checkbox
                  checked={props.active_disease}
                  label="Active Disease"
                  name="active_disease"
                  onChange={props.onChangeValue}
                  readOnly={props.readOnly}
                  style={{ fontWeight: "bold", display: props.hideActiveDisease ? "none" : "", width: "130px"}}>
                </Checkbox>
              </FormField>
              <FormField>
                <Button
                  className="icon"
                  color="teal"
                  onClick={props.onClear}
                  style={{display: props.clearable ? "" : "none"}}>
                  <Icon
                    name={"erase"}>
                  </Icon>
                </Button>
              </FormField>
              <FormField>
                <Button
                  className="icon"
                  color="red"
                  onClick={props.onRemove}
                  style={{display: props.removable ? "" : "none"}}>
                  <Icon
                    name={"minus"}>
                  </Icon>
                </Button>
              </FormField>
              <FormField>
                <Button
                  className="icon"
                  color="green"
                  onClick={props.onClickAdd}
                  style={{display: props.allowedAdd ? "" : "none"}}>
                  <Icon
                    name={"plus"}>
                  </Icon>
                </Button>
              </FormField>
            </FormGroup>
          </Form>
          <div
            style={{ display: !!props.icdKeyUpSearchItems?.length ? "" : "none", width: "100%", }}>
            {}
            <Table
              data={props.icdKeyUpSearchItems}
              getTheadThProps={props.onGetTheadThProps}
              getTrProps={props.onGetTrProps}
              headers="medterm, icdterm,icdcode"
              keys="medterm,icdterm,icdcode"
              showPagination={false}
              style={{ height: "200px" }}>
            </Table>
          </div>
        </div>
        <div
          className="ui divider horizontal"
          style={{display: props.showHeader ? "" : "none",...(props.secondaryTextStyle || {})}}>
          {props.secondaryText}
        </div>
      </div>
    )
}


export default SubIcdDetailUX

export const screenPropsDefault = {}

/* Date Time : Tue Aug 20 2024 12:47:32 GMT+0700 (Indochina Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "props": {
        "className": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{  width: \"100%\" }"
        }
      },
      "seq": 0
    },
    {
      "from": null,
      "id": 25,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "code",
          "value": "props.primaryText"
        },
        "className": {
          "type": "value",
          "value": "ui divider horizontal"
        },
        "style": {
          "type": "code",
          "value": "{display: props.showHeader ? \"\" : \"none\", ...(props.primaryTextStyle || {})}"
        }
      },
      "seq": 2,
      "void": false
    },
    {
      "from": null,
      "id": 26,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "ui segment"
        },
        "style": {
          "type": "code",
          "value": "{backgroundColor:  \"white\", ...props.boxStyle }"
        }
      },
      "seq": 25,
      "void": false
    },
    {
      "from": null,
      "id": 39,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "code",
          "value": "props.secondaryText"
        },
        "className": {
          "type": "value",
          "value": "ui divider horizontal"
        },
        "style": {
          "type": "code",
          "value": "{display: props.showHeader ? \"\" : \"none\",...(props.secondaryTextStyle || {})}"
        }
      },
      "seq": 39,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 46,
      "name": "Form",
      "parent": 26,
      "props": {
      },
      "seq": 71,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 48,
      "name": "FormGroup",
      "parent": 46,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 48,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 49,
      "name": "FormField",
      "parent": 48,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "6"
        }
      },
      "seq": 49,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 50,
      "name": "FormField",
      "parent": 48,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "6"
        }
      },
      "seq": 50,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 51,
      "name": "FormField",
      "parent": 48,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "6"
        }
      },
      "seq": 51,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 52,
      "name": "FormField",
      "parent": 48,
      "props": {
      },
      "seq": 52,
      "void": false
    },
    {
      "from": null,
      "id": 55,
      "name": "label",
      "parent": 50,
      "props": {
        "children": {
          "type": "code",
          "value": "`${props.icdType || \"\"} Term`"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"bold\", textAlign: \"center\", width: \"114px\"}"
        }
      },
      "seq": 55,
      "void": false
    },
    {
      "from": null,
      "id": 57,
      "name": "label",
      "parent": 51,
      "props": {
        "children": {
          "type": "code",
          "value": "`${props.icdType || \"\"} Code`"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"bold\", textAlign: \"center\", width: \"116px\"}"
        }
      },
      "seq": 57,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 59,
      "name": "Checkbox",
      "parent": 52,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.active_disease"
        },
        "label": {
          "type": "value",
          "value": "Active Disease"
        },
        "name": {
          "type": "value",
          "value": "active_disease"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeValue"
        },
        "readOnly": {
          "type": "code",
          "value": "props.readOnly"
        },
        "style": {
          "type": "code",
          "value": "{ fontWeight: \"bold\", display: props.hideActiveDisease ? \"none\" : \"\", width: \"130px\"}"
        }
      },
      "seq": 59,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 60,
      "name": "FormField",
      "parent": 48,
      "props": {
      },
      "seq": 60,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 61,
      "name": "FormField",
      "parent": 48,
      "props": {
      },
      "seq": 61,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 62,
      "name": "Button",
      "parent": 60,
      "props": {
        "className": {
          "type": "value",
          "value": "icon"
        },
        "color": {
          "type": "value",
          "value": "teal"
        },
        "onClick": {
          "type": "code",
          "value": "props.onClear"
        },
        "style": {
          "type": "code",
          "value": "{display: props.clearable ? \"\" : \"none\"}"
        }
      },
      "seq": 62,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 63,
      "name": "Button",
      "parent": 61,
      "props": {
        "className": {
          "type": "value",
          "value": "icon"
        },
        "color": {
          "type": "value",
          "value": "red"
        },
        "onClick": {
          "type": "code",
          "value": "props.onRemove"
        },
        "style": {
          "type": "code",
          "value": "{display: props.removable ? \"\" : \"none\"}"
        }
      },
      "seq": 63,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 64,
      "name": "Icon",
      "parent": 62,
      "props": {
        "name": {
          "type": "code",
          "value": "\"erase\""
        }
      },
      "seq": 64,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 65,
      "name": "Icon",
      "parent": 63,
      "props": {
        "name": {
          "type": "code",
          "value": "\"minus\""
        }
      },
      "seq": 65,
      "void": false
    },
    {
      "from": null,
      "id": 71,
      "name": "div",
      "parent": 26,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "ui form"
        },
        "style": {
          "type": "code",
          "value": "{display: props.showSubType ? \"\" : \"none\"}"
        }
      },
      "seq": 66,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 72,
      "name": "FormGroup",
      "parent": 71,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 72,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 73,
      "name": "FormField",
      "parent": 72,
      "props": {
        "className": {
          "type": "value",
          "value": "required"
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "10"
        }
      },
      "seq": 73,
      "void": false
    },
    {
      "from": null,
      "id": 74,
      "name": "label",
      "parent": 73,
      "props": {
        "children": {
          "type": "value",
          "value": "ประเภท"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100px\", margin: 0}"
        }
      },
      "seq": 74,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 75,
      "name": "Dropdown",
      "parent": 73,
      "props": {
        "disabled": {
          "type": "code",
          "value": "props.readOnly"
        },
        "fluid": {
          "type": "code",
          "value": ""
        },
        "name": {
          "type": "value",
          "value": "subType"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeValue"
        },
        "options": {
          "type": "code",
          "value": "props.typeOptions"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\", opacity: 1}"
        },
        "value": {
          "type": "code",
          "value": "props.subType || \"\""
        }
      },
      "seq": 75,
      "void": false
    },
    {
      "from": null,
      "id": 76,
      "name": "div",
      "parent": 49,
      "props": {
        "children": {
          "type": "code",
          "value": "props.SearchBoxMedTerm"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        }
      },
      "seq": 90,
      "void": false
    },
    {
      "from": null,
      "id": 77,
      "name": "div",
      "parent": 50,
      "props": {
        "children": {
          "type": "code",
          "value": "props.SearchBoxICDTerm"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        }
      },
      "seq": 77,
      "void": false
    },
    {
      "from": null,
      "id": 78,
      "name": "div",
      "parent": 51,
      "props": {
        "children": {
          "type": "code",
          "value": "props.SearchBoxICDCode"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        }
      },
      "seq": 78,
      "void": false
    },
    {
      "from": null,
      "id": 79,
      "name": "div",
      "parent": 26,
      "props": {
        "children": {
          "type": "code",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: !!props.icdKeyUpSearchItems?.length ? \"\" : \"none\", width: \"100%\", }"
        }
      },
      "seq": 79,
      "void": false
    },
    {
      "from": "react-lib/frameworks/Table",
      "id": 80,
      "name": "Table",
      "parent": 79,
      "props": {
        "data": {
          "type": "code",
          "value": "props.icdKeyUpSearchItems"
        },
        "getTheadThProps": {
          "type": "code",
          "value": "props.onGetTheadThProps"
        },
        "getTrProps": {
          "type": "code",
          "value": "props.onGetTrProps"
        },
        "headers": {
          "type": "value",
          "value": "medterm, icdterm,icdcode"
        },
        "keys": {
          "type": "value",
          "value": "medterm,icdterm,icdcode"
        },
        "showPagination": {
          "type": "code",
          "value": "false"
        },
        "style": {
          "type": "code",
          "value": "{ height: \"200px\" }"
        }
      },
      "seq": 80,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 81,
      "name": "FormField",
      "parent": 72,
      "props": {
      },
      "seq": 81,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 82,
      "name": "FormField",
      "parent": 72,
      "props": {
      },
      "seq": 82,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 83,
      "name": "Button",
      "parent": 81,
      "props": {
        "className": {
          "type": "value",
          "value": "icon"
        },
        "color": {
          "type": "value",
          "value": "red"
        },
        "onClick": {
          "type": "code",
          "value": "props.onDelete"
        },
        "style": {
          "type": "code",
          "value": "{display: props.deletable ? \"\" : \"none\"}"
        }
      },
      "seq": 83,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 84,
      "name": "Button",
      "parent": 82,
      "props": {
        "className": {
          "type": "value",
          "value": "icon"
        },
        "color": {
          "type": "value",
          "value": "green"
        },
        "onClick": {
          "type": "code",
          "value": "props.onAdd"
        },
        "style": {
          "type": "code",
          "value": "{display: props.addable? \"\" : \"none\"}"
        }
      },
      "seq": 84,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 85,
      "name": "Icon",
      "parent": 83,
      "props": {
        "name": {
          "type": "code",
          "value": "\"minus\""
        }
      },
      "seq": 85,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 86,
      "name": "Icon",
      "parent": 84,
      "props": {
        "name": {
          "type": "code",
          "value": "\"plus\""
        }
      },
      "seq": 86,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 87,
      "name": "FormField",
      "parent": 48,
      "props": {
      },
      "seq": 87,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 88,
      "name": "Button",
      "parent": 87,
      "props": {
        "className": {
          "type": "value",
          "value": "icon"
        },
        "color": {
          "type": "value",
          "value": "green"
        },
        "onClick": {
          "type": "code",
          "value": "props.onClickAdd"
        },
        "style": {
          "type": "code",
          "value": "{display: props.allowedAdd ? \"\" : \"none\"}"
        }
      },
      "seq": 88,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 89,
      "name": "Icon",
      "parent": 88,
      "props": {
        "name": {
          "type": "code",
          "value": "\"plus\""
        }
      },
      "seq": 89,
      "void": false
    },
    {
      "from": null,
      "id": 90,
      "name": "div",
      "parent": 49,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\",width: props.requiredMedicalTerm ? \"130px\": \"115px\"}"
        }
      },
      "seq": 76,
      "void": false
    },
    {
      "from": null,
      "id": 91,
      "name": "label",
      "parent": 90,
      "props": {
        "children": {
          "type": "value",
          "value": "Medical Term"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"bold\", textAlign: \"center\",}"
        }
      },
      "seq": 91,
      "void": false
    },
    {
      "from": null,
      "id": 92,
      "name": "label",
      "parent": 90,
      "props": {
        "children": {
          "type": "value",
          "value": "*"
        },
        "style": {
          "type": "code",
          "value": "{color: \"red\", display : props.requiredMedicalTerm ? \"\":\"none\"}"
        }
      },
      "seq": 92,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 80,
  "isMounted": true,
  "memo": false,
  "name": "SubIcdDetailUX",
  "project": "IsHealth",
  "screenPropsDefault": {
  },
  "width": 80
}

*********************************************************************************** */
