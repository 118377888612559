import React, {
  CSSProperties,
  useEffect,
  useState,
  useCallback,
  useMemo,
  useRef,
  MutableRefObject,
  SyntheticEvent,
} from "react";
import { Modal, Checkbox, CheckboxProps, Dropdown, Button, Icon, Popup, Divider, Pagination, DropdownProps } from "semantic-ui-react";

import moment from "moment";
// @ts-ignore
import { CSVLink } from "react-csv";
import { DateLocalizer, momentLocalizer } from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";

// UX
import CardPatientAppointmentUX from "./CardPatientAppointmentUX";
import CardWaitingListUX from "./CardWaitingListUX";
import CardRescheduleUX from "./CardRescheduleUX";
import CardReconfirmUX from "./CardReconfirmUX";
import CardORRequest from "../HISV3/ORM/CardORRequest";
import CardSelectPackage from "../HISV3/HCU/CardSelectPackage";
import CardSelectDateTimePackage from "../HISV3/HCU/CardSelectDateTimePackage";
import CardAppointmentDetail from "./CardAppointmentDetail";

import ModEventDetail from "./ModEventDetail";
import ModWaitingListManageUX from "./ModWaitingListManageUX";
import ModOpenSendToClinicUX from "./ModOpenSendToClinicUX";
import ModSummaryWaitingListUX from "./ModSummaryWaitingListUX";
import ModSummaryStaticUX from "./ModSummaryStaticUX";
import ModSelectEncounter from "./ModSelectEncounter";

// Common
import SearchBoxDropdown from "react-lib/appcon/common/SearchBoxDropdown";
import { appointmentListForPatientAppointment } from "react-lib/apps/Scheduling/common/AppointmentList";
import ModConfirmAuthen from "react-lib/apps/common/ModConfirmAuthen";
import SnackMessage from "../common/SnackMessage";
import ModConfirmWithNote from "react-lib/appcon/common/ModConfirmWithNote";
import { PatientSearchBoxCU } from "react-lib/apps/common";
import EmployeeToken from "react-lib/apps/common/EmployeeToken";

// Ishealth-v3 port into CUDent
import CardSelectDateTimeOperation from "react-lib/apps/Scheduling/CardSelectDateTimeOperation";
import CardOtherOrderAppointment from "react-lib/apps/Scheduling/CardOtherOrderAppointment";
import CardConsultDetail from "react-lib/apps/Scheduling/CardConsultDetail";
import CardConsultDateTime from "react-lib/apps/Scheduling/CardConsultDateTime";

// Utils
import { parseDate, formatADtoBEString, adToBe, formatDatetime, beStringToDateObject } from "react-lib/utils/dateUtils";

// Interface
import {
  APPOINTMENT_CANCEL_OPTIONS,
  APPOINTMENT_POSTPONE_OPTIONS,
  DOCTOR_CONSULT_ORDER,
} from "./SchedulingInterface";
import { APPOINTMENT_STATUS } from "react-lib/apps/HISV3/DPO/sequence/ConsultationList";
import { DATE_FORMAT } from "react-lib/apps/Scheduling/common/Utils";

// Config
import CONFIG from "config/config";

console.log("initial moment locale en week dow 1")
moment.locale("en", { week: { dow: 1 } });
const localizer = momentLocalizer(moment);

const PAGE_LIMIT = 6;
const NOTE_MSG = {
  pause: {
    title: "ต้องการพักคิวผู้ป่วย",
    color: "blue",
    subtitle: "ระบุเหตุผลในการพักคิว",
  },
  resume: {
    title: "ต้องการคืนคิวผู้ป่วย",
    color: "orange",
    subtitle: "ระบุเหตุผลในการคืนคิว",
  },
  cancel: {
    title: "ต้องการยกเลิกแผนการรักษา",
    color: "red",
    subtitle: "ระบุเหตุผลในการยกเลิกแผนการรักษา",
  },
};

const IMAGES = {
  appointment_onsite: "/static/images/order_images/onsite_outline.png",
  appointment_telemed: "/static/images/order_images/telemed_outline.png",
  or_outline: "/static/images/order_images/or_outline.png",
  waiting_list_outline: "/static/images/order_images/onsite_outline.png",
  package_outline: "/static/images/order_images/package_outline.png",
};

const APPOINTMENT_MSG = {
  appointment: "นัดหมายทั่วไป",
  or: "นัดหมายผ่าตัด (EN เดิม)",
  new_or: "นัดหมายผ่าตัด (EN ใหม่)",
  waiting_list: "Waiting List",
  package: "นัดหมาย Package",
  consult_opd: "นัดหมาย Consult (OPD)",
  consult_ipd: "นัดหมาย Consult (IPD)",
} as const;

const TYPE_DISPLAY = {
  or: "นัดหมายผ่าตัด",
  package: "นัดหมาย Package",
  consult_opd: "นัดหมาย Consult OPD",
  consult_ipd: "นัดหมาย Consult IPD",
};

const APPOINTMENT_TYPE: any = [
  { key: 1, text: TYPE_DISPLAY.or, value: "OR" },
  { key: 2, text: "Waiting List", value: "WAITING_LIST" },
  { key: 3, text: "นัดหมายทั่วไป", value: "NORMAL" },
] as const;

const SelectItem = (props: any) => (
  <Checkbox
    key={props.index}
    checked={props.item?.id && props.selectedIdList.includes(props.item.id)}
    onClick={(e: any) => {
      e.stopPropagation();
      console.log(props.item);
      if (props.item?.id) {
        console.log(props.selectedIdList);
        let newSelectedIdList: number[] = Object.assign([], props.selectedIdList);
        if (props.selectedIdList.includes(props.item.id)) {
          newSelectedIdList = newSelectedIdList.filter((id: number) => id !== props.item.id);
        } else {
          newSelectedIdList.push(props.item.id);
          props.setSelectedDoctorEditWaiting("");
          props.setSelectedTreatmentType("");
          props.setNote("");
          props.setOpenModClinic(false);
        }
        console.log("newSelectedIdList", newSelectedIdList);
        props.setSelectedIdList(newSelectedIdList);
        props.setOpenModClinic(false);
      }
    }}
  />
);

const MenuItem = (props: any) => (
  <Button
    size="tiny"
    onClick={(e: any) => props.setTab(props.menu)}
    color={props.menu === props.tab ? "blue" : undefined}
  >
    {props.name}
  </Button>
);

const staterDate = new Date().setMonth(new Date().getMonth() - 1);
const startDateDefault = moment(staterDate)?.format(DATE_FORMAT);
const endDateDefault = moment(new Date())?.format(DATE_FORMAT);



const CardPatientAppointment = (props: any) => {
  // Ishealth-v3 port into CUDent
  const CARD_PATIENT_APPOINTMENT = "CardPatientAppointment";

  // const [mode, setMode] = useState("search");
  const [mode, setMode] = useState(!props.config?.disabledWaitingList ? "search" : "search");
  const [tab, setTab] = useState(!props.config?.disabledWaitingList ? "detail" : "search");
  const [modOrder, setModOrder] = useState("");
  const [showMod, setShowMod] = useState(false);
  const [showModWaitingList, setShowModWaitingList] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState<any>(null);

  // PatientAppointment
  const [patientSearchText, setPatientSearchText] = useState("");
  const [selectedDoctorPA, setSelectedDoctorPA] = useState<any>(null);
  const [selectDivisionPA, setSelectDivisionPA] = useState<any>(null);
  const [waitingListNamePA, setWaitingListNamePA] = useState<any>("");
  const [filterAppointmentList, setFilterAppointmentList] = useState<any[]>([]);

  // Duplicated make appointment
  const [duplicateReason, setDuplicateReason] = useState("");

  const [initial, setInitial] = useState<boolean>(false)
  // const [selectedDivision, setSelectedDivision] = useState<any>(null);
  // เลือกวันเวลาออกตรวจ
  // ค้นหาทันตแพทย์ สำหรับ เลือกวันเวลาออกตรวจ
  const [selectedProvider, setSelectedProvider] = useState<any>(null);
  const [blockFilterAppointments, setBlockFilterAppointments] = useState<any[]>([]);

  const [estimatedAt, setEstimatedAt] = useState("");
  const [estimatedDuration, setEstimatedDuration] = useState("");
  // const [full, setFull] = useState(false);
  // const [manualTime, setManualTime] = useState(false);

  // ตัวเลือก waitingList ใน WaitingList Table (Tab waitingList)
  const [selectedWaitingList, setSelectedWaitingList] = useState<any>(null);

  const [waitingListItems, setWaitingListItems] = useState<any[]>([]);
  const [filterDoctorOptions, setFilterDoctorOptions] = useState<any[]>([]);
  const [manageWaitingListItems, setManageWaitingListItems] = useState<any[]>([]);
  const [selectedIdList, setSelectedIdList] = useState<number[]>([]);

  // ตัวเลือก waitingListItem ใน WaitingListItem Table (Tab waitingList)
  const [selectedWaitingListItem, setSelectedWaitingListItem] = useState<any>(null);
  const [note, setNote] = useState("");
  const [selectedPatientId, setSelectedPatientId] = useState<any>(null);
  const [selectedDoctorAddWaiting, setSelectedDoctorAddWaiting] = useState<any>(null);
  const [selectedDoctorEditWaiting, setSelectedDoctorEditWaiting] = useState<any>(null);
  const [selectDivision, setSelectDivision] = useState<any>(null);
  const [openModClinic, setOpenModClinic] = useState<boolean>(false);
  const [selectedRow, setSelectedRow] = React.useState<any>(null);

  // Input Search
  const [searchWaitingList, setSearchWaitingList] = useState<string>("");
  const [filterWaitingList, setFilterWaitingList] = useState<any[]>([]);
  // Checkbox Search
  // const [isAppointed, setIsAppointed] = useState<boolean>(false);
  const [isFilterDoctor, setIsFilterDoctor] = useState<boolean>(false);
  // const [isOnHoldQ, setIsOnHoldQ] = useState<boolean>(false);
  // Dropdown filter (ModWaitingListManageUX)
  const [selectedFilterProviderCode, setSelectedFilterProviderCode] = useState("ไม่ระบุ");
  const [doctorManageLists, setDoctorManageLists] = useState<any[]>([]);
  // PatientSearchBox
  const [filterPatientName, setFilterPatientName] = useState<string>("");
  const [selectedStatus, setSelectedStatus] = useState<string>("รอนัดหมาย");
  // จัดการผู้ป่วยรายคน
  const [selectedManagePatient, setSelectedManagePatient] = useState<any>(null);
  const [selectedTreatmentType, setSelectedTreatmentType] = useState<any>(null);
  // group button manage
  const [openModConfirmNote, setOpenModConfirmNote] = useState<boolean>(false);
  const [modConfirmTitle, setModConfirmTitle] = useState<string>("");
  const [modConfirmSubtitle, setModConfirmSubtitle] = useState<string>("");
  const [modColor, setModColor] = useState<string>("");
  const [manageQNote, setManageQNote] = useState<string>("");

  const hnSearchRefPA = useRef(null) as MutableRefObject<any>;

  // PatientSearch
  const hnSearchRef = useRef(null) as MutableRefObject<any>;

  // CardReconfirmed
  const hnSearchRefRC = useRef(null) as MutableRefObject<any>;
  const [selectedDoctorRC, setSelectedDoctorRC] = useState<any>(null);
  const [selectDivisionRC, setSelectDivisionRC] = useState<any>(null);
  const [selectWaitingListRC, setSelectWaitingListRC] = useState("");
  const [selectAppointmentType, setSelectedAppointmentType] = useState("");
  const [selectStatusRC, setSelectStatusRC] = useState("");
  const [startDateRC, setStartDateRC] = useState("");
  const [endDateRC, setEndDateRC] = useState("");

  // CardReschedule
  const hnSearchRefRS = useRef(null) as MutableRefObject<any>;
  const [selectedDoctorRS, setSelectedDoctorRS] = useState<any>(null);
  const [selectDivisionRS, setSelectDivisionRS] = useState<any>(null);
  const [selectWaitingListRS, setSelectWaitingListRS] = useState("");
  const [selectStatusRS, setSelectStatusRS] = useState("");
  const [startDateRS, setStartDateRS] = useState("");
  const [endDateRS, setEndDateRS] = useState("");

  // ModConfirm
  const [questionDetail, setQuestionDetail] = useState<any>(null);
  const [openModMessage, setOpenModMessage] = useState(false);
  const [options, setOptions] = useState<any[]>([]);
  const [currentValue, setCurrentValue] = useState([]);

  //Button Static
  const [modTab, setModTab] = useState("queue");
  const [showModStatic, setShowModStatic] = useState(false);

  //ModWaitingLists
  const [startDateModWL, setStartDateModWL] = useState(startDateDefault);
  const [endDateModWL, setEndDateModWL] = useState(endDateDefault);
  const [waitingQueueList, setWaitingQueueList] = useState<any>([]);

  // sortstate ascending = น้อย ไป มาก
  const [isAscendingCreated, setIsAscendingCreated] = useState(false);
  const [isAscendingAppointed, setIsAscendingAppointed] = useState(false);
  const [isAscendingWaitingTime, setIsAscendingWaitingTime] = useState(false);

  //ModStatic
  const [startDateModST, setStartDateModST] = useState(startDateDefault);
  const [endDateModST, setEndDateModST] = useState(endDateDefault);
  const [onlyNewPatient, setOnlyNewPatient] = useState<boolean>(false);

  const [openModOrder, setOpenModOrder] = useState<{
    id?: number;
    type?: string;
  } | null>(null);
  const [openModSelectEncounter, setOpenModSelectEncounter] = useState<{
    open: boolean;
    isNewEncounter: boolean;
    appointmentType: string;
    encounterType: string;
  } | null>(null);
  const [openPopup, setOpenPopup] = useState<boolean>(false);
  const [calendarYear, setCalendarYear] = useState<string[]>([moment().format("YYYY")]);
  const [selectedProviderCode, setSelectedProviderCode] = useState("ไม่ระบุ");

  // Feature 66917
  const [filterStartDateWL, setFilterStartDateWL] = useState("");
  const [filterEndDateWL, setFilterEndDateWL] = useState("");
  const [filterStatusWL, setFilterStatusWL] = useState<string[]>(["PENDING"]); /// default
  const [filterActivePage, setFilterActivePage] = useState<number>(1);

  const isInitialMount = useRef(true); // flag use only one useEffect
  const isWaitingSelect = useRef<boolean>(false);
  const goToTabSelectDateTime = useRef<boolean>(false);
  const goToTabOtherOrder = useRef<boolean>(false);
  const isSelectingAppRef = useRef<boolean>(false)
  const isResetAppointmentRef = useRef<boolean>(false);

  const formats = {
    monthHeaderFormat: (date: Date, culture?: string, localize?: DateLocalizer) => {
      let defaultMonth = localize?.format(date, "MMMM YYYY", culture)
      let BEYear = Number(localize?.format(date, "YYYY")) + 543
      let thaiMonth = localize?.format(date, "MMMM", "th-TH")
      return `${defaultMonth}  (${thaiMonth} ${BEYear})`
    }
  };

  const mapProviderDoctorOptions = (items: any) => {
    let uniqueProviderCodes = null;
    if (items?.length > 0) {
      let providerCodes = items
        ?.filter((i: any) => i?.provider_code)
        ?.map((i: any) => i?.provider_code);
      uniqueProviderCodes = Array.from(new Set(providerCodes));
      let options = (
        uniqueProviderCodes
          ?.map((providerCode: any) => items?.find((w: any) => w.provider_code === providerCode))
          ?.map((i: any) => ({ key: i.provider, value: i.provider_code, text: i.provider_name })) ||
        []
      ).concat([{ key: "ไม่ระบุ", value: "ไม่ระบุ", text: "ไม่ระบุ" }]);
      return options;
    }
    return [];
  };

  const addCurrentDoctorOptionsIfNotHave = (options: { key: any; value: any; text: string }[]) => {
    let addOptions = [...options] || [];
    let currentCode = props.currentDoctor?.code;
    let currentFullName = props.currentDoctor?.full_name;
    if (!addOptions.some((i) => i?.value === currentCode)) {
      addOptions.unshift({ key: "self", value: currentCode, text: currentFullName });
    }
    return addOptions;
  };

  // ---------------------------------------------------------------
  //  CDM
  // ---------------------------------------------------------------
  useEffect(() => {
    // props.onEvent({
    //   message: "SetScheduling",
    //   params: {
    //     action: "GetCardPatientAppointmentData",
    //   },
    // });

    props.onEvent({
      message: "Holiday",
      params: {
        action: "refresh",
      },
    });

    props.onEvent({ message: "GetChairList", params: {} });

    props.onEvent({
      message: "GetMasterData",
      params: {
        masters: [
          ["patientAppType", {}],
          ["waitingListStatus", {}],
        ],
      },
    } as any);
  }, []);

  // ---------------------------------------------------------------
  //  เมื่อส่ง goToCreateAppointment ให้ทำการค้นหา และ เลือกผู้ป่วยคนนั้น
  // ---------------------------------------------------------------
  useEffect(() => {
    if (isResetAppointmentRef.current) {
      isResetAppointmentRef.current = false;

      return;
    }

    const selectedPatient = props.goToCreateAppointment?.selectedPatient;

    if (mode !== "search" || !selectedPatient) {
      return;
    }

    // #setMode("search");
    setFilterAppointmentList([]);

    handleOnSelectPatient(selectedPatient.id, selectedPatient.hn);

    isWaitingSelect.current = true;

    hnSearchRefPA?.current?.setValue(selectedPatient.hn);

    setSelectedPatientId(selectedPatient.id);
  }, [props.goToCreateAppointment, mode]);

  useEffect(() => {
    const selectedAppointment = props.goToCreateAppointment?.selectedAppointment;

    if (mode !== "search" || !selectedAppointment || !props.appointmentList?.length) {
      return;
    }

    handleSelectAppointment({ original: selectedAppointment });

    isResetAppointmentRef.current = true;

    props.setProp("goToCreateAppointment.selectedAppointment", null);
  }, [props.goToCreateAppointment, mode, props.appointmentList]);

  useEffect(() => {
    return () => {
      props.setProp("goToCreateAppointment", null);
    };
  }, []);

  // ---------------------------------------------------------------
  //  Selected Division
  // ---------------------------------------------------------------
  useEffect(() => {
    console.log("GetCardPatientAppointment Data in useEffect");

    props.onEvent({
      message: "FilterSchedule",
      params: {
        divisionId: props.selectedDivision?.id,
        initial: initial === false,
      },
    });

    setInitial(true)

    props.onEvent({
      message: "ItemSearch",
      params: {
        id: "1",
        type: "Division",
        searchText: props.selectedDivision?.name,
        verbose: false,
      },
    });
    setSelectDivisionRC(props.selectedDivision);

    props.onEvent({ message: "ClearAppointment", params: {} });

    props.onEvent({
      message: "SetScheduling",
      params: {
        action: "GetCardPatientAppointmentData",
      },
    });

    setSelectedWaitingList(null);
    setSelectedWaitingListItem(null);
    setManageWaitingListItems([]);
    setWaitingListItems([]);
    setFilterDoctorOptions([]);
    setTab("detail");
    setFilterWaitingList([]);
    // For search mode
    setFilterAppointmentList([]);
  }, [props.selectedDivision]);

  // ---------------------------------------------------------------
  //  Selected Appointment Division
  // ---------------------------------------------------------------
  useEffect(() => {

    console.log('props.selectedAppointmentDivision: ', props.selectedAppointmentDivision);
    if (props.selectedAppointmentDivision) {
      props.onEvent({
        message: "FilterAppointmentSchedule",
        params: {
          divisionId: props.selectedAppointmentDivision?.id,
          initial: false
        },
      });

      // props.onEvent({
      //   message: "ItemSearch",
      //   params: {
      //     id: "1",
      //     type: "Division",
      //     searchText: props.selectedDivision?.name,
      //     verbose: false,
      //   },
      // });

      // setSelectDivisionRC(props.selectedDivision);

      // props.onEvent({ message: "ClearAppointment", params: {} });

      // props.onEvent({
      //   message: "SetScheduling",
      //   params: {
      //     action: "GetCardPatientAppointmentData",
      //   },
      // });

      // setSelectedWaitingList(null);
      // setSelectedWaitingListItem(null);
      // setManageWaitingListItems([]);
      // setWaitingListItems([]);
      // setFilterDoctorOptions([]);
      // setTab("detail");
      // setFilterWaitingList([]);
      // // For search mode
      // setFilterAppointmentList([]);
    }

  }, [props.selectedAppointmentDivision]);


  useEffect(() => {
    if (props.patientList?.[0] && hnSearchRefPA.current && selectedPatientId) {
      // const patient = props.patientList?.[0];
      // hnSearchRefPA?.current?.setValue(`[${patient?.hn}] ${patient?.full_name}`);
    }
  }, [props.patientList]);


  // ---------------------------------------------------------------
  // Split selectedDivision to selectedAppointmentDivision
  // ---------------------------------------------------------------

  useEffect(() => {
    console.log('useEffect props.allDivisionList: ', props.allDivisionList);
    console.log('useEffect props.selectedDivision: ', props.selectedDivision);
    if (props.allDivisionList?.length > 0 && props.selectedDivision?.id) {
      if (!props.selectedAppointmentDivision?.id) {
        let division = props.allDivisionList?.find((i: any) => i.id === props.selectedDivision?.id)
        if (division) {
          props.setProp("selectedAppointmentDivision", division)
        }
      }
    }

  }, [props.selectedDivision, props.allDivisionList])



  // ---------------------------------------------------------------
  //  Change mode
  // ---------------------------------------------------------------
  useEffect(() => {
    // console.log(" mode useEffect ", mode)

    if (isInitialMount.current) {
      isInitialMount.current = false;
      return;
    }

    // mode :  "waitingList" "reconfirm"  "reschedule" "search"
    props.onEvent({ message: "ClearAppointment", params: {} });

    if (mode === "reconfirm") {
      props.onEvent({ message: "GetReconfirm", params: {} });
      props.onEvent({
        message: "SetScheduling",
        params: {
          action: "GetReconfirmAppointment",
          division_id: props.selectedDivision?.id,
        },
      });
    } else if (mode === "reschedule") {
      props.onEvent({
        message: "SetScheduling",
        params: {
          action: "GetRescheduleAppointment",
        },
      });
    } else if (mode === "search") {
      // ---
    }
    // console.log("Get useEffect mode: ", mode);
  }, [mode]);

  // ---------------------------------------------------------------
  //  เลือก Appointment , เปิด Tab ด้านขวา
  // ---------------------------------------------------------------
  useEffect(() => {
    // เลือก Appointment

    // console.log('useEffect props.selectedAppointment?.division_service_block: ', props.selectedAppointment?.division_service_block);
    // console.log('useEffect props.selectedBlock?.dsb_id: ', props.selectedBlock?.dsb_id);
    // console.log('useEffect props.selectedAppointment: ', props.selectedAppointment, goToTabSelectDateTime);

    if (!props.selectedAppointment?.id || props.selectedAppointment?.main_patient_appointment) {
      if (props.selectedAppointment?.appointment_type === "PACKAGE") {
        setTab("selectpackage");
      } else {
        setTab("detail");
      }

      return;
    }

    console.groupCollapsed("useEffect estimate");
    // have Appointment
    if (
      props.selectedAppointment?.division_service_block == null &&
      props.selectedBlock?.dsb_id == null
    ) {
      // null or undefined, https://262.ecma-international.org/12.0/#sec-abstract-equality-comparison
      console.log("case selectedBlock.dsb_id = null", props.selectedAppointment);
      // console.log("useEffect setTab to calendar ",props.selectedAppointment, isCreate);
      if (props.selectedAppointment?.type_display === TYPE_DISPLAY.or) {
        if (goToTabSelectDateTime.current || props.isSelectTabCalendar) {
          goToTabSelectDateTime.current = false;
          props.setProp("isSelectTabCalendar", false);
          setTab("selectdatetimeoperation");
        } else {
          setTab("or_request");
        }
      } else if (props.selectedAppointment?.appointment_type === "PACKAGE") {
        setTab("selectpackage");
      } else if (
        [TYPE_DISPLAY.consult_ipd, TYPE_DISPLAY.consult_opd].includes(
          props.selectedAppointment?.type_display
        )
      ) {
        setTab("consultDetail");
      } else {
        setTab("calendar");
      }
    } else {
      // console.log('props.selectedAppointment?.id: ', props.selectedAppointment?.id);
      // console.log('props.selectedBlock: ', props.selectedBlock);
      if (
        [TYPE_DISPLAY.consult_ipd, TYPE_DISPLAY.consult_opd].includes(
          props.selectedAppointment?.type_display
        )
      ) {
        setTab("consultDetail");
      } else if (
        props.selectedAppointment?.type_display !== TYPE_DISPLAY.or &&
        props.selectedAppointment?.id &&
        !props.selectedAppointment?.estimated_at
      ) {
        setTab("calendar");
      } else if (props.selectedAppointment?.type_display === TYPE_DISPLAY.or) {
        if (goToTabOtherOrder.current) {
          goToTabOtherOrder.current = false;
          setTab("otherorderappointment");
        } else {
          setTab("or_request");
        }
      } else if (props.selectedAppointment?.appointment_type === "PACKAGE") {
        setTab("selectpackage");
      } else {
        setTab("detail");
      }
    }

    console.groupEnd();
  }, [props.selectedAppointment, props.availableSlots]);

  // ---------------------------------------------------------------
  // WaitingList เมื่อมีการ update WaitingList จะคำนวน
  // 1. filterWaitingList Table(1)
  // 2. selectedWaitingList เลือก อีกที บางที ไม่มีแล้ว
  // 3. clear Confirmed Note
  // ---------------------------------------------------------------
  useEffect(() => {
    let filter = props.waitingList;

    if (searchWaitingList?.length > 0) {
      filter = props.waitingList?.filter((item: any) => item?.name?.includes(searchWaitingList));
    }

    // student_flag กรองจาก หลังบ้าน
    // teacher_flag
    // if (props.userPosition?.teacher_flag && selectedProviderCode) {
    //   if (selectedProviderCode && selectedProviderCode !== "ไม่ระบุ") {
    //     filter = filter.filter((i: any) => i.provider_code === selectedProviderCode);
    //   }
    // }

    // console.log("useEffect selectedProviderCode: ", selectedProviderCode);
    // console.log("useEffect filter: ", filter);

    // console.log('useEffect &&&&&&&&&&&&&&&& filter: ', filter);
    // Updated added item from props.waitingList
    if (Number.isInteger(selectedWaitingList?.id)) {
      // มีการเลือก
      let findSelected = filter?.find((item: any) => item.id === selectedWaitingList?.id);

      // console.log("useEffect &&&&&&&&&&&&&&&& findSelected", findSelected);
      // console.log("useEffect &&&&&&&&&&&&&&&& props.selectedPatient: ", props.selectedPatient);
      // console.log("useEffect &&&&&&&&&&&&&&&& selectedWaitingList: ", selectedWaitingList);
      if (
        (findSelected && !props.selectedPatient) ||
        (findSelected?.items && findSelected?.items?.length !== selectedWaitingList?.items?.length)
      ) {
        // console.log("useEffect &&&&&&&&&&&&&&&& setSelectedWaitingList", findSelected);
        setSelectedWaitingList(findSelected);
      }

      // console.log("useEffect &&&&&&&&&&&&&&&& findSelected?.items?.length", findSelected?.items?.length)
      // console.log("useEffect &&&&&&&&&&&&&&&& selectedWaitingList?.items?.length", selectedWaitingList?.items?.length)
      // if (findSelected?.items?.length !== undefined && selectedWaitingList?.items?.length !== findSelected?.items?.length) {
      //   console.log(" @@@ not equal @@@ findSelected: ", findSelected)

      // }
    }

    if (props.waitingList && openModConfirmNote) {
      setOpenModConfirmNote(false);
      setModConfirmTitle("");
      setModColor("");
      setModConfirmSubtitle("");
      setManageQNote("");
      setSelectedManagePatient(null);
      props.onEvent({
        message: "UserTokenize",
        params: {
          action: "clear",
        },
      });
    }

    //

    setFilterWaitingList(filter);
  }, [props.waitingList, searchWaitingList, selectedWaitingList, props.userPosition]);

  // ---------------------------------------------------------------
  // เลือก selectedWaitingList ยิง API ที่ 2 เพื่อดึง GetWaitingListItem
  // ---------------------------------------------------------------
  useEffect(() => {
    if (!(Array.isArray(selectedIdList) && selectedIdList?.length === 0)) {
      setSelectedIdList([]);
    }
    if (selectedWaitingList?.id) {
      console.log("selectedWaitingList: ", selectedWaitingList);
      props.onEvent({
        message: "SetScheduling",
        params: {
          action: "GetWaitingListItem",
          waiting_list_id: selectedWaitingList?.id,
        },
      });
    }
  }, [selectedWaitingList]);

  useEffect(() => {
    let uniqueProviderCodes = null;
    console.groupCollapsed("useEffect filterDoctor");
    console.log("useEffect props.waitingList props.waitingList: ", props.waitingList);
    console.log("useEffect props.waitingList selectedWaitingList: ", selectedWaitingList);
    console.log("useEffect props.waitingList selectedWaitingList?.id: ", selectedWaitingList?.id);
    if (
      props.waitingList &&
      selectedWaitingList?.id &&
      props.waitingList?.some((i: any) => i.id === selectedWaitingList?.id)
    ) {
      let items = props.waitingList?.find((i: any) => i.id === selectedWaitingList?.id).items;
      // console.log("useEffect props.waitingList items: ", items);
      // if (items?.length > 0) {
      //   console.log("useEffect props.waitingList: ", props.waitingList);
      //   let providerCodes = items?.filter((i: any) => i?.provider_code)?.map((i: any) => i?.provider_code);
      //   console.log("useEffect props.waitingList providerCodes: ", providerCodes);
      //   uniqueProviderCodes = Array.from(new Set(providerCodes));
      //   console.log("useEffect props.waitingList uniqueProviderCodes: ", uniqueProviderCodes);
      //   let options = [{ key: "ไม่ระบุ", value: "ไม่ระบุ", text: "ไม่ระบุ" }].concat(
      //     uniqueProviderCodes
      //       ?.map((providerCode: any) => items?.find((w: any) => w.provider_code === providerCode))
      //       ?.map((i: any) => ({ key: i.provider, value: i.provider_code, text: i.provider_name }))
      //   );
      if (items?.length > 0) {
        let options = mapProviderDoctorOptions(items);
        options = addCurrentDoctorOptionsIfNotHave(options);
        setFilterDoctorOptions(options);
        // if (props.userPosition?.student_flag) { // Both student and teacher // Issue 66568

        setSelectedProviderCode(props.currentDoctor?.code);
        console.log("setSelectedProviderCode: ", props.currentDoctor?.code);

        // }
      }
    }
    console.groupEnd();

    console.log("useEffect props.waitingList uniqueProviderCodes: ", uniqueProviderCodes);
  }, [props.waitingList, selectedWaitingList]);

  // useWhatChanged([props.waitingList, selectedStatus, selectedIdList, filterPatientName, selectedWaitingList]);

  // ---------------------------------------------------------------
  // waitingList คำนวน WaitingListItem Table(2)
  // 1. ผ่านทาง updateWaitingListItem Table(2)
  // ---------------------------------------------------------------
  useEffect(() => {
    // console.log("useEffect ---> props.waitingList: ", props.waitingList)
    // console.log('useEffect ---> selectedWaitingList: ', selectedWaitingList);
    if (selectedWaitingList?.id && props.waitingList?.length >= 0) {
      let idx = props.waitingList?.findIndex((item: any) => item.id === selectedWaitingList?.id);
      if (idx >= 0) {
        // console.log('useEffect ---> idx: ', idx);
        // console.log('useEffect --->  selectedWaitingListItem?.id' , selectedWaitingListItem?.id)

        // Workaround

        console.log(
          "useEffect --->  props.waitingList?.[idx].status: ",
          props.waitingList?.[idx].status
        );
        console.log("useEffect --->  props.waitingList?.[idx]: ", props.waitingList?.[idx]);
        // console.log('useEffect --->  props.waitingList: ', props.waitingList);
        // console.log('useEffect --->  selectedWaitingList?.items?.[idx]?.status: ', selectedWaitingList?.items?.[idx]?.status);
        // console.log('useEffect --->  selectedWaitingList: ', selectedWaitingList);
        let newStatus = props.waitingList?.[idx]?.items?.find(
          (item: any) => item.id === selectedWaitingListItem?.id
        )?.status;
        // console.log('useEffect --->  newStatus: ', newStatus);
        let prevStatus = selectedWaitingList?.items?.find(
          (item: any) => item.id === selectedWaitingListItem?.id
        )?.status;
        // console.log('useEffect --->  prevStatus: ', prevStatus);
        if (newStatus && newStatus !== prevStatus) {
          setSelectedWaitingList(props.waitingList?.[idx]);
        }
        updateWaitingListItems();
      }
    }
  }, [
    props.waitingList,
    selectedStatus,
    selectedIdList,
    filterPatientName,
    selectedWaitingList,
    selectedProviderCode,
  ]);

  // ---------------------------------------------------------------
  // หน้า Modal WaitingList , filter คำนวน manageWaitingListItem
  // ---------------------------------------------------------------
  useEffect(() => {
    if (showModWaitingList && selectedWaitingList?.items) {
      console.log("selectedWaitingList?.items: ", selectedWaitingList?.items);
      if (selectedWaitingList?.items?.length > 0) {
        let options = mapProviderDoctorOptions(selectedWaitingList?.items);
        options = addCurrentDoctorOptionsIfNotHave(options);
        console.log("selectedWaitingList?.items options: ", options);
        setDoctorManageLists(options);

        if (props.userPosition?.student_flag) {
          setSelectedFilterProviderCode(props.currentDoctor?.code);
        }
      }

      // console.log("useEffect ----->  recal manageWaitingListItems")
      console.log('useEffect filterEndDateWL: ', filterEndDateWL);
      console.log('useEffect filterStartDateWL: ', filterStartDateWL);

      console.log('useEffect beStringToDateObject(filterStartDateWL): ', beStringToDateObject(filterStartDateWL));
      console.log("useEffect selectedWaitingList: ", selectedWaitingList)



      const newManageWaitingListItems = (selectedWaitingList?.items || [])
        // .filter((item: any) => (isAppointed ? true : item.status !== "APPOINTMENT"))
        // .filter((item: any) => (isOnHoldQ ? true : item.status !== "STALL"))
        .filter((item: any) =>
          isFilterDoctor && selectedFilterProviderCode !== "ไม่ระบุ"
            ? item.provider_code === selectedFilterProviderCode
            : true
        )
        // ใช้ API ในการค้นหา
        // .filter((item: any) =>  item.created && filterStartDateWL && filterStartDateWL?.length === 10 ? beStringToDateObject(filterStartDateWL) < new Date(item.created) : true )
        // .filter((item: any) => item.created && filterEndDateWL && filterEndDateWL?.length === 10 ? beStringToDateObject(filterEndDateWL) > new Date(item.created) : true)
        // .filter((item: any) => {
        //   return (filterStatusWL?.length === 0 ? true : filterStatusWL.includes(item.status))})


        .map((item: any, index: number) => {

          let isStall = item.status === "STALL"
          let isCancel = item.status === "CANCEL"
          let showReason = isStall || isCancel

          return {
          index: index + 1,
          ...item,
          statusNameWithReason: (<div>
              <div style={{...(isStall && {color: "orange"}), ...(isCancel && {color: "red"})}}>{item.status_name} </div>
              { showReason && item.reason ? <div>  {`\n(${item.reason})`} </div> : <> </>}
            </div>)
          ,
          code: item.code,
          createdFormat: formatDatetime(item.created, true),
          iconPrint: (
            <div
              style={{
                cursor: "pointer",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              onClick={() => {
                props.onEvent({
                  message: "PrintScheduling",
                  params: {
                    form: "FormAppointmentQueue",
                    data: {
                      // status_name: isOnHoldQ ? "STALL" : isAppointed ? "PENDING" : "ALL",
                      item,
                      division_name: props.selectedDivision.name_code,
                      waiting_list_name: selectedWaitingList.name,
                      // provider_name: `${selectedDoctorAddWaiting?.first_name} ${selectedDoctorAddWaiting?.last_name}`,
                      // orders: manageWaitingListItems,
                      // startDate: filterStartDateWL,
                      // endDate: filterEndDateWL,
                      // userPrint: props.django.user.full_name,
                      // datePrint: formatDatetime(new Date(), true),
                      // status_name: Object.values(STATUS_NAMEING_MAP).includes(filterStatusWL)
                      //   ? STATUS_NAMEING_MAP[filterStatusWL]
                      //   : "",
                    },
                  },
                });
              }}
            >
              <img width="15px" src="static/images/iconprint.png" />
            </div>
          ),
          checkbox: (
            <SelectItem
              index={index}
              item={item}
              selectedIdList={selectedIdList}
              setSelectedIdList={setSelectedIdList}
              setOpenModClinic={setOpenModClinic}
              setSelectedDoctorEditWaiting={setSelectedDoctorEditWaiting}
              setSelectedTreatmentType={setSelectedTreatmentType}
              setNote={setNote}
            />
          ),
          appointment:
            props.patientAppointmentList?.find((item: any) => item.id === item.appointment)
              ?.estimate_service_at || item?.appointment,
        }});
      console.log("newManageWaitingListItems: ", newManageWaitingListItems);
      setManageWaitingListItems(newManageWaitingListItems);
    }
  }, [
    // isAppointed,
    // isOnHoldQ,
    selectedWaitingList?.items,
    showModWaitingList,
    props.patientAppointmentList,
    selectedIdList,
    selectedFilterProviderCode,
    filterStartDateWL,
    filterEndDateWL,
    filterStatusWL
  ]);

  // ---------------------------------------------------------------
  // Tab ค้นหา,  filter ของ filterAppointmentList Table นัดหมายก่อนหน้า
  // ---------------------------------------------------------------
  useEffect(() => {
    if (props.appointmentList) {
      let appList = props.appointmentList;
      if (selectedDoctorPA) {
        appList = appList?.filter(
          (item: any) => item?.display_info?.provider === selectedDoctorPA?.provider_id
        );
      }

      if (selectDivisionPA) {
        appList = appList?.filter((item: any) => item.division === selectDivisionPA?.id);
      }

      if (waitingListNamePA) {
        // console.log("waitingListNamePA", waitingListNamePA)
        appList = appList?.filter((item: any) =>
          item.waiting_list_name?.includes(waitingListNamePA)
        );
      }

      appList = appList.filter((item: any) => ![4, 5, 6].includes(item.status));

      setFilterAppointmentList(appList);
      // console.log("After filter ", appList)
    }
  }, [props.appointmentList, selectDivisionPA, selectedDoctorPA, waitingListNamePA]);

  // ---------------------------------------------------------------
  // กรองเฉพาะ ที่เลือกหมอ ในหน้า เลือกวันเวลาออกตรวจ สำหรับ CardPatientAppointmentUX
  // ---------------------------------------------------------------

  useEffect(() => {
    // console.log("useEffect props.blockList ", props.blockList);
    // console.log("useEffect selectedProvider?.provider_id ", selectedProvider?.provider_id);
    if (props.blockList != null) {
      // null and undefined
      let titleEdit: any[] = [];
      if (selectedProvider?.provider_id) {
        let filterProvider = props.blockList
          ?.map((item: any) => ({
            ...item,
            providers: (item.providers || [])?.filter(
              (provider: any) =>
                provider.doctor_provider_id === selectedProvider?.provider_id &&
                provider?.dsb?.doctor_dsb_status !== 2
            ),
          }))
          .filter((item: any) => item?.providers?.length !== 0);

        // console.log("useEffect filterProvider: ", filterProvider);
        titleEdit = filterProvider;
      } else if (
        props.selectedAppointment &&
        !props.selectedAppointment?.provider &&
        props.selectedAppointment?.doctor_full_name &&
        mode === "waitingList"
      ) {
        let findMatchProvider: any = null;
        let filterProvider = [...(props.blockList || [])];
        let doctorNameSplit = props.selectedAppointment?.doctor_full_name?.split(" ");
        let doctorName = "";
        if (doctorNameSplit?.length === 3) {
          doctorName = `${doctorNameSplit[0]}${doctorNameSplit[1]} ${doctorNameSplit[2]}`;
        } else {
          doctorName = props.selectedAppointment?.doctor_full_name;
        }

        props.blockList?.find((item: any) => {
          let targetProviderInBlocklist = item.providers?.filter(
            (dsb: any) =>
              dsb.dsb.doctor_provider_name === doctorName && dsb?.doctor_dsb_status !== 2
          );

          findMatchProvider = targetProviderInBlocklist?.[0]?.doctor_provider_id || null;
          return findMatchProvider;
        });

        let targetDoctor = props.masterData?.doctor?.find(
          (item: any) =>
            item.full_name &&
            item.full_name?.replaceAll(" ", "") ===
              props.selectedAppointment?.doctor_full_name.replaceAll(" ", "")
        );
        let targetPreName = props.masterData?.prenameTh?.find(
          (item: any) => targetDoctor?.pre_name === item.id
        );
        // console.log("targetDoctor: ", targetDoctor);
        if (targetDoctor) {
          let doctorFullName =
            (targetPreName?.name || "") +
            " " +
            targetDoctor.first_name +
            " " +
            targetDoctor.last_name +
            " [" +
            targetDoctor.code +
            "]";
          props.onEvent({
            message: "ItemSearch",
            params: {
              id: "6",
              type: "Provider",
              searchText: targetDoctor?.first_name || "",
            },
          });
          setSelectedProvider({
            provider_id: findMatchProvider,
            ...(targetDoctor || {}),
            pre_name: targetPreName?.name || "",
            key: findMatchProvider,
            text: doctorFullName,
            value: doctorFullName,
          });

          filterProvider = filterProvider
            ?.map((item: any) => ({
              ...item,
              providers: (item.providers || [])?.filter(
                (provider: any) =>
                  provider.doctor_provider_id === findMatchProvider &&
                  provider?.dsb?.doctor_dsb_status !== 2
              ),
            }))
            .filter((item: any) => item?.providers?.length !== 0);
        }

        titleEdit = filterProvider;
      } else {
        // console.log("useEffect mirror props.blockList: ", props.blockList);
        titleEdit = props.blockList;
      }

      titleEdit = titleEdit?.map((item: any) => {
        let length = item?.providers?.filter(
          (item: any) =>
            item?.dsb?.doctor_provider_type === "Doctor" &&
            item?.dsb?.doctor_dsb_status !== 2 &&
            !item?.dsb?.doctor_dsb_full
        )?.length;
        return {
          ...item,
          // title: length > 0 ? item.title + " (แพทย์ " + length + " คน)" : item.title,
          title: item.title + " (แพทย์ " + length + " คน)",
        };
      });

      const holiday = (props.holiday?.items || []).flatMap((item: any) => {
        const [day, month, year] = item.date[0].split("/");
        return calendarYear.map((year: any) => {
          const date = `${year}-${month}-${day}`;
          return {
            start: new Date(`${date} 00:00`),
            end: new Date(`${date} 23:59`),
            title: item.detail,
            holiday: true,
          };
        });
      });

      // console.log('useEffect titleEdit: ', titleEdit);
      // console.log('useEffect holiday: ', holiday);
      titleEdit = [...holiday, ...titleEdit];
      console.log("setBlockFilterAppointments: ", titleEdit);

      setBlockFilterAppointments(titleEdit);
    }
  }, [props.blockList, selectedProvider?.provider_id, props.holiday, props.selectedAppointment]);

  // Ishealth-v3 port into CUDent

  // useEffect(() => {
  //   const stockList = props.StockManagementSequence?.stockList || [];
  //   const selected = props.StockManagementSequence?.selectedStock;

  //   const isNotFound =
  //     !!selected &&
  //     !stockList.find(
  //       (item) =>
  //         item.product.id === selected.product.id &&
  //         item.product.p_type_name === selected.product.p_type_name
  //     );
  // }, [props.appointmentList]);

  useEffect(() => {
    if (props.duplicateAppointment) {
      setDuplicateReason("");
    }
  }, [props.duplicateAppointment]);

  // ---------------------------------------------------------------
  // กรณี จัดการรายชื่อผู้ป่วย Waiting List และเป็น student flag === true
  // จะบังคับเลือก ทันตแพทย์ ที่จะส่งต่อคลินิก Issue 66824
  // ---------------------------------------------------------------

  useEffect(() => {
    if (props.userPosition?.student_flag === true && selectedIdList?.length > 0) {
      setSelectedDoctorEditWaiting(props.providerInfo?.id);
    }
  }, [props.userPosition?.student_flag, selectedIdList]);

  const handleGetWaitingListItem = () => {
    props.onEvent({
      message: "SetScheduling",
      params: {
        action: "GetWaitingListItem",
        offset: (filterActivePage - 1) * PAGE_LIMIT,
        pageLimit: PAGE_LIMIT,
        includeCancel: true,
        waiting_list_id: selectedWaitingList?.id,
        startDate: filterStartDateWL ? beStringToDateObject(filterStartDateWL)?.toISOString() : null,
        endDate: filterEndDateWL? beStringToDateObject(filterEndDateWL)?.toISOString() : null,
        statusList: (filterStatusWL || [])?.map(
          (statusName: any) =>
            props.masterOptions?.waitingListStatus?.find((m: any) => m.value === statusName)
              ?.key
        ),
      },
    });
  }

  // ---------------------------------------------------------------
  // Pagination at ModWaitingListManageUX
  // ---------------------------------------------------------------

  useEffect(() => {
    if (selectedWaitingList?.id && showModWaitingList && props.masterOptions?.waitingListStatus) {
      handleGetWaitingListItem()
    }
  }, [filterActivePage, showModWaitingList, props.masterOptions?.waitingListStatus])



  const updateWaitingListItems = () => {
    console.log("updateWaitingListItems", selectedWaitingList);
    let newWaitingListItems = (selectedWaitingList?.items || [])
      .filter((item: any) => {
        let result = false;
        // console.log("item", item);
        switch (selectedStatus) {
          case "แสดงทั้งหมด":
            result = true;
            break;
          case "รอนัดหมาย":
            result = item?.status === "PENDING";
            break;
          case "ยืนยันนัดหมาย":
            result = item?.status === "APPOINTMENT";
            break;
          case "พักคิว":
            result = item?.status === "STALL";
            break;
          default:
            result = true;
        }
        return result;
      })
      .map((item: any, index: number) => ({
        index: index + 1,
        ...item,
        patient_name: item.patient_name + " (" + item.patient_hn + ")",
        checkbox: (
          <SelectItem
            index={index}
            item={item}
            selectedIdList={selectedIdList}
            setSelectedIdList={setSelectedIdList}
            setSelectedDoctorEditWaiting={setSelectedDoctorEditWaiting}
            setSelectedTreatmentType={setSelectedTreatmentType}
            setNote={setNote}
            setOpenModClinic={setOpenModClinic}
          />
        ),
      }))
      ?.filter((item: any) => {
        if (filterPatientName?.length > 0) {
          return item?.patient_name?.includes(filterPatientName);
        } else {
          return item;
        }
      });

    console.log("1 updateWaitingListItems newWaitingListItems: ", newWaitingListItems);
    // student_flag กรองจาก หลังบ้าน
    // teacher_flag
    console.log("updateWaitingListItems selectedProviderCode: ", selectedProviderCode);
    console.log(
      "updateWaitingListItems props.userPosition?.teacher_flag: ",
      props.userPosition?.teacher_flag
    );
    if (props.userPosition?.student_flag === false && selectedProviderCode) {
      if (selectedProviderCode && selectedProviderCode !== "ไม่ระบุ") {
        newWaitingListItems = newWaitingListItems.filter(
          (i: any) => i.provider_code === selectedProviderCode
        );
      }
    }
    console.log("2 updateWaitingListItems newWaitingListItems: ", newWaitingListItems);
    setWaitingListItems(newWaitingListItems);
  };

  const editWaitingListItem = () => {
    const selectedWaitingList = (manageWaitingListItems || [])?.filter((item: any) =>
      selectedIdList.includes(item.id)
    );

    props.onEvent({
      message: "SetScheduling",
      params: {
        action: "EditWaitingListItem",
        list: selectedWaitingList,
        selectedProvider: selectedDoctorEditWaiting,
        type: selectedTreatmentType,
        note: note,
        waitingList: selectedWaitingList,
      },
    });
    console.log("editWaitingListItem 5 ");
    setSelectedDoctorEditWaiting("");
    setSelectedTreatmentType("");
    setNote("");
  };

  const statusOptions = useMemo(
    () => [
      {
        key: "ALL",
        text: "แสดงทั้งหมด",
        value: "แสดงทั้งหมด",
      },
      {
        key: "PENDING",
        text: "รอนัดหมาย",
        value: "รอนัดหมาย",
      },
      {
        key: "APPOINTMENT",
        text: "ยืนยันนัดหมาย",
        value: "ยืนยันนัดหมาย",
      },
      {
        key: "STALL",
        text: "พักคิว",
        value: "พักคิว",
      },
    ],
    []
  );

  const dataWaitingList = useMemo(() => {
    return (props.allWaitingList?.items || []).map((item: any, index: number) => ({
      ...item,
      division_name: props.controller.data?.allDivisionList?.filter(
        (acc: any) => acc.id === item.division
      )?.[0]?.name,
    }));
  }, [props.allWaitingList?.items]);

  const dataSummaryStatic = useMemo(() => {
    return (props.staticCanCelQueueList || []).map((item: any, index: number) => ({
      ...item,
    }));
  }, [props.staticCanCelQueueList]);

  const getConfigAppointmentIcon = useCallback((item: any) => {
    // console.log("getConfigAppointmentIcon: ", item)
    const type = [TYPE_DISPLAY.consult_ipd, TYPE_DISPLAY.consult_opd].includes(item.type_display)
      ? "consult"
      : item.type_display === TYPE_DISPLAY.or
      ? "or"
      : item.waiting_list_name
      ? "waiting_list"
      : item.appointment_type === "PACKAGE"
      ? "package"
      : item.is_telemed
      ? "appointment_telemed"
      : "appointment_onsite";

    const config = {
      consult: {
        message: (
          <div>
            <div style={{ fontWeight: "bold" }}>
              {TYPE_DISPLAY.consult_ipd === item.type_display
                ? APPOINTMENT_MSG.consult_ipd
                : APPOINTMENT_MSG.consult_opd}
            </div>
            <div>{`โดย: ${item.edit_user_employee_name || "ไม่ระบุแพทย์"}`}</div>
            <div>{`[${item.order_division_name || "ไม่ระบุแผนก"}]`}</div>
            <div>{`วันที่สร้างนัดหมาย: ${adToBe(moment(item.created).format("DD/MM/YYYY"))}`}</div>
          </div>
        ),
        color: "#949494",
        image: "",
        icon: "user md",
        status: ["FINISHED"],
        type: "consult",
      },
      or: {
        message: item.is_new_order_encounter ? APPOINTMENT_MSG.new_or : APPOINTMENT_MSG.or,
        color: "#949494",
        image: IMAGES.or_outline,
        status: ["REALIZED", "OPERATION", "COMPLETED", "HOLDING_ROOM", "OPERATING_ROOM", "PACU"],
        type: "operatingdetail",
      },
      appointment_onsite: {
        message: APPOINTMENT_MSG.appointment,
        color: "#949494",
        image: IMAGES.appointment_onsite,
        status: ["FINISHED"],
        type: "doctor",
      },
      appointment_telemed: {
        message: APPOINTMENT_MSG.appointment,
        color: "#949494",
        image: IMAGES.appointment_telemed,
        status: ["FINISHED"],
        type: "doctor",
      },
      waiting_list: {
        message: item.waiting_list_name,
        color: "#949494",
        image: IMAGES.waiting_list_outline,
        status: ["FINISHED"],
        type: "doctor",
      },
      package: {
        message: APPOINTMENT_MSG.package,
        color: "#949494",
        image: IMAGES.package_outline,
        status: ["FINISHED"],
        type: "doctor",
      },
    }[type];

    if (config.status.includes(item.related_detail) && config.type === item.related_type) {
      config.color = "#46A04A";
    }

    return config;
  }, []);

  const handleRemoveAppointment = useCallback((e: SyntheticEvent) => {
    e.stopPropagation();

    if (props.selectedAppointment?.id === null) {
      const list = (props.appointmentList || []).filter((item: any) => item.id !== null);

      setTab("detail");
      props.setProp("selectedAppointment", null)
      props.setProp("selectedBlock", null);

      return props.setProp("appointmentList", [...list]);
    }

    console.log("handleOnCancelAppointment ");
    setOpenModMessage(true);
    setOptions(props.masterOptions?.orCancelNote || []);
    setQuestionDetail({
      type: "remove",
      title: "ยกเลิกนัดหมาย",
      subTitle: "",
      questionDetail: "โปรดระบุเหตุผลในการยกเลิก",
      titleColor: "red",
      userEditQuestion: " ",
      buttonLeftLabel: "ใช่",
      buttonRightLabel: "ไม่",
      buttonLeftColor: "green",
      buttonRightColor: "red",
      isUserEditQuesion: true,
      isRequiredQuestionDetail: true,
      multiple: false,
    });
  }, [props.selectedAppointment?.id, props.appointmentList]);

  // const haveEncounterOPD = useMemo(() => {
  //   return (props.encounterPatientList || []).some((item: any) => item.type === "OPD");
  // }, [props.encounterPatientList]);

  const encounterIPD = useMemo(() => {
    return (
      (props.encounterPatientList || [])
        .filter((item: any) => item.type === "IPD")
        ?.reverse()?.[0] || null
    );
  }, [props.encounterPatientList]);

  const appointmentFilterList = useMemo(() => {
    const list: any[] = appointmentListForPatientAppointment(filterAppointmentList);

    return list.map((item, index) => {
      const config = getConfigAppointmentIcon(item);
      return {
        ...item,
        icon: (
          <Popup
            popperModifiers={{
              preventOverflow: {
                boundariesElement: "offsetParent",
              },
            }}
            wide
            offset={10}
            trigger={
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  height: "100%",
                }}
              >
                <div
                  style={{
                    width: "32px",
                    height: "32px",
                    backgroundColor: "white",
                    borderRadius: "500rem",
                    display: "flex",
                  }}
                >
                  <div
                    style={{
                      width: "30px",
                      height: "30px",
                      backgroundColor: config.color,
                      borderRadius: "500rem",
                      margin: "auto",
                      padding: "6px",
                    }}
                  >
                    {config.image && (
                      <img
                        src={config.image}
                        alt={`icon-${index}.icon`}
                        style={{ width: "100%" }}
                      />
                    )}
                    {config.icon && (
                      <Icon
                        name={config.icon as "user md"}
                        // alt={`icon-${index}.icon`}
                        style={{ width: "100%", color: "white" }}
                      />
                    )}
                  </div>
                </div>
              </div>
            }
            content={config.message}
            position="bottom right"
          />
        ),
        estimated_time:
          item.status === 4
            ? "ไม่ระบุ"
            : item.order_note === "indefinitely postponed"
            ? "เลื่อนนัดหมายไม่ระบุวัน"
            : [TYPE_DISPLAY.consult_ipd, TYPE_DISPLAY.consult_opd].includes(item.type_display) &&
              !item.provider &&
              item?.estimated_at
            ? `${item.estimated_at?.split(" ")[0]} [${moment(
                item?.display_info?.start_datetime_iso
              ).format("HH:mm")} - ${moment(item?.display_info?.end_datetime_iso).format("HH:mm")}]`
            : item.estimated_at
            ? item.estimated_at
            : props.selectedAppointment?.id === item?.id && (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "center",
                    height: "100%",
                  }}
                >
                  <Button
                    icon="minus"
                    circular
                    color="red"
                    size="mini"
                    style={{
                      float: "right",
                      marginRight: "15px",
                      fontSize: "0.75rem",
                    }}
                    onClick={handleRemoveAppointment}
                  />
                </div>
              ),
      };
    });
  }, [filterAppointmentList, props.selectedAppointment?.id]);

  const reconfirmList = useMemo(() => {
    return props.reconfirmList?.map((item: any, index: number) => {
      const config = getConfigAppointmentIcon(item);
      let statusNote = "";
      try {
        statusNote = JSON.parse(item.status_note)?.join(", ");
      } catch (e) {
        statusNote = item.status_note;
      }
      return {
        ...item,
        status_note: statusNote,
        provider_name:
          item.provider_name && item.provider_name !== "undefined"
            ? item.provider_name
            : item.doctor_full_name || "",
        icon: (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Popup
              popperModifiers={{
                preventOverflow: {
                  boundariesElement: "offsetParent",
                },
              }}
              wide
              offset={10}
              trigger={
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    height: "100%",
                  }}
                >
                  <div
                    style={{
                      width: "32px",
                      height: "32px",
                      backgroundColor: "white",
                      borderRadius: "500rem",
                      display: "flex",
                    }}
                  >
                    <div
                      style={{
                        width: "30px",
                        height: "30px",
                        backgroundColor: config.color,
                        borderRadius: "500rem",
                        margin: "auto",
                        padding: "6px",
                      }}
                    >
                      <img
                        src={config.image}
                        alt={`icon-${index}.icon`}
                        style={{ width: "100%" }}
                      />
                    </div>
                  </div>
                </div>
              }
              content={config.message}
              position="bottom right"
            />
          </div>
        ),
      };
    });
  }, [props.reconfirmList]);

  const patientList = useMemo(() => {
    return (props.patientList || []).map((item: any) => ({
      ...item,
      tel_num: item.tel_num ? item.tel_num : item.present_mobile_no ? item.present_mobile_no : "",
    }));
  }, [props.patientList]);

  useEffect(() => {
    const selectedPatient = props.goToCreateAppointment?.selectedPatient;

    if (isWaitingSelect.current) {
      const patient = patientList.find((item: any) => item.id === selectedPatient?.id);

      if (patient) {
        props.onEvent({
          message: "SelectPatient",
          params: patient,
          card: CARD_PATIENT_APPOINTMENT,
        });

        isWaitingSelect.current = false;
      }
    }
  }, [patientList, props.goToCreateAppointment?.selectedPatient]);

  // #55748
  // const blockList = useMemo(() => {
  //   const block = selectedDivisionApp && props.selectedDivision?.id !== selectedDivisionApp && props.selectedAppointment?.id ? props.searchBlockList : props.blockList
  //   return blockListForPatientAppointment(block, selectedProvider);
  // }, [props.blockList, props.selectedDivision?.id, props.searchBlockList, props.selectedAppointment?.id, selectedProvider, selectedDivisionApp]);

  const mapProviderOptions = (items: any) => {
    // console.log("mapProviderOptions items", items)
    return items.map((item: any) => ({
      key: item.provider_id,
      value:
        (item?.pre_name || "") +
        " " +
        item.first_name +
        " " +
        item.last_name +
        " [" +
        item.code +
        "]",
      text:
        (item?.pre_name || "") +
        " " +
        item.first_name +
        " " +
        item.last_name +
        " [" +
        item.code +
        "]",
    }));
  };

  const mapDivisionOptions = (items: any) => {
    // console.log("mapDivisionOptions items: ", items);
    return items.map((item: any) => ({
      key: item.code,
      value: item.name,
      text: item.name,
    }));
  };

  const mapWaitingListOptions = (items: any) => {
    return items.map((item: any) => ({
      key: item.id,
      value: item.name,
      text: item.name,
    }));
  };

  // const mapPatientOptions = (items: any) => {
  //   // console.log("mapPatientOptions items", items)
  //   return items.map((item: any) => ({
  //     key: item.id,
  //     value: item.full_name,
  //     text: item.full_name,
  //   }));
  // };

  const handleOnSelectPatient = (id: any, hn: any) => {
    console.log("handleonSelectPatient id: ", id, hn);
    if (id !== null) {
      // setPatientSearchText(hn);
      setSelectedPatientId(id);
      setSelectedManagePatient(null);
      setNote("");

      props.setProp("patientList", []);
      props.setProp("appointmentList", []);

      props.onEvent({
        message: "SearchPatient",
        params: { patientSearchText: hn },
      });
    }
  };

  const handleOnPostponeOperatingAppointment = (callback: any) => {
    console.log("CardPatientAppointment handleOnPostponeOperatingAppointment ");
    setOpenModMessage(true);
    setOptions(APPOINTMENT_POSTPONE_OPTIONS);
    setQuestionDetail({
      type: "operatingPostpone",
      title: "ต้องการเลื่อนนัดผู้ป่วยใช่หรือไม่",
      subTitle: "กรณีที่เลื่อนนัดผู้ป่วยเจ้าหน้าที่จะต้องทำการติดนามนัดหมายผู้ป่วยใหม่",
      questionDetail: "ระบุเหตุผลเลื่อนนัดผู้ป่วย",
      isRequiredQuestionDetail: true,
      titleColor: "orange",
      userEditQuestion: "ระบุชื่อผู้บันทึกข้อมูล",
      isUserEditQuesion: true,
      buttonLeftLabel: "ใช่",
      buttonRightLabel: "ไม่",
      buttonLeftColor: "green",
      buttonRightColor: "red",
      callback,
    });
    //
  };

  const handleAddition = (e: any, { value }: any) => {
    setOptions([{ text: value, value }, ...options]);
  };

  const handleChange = (e: any, { value }: any) => setCurrentValue(value);

  const handleConfirmedButton = () => {
    if (questionDetail?.type === "postpone") {
      props.onEvent({
        message: "SetScheduling",
        params: {
          action: "PostponeAppointment",
          reason: currentValue,
          waitingList: selectedWaitingList,
        },
      });
    } else if (questionDetail?.type === "cancel") {
      props.onEvent({
        message: "SetScheduling",
        params: {
          action: "CancelAppointment",
          reason: currentValue,
          waitingList: selectedWaitingList,
        },
      });
    } else if (questionDetail?.type === "cancelbypatient") {
      props.onEvent({
        message: "SetScheduling",
        params: {
          action: "CancelAppointmentByPatient",
          reason: currentValue,
          waitingList: selectedWaitingList,
        },
      });
    } else if (questionDetail?.type === "remove") {
      props.onEvent({
        message: "SetScheduling",
        params: {
          action: "CancelAppointmentByPatient",
          reason: currentValue,
          waitingList: selectedWaitingList,
        },
      });
    } else if (questionDetail?.type === "operatingPostpone") {
      props.runSequence({
        sequence: "OperatingDateTime",
        action: "operatingPostpone",
        reason: currentValue,
        // waitingList: selectedWaitingList,
        callback: questionDetail?.callback,
      });
    }
    setCurrentValue([]);
    setOpenModMessage(false);
    setQuestionDetail(null);
  };

  const handleCancelButton = () => {
    setOpenModMessage(false);
    setOptions([]);
    setCurrentValue([]);
    setQuestionDetail(null);
    props.setProp("userTokenize", null);
  };

  // CardReconfirmed
  // const allStatusOptions = [
  //   { key: "รอนัดหมาย", text: "รอนัดหมาย", value: "รอนัดหมาย" },
  //   { key: "ยืนยันนัดหมายแล้ว", text: "ยืนยันนัดหมายแล้ว", value: "ยืนยันนัดหมายแล้ว" },
  //   { key: "คืนคิว", text: "คืนคิว", value: "คืนคิว" },
  //   { key: "พักคิว", text: "พักคิว", value: "พักคิว" },
  //   { key: "ยกเลิกวางแผนการรักษา", text: "ยกเลิกวางแผนการรักษา", value: "ยกเลิกวางแผนการรักษา" },
  //   { key: "ยกเลิกนัดหมาย", text: "ยกเลิกนัดหมาย", value: "ยกเลิกนัดหมาย" },
  //   { key: "เลื่อนนัดหมาย", text: "เลื่อนนัดหมาย", value: "เลื่อนนัดหมาย" },
  // ];

  // CardReconfirm
  const handleChangeAppointmentType = (e: any, { value }: any) => {
    setSelectedAppointmentType(value);
  };

  const handleOnChangeStartDateRC = (date: string) => {
    setStartDateRC(date);
  };

  const handleOnChangeEndDateRC = (date: string) => {
    setEndDateRC(date);
  };

  // CardReschedule
  const handleChangeSelectWaitingListRS = (e: any, { value }: any) => {
    setSelectWaitingListRS(value);
  };

  const handleChangeSelectStatusRS = (e: any, { value }: any) => {
    setSelectStatusRS(value);
  };

  const handleOnChangeStartDateRS = (date: string) => {
    setStartDateRS(date);
  };

  const handleOnChangeEndDateRS = (date: string) => {
    setEndDateRS(date);
  };

  const changeDate = (key: string) => (value: any) => {
    console.log(value);
    const newDateString = (parseDate(value, true) as moment.Moment).format(DATE_FORMAT);
    if (key === "startDateModWL") {
      setStartDateModWL(newDateString);
    } else if (key === "endDateModWL") {
      setEndDateModWL(newDateString);
    } else if (key === "startDateModST") {
      setStartDateModST(newDateString);
    } else if (key === "endDateModST") {
      setEndDateModST(newDateString);
    }
  };

  const sortDateWaitngList = () => {
    // console.log("waitingQueueList: ", waitingQueueList);
    const dateWaitngList = waitingQueueList?.sort((a: any, b: any) =>
      isAscendingCreated ? (a.created < b.created ? 1 : -1) : a.created < b.created ? -1 : 1
    );
    // console.log("dateWaitngList: ", dateWaitngList);
    setIsAscendingCreated(!isAscendingCreated);
    setWaitingQueueList([...dateWaitngList]);
  };

  const sortDateAppointment = () => {
    // console.log("waitingQueueList: ", waitingQueueList);
    const dateAppointment = waitingQueueList?.sort((a: any, b: any) =>
      isAscendingAppointed
        ? a.appointment__estimated_at < b.appointment__estimated_at
          ? 1
          : -1
        : a.appointment__estimated_at < b.appointment__estimated_at
        ? -1
        : 1
    );
    // console.log("dateAppointment: ", dateAppointment);
    setIsAscendingAppointed(!isAscendingAppointed);
    setWaitingQueueList([...dateAppointment]);
  };

  const sortWaitingTime = () => {
    // console.log("waitingQueueList: ", waitingQueueList);
    const waitingTime = waitingQueueList?.sort((a: any, b: any) =>
      isAscendingWaitingTime
        ? a.waiting_time < b.waiting_time
          ? 1
          : -1
        : a.waiting_time < b.waiting_time
        ? -1
        : 1
    );
    // console.log("waitingTime: ", waitingTime);
    setIsAscendingWaitingTime(!isAscendingWaitingTime);
    setWaitingQueueList([...waitingTime]);
  };

  //  //Export .CSV ModWaitingList
  const headersModWL = [
    { label: "HN", key: "patient__hn" },
    { label: "ชื่อ - สกุล", key: "name" },
    { label: "วันที่เข้า Waiting List", key: "created" },
    { label: "วันที่นัดหมาย", key: "appointment__estimated_at" },
    { label: "ระยะเวลารอคิว (วัน)", key: "waiting_time" },
  ];

  // //Export .CSV ModStatic
  const headersModST = [
    { label: "จำนวนผู้ป่วยที่อยู่ใน Waiting List", key: "all" },
    { label: "จำนวนผู้ป่วยที่ยกเลิกคิว", key: "cancel" },
    { label: "% การยกเลิกคิว", key: "cancel_percent" },
  ];

  const handleConfirmDuplicate = () => {
    props.onEvent({
      message: "SetScheduling",
      params: {
        ...props.duplicateAppointment.payload,
        repetition_note: duplicateReason,
      },
    });
  };
  const handleCancelDuplicate = () => {
    props.setProp("duplicateAppointment", null);

    props.setProp(`buttonLoadCheck.${CARD_PATIENT_APPOINTMENT}_CONFIRM`, null);
  };

  // console.log("CardPatientAppointment : ", props);
  // console.log('CardPatientAppointment : props.divisionList: ', props.divisionList);
  const handleSelectAppointment = async (rowInfo: any) => {
    if (isSelectingAppRef.current) {
      return;
    };

    isSelectingAppRef.current = true;

    if (props.PackageDateTimeSequence?.sequenceIndex) {
      await props.setProp("PackageDateTimeSequence", null);
    }

    props.onEvent({
      message: "SelectAppointment",
      params: rowInfo?.original,
      card: CARD_PATIENT_APPOINTMENT,
    });
    setEstimatedAt("");
    setEstimatedDuration("");

    setTimeout(() => {
      isSelectingAppRef.current = false;
    }, 500);
  };

  const handleSelectEncounter = (
    encounter: any,
    defaultCreate: string = "",
    enType: string = ""
  ) => {
    setOpenModSelectEncounter(null);
    if (defaultCreate === "CONSULT") {
      handleAddConsultAppointment(enType as "OPD" | "IPD", encounter);
    } else {
      props.onEvent({
        message: "CreatePatientAppointment",
        params: {
          WaitingList: selectedWaitingList,
          card: CARD_PATIENT_APPOINTMENT,
          is_or_type: true,
          encounter_id: encounter,
        },
      });
    }
  };

  const handleAddConsultAppointment = (consultType: string, encounter: any = null) => {
    props.onEvent({
      message: "CreatePatientAppointment",
      params: {
        WaitingList: selectedWaitingList,
        card: CARD_PATIENT_APPOINTMENT,
        is_or_type: false,
        encounter_id: encounter,
        consult_type: consultType,
      },
    });
  };

  const handleCreateSuccess = () => {
    goToTabSelectDateTime.current = true;
    setTab("selectdatetimeoperation");
  };

  const handleUpdateOperationTime = (data: any = {}) => {
    if (data.message === "RECONFIRM") {
      return handleSearchReconfirm();
    }

    goToTabOtherOrder.current = true;
    setTab("otherorderappointment");
  };

  const handleAddAppointmentPackage = () => {
    const list: any[] = [...(props.appointmentList || [])];

    const findAppPackage = list.find(
      (item) => item.id === null && item.appointment_type === "PACKAGE"
    );

    if (!!findAppPackage) {
      return handleSelectAppointment({ original: findAppPackage });
    }

    list.push({
      id: null,
      appointment_type: "PACKAGE",
      patient_name: props.selectedPatient?.full_name,
      division_name: props.selectedDivision?.name,
    });

    props.setProp("appointmentList", list);
  };

  const handleClearAppointment = () => {
    hnSearchRefPA.current?.clear();

    setPatientSearchText("");
    setSelectedPatientId(null);

    props.onEvent({ message: "ClearAppointment", params: {} });
  };

  const selectedConsultId = useMemo(() => {
    return (
      props.selectedAppointment?.orders?.find((item: any) => item.type === DOCTOR_CONSULT_ORDER)
        ?.id || null
    );
  }, [props.selectedAppointment]);

  const handleSetModOrder = (type: string) => {
    setOpenModOrder({ type: type });
    setModOrder(type);
  };

  const handlePatientSearch = () => {
    const selectedPatient = props.goToCreateAppointment?.selectedPatient;

    if (selectedPatient) {
      props.setProp("goToCreateAppointment", null);
    }

    const searchText = hnSearchRefPA.current?.value?.(); // || patientSearchText

    props.onEvent({
      message: "SearchPatient",
      params: { patientSearchText: searchText },
    });
  };

  const handleSearchReconfirm = () => {
    console.log("onSearch");
    console.log("onSearch hnSearchRefRC?.current?.value: ", hnSearchRefRC?.current?.value());
    // console.log("onSearch selectedDoctorRC", selectedDoctorRC);
    // console.log("onSearch selectDivisionRC", selectDivisionRC);
    // console.log("onSearch selectWaitingListRC", selectWaitingListRC);
    // console.log("onSearch selectStatusRC", selectStatusRC);
    // console.log("onSearch startDateRC", startDateRC);
    // console.log("onSearch endDateRC", endDateRC);

    // query parameters:
    // hn: เลข hn ของผู้ป่วย
    // provider_id: provider id ของ หมอ
    // division_id : id ของ หน่วย/แผนก
    // waiting_list: <string>
    // start_date: วันที่เริ่มต้น ในรูปแบบ format DD/MM/YYYY BE
    // end_date: วันที่สิ้นสุด ในรูปแบบ format DD/MM/YYYY BE
    // type: ชนิดของนัดหมาย (id ของ clinicalTermset ของ ประเภทนัดหมาย)

    props.onEvent({
      message: "SetScheduling",
      params: {
        action: "GetReconfirmAppointment",
        hn: hnSearchRefRC?.current?.value(),
        provider_id: selectedDoctorRC?.id,
        division_id: selectDivisionRC?.id,
        waiting_list: selectWaitingListRC,
        start_date: startDateRC,
        end_date: endDateRC,
        type: selectStatusRC,
        appointment_type: selectAppointmentType,
      },
    });
  };

  const handlePageChangeModWaitingList = (event: SyntheticEvent, data: any)  => {
    setFilterActivePage(data.activePage);
  }

  // console.log("CardPatientAppointment", props.selectedAppointment, props.selectedBlock);
  // console.log('CardPatientAppointment selectedDoctorEditWaiting: ', props, selectedWaitingList, selectedDoctorAddWaiting, selectedDoctorEditWaiting);
  // console.log("waitingQueueList", waitingQueueList);
  // console.log("CardPatientAppointment props: ", props.selectedEmr?.id, tab, mode);

  // console.log("CardPatientAppointment filterWaitingList : ", filterWaitingList);
  console.log("CardPatientAppointment waitingListItems: ", waitingListItems);
  // console.log("CardPatientAppointment selectedWaitingList: ", selectedWaitingList);
  // console.log("CardPatientAppointment selectedWaitingListItem: ", selectedWaitingListItem);
  // console.log("CardPatientAppointment selectedDoctorEditWaiting: ", selectedDoctorEditWaiting);

  return (
    <div id="CardPatientAppointment">
      <ModConfirmAuthen
        {...questionDetail}
        options={options}
        onEvent={props.onEvent}
        setProp={props.setProp}
        userTokenize={props.userTokenize}
        onButtonLeftClick={handleConfirmedButton}
        onButtonRightClick={handleCancelButton}
        open={openModMessage}
        onClose={handleCancelButton}
        isButtonBasic={true}
        handleAddition={handleAddition}
        handleChange={handleChange}
        currentValue={currentValue}
        isErrMsg={true}
        multiple={questionDetail?.multiple}
      />

      <CardPatientAppointmentUX
        isShowChair={CONFIG.SHOW_DOCTOR_CHAIR}
        setProp={props.setProp}
        onEvent={props.onEvent}
        mode={mode}
        setMode={setMode}
        tab={tab}
        formats={formats}
        // tab="selectpackage"
        setTab={setTab}
        calendarDisabled={!props.selectedAppointment?.id}
        detailDisabled={
          !props.selectedAppointment?.id ||
          (props.selectedAppointment?.type_display !== TYPE_DISPLAY.or &&
            props.selectedAppointment?.id &&
            !props.selectedAppointment?.estimated_at)
        }
        // detailDisabled={!props.selectedAppointment?.id || !props.selectedBlock}
        disabledPostponeBtn={!!props.config?.disabledPostpone} // CONFIG.DISABLED_POSTPONE}
        disabledWaitingListBtn={!!props.config?.disabledWaitingList} // CONFIG.DISABLED_WAITINGLIST}
        disabledSelectDateTime={!props.selectedOrOrder?.id}
        disabledSelectDateTimePackage={
          !props.PackageDateTimeSequence?.selectedPackageItems?.length ||
          (!!props.selectedAppointment?.date && props.selectedAppointment?.status !== 4)
        }
        disabledOtherOrder={!props.ORRequestSequence?.id}
        appointmentHeaders={
          mode === "search" ? "ชื่อ-สกุล,,หน่วยงาน-แพทย์นัดหมาย,วันเวลานัดหมาย" : ""
        }
        appointmentKeys={mode === "search" ? "patient_name,icon,detail,estimated_time" : ""}
        appointmentWidths={mode === "search" ? "^100,^40,^90,^90" : ""}
        // Feature 68560
        allDivisionsOptions={props.allDivisionList
          ?.filter((i: any) => i.type !== 2 && i.type !== 10)
          ?.map((i: any) => ({ key: i.id, text: i.name, value: i.code }))}
        selectedDivision={props.selectedAppointmentDivision?.code}
        onChangeSelectedDivision={(e: SyntheticEvent<HTMLElement>, data: DropdownProps) => {
          let division = props.allDivisionList?.find((i: any) => i.code === data?.value);
          props.setProp("selectedAppointmentDivision", division);
        }}
        otherOrderAppointmentCard={
          tab === "otherorderappointment" && (
            <CardOtherOrderAppointment
              controller={props.controller}
              onEvent={props.onEvent}
              setProp={props.setProp}
              config={props.config}
              runSequence={props.runSequence}
              masterOptions={props.masterOptions}
              // config
              hidePrintAppointmentDoc={props.selectedAppointment?.type_display === TYPE_DISPLAY.or}
              showOrderAppointment={props.showOrderAppointment}
              hideDrugAllergy={props.hideDrugAllergy}
              // drugOperatingOrderId={props.preOrderList?.find((item: any) => item.type === "drugoperatingorder")?.id}
              setTab={setTab}
              setModOrder={setModOrder}
              ORRequestSequence={props.ORRequestSequence}
              OrderTreatmentSequence={props.OrderTreatmentSequence}
              treatmentOrderList={props.treatmentOrderList}
              userLabPermission={props.userLabPermission}
              searchedItemList={props.searchedItemList}
              successMessage={props.successMessage}
              errorMessage={props.errorMessage}
              procedureItems={props.procedureItems}
              secondaryItems={props.secondaryItems}
              treatmentTemplateList={props.treatmentTemplateList}
              selectedAppointment={props.selectedAppointment}
              // central lab
              OrderCentralLabSequence={props.OrderCentralLabSequence}
              selectedEncounter={props.selectedEncounter}
              selectedEmr={props.selectedEmr}
              selectedProgressCycle={props.selectedProgressCycle}
              doctorLabOrderList={props.doctorLabOrderList}
              // imaging
              imagingList={props.imagingList}
              orderImagingList={props.orderImagingList}
              loadingStatus={props.loadingStatus}
              buttonLoadCheck={props.buttonLoadCheck}
              selectedOperatingOption={props.selectedOperatingOption}
              //CardDrugOrder
              selectedDrugItemDetail={props.selectedDrugItemDetail}
              selectedDrugItemDetailOptions={props.selectedDrugItemDetailOptions}
              drugSearchText={props.drugSearchText}
              drugSearchResult={props.drugSearchResult}
              DrugSelectSequence={props.DrugSelectSequence}
              selectedDrug={props.selectedDrug}
              drugOrderList={props.drugOrderList}
              drugOrderListLoading={props.drugOrderListLoading}
              drugOrder={props.drugOrder}
              renderDrugOrderItems={props.renderDrugOrderItems}
              drugDose={props.drugDose}
              drugQty={props.drugQty}
              drugDuration={props.drugDuration}
              drugDescription={props.drugDescription}
              drugPrn={props.drugPrn}
              drugSearchLoading={props.drugSearchLoading}
              // modal doctor certificate check
              modDoctorCertificate={props.modDoctorCertificate}
              modDrugLabInteraction={props.modDrugLabInteraction}
              modDrugDisease={props.modDrugDisease}
              modDrugInteraction={props.modDrugInteraction}
              currentDoctor={props.currentDoctor}
              // modal drug order template
              DrugOrderTemplateSequence={props.DrugOrderTemplateSequence}
              modReMedication={props.modReMedication}
              modConfirmDeleteDrug={props.modConfirmDeleteDrug}
              drugOrderItemEstimate={props.drugOrderItemEstimate}
              drugPermission={props.drugPermission}
              modSolventSelectionConfig={props.modSolventSelectionConfig}
              selectedVerbalDrugOrderWorking={props.selectedVerbalDrugOrderWorking}
              preOrderList={props.preOrderList}
              defaultModOrder={modOrder}
              defaultOrderBy={true}
              //
              availableOptions={props.availableSlots}
              searchedItemListWithKey={props.searchedItemListWithKey}
              userTokenize={props.userTokenize}
              // Imaging
              ImagingOrderSequence={props.ImagingOrderSequence}
              modXrayDetail={props.modXrayDetail}
              // ----- Dental Diagram
              clinicalFindingId={props.clinicalFindingId}
              clinicalFindingIndex={props.clinicalFindingIndex}
              clinicalFindings={props.clinicalFindingList || []}
              organ={props.organ}
              clinicaltags={props.clinicaltags}
              filterClinicalFindingIds={props.filterClinicalFindingIds || []}
            />
          )
        }
        selectdatetimeopertionCard={
          tab === "selectdatetimeoperation" && (
            <>
              <CardSelectDateTimeOperation
                runSequence={props.runSequence}
                masterOptions={props.masterOptions}
                operatingLocationRoom={props.operatingLocationRoom}
                errorMessage={props.errorMessage}
                successMessage={props.successMessage}
                setProp={props.setProp}
                // data
                preOrderList={props.preOrderList}
                OperatingDateTimeSequence={props.OperatingDateTimeSequence}
                onPostpone={handleOnPostponeOperatingAppointment}
                selectedOperatingDetail={props.selectedOperatingDetail}
                selectedOrOrder={props.selectedOrOrder}
                selectedDoctor={props.selectedDoctor}
                selectedAnesthesiologist={props.selectedAnesthesiologist}
                selectedOperatingRoom={props.selectedOperatingRoom}
                operatingBlock={props.operatingBlock}
                selectOperatingDSBChange={props.selectOperatingDSBChange}
                estimateTimeText={props.estimateTimeText}
                selectedAppointment={props.selectedAppointment}
                // callback
                onUpdated={handleUpdateOperationTime}
              />
            </>
          )
        }
        selectConsultDetail={
          <CardConsultDetail
            // data
            emrId={props.selectedEmr?.id}
            refreshId={props.selectedAppointment?.id}
            selectedConsultId={selectedConsultId} // Todo
            selectedAppointment={props.selectedAppointment}
            consultDetail={props.consultDetail}
            consultData={props.consultData}
            // CommonInterface
            errorMessage={props.errorMessage}
            successMessage={props.successMessage}
            buttonLoadCheck={props.buttonLoadCheck}
            // function
            onEvent={props.onEvent}
            setProp={props.setProp}
            mapProviderOptions={mapProviderOptions}
            onSelectDateTime={() => {
              setTab("consultDateTime");
            }}
            onConfirmAppointmentSuccess={() => {}}
            // options
            masterOptions={props.masterOptions}
            divisionList={props.divisionList}
            config={{
              isSource: false,
              sourceReadOnly: true,
              isDestination: true,
              destinationReadOnly: !!props.consultDetail?.id,
              hideEditSourceNote: false,
              hideDestinationNote: true,
              hideEditDestinationNote: true,
              hideActionBar: false,
              hideConfirmButton: true,
              // เนื่องจากผู้ขอคำปรึกษา ไม่สามารถ ยืนยันนัดหมายด้วยตนเองได้ ต้องไปยืนยันที่หน้าสรุปนัดหมาย
              // props.selectedAppointment?.status !== 1 ||
              // !props?.consultDetail?.destinationProviderDoctor,
              hideEditDateTimeButton: [
                APPOINTMENT_STATUS.CONFIRMED,
                APPOINTMENT_STATUS.FINISHED,
              ].includes(props.consultDetail?.app_status),
              hideSaveButton: false,
              hideEditButton: false,
              hideCancelButton: false,
              hideCloseIcon: true,
              hideConsultStatus: true,
              hideResponseButton: true,
              hideFinishButton: true,
              hideReverseFinishButton: true,
            }}
          />
        }
        selectConsultDateTime={
          <CardConsultDateTime
            // data
            selectedPatient={props.selectedPatient}
            selectedAppointment={props.selectedAppointment}
            consultDetail={props.consultDetail}
            consultData={props.consultData}
            buttonLoadCheck={props.buttonLoadCheck}
            duplicateAppointment={props.duplicateAppointment}
            reoccureDuplicateAppointment={props.reoccureDuplicateAppointment}
            userTokenize={props.userTokenize}
            // function
            onEvent={props.onEvent}
            setProp={props.setProp}
            mapProviderOptions={mapProviderOptions}
            backToDetailTab={() => {
              setTab("consultDetail");
            }}
            // options
            divisionList={props.divisionList}
          />
        }
        PopupElement={
          <Popup
            open={openPopup}
            onClose={() => setOpenPopup(false)}
            position="bottom right"
            content={
              <div>
                <Button
                  content={APPOINTMENT_MSG.appointment}
                  color="orange"
                  size="small"
                  fluid
                  style={{ margin: "0.25rem 0" }}
                  onClick={() => {
                    setOpenPopup(false);
                    props.onEvent({
                      message: "CreatePatientAppointment",
                      params: {
                        WaitingList: selectedWaitingList,
                        card: CARD_PATIENT_APPOINTMENT,
                      },
                    });
                  }}
                />
                {!!props.django?.user?.role_types?.find((role: string) =>
                  ["DOCTOR"].includes(role)
                ) && (
                  <>
                    <Button
                      content={APPOINTMENT_MSG.new_or}
                      color="teal"
                      size="small"
                      fluid
                      style={{ margin: "0.25rem 0" }}
                      onClick={() => {
                        // setOpenModSelectEncounter({ open: true, isNewEncounter: true });
                        setOpenPopup(false);
                        handleSelectEncounter(undefined);
                      }}
                    />
                    <Button
                      content={APPOINTMENT_MSG.or}
                      color="blue"
                      size="small"
                      fluid
                      style={{ margin: "0.25rem 0" }}
                      onClick={() => {
                        setOpenModSelectEncounter({
                          open: true,
                          isNewEncounter: false,
                          appointmentType: "OR",
                          encounterType: "",
                        });
                        setOpenPopup(false);
                      }}
                    />
                  </>
                )}
                {!!props.django?.user?.role_types?.find((role: string) =>
                  ["DOCTOR", "REGISTERED_NURSE"].includes(role)
                ) && (
                  <>
                    <Button
                      content={APPOINTMENT_MSG.package}
                      color="purple"
                      size="small"
                      fluid
                      style={{ margin: "0.25rem 0" }}
                      onClick={() => {
                        handleAddAppointmentPackage();
                        setOpenPopup(false);
                      }}
                    />
                  </>
                )}
                <Button
                  content={APPOINTMENT_MSG.consult_opd}
                  color="green"
                  size="small"
                  fluid
                  style={{ margin: "0.25rem 0" }}
                  onClick={() => {
                    setOpenModSelectEncounter({
                      open: true,
                      isNewEncounter: true,
                      appointmentType: "CONSULT",
                      encounterType: "OPD",
                    });
                    setOpenPopup(false);
                  }}
                />

                {encounterIPD?.id && (
                  <Button
                    content={APPOINTMENT_MSG.consult_ipd}
                    color="green"
                    size="small"
                    fluid
                    style={{ margin: "0.25rem 0" }}
                    onClick={() => {
                      setOpenModSelectEncounter({
                        open: true,
                        isNewEncounter: false,
                        appointmentType: "CONSULT",
                        encounterType: "IPD",
                      });
                      // setOpenPopup(false);
                      // handleAddConsultAppointment("IPD", encounterIPD?.id);
                      setOpenPopup(false);
                    }}
                  />
                )}
              </div>
            }
            on="click"
            pinned
            trigger={
              <Button
                content="เพิ่มนัดหมาย"
                color="green"
                size="small"
                disabled={!props.selectedPatient}
                onClick={() => setOpenPopup(true)}
              />
            }
          />
        }
        // Left panel --------------------------------------------------------------------------
        // Search Patient
        doctorSearch={
          <SearchBoxDropdown
            type="Provider"
            id="5"
            style={{ width: "100%" }}
            fluid={true}
            label=""
            // disabled={selectedManagePatient !== null}
            toDisplay={(item: any) =>
              `${item.pre_name || ""} ${item.first_name} ${item.last_name} [${item.code}]`
            }
            onEvent={props.onEvent}
            // searchedItemList={props.searchedItemList}
            searchedItemListWithKey={props.searchedItemListWithKey}
            selectedItem={selectedDoctorPA}
            setSelectedItem={(value: any, key: any) => {
              if (value === null && key === null) {
                setSelectedDoctorPA(null);
                return;
              }
              let doctor = props.searchedItemListWithKey?.Provider_5?.find(
                (item: any) => item.provider_id === key
              );
              setSelectedDoctorPA(doctor);
            }}
            useWithKey={true}
            mapOptions={mapProviderOptions}
          />
        }
        divisionSearch={
          <SearchBoxDropdown
            type="Division"
            id="3"
            style={{ width: "100%" }}
            fluid={true}
            label=""
            // disabled={selectedManagePatient !== null}
            toDisplay={(item: any) => `${item.name}`}
            onEvent={props.onEvent}
            // searchedItemList={props.searchedItemList}
            searchedItemListWithKey={props.searchedItemListWithKey}
            selectedItem={selectDivisionPA}
            setSelectedItem={(value: any, key: any) => {
              if (value === null && key === null) {
                setSelectDivisionPA(null);
                return;
              }
              let division = props.searchedItemListWithKey?.Division_3?.find(
                (item: any) => item.code === key
              );
              setSelectDivisionPA(division);
            }}
            useWithKey={true}
            mapOptions={mapDivisionOptions}
          />
        }
        waitingListFilter={waitingListNamePA}
        onChangeWaitingListFilter={(e: any, { value }: any) => {
          setWaitingListNamePA(value);
        }}
        patientSearch={
          <PatientSearchBoxCU
            ref={hnSearchRefPA}
            modalStyle={{ width: "auto" }}
            controller={props.proxyController}
            inputChangeAutoSearch={false}
            onSelectPatient={handleOnSelectPatient}
            onEnter={handleOnSelectPatient}
            systemHn8Digit={CONFIG.COMPANY === "CU"}
            fluid={true}
            userProfile={props.django?.user}
          />
        }
        patientList={patientList}
        onPatientSearch={handlePatientSearch}
        patientRowProps={(state: any, rowInfo: any, column: any, instance: any) => ({
          style: {
            backgroundColor:
              props.selectedPatient && rowInfo?.original?.id === props.selectedPatient?.id
                ? "#a5dae6"
                : "#d6ecf3",
            fontWeight:
              props.selectedPatient && rowInfo?.original?.id === props.selectedPatient?.id
                ? "bold"
                : "normal",
            cursor: "pointer",
          },
          // ใส่ blueSelectedRow เพื่อกัน css hover chain ใน ReactTAble.css
          className:
            props.selectedPatient && rowInfo?.original?.id === props.selectedPatient?.id
              ? "blueSelectedRow"
              : "",
          onClick: () => {
            // console.log(" select Patient")
            props.onEvent({
              message: "SelectPatient",
              params: rowInfo?.original,
              card: CARD_PATIENT_APPOINTMENT,
            });
          },
        })}
        // #55840
        disabledCreatePatientAppointment={!props.selectedPatient}
        clearAppointment={handleClearAppointment}
        appointmentFilterList={appointmentFilterList}
        appointmentList={appointmentListForPatientAppointment(props.appointmentList)}
        selectedAppointment={props.selectedAppointment}
        isOR={props.selectedAppointment?.type_display === TYPE_DISPLAY.or}
        isConsult={[TYPE_DISPLAY.consult_ipd, TYPE_DISPLAY.consult_opd].includes(
          props.selectedAppointment?.type_display
        )}
        isPackage={props.selectedAppointment?.appointment_type === "PACKAGE"}
        createPatientAppointment={() =>
          props.onEvent({
            message: "CreatePatientAppointment",
            params: {
              WaitingList: selectedWaitingList,
              card: CARD_PATIENT_APPOINTMENT,
            },
          })
        }
        hideAppointmentTable={false && props.selectedPatient?.id === null}
        appointmentRowProps={(state: any, rowInfo: any, column: any, instance: any) => ({
          style: {
            // backgroundColor:
            //   props.selectedAppointment && rowInfo?.original?.id === props.selectedAppointment?.id
            //     ? "#cccccc"
            //     : "#d6ecf3",
            backgroundColor:
              props.selectedAppointment && rowInfo?.original?.id === props.selectedAppointment?.id
                ? "#a5dae6"
                : "#d6ecf3",
            fontWeight:
              props.selectedAppointment && rowInfo?.original?.id === props.selectedAppointment?.id
                ? "bold"
                : "normal",
            cursor: "pointer",
          },
          // ใส่ blueSelectedRow เพื่อกัน css hover chain ใน ReactTAble.css
          className:
            props.selectedAppointment && rowInfo?.original?.id === props.selectedAppointment?.id
              ? "blueSelectedRow"
              : "",
          onClick: () => handleSelectAppointment(rowInfo),
        })}
        // OR Request
        CardORRequest={
          tab === "or_request" && (
            <CardORRequest
              // callback
              onEvent={props.onEvent}
              setProp={props.setProp}
              runSequence={props.runSequence}
              setTab={setTab}
              setModOrder={handleSetModOrder}
              // controller
              proxyController={props.proxyController}
              // data
              ORRequestSequence={props.ORRequestSequence}
              searchedItemListWithKey={props.searchedItemListWithKey}
              masterData={props.masterData}
              patientAppointmentId={props.selectedAppointment?.id}
              operatingOrderId={
                props.preOrderList?.find((item: any) => item.type === "operatingorder")?.id || null
              }
              encounterId={props.selectedAppointment?.order_encounter}
              isNewEncounter={props.selectedAppointment?.is_new_order_encounter}
              patientId={props.selectedAppointment?.patient}
              errorMessage={props.errorMessage}
              loadingStatus={props.loadingStatus}
              buttonLoadCheck={props.buttonLoadCheck}
              userTokenize={props.userTokenize}
              divisionId={props.divisionId}
              medReconcileCheck={props.medReconcileCheck}
              medReconcileIndex={props.medReconcileIndex}
              selectedEncounter={props.selectedEncounter}
              django={props.django}
              cardStyle={{ height: "" }}
              onCreateSuccess={handleCreateSuccess}
              // options
              masterOptions={props.masterOptions}
              // config
              isCancel={true}
              // isConfirm={true}
              // isCancelConfirm={true}
            />
          )
        }
        // Package
        selectpackageCard={
          tab === "selectpackage" && (
            <CardSelectPackage
              onEvent={props.onEvent}
              setProp={props.setProp}
              // data
              selectedPatient={props.selectedPatient}
              // controller
              proxyController={props.proxyController}
              // seq
              runSequence={props.runSequence}
              PackagePurchaseSequence={props.PackagePurchaseSequence}
              PackageDateTimeSequence={props.PackageDateTimeSequence}
              // CommonInterface
              selectedDivision={props.selectedDivision}
              userTokenize={props.userTokenize}
              masterOptions={props.masterOptions}
              selectedAppointment={props.selectedAppointment}
              buttonLoadCheck={props.buttonLoadCheck}
              errorMessage={props.errorMessage}
              searchedItemListWithKey={props.searchedItemListWithKey}
            />
          )
        }
        selectdatetimepackageCard={
          tab === "selectdatetimepackage" && (
            <CardSelectDateTimePackage
              onEvent={props.onEvent}
              setProp={props.setProp}
              // data
              selectedPatient={
                props.selectedPatient || {
                  full_name: "นาย โชคชัย งามแท้",
                  hn: "65008965",
                  birthdate: "01/12/2530",
                  phone_num: "065-874-8368",
                }
              }
              // controller
              proxyController={props.proxyController}
              // seq
              runSequence={props.runSequence}
              PackageDateTimeSequence={props.PackageDateTimeSequence}
              // CommonInterface
              selectedDivision={props.selectedDivision}
              buttonLoadCheck={props.buttonLoadCheck}
              errorMessage={props.errorMessage}
              selectedAppointment={props.selectedAppointment}
            />
          )
        }
        // Reschedule
        reschedule={
          mode === "reschedule" && (
            <CardRescheduleUX
              onClickSearch={() => {
                console.log("onSearch");
                console.log(
                  "onSearch hnSearchRefRS?.current?.value: ",
                  hnSearchRefRS?.current?.value()
                );
                console.log("onSearch selectedDoctorRS", selectedDoctorRS);
                console.log("onSearch selectDivisionRS", selectDivisionRS);
                console.log("onSearch selectWaitingListRS", selectWaitingListRS);
                console.log("onSearch selectStatusRS", selectStatusRS);
                console.log("onSearch startDateRS", startDateRS);
                console.log("onSearch endDateRS", endDateRS);

                // query parameters:
                // hn: เลข hn ของผู้ป่วย
                // provider_id: provider id ของ หมอ
                // division_id : id ของ หน่วย/แผนก
                // waiting_list: <string>
                // start_date: วันที่เริ่มต้น ในรูปแบบ format DD/MM/YYYY BE
                // end_date: วันที่สิ้นสุด ในรูปแบบ format DD/MM/YYYY BE
                // type: ชนิดของนัดหมาย (id ของ clinicalTermset ของ ประเภทนัดหมาย)

                props.onEvent({
                  message: "SetScheduling",
                  params: {
                    action: "GetRescheduleAppointment",
                    hn: hnSearchRefRS?.current?.value(),
                    provider_id: selectedDoctorRS?.provider_id,
                    division_id: selectDivisionRS?.id,
                    waiting_list: selectWaitingListRS,
                    start_date: startDateRS,
                    end_date: endDateRS,
                    type: selectStatusRS,
                  },
                });
              }}
              onChangeStartDate={handleOnChangeStartDateRS}
              onChangeEndDate={handleOnChangeEndDateRS}
              startDate={startDateRS}
              endDate={endDateRS}
              searchHN={
                <PatientSearchBoxCU
                  fluid={true}
                  modalStyle={{ width: "auto" }}
                  ref={hnSearchRefRS}
                  controller={props.proxyController}
                  inputChangeAutoSearch={false}
                  onSelectPatient={handleOnSelectPatient}
                  onEnter={handleOnSelectPatient}
                  systemHn8Digit={CONFIG.COMPANY === "CU"}
                  userProfile={props.django?.user}
                />
              }
              searchDoctor={
                <SearchBoxDropdown
                  type="Provider"
                  id="4"
                  style={{ width: "100%" }}
                  fluid={true}
                  label=""
                  // disabled={selectedManagePatient !== null}
                  toDisplay={(item: any) =>
                    `${item.pre_name || ""} ${item.first_name} ${item.last_name} [${item.code}]`
                  }
                  onEvent={props.onEvent}
                  // searchedItemList={props.searchedItemList}
                  searchedItemListWithKey={props.searchedItemListWithKey}
                  selectedItem={selectedDoctorRS}
                  setSelectedItem={(value: any, key: any) => {
                    if (value === null && key === null) {
                      setSelectedDoctorRS(null);
                      return;
                    }
                    let doctor = props.searchedItemListWithKey?.Provider_4?.find(
                      (item: any) => item.provider_id === key
                    );
                    setSelectedDoctorRS(doctor);
                  }}
                  useWithKey={true}
                  mapOptions={mapProviderOptions}
                />
              }
              searchClinic={
                <SearchBoxDropdown
                  type="Division"
                  id="2"
                  style={{ width: "100%" }}
                  fluid={true}
                  label=""
                  // disabled={selectedManagePatient !== null}
                  toDisplay={(item: any) => `${item.name}`}
                  onEvent={props.onEvent}
                  // searchedItemList={props.searchedItemList}
                  searchedItemListWithKey={props.searchedItemListWithKey}
                  selectedItem={selectDivisionRS}
                  setSelectedItem={(value: any, key: any) => {
                    if (value === null && key === null) {
                      setSelectDivisionRS(null);
                      return;
                    }
                    let division = props.searchedItemListWithKey?.Division_2?.find(
                      (item: any) => item.code === key
                    );
                    setSelectDivisionRS(division);
                  }}
                  useWithKey={true}
                  mapOptions={mapDivisionOptions}
                />
              }
              searchWaitingList={
                <Dropdown
                  style={{ width: "100%" }}
                  selection
                  value={selectWaitingListRS}
                  onChange={handleChangeSelectWaitingListRS}
                  options={mapWaitingListOptions(props.waitingList)}
                  clearable={true}
                />
              }
              searchStatus={
                <Dropdown
                  style={{ width: "100%" }}
                  selection
                  value={selectStatusRS}
                  onChange={handleChangeSelectStatusRS}
                  options={props.waitingListItemStatus || []}
                  clearable={true}
                />
              }
              rescheduleList={props.rescheduleList}
              rowProps={(state: any, rowInfo: any, column: any, instance: any) => ({
                className:
                  props.selectedAppointment &&
                  rowInfo?.original?.id === props.selectedAppointment?.id
                    ? "blueSelectedRow"
                    : "",
                style: {
                  // backgroundColor: props.selectedAppointment && rowInfo?.original?.id === props.selectedAppointment?.id ? "#cccccc" : "#d6ecf3",
                  cursor: "pointer",
                },
                onClick: () => handleSelectAppointment(rowInfo),
              })}
            />
          )
        }
        // Reconfirm
        reconfirm={
          mode === "reconfirm" && (
            <CardReconfirmUX
              onClickSearch={handleSearchReconfirm}
              onChangeStartDate={handleOnChangeStartDateRC}
              onChangeEndDate={handleOnChangeEndDateRC}
              startDate={startDateRC}
              endDate={endDateRC}
              searchHN={
                <PatientSearchBoxCU
                  fluid={true}
                  modalStyle={{ width: "auto" }}
                  ref={hnSearchRefRC}
                  controller={props.proxyController}
                  inputChangeAutoSearch={false}
                  onSelectPatient={handleOnSelectPatient}
                  onEnter={handleOnSelectPatient}
                  systemHn8Digit={CONFIG.COMPANY === "CU"}
                  userProfile={props.django?.user}
                />
              }
              searchDoctor={
                <SearchBoxDropdown
                  type="Provider"
                  id="3"
                  style={{ width: "100%" }}
                  fluid={true}
                  label=""
                  // disabled={selectedManagePatient !== null}
                  toDisplay={(item: any) =>
                    `${item.pre_name || ""} ${item.first_name} ${item.last_name} [${item.code}]`
                  }
                  onEvent={props.onEvent}
                  // searchedItemList={props.searchedItemList}
                  searchedItemListWithKey={props.searchedItemListWithKey}
                  selectedItem={selectedDoctorRC}
                  setSelectedItem={(value: any, key: any) => {
                    if (value === null && key === null) {
                      setSelectedDoctorRC(null);
                      return;
                    }
                    let doctor = props.searchedItemListWithKey?.Provider_3?.find(
                      (item: any) => item.provider_id === key
                    );
                    setSelectedDoctorRC(doctor);
                  }}
                  useWithKey={true}
                  mapOptions={mapProviderOptions}
                />
              }
              searchClinic={
                <SearchBoxDropdown
                  type="Division"
                  id="1"
                  style={{ width: "100%" }}
                  fluid={true}
                  label=""
                  // disabled={selectedManagePatient !== null}
                  toDisplay={(item: any) => `${item.name}`}
                  onEvent={props.onEvent}
                  // searchedItemList={props.searchedItemList}
                  searchedItemListWithKey={props.searchedItemListWithKey}
                  selectedItem={selectDivisionRC}
                  setSelectedItem={(value: any, key: any) => {
                    if (value === null && key === null) {
                      setSelectDivisionRC(null);
                      return;
                    }
                    let division = props.searchedItemListWithKey?.Division_1?.find(
                      (item: any) => item.code === key
                    );
                    setSelectDivisionRC(division);
                  }}
                  useWithKey={true}
                  mapOptions={mapDivisionOptions}
                />
              }
              // searchWaitingList={
              //   <Dropdown
              //     style={{ width: "100%" }}
              //     selection
              //     value={selectWaitingListRC}
              //     onChange={handleChangeSelectWaitingListRC}
              //     options={mapWaitingListOptions(props.waitingList)}
              //     clearable={true}
              //   />
              // }
              // searchStatus={
              //   <Dropdown
              //     style={{ width: "100%" }}
              //     selection
              //     value={selectStatusRC}
              //     onChange={handleChangeSelectStatusRC}
              //     options={props.waitingListItemStatus || []}
              //     clearable={true}
              //   />
              // }
              searchAppointmentType={
                <Dropdown
                  style={{ width: "100%" }}
                  selection
                  value={selectAppointmentType}
                  onChange={handleChangeAppointmentType}
                  options={APPOINTMENT_TYPE}
                  clearable={true}
                />
              }
              reconfirmList={reconfirmList}
              rowProps={(state: any, rowInfo: any, column: any, instance: any) => ({
                className:
                  props.selectedAppointment &&
                  rowInfo?.original?.id === props.selectedAppointment?.id
                    ? "blueSelectedRow"
                    : "",
                style: {
                  // backgroundColor: props.selectedAppointment && rowInfo?.original?.id === props.selectedAppointment?.id ? "#cccccc" : "#d6ecf3",
                  cursor: "pointer",
                },
                onClick: () => handleSelectAppointment(rowInfo),
              })}
            />
          )
        }
        // Waiting list
        waitingList={
          mode === "waitingList" && (
            <CardWaitingListUX
              // Waiting List
              onNewWaitingList={() => {
                let url = CONFIG.API_HOST + "/admin/QUE/waitinglist";
                globalThis.window?.open?.(url, "_blank")?.focus();
              }}
              onRefresh={() => {
                props.onEvent({
                  message: "SetScheduling",
                  params: {
                    action: "GetWaitingList",
                  },
                });
              }}
              waitingList={filterWaitingList}
              waitingListRowProps={(state: any, rowInfo: any, column: any, instance: any) => ({
                className:
                  selectedWaitingList && rowInfo?.original?.id === selectedWaitingList?.id
                    ? "blueSelectedRow"
                    : "",
                style: {
                  // backgroundColor: selectedWaitingList && rowInfo?.original?.id === selectedWaitingList?.id ? "#cccccc" : "#d6ecf3",
                  ...(rowInfo?.original?.id && { cursor: "pointer" }),
                },
                onClick: () => {
                  if (selectedWaitingList && selectedWaitingList?.id === rowInfo?.original?.id)
                    setSelectedWaitingList(null);
                  else setSelectedWaitingList(rowInfo?.original);
                  console.log("!! rowInfo?.original: ", rowInfo?.original);

                  // Clear selected patient
                  props.onEvent({ message: "SelectPatient", params: null });
                  setSelectedWaitingListItem(null);
                },
              })}
              /// Search
              waitingListSearch={searchWaitingList}
              onChangeWaitingListSearch={(e: any, { value }: any) => {
                setSearchWaitingList(value);
              }}
              // onSearchWaitingList={handleOnSearchWaitingList}
              // Checkbox appointment
              // isAppointed={isAppointed}
              // toggleIsAppointed={(e:any, {checked}: CheckboxProps)=> { setIsAppointed(!!checked)}}
              // สถานะ dropdown
              statusOptions={statusOptions}
              selectedStatus={selectedStatus}
              onSelectedStatus={(e: any, { value }: any) => {
                setSelectedStatus(value);
                //
                console.log(
                  "onSelectedStatus then setSelectedWaitingListItem selectedWaitingListItem to null , before: ",
                  selectedWaitingListItem
                );
                setSelectedWaitingListItem(null);
                props.onEvent({ message: "SelectPatient", params: null });
              }}
              selectedName={
                selectedWaitingListItem?.patient_name ? (
                  <span style={{ fontWeight: "normal", fontSize: "1em" }}>
                    {" "}
                    {selectedWaitingListItem?.patient_name}{" "}
                  </span>
                ) : (
                  ""
                )
              }
              disabledFilter={!selectedWaitingList?.id}
              // Issue 64135
              hiddenCreateWaitingList={
                !props.myPermission?.some((i: any) => i.codename === "CREATE_QUE:waiting_list")
              }
              // Issue 64134
              disabledFilterDoctor={!selectedWaitingList?.id || props.userPosition?.student_flag}
              filterDoctorOptions={filterDoctorOptions}
              filterSelectedDoctor={selectedProviderCode}
              onChangeFilterSelectedDoctor={(e: any, { value }: any) => {
                setSelectedProviderCode(value);
              }}
              searchPatientNameBox={filterPatientName}
              onSearchPatientNameBox={(e: any, { value }: any) => {
                setFilterPatientName(value);
              }}
              selectedWaitingListName={selectedWaitingList?.name || ""}
              setShowModStatic={() => {
                setShowModStatic(true);
                props.onEvent({
                  message: "SetScheduling",
                  params: {
                    action: "SummaryStatic",
                    waitingList: selectedWaitingList,
                  },
                });
              }}
              setShowModWaitingList={(open: boolean) => {
                // props.onEvent({
                //   message: "SetScheduling",
                //   params: {
                //     action: "GetWaitingListItem",
                //     waiting_list_id: selectedWaitingList?.id,
                //   },
                // });

                // setIsAppointed(false);
                // setIsOnHoldQ(false);
                setIsFilterDoctor(false);

                setSelectedWaitingListItem(null);
                setManageWaitingListItems([]);

                setShowModWaitingList(open);
                if (open) {
                  setFilterStartDateWL("");
                  setFilterEndDateWL("");
                  setFilterStatusWL(["PENDING"]);
                }

                props.onEvent({ message: "SelectPatient", params: null });
              }}
              disableWaitingListManage={selectedWaitingList === null}
              // Patient List
              waitingListItems={waitingListItems}
              waitingListItemRowProps={(state: any, rowInfo: any, column: any, instance: any) => ({
                className:
                  selectedWaitingListItem && rowInfo?.original?.id === selectedWaitingListItem?.id
                    ? "blueSelectedRow"
                    : "",
                style: {
                  // backgroundColor: selectedWaitingListItem && rowInfo?.original?.id === selectedWaitingListItem?.id ? "#cccccc" : "#d6ecf3",
                  ...(rowInfo?.original?.id && { cursor: "pointer" }),
                },
                onClick: () => {
                  console.log(" selected !!! selectedWaitingListItem", selectedWaitingListItem);
                  if (
                    selectedWaitingListItem?.id &&
                    selectedWaitingListItem?.id === rowInfo?.original?.id
                  ) {
                    setSelectedWaitingListItem(null);
                    props.onEvent({ message: "SelectPatient", params: null });
                  } else {
                    setSelectedWaitingListItem(rowInfo?.original);
                    props.onEvent({
                      message: "SelectPatient",
                      params: {
                        id: rowInfo?.original?.patient,
                        card: CARD_PATIENT_APPOINTMENT,
                      },
                    });
                  }
                },
              })}
              // Appointment List
              appointmentList={appointmentListForPatientAppointment(
                props.appointmentList?.filter((item: any) => item.status !== 6)
              )}
              appointmentRowProps={(state: any, rowInfo: any, column: any, instance: any) => ({
                className:
                  props.selectedAppointment?.id &&
                  rowInfo?.original?.id === props.selectedAppointment?.id
                    ? "blueSelectedRow"
                    : "",
                style: {
                  // backgroundColor: props.selectedAppointment && rowInfo?.original?.id === props.selectedAppointment?.id ? "#cccccc" : "#d6ecf3",
                  ...(rowInfo?.original?.id && { cursor: "pointer" }),
                },
                onClick: () => handleSelectAppointment(rowInfo),
              })}
              createPatientAppointment={() =>
                props.onEvent({
                  message: "CreatePatientAppointment",
                  params: {
                    waitingListItem: selectedWaitingListItem,
                    waitingList: selectedWaitingList,
                    card: CARD_PATIENT_APPOINTMENT,
                  },
                })
              }
              disabledCreatePatientAppointment={
                !selectedWaitingListItem || selectedWaitingListItem?.status !== "PENDING"
              }
            />
          )
        }
        // Right panel --------------------------------------------------------------------------
        // Appointment Detail
        appointmentDetail={
          tab === "detail" && (
            <CardAppointmentDetail
              setProp={props.setProp}
              onEvent={props.onEvent}
              // controller
              proxyController={props.proxyController}
              // seq
              runSequence={props.runSequence}
              // data
              estimatedAt={estimatedAt}
              estimatedDuration={estimatedDuration}
              card={CARD_PATIENT_APPOINTMENT}
              availableSlots={props.availableSlots}
              blockList={props.blockList}
              preOrderList={props.preOrderList}
              selectedBlock={props.selectedBlock}
              selectedWaitingListItem={selectedWaitingListItem}
              selectedWaitingList={selectedWaitingList}
              openModOrder={openModOrder}
              duplicateAppointment={props.duplicateAppointment}
              reoccureDuplicateAppointment={props.reoccureDuplicateAppointment}
              // treatment order
              OrderTreatmentSequence={props.OrderTreatmentSequence}
              procedureItems={props.procedureItems}
              secondaryItems={props.secondaryItems}
              treatmentTemplateList={props.treatmentTemplateList}
              treatmentOrderList={props.treatmentOrderList}
              // imaging order
              ImagingOrderSequence={props.ImagingOrderSequence}
              modXrayDetail={props.modXrayDetail}
              clinicalFindingId={props.clinicalFindingId}
              clinicalFindingIndex={props.clinicalFindingIndex}
              clinicalFindingList={props.clinicalFindingList}
              organ={props.organ}
              clinicaltags={props.clinicaltags}
              filterClinicalFindingIds={props.filterClinicalFindingIds}
              // lab order
              selectedEncounter={props.selectedEncounter}
              selectedEmr={props.selectedEmr}
              selectedProgressCycle={props.selectedProgressCycle}
              OrderCentralLabSequence={props.OrderCentralLabSequence}
              userLabPermission={props.userLabPermission}
              doctorLabOrderList={props.doctorLabOrderList}
              // CommonInterface
              userTokenize={props.userTokenize}
              selectedAppointment={props.selectedAppointment}
              selectedPatient={props.selectedPatient}
              loadingStatus={props.loadingStatus}
              buttonLoadCheck={props.buttonLoadCheck}
              errorMessage={props.errorMessage}
              successMessage={props.successMessage}
              searchedItemList={props.searchedItemList}
              searchedItemListWithKey={props.searchedItemListWithKey}
              // options
              masterOptions={props.masterOptions}
              cancelOptions={APPOINTMENT_CANCEL_OPTIONS}
              postponeOptions={APPOINTMENT_POSTPONE_OPTIONS}
              // config
              showOrderAppointment={props.showOrderAppointment}
              hideDrugAllergy={props.hideDrugAllergy}
              isAppointment={true}
              // callback
              setTab={setTab}
              setEstimatedAt={setEstimatedAt}
              setEstimatedDuration={setEstimatedDuration}
              setOpenModOrder={setOpenModOrder}
            />
          )
        }
        onlyNewPatient={onlyNewPatient}
        changeOnlyNewPatient={() => {
          props.onEvent({
            message: "FilterAppointmentSchedule",
            params: {
              onlyNewPatient: !onlyNewPatient,
              divisionId: props.selectedAppointmentDivision?.id,
            },
          });
          setOnlyNewPatient(!onlyNewPatient);
        }}
        // Calendar
        minTime={new Date(1972, 0, 1, 8, 0, 0)}
        maxTime={new Date(1972, 0, 1, 20, 0, 0)}
        hideBlockTable={false && props.selectedAppointment?.id === null}
        localizer={localizer}
        blockList={blockFilterAppointments}
        eventPropGetter={(event: any, start: Date, end: Date, isSelected: boolean) => {
          // console.log("event", event)
          let length = event?.providers?.filter(
            (item: any) =>
              item?.dsb?.doctor_provider_type === "Doctor" && item?.dsb?.doctor_dsb_status !== 2
          )?.length;

          return {
            style: event?.holiday
              ? {
                  backgroundColor: "#FFE696",
                  color: "black",
                  textAlign: "center",
                }
              : length === 0
              ? { backgroundColor: "pink", color: "black" }
              : moment() > moment(event.end)
              ? { backgroundColor: "#3174ad94", color: "white" }
              : ({} as CSSProperties),
          };
        }}
        onSelectEvent={(e: any) => {
          // console.log("onSelectEvent e: ", e);
          if (props.selectedAppointment && moment() < moment(e.end)) {
            setSelectedEvent(e);
            console.log(e);
            setShowMod(true);
          }
        }}
        onRangeChange={(range: any, view: any) => {
          console.log(range);
          let year = range.start?.getFullYear();
          let month = range.start?.getMonth() + 1;
          if (!Number.isInteger(year) || !Number.isInteger(month)) return;
          if (range.start?.getDate() !== 1) {
            if (month !== 12) {
              month = month + 1;
            } else {
              month = 1;
              year += 1;
            }
          }

          const uYear = Array.isArray(range)
            ? [range[0], range.slice(-1)[0]]
            : [range.start, range.end];
          const uniqueYear = Array.from(
            new Set([moment(uYear[0]).format("YYYY"), moment(uYear[1]).format("YYYY")])
          );
          if (uniqueYear.toString() !== calendarYear.toString()) {
            setCalendarYear(uniqueYear);
          }
          props.onEvent({
            message: "FilterSchedule",
            params: { range: { year, month } },
          });
        }}
        divisionOptions={[
          {
            key: -1,
            value: -1,
            text: "ไม่ระบุหน่วยงาน",
          },
        ].concat(
          props.divisionList.map((item: any) => ({
            key: item.id,
            value: item.id,
            text: item.name_code,
          }))
        )}
        // #55748
        // selectedDivisionId={selectedDivisionApp || -1}
        selectedDivisionId={props.selectedDivision?.id || -1}
        changeDivision={(e: any, v: any) => {
          // #55748
          // setSelectedDivisionApp(v.value)
          // props.onEvent({
          //   message: "SearchBlockList",
          //   params: { divisionId: v.value, action: v.value === props.selectedDivision?.id ? "clear" : "" },
          // });
          props.onEvent({
            message: "FilterSchedule",
            params: { divisionId: v.value },
          });
        }}
        hideFilterDivision={true}
        providerSelector={
          <>
            {/* <SearchBox
            type="Provider"
            style={{ width: "15vw" }}
            fluid={true}
            label={"ทันตแพทย์"}
            placeholder="ค้นหาชื่อทันตแพทย์"
            toDisplay={(item: any) => `${item.first_name} ${item.last_name}`}
            onEvent={props.onEvent}
            searchedItemList={props.searchedItemList}
            selectedItem={selectedProvider}
            setSelectedItem={setSelectedProvider}
          /> */}

            <SearchBoxDropdown
              type="Provider"
              id="6"
              style={{ width: "100%" }}
              fluid={true}
              label="แพทย์"
              clearable={true}
              disabled={selectedManagePatient !== null}
              toDisplay={(item: any) =>
                `${item.pre_name || ""} ${item.first_name} ${item.last_name} [${item.code}]`
              }
              onEvent={props.onEvent}
              // searchedItemList={props.searchedItemList}
              searchedItemListWithKey={props.searchedItemListWithKey}
              selectedItem={selectedProvider}
              setSelectedItem={(value: any, key: any) => {
                if (value === null && key === null) {
                  setSelectedProvider(null);
                  return;
                }
                let doctor = props.searchedItemListWithKey?.Provider_6?.find(
                  (item: any) => item.provider_id === key
                );
                console.log("setSelectedDoctor doctor: ", doctor, props.searchedItemListWithKey);
                setSelectedProvider(doctor);
              }}
              useWithKey={true}
              mapOptions={mapProviderOptions}
            />
          </>
        }
        orPrintAppointmentButton={
          <Button
            inverted
            color="blue"
            content="พิมพ์ใบนัด"
            icon="print"
            disabled={!props.selectedOrOrder?.id}
            onClick={() => {
              props.onEvent({
                message: "HandlePrintORAppointmentForm",
                params: {},
              });
            }}
          />
        }
      />

      {openModSelectEncounter?.open && (
        <ModSelectEncounter
          onEvent={props.onEvent}
          patientId={props.selectedPatient?.id}
          encounterList={props.encounterPatientList}
          appointmentType={openModSelectEncounter?.appointmentType}
          encounterFilterType={openModSelectEncounter?.encounterType}
          isNewEncounter={openModSelectEncounter?.isNewEncounter}
          // Callback
          onClose={() => setOpenModSelectEncounter(null)}
          onApprove={(encounterId: number) =>
            handleSelectEncounter(
              encounterId,
              openModSelectEncounter.appointmentType,
              openModSelectEncounter?.encounterType
            )
          }
        />
      )}

      <Modal open={showMod} closeOnDimmerClick={true} onClose={(e: any) => setShowMod(false)}>
        <ModEventDetail
          isShowChair={CONFIG.SHOW_DOCTOR_CHAIR}
          title={`วันที่เลือก: ${formatADtoBEString(selectedEvent?.date)}, ${selectedEvent?.title}`}
          providers={selectedEvent?.providers}
          chairList={props.chairList}
          divisionDict={props.divisionDict}
          selectedBlock={props.selectedBlock}
          selectedAppointment={props.selectedAppointment}
          onEvent={props.onEvent}
          setShowMod={setShowMod}
          setTab={setTab}
          setEstimatedAt={setEstimatedAt}
          setEstimatedDuration={setEstimatedDuration}
        />
      </Modal>

      <Modal
        size="fullscreen"
        open={showModWaitingList}
        closeOnDimmerClick={true}
        onClose={(e: any) => {
          setShowModWaitingList(false);
          setSelectedIdList([]);
          setSelectedDoctorAddWaiting("");
          setOpenModClinic(false);
        }}
      >
        <ModWaitingListManageUX
          waitingListLoading={props.waitingListLoading}
          divider={<Divider />}
          pagination={
            selectedWaitingList?.total > PAGE_LIMIT ? (
              <div
                style={{ marginTop: "15px", display: "flex", alignItems: "baseline", gap: "20px" }}
              >
                <Pagination
                  boundaryRange={0}
                  defaultActivePage={1}
                  ellipsisItem={null}
                  firstItem={null}
                  lastItem={null}
                  siblingRange={1}
                  totalPages={Math.trunc(selectedWaitingList?.total / PAGE_LIMIT) + 1}
                  activePage={filterActivePage}
                  onPageChange={handlePageChangeModWaitingList}
                  size="small"
                />
                <div style={{ alignSelf: "flex-end", marginBottom: "10px", fontSize: "smaller" }}>
                  {`แสดง ${manageWaitingListItems?.length} รายงาน จาก ${selectedWaitingList?.total} รายการ`}
                </div>
              </div>
            ) : (
              <div style={{ marginTop: "15px", marginLeft: "15px" }}>
                {`แสดงรายการทั้งหมด ${selectedWaitingList?.total} รายการ`}
              </div>
            )
          }
          disabledPauseQ={selectedWaitingList?.status === "CANCEL"}
          // disabledResumeQ={selectedWaitingList?.status !== "CANCEL"}
          // isCancel={isCancel}
          // toggleIsCancel={(e: any, { checked }: CheckboxProps) => {
          //   setIsCancel(!!checked);
          // }}
          disabledFilterDoctor={props.userPosition?.student_flag}
          doctorLists={doctorManageLists}
          onChangeSelectedFilterDoctor={(e: any, { value }: any) => {
            setSelectedFilterProviderCode(value);
          }}
          selectedFilterDoctor={selectedFilterProviderCode}
          disabledFilterDoctorDropdown={!isFilterDoctor}
          isFilterDoctor={isFilterDoctor}
          toggleIsFilterDoctor={(e: any, { checked }: CheckboxProps) => {
            setIsFilterDoctor(!!checked);
          }}
          // isAppointed={isAppointed}
          // toggleIsAppointed={(e: any, { checked }: CheckboxProps) => {
          //   setIsAppointed(!!checked);
          // }}
          waitingListName={selectedWaitingList?.name ? " " + selectedWaitingList?.name : ""}
          // isOnHoldQ={isOnHoldQ}
          // toggleIsOnHoldQ={(e: any, { checked }: CheckboxProps) => {
          //   setIsOnHoldQ(!!checked);
          // }}
          patientSelector={
            <PatientSearchBoxCU
              ref={hnSearchRef}
              modalStyle={{ width: "auto" }}
              controller={props.proxyController}
              inputChangeAutoSearch={false}
              onSelectPatient={handleOnSelectPatient}
              onEnter={handleOnSelectPatient}
              systemHn8Digit={CONFIG.COMPANY === "CU"}
              userProfile={props.django?.user}
            />
          }
          doctorSelector={
            <SearchBoxDropdown
              type="Provider"
              id="1"
              style={{ width: "100%" }}
              fluid={true}
              label=""
              clearable={true}
              disabled={selectedManagePatient !== null || props.userPosition?.student_flag === true}
              toDisplay={(item: any) =>
                `${item.pre_name || ""} ${item.first_name} ${item.last_name} [${item.code}]`
              }
              onEvent={props.onEvent}
              // searchedItemList={props.searchedItemList}
              searchedItemListWithKey={props.searchedItemListWithKey}
              selectedItem={selectedDoctorAddWaiting}
              setSelectedItem={(value: any, key: any) => {
                if (value === null && key === null) {
                  setSelectedDoctorAddWaiting(null);
                  return;
                }
                let doctor = props.searchedItemListWithKey?.Provider_1?.find(
                  (item: any) => item.provider_id === key
                );
                console.log("setSelectedDoctor doctor: ", doctor);
                setSelectedDoctorAddWaiting(doctor);
              }}
              useWithKey={true}
              mapOptions={mapProviderOptions}
            />
          }
          disableAddPatient={
            !Number.isInteger(selectedWaitingList?.id) ||
            !Number.isInteger(selectedPatientId) ||
            selectedManagePatient !== null
          }
          disabledPrint={!(manageWaitingListItems?.length > 0)}
          // Table 3
          waitingListItems={manageWaitingListItems}
          addPatientToWaitingList={() => {
            props.onEvent({
              message: "SetScheduling",
              params: {
                action: "AddPatientToWaitingList",
                waitingList: selectedWaitingList,
                patientId: selectedPatientId,
                provider: selectedDoctorAddWaiting,
                searchParams: {
                  offset: (filterActivePage - 1) * PAGE_LIMIT,
                  pageLimit: PAGE_LIMIT,
                  includeCancel: true,
                  waiting_list_id: selectedWaitingList?.id,
                  startDate: filterStartDateWL
                    ? beStringToDateObject(filterStartDateWL)?.toISOString()
                    : null,
                  endDate: filterEndDateWL
                    ? beStringToDateObject(filterEndDateWL)?.toISOString()
                    : null,
                  statusList: (filterStatusWL || [])?.map(
                    (statusName: any) =>
                      props.masterOptions?.waitingListStatus?.find(
                        (m: any) => m.value === statusName
                      )?.key
                  ),
                },
              },
            });
            hnSearchRef?.current?.clear();
            setSelectedDoctorAddWaiting("");
          }}
          printWaitingList={(e: any) => {
            console.log("props.django.user.full_name,: ", props.django.user.full_name);
            props.onEvent({
              message: "PrintScheduling",
              params: {
                form: "FormWaitingList",
                data: {
                  // status_name: isOnHoldQ ? "STALL" : isAppointed ? "PENDING" : "ALL",
                  waiting_list_name: selectedWaitingList.name,
                  division_name: props.selectedDivision.name,
                  provider_name: `${selectedDoctorAddWaiting?.first_name} ${selectedDoctorAddWaiting?.last_name}`,
                  orders: manageWaitingListItems,
                  startDate: filterStartDateWL,
                  endDate: filterEndDateWL,
                  userPrint: props.django.user.full_name,
                  datePrint: formatDatetime(new Date(), true),
                  status_code: filterStatusWL,
                },
              },
            });
          }}
          providerSelector={
            <SearchBoxDropdown
              type="Provider"
              id="2"
              style={{ width: "70%" }}
              fluid={true}
              label="ทันตแพทย์ / นิสิต"
              toDisplay={(item: any) =>
                `${item.pre_name || ""} ${item.first_name} ${item.last_name} [${item.code}]`
              }
              onEvent={props.onEvent}
              // searchedItemList={props.searchedItemList}
              searchedItemListWithKey={props.searchedItemListWithKey}
              selectedItem={selectedDoctorEditWaiting}
              setSelectedItem={(value: any, key: any) => {
                if (value === null && key === null) {
                  setSelectedDoctorEditWaiting(null);
                  return;
                }
                let doctor = props.searchedItemListWithKey?.Provider_2?.find(
                  (item: any) => item.provider_id === key
                );
                setSelectedDoctorEditWaiting(doctor);
              }}
              disabled={props.userPosition?.student_flag === true}
              {...(props.userPosition?.student_flag === true && {
                defaultText: `${props.providerInfo?.doctor_info?.full_name} [${props.providerInfo?.doctor_info?.code}]`,
              })}
              useWithKey={true}
              mapOptions={mapProviderOptions}
            />
          }
          // Dropdown ประเภทการรักษา
          treatmentTypeOptions={props.patientAppType}
          onChangeTreatmentType={(e: any, data: any) => {
            setSelectedTreatmentType(data.value);
          }}
          treatmentType={selectedTreatmentType}
          note={note}
          changeNote={(e: any) => setNote(e.target.value)}
          disableAssign={selectedIdList?.length === 0}
          // Button Group
          onClickPauseQ={() => {
            setOpenModConfirmNote(true);
            setModConfirmTitle(NOTE_MSG.pause.title);
            setModColor(NOTE_MSG.pause.color);
            setModConfirmSubtitle(NOTE_MSG.pause.subtitle);
          }}
          onClickResumeQ={() => {
            setOpenModConfirmNote(true);
            setModConfirmTitle(NOTE_MSG.resume.title);
            setModColor(NOTE_MSG.resume.color);
            setModConfirmSubtitle(NOTE_MSG.resume.subtitle);
          }}
          onCancelTreatmentPlan={() => {
            setOpenModConfirmNote(true);
            setModConfirmTitle(NOTE_MSG.cancel.title);
            setModColor(NOTE_MSG.cancel.color);
            setModConfirmSubtitle(NOTE_MSG.cancel.subtitle);
          }}
          assignWaitingListItems={editWaitingListItem}
          onSendToClinic={() => {
            setOpenModClinic(!openModClinic);
            props.onEvent({
              message: "SetScheduling",
              params: {
                action: "GetAllWaitingList",
              },
            });
          }}
          openModClinic={openModClinic}
          OpenClinic={
            <>
              <ModOpenSendToClinicUX
                dataWaitingList={dataWaitingList}
                divisionSelector={
                  <SearchBoxDropdown
                    type="Division"
                    id="4"
                    style={{ width: "100%" }}
                    fluid={true}
                    label=""
                    // disabled={selectedManagePatient !== null}
                    toDisplay={(item: any) => `${item.name}`}
                    onEvent={props.onEvent}
                    // searchedItemList={props.searchedItemList}
                    searchedItemListWithKey={props.searchedItemListWithKey}
                    selectedItem={selectDivision}
                    setSelectedItem={(value: any, key: any) => {
                      if (value === null && key === null) {
                        setSelectDivision(null);
                        return;
                      }
                      let division = props.searchedItemListWithKey?.Division_4?.find(
                        (item: any) => item.code === key
                      );
                      setSelectDivision(division);
                      props.onEvent({
                        message: "SetScheduling",
                        params: {
                          action: "SelectedWaitingList",
                          division: division,
                        },
                      });
                    }}
                    useWithKey={true}
                    mapOptions={mapDivisionOptions}
                  />
                }
                OnCancel={() => setOpenModClinic(false)}
                OnConfirm={() => {
                  const selectedWaitingItemList = (manageWaitingListItems || [])?.filter(
                    (item: any) => selectedIdList.includes(item.id)
                  );
                  props.onEvent({
                    message: "SetScheduling",
                    params: {
                      action: "SendWaitingListToClinic",
                      waitinglist: selectedRow,
                      patient: selectedWaitingItemList,
                    },
                  });
                  setOpenModClinic(false);
                }}
                getTrPropsSendWaitingList={(
                  state: any,
                  rowInfo: any,
                  column: any,
                  instance: any
                ) => {
                  return {
                    style: {
                      backgroundColor:
                        rowInfo?.original?.id && rowInfo?.original?.id === selectedRow?.id
                          ? "#cccccc"
                          : "white",
                      pointerEvents:
                        rowInfo?.original?.id && rowInfo?.original?.id === selectedRow?.id
                          ? "none"
                          : "auto",
                    },
                    onClick: () => {
                      setSelectedRow(rowInfo?.original);
                    },
                  };
                }}
              />
            </>
          }
          // Issue 64138
          isHiddenGroup={props.userPosition?.student_flag === true}
          // Feature 66917
          statusOptions={props.masterOptions.waitingListStatus}
          onChangeEndDate={(text: string) => {
            setFilterEndDateWL(text);
          }}
          onChangeStartDate={(text: string) => {
            setFilterStartDateWL(text);
          }}
          onChangeFilterStatus={(
            e: React.SyntheticEvent<HTMLElement>,
            { value }: DropdownProps
          ) => {
            console.log("onChangeFilterStatus value: ", value);
            setFilterStatusWL(value);
          }}
          startDate={filterStartDateWL}
          endDate={filterEndDateWL}
          filterStatus={filterStatusWL}
          onClickSearch={() => {
            handleGetWaitingListItem();
          }}
        />
      </Modal>
      <Modal
        size="fullscreen"
        open={showModStatic}
        closeOnDimmerClick={true}
        onClose={(e: any) => {
          setShowModStatic(false);
        }}
      >
        <>
          <div style={{ height: "100%", width: "100%", padding: "10px" }}>
            <div style={{ display: "flex" }}>
              <MenuItem menu="queue" tab={modTab} name="ระยะเวลารอคิว" setTab={setModTab} />
              <MenuItem menu="static" tab={modTab} name="สถิติการเลิกคิว" setTab={setModTab} />
            </div>

            {modTab === "queue" ? (
              <ModSummaryWaitingListUX
                headers={[
                  "HN",
                  "ชื่อสกุล",
                  <div>
                    {" "}
                    วันที่เข้า WaitingList{" "}
                    {isAscendingCreated ? (
                      <Icon name="sort amount down" onClick={sortDateWaitngList} />
                    ) : (
                      <Icon name="sort amount up" onClick={sortDateWaitngList} />
                    )}
                  </div>,
                  <div>
                    {" "}
                    วันที่นัดหมาย
                    {isAscendingAppointed ? (
                      <Icon name="sort amount down" onClick={sortDateAppointment} />
                    ) : (
                      <Icon name="sort amount up" onClick={sortDateAppointment} />
                    )}
                  </div>,
                  <div>
                    {" "}
                    ระยะเวลารอคิว (วัน)
                    {isAscendingWaitingTime ? (
                      <Icon name="sort amount down" onClick={sortWaitingTime} />
                    ) : (
                      <Icon name="sort amount up" onClick={sortWaitingTime} />
                    )}
                  </div>,
                ]}
                waitingListName={selectedWaitingList?.name || ""}
                startDateModWL={formatADtoBEString(moment(startDateModWL).format(DATE_FORMAT))}
                changeDate={changeDate}
                endDateModWL={formatADtoBEString(moment(endDateModWL).format(DATE_FORMAT))}
                dataSummaryWaitingList={waitingQueueList}
                OnSearch={() => {
                  props.onEvent({
                    message: "SetScheduling",
                    params: {
                      action: "SummaryStatic",
                      card: "ModSummaryWaitingList",
                      startDateModWL: startDateModWL,
                      endDateModWL: endDateModWL,
                      waitingList: selectedWaitingList,
                    },
                  });
                }}
                buttonExportCSV={
                  <CSVLink
                    data={waitingQueueList}
                    headers={headersModWL}
                    filename={"รายงานระยะเวลารอคิว.csv"}
                    target="_blank"
                  >
                    <Button color="orange">
                      <div style={{ display: "flex" }}>
                        <Icon name="download" />
                        <div style={{ marginRight: "3px" }}> Export .CSV </div>
                      </div>
                    </Button>
                  </CSVLink>
                }
              />
            ) : modTab === "static" ? (
              <ModSummaryStaticUX
                waitingListName={selectedWaitingList?.name || ""}
                startDateModST={formatADtoBEString(moment(startDateModST).format(DATE_FORMAT))}
                changeDate={changeDate}
                endDateModST={formatADtoBEString(moment(endDateModST).format(DATE_FORMAT))}
                dataSummaryStatic={dataSummaryStatic}
                OnSearch={() => {
                  props.onEvent({
                    message: "SetScheduling",
                    params: {
                      action: "SummaryStatic",
                      card: "ModSummaryStatic",
                      startDateModST: startDateModST,
                      endDateModST: endDateModST,
                      waitingList: selectedWaitingList,
                    },
                  });
                }}
                buttonExportCSV={
                  <CSVLink
                    data={dataSummaryStatic}
                    headers={headersModST}
                    filename={"รายงานสถิติการเลิกคิว.csv"}
                    target="_blank"
                  >
                    <Button color="orange">
                      <div style={{ display: "flex" }}>
                        <Icon name="download" />
                        <div style={{ marginRight: "3px" }}> Export .CSV </div>
                      </div>
                    </Button>
                  </CSVLink>
                }
              />
            ) : (
              ""
            )}
          </div>
        </>
      </Modal>
      <ModConfirmWithNote
        modalOpen={openModConfirmNote}
        titleName={modConfirmTitle}
        modalSize="tiny"
        titleColor={modColor}
        showTextNote={true}
        textContent={modConfirmSubtitle}
        approveButtonText="ใช่"
        approveButtonColor="green"
        denyButtonText="ไม่"
        denyButtonColor="red"
        buttonBasic={true}
        textNote={manageQNote}
        disabledApproveButton={!(manageQNote?.length > 3) || !props.userTokenize?.token}
        setTextNote={(e: any, { value }: any) => {
          setManageQNote(value);
        }}
        onDeny={() => {
          setOpenModConfirmNote(false);
          setModConfirmTitle("");
          setModColor("");
          setModConfirmSubtitle("");
          setManageQNote("");

          props.onEvent({
            message: "UserTokenize",
            params: {
              action: "clear",
            },
          });
        }}
        onApprove={() => {
          const selectedWaitingItemList = (manageWaitingListItems || [])?.filter((item: any) =>
            selectedIdList.includes(item.id)
          );
          switch (modConfirmTitle) {
            case NOTE_MSG.pause.title:
              props.onEvent({
                message: "SetScheduling",
                params: {
                  action: "WaitItems",
                  note: manageQNote,
                  token: props.userTokenize?.token,
                  item: selectedWaitingItemList,
                  waitinglist: selectedWaitingList,
                },
              });
              break;
            case NOTE_MSG.resume.title:
              props.onEvent({
                message: "SetScheduling",
                params: {
                  action: "ResumeWaiting",
                  note: manageQNote,
                  token: props.userTokenize?.token,
                  item: selectedWaitingItemList,
                  waitinglist: selectedWaitingList,
                },
              });
              break;
            case NOTE_MSG.cancel.title:
              props.onEvent({
                message: "SetScheduling",
                params: {
                  action: "RemoveItems",
                  note: manageQNote,
                  token: props.userTokenize?.token,
                  item: selectedWaitingItemList,
                  waitinglist: selectedWaitingList,
                },
              });
              break;

            default:
              break;
          }
        }}
        authorizeComponent={
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-evenly",
              margin: "10px",
            }}
          >
            {"ระบุชื่อผู้บันทึกข้อมูล "}
            <EmployeeToken
              placeholder={"รหัสผู้บันทึก"}
              onEnterToken={(code) => {
                props.onEvent({
                  message: "UserTokenize",
                  params: {
                    action: "CheckUserToken",
                    code: code,
                  },
                });

                // props.onEvent({
                //   message: "HandleGetDeliverDrugTokenization",
                //   params: { code },
                // });
              }}
              onClearToken={() =>
                props.setProp("userTokenize", {
                  ...props.userTokenize,
                  token: "",
                  employeeName: "",
                  loading: false,
                  error: null,
                })
              }
              error={!!props.userTokenize?.error}
              loading={props.userTokenize?.loading}
              employeeName={props.userTokenize?.employeeName}
            />
          </div>
        }
      />

      <SnackMessage
        onEvent={props.onEvent}
        onClose={() => {
          props.setProp(`errorMessage.${CARD_PATIENT_APPOINTMENT}`, null);
        }}
        error={props.errorMessage?.[CARD_PATIENT_APPOINTMENT]}
        success={null}
      />
    </div>
  );
};

export default CardPatientAppointment;
