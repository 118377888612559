import React, { useEffect, useState, forwardRef, useRef } from "react";
import PropTypes from "prop-types";
import * as CONSTANT from "../../utils/constant";

import { formatDate, parseDate } from "react-lib/utils/dateUtils";
import CardLayout from "react-lib/apps/common/CardLayout";
import ModInfo from "react-lib/apps/common/ModInfo";

import DateTextBox from "react-lib/apps/common/DateTextBox";
import ModAuthen from "react-lib/apps/common/ModAuthen";

import { Label, Form, Button, Modal, Grid } from "semantic-ui-react";
import moment from "moment";
import PureReactTable from "react-lib/apps/common/PureReactTable";
import CardSelectDoctorOrder from "react-lib/apps/PTM/CardSelectDoctorOrder";
import SubNurseNoteItem from "react-lib/apps/PTM/SubNurseNoteItem";
// import ReactTable from "react-table-6";

const IS_USE_NURSE_NOTE_VERSION_2 = true;

const CardNurseNote = forwardRef((props: any, ref) => {
  const [isLoading, setIsLoading] = useState(false);
  const [readOnly, setReadOnly] = useState(false);
  const [status, setStatus] = useState(null);
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [nurseNoteItemData, setNurseNoteItemData] = useState([]);
  const [selectedRow, setSelectedRow] = useState(null);
  const [nurseNoteData, setNurseNoteData] = useState({});

  const [hideBtnCancel, setHideBtnCancel] = useState(true);
  const [hideBtnConfirm, setHideBtnConfirm] = useState(true);

  const [openNurseNoteItem, setOpenNurseNoteItem] = useState(false);

  const [openModSuccess, setOpenModSuccess] = useState(false);
  const [openModDeleteAuthen, setOpenModDeleteAuthen] = useState(false);
  const [openModCancelAuthen, setOpenModCancelAuthen] = useState(false);
  const [openModSelectDoctorOrder, setOpenModSelectDoctorOrder] =
    useState(false);
  const [authenError, setAuthenError] = useState(null);

  const [encounter, setEncounter] = useState(null);

  const [nurseNoteAction, setNurseNoteAction] = useState(null);

  const authenDeleteRef = useRef();
  const authenCancelRef = useRef();
  const nurseNoteItemRef = useRef();

  useEffect(() => {
    if (!openNurseNoteItem && nurseNoteItemRef && nurseNoteItemRef.current) {
      nurseNoteItemRef.current.clear();
    }
  }, [openNurseNoteItem]);

  useEffect(() => {
    setAuthenError(null);
    if (authenCancelRef && authenCancelRef.current) {
      authenCancelRef.current.clear();
    }
  }, [openModCancelAuthen]);

  useEffect(() => {
    setAuthenError(null);
    if (authenDeleteRef && authenDeleteRef.current) {
      authenDeleteRef.current.clear();
    }
  }, [openModDeleteAuthen]);

  useEffect(() => {
    refresh({ encounterId: props.encounterId });
    setEncounter(props.encounterId);
  }, [props.encounterId]);

  useEffect(() => {
    console.log("useEffect !");
    if (props.nurseNoteData?.status) {
      console.log('props.nurseNoteData?.status: ', props.nurseNoteData?.status);
      setStatus(props.nurseNoteData?.status);
    }

    if (props.nurseNoteData?.created) { 
      let da = props.nurseNoteData?.created?.split(" ")?.[0]
      let dasplit = da?.split("/")
      if (dasplit?.length === 3 ) { 
        setFromDate(da)
        setToDate(da)
        return
      }
    } else { 
      setFromDate(formatDate(moment().subtract(7, "days")))
      setToDate(formatDate(moment()))
    }
    
  }, [props.nurseNoteData]);

  useEffect(() => {
    setReadOnly(props.readOnly);
  }, [props.readOnly]);

  useEffect(() => {
    // console.log("CardNurseNote useEffect nurseNoteData: ", nurseNoteData);
    checkStatus({ dataStatus: nurseNoteData.status });
  }, [nurseNoteData.status]);

  const refresh = ({ encounterId } = {}) => {
    console.log('CardNurseNote refresh ');
    let encounter = encounterId
      ? encounterId
      : props.patientData.ENCOUNTER.encounter_id;

    getEncounterDetail({ encounterId: encounter });

    // console.log(
    //   "CardNurseNote props.nurseNoteData?.id: ",
    //   props.nurseNoteData?.id
    // );
    // console.log("CardNurseNote props.nurseNoteData: ", props.nurseNoteData);
    if (props.nurseNoteData?.id) {
      // console.log('CardNurseNote props.nurseNoteData?.status: ', props.nurseNoteData?.status);
      if (props.nurseNoteData?.status) {
        setStatus(props.nurseNoteData?.status);
        checkStatus({ dataStatus: props.nurseNoteData?.status });
      }
      getNurseNoteItem({ nurseNote: props.nurseNoteData.id });
    } else {
      findNurseNoteIdByEN({ encounterId: encounter });
    }
  };

  const getNurseNoteItem = async ({ nurseNote } = {}) => {
    // console.log('getNurseNoteItem nurseNote: ', nurseNote);
    // console.log("getNurseNoteItem nurseNoteData ", nurseNoteData);
    // console.log("getNurseNoteItem props.nurseNoteData ", props.nurseNoteData);
    // console.log("getNurseNoteItem (!(nurseNote || nurseNoteData.id) && props.nurseNoteData?.id )", (!(nurseNote || nurseNoteData.id) && props.nurseNoteData?.id ))
    setIsLoading(true);
    const [data, error] = await props.controller.getNurseNoteItem({
      nurseNoteId: nurseNote || nurseNoteData.id || props.nurseNoteData?.id,
      ...(!(nurseNote || nurseNoteData.id) && { fromDate }),
      ...(!(nurseNote || nurseNoteData.id) && { toDate }),
    });
    setNurseNoteItemData(data.items);
    // console.log("CardNurseNote setNurseNoteItemData: ", data.items);
    setIsLoading(false);
  };

  const getEncounterDetail = async ({ encounterId } = {}) => {
    setIsLoading(true);
    const [data, error] = await props.controller.getEncounterDetail({
      encounterId,
    });
    if (data.is_nurse_key_discharge_form_expired) {
      setReadOnly(true);
    }
    setIsLoading(false);
  };

  const findNurseNoteIdByEN = async ({ encounterId } = {}) => {
    setIsLoading(true);
    const [data, error] = await props.controller.getNurseNoteLatest({
      encounterId,
    });
    console.log("CardNurseNote findNurseNoteIdByEN data", data);
    if (data) {
      setNurseNoteData(data);
      if (data.status) {
        setStatus(data.status);
      }
      if (data.id) {
        return getNurseNoteItem({ nurseNote: data.id });
      }
    }
    setIsLoading(false);
  };

  const checkStatus = ({ dataStatus } = {}) => {
    console.log("CardNurseNote checkStatus dataStatus: ", dataStatus);

    if (!dataStatus) {
      return;
    }

    let dataStatusText = dataStatus;
    if (typeof dataStatus === "number") {
      dataStatusText = STATUS[dataStatus];
    }
    // console.log('dataStatusText: ', dataStatusText);

    if (dataStatusText === STATUS_TYPE.DRAFTED) {
      setHideBtnConfirm(false);
      setHideBtnCancel(true);
    } else if (dataStatusText === STATUS_TYPE.CLOSED) {
      setReadOnly(true);
    } else if (dataStatusText === STATUS_TYPE.CONFIRMED) {
      // console.log(
      //   "CardNurseNote checkStatus dataStatus setHideBtnConfirm(true); "
      // );
      setHideBtnConfirm(true);
      setHideBtnCancel(false);
    }
    if (
      props.isIPD &&
      !CHECKOUT_STATUS.includes(parseInt(props.roomItemStatus))
    ) {
      // console.log(
      //   "CardNurseNote checkStatus dataStatus setHideBtnConfirm(true); "
      // );
      setHideBtnConfirm(true);
      setHideBtnCancel(true);
    }
  };

  const handleEditClick = (row) => {
    setOpenNurseNoteItem(true);
  };

  const handleSaveNurseNoteItem = () => {
    setOpenModSuccess(true);
    setOpenNurseNoteItem(false);

    refresh({ encounterId: encounter });

    // findNurseNoteIdByEN({ encounterId: encounter });
  };

  const handleRemoveNurseNoteItem = async ({ username, password } = {}) => {
    setIsLoading(true);
    const [data, error] = await props.controller.deleteNurseNoteItem({
      username,
      password,
      csrf: props.django.csrf,
      nurseNoteItemId: selectedRow.id,
    });
    if (error) {
      setAuthenError(error);
    } else {
      setOpenModDeleteAuthen(false);
      getNurseNoteItem();
    }
    setIsLoading(false);
  };

  const handleCancelNurseNote = async ({ username, password } = {}) => {
    console.log('CardNurseNote nurseNoteData: ', nurseNoteData);
    setIsLoading(true);
    const [data, error] = await props.controller.putNurseNote({
      username,
      password,
      csrf: props.django.csrf,
      nurseNoteId: nurseNoteData.id || nurseNoteItemData?.find(Boolean)?.nurse_note ,
      status,
      action: nurseNoteAction,
      isCheifNurse: nurseNoteData.is_chief_nurse,
      encounter: encounter
        ? encounter
        : props.patientData.ENCOUNTER.encounter_id,
    });
    // console.log("data", data)
    if (error) {
      setAuthenError(error);
    } else if (data) {

      // console.log("data.status", data.status)
      setOpenModCancelAuthen(false);
      setOpenModSuccess(true);
      setNurseNoteData(data);
      if (data.status) {
        setStatus(data.status);
      }
      props.needPatientRefresh();
    }
    setIsLoading(false);
  };

  const handleSelectDoctorOrder = ({ orderDetail = "" } = {}) => {
    setOpenModSelectDoctorOrder(false);
    setOpenNurseNoteItem(true);
    setSelectedRow({ id: null, plan: orderDetail });
  };


  let statusColor = "black";
  if (STATUS[status] === STATUS_TYPE.DRAFTED || status === "DRAFTED") {
    statusColor = "yellow";
  } else if (STATUS[status] === STATUS_TYPE.CONFIRMED || status === "CONFIRMED") {
    statusColor = "green";
  } else if (STATUS[status] === STATUS_TYPE.CLOSED || status === "CLOSED") {
    statusColor = "red";
  }

  let labelStatus = <Label color={statusColor}>{STATUS[status] || status}</Label>;

  // console.log("CardNurseNote hideBtnConfirm ", hideBtnConfirm);
  // console.log("CardNurseNote readOnly ", readOnly);
  console.log("CardNurseNote nurseNoteItemData ", nurseNoteItemData);
  return (
    <CardLayout
      closeable={props.closeable}
      loading={isLoading}
      titleText="Nurse's Note"
      onClose={props.onClose}
      titleContent={labelStatus}
    >
      <ModAuthen
        open={openModCancelAuthen}
        onDeny={() => setOpenModCancelAuthen(false)}
        onApprove={handleCancelNurseNote}
        loading={isLoading}
        ref={authenCancelRef}
        error={authenError}
      />
      <ModAuthen
        open={openModDeleteAuthen}
        onDeny={() => setOpenModDeleteAuthen(false)}
        error={authenError}
        onApprove={handleRemoveNurseNoteItem}
        loading={isLoading}
        ref={authenDeleteRef}
      />
      <ModInfo
        open={openModSuccess}
        titleName="บันทึกสำเร็จ"
        titleColor="green"
        onApprove={() => setOpenModSuccess(false)}
      />
      <Modal open={openModSelectDoctorOrder} size="large">
        <CardSelectDoctorOrder
          controller={props.controller}
          patientData={props.patientData}
          toggleable={false}
          encounter={encounter}
          onClose={() => setOpenModSelectDoctorOrder(false)}
          onSelect={handleSelectDoctorOrder}
        />
      </Modal>
      <Modal
        open={openNurseNoteItem}
        closeOnDimmerClick
        onClose={() => {
          setOpenNurseNoteItem(false);
        }}
      >
        <SubNurseNoteItem
          ref={nurseNoteItemRef}
          isIPD={props.isIPD}
          onClose={() => {
            setOpenNurseNoteItem(false);
          }}
          nurseNoteId={nurseNoteData ? nurseNoteData.id : null}
          controller={props.controller}
          patientData={props.patientData}
          onSaved={handleSaveNurseNoteItem}
          django={props.django}
          workingShift={selectedRow ? selectedRow.working_shift : ""}
          plan={selectedRow ? selectedRow.plan : ""}
          goal={selectedRow ? selectedRow.goal : ""}
          diagnosis={selectedRow ? selectedRow.diagnosis : ""}
          nurseNoteItemId={selectedRow ? selectedRow.id : null}
          date={selectedRow ? selectedRow.formatted_date : null}
          division={selectedRow?.division}
        />
      </Modal>
      <Form>
        <Form.Group inline className="rightAlign">
          <Form.Field width={4}>
            <Button
              color="yellow"
              fluid
              content="เลือกรายการคำสั่งแพทย์"
              disabled={readOnly}
              onClick={() => setOpenModSelectDoctorOrder(true)}
            />
          </Form.Field>
          <Form.Field width={3}>
            <DateTextBox
              testid="dtbStartDate"
              value={fromDate}
              onChange={(date) => setFromDate(date)}
            />
          </Form.Field>
          <Form.Field width={3}>
            <DateTextBox
              testid="dtbEndDate"
              value={toDate}
              onChange={(date) => setToDate(date)}
            />
          </Form.Field>
          <Form.Field width={2}>
            <Button
              color="blue"
              fluid
              content="ค้นหา"
              onClick={getNurseNoteItem}
            />
          </Form.Field>
        </Form.Group>
      </Form>
      <br />
      {IS_USE_NURSE_NOTE_VERSION_2 ? (
        <PureReactTable
          testid="multiTable"
          showPagination={false}
          className=""
          minRows={0}
          data={nurseNoteItemData}
          style={styles.nurseNoteTable}
          selectedClassName="blueSelectedRow"
          onSelect={(originalRow: any) => setSelectedRow(originalRow)}
          selectedRow={selectedRow}
          columns={[
            {
              Header: "วันที่และเวลาที่บันทึก",
              accessor: "formatted_date",
              minWidth: 100,
            },
            {
              Header: "ผู้บันทึก",
              accessor: "nurse",
              minWidth: 100,
            },
            {
              Header: "Focus",
              accessor: "focus",
            },
            {
              Header: "Progress Note A:Assessment I:Intervention E:Evaluation",
              accessor: "progress_note",
              Cell: ({ original }: any) => {
                return (
                  <div style={{ wordWrap: "break-word", padding: "2px" }}>
                    <Grid divided="vertically">
                      <Grid.Row columns={2}>
                        <Grid.Column
                          width={2}
                          style={{ fontWeight: "bold", textAlign: "center" }}
                        >
                          A:
                        </Grid.Column>
                        <Grid.Column width={14}>
                          {original.diagnosis}
                        </Grid.Column>
                      </Grid.Row>
                      <Grid.Row columns={2}>
                        <Grid.Column
                          width={2}
                          style={{ fontWeight: "bold", textAlign: "center" }}
                        >
                          I:
                        </Grid.Column>
                        <Grid.Column width={14}>{original.plan}</Grid.Column>
                      </Grid.Row>
                      <Grid.Row columns={2}>
                        <Grid.Column
                          width={2}
                          style={{ fontWeight: "bold", textAlign: "center" }}
                        >
                          E:
                        </Grid.Column>
                        <Grid.Column width={14}>{original.goal}</Grid.Column>
                      </Grid.Row>
                    </Grid>
                  </div>
                );
              },
            },
            {
              Header: "",
              minWidth: 90,
              show: !readOnly,
              Cell: (row: any) => {

                // console.log(" row :", row)
                // console.log(" row status :", status)
                if (
                  row.original?.edit_user_id ===
                  props?.django?.user?.id?.toString() && !(status === "CONFIRMED" || status === 2)
                ) {
                  return (
                    <>
                      <Button
                        color="yellow"
                        icon="pencil"
                        size="mini"
                        onClick={() => {
                          handleEditClick(row);
                        }}
                      />
                      <Button
                        color="red"
                        icon="trash"
                        size="mini"
                        onClick={() => {
                          setOpenModDeleteAuthen(true);
                        }}
                      />
                    </>
                  );
                } else {
                  return <></>;
                }
              },
            },
          ]}
        />
      ) : (
        <PureReactTable
          testid="multiTable"
          showPagination={false}
          className=""
          minRows={0}
          data={nurseNoteItemData}
          style={styles.nurseNoteTable}
          selectedClassName="blueSelectedRow"
          onSelect={(originalRow) => setSelectedRow(originalRow)}
          selectedRow={selectedRow}
          columns={[
            {
              Header: "เวร",
              accessor: "working_shift",
              minWidth: 90,
            },
            {
              Header: "วันที่และเวลาที่บันทึก",
              accessor: "formatted_date",
              minWidth: 140,
            },
            {
              Header: "ข้อมูล/อาการ/ปัญหา",
              accessor: "plan",
              minWidth: 160,
            },
            {
              Header: "การพยาบาล Note",
              accessor: "diagnosis",
              minWidth: 160,
            },
            {
              Header: "ผลการพยาบาล",
              accessor: "goal",
              minWidth: 160,
            },
            {
              Header: "Nurse",
              accessor: "nurse",
              minWidth: 135,
            },
            {
              Header: "Division",
              accessor: "division_name",
              minWidth: 130,
            },
            {
              Header: "",
              accessor: "action",
              minWidth: 90,
              show: !readOnly,
              Cell: (row) => {
                return (
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <Button
                      color="yellow"
                      icon="pencil"
                      size="mini"
                      onClick={() => {
                        handleEditClick(row);
                      }}
                    />
                    <Button
                      color="red"
                      icon="trash"
                      size="mini"
                      onClick={() => {
                        setOpenModDeleteAuthen(true);
                      }}
                    />
                  </div>
                );
              },
            },
          ]}
        />
      )}
      <br />
      <Form>
        <Form.Group inline>
          <Form.Field width={1}>
            <Button
              disabled={readOnly}
              testid="btnAdd"
              circular
              color="yellow"
              icon="plus"
              onClick={handleSelectDoctorOrder}
            />
          </Form.Field>
          <Form.Field width={9} />
          <Form.Field width={5} className="rowReverse">
            <Button
              style={{ display: hideBtnConfirm || readOnly ? "none" : null }}
              testid="btnConfirm"
              color="olive"
              content="ยืนยันเอกสาร"
              onClick={() => {
                // console.log('CardNurseNote onClick ยืนยันเอกสาร nurseNoteData: ', nurseNoteData);
                setNurseNoteAction("CONFIRM");
                setOpenModCancelAuthen(true);
              }}
            />
            <Button
              style={{ display: hideBtnCancel || readOnly ? "none" : null }}
              testid="btnCancel"
              color="red"
              content="ยกเลิกยืนยันเอกสาร"
              onClick={() => {
                setNurseNoteAction("CANCEL_CONFIRM");
                setOpenModCancelAuthen(true);
              }}
            />
          </Form.Field>
          <Form.Field width={2}>
            <Button
              // no implementation in QML
              fluid
              testid="btnPrint"
              color="blue"
              content="พิมพ์"
              disabled={readOnly}
              onClick={() => {
                props.onPrintNurseNote(nurseNoteItemData)
              }}
            />
          </Form.Field>
        </Form.Group>
      </Form>
    </CardLayout>
  );
});

const CHECKOUT_STATUS = [
  CONSTANT.ROOM_ITEM_STATUS.CHECKED_OUT,
  CONSTANT.ROOM_ITEM_STATUS.CASHIER_REQUESTED,
  CONSTANT.ROOM_ITEM_STATUS.CASHIER_DISCHARGED,
  CONSTANT.ROOM_ITEM_STATUS.FINISHED,
];

const styles = {
  nurseNoteTable: {
    height: "300px",
  },
};

const STATUS_TYPE = {
  DRAFTED: "DRAFTED",
  CONFIRMED: "CONFIRMED",
  CLOSED: "CLOSED",
};

const STATUS = {
  "1": "DRAFTED",
  "2": "CONFIRMED",
  "3": "CLOSED",
};

CardNurseNote.defaultProps = {
  closeable: true,
  onClose: () => {},
  controller: {},
  patientData: {},
  readOnly: false,
  isIPD: false,
  roomItemStatus: null,
};

CardNurseNote.propTypes = {
  closeable: PropTypes.bool,
  onClose: PropTypes.func,
  controller: PropTypes.object,
  patientData: PropTypes.object,
  readOnly: PropTypes.bool,
  isIPD: PropTypes.bool,
  roomItemStatus: PropTypes.number,
};

export default React.memo(CardNurseNote);
CardNurseNote.displayName = "CardNurseNote";
